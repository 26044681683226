import React from 'react';

const SvgPen = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M7.543 1.97l2.485 2.492a.251.251 0 0 1 0 .355l-5.49 5.491a.251.251 0 0 1-.356 0L1.693 7.819a.251.251 0 0 1 0-.355l5.494-5.495a.251.251 0 0 1 .356 0zM1.187 8.414l2.397 2.402a.251.251 0 0 1-.12.423l-3.154.754a.251.251 0 0 1-.303-.303l.757-3.156a.251.251 0 0 1 .423-.12zm10.446-6.978c.49.49.49 1.284 0 1.775l-.712.714a.251.251 0 0 1-.356 0l-2.49-2.49a.251.251 0 0 1 0-.355L8.79.367c.49-.49 1.286-.49 1.776 0l1.068 1.069z"
		/>
	</svg>
);

export default SvgPen;
