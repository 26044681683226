import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';

export default {
	...lifecycle,
	buildingsQuery: {_page: 1, _city: '', _street: '', streetNumber: '', zip: ''},
	buildings: [],
	buildingsLoading: false,
	buildingsPagination: emptyPagination,
	showPrompt: false,
	addBuildingModalOpen: false,
	processing: false,
};
