import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {getFormValues} from 'redux-form';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {formatSettingsFormOutput} from './utils';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions._setTeams.type: {
			const teams = payload;
			const teamsLoading = false;
			return [{...state, teams, teamsLoading}, null];
		}

		case actions.saveSettings.type: {
			const form = getFormValues('settingsForm')(fullState);
			const settings = formatSettingsFormOutput({form});
			return [{...state, settings}, effects.saveSettings(settings)];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._setSettings.type: {
			return [{...state, settings: payload}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
