import {mapStateKeys as _mapStateKeys} from 'utils/redux';

export const stateVals = {
	callReminder: null,
	callReminderLoading: true,
	callReminderEditorOpen: false,
	callReminders: [],
	callRemindersLoading: false,
	weekSample: new Date(),
	hourReminders: [],
};

export const stateKeys = Object.keys(stateVals);

export const mapStateKeys = _mapStateKeys(stateKeys);
