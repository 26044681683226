import {pipeHandlers} from 'utils/reduxEffects';
import common from './common/handler';
import users from './usersPage/handler';
import teams from './teamsPage/handler';
import roles from './rolesPage/handler';
import organizations from './organizationsPage/handler';
import tags from './tagsPage/handler';
import reasonMappings from './reasonMappingsPage/handler';
import smsTemplates from './smsTemplatesPage/handler';
import dashboardTargets from './dashboardTargetsPage/handler';

export default pipeHandlers(
	common,
	users,
	teams,
	roles,
	organizations,
	tags,
	reasonMappings,
	smsTemplates,
	dashboardTargets,
);
