export const emptyClientsFilters = {
	searchType: 'clients',
	installedDateFrom: '',
	installedDateTo: '',
	organizationIds: [],
	productIds: [],
	offerDateFrom: '',
	offerDateTo: '',
	withLeadClients: false,
};
