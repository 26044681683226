import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {formatLeadsFilterFormOutput, formatAreas} from './utils';
import removeUnassignedProperties from 'utils/remove-unassigned-props';
import {always} from 'ramda';
import {replaceQuery} from 'io/history';
import {emptyLeadsFilters} from './constants';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.changePage.type: {
			const newState = {
				...state,
				leadsLoading: true,
				leadsQuery: {...state.leadsQuery, _page: payload},
			};

			return [newState, effects.updateLeads()];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		case actions.searchLeads.type: {
			const newState = {
				...state,
				leadsLoading: true,
				leadsQuery: {
					...state.leadsQuery,
					_page: 1,
					...formatLeadsFilterFormOutput({form: payload}),
				},
			};

			return [newState, effects.updateLeads()];
		}

		case actions.clearQuery.type: {
			const newQuery = {
				...state.leadsQuery,
				...emptyLeadsFilters,
			};

			replaceQuery(always(removeUnassignedProperties(newQuery)));

			return [
				{
					...state,
					leadsQuery: newQuery,
				},
				null,
			];
		}

		case actions.updateLeadsQuery.type: {
			const newState = {
				...state,
				leadsLoading: true,
				leadsQuery: {...state.leadsQuery, ...payload},
			};

			return [newState, effects.updateLeads()];
		}

		case actions._setSources.type: {
			return [{...state, sources: payload}, null];
		}

		case actions._setProducts.type: {
			return [{...state, products: payload}, null];
		}

		case actions._setOrganizations.type: {
			return [{...state, organizations: payload}, null];
		}

		case actions._setUtmOptions.type: {
			const {campaigns, contents, sources, mediums, services, domains} = payload;

			return [
				{
					...state,
					utmCampaigns: campaigns,
					utmContents: contents,
					utmSources: sources,
					utmMediums: mediums,
					marketingServices: services,
					domains,
				},
				null,
			];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._updateLeadsQuery.type: {
			const newState = {
				...state,
				leadsLoading: true,
				leadsQuery: {...state.leadsQuery, ...payload},
			};

			return [newState, null];
		}

		case actions._setLeads.type: {
			const {data, pagination} = payload;
			const newState = {
				...state,
				leads: data,
				leadsPagination: pagination,
				leadsLoading: false,
				processing: false,
			};
			return [newState, null];
		}

		case actions._setProcessing.type: {
			return [{...state, processing: payload}, null];
		}

		case actions._setSalesTeams.type: {
			return [{...state, salesTeams: payload}, null];
		}

		case actions._setAreas.type: {
			return [{...state, areas: formatAreas(payload), areasLoading: false}, null];
		}

		case actions._setTags.type: {
			return [{...state, tags: payload, tagsLoading: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
