import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {isSalesmanagerUser} from '../utils';
import {createSelector} from 'reselect';
import * as commonSelectors from 'modules/common/selectors';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {formatUrlQuery} from './utils';
import {reportFormBase} from './constants';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const reportsQuery = pipe(root, prop('reportsQuery'));

export const userTeams = pipe(root, prop('userTeams'));

export const allTeams = pipe(root, prop('allTeams'));

export const allUsers = pipe(root, prop('allUsers'));

export const urlQuery = createSelector([reportsQuery], reportsQuery => {
	return formatUrlQuery({reportsQuery});
});

export const reportsQueryFetchable = createSelector(
	[reportsQuery, commonSelectors.user, userTeams],
	(raw, user, userTeams) => {
		const isManager = isSalesmanagerUser(user);
		return {
			...raw,
			teamId: isManager && userTeams.length ? userTeams[0].id : '',
			isAreaSalesManager: isManager ? 'true' : '',
		};
	},
);

export const allUsersQueryFetchable = createSelector(
	[commonSelectors.user, userTeams],
	(user, userTeams) => ({
		teamId: isSalesmanagerUser(user) && userTeams ? userTeams[0].id : '',
	}),
);

export const reportFormInitValues = () => reportFormBase;

export const reports = pipe(root, prop('reports'));

export const reportsPagination = pipe(root, prop('reportsPagination'));

export const reportsLoading = pipe(root, prop('reportsLoading'));

export const processing = pipe(root, prop('processing'));

export const reportCreatorOpen = pipe(root, prop('reportCreatorOpen'));
