import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import * as commonSelectors from 'modules/common/selectors';
import {isTeamLeaderUser, formatEditorInput} from './utils';
import {hasTimeEntriesCrud} from 'utils/perms';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const loadingTimeEntries = pipe(root, prop('loadingTimeEntries'));

export const users = pipe(root, prop('users'));

export const timeEntries = pipe(root, prop('timeEntries'));

export const timeEntriesCreatorOpen = pipe(root, prop('creatorOpen'));

export const timeEntriesEditorOpen = pipe(root, prop('editorOpen'));

export const entryInEdit = pipe(root, prop('entryInEdit'));

export const selectedUserId = pipe(root, prop('selectedUserId'));

export const selectedUser = createSelector(
	[selectedUserId, users],
	(selectedUserId, users) => users.find(u => u.id === selectedUserId),
);

export const processing = pipe(root, prop('processing'));

// PERM: manage-team-users-work-time
export const isTeamLeader = createSelector([commonSelectors.user], user =>
	isTeamLeaderUser(user),
);

export const editorInitialValues = createSelector([entryInEdit], entryInEdit =>
	formatEditorInput(entryInEdit),
);

export const timeEntriesQuery = pipe(root, prop('timeEntriesQuery'));

export const userHasTimeEntriesCrud = createSelector([commonSelectors.user], user =>
	hasTimeEntriesCrud(user),
);
