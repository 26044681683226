import {action} from 'utils/redux';
import {P, PaginatedData} from 'utils/types';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');

export const recheckQuery = creator('recheckQuery');

export const changePage = creator('changePage', P.Number);

export const openRequestCreator = creator('openRequestCreator');

export const closeRequestCreator = creator('closeRequestCreator');

export const createRequest = creator('createRequest');

export const searchClients = creator('searchClients');

export const destroy = creator('destroy');

export const _updateRequestsQuery = creator('_updateRequestsQuery', P.Object);

export const _setRequests = creator('_setRequests', PaginatedData(P.Array));

export const _setClients = creator('_setClients', P.Array);

export const _opOk = creator('_opOk');

export const _opFailed = creator('_opFailed');
