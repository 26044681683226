import {action} from 'utils/redux';
import {P, Nullable} from 'utils/types';

export default ({namespace}) => {
	const creator = action(namespace);

	return {
		selectCalendarResource: creator('selectCalendarResource', Nullable(P.Number)),
		_calendarResourceSelected: creator('_calendarResourceSelected'),
		_calendarResourceSelectionFailed: creator('_calendarResourceSelectionFailed'),
		_calendarResourceReserved: creator('_calendarResourceReserved'),
		_calendarResourceReleased: creator('_calendarResourceReleased'),
	};
};
