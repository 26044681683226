// note that we won't be using mapbox due to pricing except as fallback
// raster tile apis - cheaper
export const getMapboxTileUrl = (type, mapboxApiKey) => {
	switch (type) {
		case 'streets':
			return `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${mapboxApiKey}`;
		case 'light':
			return `https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=${mapboxApiKey}`;
		case 'satellite':
			return `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${mapboxApiKey}`;
		default:
			return '';
	}
};

//export const mapTilerTileUrls = {
//streets: `https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=${mapTilerApiKey}`,
//basic: `https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=${mapTilerApiKey}`,
//satellite: `https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=${mapTilerApiKey}`,
//};

// vector tiles sadly aren't performant enough with openlayers. they work, but they can be unnecessarily laggy, at least on mobile. not worth migrating to mapbox gl over this though, we'd also lose some nice OL customizability stuff.
//export const mapTilerStyleUrls = {
//streets: `https://api.maptiler.com/maps/basic/style.json?key=${mapTilerApiKey}`,
//};

export const getHereTileUrl = (type, hereMapsApiKey) => {
	switch (type) {
		case 'streets':
			return `https://{1-4}.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png?apiKey=${hereMapsApiKey}`;
		case 'light':
			return `https://{1-4}.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/reduced.day/{z}/{x}/{y}/512/png?apiKey=${hereMapsApiKey}`;
		case 'satellite':
			return `https://{1-4}.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/512/jpg?apiKey=${hereMapsApiKey}`;
		default:
			return '';
	}
};
