import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	initialized: false,
	processing: false,
	calendarQuery: {
		teamId: null,
		date: new Date(),
	},
	calendarResources: [],
	routes: [],
	extra: [],
	team: null,
	routesCreated: false,
	markers: [],
	processingCloseByRoutes: false,
	processingRoutes: false,
};
