// core dependencies
import React, {useEffect} from 'react';
import {formValueSelector, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose, isNil} from 'ramda';
import {
	reasonMappings,
	availableTags,
} from 'modules/usersApp/reasonMappingsPage/selectors';
// custom helpers & utils
import {applyDispatch, applyState} from 'utils/redux';
import {injectIntl, FormattedMessage} from '@meiko/react-intl';
import msgs from 'dicts/messages';
// components
import Input from 'components/generic/Input';
import Field from 'components/generic/Field';
import ReactSelect from 'components/generic/ReactSelect';
import Label from 'components/generic/Label';
import FieldError from 'components/generic/FieldError';
import FormBlock from 'components/generic/FormBlock';
import FieldSet from 'components/generic/FieldSet';
import {reasonMappingTypes} from 'dicts/reasonMappings';
import {
	TYPE_CALL,
	TYPE_VISIT,
	statesByType,
} from 'modules/usersApp/reasonMappingsPage/constants';
import {state as encounterStates} from 'dicts/encounters';

export const REASON_MAPPING_FORM = 'reasonMappingForm';

const fieldValue = field => state => formValueSelector(REASON_MAPPING_FORM)(state, field);

function validate(values, {reasonMappings = [], intl, isNew}) {
	const existingReasonMappings = reasonMappings;

	const requiredMsg = intl.formatMessage({id: msgs.requiredField});
	let errors = {};

	if (!values.title) errors.title = requiredMsg;
	if (!values.type) errors.type = requiredMsg;
	if (!values.state) errors.state = requiredMsg;

	if (
		isNew &&
		values.name &&
		values.type &&
		existingReasonMappings.length &&
		existingReasonMappings.find(t => t.name === values.name && t.type === values.type)
	) {
		errors.name = intl.formatMessage({id: 'ReasonMapping already exists'});
		errors.type = intl.formatMessage({id: 'ReasonMapping already exists'});
	}

	return errors;
}

const ReasonMappingForm = ({
	intl,
	availableTags,
	handleSubmit,
	typeValue,
	stateValue,
	preferredEncounterStateValue,
	change,
	isNew,
}) => {
	useEffect(() => {
		if (
			[TYPE_CALL, TYPE_VISIT].includes(typeValue) &&
			isNil(preferredEncounterStateValue)
		) {
			change('preferredEncounterState', 'contacted');
		}
	}, [typeValue, preferredEncounterStateValue]);

	useEffect(() => {
		if ([TYPE_CALL, TYPE_VISIT].includes(typeValue) && stateValue) {
			change('state', null);
		}
	}, [typeValue, stateValue]);

	return (
		<form id={REASON_MAPPING_FORM} onSubmit={handleSubmit}>
			<FieldSet noTopMargin noBottomMargin>
				<Field
					required
					name="title"
					type="text"
					component={({input, inputId, meta}) => (
						<FormBlock>
							<Label htmlFor={inputId}>
								<FormattedMessage id="Title" />
							</Label>
							<Input
								{...input}
								id={inputId}
								meta={meta}
								block
								stretch
								disabled={!isNew}
							/>
							<FieldError {...meta} />
						</FormBlock>
					)}
				/>

				<Field
					required
					disabled={!isNew}
					name="type"
					component={({input, inputId, meta}) => (
						<FormBlock>
							<Label htmlFor={inputId}>
								<FormattedMessage id="Type" />
							</Label>
							<ReactSelect
								{...input}
								id={inputId}
								meta={meta}
								disabled={!isNew}
								block
								options={Object.keys(reasonMappingTypes).map(key => ({
									value: key,
									label: intl.formatMessage({id: reasonMappingTypes[key]}),
								}))}
							/>
							<FieldError {...meta} />
						</FormBlock>
					)}
				/>

				{![TYPE_CALL, TYPE_VISIT].includes(typeValue) && (
					<Field
						required
						name="state"
						props={{
							options: Object.values(statesByType[typeValue] || []).map(key => ({
								value: key,
								label: intl.formatMessage({id: encounterStates[key]}),
							})),
						}}
						component={({input, inputId, meta, options}) => (
							<FormBlock>
								<Label htmlFor={inputId}>
									<FormattedMessage id="State" />
								</Label>
								<ReactSelect
									disabled={!isNew}
									{...input}
									id={inputId}
									meta={meta}
									block
									options={options}
								/>
								<FieldError {...meta} />
							</FormBlock>
						)}
					/>
				)}

				<Field
					required
					name="preferredEncounterState"
					props={{
						options: [
							{
								value: '',
								label: intl.formatMessage({id: 'No selection'}),
							},
							...Object.keys(encounterStates).map(key => ({
								value: key,
								label: intl.formatMessage({id: encounterStates[key]}),
							})),
						],
					}}
					component={({input, inputId, meta, options}) => (
						<FormBlock>
							<Label htmlFor={inputId}>
								<FormattedMessage id="Preferred encounter state" />
							</Label>
							<ReactSelect {...input} id={inputId} meta={meta} block options={options} />
							<FieldError {...meta} />
						</FormBlock>
					)}
				/>

				<Field
					name="tags"
					props={{
						options: availableTags.map(tag => ({
							value: tag.id,
							label: tag.name,
						})),
					}}
					component={({input, inputId, options}) => (
						<FormBlock>
							<Label htmlFor={inputId}>
								<FormattedMessage id="ResonMapping: Link tags to building" />
							</Label>
							<ReactSelect
								{...input}
								id={inputId}
								block
								isMulti
								closeMenuOnSelect={false}
								options={options}
							/>
						</FormBlock>
					)}
				/>
			</FieldSet>
		</form>
	);
};

ReasonMappingForm.propTypes = {
	intl: PropTypes.object.isRequired,
	isNew: PropTypes.bool.isRequired,
	reasonMappings: PropTypes.array,
};

export default compose(
	injectIntl,
	connect(
		applyState({
			availableTags,
			reasonMappings,
			typeValue: fieldValue('type'),
			stateValue: fieldValue('state'),
			preferredEncounterStateValue: fieldValue('preferredEncounterState'),
		}),
		applyDispatch({}),
	),
	reduxForm({
		form: REASON_MAPPING_FORM,
		validate,
	}),
)(ReasonMappingForm);
