import {action} from 'utils/redux';
import namespace from './namespace';
import {P, Record, Nullable} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

/**
 * Open send sms modal
 * @param {Object} data
 * @param {String} data.key
 * @param {Array} data.receivers
 * @param {Object} data.client
 * @param {Object} data.data
 * @returns {Object}
 */
export const openSendSmsModal = creator(
	'openSendSmsModal',
	Record({
		defaultTemplateKey: P.String,
		availableTemplateKeys: P.Array,
		receivers: Nullable(P.Array),
		client: Nullable(P.Object),
		data: Nullable(P.Object),
	}),
);
export const _openSendSmsModal = creator('_openSendSmsModal', P.Object);

export const closeSendSmsModal = creator('closeSendSmsModal');

// Trigger sms sending
export const sendSms = creator('sendSms', P.Object);
export const _sendSms = creator('_sendSms');

export const smsTemplatesLoadingFailed = creator('cannotLoadSmsTemplate', P.Boolean);
