import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';

export default {
	...lifecycle,
	remindersQuery: {_page: 1},
	reminders: [],
	remindersLoading: true,
	remindersPagination: emptyPagination,
	processing: false,
};
