import {equals, mergeLeft} from 'ramda';
import {catchNonFatalDefault} from 'io/errors';
import {decorateWithNotifications} from 'io/app';
import {getQuery, pushQuery} from 'io/history';
import {effect} from 'utils/redux';
import services from 'services';
import msgs from 'dicts/messages';
import namespace from './namespace';
import {parseUrlQuery} from './utils';
import {
	getRemovalRequests,
	postRemovalRequest,
	removalRequestClientSearch,
	postRemovalRequestComment,
} from './io';
import * as actions from './actions';
import * as selectors from './selectors';
import * as rootSelectors from 'modules/common/selectors';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const creator = effect(namespace);

const fetchRequests =
	({notifyOpts = {}}) =>
	(getState, dispatch) => {
		return decorateWithNotifications(
			{id: 'get-removal-requests', failureStyle: 'error', ...notifyOpts},
			getRemovalRequests(selectors.requestsQueryFetchable(getState())),
		)(getState, dispatch).then(requests => {
			dispatch(actions._setRequests(requests));
		});
	};

export let initialize = () => (getState, dispatch) => {
	const {requestsQuery} = parseUrlQuery(getQuery());
	dispatch(actions._updateRequestsQuery(requestsQuery));
	fetchRequests({})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let recheckQuery = () => (getState, dispatch) => {
	const urlQueries = parseUrlQuery(getQuery());
	const requestsQuery = selectors.requestsQuery(getState());
	if (!equals(requestsQuery, urlQueries.requestsQuery)) {
		dispatch(actions._updateRequestsQuery(urlQueries.requestsQuery));
		fetchRequests({})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}
};

export let updateRequests = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	fetchRequests({})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
updateRequests = creator('updateRequests', updateRequests);

export let searchClients = query => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'search-clients',
			failureStyle: 'warning',
		},
		removalRequestClientSearch(query),
	)(getState, dispatch)
		.then(clients => {
			dispatch(actions._setClients(clients));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
searchClients = creator('searchClients', searchClients);

export let createRequest =
	({request, comment}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'post-removal-request',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
			},
			postRemovalRequest(request).then(r =>
				comment
					? postRemovalRequestComment({
							comment,
							commentFrom: rootSelectors.user(getState()).fullName,
							removalRequestId: r.id,
					  })
					: Promise.resolve(null),
			),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._opOk());
				dispatch(actions.closeRequestCreator());

				return fetchRequests({
					notifyOpts: {loading: intl.formatMessage({id: msgs.loading})},
				})(getState, dispatch);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
createRequest = creator('createRequest', createRequest);
