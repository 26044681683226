// core dependencies
import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {injectIntl} from '@meiko/react-intl';
import Select, {components as selectComps} from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import {
	grayLight,
	grayDark,
	grayLighter,
	textColorBase,
	fontSizeBase,
	grayExtraLight,
	fontWeightMedium,
	borderColorInput,
	disabledInputColor,
	fontSizeM,
	highlightColor,
	errorColor,
	errorColorLight,
	errorColorDark,
} from 'styles/constants';
import memoize from 'memoize-one';

// note: most styles in here are copy-paste of regular select styles

const MultiValueContainer = props => {
	const {selectProps, data} = props;
	const {value: currentValue} = selectProps;
	const isFirst = currentValue.findIndex(option => option.value === data.value) === 0;

	return isFirst ? (
		<div>
			<selectComps.MultiValueContainer {...props} />
		</div>
	) : null;
};

const MultiValueLabel = props => {
	const {selectProps, data} = props;
	const {value: currentValue} = selectProps;
	const isFirst = currentValue.findIndex(option => option.value === data.value) === 0;

	return isFirst ? (
		<div content={'Customise your multi-value label component!'}>
			<selectComps.MultiValueLabel {...props}>
				+{currentValue.length}
			</selectComps.MultiValueLabel>
		</div>
	) : null;
};

const MultiValueRemove = () => {
	return null;
};

export const selectStyle = css`
	display: ${({block}) => (block ? 'block' : 'inline-block')};
	${({width}) => (width ? `width: ${width};` : '')};
	${({minWidth}) => (minWidth ? `min-width: ${minWidth};` : '')};

	.react-select__control.react-select__control {
		border-width: 1px;
		border-style: solid;
		border-color: ${({isValid}) => (isValid ? borderColorInput : errorColor)};

		padding: 0 15px;
		// better symmetry than 35px
		min-height: 36px;
		border-radius: 3px;
		background: ${({isDisabled, isValid}) =>
			isDisabled ? disabledInputColor : isValid ? 'white' : errorColorLight};
	}
	.react-select__control--is-focused {
		border-color: ${({isValid}) => (isValid ? highlightColor : errorColor)} !important;
		box-shadow: none !important;
	}

	.react-select__indicators {
		margin-left: auto;
		padding-left: 5px;
	}

	.react-select__indicator-separator {
		display: none;
	}

	.react-select__indicator.react-select__dropdown-indicator {
		padding: 0;
		color: ${textColorBase};
	}

	.react-select__clear-indicator {
		box-sizing: content-box;
		margin-right: 6px;
		padding: 2px;
		width: 16px;
	}

	.react-select__value-container {
		padding: 5px 0;

	}
	// all values have a horizontal margin of 2px by default, counter that
	.react-select__value-container {
		margin: -2px;
	}

	.react-select__single-value,
	.react-select__placeholder,
	.react-select__input,
	.react-select__multi-value__label {
		font-size: ${fontSizeBase};
		color: ${({isValid}) => (isValid ? textColorBase : errorColorDark)};
	}

	.react-select__multi-value__remove {
		&:hover {
			color: ${grayDark};
			background-color: ${grayLight};
		}
	}

	.react-select__menu {
		background-color: ${grayExtraLight};
		z-index: 1000;
	}

	.react-select__option {
		color: ${textColorBase};
		padding: 8px 15px;

		&:hover {
			background-color: ${grayLight};
		}
	}
	.react-select__option--is-focused {
		/* solid color causes flashing issues when selected options are displayed in the option list */
		background-color: transparent;
	}
	.react-select__option--is-selected {
		background-color: ${grayLighter};
		opacity: 0.75;
	}

	.react-select__menu-notice {
		padding: 6px 12px;
	}
}
`;

const StyledSelect = styled(Select)`
	${selectStyle};
`;

const StyledAsyncSelect = styled(AsyncSelect)`
	${selectStyle};
`;

const StyledCreatableSelect = styled(CreatableSelect)`
	${selectStyle};
`;

const DropDownIcon = styled.i.attrs({
	className: 'fa fa-angle-down',
})`
	font-weight: ${fontWeightMedium};
	font-size: ${fontSizeM};
`;

const DropdownIndicator = props => (
	<selectComps.DropdownIndicator {...props}>
		<DropDownIcon />
	</selectComps.DropdownIndicator>
);

class ReactSelect extends React.Component {
	getRsValue = memoize(({value, options, getOptionValue}) => {
		const rsifyVal = v => options.find(o => getOptionValue(o) === v);
		/* prettier-ignore */
		// redux-form sets null input value as empty string, but ReactSelect expects null
		return value === '' ? null
			: value == null ? value
			: Array.isArray(value) ? value.map(rsifyVal)
			: rsifyVal(value);
	});

	render() {
		const {
			intl,
			async,
			creatable,
			useOptionsAsValues,
			getOptionValue = opt => opt.value,
			getOptionLabel = opt => opt.label,
			placeholder = intl.formatMessage({id: 'Choose...'}),
			noOptionsMessage = ({inputValue}) =>
				async && inputValue === ''
					? intl.formatMessage({id: 'Type to search...'})
					: intl.formatMessage({id: 'No choices'}),
			loadingMessage = () => intl.formatMessage({id: 'Loading...'}),
			formatCreateLabel = inputValue =>
				intl.formatMessage({id: 'Create {inputValue}'}, {inputValue}),
			options,
			value,
			onChange = () => {},
			// onBlur = () => {},
			onBlur,
			onFocus = () => null,
			components = {},
			meta,
			concise = false,
			...rest
		} = this.props;

		let rsValue = value;
		let rsOnChange = onChange;
		// let rsOnBlur = onBlur;
		// conversions between option objects and values
		if (!(async || useOptionsAsValues)) {
			rsValue = this.getRsValue({
				value,
				options,
				getOptionValue,
			});
			// the react-select onChange function gives us the new value (and some other stuff), so format it right. works with redux-form too.
			rsOnChange = (val, ...rest) => {
				// prettier-ignore
				const outVal = val == null ? val
					: Array.isArray(val) ? val.map(getOptionValue)
					: getOptionValue(val)
				onChange(outVal, ...rest);
			};
			// the react-select onBlur function gives us an event, but it's hard to change the event's target object to contain a formatted value (while also preserving the target object in a valid format), so just change the onBlur API to produce a value instead of an event. producing the correct value is important when working with redux-form.
			// rsOnBlur = () => onBlur(value);
		}

		let SelectComponent = null;
		if (async) {
			SelectComponent = StyledAsyncSelect;
		} else if (creatable) {
			SelectComponent = StyledCreatableSelect;
		} else {
			SelectComponent = StyledSelect;
		}

		// TODO: onBlur was disabled for now since it causes problems on mobile + redux-form. it was under onChange like this, see git history
		// onBlur={useOptionsAsValues ? onBlur : rsOnBlur}

		const isValid = meta ? !((meta.submitFailed || meta.touched) && meta.error) : true;

		return (
			<SelectComponent
				classNamePrefix="react-select"
				isDisabled={rest.disabled}
				{...rest}
				placeholder={placeholder}
				noOptionsMessage={noOptionsMessage}
				loadingMessage={loadingMessage}
				formatCreateLabel={formatCreateLabel}
				getOptionValue={getOptionValue}
				getOptionLabel={getOptionLabel}
				options={options}
				value={useOptionsAsValues ? value : rsValue}
				onChange={useOptionsAsValues ? onChange : rsOnChange}
				components={{
					DropdownIndicator,
					...(concise
						? {
								MultiValueContainer,
								MultiValueLabel,
								MultiValueRemove,
						  }
						: {}),
					...components,
				}}
				isValid={isValid}
				onFocus={onFocus}
			/>
		);
	}
}

ReactSelect.propTypes = {
	intl: propTypes.object,
	// setting to true renders the Async version of react-select. it may be used when you have something that requires a select instead of an autosuggest, but the options list is too long to load all at once. note that the async component accepts different props - "defaultOptions" should often be set to true, while "options" shouldn't be given. also note that "useOptionsAsValues" will always be true when async.
	async: propTypes.bool,
	// by default the component works like a regular select - passing in label-value pairs, and working only with the values. this restores the default react-select behavior where the value passed through "value" and "onChange" props is the entire corresponding option object, instead of just the option value.
	// this is always true when using the async version (because async makes accessing the options list from the outside more difficult and presents difficulties with loading initially selected options) - use decorators around "onChange" and "value" if you don't want the whole option object.
	useOptionsAsValues: propTypes.bool,
	placeholder: propTypes.string,
	noOptionsMessage: propTypes.func,
	loadingMessage: propTypes.func,
	getOptionLabel: propTypes.func,
	getOptionValue: propTypes.func,
	options: propTypes.array,
	value: propTypes.any,
	onChange: propTypes.func,
	onBlur: propTypes.any,
	components: propTypes.object,
	meta: propTypes.object,
};

export default injectIntl(ReactSelect);
export {selectComps as components};
