import namespace from './namespace';
import {effect} from 'utils/redux';
import {setPageTitleMessage} from 'io/app';
import {getQuery} from 'io/history';
import * as globalActions from 'modules/common/actions';
import services from 'services';
import {catchNonFatalDefault} from 'io/errors';
import {loggedIn} from 'modules/common/selectors';

const creator = effect(namespace);

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));

export let initialize = () => (getState, dispatch) => {
	setPageTitleMessage('Uudelleen ohjataan')(getState, dispatch);

	if (loggedIn(getState())) {
		window.location.assign('/');
		return;
	}

	const redirectUrl = localStorage.getItem('redirectUrl')
		? localStorage.getItem('redirectUrl')
		: '/';

	const token = getQuery().code;
	httpJson('get', `/auth/callback/azure?code=${token}`)
		.catch(e => {
			const resMsg = e.response.json().then(res => res.info);
			resMsg.then(msg => {
				window.location.assign(`${redirectUrl}?error=${msg}`);
			});
			throw e;
		})
		.then(({token}) => {
			dispatch(globalActions.afterLogin(token));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);
