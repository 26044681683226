import React from 'react';

const UserCheck = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<g
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			transform="scale(.85714)"
		>
			<path d="M13.5 8l-4.12 5.5-2.75-2.06" />
			<circle cx={5} cy={2.75} r={2.25} />
			<path d="M3 12.5H.5V11a4.5 4.5 0 017.68-3.18" />
		</g>
	</svg>
);

export default UserCheck;
