import {map} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import {getResponseData} from 'utils/app';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const postTarget = target =>
	httpJson('post', '/targets', {}, {body: target})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save goal'})))
		.then(getResponseData(normalize.target));

export const getTargets = query =>
	httpJson('get', '/targets', query)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.target)));
