import {effect} from 'utils/redux';
import * as actions from './actions';
import {P} from 'utils/types';
import {catchNonFatalDefault} from 'io/errors';
import {decorateWithNotifications} from 'io/app';
import namespace from './namespace';
import {resolveObject} from 'utils/promises';
import services from 'services';
import msgs from 'dicts/messages';
import {
	attachTag as attachTagIo,
	detachTag as detachTagIo,
	getBuildingsTags,
} from 'modules/common/io';
import {getTags as getAvailableTags} from 'modules/usersApp/tagsPage/io';
import * as confirmerActions from 'modules/confirmer/actions';
import * as selectors from './selectors';
import {
	getBuilding,
	getClients,
	putClient,
	postClient,
	deleteClient,
	getEncounter,
	get16100Clients,
	getClientData,
	getAreas,
} from './io';
import createBuildingModalEffects from 'fragments/buildingModalActions/effects';
import {removeBan as removeBanIo, banClient as banClientIo} from '../clientPage/io';
import {omit, pipe, prop} from 'ramda';
import {TYPE_BUILDING} from 'modules/usersApp/tagsPage/constants';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const searchClients = buildingId => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'search-clients',
			suppressFailure: true,
		},
		get16100Clients(buildingId),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._search16100Failed());
			throw e;
		})
		.then(data => {
			dispatch(actions._set16100Clients(data));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};

const buildingModalEffects = createBuildingModalEffects({
	namespace,
	actions,
});

export const {removeBuilding, saveBuildingData} = buildingModalEffects;

export let initialize = buildingId => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'get-building-data',
			failureStyle: 'error',
		},
		resolveObject({
			building: getBuilding(buildingId),
			clients: getClients(buildingId),
			areas: getAreas(),
			availableTags: getAvailableTags({
				type: TYPE_BUILDING,
				getAllTags: false,
			}).then(pipe(prop('data'))),
		}),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(data => {
			searchClients(buildingId)(getState, dispatch);

			dispatch(actions._setInitData(data));
		})
		.catch(catchNonFatalDefault(getState, dispatch));

	dispatch(actions.getTags(buildingId));
};
initialize = creator('initialize', initialize, P.Number);

export let saveClient =
	({client, buildingId}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'save-client',
				failureStyle: 'warning',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Saved'}),
			},
			!buildingId ? putClient(client) : postClient(client, buildingId),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(client => {
				dispatch(
					actions._updateClients({
						client: client.data,
						type: !buildingId ? 'update' : 'add',
					}),
				);
			})
			.catch(catchNonFatalDefault);
	};
saveClient = creator('saveClient', saveClient);

export let removeClient = id => (getState, dispatch) => {
	const onConfirm = () => {
		decorateWithNotifications(
			{
				id: 'delete-client',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Client deleted'}),
			},
			deleteClient(id),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._updateClients({client: {id}, type: 'remove'}));
			})
			.catch(catchNonFatalDefault);
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete client?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
removeClient = creator('removeClient', removeClient);

export let fetchEncounter = id => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'get-encounter-data',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		getEncounter(id),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(encounter => dispatch(actions._setEncounterData(encounter)))
		.catch(catchNonFatalDefault(getState, dispatch));
};
fetchEncounter = creator('fetchEncounter', fetchEncounter);

export let fetchClientData = clientId => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'get-client-data',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		getClientData(clientId),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(data => {
			dispatch(actions._set16100Client(data));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
fetchClientData = creator('fetchClientData', fetchClientData);

export let banClient = clientId => (getState, dispatch) => {
	const client = selectors.clients(getState()).find(c => c.id === clientId);

	decorateWithNotifications(
		{
			id: 'ban-client',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		banClientIo(clientId),
	)(getState, dispatch)
		.then(data => {
			dispatch(actions._banClient());
			dispatch(
				actions._updateClients({
					type: 'update',
					client: {...client, ban: data},
					clientEditorOpen: true,
				}),
			);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
banClient = creator('banClient', banClient);

export let unbanClient =
	({clientId, banId}) =>
	(getState, dispatch) => {
		const client = selectors.clients(getState()).find(c => c.id === clientId);
		decorateWithNotifications(
			{
				id: 'ban-client',
				failureStyle: 'warning',
				loading: intl.formatMessage({id: msgs.processing}),
			},
			removeBanIo(banId),
		)(getState, dispatch)
			.then(data => {
				dispatch(actions._unbanClient());
				dispatch(
					actions._updateClients({
						type: 'update',
						client: omit(['ban'], client),
						clientEditorOpen: true,
					}),
				);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
unbanClient = creator('unbanClient', unbanClient);

export let getTags = id => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'fetch-tags',
			failureStyle: 'warning',
		},
		getBuildingsTags(id),
	)(getState, dispatch)
		.then(tags => {
			dispatch(actions._getTags(tags));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
getTags = creator('getTags', getTags);

export let attachTag = params => (getState, dispatch) => {
	const {buildingId} = params;
	decorateWithNotifications(
		{
			id: 'attach-tag',
			failureStyle: 'error',
		},
		attachTagIo(params),
	)(getState, dispatch)
		.then(() => dispatch(actions._attachTag()))
		.then(() => dispatch(actions.getTags(buildingId)))
		.catch(catchNonFatalDefault);
};

attachTag = creator('attachTag', attachTag);

export let detachTag = params => (getState, dispatch) => {
	const {buildingId} = params;
	decorateWithNotifications(
		{
			id: 'detach-tag',
			failureStyle: 'error',
		},
		detachTagIo(params),
	)(getState, dispatch)
		.catch(catchNonFatalDefault)
		.then(() => dispatch(actions._detachTag()))
		.then(() => dispatch(actions.getTags(buildingId)));
};

detachTag = creator('detachTag', detachTag);
