import {toLookup} from 'utils/arrays';
import {objectListToCheckboxVals, checkboxValsToIdObjectList} from 'utils/data';
import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import initState from './state';
import {parseNullableNumber} from 'utils/fields';

export const formatUserFormInput = ({user, organizations, roles, teams, permissions}) => {
	const additionalTeamIds = user.additionalTeamIds.map(x => x.id);

	const enioCallerUser = user.meta?.enioCallerUser;
	const enioCallerPassword = user.meta?.enioCallerPassword;
	const enioCallerToken = user.meta?.enioCallerToken;

	const allTeams = toLookup(
		o => o.id,
		o => {
			const orgTeam = user.allTeams.find(t => t.organizationId === o.id);
			return orgTeam ? orgTeam.id : null;
		},
		organizations,
	);

	return {
		...user,
		additionalTeamIds,
		organizations: objectListToCheckboxVals(organizations, user.organizations),
		roles: objectListToCheckboxVals(roles, user.roles),
		permissions: objectListToCheckboxVals(permissions, user.userPermissions),
		allTeams,
		enioCallerUser,
		enioCallerPassword,
		enioCallerToken,
	};
};

export const formatUserFormOutput = ({form}) => {
	const allTeams = Object.values(form.allTeams).reduce(
		(acc, opt) => (opt ? [...acc, {id: opt}] : acc),
		[],
	);

	const user = {
		...form,
		additionalTeamIds: form.additionalTeamIds
			? form.additionalTeamIds.map(val => ({id: val}))
			: [],
		organizations: checkboxValsToIdObjectList(form.organizations),
		roles: checkboxValsToIdObjectList(form.roles),
		permissions: checkboxValsToIdObjectList(form.permissions),
		allTeams,
	};

	return {user};
};

export const formatSearchInput = ({usersQuery}) => {
	const search = {
		search: usersQuery._q,
		organizationSearch: usersQuery.organizationId,
		teamSearch: usersQuery.teamId,
		roleSearch: usersQuery.roleId,
	};
	return search;
};

export const formatSearchOutput = ({form}) => {
	const searchQuery = {
		organizationId: form.organizationSearch,
		_q: form.search,
		teamId: form.organizationSearch ? form.teamSearch : null,
		roleId: form.roleSearch ? form.roleSearch : null,
	};

	return searchQuery;
};

export const formatExcelInput = roles => {
	return {
		roles: objectListToCheckboxVals(roles, roles),
	};
};

export const formatExcelOutput = ({form}) => {
	const roles = {roles: checkboxValsToIdObjectList(form.roles)};
	return roles;
};

export const parseUrlQuery = query => {
	const vals = evolve(
		{
			_page: Number,
			organizationId: parseNullableNumber,
			teamId: parseNullableNumber,
			roleId: parseNullableNumber,
		},
		query,
	);
	return {usersQuery: replace(vals, initState.usersQuery)};
};

export const formatUrlQuery = ({usersQuery}) => usersQuery;

export const getRolesPermissions = (rolesVal, roles = []) => {
	if (!rolesVal) return new Set();

	let roleIds = [];
	let rolesPermissions = [];

	Object.entries(rolesVal).forEach(([id, selected]) => {
		if (selected) roleIds = [...roleIds, Number(id)];
	});

	roleIds.forEach(id => {
		const role = roles.find(x => x.id === id);
		if (role && role.permissions?.length) {
			rolesPermissions = [...rolesPermissions, ...role.permissions];
		}
	});

	return new Set(rolesPermissions.map(p => p.id));
};
