import {getCallReminders} from './io';
import * as commonSelectors from 'modules/common/selectors';

export const initializeCallReminder =
	({buildingId, actions, projectBuildingId}) =>
	(getState, dispatch) => {
		const userId = commonSelectors.user(getState()).id;
		return getCallReminders({buildingId, userId, projectBuildingId, _limit: 999}).then(
			callReminders => {
				dispatch(
					actions._setCallReminder(callReminders.length ? callReminders[0] : null),
				);
			},
		);
	};

export const initializeCallReminders =
	({actions, userId, dateFrom, dateTo}) =>
	(getState, dispatch) => {
		return getCallReminders({userId, dateTo, dateFrom}).then(callReminders => {
			dispatch(actions._setCallReminders(callReminders.length ? callReminders : []));
		});
	};
