import {curry, pick} from 'ramda';

export const mapKeys = curry((f, object) => {
	let newObj = {};
	Object.keys(object).forEach(k => {
		newObj[f(k)] = object[k];
	});
	return newObj;
});

export const split = curry((keys, object) => {
	let otherProps = Object.assign({}, object);
	let matchingProps = {};
	keys.forEach(k => {
		if (Object.prototype.hasOwnProperty.call(otherProps, k)) {
			matchingProps[k] = otherProps[k];
			delete otherProps[k];
		}
	});
	return [matchingProps, otherProps];
});

export const spreadProp = curry((key, object) => {
	const newObj = Object.assign({}, object);
	delete newObj[key];
	Object.assign(newObj, object[key]);
	return newObj;
});

export const dissocNulls = object => {
	const newObj = {};
	Object.keys(object).forEach(k => {
		if (object[k] != null) {
			newObj[k] = object[k];
		}
	});
	return newObj;
};

export const replace = (source, target) =>
	Object.assign({}, target, pick(Object.keys(target), source));

export const pickIf = (predicates, object) => {
	const newObj = {};
	Object.keys(predicates).forEach(k => {
		if (predicates[k]) {
			newObj[k] = object[k];
		}
	});
	return newObj;
};

export const pickIfNot = (predicates, object) => {
	const newObj = {};
	Object.keys(predicates).forEach(k => {
		if (!predicates[k]) {
			newObj[k] = object[k];
		}
	});
	return newObj;
};

export const singleValueObject = (value, keys) => {
	const obj = {};
	keys.forEach(k => {
		obj[k] = value;
	});
	return obj;
};
