import {state as lifecycle} from 'fragments/lifecycle';
import createDatePickState from 'fragments/calendarResourcePicker/state';
import createCallReminderState from 'fragments/callReminder/state';

const datePickState = createDatePickState({});
const callReminderState = createCallReminderState({});

export default {
	...lifecycle,
	...datePickState,
	...callReminderState,
	processing: false,
	building: null,
	clients: [],
	salesmanVisit: null,
	salesmanVisitClient: null,
	calendarResource: null,
	calendarResourceDateEditOpen: false,
	buildingPreviewOpen: false,
	encounterPreviewOpen: false,
	prevVisitState: null,
	allProductStates: [],
	productStatesLoading: true,
	salesmen: [],
	salesmenLoading: true,
	encounters: [],
	previewableEncounter: null,
	newAppointment: null,
	// comments state
	comments: [],
	clientsEditorOpen: false,
	clientChangerOpen: false,
	client: null,
	activeTab: 'visit',
	salesmanVisits: [],
	availableTags: [],
	tags: [],
	processingTags: false,
	processingTag: false,
};
