import services from 'services';
import {map} from 'ramda';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {mapResponseData, getResponseData} from 'utils/app';
import {organizationsQueryBase} from './constants';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getProducts = query =>
	httpJson('get', '/products/all', {...query, include: 'organizations'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.product)));

export const getOrganizations = query =>
	httpJson('get', '/organizations', {...organizationsQueryBase, ...query})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.team)));

export const putOrganization = (id, organization) =>
	httpJson('put', `/organizations/${id}`, {}, {body: organization})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save organization'})))
		.then(getResponseData(map(normalize.role)));
