import Mustache from 'mustache';

/**
 * Render template to string
 * @param {string} template
 * @param {Object} data
 */
export const render = (template, data) => {
	return Mustache.render(template, data);
};

/*
 * Parse available variables in template
 * See reference: https://github.com/janl/mustache.js/issues/538#issuecomment-168947923
 * @param {string} template
 */
export const getTemplateVariables = template => {
	try {
		return Mustache.parse(template)
			.filter(function (v) {
				return v[0] === 'name';
			})
			.map(function (v) {
				return v[1];
			});
	} catch (e) {
		return [];
	}
};
