import React from 'react';
import propTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import Media from 'react-responsive';
import {applyState, applyDispatch} from 'utils/redux';
import {initialize, login, loginWithAd} from 'modules/login/actions';
import {loading} from 'modules/login/selectors';
import logoLight from 'assets/images/eniopro1_white.svg';
import {colors, screenMdMin} from 'styles/constants';
import Form from './components/Form';
import View from 'components/generic/View';
import Page from 'components/generic/Page';
import AzureLogin from './components/AzureLogin';
import ContentSeparator from 'components/generic/ContentSeparator';
import Link from 'components/generic/Link';

const desktopQuery = `(min-width: ${screenMdMin})`;

const Row = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	background: #232323;
`;

const LeftCol = styled.div`
	flex: 1;
	background: #232323;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const RightCol = styled.div`
	flex: 1;
	background: ${colors.grey1};
	display: flex;
	align-items: center;
	justify-content: center;
`;

const FormContainer = styled.div`
	flex: 1;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	max-width: 400px;
	min-width: 300px;
	margin: auto auto;
	padding: 50px 55px;

	> * {
		box-sizing: border-box;
	}
`;

const SmallLogo = styled.img`
	height: 48px;
	width: auto;
	margin-bottom: 40px;
`;

const LargeLogo = styled.img`
	height: 380px;
	width: auto;
	max-width: 300px;
`;

const Separator = styled(ContentSeparator)`
	border-top: 1px solid ${colors.grey2};
	width: 100%;
`;

const StyledLink = styled(Link)`
	color: ${colors.primary1};
`;

class Login extends React.Component {
	componentDidMount() {
		this.props.initialize();
	}

	render() {
		const {login, loginWithAd, loading} = this.props;

		return (
			<View>
				<Page>
					<Row>
						<LeftCol>
							<FormContainer>
								<SmallLogo src={logoLight} alt="eniopro-logo" />
								<AzureLogin loginWithAd={loginWithAd} loading={loading} />
								<Separator />
								<Form onSubmit={() => login()} loading={loading} />
								<Separator />
								{window.location.hostname !== 'enio.pro' && (
									<StyledLink href="//renoa.pro/country-select">
										Country selection
									</StyledLink>
								)}
							</FormContainer>
						</LeftCol>
						<Media query={desktopQuery}>
							<RightCol>
								<LargeLogo src={logoLight} alt="eniopro-logo-large" />
							</RightCol>
						</Media>
					</Row>
				</Page>
			</View>
		);
	}
}

Login.propTypes = {
	initialize: propTypes.func,
	login: propTypes.func,
	loginWithAd: propTypes.func,
	loading: propTypes.bool,
};

export default connect(
	applyState({loading}),
	applyDispatch({initialize, login, loginWithAd}),
)(Login);
