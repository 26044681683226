import namespace from './namespace';
import {effect} from 'utils/redux';
import {catchNonFatalDefault} from 'io/errors';
import {ensureAccess, setPageTitleMessage, decorateWithNotifications} from 'io/app';
import {
	getNotices,
	getOrganizations,
	getRoles,
	deleteNotice,
	postNotice,
	putNotice,
} from './io';
import services from 'services';
import * as actions from './actions';
import * as selectors from './selectors';
import * as commonSelectors from 'modules/common/selectors';
import * as confirmerActions from 'modules/confirmer/actions';
import {getQuery, pushQuery} from 'io/history';
import {resolveObject} from 'utils/promises';
import {pick, mergeLeft, equals} from 'ramda';
import msgs from 'dicts/messages';
import {appName} from './constants';
import {parseUrlQuery} from './utils';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const noticesQueryKeys = ['_page', '_sort'];

const fetchNoticesData = (getState, dispatch) => {
	const noticesQuery = selectors.noticesQuery(getState());

	decorateWithNotifications(
		{id: 'get-report', failureStyle: 'error'},
		Promise.all([
			getNotices(noticesQuery).then(notices => {
				dispatch(actions._setNotices(notices));
			}),
			resolveObject({
				organizations: getOrganizations({_limit: '999', include: 'teams'}),
				roles: getRoles(),
			}).then(data => {
				dispatch(actions._setInitData(data));
			}),
		]),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};

export let initialize = () => (getState, dispatch) => {
	setPageTitleMessage('Internal releases')(getState, dispatch);

	dispatch(actions._updateNoticesQuery(pick(noticesQueryKeys, getQuery())));

	ensureAccess(appName, commonSelectors.user(getState()))(getState, dispatch)
		.then(() => {
			dispatch(actions._initialize());
			fetchNoticesData(getState, dispatch);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let pageChanged = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	updateNotices()(getState, dispatch);
};

pageChanged = creator('pageChanged', pageChanged);

export let updateNotices = () => (getState, dispatch) => {
	const noticesQuery = selectors.noticesQuery(getState());
	decorateWithNotifications(
		{id: 'get-notices', failureStyle: 'error'},
		getNotices(noticesQuery),
	)(getState, dispatch)
		.then(notices => {
			dispatch(actions._setNotices(notices));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
updateNotices = creator('updateNotices', updateNotices);

const fetchNotices = ({notifyOpts = {}}) => (getState, dispatch) => {
	return decorateWithNotifications(
		{id: 'get-notices', failureStyle: 'error', ...notifyOpts},
		getNotices(selectors.noticesQuery(getState())),
	)(getState, dispatch).then(notices => {
		dispatch(actions._setNotices(notices));
	});
};

export let recheckQuery = () => (getState, dispatch) => {
	const urlQueries = parseUrlQuery(getQuery());
	const noticesQuery = selectors.noticesQuery(getState());
	if (!equals(noticesQuery, urlQueries.noticesQuery)) {
		dispatch(actions._updateNoticesQuery(urlQueries.noticesQuery));
		fetchNotices({})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}
};

export let removeNotice = id => (getState, dispatch) => {
	const onConfirm = () => {
		dispatch(actions._startOp());
		decorateWithNotifications(
			{
				id: 'delete-notice',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Internal release deleted'}),
			},
			deleteNotice(id),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._opOk());
				const {_page: page} = selectors.noticesQuery(getState());
				if (selectors.notices(getState()).length <= 1 && page > 1) {
					dispatch(actions._updateNoticesQuery({_page: page - 1}));
					pushQuery(mergeLeft(selectors.urlQuery(getState())));
				}

				return fetchNotices({notifyOpts: {loading: msgs.loading}})(getState, dispatch);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete internal release?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
removeNotice = creator('removeNotice', removeNotice);

export let saveNewNotice = notice => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'post-notice',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		postNotice(notice),
	)(getState, dispatch)
		.then(notice => {
			dispatch(actions._noticeSaved());
			updateNotices()(getState, dispatch);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
saveNewNotice = creator('saveNewNotice', saveNewNotice);

export let editNotice = ({notice, id}) => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'put-notice',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		putNotice(notice, id),
	)(getState, dispatch)
		.then(notice => {
			dispatch(actions._noticeSaved());
			updateNotices()(getState, dispatch);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
editNotice = creator('editNotice', editNotice);
