import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const openPlacesSuggestion = creator('openPlacesSuggestion', P.String);

export const updateBuildingsQuery = creator('updateBuildingsQuery', P.Object);

export const updateMap = creator('updateMap');

export const openBuilding = creator('openBuilding', P.Object);

export const closeBuilding = creator('closeBuilding');

export const setMapSource = creator('setMapSource', P.String);

export const recheckQuery = creator('recheckQuery');

export const _updateBuildingsQuery = creator('_updateBuildingsQuery', P.Object);
