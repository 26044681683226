import * as React from 'react';

const Chat = props => {
	return (
		<svg viewBox="0 0 12 12" width="1em" height="1em" {...props}>
			<path
				d="M3.857 10.714l-3.428.857L1.286 9V1.286a.857.857 0 01.857-.857h8.571a.857.857 0 01.857.857v8.571a.857.857 0 01-.857.857z"
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={0.85714}
			/>
		</svg>
	);
};

export default Chat;
