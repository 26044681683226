import {path, pipe, prop} from 'ramda';
//import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';

// eslint-disable-next-line no-unused-vars
const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const redirect = pipe(root, prop('redirect'));

export const loading = pipe(root, prop('loading'));
