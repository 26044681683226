import services from 'services';
import {prop} from 'ramda';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getBuildings = query =>
	httpJson('get', `/buildings/search`, query)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(prop('data'));
