import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	processing: false,
	activeBan: {},
	bansType: 'phone',
	bans: [],
	buildingsBans: [],
	buildingsBansPagination: {},
	bansLoading: true,
	bansPagination: {},
	bansQuery: {_limit: 25, _page: 1},
	banModalOpen: false,
	banFormInitialValues: {firstName: '', lastName: '', phone: '', description: ''},
};
