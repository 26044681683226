import {action} from 'utils/redux';
import namespace from './namespace';
import {P, Nullable} from 'utils/types';
const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const _setCallLogs = creator('_setCallLogs', Nullable(P.Array));

export const _setUsers = creator('_setUsers', P.array);

export const _setOrganizations = creator('_setOrganizations', P.array);

export const _initialize = creator('_initialize');

export const searchCallLogs = creator('searchCallLogs');

export const setPhonenumber = creator('setPhonenumber', P.String);

export const setDateFrom = creator('setDateFrom', P.String);

export const setDateTo = creator('setDateTo', P.String);

export const setUserId = creator('setUserId', P.Array);

export const setTeamId = creator('setTeamId', P.Array);

export const setTeamType = creator('setTeamType', Nullable(P.String));

export const _setTeams = creator('_setTeams');

export const getTeams = creator('getTeams');

export const _setTeamType = creator('_setTeamType', Nullable(P.String));

export const fetchTeamsWithUsers = creator('fetchTeamsWithUsers', Nullable(P.Array));
