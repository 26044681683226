const perms = [
	'geolocation',
	'camera',
	'microphone',
	'notifications',
	'clipboard-read',
	'clipboard-write',
	'fullscreen',
	'push',
	'midi',
	'midi-sys-ex',
	'ambient-light-sensor',
	'accelerometer',
	'gyroscope',
	'magnetometer',
	'speaker-selection',
	'vibrate',
	'background-fetch',
	'background-sync',
	'bluetooth',
	'persistent-storage',
	'idle-detection',
	'web-share',
	'serial',
	'usb',
	'payment-handler',
	'nfc',
	'vr',
	'xr-spatial-tracking',
];
const permissionStatus = ['granted', 'denied', 'prompt', 'dismissed'];
export const browserPermissions = async permName => {
	if (!perms.includes(permName)) {
		return {
			status: false,
			msg: 'Invalid permission',
		};
	}
	const permission = await navigator.permissions.query({name: permName});

	if (!permissionStatus.includes(permission.state)) {
		return {
			status: false,
			msg: 'Invalid permission status',
		};
	}
	let returnValue;
	switch (permission.state) {
		case permissionStatus[0]:
			returnValue = {status: true, msg: ''};
			break;
		case permissionStatus[1]:
			returnValue = {status: false, msg: 'Permission denied'};
			break;
		case permissionStatus[2]:
			returnValue = {status: true, msg: ''};
			break;
		case permissionStatus[3]:
			returnValue = {status: false, msg: 'Permission is removed'};
			break;
		default:
			returnValue = {status: false, msg: 'Unexpected error'};
			break;
	}
	return returnValue;
};
