export const overviewQueryBase = {
	allCallPools: true,
	type: 'tele',
};

export const overviewInclude = 'areas:withCallPoolInfo(true)';

export const overviewIncludeWithTeams = 'areas:withCallPoolInfo(true),teams.users';

export const minRefreshRate = 3;
