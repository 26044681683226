import React from 'react';
import styled from 'styled-components';
import RouterLink from './RouterLink';
import {navHeight, fontWeightMedium} from 'styles/constants';
import {
	setTextColor,
	setBgColorHover,
	setTextColorHover,
	setBorderColor,
} from 'utils/headerColors';
// used only by Navbar
/* eslint-disable react/prop-types */

// use this instead of styling react-router Link, because Link doesn't work well with styled-components (style props get passed into the DOM component). https://github.com/styled-components/styled-components/issues/1814
const LinkWrapper = styled.span`
	> * {
		height: calc(${navHeight} - 1px);
		display: inline-flex;
		border-right: ${({organizationColors}) => setBorderColor(organizationColors?.border)}
		padding: 0px 15px;
		white-space: nowrap;
		${({organizationColors}) => setTextColor(organizationColors?.text)}
		&:hover {
			${({organizationColors}) => setBgColorHover(organizationColors?.bgHover)}
			${({organizationColors}) => setTextColorHover(organizationColors?.textHover)}
			text-decoration: none;
		}

		${/*prettier-ignore*/ ({isActive}) => isActive ? `
			font-weight: ${fontWeightMedium};
		` : ''}

		> span {
			margin-top: auto;
			margin-bottom: auto;
		}
	}
`;

const getItemStyle = isVisible =>
	isVisible ? {opacity: 1, order: 0} : {opacity: 0, order: 100, pointerEvents: 'none'};

const NavbarNavItem = ({
	children,
	to,
	active,
	organizationId,
	organizationColors,
	isVisible = true,
	...rest
}) => (
	<li {...rest} style={getItemStyle(isVisible)}>
		<LinkWrapper
			organizationId={organizationId}
			organizationColors={organizationColors}
			isActive={active}
		>
			<RouterLink to={to} className={active ? 'active' : ''}>
				{children}
			</RouterLink>
		</LinkWrapper>
	</li>
);

export default styled(NavbarNavItem)``;
