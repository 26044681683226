import React from 'react';

const SvgConversation = props => (
	<svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M8.667 8a.668.668 0 0 1 0-1.333h14.666a.667.667 0 0 1 0 1.333H8.667zm0 5.333a.668.668 0 0 1 0-1.333H16a.667.667 0 0 1 0 1.333H8.667zM18 20a.667.667 0 0 1 0-1.333h8A.667.667 0 0 1 26 20h-8zm0 4a.667.667 0 0 1 0-1.333h8A.667.667 0 0 1 26 24h-8zm-9.333-5.333a.668.668 0 0 1 0-1.334h2.666a.667.667 0 0 1 0 1.334H8.667zm-6.85 10.998a.67.67 0 0 1-.597-.965l3.207-6.401A12.105 12.105 0 0 1 0 13.192c0-3.537 1.675-6.856 4.716-9.35C7.737 1.366 11.746 0 16 0c8.823 0 16 5.91 16 13.177a.667.667 0 0 1-1.333 0c0-6.53-6.579-11.844-14.667-11.844-8.088 0-14.667 5.314-14.667 11.844a10.742 10.742 0 0 0 4.334 8.4.666.666 0 0 1 .196.834l-2.594 5.176 7.078-3.294a.666.666 0 1 1 .562 1.208l-8.812 4.102a.656.656 0 0 1-.28.062zM30.644 32a.667.667 0 0 1-.283-.063l-5.058-2.354a11.67 11.67 0 0 1-3.147.43c-5.464 0-9.89-3.666-9.89-8.172s4.427-8.173 9.867-8.173c5.44 0 9.867 3.667 9.867 8.173a7.549 7.549 0 0 1-2.59 5.54l1.83 3.654a.67.67 0 0 1-.596.965zm-5.287-3.795c.098 0 .195.022.283.063l3.55 1.653-1.213-2.422a.669.669 0 0 1 .198-.834 6.184 6.184 0 0 0 2.492-4.84c0-3.76-3.828-6.825-8.534-6.825-4.705 0-8.533 3.068-8.533 6.84 0 3.77 3.828 6.839 8.533 6.839a10.972 10.972 0 0 0 3.031-.446.699.699 0 0 1 .193-.028z"
		/>
	</svg>
);

export default SvgConversation;
