import {warn, inform, logInfo} from 'io/errors';
import * as nActions from 'modules/notifications/actions';
import * as confirmerActions from 'modules/confirmer/actions';
import {messages} from 'constants/loc';
import {shortDur, medDur, longDur} from 'constants/notifications';
import {formatMessageFallbacked} from 'utils/app';
import {describeError, describeThrow} from 'utils/errors';
import services from 'services';
import msgs from 'dicts/messages';
import {apiUrl} from 'constants/app';

let intl = null;
services.waitFor('intl').then(x => (intl = x));
let store = null;
services.waitFor('store').then(x => (store = x));
let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));

const history = services.get('history');

export const formatMessageRaw = id => {
	return store ? formatMessageFallbacked(id)(store.getState()) : messages[id] || id;
};

export const notify = (type, notificationSpec) => {
	if (!store) {
		return;
	}
	const action = {
		info: nActions.info,
		warning: nActions.warning,
		error: nActions.error,
		success: nActions.success,
	}[type];
	if (!action) {
		throw new Error('invalid notification type name');
	}
	store.dispatch(action(notificationSpec));
};

export const decorateWithNotifications = (spec, promise) => (getState, dispatch) => {
	const {
		id,
		loading,
		success,
		suppressFailure = false,
		failureStyle = 'warning',
		successStyle = 'success',
		failureDuration,
		successDuration,
		// this is offered for convenience - sometimes you may have a helper function and you want to disable the handling there because the parent function takes care of it already
		disableEverything = false,
	} = spec;
	if (disableEverything) {
		return promise;
	}
	if (loading) {
		dispatch(nActions.loading({id, message: loading}));
	}
	return promise
		.catch(e => {
			const suppress =
				typeof suppressFailure === 'function' ? suppressFailure(e) : suppressFailure;
			if (suppress) {
				logInfo(e);
				if (loading) {
					dispatch(nActions.remove(id));
				}
			} else {
				const style = typeof failureStyle === 'function' ? failureStyle(e) : failureStyle;
				const dur =
					typeof failureDuration === 'function' ? failureDuration(e) : failureDuration;
				// TODO: could consider letting the caller decide between "warn" and "inform"
				// prettier-ignore
				const doWarn =
					style === 'error' ?
						warn(nActions.error, {id, duration: dur || longDur}, e)
					: style === 'info' ?
						inform(nActions.info, {id, duration: dur || medDur}, e)
					:
						warn(nActions.warning, {id, duration: dur || medDur}, e);
				doWarn(getState, dispatch);
			}
			throw e;
		})
		.then(res => {
			const successMsg = typeof success === 'function' ? success(res) : success;
			if (successMsg) {
				const dur =
					typeof successDuration === 'function' ? successDuration(res) : successDuration;
				if (successStyle === 'info') {
					dispatch(nActions.info({id, message: successMsg, duration: dur || medDur}));
				} else {
					dispatch(
						nActions.success({id, message: successMsg, duration: dur || shortDur}),
					);
				}
			} else if (loading) {
				dispatch(nActions.remove(id));
			}
			return res;
		});
};

export const canAccessApp = (appName, user) => {
	const permissions = user?.permissions || [];
	if (permissions.find(p => p.slug === `access.${appName}`)) {
		return true;
	}

	return false;
};

export const ensureAccess = (appName, user) => (getState, dispatch) => {
	if (canAccessApp(appName, user)) {
		return Promise.resolve();
	}

	const e = describeError(
		intl.formatMessage({id: 'You do not have access to the application'}),
		new Error('No permissions'),
	);
	logInfo(e);

	return new Promise((resolve, reject) => {
		const onConfirmed = () => {
			history.push('/');

			reject(e);
		};
		dispatch(
			confirmerActions.show({
				message: e.description,
				cancelText: intl.formatMessage({id: msgs.ok}),
				onCancel: onConfirmed,
			}),
		);
	});
};

export const setPageTitleMessage = msgId => (getState, dispatch) => {
	const msg = formatMessageFallbacked(msgId)(getState());
	document.title = `${msg} - Eniopro`;
};

export const bindToBuildingPresenceChannel = channel => (getState, dispatch) => {
	let finished = false;

	return new Promise((resolve, reject) => {
		channel.bind('pusher:subscription_succeeded', () => {
			if (!finished) {
				const members = channel.members;
				const count = members.count;

				if (count > 1) {
					finished = true;

					const e = new Error('building already taken');
					e.description = intl.formatMessage({
						id: 'The requested building is reserved for another user',
					});
					e.causedByReservationFailure = true;

					reject(e);
				} else {
					finished = true;
					resolve();
				}
			}
		});

		channel.bind('pusher:subscription_error', status => {
			if (!finished) {
				const e = new Error('building reservation failed');
				e.description = intl.formatMessage({
					id: 'The requested building could not be booked',
				});
				e.causedByReservationFailure = true;

				reject(e);
			}
		});

		setTimeout(
			() => {
				if (!finished) {
					const e = new Error('building reservation timed out');
					e.description = intl.formatMessage({
						id: 'The requested building could not be booked',
					});
					e.causedByReservationFailure = true;

					reject(e);
				}
			},
			// if running a local backend pusher auth can be damn slow, so use this as an ugly fix
			apiUrl.includes('local.') ? 10e3 : 5e3,
		);
	});
};

export const getProfinderSearchFilters = type => {
	return httpJson('get', `/profinder/${type}/filters`)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(x => x.data)
		.catch(e => {
			warn(nActions.error, {id: 'profinder-filter-fetch-fail', duration: medDur}, e);
			throw e;
		});
};

export const createProfinderCallPoolResultList = ({
	callPoolId,
	queryOnly = false,
	filters = {},
	title = '',
	description = '',
}) => {
	const reqBody = {callPoolId, queryOnly, filters, title, description};
	return httpJson('post', '/profinder/createResultListForCallPool', {}, {body: reqBody})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(x => x.data)
		.catch(e => {
			warn(nActions.error, {id: 'profinder-list-create-fail', duration: medDur}, e);
			throw e;
		});
};

export const createProfinderCallPoolBloomList = ({
	callPoolListId,
	queryOnly = false,
	filters = {},
	title = '',
	description = '',
}) => {
	const reqBody = {
		profinderCallPoolListId: callPoolListId,
		queryOnly,
		filters,
		title,
		description,
	};
	return httpJson('post', '/profinder/createBloomListForCallPool', {}, {body: reqBody})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(x => x.data)
		.catch(e => {
			warn(nActions.error, {id: 'profinder-list-create-fail', duration: medDur}, e);
			throw e;
		});
};

export const purchaseProfinderList = ({callPoolListId}) => {
	const reqBody = {profinderCallPoolListId: callPoolListId};
	return httpJson(
		'post',
		'/profinder/purchaseListAndStartProcessing',
		{},
		{body: reqBody},
	)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(x => x.data)
		.catch(e => {
			warn(nActions.error, {id: 'profinder-list-create-fail', duration: medDur}, e);
			throw e;
		});
};

export const deleteProfinderList = ({callPoolListId}) => {
	return httpJson('delete', `/profinder/${callPoolListId}`, {}, {}).catch(
		describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})),
	);
};
