import {effect} from 'utils/redux';
import {catchNonFatalDefault} from 'io/errors';
import namespace from './namespace';
import * as actions from './actions';

import * as commonSelectors from 'modules/common/selectors';
import {appName} from '../constants';
import {ensureAccess, setPageTitleMessage, decorateWithNotifications} from 'io/app';
import services from 'services';
import {getBuildings} from './io';

const creator = effect(namespace);

const history = services.get('history');

export let initialize = () => (getState, dispatch) => {
	setPageTitleMessage('Calls [app]')(getState, dispatch);

	ensureAccess(appName, commonSelectors.user(getState()))(getState, dispatch)
		.then(() => {
			dispatch(actions._initialize());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let searchBuildings =
	({text, callback}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'get-users',
				failureStyle: 'warning',
			},
			getBuildings({
				_q: text,
				_limit: '20',
				include: 'clients',
			}),
		)(getState, dispatch)
			.then(buildings => {
				callback(buildings);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
searchBuildings = creator('searchBuildings', searchBuildings);

export let navigateToBuilding = buildingId => (getState, dispatch) => {
	history.push(`/calls/buildings/${buildingId}`);
};
navigateToBuilding = creator('navigateToBuildings', navigateToBuilding);
