import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {createSelector} from 'reselect';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {formatUrlQuery} from './utils';
const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const processing = pipe(root, prop('processing'));

export const noticesQuery = pipe(root, prop('noticesQuery'));

export const notices = pipe(root, prop('notices'));

export const noticesLoading = pipe(root, prop('noticesLoading'));

export const noticesPagination = pipe(root, prop('noticesPagination'));

export const noticeCreatorOpen = pipe(root, prop('noticeCreatorOpen'));

export const organizations = pipe(root, prop('organizations'));

export const roles = pipe(root, prop('roles'));

const initValues = pipe(root, prop('noticeFormInitValues'));

export const noticeFormInitValues = createSelector([initValues], initValues => {
	const organizations = initValues.organizations.length
		? initValues.organizations.map(o => o.id)
		: [];
	const teams = initValues.teams.length ? initValues.teams.map(t => t.id) : [];
	const roles = initValues.roles.length ? initValues.roles.map(r => r.id) : [];

	return {
		title: initValues.title,
		content: initValues.content,
		startDate: initValues.startDate ? initValues.startDate.substring(0, 10) : null,
		organizations,
		teams,
		roles,
	};
});

export const noticeInEdit = pipe(root, prop('noticeInEdit'));

export const urlQuery = createSelector([noticesQuery], noticesQuery => {
	return formatUrlQuery({noticesQuery});
});
