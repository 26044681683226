// core dependencies
import React, {Component} from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
// custom helpers & utils
import {FormattedMessage} from '@meiko/react-intl';
import {
	screenSmMax,
	screenLgMin,
	fontSizeS,
	fontWeightRegular,
	borderColorBase,
} from 'styles/constants';
// components
import H1 from 'components/generic/H1';
import ContentLoader from 'components/generic/ContentLoader';
import RouterLink from 'components/generic/RouterLink';
import ContentEmpty from 'components/generic/ContentEmpty';
import Notice from './Notice';
import TextButton from 'components/generic/TextButton';

const Feed = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: 30px 40px;
	overflow: auto;
	justify-content: flex-start;
	background-color: white;

	@media only screen and (max-width: ${screenSmMax}) {
		padding: 20px 10px;
		max-width: 100%;
	}

	@media only screen and (min-width: ${screenLgMin}) {
		padding-right: 60px;
		padding-left: 60px;
	}
`;

const FeedTitle = styled(H1)`
	margin: 0px;
`;

const AdminLink = styled(RouterLink)`
	margin-left: 10px;
	font-size: ${fontSizeS};
	font-weight: ${fontWeightRegular};
`;

const ButtonContainer = styled.div`
	border-top: 1px solid ${borderColorBase};
	border-bottom: 1px solid ${borderColorBase};

	margin: 0px -40px -30px -40px;
	@media (max-width: ${screenSmMax}) {
		margin: 0px -10px -20px -10px;
	}
	@media (min-width: ${screenLgMin}) {
		margin-left: -60px;
		margin-right: -60px;
	}
`;

const ShowMoreButton = styled(TextButton)`
	padding: 16px;
	text-align: center;
	width: 100%;
`;

const LoaderContainer = styled.div`
	margin-top: 10px;
	padding: 5px 0px;
	display: block;
	text-align: center;
`;

const FeedHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 26px;
`;

class Notices extends Component {
	state = {amountShown: 5};

	currentDate = new Date();

	render() {
		const {
			organization,
			user,
			notices,
			noticesPagination = {},
			noticesLoading,
			getNotices,
			appPerms,
		} = this.props;

		const {totalPages, currentPage} = noticesPagination;

		return (
			<Feed>
				<FeedHeader>
					<FeedTitle>
						<FormattedMessage
							id="{organization} - Internal releases"
							values={{organization: organization}}
						/>
					</FeedTitle>
					{appPerms.has('notices') && (
						<AdminLink to="/notices">
							<FormattedMessage id="Control" />
						</AdminLink>
					)}
				</FeedHeader>
				{!notices.length && noticesLoading ? (
					<LoaderContainer>
						<ContentLoader />
					</LoaderContainer>
				) : !notices.length ? (
					<ContentEmpty message="No notices" />
				) : (
					notices.map(n => (
						<Notice
							key={n.id}
							date={n.startDate}
							header={n.title}
							content={n.content}
							userLogin={user.lastLogin}
						/>
					))
				)}
				{notices && currentPage < totalPages && (
					<ButtonContainer>
						<ShowMoreButton
							disabled={noticesLoading}
							onClick={e => {
								e.stopPropagation();
								getNotices(currentPage + 1);
							}}
						>
							{noticesLoading ? (
								<ContentLoader size={16} noPadding />
							) : (
								<FormattedMessage id="Show more" />
							)}
						</ShowMoreButton>
					</ButtonContainer>
				)}
			</Feed>
		);
	}
}

Notices.propTypes = {
	notices: propTypes.array,
	noticesPagination: propTypes.object,
	noticesLoading: propTypes.bool,
	getNotices: propTypes.func,
	user: propTypes.object,
	organization: propTypes.string,
	appPerms: propTypes.object,
};
export default Notices;
