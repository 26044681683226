export const CUSTOM = 'custom';
export const CALLS_CONFIRMATION = 'calls.confirmation';
export const D2D_CONFIRMATION = 'd2d.confirmation';
export const CALENDAR_VISIT_BOOKED = 'calendar.visitBooked';
export const CALENDAR_VISIT_RESCHEDULED = 'calendar.visitRescheduled';
export const CALENDAR_VISIT_CANCELLED = 'calendar.visitCancelled';

export const smsTemplatesConfig = [
	{
		key: CALLS_CONFIRMATION,
		variables: ['date'],
	},
	{
		key: D2D_CONFIRMATION,
		variables: ['date'],
	},
	{
		key: CALENDAR_VISIT_BOOKED,
		variables: [
			'dateFrom',
			'dateTo',
			'customerName',
			'customerAddress',
			'previousDateTo',
			'previousDateFrom',
		],
	},
	{
		key: CALENDAR_VISIT_RESCHEDULED,
		variables: [
			'dateFrom',
			'dateTo',
			'customerName',
			'customerAddress',
			'previousDateTo',
			'previousDateFrom',
		],
	},
	{
		key: CALENDAR_VISIT_CANCELLED,
		variables: [
			'dateFrom',
			'dateTo',
			'customerName',
			'customerAddress',
			'previousDateTo',
			'previousDateFrom',
		],
	},
	{
		key: CUSTOM,
		variables: [],
	},
];
