import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}
		case actions.searchCallLogs.type: {
			return [{...state}, effects.searchCallLogs()];
		}
		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}
		case actions._setCallLogs.type: {
			return [{...state, callLogs: payload}];
		}
		case actions._setTimeEntries.type: {
			return [{...state, timeEntries: payload}];
		}
		case actions._setUsers.type: {
			// Here we check that user has not already been added to the users list.
			// const uniqueUsers = payload.filter(
			// 	newUser => !state.users.some(existingUser => existingUser.id === newUser.id),
			// );
			return [{...state, users: payload}];
		}
		case actions.setTeamType.type: {
			return [{...state}, effects.setTeamType(payload)];
		}
		case actions._setTeamType.type: {
			return [{...state, teamType: payload}, null];
		}
		case actions._setOrganizations.type: {
			return [{...state, organizations: payload}];
		}
		case actions.getTeams.type: {
			return [{...state}, effects.getTeams()];
		}
		case actions._setTeams.type: {
			return [{...state, teams: payload}];
		}
		case actions.fetchTeamWithUsers.type: {
			return [{...state}, effects.fetchTeamWithUsers(payload)];
		}
		case actions.fetchTeamsWithUsers.type: {
			return [{...state}, effects.fetchTeamsWithUsers(payload)];
		}
		case actions._setTrackingData.type: {
			return [{...state, trackingData: payload}];
		}
		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
