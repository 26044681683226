import {action} from 'utils/redux';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const toggleSettingsModal = creator('toggleSettingsModal');

export const createSetting = creator('createSetting');

export const updateSetting = creator('updateSetting');

export const removeSetting = creator('removeSetting');

export const setShowDeleted = creator('setShowDeleted');

export const restoreSetting = creator('restoreSetting');

export const openUploadModal = creator('openUploadModal');

export const closeUploadModal = creator('closeUploadModal');

export const uploadExcel = creator('uploadExcel');

export const _addToSettings = creator('_addToSettings');

export const _updateToSettings = creator('_updateToSettings');

export const _initialize = creator('_initialize');

export const _updateLists = creator('_updateLists');

export const _setCategories = creator('_setCategories');

export const _setChannels = creator('_setChannels');

export const _setCustomerTypes = creator('_setCustomerTypes');

export const _setSources = creator('_setSources');

export const _setTags = creator('_setTags');

export const _setServiceMappings = creator('_setServiceMappings');

export const _setProducts = creator('_setProducts');

export const _setOrganizations = creator('_setOrganizations');

export const _opFailed = creator('_opFailed');

export const _excelUploaded = creator('_excelUploaded');
