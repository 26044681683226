import {pick, evolve} from 'ramda';

export const computeCondosQuery = ({filterForm, sortForm}) => {
	return {
		...pick(
			[
				'address',
				'zip',
				'city',
				'county',
				'companyName',
				'manufacturingYearStart',
				'manufacturingYearEnd',
				'latestActivityUserId',
			],
			filterForm,
		),
		_sort: sortForm.sort,
	};
};

export const formatCondosUrlQuery = query => {
	return {
		...query,
		_sort: query._sort ? `${query._sort}:asc` : '',
	};
};

export const parseCondosUrlQuery = query => {
	return evolve(
		{
			manufacturingYearStart: x => (x ? Number(x) : null),
			manufacturingYearEnd: x => (x ? Number(x) : null),
			_sort: x => (x ? x.split(':')[0] : null),
			latestActivityUserId: x => (x ? Number(x) : null),
		},
		query,
	);
};
