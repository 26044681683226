import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {createSelector} from 'reselect';
import {formatUrlQuery} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const processing = pipe(root, prop('processing'));

export const bans = pipe(root, prop('bans'));

export const bansLoading = pipe(root, prop('bansLoading'));

export const bansPagination = pipe(root, prop('bansPagination'));

export const bansType = pipe(root, prop('bansType'));

export const bansQuery = pipe(root, prop('bansQuery'));

export const buildingsBansLoading = pipe(root, prop('buildingsBansLoading'));

export const buildingsBans = pipe(root, prop('buildingsBans'));

export const buildingsBansPagination = pipe(root, prop('buildingsBansPagination'));

export const urlQuery = createSelector([bansQuery], bansQuery => {
	return formatUrlQuery({bansQuery});
});

export const banModalOpen = pipe(root, prop('banModalOpen'));

export const activeBan = pipe(root, prop('activeBan'));

export const banFormInitialValues = pipe(root, prop('banFormInitialValues'));
