import {any, contains, evolve} from 'ramda';
import {teamLeaders} from 'modules/calendarApp/common/constants';
import {getHoursMinutesFromIsoString} from 'utils/time';
import services from 'services';
import {parseNullableNumber, parseNullableString} from 'utils/fields';
import initState from './state';
import {replace} from 'utils/objects';
import * as teDicts from 'dicts/timeEntries';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const isWorkingType = type => {
	return Object.keys(teDicts.workTypes).find(t => t === type);
};

export const formatTimeEntryFormOutput = ({form, userId}) => {
	const startTime = isWorkingType(form.type)
		? new Date(Date.parse(form.date + 'T' + form.startTime + ':00')).toISOString()
		: new Date(Date.parse(form.date + 'T12:00:00'));

	const endTime = isWorkingType(form.type)
		? new Date(Date.parse(form.date + 'T' + form.endTime + ':00')).toISOString()
		: new Date(Date.parse(form.date + 'T12:00:01'));

	return {
		startTime,
		endTime,
		comment: form.comment,
		type: form.type,
		userId,
		perDiem: form.perDiem,
		kmStart: form.kmStart,
		kmEnd: form.kmEnd,
		organizationId: form.organizationId,
	};
};

export const formatEditorInput = entry => {
	if (!entry) return {};

	return {
		date: entry.startTime.split('T')[0],
		startTime: getHoursMinutesFromIsoString(entry.startTime),
		endTime: getHoursMinutesFromIsoString(entry.endTime),
		type: entry.type,
		comment: entry.comment,
		perDiem: entry.perDiem,
		kmStart: entry.kmStart,
		kmEnd: entry.kmEnd,
		kmTotal: entry.kmTotal,
		organizationId: entry.organizationId,
	};
};

export const formatTimeEntriesSearchInput = () => {
	const date = new Date();
	const start = new Date(date.getFullYear(), date.getMonth(), 1, 12)
		.toISOString()
		.split('T')[0];
	const end = new Date(date.getFullYear(), date.getMonth() + 1, 0, 12)
		.toISOString()
		.split('T')[0];

	return {
		start,
		end,
	};
};

// PERM: manage-team-users-work-time
export const isTeamLeaderUser = user =>
	any(r => contains(r.slug, teamLeaders), user.roles);

// takes multiple timeEntries and returns all comments in one string seperated by comma (,)
export const getComments = entries => {
	let comments = [];

	entries.map(c => {
		c.comment !== null && comments.push(c.comment);
		return null;
	});
	const joined = comments.length > 1 ? comments.join(', ') : comments[0];

	return joined;
};

export const getWorkingTimeString = timeEntry => {
	const timeEntryEndTime = timeEntry.active
		? intl.formatMessage({id: 'Active'})
		: getHoursMinutesFromIsoString(timeEntry.endTime);

	return isWorkingType(timeEntry.type)
		? `${getHoursMinutesFromIsoString(timeEntry.startTime)} - ${timeEntryEndTime}`
		: '';
};

export const parseUrlQuery = query => {
	const vals = evolve(
		{
			start: parseNullableString,
			end: parseNullableString,
			userId: parseNullableNumber,
		},
		query,
	);

	return {timeEntriesQuery: replace(vals, initState.timeEntriesQuery)};
};
