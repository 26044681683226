import {scopedHandler} from 'utils/redux';
import {getFormValues} from 'redux-form';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {formatTeamFormOutput} from './utils';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		case actions._updateTeamsQuery.type: {
			const newState = {
				...state,
				teamsLoading: true,
				teamsQuery: {...state.teamsQuery, ...payload},
			};

			return [newState, null];
		}

		case actions.changePage.type: {
			const newState = {
				...state,
				teamsQuery: {...state.teamsQuery, _page: payload},
				teamsLoading: true,
			};

			return [newState, effects.updateTeams()];
		}

		case actions.toggleTeamCreator.type: {
			const newState = {
				...state,
				selectedTeam: payload,
				teamCreatorOpen: !state.teamCreatorOpen,
			};

			return [newState, null];
		}

		case actions.openTeamAreasSelector.type: {
			return [{...state, teamAreasSelectorOpen: true}, effects.initTeamAreasMap()];
		}

		case actions.closeTeamAreasSelector.type: {
			const newState = {
				...state,
				teamAreasSelectorOpen: false,
				teamAreasSelectorSearch: '',
				teamAreasSelectorSuggestionsOpen: false,
				teamAreasSelectorSuggestions: [],
			};
			return [newState, null];
		}

		case actions.setTeamAreasSelectorType.type: {
			return [state, effects.setTeamAreasMapType(payload)];
		}

		case actions.setTeamAreasSelectorSearch.type: {
			return [{...state, teamAreasSelectorSearch: payload}, null];
		}

		case actions.openTeamAreasSelectorSuggestion.type: {
			return [state, effects.openTeamAreasSelectorSuggestion(payload)];
		}

		case actions.saveTeam.type: {
			const form = getFormValues('teamForm')(fullState) || {};
			const {team: teamData} = formatTeamFormOutput({form});

			const team = {...teamData, id: state.selectedTeam ? state.selectedTeam.id : null};

			return [{...state, teamCreatorOpen: false}, effects.saveTeam({id: team.id, team})];
		}

		case actions.removeTeam.type: {
			const values = getFormValues('teamForm')(fullState) || {};
			const id = values.id;
			return [state, effects.removeTeam(id)];
		}

		case actions._toggleTeamCreator.type: {
			return [{...state, teamCreatorOpen: !state.teamCreatorOpen}, null];
		}

		case actions._setTeams.type: {
			const {data, pagination} = payload;
			const newState = {
				...state,
				teams: data,
				teamsPagination: pagination,
				teamsLoading: false,
			};
			return [newState, null];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false, teamCreatorOpen: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
