import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import initState from './state';

export const bansInclude = 'user,organization';

export const buildingsBansInclude = 'latestBanLog.user,latestBanLog.organization';

export const parseUrlQuery = query => {
	const vals = evolve({_page: Number}, query);
	return {bansQuery: replace(vals, initState.bansQuery)};
};

export const formatUrlQuery = ({bansQuery}) => bansQuery;
