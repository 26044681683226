import styled from 'styled-components';
import Navbar from './Navbar';
import Page from './Page';

export default styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	> ${Navbar} {
		flex-shrink: 0;
	}

	> ${Page} {
		overflow: auto;
		flex-grow: 1;
	}
`;
