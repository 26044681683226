import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const loadingCallPools = pipe(root, prop('loadingCallPools'));

export const callPools = pipe(root, prop('callPools'));

export const automatedFetching = pipe(root, prop('automatedFetching'));

export const refreshRate = pipe(root, prop('refreshRate'));

export const processingAreaId = pipe(root, prop('processingAreaId'));

export const processingCallpoolId = pipe(root, prop('processingCallpoolId'));

export const callPoolAreaModalOpen = pipe(root, prop('callPoolAreaModalOpen'));

export const selectedCallPool = pipe(root, prop('selectedCallPool'));

export const allAreas = pipe(root, prop('allAreas'));

export const allAreasLoading = pipe(root, prop('allAreasLoading'));

export const processing = pipe(root, prop('processing'));

export const callPoolLoading = pipe(root, prop('callPoolLoading'));
