import {state as lifecycle} from 'fragments/lifecycle';

const date = new Date();

export default {
	...lifecycle,
	loading: true,
	report: null,
	reportQuery: {
		startDate: new Date(date.getFullYear(), date.getMonth(), 1, 12)
			.toISOString()
			.split('T')[0],
		endDate: new Date().toISOString().split('T')[0],
		timespan: 'week',
	},
};
