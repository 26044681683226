import styled from 'styled-components';
import ButtonRadioLabel from './ButtonRadioLabel';

// note: this component should only include the children Input and ButtonRadioLabel, in that order (not nested)

const ButtonRadio = styled.span`
	display: inline-block;

	> input {
		display: none;
	}

	> ${ButtonRadioLabel} {
		border-radius: inherit;
	}
`;

export default ButtonRadio;
