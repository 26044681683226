import {FormattedMessage} from '@meiko/react-intl';
import H3 from 'components/generic/H3';
import styled from 'styled-components';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';
import React, {useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import memoize from 'memoize-one';
import {activeOrganization, user} from 'modules/common/selectors';
import {borderColorBase, screenMdMin} from 'styles/constants';
import {applyDispatch, applyState} from 'utils/redux';
import {
	initialize as initializeLauncher,
	destroy as destroyLauncher,
	getNotices,
} from 'modules/launcher/actions';
import {notices, noticesPagination, noticesLoading} from 'modules/launcher/selectors';
import {userAppPermissions, isSubcontractorUser, isThirdPartyUser} from 'utils/perms';
import SideDrawer, {
	SideDrawerHeader,
	SideDrawerContent,
} from 'components/generic/SideDrawer';
import NoticesFeed from 'views/Launcher/components/NoticesFeed';
import Button from 'components/generic/Button';

const StyledSideDrawer = styled(SideDrawer)`
	position: fixed;
	left: auto;
	right: ${({isOpen}) => (isOpen ? '0' : '-100%')};
	z-index: 100;
	width: 100%;
	@media screen and (min-width: ${screenMdMin}) {
		width: 500px;
		right: ${({isOpen}) => (isOpen ? '0px' : '-100%')};
	}
	border-left: 1px solid ${borderColorBase};
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	overflow-y: scroll;
`;

const CloseButton = styled(Button)`
	background: transparent;
`;

const getAppPerms = memoize(userAppPermissions);

const NoticesSideDrawer = ({
	initializeLauncher,
	destroyLauncher,
	user,
	activeOrganization,
	notices,
	noticesPagination,
	noticesLoading,
	getNotices,
	isOpen,
	setIsOpen,
}) => {
	useEffect(() => {
		initializeLauncher();
		return () => destroyLauncher();
	}, []);
	const wrapperRef = useRef(null);
	useClickOutsideComponent(wrapperRef, () => {
		setIsOpen(false);
	});

	const appPerms = getAppPerms(user);
	const isSubcontractor = isSubcontractorUser(user);
	const isThirdParty = isThirdPartyUser(user);
	return (
		<StyledSideDrawer ref={wrapperRef} isOpen={isOpen} onClose={() => setIsOpen(false)}>
			<SideDrawerHeader>
				<H3>
					<FormattedMessage id="Internal releases" />
				</H3>
				<CloseButton onClick={() => setIsOpen(false)}>
					<i className="fa fa-times" />
				</CloseButton>
			</SideDrawerHeader>

			<SideDrawerContent>
				{/* TODO
							{!isSubcontractor && !isThirdParty && (
							 */}

				{!isSubcontractor && !isThirdParty && (
					<NoticesFeed
						organization={activeOrganization.title}
						notices={notices}
						noticesPagination={noticesPagination}
						noticesLoading={noticesLoading}
						getNotices={getNotices}
						user={user}
						appPerms={appPerms}
					/>
				)}
			</SideDrawerContent>
		</StyledSideDrawer>
	);
};

export default compose(
	connect(
		applyState({
			activeOrganization,
			user,
			notices,
			noticesPagination,
			noticesLoading,
		}),
		applyDispatch({
			initializeLauncher,
			destroyLauncher,
			getNotices,
		}),
	),
)(NoticesSideDrawer);
