import React from 'react';
import {Field as ReduxField} from 'redux-form';
import propTypes from 'prop-types';

class Field extends React.Component {
	// cache the "component" prop on initial render. this enables using render functions without having to define them outside of the parent render function (otherwise inputs would run into issues with unnecessary re-renders and loss of focus each time the parent renders and the component function gets re-created). the downside is that all later changes to the component function will have no effect. this means that using variable closures in the function definition won't work - variables need to be passed explicitly to the function as props instead!
	component = this.props.component;

	// cache inputId on initial render, no need for it to be changeable dynamically
	inputId = `field_${this.props.name}${this.props.value ? `-${this.props.value}` : ''}`;

	renderComponent = ({input, meta, type, ...rest}) => {
		// inject the "type" prop into input props, because redux-form needs it and it's basically always an input prop like any other
		const inputProps = type !== undefined ? {...input, type} : input;
		return React.createElement(this.component, {
			input: inputProps,
			meta,
			inputId: this.inputId,
			...rest,
		});
	};

	render() {
		return <ReduxField {...this.props} component={this.renderComponent} />;
	}
}

Field.propTypes = {
	component: propTypes.any,
	name: propTypes.string,
	value: propTypes.any,
};

export default Field;
