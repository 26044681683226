import services from 'services';
import {map} from 'ramda';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {mapResponseData, getResponseData} from 'utils/app';
import {callReminderInclude} from './constants';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getCallReminders = query =>
	httpJson('get', '/callReminders', {
		include: callReminderInclude,
		_sortDirection: 'desc',
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.callReminder)));

export const putCallReminder = callReminder =>
	httpJson(
		'put',
		`/callReminders/${callReminder.id}`,
		{include: callReminderInclude},
		{body: callReminder},
	)
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save reminder'})))
		.then(getResponseData(normalize.callReminder));
