import {locale} from 'constants/app';
import {filter, ascend, descend} from 'ramda';
import {addDays} from 'utils/time';
import {sort, chainComparators} from 'utils/arrays';

const rankSeSource = source => (source === 'Bisnode' ? 0 : 1);

// note: bisnode is (temporarily?) favored over other sources in Sweden (leadcloud has given us bad data)
const sourceComparators = locale === 'se' ? [ascend(x => rankSeSource(x.source))] : [];

// note: expects a non-empty client list
export const getPreferredCallClient = (callPool, clients) => {
	const minYear = callPool.meta.birthYearStart;
	const maxYear = callPool.meta.birthYearEnd;

	const rankBirthYear = birthYear => {
		const ok =
			!birthYear ||
			((!minYear || birthYear >= minYear) && (!maxYear || birthYear <= maxYear));
		return ok ? 0 : 1;
	};

	const sortedClients = sort(
		chainComparators(
			ascend(x => rankBirthYear(x.birthYear)),
			...sourceComparators,
			descend(x => x.createdAt),
		),
		clients,
	);

	return sortedClients[0];
};

export const getCallableClient = ({callPool, building, clients, encounters}) => {
	// no suitable pool
	if (!callPool.meta.autoCall && !callPool.meta.fullyAutoCall) return null;
	// wrong building state
	if (building.encounterState !== 'takeContact') return null;
	const latestEnc = encounters[0];
	// just encountered
	if (
		latestEnc &&
		latestEnc.state !== 'notReached' &&
		addDays(latestEnc.date, 1.5) > new Date()
	) {
		return null;
	}

	const callableClients = filter(c => c.phone, clients);
	if (!callableClients.length) return null;

	return getPreferredCallClient(callPool, callableClients);
};
