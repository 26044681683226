import React, {useEffect, useState} from 'react';
import {FormattedMessage, injectIntl} from '@meiko/react-intl';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {applyDispatch, applyState} from 'utils/redux';
import Panel from 'components/generic/Panel';
import Content from 'components/generic/Content';
import PanelHeaderTitle from 'components/generic/ModalHeaderTitle';
import NoticesSideDrawer from 'components/views/NoticesSideDrawer';
import {colors, screenMdMax, screenMdMin} from 'styles/constants';
import {buttonUnstyled, h1} from 'styles/fragments';
// import Dl from 'components/generic/Dl';
import SalesChart from './SalesChart';
import OffersChart from './OffersChart';
import MeetingsByCustomerChart from './MeetingsByCustomerChart';
import SalesPipelineChart from './SalesPipelineChart';
import SalesBySourcesChart from './SalesFromSourcesChart';
import TopList from './TopList';
import {evolve, isNil, uniq} from 'ramda';
import DashboardFilterForm from './DashboardFilterForm';
import {
	user,
	activeOrganization,
	isSalesmanagerUser,
	isSalesLeaderUser,
	isAdminUser,
} from 'modules/common/selectors';
import {
	initialize,
	destroy,
	getDashboard,
	getTeams,
} from 'modules/dashboardApp/dashboardPage/actions';
import {notices} from 'modules/launcher/selectors';
import {
	dashboard,
	processing,
	teams,
	unacknowledgedVisitsCount,
} from 'modules/dashboardApp/dashboardPage/selectors';
import useQueryParams from 'hooks/queryParams';
import LoaderOverlay from 'components/generic/LoaderOverlay';
import {normalizeDashboardFilterFormValues} from 'modules/dashboardApp/dashboardPage/util';
import {getQuery} from 'io/history';
import Table from 'components/generic/Table';
import Button from 'components/generic/Button';
import {
	isSubcontractorUser,
	isThirdPartyUser,
	userCanShowDashboardOrganizations,
} from 'utils/perms';
import NavbarLeftMenu from 'components/generic/_NavbarLeftMenu';
import {getPageDefs} from 'components/generic/Navbar';
import DefaultNavbar from 'components/views/DefaultNavbar';
import Link from 'components/generic/Link';
import {encodeQuery} from 'utils/url';
import Modal from 'components/generic/Modal';
import ContentUnpad from 'components/generic/ContentUnpad';
import HorizontalScroll from 'components/generic/HorizontalScroll';
import messages from 'dicts/messages';
import DashboardTargetsPage from 'views/UsersApp/DashboardTargetsPage';
import {canAccessApp} from 'io/app';

const PanelWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	flex: 1 1 0%;

	@media (min-width: ${screenMdMin}) {
		flex-direction: row;
		gap: 30px;
		padding: 0px;

		> div:nth-child(1) {
			width: 25%;
			flex-shrink: 1;
		}

		> div:nth-child(2) {
			width: 50%;
			flex-shrink: 1;
		}

		> div:nth-child(3) {
			width: 25%;
			flex-shrink: 1;
		}
	}
}
`;

const formatPercentage = (percentage, emptyValue = '-') => {
	return !isNil(percentage) ? `${(percentage * 100).toFixed(0)}%` : emptyValue;
};

const SmallPanel = styled(Panel)`
	margin: 0;
	h1,
	h3 {
		text-align: center;
	}
`;

const BigText = styled.p`
	${h1}
	text-align: center;
	margin: 30px 0 20px 0;
`;

const UnstyledButton = styled(Button)`
	${buttonUnstyled}
	width: 100%;
	background: transparent !important;
	&:hover {
		cursor: pointer;
	}
`;

const NavbarWrapper = styled.div`
	display: none;
	@media screen and (min-width: ${screenMdMin}) {
		z-index: 1;
		width: 80px;
		bottom: 0;
		overflow-x: hidden;
		overflow-y: scroll;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-items-center;
		height: 100%;
	}
`;

const FilterWrapper = styled.div`
	background: white;
	border-bottom: 1px solid ${colors.grey7};
	padding: 20px;
`;

const DashboardTargetsSettingsModal = ({show, onClose}) => {
	return (
		<>
			{show && (
				<Modal
					hide={onClose}
					maxWidth={screenMdMax}
					verticalPadding
					headerTitle={
						<PanelHeaderTitle>
							<FormattedMessage id="DashboardTargets" />
						</PanelHeaderTitle>
					}
					cancelButton={
						<Button onClick={() => onClose()}>
							<FormattedMessage id={messages.close} />
						</Button>
					}
				>
					<ContentUnpad>
						<HorizontalScroll>
							<DashboardTargetsPage showNavigation={false} />
						</HorizontalScroll>
					</ContentUnpad>
				</Modal>
			)}
		</>
	);
};

const DashboardPage = ({
	intl,
	user,
	activeOrganization,
	initialize,
	destroy,
	dashboard,
	teams,
	processing,
	getTeams,
	getDashboard,
	notices,
	pathname,
	isAdminUser,
	isSalesmanagerUser,
	isSalesLeaderUser,
	unacknowledgedVisitsCount,
	history,
}) => {
	useEffect(() => {
		initialize();
		getTeams({getAllTeams: true});

		return () => {
			destroy();
		};
	}, []);

	const [isNoticesOpen, setIsNoticesOpen] = useState(false);

	/**
	 *Jos käyttäjän rooli on Myyntipäällikkö, käyttäjän oma tiimi on lukittu siihen ja voi nähdä oman tiimin alla olevat myyjät ja vaihtaa niitä.
	 Jos rooli on Myyntijohtaja tai Aluemyyntijohtaja, tai Admin niin voi muokata ja nähdä kaikki tiimit ja kaikki käyttäjät.
	 */
	//
	const canChangeUser = isAdminUser || isSalesLeaderUser || isSalesmanagerUser;
	const canChangeTeam = isAdminUser || isSalesLeaderUser;
	const canChangeOrganization = userCanShowDashboardOrganizations(user);

	const validateParams = params =>
		evolve(
			{
				organizationId: organizations => {
					if (canChangeOrganization) {
						return uniq([activeOrganization.id, ...organizations]);
					}

					return [activeOrganization.id];
				},
				teamId: teamIds => {
					if (canChangeTeam) {
						return teamIds;
					}
					// TODO: what is the 'active' team
					const team = user?.teams[0]?.id ?? null;
					return team ? [team] : [];
				},
				userId: users => {
					if (canChangeUser) {
						return users;
					}
					return [user.id];
				},
			},
			params,
		);

	const {params, setParams} = useQueryParams(
		validateParams(normalizeDashboardFilterFormValues(getQuery())),
	);
	const availableTeams = teams.filter(t =>
		params.organizationId.includes(t.organizationId),
	);

	useEffect(() => {
		getDashboard(params);
	}, [params]);

	const data = dashboard;

	const isSubcontractor = isSubcontractorUser(user);
	const isThirdParty = isThirdPartyUser(user);

	const {pageDefs, activeApp} = getPageDefs(
		user,
		'dashboard',
		pathname,
		activeOrganization,
	);

	const canEditDashboardTargets = user
		? canAccessApp('users', user) || canAccessApp('users.dashboard-targets', user)
		: false;

	const [dashboardTargetsModalOpen, setDashboardTargetsModalOpen] = useState(false);

	return (
		<div
			style={{
				height: '100vh',
				width: '100vw',
				display: 'flex',
				flexDirection: 'column',
				overflow: 'hidden',
			}}
		>
			<DefaultNavbar />
			<NoticesSideDrawer isOpen={isNoticesOpen} setIsOpen={setIsNoticesOpen} />
			{canEditDashboardTargets && (
				<DashboardTargetsSettingsModal
					show={dashboardTargetsModalOpen}
					onClose={setDashboardTargetsModalOpen}
				/>
			)}
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					flex: '1 1 0%',
					overflow: 'hidden',
				}}
			>
				<NavbarWrapper>
					<NavbarLeftMenu
						showMainMenu={false}
						condensed={true}
						pageDefs={pageDefs}
						activeApp={activeApp}
						onItemSelect={() => null}
						user={user}
					/>
					{/* <NavbarLeftBackground /> */}
				</NavbarWrapper>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						flex: '1 1 0%',
						overflow: 'scroll',
					}}
				>
					<div>
						<FilterWrapper>
							<DashboardFilterForm
								initialValues={params}
								onSubmit={params => setParams(validateParams(params))}
								teams={availableTeams}
								organizations={user.organizations.map(o => ({
									...o,
									isFixed: o.id === activeOrganization.id,
								}))}
								canChangeUser={canChangeUser}
								canChangeTeam={canChangeTeam}
								canChangeOrganization={canChangeOrganization}
								onEditDashboardTargets={() => setDashboardTargetsModalOpen(true)}
								canEditDashboardTargets={canEditDashboardTargets}
							/>
						</FilterWrapper>
					</div>
					<LoaderOverlay loading={processing}>
						<Content>
							<PanelWrapper>
								<div>
									{!isSubcontractor && !isThirdParty && (
										<>
											<SmallPanel
												header={
													<UnstyledButton
														onClick={e => {
															e.stopPropagation();
															setIsNoticesOpen(true);
														}}
													>
														<PanelHeaderTitle textAlign="center">
															<FormattedMessage id="Internal releases" />
														</PanelHeaderTitle>
													</UnstyledButton>
												}
											>
												<Table slimRows>
													<tbody>
														{notices.slice(0, 5).map(n => (
															<tr key={n.id}>
																<td>
																	<UnstyledButton
																		onClick={e => {
																			e.stopPropagation();
																			setIsNoticesOpen(true);
																		}}
																	>
																		{n.title}
																	</UnstyledButton>
																</td>
															</tr>
														))}
													</tbody>
												</Table>
											</SmallPanel>
										</>
									)}
									{/* Tehtävälista */}
									<SmallPanel
										header={
											<PanelHeaderTitle textAlign="center">
												<FormattedMessage id="Taskslist" />
											</PanelHeaderTitle>
										}
									>
										<Table slimRows>
											<tbody>
												<tr>
													<td>
														<Link
															target="_blank"
															href={`http://localhost:3000/leads/leads${encodeQuery({
																_page: 1,
																salesmanagerLeads: false,
															})}`}
														>
															<FormattedMessage id="Leads" />
														</Link>
													</td>
												</tr>
												<tr>
													<td>
														<Link
															target="_blank"
															href={`calendar/salesman-visits${encodeQuery({
																_page: 1,
																state: 'unvisited',
																dateFrom: '',
																dateTo: '',
																countyId: '',
																cityId: '',
																userId: user.id,
																cancelled: false,
																reservationSources: '',
																unacknowledged: true,
															})}`}
														>
															<FormattedMessage id="Unacknowledged [plural]" />{' '}
															{intl
																.formatMessage({id: 'Salesman visits [logs]'})
																.toLowerCase()}{' '}
															{unacknowledgedVisitsCount
																? `(${unacknowledgedVisitsCount})`
																: ''}
														</Link>
													</td>
												</tr>
											</tbody>
										</Table>
									</SmallPanel>
									{/* Käynnit ja toteuma */}
									<SmallPanel
										header={
											<PanelHeaderTitle textAlign="center">
												<FormattedMessage id="Visits and realization %" />
											</PanelHeaderTitle>
										}
									>
										<BigText>
											{data?.visitsAndSuccessful.visits} <FormattedMessage id="pcs" /> /{' '}
											{formatPercentage(data?.visitsAndSuccessful?.successfulPercentage)}
										</BigText>
										{/*
										<Dl>
											<div>
												<dt>
													<FormattedMessage id="Best" />
												</dt>
												<dd>
													{data?.visitsAndSuccessful.best} <FormattedMessage id="pcs" />{' '}
												</dd>
											</div>
											<div>
												<dt>
													<FormattedMessage id="Average" />
												</dt>
												<dd>
													{data?.visitsAndSuccessful.average}{' '}
													<FormattedMessage id="pcs" />{' '}
												</dd>
											</div>
										</Dl>
										 */}
									</SmallPanel>
									{/* Tarjous toteuma */}
									<SmallPanel
										header={
											<PanelHeaderTitle textAlign="center">
												<FormattedMessage id="Offers and realization %" />
											</PanelHeaderTitle>
										}
									>
										<BigText>
											{data?.offers?.offerCount} <FormattedMessage id="pcs" /> /{' '}
											{formatPercentage(data?.offers?.percentage)}
										</BigText>
										{/*
										<Dl>
											<div>
												<dt>
													<FormattedMessage id="Best" />
												</dt>
												<dd>
													{data?.offers?.best} <FormattedMessage id="pcs" />
												</dd>
											</div>
											<div>
												<dt>
													<FormattedMessage id="Average" />
												</dt>
												<dd>
													{data?.offers?.average} <FormattedMessage id="pcs" />
												</dd>
											</div>
										</Dl>
										 */}
									</SmallPanel>
									{/* Kaupan päätös */}
									<SmallPanel
										header={
											<PanelHeaderTitle textAlign="center">
												<FormattedMessage id="Deals and realization %" />
											</PanelHeaderTitle>
										}
									>
										<BigText>{formatPercentage(data?.deals?.percentage)}</BigText>
										{/*
										<Dl>
											<div>
												<dt>
													<FormattedMessage id="Best" />
												</dt>
												<dd>{formatPercentage(data?.deals?.bestPercentage)}</dd>
											</div>
											<div>
												<dt>
													<FormattedMessage id="Average" />
												</dt>
												<dd>{formatPercentage(data?.deals?.averagePercentage)}</dd>
											</div>
										</Dl>
										 */}
									</SmallPanel>
									{/* Peruutus */}
									<SmallPanel
										header={
											<PanelHeaderTitle textAlign="center">
												<FormattedMessage id="Cancelled and realization %" />
											</PanelHeaderTitle>
										}
									>
										<BigText>
											{data?.cancelledDeals?.cancelledCount} <FormattedMessage id="pcs" />{' '}
											/ {formatPercentage(data?.cancelledDeals?.percentage)}
										</BigText>
										{/*
										<Dl>
											<div>
												<dt>
													<FormattedMessage id="Best" />
												</dt>
												<dd>{formatPercentage(data?.cancelledDeals?.bestPercentage)}</dd>
											</div>
											<div>
												<dt>
													<FormattedMessage id="Average" />
												</dt>
												<dd>
													{formatPercentage(data?.cancelledDeals?.averagePercentage)}
												</dd>
											</div>
										</Dl>
										 */}
									</SmallPanel>
								</div>
								<div>
									<SalesChart data={data?.dealAndTargets ?? []} />
									<OffersChart data={data?.offersAndTargets ?? []} />
									<MeetingsByCustomerChart data={data?.visitsBySourcesAndTargets ?? []} />
								</div>
								<div>
									<SalesPipelineChart data={data?.salesPipeline} />
									<SalesBySourcesChart data={data?.salesPercentageByReservationSource} />
									<TopList data={data?.top5Sales ?? []} />
								</div>
							</PanelWrapper>
						</Content>
					</LoaderOverlay>
				</div>
			</div>
		</div>
	);
};

export default compose(
	injectIntl,
	connect(
		applyState({
			dashboard,
			processing,
			teams,
			notices,
			user,
			activeOrganization,
			isSalesmanagerUser,
			isSalesLeaderUser,
			isAdminUser,
			unacknowledgedVisitsCount,
		}),
		applyDispatch({
			initialize,
			destroy,
			getDashboard,
			getTeams,
		}),
	),
)(DashboardPage);
