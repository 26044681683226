import {logWarning} from 'io/errors';
import services from 'services';
import importLeaddeskTalk from 'services/importLeaddeskTalk';
import {expandLeaddeskTalk} from 'modules/common/actions';
import {inProd} from 'constants/app';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
// TODO: make sales app use this too

export const postCallLog = ({
	clientId,
	buildingId,
	appName,
	//callMethod = null,
	startTime,
	phone,
	endTime,
	...other
}) => {
	const apiData = {
		clientId,
		buildingId,
		appName,
		//callMethod,
		startTime,
		phone: phone ? phone : null,
		endTime,
		...other,
	};
	httpJson('post', '/callLogs', {}, {body: apiData}).catch(e => {
		logWarning(e);
	});
};

export const telClient = ({client, buildingId, appName}) => {
	window.location.assign(`tel:${client.phone}`);

	if (buildingId) {
		postCallLog({
			clientId: client.id,
			buildingId,
			appName,
			//callMethod: 'tel',
			startTime: new Date(),
		});
	}
};

let leaddeskTalkDebugNr = 1;

export const leaddeskTalkCallClient = ({client, buildingId, appName, onCallEnded}) => {
	const store = services.get('store');
	store.dispatch(expandLeaddeskTalk());

	importLeaddeskTalk().then(talkApi => {
		const startTime = new Date();

		if (inProd) {
			talkApi.call({number: client.phone});
		} else {
			console.info(
				`Not calling the real number (${client.phone}) with LeadDesk Talk in development, instead using a test number. Change the number here if you want to test receiving calls.`,
			);
			talkApi.call({number: `555-555-555${leaddeskTalkDebugNr++ % 10}`});
		}

		// note that talkApi callbacks are cleared between calling sessions (or maybe earlier callbacks are cleared when you set new ones), so they won't stack up with our use case.

		let answerTime = null;
		talkApi.onConnect(data => {
			answerTime = new Date(data.callStart);
		});

		// call end might fire twice when the call gets ended. we ignore a possible second event.
		let callEndHandled = false;
		talkApi.onDisconnect(data => {
			if (callEndHandled) {
				return;
			}
			callEndHandled = true;

			const callInfo = {
				clientId: client.id,
				buildingId,
				appName,
				//callMethod: 'leaddeskTalk',
				startTime,
				// null if not answered
				answerTime,
				endTime: new Date(data.callEnd),
				recordingUrl: data.recordUrl || data.recordingUrl || null,
			};

			if (onCallEnded) {
				onCallEnded(callInfo);
			}

			if (buildingId) {
				postCallLog(callInfo);
			}
		});
	});
};

export const leaddeskTalkHangup = () => {
	importLeaddeskTalk().then(talkApi => {
		talkApi.hangup();
	});
};

export const leaddeskCallClient = ({client, buildingId, appName}) => {
	window.location.assign(`cmpro:call=${client.phone}`);

	if (buildingId) {
		postCallLog({
			clientId: client.id,
			buildingId,
			appName,
			//callMethod: 'leaddesk',
			startTime: new Date(),
		});
	}
};

export const soittolinjaCallClient = ({client, buildingId, appName}) => {
	const pluginPresent =
		// eslint-disable-next-line eqeqeq
		window.soittolinja_plugin_installed == 1 &&
		typeof window.soittolinja_call_function == 'function';
	if (!pluginPresent) {
		console.warn('Cannot call, Soittolinja plugin not installed');
		return;
	}

	try {
		window.soittolinja_call_function(client.phone);
	} catch (e) {
		logWarning(e);
	}

	if (buildingId) {
		postCallLog({
			clientId: client.id,
			buildingId,
			appName,
			//callMethod: 'soittolinja',
			startTime: new Date(),
		});
	}
};

export const leaddeskHangup = () => {
	const useSoittolinja =
		// eslint-disable-next-line eqeqeq
		window.soittolinja_plugin_installed === 1 &&
		typeof window.soittolinja_hangup_function === 'function';

	if (useSoittolinja) {
		try {
			window.soittolinja_hangup_function();
		} catch (e) {
			logWarning(e);
		}
	} else {
		window.location.assign('cmpro:hangup');
	}
};
