import {evolve, map, find, propEq, curry, pick} from 'ramda';
import {salesmanVisitFormBase} from './constants';
import {pickIfNot} from 'utils/objects';
import {processProductStatesForForms} from 'utils/products';
import memoize from 'memoize-one';

export const processProductStates = processProductStatesForForms;

const _getSalesmanVisitStates = state => {
	const deal = state === 'deal';
	const offer = state === 'offer';
	const cancelledDeal = state === 'cancelledDeal';
	const cancelledOffer = state === 'cancelledOffer';
	const newAppointment = state === 'newAppointment';
	const offerMade = deal || offer || cancelledDeal || cancelledOffer;
	const dealOrCancelled = deal || cancelledDeal;
	const failed = !deal && !newAppointment;
	const cancelled = cancelledDeal || cancelledOffer;

	return {
		deal,
		offer,
		cancelledDeal,
		cancelledOffer,
		newAppointment,
		offerMade,
		dealOrCancelled,
		failed,
		cancelled,
	};
};

// easy optimization
export const getSalesmanVisitStates = memoize(_getSalesmanVisitStates);

export const formatSalesmanVisitFormInput = ({salesmanVisit}) => {
	const visitProducts = salesmanVisit ? salesmanVisit.products : [];

	const salesmanVisitPart = salesmanVisit
		? {
				...pick(Object.keys(salesmanVisitFormBase), salesmanVisit),
				reason:
					salesmanVisit && salesmanVisit.encounter && salesmanVisit.encounter.reason,
				reasonMappingId:
					salesmanVisit &&
					salesmanVisit.encounter &&
					salesmanVisit.encounter.reasonMappingId,
				otherReason:
					salesmanVisit && salesmanVisit.encounter && salesmanVisit.encounter.otherReason,
		  }
		: {};

	return {
		...salesmanVisitFormBase,
		...salesmanVisitPart,
		products: visitProducts.map(p => p.id),
		callReminder: {
			callAt: null,
			sendEmailReminder: false,
			description: '',
		},
	};
};

export const formatSalesmanVisitFormOutput = ({
	form,
	salesmanVisit,
	building,
	clientId,
	calendarResourceId,
}) => {
	const {dateCreatorOpen, survey, ...visitVals} = form;

	const ss = getSalesmanVisitStates(visitVals.state);

	const salesmanVisitData = {
		id: salesmanVisit ? salesmanVisit.id : null,
		...visitVals,
		// keep fields if they match criteria, otherwise clear them to base values
		...pickIfNot(
			{
				price: ss.offerMade,
				products: ss.offerMade,
				dealAt: ss.dealOrCancelled,
				secondUserId: ss.offerMade,
				secondUserPercentage: ss.offerMade && visitVals.secondUserId,
				reason: ss.failed,
				reasonMappingId: ss.failed,
				otherReason: ss.failed && visitVals.reason === 'other',
				newAppointmentDate: ss.newAppointment && dateCreatorOpen,
				newCalendarResourceId: ss.newAppointment && !dateCreatorOpen,
			},
			salesmanVisitFormBase,
		),
		cancelled: ss.cancelledDeal,
	};
	const salesmanVisitData2 = evolve({products: map(id => ({id}))}, salesmanVisitData);

	const callReminder = form.isCallReminder
		? {
				callAt: new Date(form.callAt),
				buildingId: building.id,
				clientId,
				sendReminderMail: form.sendReminderMail,
				description: form.description,
				calendarResourceId,
		  }
		: null;

	return {salesmanVisit: salesmanVisitData2, callReminder};
};

export const formatClientFormInput = ({client}) => {
	return client;
};

export const formatClientFormOutput = ({form}) => {
	const client = {...form, emailMarketing: form.emailMarketing ? new Date() : null};
	return client;
};

export const verifyOrg = curry((resourceOrg, user) =>
	find(propEq('id', resourceOrg), user.organizations) ? true : false,
);
