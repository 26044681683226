import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.handleEvent.type: {
			return [{...state, processing: true}, effects.handleEvent(payload)];
		}

		case actions.fetchMore.type: {
			const newState = {
				...state,
				fetchingMore: true,
				eventsQuery: {
					...state.eventsQuery,
					_page: state.eventsPagination.currentPage + 1,
				},
			};

			return [newState, effects.updateEvents()];
		}

		case actions.searchEvents.type: {
			const newState = {
				...state,
				events: [],
				eventsQuery: {...state.eventsQuery, _page: 1, ...payload},
				eventsLoading: true,
			};

			return [newState, effects.updateEvents()];
		}

		case actions.searchUsers.type: {
			return [state, effects.searchUsers(payload)];
		}

		case actions._setEvents.type: {
			const newState = {
				...state,
				events: [...state.events, ...payload.data],
				eventsPagination: payload.pagination,
				eventsLoading: false,
				fetchingMore: false,
			};

			return [newState, null];
		}

		case actions._updateEvent.type: {
			const newState = {
				...state,
				processing: false,
				events: state.events.map(f => (f.id === payload.id ? {...f, ...payload} : f)),
			};

			return [newState, null];
		}

		case actions._updateEventsQuery.type: {
			const newState = {
				...state,
				eventsQuery: {...state.eventsQuery, ...payload},
			};

			return [newState, null];
		}

		case actions._setProcessing.type: {
			return [{...state, processing: payload}, null];
		}

		case actions._setOptions.type: {
			return [{...state, options: payload}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
