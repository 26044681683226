import React from 'react';

const SvgHeadset = props => (
	<svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M15.333 32c-1.102 0-2-.897-2-2s.898-2 2-2h1.334c.853 0 1.606.547 1.885 1.333h2.781a2.663 2.663 0 0 0 2.644-2.35L22.667 26a3.348 3.348 0 0 1-1.334-2.667V18c0-1.044.499-2.041 1.334-2.667l2.122-1.592a.514.514 0 0 1 .056-.034c.331-.24.735-.374 1.155-.374h.667V12C26.667 6.119 21.88 1.333 16 1.333S5.333 6.12 5.333 12v1.333H6c.413 0 .813.13 1.156.375.021.01.041.023.056.035l2.121 1.59A3.348 3.348 0 0 1 10.667 18v5.333A3.348 3.348 0 0 1 9.333 26l-2.122 1.592a.411.411 0 0 1-.056.035A1.98 1.98 0 0 1 6 28H4.667a3.337 3.337 0 0 1-3.334-3.333v-8A3.329 3.329 0 0 1 4 13.4V12C4 5.383 9.383 0 16 0s12 5.383 12 12v1.401a3.329 3.329 0 0 1 2.667 3.266v8A3.338 3.338 0 0 1 27.333 28H26c-.29 0-.575-.064-.837-.188a3.987 3.987 0 0 1-3.83 2.855h-2.781A2.007 2.007 0 0 1 16.667 32h-1.334zm0-2.667a.667.667 0 0 0 0 1.334h1.334a.667.667 0 0 0 0-1.334h-1.334zM26 14.667a.667.667 0 0 0-.667.666V26c0 .368.299.667.667.667h1.333c1.103 0 2-.898 2-2v-8c0-1.103-.897-2-2-2H26zm-21.333 0c-1.103 0-2 .897-2 2v8c0 1.102.897 2 2 2H6A.667.667 0 0 0 6.667 26V15.333A.667.667 0 0 0 6 14.667H4.667zm18.8 1.733c-.5.376-.8.973-.8 1.6v5.333c0 .626.298 1.224.8 1.6l.533.4V16l-.533.4zM8 25.333l.533-.4c.502-.376.8-.973.8-1.6V18c0-.627-.298-1.224-.8-1.6L8 16v9.333z"
		/>
	</svg>
);

export default SvgHeadset;
