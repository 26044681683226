import React from 'react';
import {withRouter} from 'react-router-dom';
import View from 'components/generic/View';
import DefaultNavbar from 'components/views/DefaultNavbar';
import LoadingPage from 'components/generic/LoadingPage';

const LoadingView = props => (
	<View {...props}>
		<DefaultNavbar />
		<LoadingPage />
	</View>
);

export default withRouter(LoadingView);
