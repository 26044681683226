import {reset} from 'redux-form';
import {effect} from 'utils/redux';
import {catchNonFatalDefault} from 'io/errors';
import namespace from './namespace';
import * as actions from './actions';
import * as commonSelectors from 'modules/common/selectors';
import {appName} from '../constants';
import {ensureAccess, setPageTitleMessage, decorateWithNotifications} from 'io/app';
import {encodeQuery} from 'utils/url';
import services from 'services';
import {getBuildings} from './io';

const creator = effect(namespace);

const history = services.get('history');

export let initialize = () => (getState, dispatch) => {
	setPageTitleMessage('Buildings [app]')(getState, dispatch);

	ensureAccess(appName, commonSelectors.user(getState()))(getState, dispatch)
		.then(() => {
			dispatch(actions._initialize());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let searchBuildings = ({text, callback}) => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'search-buildings',
			failureStyle: 'warning',
		},
		getBuildings({_q: text, _limit: '20', include: 'clients'}),
	)(getState, dispatch)
		.then(buildings => {
			callback(buildings);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
searchBuildings = creator('searchBuildings', searchBuildings);

export let navigateToBuilding = ({buildingId, referrer}) => (getState, dispatch) => {
	history.push(`/buildings/buildings/${buildingId}${encodeQuery({referrer})}`);
	dispatch(reset('searchForm'));
};
navigateToBuilding = creator('navigateToBuilding', navigateToBuilding);
