import {pipe, over, map, lensProp, curry, clone, filter} from 'ramda';

const cmp = (a, b) => (a < b ? -1 : a > b ? 1 : 0);

const sortItemsByStats = curry((statsTimeSpan, items) => {
	const cm = (type, a, b) =>
		cmp(a.stats[statsTimeSpan][type], b.stats[statsTimeSpan][type]) * -1;
	return clone(items).sort(
		(a, b) =>
			cm('appointmentContacts', a, b) ||
			cm('reachedContacts', a, b) ||
			cm('totalContacts', a, b),
	);
});

const formatTeamForDisplay = curry((statsTimeSpan, team) =>
	over(
		lensProp('users'),
		pipe(
			filter(u => u.stats[statsTimeSpan].totalContacts),
			sortItemsByStats(statsTimeSpan),
		),
		team,
	),
);

export const formatTeamsForDisplay = curry((statsTimeSpan, teams) =>
	pipe(
		filter(t => t.stats[statsTimeSpan].totalContacts),
		map(formatTeamForDisplay(statsTimeSpan)),
		sortItemsByStats(statsTimeSpan),
	)(teams),
);

export const formatStatsNumber = num => (num ? Math.round(num * 100) / 100 : 0);
