import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import * as selectors from './selectors';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {getFormValues} from 'redux-form';
import {formatAddAreaFormOutput} from './utils';
const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, effects.destroy()];
		}

		case actions.openPlacesSuggestion.type: {
			return [state, effects.openPlacesSuggestion(payload)];
		}

		case actions.setAreasSelectorType.type: {
			return [state, effects.setTeamAreasMapType(payload)];
		}

		case actions.openAddAreaModal.type: {
			return [{...state, addAreaModalOpen: true, selectedArea: payload}, null];
		}

		case actions.closeAddAreaModal.type: {
			return [{...state, addAreaModalOpen: false, selectedArea: null}, null];
		}

		case actions.updateTeamAreas.type: {
			const team = formatAddAreaFormOutput({
				team: selectors.team(fullState),
				area: payload,
			});

			return [{...state, processing: true}, effects.updateTeamAreas(team)];
		}

		case actions.removeTeamArea.type: {
			return [state, effects.removeTeamArea(payload)];
		}

		case actions.changeTeam.type: {
			const form = getFormValues('teamChangeForm')(fullState);
			const teams = state.teams;

			const _team = form.team;
			const team = teams && _team && _team.id && teams.filter(t => t.id === _team.id)[0];

			return [{...state, team}, team && effects.changeTeam(team.id)];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		case actions._setTeam.type: {
			return [{...state, team: payload}, null];
		}

		case actions._setTeams.type: {
			const team = state.team ? state.team : payload[0];
			let teams = payload;
			if (teams && teams.find(t => t.id === team.id)) {
				teams = teams.filter(t => t.id !== team.id);
			}

			teams = [team, ...teams];
			return [{...state, teams: teams, teamsLoading: false}, null];
		}

		case actions._updateTeamAreas.type: {
			return [
				{...state, team: payload, processing: false, addAreaModalOpen: false},
				null,
			];
		}

		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
