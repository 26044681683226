import {effect} from 'utils/redux';
import {P} from 'utils/types';
import namespace from './namespace';
import {catchNonFatalDefault} from 'io/errors';
import {decorateWithNotifications, notify} from 'io/app';
import services from 'services';
import msgs from 'dicts/messages';
import * as actions from './actions';
import * as confirmerActions from 'modules/confirmer/actions';
import * as selectors from './selectors';
import {
	getClient,
	getCalendarResources,
	getEncounters,
	getEncounter,
	putClient,
	postRestoreClient,
	deleteClient,
	banClient,
	removeBan,
} from './io';
import {shortDur} from 'constants/notifications';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

export let initialize = clientId => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'get-building-data',
			failureStyle: 'error',
		},
		Promise.all([
			getClient(clientId).then(client => dispatch(actions._setClient(client))),
			getCalendarResources(clientId).then(calendarResources =>
				dispatch(actions._setCalendarResources(calendarResources)),
			),
			getEncounters(clientId).then(encounters =>
				dispatch(actions._setEncounters(encounters)),
			),
		]),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize, P.Number);

export let getPreviewableEncounter = id => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'get-previewable-encounter',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		getEncounter(id),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(encounter => dispatch(actions._setPreviewableEncounter(encounter)))
		.catch(catchNonFatalDefault(getState, dispatch));
};
getPreviewableEncounter = creator('getPreviewableEncounter', getPreviewableEncounter);

export let setClientBanState = isBeingBanned => (getState, dispatch) => {
	const client = selectors.client(getState());
	// TODO: there should be a delay
	decorateWithNotifications(
		{
			id: 'set-client-ban-state',
			failureStyle: 'error',
			loading: intl.formatMessage({id: 'Saving'}),
		},
		isBeingBanned ? banClient(client.id) : removeBan(client.ban.id),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(ban => {
			const msg = isBeingBanned ? 'Ban saved' : 'Ban removed';
			notify('success', {
				id: 'client-ban-state-changed',
				message: intl.formatMessage({id: msg}),
				duration: shortDur,
			});

			if (isBeingBanned) {
				return dispatch(actions._setClientBan(ban));
			}
			return dispatch(actions._removeClientBan());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
setClientBanState = creator('setClientBanState', setClientBanState, P.Boolean);

export let restoreClient = () => (getState, dispatch) => {
	const client = selectors.client(getState());
	decorateWithNotifications(
		{
			id: 'restore-client',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Customer restored'}),
		},
		postRestoreClient(client.id),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(() => dispatch(actions._setClientTrashed(false)))
		.catch(catchNonFatalDefault(getState, dispatch));
};
restoreClient = creator('restoreClient', restoreClient);

export let removeClient = () => (getState, dispatch) => {
	const client = selectors.client(getState());
	const onConfirm = () => {
		dispatch(actions._startOp());
		decorateWithNotifications(
			{
				id: 'delete-client',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Client deleted'}),
			},
			deleteClient(client.id),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._setClientTrashed(true));
				dispatch(actions.closeClientEditor());
			})
			.catch(catchNonFatalDefault);
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete client?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
removeClient = creator('removeClient', removeClient);

export let saveClient = client => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'save-client',
			failureStyle: 'error',
			loading: intl.formatMessage({id: 'Saving'}),
			success: intl.formatMessage({id: 'Client saved'}),
		},
		putClient(client),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(c => {
			dispatch(actions._setClient(c));
			dispatch(actions.closeClientEditor());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
saveClient = creator('saveClient', saveClient, P.Object);
