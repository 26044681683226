import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import {sortByTitle as sortAreas} from 'utils/areas';
import initState from './state';
import {referencesPerPage} from './constants';

export const parseUrlQuery = query => {
	const vals = evolve(
		{
			_page: Number,
			dismissPrompt: str => (str ? JSON.parse(str) : false),
			city: str => (str ? JSON.parse(str) : null),
			zip: str => (str ? JSON.parse(str) : null),
			county: str => (str ? JSON.parse(str) : null),
			products: str => (str ? JSON.parse(str) : []),
		},
		query,
	);
	return {referencesQuery: replace(vals, initState.referencesQuery)};
};

export const formatUrlQuery = ({referencesQuery}) => {
	const {city, zip, county, products} = referencesQuery;

	const _city = city ? JSON.stringify(city) : null;
	const _zip = zip ? JSON.stringify(zip) : null;
	const _county = county ? JSON.stringify(county) : null;
	const _products = products ? JSON.stringify(products) : null;

	return {
		...referencesQuery,
		city: _city,
		zip: _zip,
		county: _county,
		products: _products,
	};
};

export const formatFetchableReferencesQuery = ({referencesQuery}) => {
	const {city, zip, county, products} = referencesQuery;

	return {
		_page: 1,
		/*
			Send the most precise areaId, priority:
				1. zip selected -> send zip area's id
				2. city selected -> send city area's id
				3. county selected -> send county area's id
		*/
		// prettier-ignore
		areaId: zip ? zip.id
			: city ? city.id
			: county ? county.id
			: null,
		products: products ? products.map(p => p.id) : [],
	};
};

export const formatReferencesSearchInput = ({referencesQuery}) => {
	return {
		city: referencesQuery.city,
		zip: referencesQuery.zip,
		county: referencesQuery.county,
		products: referencesQuery.products,
	};
};

export const formatAreas = areas => {
	// areas fetched withoutTransform, so change municipal_name to camelCase here
	const _areas = sortAreas(areas.map(a => ({...a, municipalName: a.municipal_name})));
	const counties = _areas.filter(a => a.type === 'county');
	const cities = _areas.filter(a => a.type === 'city');
	const postcodes = _areas.filter(a => a.type === 'postcode');

	return {
		counties,
		cities,
		postcodes,
	};
};

export const filterSelectableAreas = ({areas, selectedCounty, selectedCity}) => {
	const counties = areas.counties;
	// only allow selecting cities in the selected county
	const cities = selectedCounty
		? areas.cities.filter(c => c.county === selectedCounty.title)
		: areas.cities;
	// only allow selecting postcodes in the selected city (or in the selected county if no city selected)
	// prettier-ignore
	const postcodes = selectedCity ? areas.postcodes.filter(p => p.municipalName === selectedCity.title)
		: selectedCounty ? areas.postcodes.filter(p => p.county === selectedCounty.title)
		: areas.postcodes;

	return {
		counties,
		cities,
		postcodes,
	};
};

export const getBookTotalPagesCount = references => {
	const totalPages = Math.ceil(references.length / referencesPerPage) + 2; // +2 = cover + summary page

	return {
		desktop: totalPages % 2 === 0 ? totalPages : totalPages + 1, // +1 = odd number of pages in desktop => extra empty last page rendered
		mobile: totalPages,
	};
};

export const launchIntoFullscreen = element => {
	if (element.requestFullscreen) {
		element.requestFullscreen();
	} else if (element.mozRequestFullScreen) {
		element.mozRequestFullScreen();
	} else if (element.webkitRequestFullscreen) {
		element.webkitRequestFullscreen();
	} else if (element.msRequestFullscreen) {
		element.msRequestFullscreen();
	}
};

export const exitFullscreen = () => {
	if (document.exitFullscreen) {
		document.exitFullscreen();
	} else if (document.mozCancelFullScreen) {
		document.mozCancelFullScreen();
	} else if (document.webkitExitFullscreen) {
		document.webkitExitFullscreen();
	}
};

export const filterProducts = ({products, activeOrganizationId}) => {
	if (activeOrganizationId === 2) {
		//remove products with id 6 & 24 from Kotisun Viemäripalvelut Oy organization
		const _products = products.filter(p => p.id === 6 || p.id === 24);
		return _products;
	} else if (activeOrganizationId === 4) {
		//remove products with id 5 & 23 from KS Viemärihuolto organization
		const _products = products.filter(p => p.id === 5 || p.id === 23);
		return _products;
	} else return products;
};
