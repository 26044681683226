export const workTypes = {
	working: 'Work',
	break: 'Break',
	lunch: 'Lunch',
};

export const types = {
	...workTypes,
	sickLeave: 'Sick leave',
	sickLeaveContagion: 'Sick leave, contagion',
	annualLeave: 'Annual leave',
	unpaidHoliday: 'Unpaid holiday',
	midweekHoliday: 'Bank holiday',
};
