import {getQuery, pushQuery} from 'io/history';
import {useState} from 'react';

const useQueryParams = (initialState = null) => {
	const defaults = initialState ? initialState : getQuery();
	const [params, _setParams] = useState(defaults);

	const setParams = (newParams, updateUrl = true) => {
		_setParams(newParams);

		if (updateUrl) {
			// We update url by pushing only provided params
			pushQuery(_ => newParams);
		}
	};
	return {
		params,
		setParams,
	};
};

export default useQueryParams;
