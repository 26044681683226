import {evolve} from 'ramda';
import initState from './state';
import {parseNullableNumber, parseNullableString} from 'utils/fields';
import {format, parse} from 'date-fns';

const dateFmt = 'yyyy-MM';
export const parseDate = dateStr => {
	return parse(dateStr, dateFmt, new Date());
};

export const formatDate = date => {
	return format(date, dateFmt);
};

export const parseUrlQuery = query => {
	return evolve(
		{
			teamId: v => parseNullableNumber(v),
			date: v => {
				const value = parseNullableString(v);
				if (value && value.length > 0) {
					return value;
				}

				return formatDate(new Date());
			},
		},
		{
			...initState.dashboardTargetsQuery,
			...query,
		},
	);
};

export const encodeUserIdToFormInput = userId => `user_${userId}`;
export const decodeUserIdToFormInput = key => key.replace('user_', '');
