import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {formatBuildingsSearchOutput} from './utils';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.changePage.type: {
			const newState = {
				...state,
				buildingsLoading: true,
				buildingsQuery: {...state.buildingsQuery, _page: payload},
			};

			return [newState, effects.updateBuildings()];
		}

		case actions.searchBuildings.type: {
			const buildingsQuery = formatBuildingsSearchOutput(payload);

			const newState = {
				...state,
				buildingsLoading: true,
				showPrompt: false,
				buildingsQuery,
			};

			return [newState, effects.updateBuildings()];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		case actions.openBuilding.type: {
			return [state, effects.openBuilding(payload)];
		}

		case actions.restoreBuilding.type: {
			return [{...state, processing: true}, effects.restoreBuilding(payload)];
		}

		case actions.openAddBuildingModal.type: {
			return [{...state, addBuildingModalOpen: true}, null];
		}

		case actions.closeAddBuildingModal.type: {
			return [{...state, addBuildingModalOpen: false}, null];
		}

		case actions.addBuilding.type: {
			return [{...state, processing: true}, effects.addBuilding(payload)];
		}

		case actions._setBuildings.type: {
			const newState = {
				...state,
				buildings: payload.data,
				buildingsPagination: payload.pagination,
				buildingsLoading: false,
			};

			return [newState, null];
		}

		case actions._updateBuildingsQuery.type: {
			const newState = {
				...state,
				buildingsLoading: true,
				buildingsQuery: {...state.buildingsQuery, ...payload},
			};

			return [newState, null];
		}

		case actions._showPrompt.type: {
			return [{...state, showPrompt: true}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
