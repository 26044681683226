import createCache from 'services/createCache';

export default createCache({
	map: null,
	buildingsLayer: null,
	areasLayer: null,
	callPoolsLayer: null,
	drawSource: null,
	draw: null,
	mapLayer: null,
	markerLayer: null,
	groundwaterAreasLayer: null,
	propertyLinesLayer: null,
});
