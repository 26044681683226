import {
	TYPE_CALL,
	TYPE_VISIT,
	TYPE_SALESMANVISIT,
} from 'modules/usersApp/reasonMappingsPage/constants';

export const reasonMappingTypes = {
	[TYPE_CALL]: 'ReasonMapping type:call',
	[TYPE_VISIT]: 'ReasonMapping type:visit',
	[TYPE_SALESMANVISIT]: 'ReasonMapping type:salesmanvisit',
};
