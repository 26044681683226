import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import {getFormValues} from 'redux-form';
import {formatClientFormOutput, format16100data, formatAreas} from './utils';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {omit, sortBy, prop} from 'ramda';
import createBuildingModalHandler from 'fragments/buildingModalActions/handler';

const buildingHandler = createBuildingModalHandler({
	actions,
	effects,
});

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [
				{...state, processing: true, loading16100Clients: true},
				effects.initialize(payload),
			];
		}

		case actions.reinitialize.type: {
			return [
				{
					...state,
					buildingDataLoading: true,
					processing: true,
					loading16100Clients: true,
				},
				effects.initialize(payload),
			];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.toggleClientEditor.type: {
			const newState = {
				...state,
				clientInEdit: payload,
				clientEditorOpen: !state.clientEditorOpen,
			};

			return [newState, null];
		}

		case actions.saveClient.type: {
			const form = getFormValues('clientForm')(fullState);
			const client = formatClientFormOutput({form});
			const newClient = state.clientInEdit ? state.clientInEdit.isNew : true;
			const buildingId = newClient ? state.building.id : null;

			return [{...state, processing: true}, effects.saveClient({client, buildingId})];
		}

		case actions.removeClient.type: {
			const clientId = state.clientInEdit.id;

			return [{...state, processing: true}, effects.removeClient(clientId)];
		}

		case actions.openEncounterModal.type: {
			return [{...state, processing: true}, effects.fetchEncounter(payload)];
		}

		case actions.closeEncounterModal.type: {
			return [{...state, previewableEncounter: null}, null];
		}

		case actions.fetchClientData.type: {
			return [{...state, processing: true}, effects.fetchClientData(payload)];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false}, null];
		}

		case actions._setInitData.type: {
			const newState = {
				...state,
				processing: false,
				buildingDataLoading: false,
				building: omit(['encounters'], payload.building),
				encounters: payload.building.allEncounters,
				clients: payload.clients,
				areas: formatAreas(payload.areas),
				availableTags: payload?.availableTags || [],
			};

			return [newState, null];
		}

		case actions._updateClients.type: {
			const clients =
				payload.type === 'update'
					? state.clients.map(c => {
							return c.id === payload.client.id ? payload.client : c;
					  })
					: payload.type === 'add'
					? [...state.clients, payload.client]
					: state.clients.filter(c => c.id !== payload.client.id);

			const clientEditorOpen = payload?.clientEditorOpen || false;
			const newState = {...state, clients, processing: false, clientEditorOpen};

			// Update the client in editor as well
			if (state.clientInEdit && state.clientInEdit.id === payload.client.id) {
				if (payload.type === 'update') {
					newState.clientInEdit = payload.client;
				} else if (payload.type === 'remove') {
					newState.clientInEdit = null;
					newState.clientEditorOpen = false;
				}
			}

			return [newState, null];
		}

		case actions._setEncounterData.type: {
			const newState = {
				...state,
				processing: false,
				previewableEncounter: payload,
			};

			return [newState, null];
		}

		case actions._set16100Clients.type: {
			const sortByName = sortBy(prop('name'));
			const searchedClients = sortByName(payload);

			return [{...state, newClients: searchedClients, loading16100Clients: false}, null];
		}

		case actions._set16100Client.type: {
			const client = format16100data(payload);

			return [
				{...state, clientInEdit: client, clientEditorOpen: true, processing: false},
				null,
			];
		}

		case actions._search16100Failed.type: {
			return [{...state, newClients: [], loading16100Clients: false}, null];
		}

		case actions.banClient.type: {
			return [
				{
					...state,
					processing: true,
				},
				effects.banClient(payload),
			];
		}

		case actions._banClient.type: {
			return [
				{
					...state,
					processing: false,
				},
				null,
			];
		}

		case actions.unbanClient.type: {
			return [
				{
					...state,
					processing: true,
				},
				effects.unbanClient(payload),
			];
		}

		case actions._unbanClient.type: {
			return [
				{
					...state,
					processing: false,
				},
				null,
			];
		}

		case actions._setAvailableTags.type: {
			return [{...state, availableTags: payload}, null];
		}

		case actions.getTags.type: {
			return [{...state, processingTags: true}, effects.getTags(payload)];
		}

		case actions._getTags.type: {
			return [{...state, processingTags: false, tags: payload}, null];
		}

		case actions.attachTag.type: {
			return [
				{
					...state,
					processingTag: true,
				},
				effects.attachTag(payload),
			];
		}

		case actions._attachTag.type: {
			return [
				{
					...state,
					processingTag: false,
				},
				null,
			];
		}

		case actions.detachTag.type: {
			return [
				{
					...state,
					processingTag: true,
				},
				effects.detachTag(payload),
			];
		}

		case actions._detachTag.type: {
			return [
				{
					...state,
					processingTag: false,
				},
				null,
			];
		}

		default:
			return buildingHandler(state, fullState, {type, payload});
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
