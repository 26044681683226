import React from 'react';

const SvgProjects = props => (
	<svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M5.333 29.333a2.67 2.67 0 0 1-2.666-2.666A2.67 2.67 0 0 1 5.333 24 2.67 2.67 0 0 1 8 26.667a2.67 2.67 0 0 1-2.667 2.666zm0-4a1.335 1.335 0 0 0 0 2.667c.735 0 1.334-.597 1.334-1.333s-.599-1.334-1.334-1.334zm0 6.667A5.339 5.339 0 0 1 0 26.667V5.333A5.339 5.339 0 0 1 5.333 0a5.353 5.353 0 0 1 5.164 4H30c1.103 0 2 .897 2 2v24c0 1.103-.897 2-2 2H5.333zM30 30.667a.667.667 0 0 0 .667-.667V6A.667.667 0 0 0 30 5.333H10.667v21.334c0 1.54-.676 2.996-1.816 4H30zm-24.667-8c-2.205 0-4 1.794-4 4 0 2.205 1.795 4 4 4 2.206 0 4-1.795 4-4 0-2.206-1.794-4-4-4zm0-21.334c-2.205 0-4 1.795-4 4V23.15a5.348 5.348 0 0 1 4-1.816c1.54 0 2.996.678 4 1.816V5.333c0-2.205-1.794-4-4-4zm9.334 24A1.335 1.335 0 0 1 13.333 24V12.412a1.336 1.336 0 0 1 1.931-1.192L20 13.588v-2.255a.667.667 0 0 1 1.333 0V16h4v-2.667a.667.667 0 0 1 1.334 0V16c.736 0 1.333.597 1.333 1.333V24c0 .736-.597 1.333-1.333 1.333h-12zm12-1.333v-6.667h-5.334v1.334H24A.667.667 0 0 1 24 20h-2.667v1.333H24a.667.667 0 0 1 0 1.334h-2.667V24h5.334zm-12 0H20v-8.921l-5.333-2.667V16h2.666a.667.667 0 0 1 0 1.333h-2.666v1.334h2.666a.667.667 0 0 1 0 1.333h-2.666v4z"
		/>
	</svg>
);

export default SvgProjects;
