import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const toggleRouteVisibility = creator('toggleRouteVisibility', P.Object);

export const createRoutes = creator('createRoutes', P.Number);

export const setRouteSalesman = creator('setRouteSalesman', P.Object);

export const setItemRoute = creator('setItemRoute', P.Object);

export const focusRoute = creator('focusRoute', P.Number);

export const saveRoutes = creator('saveRoutes');

export const updateMarker = creator('updateMarker');

export const _initialize = creator('_initialize');

export const _updateCalendarQuery = creator('_updateCalendarQuery', P.Object);

export const _setCalendarResources = creator('_setCalendarResources', P.Array);

export const _setRoutes = creator('_setRoutes', P.Array);

export const _setTeam = creator('_setTeam', P.Object);

export const _createRoutes = creator('_createRoutes');

export const _setExtra = creator('_setExtra', P.Array);

export const _opOk = creator('_opOk');

export const _opFailed = creator('_opFailed');

export const _setMarkers = creator('_setMarkers');
