import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {compose} from 'ramda';
import {applyDispatch, applyState} from 'utils/redux';
import {
	isSendSmsModalOpen,
	smsDraft,
	canSendSmsMessage,
	canEditSmsMessageField,
	canSendCustomMessage,
	processing,
	smsTemplatesLoadingFailed,
} from 'modules/sms/selectors';
import {closeSendSmsModal, sendSms} from 'modules/sms/actions';
import Modal from 'components/generic/Modal';
import ModalHeaderTitle from 'components/generic/ModalHeaderTitle';
import Alert from 'components/generic/Alert';
import Button from 'components/generic/Button';
import {FormattedMessage, injectIntl} from '@meiko/react-intl';
import Loader from 'components/generic/Loader';
import SmsForm, {SEND_SMS_FORM} from './SmsForm';
import {getSmsFormInitialValues} from 'modules/sms/utils';

const LoaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 200px;
`;

const Sms = ({
	isSendSmsModalOpen,
	closeSendSmsModal,
	sendSms,
	canSendSmsMessage,
	canEditSmsMessageField,
	smsDraft,
	processing,
	smsTemplatesLoadingFailed,
}) => {
	if (!isSendSmsModalOpen) {
		return null;
	}

	// If we do not have the draft, it means that it's still loading
	const isLoading = !smsDraft && !smsTemplatesLoadingFailed;

	const client = smsDraft?.client;
	const clientId = client?.id;

	const initialValues = getSmsFormInitialValues(smsDraft);

	// NOTE: Modal is created with React portals.
	// The whole DOM NEEDS to be rendered for the portal to work.
	return (
		<Modal
			verticalPadding
			maxWidth="600px"
			headerTitle={
				<ModalHeaderTitle>
					<FormattedMessage id="Send SMS-message" />
				</ModalHeaderTitle>
			}
			cancelButton={
				<Button
					disabled={isLoading}
					id="modal-cancel-button"
					onClick={() => (!isLoading ? closeSendSmsModal() : null)}
				>
					<FormattedMessage id="Cancel" />
				</Button>
			}
			confirmButton={
				<Button
					disabled={
						processing || isLoading || !canSendSmsMessage || smsTemplatesLoadingFailed
					}
					type="submit"
					form={SEND_SMS_FORM}
					appearance="success"
					id="modal-confirm-button"
				>
					{processing ? (
						<FormattedMessage id="Loading" />
					) : (
						<FormattedMessage id="Confirm" />
					)}
				</Button>
			}
		>
			{isLoading ? (
				<LoaderWrapper>
					<Loader />
				</LoaderWrapper>
			) : !smsTemplatesLoadingFailed ? (
				<>
					<SmsForm
						smsDraft={smsDraft}
						initialValues={initialValues}
						availableTemplates={smsDraft?.availableTemplates ?? []}
						client={client}
						canEditSmsMessageField={canEditSmsMessageField}
						onSubmit={values => {
							const {message, receivers, currentTemplate} = values;

							sendSms({
								message,
								receivers: receivers.map(r => r.value),
								clientId,
								smsTemplateId: currentTemplate.value,
							});
						}}
					/>
					{!canSendSmsMessage && (
						<Alert appearance="warning">
							<FormattedMessage id="No permission" />
						</Alert>
					)}
				</>
			) : (
				<Alert variant="warning">
					<FormattedMessage id="SMS template loading failed" />
				</Alert>
			)}
		</Modal>
	);
};

export default compose(
	injectIntl,
	connect(
		applyState({
			isSendSmsModalOpen,
			smsDraft,
			processing,
			canSendSmsMessage,
			canEditSmsMessageField,
			canSendCustomMessage,
			smsTemplatesLoadingFailed,
		}),
		applyDispatch({
			closeSendSmsModal,
			sendSms,
		}),
	),
)(Sms);
