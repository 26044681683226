import {update} from 'ramda';
import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';

export default scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.info.type:
		case actions.success.type:
		case actions.warning.type:
		case actions.error.type:
		case actions.loading.type: {
			const [, name] = type.split(':');
			const {duration, ...rest} = payload;
			const notification = {...rest, type: name};
			const iExisting = state.findIndex(n => n.id === payload.id);
			const newState =
				iExisting >= 0
					? update(iExisting, notification, state)
					: [...state, notification];
			const effect = duration
				? effects.removeAfter({id: payload.id, timeout: duration})
				: null;

			return [newState, effect];
		}

		case actions.remove.type:
		case actions._remove.type: {
			return [state.filter(n => n.id !== payload), null];
		}

		default:
			return [state, null];
	}
});
