import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const reinitMap = creator('reinitMap');

export const searchBuildings = creator('searchBuildings', P.Object);

export const changePage = creator('changePage', P.Number);

export const setActiveTab = creator('setActiveTab', P.String);

export const setMapSource = creator('setMapSource', P.String);

export const updateContactsQuery = creator('updateContactsQuery', P.Object);

export const openBuilding = creator('openBuilding', P.Object);

export const updateBuilding = creator('updateBuilding', P.Number);

export const openAddBonusModal = creator('openAddBonusModal', P.Object);

export const closeAddBonusModal = creator('closeAddBonusModal');

export const addBonus = creator('addBonus', P.Object);

export const _setBuildings = creator('_setBuildings', P.Array);

export const _updateContactsQuery = creator('_updateContactsQuery', P.Object);

export const _setBuildingsLoading = creator('_setBuildingsLoading', P.Boolean);

export const _setAllBuildings = creator('_setAllBuildings', P.Array);

export const _setOrganizationProducts = creator('_setOrganizationProducts', P.Array);

export const _setAllProducts = creator('_setAllProducts', P.Array);

export const _updateBuildingsPagination = creator('_updateBuildingsPagination', P.Object);

export const _updateBuilding = creator('_updateBuilding', P.Object);

export const _setUserTeams = creator('_setUserTeams', P.Array);

export const _setAllAreas = creator('_setAllAreas');

export const _setCrossSalesCount = creator('_setCrossSalesCount', P.Object);

export const _addBonus = creator('_addBonus');

export const _opFailed = creator('_opFailed');

export const _updateManufacturingYears = creator('_updateManufacturingYears');

export const _setOrganizations = creator('_setOrganizations');
