import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {createSelector} from 'reselect';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {formatUrlQuery} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const buildingsQuery = pipe(root, prop('buildingsQuery'));

export const urlQuery = createSelector([buildingsQuery], buildingsQuery => {
	return formatUrlQuery({buildingsQuery});
});

export const mapSource = createSelector(
	[buildingsQuery],
	buildingsQuery => buildingsQuery.mapSource,
);
