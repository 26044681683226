import {effect} from 'utils/redux';
import {catchNonFatalDefault} from 'io/errors';
import namespace from './namespace';
import * as actions from './actions';
import * as selectors from './selectors';
import {getOrganizations, getProducts, putOrganization} from './io';
import {decorateWithNotifications} from 'io/app';
import {getQuery} from 'io/history';
import services from 'services';
import msgs from 'dicts/messages';
import {pick} from 'ramda';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const organizationsQueryKeys = [''];

const fetchOrganizationsData = (getState, dispatch) => {
	// const usersQuery = {
	// 	...selectors.allUsersQueryFetchable(getState()),
	// 	_limit: '999',
	// };
	const organizationsQuery = selectors.organizationsQueryFetchable(getState());

	return Promise.all([
		getProducts().then(products => {
			dispatch(actions._setProducts(products));
		}),
		getOrganizations(organizationsQuery).then(organizations => {
			dispatch(actions._setOrganizations(organizations));
		}),
	]);
};

export let updateOrganization = ({organization}) => (getState, dispatch) => {
	dispatch(actions._setProcessing(true));
	decorateWithNotifications(
		{
			id: 'save-organization',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Saved'}),
		},
		putOrganization(organization.id, organization),
	)(getState, dispatch)
		.then(() => {
			initialize()(getState, dispatch);
			dispatch(actions._setProcessing(false));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
updateOrganization = creator('updateOrganization', updateOrganization);

export let initialize = () => (getState, dispatch) => {
	dispatch(actions._updateOrganizationsQuery(pick(organizationsQueryKeys, getQuery())));

	decorateWithNotifications(
		{id: 'init-organizations', failureStyle: 'error'},
		fetchOrganizationsData(getState, dispatch),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);
