import {effect} from 'utils/redux';
import namespace from './namespace';
import * as actions from './actions';
import {catchNonFatalDefault} from 'io/errors';
import * as commonSelectors from 'modules/common/selectors';
import * as commonActions from 'modules/common/actions';
import {appName} from '../constants';
import {ensureAccess, setPageTitleMessage} from 'io/app';
import {createReferrerUrl, encodeQuery} from 'utils/url';
import services from 'services';

const creator = effect(namespace);

const history = services.get('history');

export let initialize = () => (getState, dispatch) => {
	setPageTitleMessage('Leads [app]')(getState, dispatch);

	ensureAccess(appName, commonSelectors.user(getState()))(getState, dispatch)
		.then(() => {
			dispatch(actions._initialize());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let navigateTo = lead => (getState, dispatch) => {
	const activeOrganizationId = commonSelectors.activeOrganizationId(getState());

	const referrerUrl = createReferrerUrl(history.location);
	const url = `/calls/buildings/${lead.building.id}${encodeQuery({
		referrer: 'leads',
		referrerUrl,
		leadId: lead.id,
	})}`;

	if (activeOrganizationId !== lead.organizationId && lead.organizationId) {
		// Change organization before navigating to calls if activeOrganizationId is not same as lead's organizationId
		dispatch(commonActions.setOrganizationChangeRedirect(url));
		dispatch(commonActions.setOrganization(lead.organizationId));
	} else {
		window.location.assign(url);
	}
};

export let createLead = () => (getState, dispatch) => {
	const referrerUrl = createReferrerUrl(history.location);
	history.push(`/leads/create${encodeQuery({referrerUrl, disableReturn: true})}`);
};
createLead = creator('creatLead', createLead);

export let editLead = ({id, referrer}) => (getState, dispatch) => {
	history.push(`/leads/lead/${id}${referrer}`);
};
editLead = creator('editLead', editLead);
