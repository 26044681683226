import React from 'react';

const SvgCalendar = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M3.5 5.25a.5.5 0 0 1 0 1H3a.5.5 0 1 1 0-1h.5zm2.5 0a.5.5 0 0 1 0 1h-.5a.5.5 0 1 1 0-1H6zm2.5 0a.5.5 0 0 1 0 1H8a.5.5 0 1 1 0-1h.5zm-5 2a.5.5 0 1 1 0 1H3a.5.5 0 0 1 0-1h.5zm2.5 0a.5.5 0 1 1 0 1h-.5a.5.5 0 0 1 0-1H6zm2.5 0a.5.5 0 1 1 0 1H8a.5.5 0 0 1 0-1h.5zm-5 2a.5.5 0 1 1 0 1H3a.5.5 0 1 1 0-1h.5zm2.5 0a.5.5 0 1 1 0 1h-.5a.5.5 0 1 1 0-1H6zm2.5 0a.5.5 0 1 1 0 1H8a.5.5 0 1 1 0-1h.5zm2-7.75a1 1 0 0 1 1 1V11a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V2.5a1 1 0 0 1 1-1h.75a.25.25 0 0 1 .25.25v1.125a.375.375 0 1 0 .75 0V.5a.5.5 0 0 1 1 0v.876c0 .068.056.124.125.124H7a.25.25 0 0 1 .25.25v1.125a.375.375 0 1 0 .75 0V.5a.5.5 0 1 1 1 0v.875c0 .069.056.125.125.125H10.5zm0 9.25v-6a.25.25 0 0 0-.25-.25h-9a.25.25 0 0 0-.25.25v6c0 .138.112.25.25.25h9a.25.25 0 0 0 .25-.25z"
		/>
	</svg>
);

export default SvgCalendar;
