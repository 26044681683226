import {action} from 'utils/redux';
import {P, Record, PaginatedData} from 'utils/types';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');

export const recheckQuery = creator('recheckQuery');

export const changePage = creator('changePage', P.Number);

export const openReportCreator = creator('openReportCreator');
export const closeReportCreator = creator('closeReportCreator');

export const saveReport = creator('saveReport');

export const removeReport = creator('removeReport', P.Number);

export const destroy = creator('destroy');

export const _updateReportsQuery = creator('_updateReportsQuery', P.Object);

export const _setUserTeams = creator('_setUserTeams', P.Array);

export const _setInitMeta = creator(
	'_setInitMeta',
	Record({allTeams: P.Array, allUsers: P.Array}),
);

export const _setReports = creator('_setReports', PaginatedData(P.Array));

export const _startOp = creator('_startOp');

export const _opOk = creator('_opOk');

export const _opFailed = creator('_opFailed');
