import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const openPlacesSuggestion = creator('openPlacesSuggestion', P.String);

export const updateBuildingsQuery = creator('updateBuildingsQuery', P.Object);

export const updateMap = creator('updateMap');

export const toggleTracking = creator('toggleTracking');

export const setMapSource = creator('setMapSource', P.String);

export const openBuilding = creator('openBuilding', P.Object);

export const closeBuilding = creator('closeBuilding');

export const addBuildingMarker = creator('addBuildingMarker');

export const removeBuildingMarker = creator('removeBuildingMarker');

export const openAddBuildingModal = creator('openAddBuildingModal');

export const closeAddBuildingModal = creator('closeAddBuildingModal');

export const addBuilding = creator('addBuilding', P.Object);

export const setLayerVisibility = creator('setLayerVisibility', P.Object);

export const _updateBuildingsQuery = creator('_updateBuildingsQuery', P.Object);

export const _setFollowingGeolocation = creator('_setFollowingGeolocation', P.Boolean);

export const _setGeolocationLoading = creator('_setGeolocationLoading', P.Boolean);

export const _setNewBuildingCoords = creator('_setNewBuildingCoords', P.Array);

export const _addBuilding = creator('_addBuilding');

export const _opFailed = creator('_opFailed');

export const _mapInitialized = creator('_mapInitialized');

export const getAvailableTags = creator('getAvailableTags');

export const _getAvailableTags = creator('_getAvailableTags', P.Array);
