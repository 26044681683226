import {effect} from 'utils/redux';
import * as actions from './actions';
import namespace from './namespace';
import {getCondosData, getAdditionalInfos, getProjectSalesmen} from './io';
import {encodeQuery} from 'utils/url';
import {replace} from 'utils/objects';
import {catchNonFatalDefault} from 'io/errors';
import {decorateWithNotifications} from 'io/app';
import services from 'services';
import {pushQuery, getQuery} from 'io/history';
import {mergeLeft} from 'ramda';
import * as selectors from './selectors';
import {parseCondosUrlQuery, formatCondosUrlQuery} from './utils';
import initState from './state';

const creator = effect(namespace);

// eslint-disable-next-line
let intl = null;
services.waitFor('intl').then(x => (intl = x));
const history = services.get('history');

export let initialize = () => (getState, dispatch) => {
	const nId = 'init-project-sales-tracking';

	const query = getQuery();
	const condosQuery = replace(parseCondosUrlQuery(query), initState.condosQuery);
	const lane = query.lane || 'need';

	decorateWithNotifications(
		{id: nId, failureStyle: 'error'},
		Promise.all([
			getCondosData(formatCondosUrlQuery(condosQuery)),
			getAdditionalInfos(),
			getProjectSalesmen(),
		]),
	)(getState, dispatch)
		.then(([condos, additionalInfos, salesmen]) => {
			dispatch(
				actions._initialize({
					condos,
					condosQuery,
					lane,
					additionalInfos,
					salesmen,
				}),
			);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let updateCondos = () => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'get-condos',
			//loading: intl.formatMessage({id: msgs.loading}),
			failureStyle: 'error',
		},
		getCondosData(selectors.urlQuery(getState())),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(condos => {
			dispatch(actions._setCondos(condos));
		})
		.catch(catchNonFatalDefault(getState, dispatch));

	pushQuery(mergeLeft(selectors.urlQuery(getState())));
};
updateCondos = creator('updateCondos', updateCondos);

export let navigateToCondo =
	([projectBuildingId, options]) =>
	(getState, dispatch) => {
		const referrerUrl =
			history.location.pathname + encodeQuery(selectors.urlQuery(getState()));
		const url = `/project-sales/condo/${projectBuildingId}${encodeQuery({referrerUrl})}`;
		if (options.newTab) {
			window.open(url, '_blank').focus();
		} else {
			history.push(url);
		}
	};
navigateToCondo = creator('navigateToCondo', navigateToCondo);
