import React from 'react';

const SvgBook = props => (
	<svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M24.669375,-0.005 C25.7092947,-0.005 26.5638985,0.788431981 26.6608417,1.80295146 L26.67,1.995625 L26.67,5.4447025 L26.7393751,5.46982562 C27.4229928,5.74053396 27.9218843,6.37706185 27.9945917,7.13795146 L28.00375,7.330625 L28.00375,30.004375 C28.00375,31.0442947 27.210318,31.8988985 26.1957985,31.9958417 L26.003125,32.005 L7.330625,32.005 C5.56276152,32.005 4.11623592,30.629189 4.00334246,28.8898611 L3.99625,28.670625 L3.99625,3.329375 L4.00334246,3.11013892 C4.11153203,1.44328305 5.44453305,0.110282027 7.11138892,0.00209245527 L7.330625,-0.005 L24.669375,-0.005 Z M5.33,5.99554125 L5.33,28.670625 C5.33,29.7105447 6.12343198,30.5651485 7.13795146,30.6620917 L7.330625,30.67125 L26.003125,30.67125 C26.3305071,30.67125 26.6027902,30.4353427 26.6592557,30.1242466 L26.67,30.004375 L26.67,7.330625 C26.67,7.00324287 26.4340927,6.73095984 26.1229966,6.67449425 L26.003125,6.66375 L7.330625,6.66375 C6.59401521,6.66375 5.91319326,6.42489391 5.36138643,6.02040905 L5.33,5.99554125 Z M24.669375,1.32875 L7.330625,1.32875 C6.22571033,1.32875 5.33,2.22446033 5.33,3.329375 C5.33,4.3692947 6.12343198,5.22389843 7.13795146,5.3208417 L7.330625,5.33 L22.66875,5.33 L22.66875,4.663125 C22.66875,4.33574287 22.4328427,4.06345984 22.1217466,4.00699425 L22.001875,3.99625 L7.330625,3.99625 C6.96232011,3.99625 6.66375,3.69767989 6.66375,3.329375 C6.66375,3.00199287 6.89965724,2.72970984 7.21075338,2.67324425 L7.330625,2.6625 L22.001875,2.6625 C23.0417947,2.6625 23.8963985,3.45593198 23.9933417,4.47045146 L24.0025,4.663125 L24.0025,5.33 L25.33625,5.33 L25.33625,1.995625 C25.33625,1.70916564 25.1556336,1.46489152 24.9020693,1.37047138 L24.7892466,1.33949425 L24.669375,1.32875 Z"
		/>
	</svg>
);

export default SvgBook;
