import {evolve, prop, any, contains, sort, ascend, isEmpty} from 'ramda';
import {replace} from 'utils/objects';
import {transform} from 'ol/proj';
import {parseNullableNumber} from 'utils/fields';
import initState from './state';
import {
	buildingsExtraInclude,
	buildingsInclude,
	buildingsPerPage,
	salesmanagerBuildingsExtraInclude,
	salesmanagerBuildingsInclude,
} from './constants';
import {ihSource} from 'dicts/calendarResources';

const parseNullableArr = val => (val ? val.split(',') : []);

export const parseUrlQuery = (query, isSalesmanager, showOffersForSalesman) => {
	const vals = evolve(
		{
			_page: Number,
			dismissPrompt: str => (str ? JSON.parse(str) : false),
			z: Number,
			x: Number,
			y: Number,
			selectionId: parseNullableNumber,
			productId: val => parseNullableArr(val).map(v => parseInt(v)),
			areaId: val => parseNullableArr(val).map(v => parseInt(v)),
			manufacturingYearStart: parseNullableNumber,
			manufacturingYearEnd: parseNullableNumber,
			organizationId: val => parseNullableArr(val).map(v => parseInt(v)),
		},
		query,
	);
	// don't allow non salesmanager user to change searchType manually via url query
	// if (!isSalesmanager && vals.searchType) delete vals.searchType; TEMPORARY removal due to covid 19
	// don't allow users to change encounterState to offers manually via url query if they don't have perms for it
	if (
		vals.encounterState === 'offer' &&
		!isSalesmanager &&
		!showOffersForSalesman &&
		vals.searchType === 'nearest'
	) {
		vals.encounterState = 'notContacted';
	}

	return {contactsQuery: replace(vals, initState.contactsQuery)};
};

export const formatUrlQuery = ({contactsQuery}) => {
	return {
		...contactsQuery,
		organizationId:
			contactsQuery.organizationId !== null
				? contactsQuery.organizationId.join(',')
				: null,
	};
};

export const formatFetchableContactsQuery = ({contactsQuery, teamAreas}) => {
	const {
		x,
		y,
		distance,
		encounterState,
		hasPhone,
		productId,
		searchType,
		areaId,
		manufacturingYearStart,
		manufacturingYearEnd,
		organizationId,
		minPrice,
		maxPrice,
	} = contactsQuery;

	const transformedCoords = transform([x, y], 'EPSG:3857', 'EPSG:4326');

	// because area select is now multiSelect, we need to get all areaIds for selected areas if searchType is area
	let areaIds = [];
	if (searchType === 'area') {
		areaIds = areaId.flatMap(id => {
			const matchingArea = teamAreas.find(area => area.id === id);
			return matchingArea ? matchingArea.areaIds : [];
		});
	}

	return {
		_page: 1,
		_limit: 300,
		withGeo: true,
		distance: searchType === 'nearest' ? distance : null,
		areaIds: searchType === 'area' ? areaIds : null,
		encounterState,
		hasPhone,
		productIds: encounterState === 'notContacted' ? null : productId,
		long: searchType === 'nearest' ? transformedCoords[0] : null,
		lat: searchType === 'nearest' ? transformedCoords[1] : null,
		manufacturingYearStart,
		manufacturingYearEnd,
		organizationIds: encounterState === 'installed' ? organizationId : null,
		minPrice,
		maxPrice,
	};
};

export const formatBuildingsFilterFormInput = ({contactsQuery}) => {
	const {
		distance,
		encounterState,
		hasPhone,
		productId,
		searchType,
		areaId,
		manufacturingYearEnd,
		manufacturingYearStart,
		organizationId,
	} = contactsQuery;

	return {
		distance,
		encounterState,
		hasPhone,
		productId,
		searchType,
		areaId,
		manufacturingYearStart,
		manufacturingYearEnd,
		organizationId,
	};
};

export const formatBuildingsFilterFormOutput = ({form}) => {
	const {
		distance,
		encounterState,
		hasPhone,
		productId,
		searchType,
		areaId,
		manufacturingYearStart,
		manufacturingYearEnd,
		organizationId,
	} = form;

	const getProductId = id => {
		let maxPrice = null;
		let minPrice = null;
		switch (id) {
			case 998:
				return {id: 6, maxPrice: 10000, minPrice};
			case 999:
				return {id: 5, minPrice: 2200, maxPrice};
			default:
				return {id, maxPrice, minPrice};
		}
	};

	const {id, maxPrice, minPrice} = getProductId(productId);

	return {
		_page: 1,
		dismissPrompt: true,
		distance,
		encounterState,
		hasPhone,
		productId: id,
		searchType,
		areaId,
		manufacturingYearStart,
		manufacturingYearEnd,
		organizationId,
		maxPrice,
		minPrice,
	};
};

export const formatOrganizationManufacturingYearLimits = manufacturingYearLimits => {
	const manufacturingYearStart = manufacturingYearLimits.start;
	const manufacturingYearEnd = manufacturingYearLimits.end;

	return {manufacturingYearStart, manufacturingYearEnd};
};

export const formatBuildingsToGeoJSON = buildings => {
	const features = buildings.map(b => {
		const client = b.clients && b.clients.length ? b.clients[0] : null;

		return {
			type: 'Feature',
			id: b.id,
			properties: {
				id: b.id,
				address: b.address,
				zip: b.zip,
				city: b.city,
				manufacturingYear: b.manufacturingYear,
				encounterState: b.encounterState,
				encounterDate: b.encounterDate,
				distance: b.distance ? `${(b.distance / 1000).toFixed(2)} km` : null,
				clientId: client ? client.id : null,
				clientFirstName: client ? client.firstName : null,
				clientLastName: client ? client.lastName : null,
			},
			geometry: {
				type: b.buildingCorners ? b.buildingCorners.type : b.location.type,
				coordinates: b.buildingCorners
					? b.buildingCorners.coordinates
					: b.location.coordinates,
			},
		};
	});

	return {
		type: 'FeatureCollection',
		features,
	};
};

export const getPageBuildings = ({buildings, currentPage}) => {
	const begin = (currentPage - 1) * buildingsPerPage;
	const end = begin + buildingsPerPage;
	return buildings.slice(begin, end);
};

export const formatTeamAreas = areas => {
	const cities = areas
		.filter(a => a.type === 'city')
		.map(a => ({...a, municipalName: a.title, areaIds: [a.id]}));
	const postCodes = areas.filter(a => a.type === 'postcode');

	postCodes.forEach(p => {
		// check if team has city with the same name as postCode's municipalName
		const i = cities.findIndex(c => c.municipalName === p.municipalName);
		if (i >= 0) {
			// add postCode area's id to city's areaIds if found
			cities[i].areaIds.push(p.id);
		} else {
			// add postCode area as new "city"
			cities.push({...p, areaIds: [p.id]});
		}
	});

	return sort(ascend(prop('municipalName')), cities);
};

export const getSalesmen = users =>
	users.filter(u => any(r => contains(r.type, ['salesman', 'salesmanager']), u.roles));

export const formatAddBonusFormOutput = ({
	dateFrom,
	clientId,
	salesmanId,
	teamId,
	buildingId,
	reserverId,
	crossSales,
	ihSource,
}) => {
	const date = new Date(dateFrom + 'T00:00:00Z');

	return {
		salesAssignmentBonus: true,
		dateFrom: date,
		dateTo: date,
		clientId,
		salesmanId,
		reservationSource: 'salesmanager',
		reserverId,
		teamId,
		buildingId,
		crossSales,
		ihSource,
	};
};

export const formatAddBonusFormInput = ({building, encounterState}) => {
	const clientId = building && building.clients[0] ? building.clients[0].id : null;

	return {
		clientId,
		ihSource:
			encounterState && Object.keys(ihSource).includes(encounterState)
				? encounterState
				: null,
	};
};

export const filterLatestEncounters = ({encounters, state, productId}) => {
	// filter encounters by given state and sort them (latest first)
	const _encounters = encounters
		.filter(e => e.state === state)
		.sort((a, b) => new Date(b.date) - new Date(a.date));

	const latest = [];

	if (productId && state !== 'offer') {
		// return latest encounter for selected productId
		const filteredByProduct = _encounters.filter(e =>
			e.products.some(p => p.id === productId),
		);

		if (filteredByProduct.length) {
			// need to show all offer encounters and not just the latest
			latest.push({
				...filteredByProduct[0],
				product: filteredByProduct[0].products.find(p => p.id === productId),
			});
		}
	} else {
		// return latest encounter for each product if productId is null
		// or if productId exists but state isn't offer
		_encounters.forEach(e => {
			// if encounter doesn't have known product, push encounter to latest and use organization title in the listing
			if (isEmpty(e.products)) {
				latest.push(e);
			} else {
				e.products.forEach(p => {
					if (!latest.find(l => !l.product || l.product.id === p.id)) {
						latest.push({...e, product: p});
					} else if (state === 'offer') {
						latest.push({...e, product: p});
					}
					// if state isn't offer and if there is already encounter with this product id in latest,
					// do nothing because that encounter is newer
				});
			}
		});
	}

	return latest;
};

export const isCrossSales = ({
	selectedProductId,
	organizationProducts,
	allEncounters,
	activeOrganizationId,
}) => {
	// if the selected product filter is another organization's product, it is crossSale
	if (selectedProductId && !organizationProducts.find(p => p.id === selectedProductId)) {
		return true;
	}

	// if allEncounters has encounter with installed state from another organization, it is crossSale
	if (allEncounters) {
		const diffOrgInstalled = allEncounters.filter(
			e => e.state === 'installed' && e.organization.id !== activeOrganizationId,
		);
		return !!diffOrgInstalled.length;
	}

	return false;
};

export const formatAllProducts = ({products, activeOrganizationId}) => {
	let extraProducts = [];

	// Show extra products only in Renoa Account (Organizations 1-8)
	if (activeOrganizationId <= 8) {
		extraProducts = [
			{
				id: 999,
				title: 'Osittaissaneeraus',
				maxPrice: 10000,
				organizations: [{id: 2}],
			},
			{
				id: 998,
				title: 'Paikkasukitettu huolto (?)',
				minPrice: 2200,
				organizations: [{id: 2}],
			},
		];
	}

	return [...products, ...extraProducts];
};

export const formatBuildingsInclude = ({encounterState, isSalesmanager}) => {
	if (isSalesmanager) {
		if (encounterState === 'installed') {
			return salesmanagerBuildingsInclude;
		} else if (encounterState === 'offer' || encounterState === 'cancelledDeal') {
			return salesmanagerBuildingsExtraInclude;
		} else {
			return buildingsInclude;
		}
	} else {
		if (encounterState === 'offer' || encounterState === 'cancelledDeal') {
			return buildingsExtraInclude;
		} else {
			return buildingsInclude;
		}
	}
};
