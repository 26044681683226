import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const _setOrganizations = creator('_setOrganizations', P.Array);

export const _setInitMeta = creator('_setInitMeta', P.Object);

export const _initialize = creator('_initialize');

export const updateData = creator('updateData');
