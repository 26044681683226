import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';

export default {
	...lifecycle,
	initialized: false,
	processing: false,
	tags: [],
	tagsQuery: {trashed: false},
	tagsLoading: true,
	tagsPagination: emptyPagination,
	isTagModalOpen: false,
	activeTag: null,
};
