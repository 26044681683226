import {locale} from 'constants/app';

export const activeCallPoolInclude = 'teams,areas,teams.users,profinderCallPoolLists';

export const issueInclude = 'user';

export const areaOffersQueryBase = {
	state: 'offer',
	include: 'user,products,client.building,calendarResource',
	_limit: 15,
	onlyWithClient: true,
};

export const callPoolAreasInclude = 'areas';

export const callPoolAreasRefreshRate = 10000;

export const drawTypes = {
	polygon: 'Polygon',
	circle: 'Circle',
};

export const emptyCustomAreaCreatorFormValues = {
	title: '',
	teams: [],
};

export const emptyCallPoolCreatorValues = {
	title: '',
	autoCall: false,
	noSwedish: false,
	onlySwedish: false,
	project: false,
	newCallPool: true,
	teams: [],
	products: {},
	allProducts: true,
	buildingTypes: [],
	heatingTypes: [],
	heatSources: [],
	oldOffers: false,
	otherInstalled: null,
	otherInstalledOrganization: null,
	otherInstalledProducts: [],
	blockedSeniorIds: [],
	smartData: [],
	allSmartData: true,
	blackListedBuildingTagIds: [],
	whiteListedBuildingTagIds: [],
	excludeUsers: [],
	defaultManufacturingYearStart: null,
	defaultManufacturingYearEnd: null,
	order: 'desc',
};

export const aggregateColors = {
	red: 'rgba(215, 109, 94, 0.9)',
	redLight: 'rgba(211, 131, 87, 0.9)',
	orange: 'rgba(217, 162, 74, 0.9)',
	yellow: 'rgba(227, 200, 73, 0.9)',
	greenYellow: 'rgba(210, 224, 49, 0.9)',
	green: 'rgba(175, 225, 41, 0.9)',
};

const layerVisibilityFilterOptsFi = [
	{label: 'Areas', value: 'areasLayer'},
	{label: 'Callpools', value: 'callPoolsLayer'},
	{label: 'Buildings', value: 'buildingsLayer'},
	{label: 'Groundwater areas', value: 'groundwaterAreasLayer'},
	{label: 'Property lines', value: 'propertyLinesLayer'},
];

const layerVisibilityFilterOptsSe = [
	{label: 'Areas', value: 'areasLayer'},
	{label: 'Callpools', value: 'callPoolsLayer'},
	{label: 'Buildings', value: 'buildingsLayer'},
];

export const layerVisibilityFilterOpts =
	locale === 'fi' ? layerVisibilityFilterOptsFi : layerVisibilityFilterOptsSe;

export const projectOnlyOrganizations = [5];

export const buildingInclude = 'clients,buildingProductStates.product';

export const encounterInclude = 'source,source.user,source.products';

export const removedAgeGroups = [
	{value: 'Not known', id: 0},
	{value: 'Seniors', id: 1},
	{value: 'Retirees', id: 2},
	{value: 'Active aged', id: 3},
];
