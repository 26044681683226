import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {getFormValues} from 'redux-form';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.login.type: {
			const {email = '', password = ''} = getFormValues('loginForm')(fullState) || {};
			return [{...state, loading: true}, effects.login({email, password})];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions._gotRedirect.type: {
			return [{...state, redirect: payload}, null];
		}

		case actions._loginOk.type: {
			return [{...state, loading: false}, null];
		}

		case actions._loginFailed.type: {
			return [{...state, loading: false}, null];
		}

		case actions.loginWithAd.type: {
			return [{...state, loading: true}, effects.loginWithAd()];
		}

		case actions._setLoading.type: {
			return [{...state, loading: true}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
