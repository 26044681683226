import React from 'react';

const SvgDashboard = props => (
	<svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M19.333 28c-1.102 0-2-.897-2-2v-9.333c0-1.103.898-2 2-2H26c1.103 0 2 .897 2 2V26c0 1.103-.897 2-2 2h-6.667zm0-12a.667.667 0 0 0-.666.667V26c0 .368.298.667.666.667H26a.667.667 0 0 0 .667-.667v-9.333A.667.667 0 0 0 26 16h-6.667zm0-4c-1.102 0-2-.897-2-2V6c0-1.103.898-2 2-2H26c1.103 0 2 .897 2 2v4c0 1.103-.897 2-2 2h-6.667zm0-6.667a.667.667 0 0 0-.666.667v4c0 .368.298.667.666.667H26a.667.667 0 0 0 .667-.667V6A.667.667 0 0 0 26 5.333h-6.667zM6 17.333c-1.103 0-2-.897-2-2V6c0-1.103.897-2 2-2h6.667c1.102 0 2 .897 2 2v9.333c0 1.103-.898 2-2 2H6zm0-12A.667.667 0 0 0 5.333 6v9.333c0 .368.299.667.667.667h6.667a.667.667 0 0 0 .666-.667V6a.667.667 0 0 0-.666-.667H6zM6 28c-1.103 0-2-.897-2-2v-4c0-1.103.897-2 2-2h6.667c1.102 0 2 .897 2 2v4c0 1.103-.898 2-2 2H6zm0-6.667a.667.667 0 0 0-.667.667v4c0 .368.299.667.667.667h6.667a.667.667 0 0 0 .666-.667v-4a.667.667 0 0 0-.666-.667H6zM2 32c-1.103 0-2-.897-2-2V2C0 .897.897 0 2 0h28c1.103 0 2 .897 2 2v28c0 1.103-.897 2-2 2H2zM2 1.333A.667.667 0 0 0 1.333 2v28c0 .368.299.667.667.667h28a.667.667 0 0 0 .667-.667V2A.667.667 0 0 0 30 1.333H2z"
		/>
	</svg>
);

export default SvgDashboard;
