import {effect} from 'utils/redux';
import * as actions from './actions';
import namespace from './namespace';
import {change} from 'redux-form';
import {getAreaCounties, getCondominiums, getUsers} from './io';
import {createReferrerUrl, encodeQuery} from 'utils/url';
import {replace} from 'utils/objects';
import {catchNonFatalDefault} from 'io/errors';
import {decorateWithNotifications} from 'io/app';
import services from 'services';
import {pushQuery, getQuery} from 'io/history';
import {mergeLeft} from 'ramda';
import {parseCondosUrlQuery, formatCondosUrlQuery} from './utils';
import * as selectors from './selectors';
import initState from './state';

const creator = effect(namespace);

// eslint-disable-next-line
let intl = null;
services.waitFor('intl').then(x => (intl = x));
const history = services.get('history');

export let initialize = () => (getState, dispatch) => {
	const nId = 'init-project-sales-customer-acquisition';

	const query = getQuery();
	const condosQuery = replace(parseCondosUrlQuery(query), initState.condosQuery);
	const lane = query.lane || 'callReminders';

	decorateWithNotifications(
		{id: nId, failureStyle: 'error'},
		Promise.all([
			getCondominiums(formatCondosUrlQuery(condosQuery)),
			getAreaCounties(),
			getUsers(),
		]),
	)(getState, dispatch)
		.then(([condos, areaCounties, users]) => {
			dispatch(
				actions._initialize({
					condos,
					condosQuery,
					lane,
					areaCounties,
					users,
				}),
			);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let updateCondos = () => (getState, dispatch) => {
	decorateWithNotifications(
		{id: 'get-condos', failureStyle: 'error'},
		getCondominiums(selectors.urlQuery(getState())),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(condos => {
			dispatch(actions._setCondos(condos));
		})
		.catch(catchNonFatalDefault(getState, dispatch));

	pushQuery(mergeLeft(selectors.urlQuery(getState())));
};
updateCondos = creator('updateCondos', updateCondos);

export let navigateToCondo =
	([projectBuildingId, options]) =>
	(getState, dispatch) => {
		pushQuery(mergeLeft(selectors.urlQuery(getState())));
		const referrerUrl = createReferrerUrl(history.location);
		const url = `/project-sales/condo/${projectBuildingId}${encodeQuery({
			referrerUrl,
		})}`;
		if (options.newTab) {
			window.open(url, '_blank').focus();
		} else {
			history.push(url);
		}
	};
navigateToCondo = creator('navigateToCondo', navigateToCondo);

export let clearFormFields =
	({form, formName}) =>
	(getState, dispatch) => {
		Object.keys(form).map(field => dispatch(change(formName, field, '')));
	};
clearFormFields = creator('clearFormFields', clearFormFields);
