import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	processing: false,
	userTeams: [],
	userTeamsLoading: true,
	teamTargets: {
		teamOfferCount: null,
		teamSalesPrice: null,
		userOfferCounts: [],
		userSalesPrices: [],
	},
	teamTargetsLoading: true,
	teamTargetsQuery: {
		date: new Date().toISOString().split('T')[0].slice(0, -3),
	},
};
