// core dependencies
import React, {useRef, useState, useEffect} from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
// custom helpers & utils
import {listUnstyled} from 'styles/fragments';
import NavOverflowMenu from './NavOverflowMenu';
import {setBgColor} from 'utils/headerColors';
const Nav = styled.ul`
	${listUnstyled};
	${({organizationColors}) => setBgColor(organizationColors?.bg)}
	display: flex;
	margin: 0px;
	width: 100%;

	// little negative margin hack to prevent overflow menu button getting cutoff
	margin-left: -50px;

	> :first-child {
		margin-left: 50px;
	}
`;

const NavbarNav = ({
	children,
	activeOrganization,
	overflowMenuOpen,
	toggleOverflowMenu,
}) => {
	const navRef = useRef(null);
	const [visibilityMap, setVisibilityMap] = useState({});

	const handleIntersection = entries => {
		const updatedEntries = {};

		entries.forEach(entry => {
			const targetid = entry.target.dataset.targetid;
			if (entry.isIntersecting) {
				updatedEntries[targetid] = true;
			} else {
				updatedEntries[targetid] = false;
			}
		});

		setVisibilityMap(prev => ({
			...prev,
			...updatedEntries,
		}));
	};

	useEffect(() => {
		const observer = new IntersectionObserver(handleIntersection, {
			root: navRef.current,
			threshold: 0.9,
		});

		Array.from(navRef.current.children).forEach(item => {
			if (item.dataset.targetid) {
				observer.observe(item);
			}
		});

		return () => observer.disconnect();
	}, []);

	return (
		<Nav
			ref={navRef}
			organizationId={activeOrganization.id}
			organizationColors={activeOrganization?.meta?.companyColors}
		>
			{React.Children.map(children, child =>
				React.cloneElement(child, {
					organizationId: activeOrganization.id,
					isVisible: !!visibilityMap[child.props['data-targetid']],
				}),
			)}
			<NavOverflowMenu
				visibilityMap={visibilityMap}
				organizationId={activeOrganization.id}
				menuOpen={overflowMenuOpen}
				toggleMenu={toggleOverflowMenu}
			>
				{children}
			</NavOverflowMenu>
		</Nav>
	);
};

NavbarNav.propTypes = {
	children: propTypes.node,
	activeOrganization: propTypes.object.isRequired,
};

export default styled(NavbarNav)``;
