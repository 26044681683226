import services from 'services';
import {map, pipe, prop} from 'ramda';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {mapResponseData, getResponseData} from 'utils/app';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getRoles = query =>
	httpJson('get', '/roles', {...query, include: 'permissions'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.role)));

export const postRole = role =>
	httpJson('post', `/roles/`, {include: 'permissions'}, {body: role})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save role'})))
		.then(getResponseData(normalize.role));

export const updateRole = (id, role) =>
	httpJson('put', `/roles/${id}`, {include: 'permissions'}, {body: role})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save role'})))
		.then(getResponseData(normalize.role));

export const getPermissions = () =>
	httpJson('get', '/permissions')
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));
