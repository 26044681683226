import {merge, __, mergeLeft, pipe, defaultTo, assoc} from 'ramda';
import {effect} from 'utils/redux';
import {P} from 'utils/types';
import {describeThrow} from 'utils/errors';
import {over} from 'utils/lenses';
import services from 'services';
import {decorateWithNotifications} from 'io/app';
import {catchNonFatalDefault, warn} from 'io/errors';
import {replaceQuery, getQuery, pushQuery} from 'io/history';
import {geocodeGooglePlaceId} from 'io/geo';
import {addMarker, getMapTileSource} from 'io/maps';
import {transform} from 'ol/proj';
import {
	buildingFocusZoom,
	maxZoom,
	groundwaterAreasMinZoom,
	propertyLinesMinZoom,
} from 'constants/maps';
import {medDur} from 'constants/notifications';
import * as rootSelectors from 'modules/common/selectors';
import * as nActions from 'modules/notifications/actions';
import {getTags} from 'modules/common/io';
import namespace from './namespace';
import * as actions from './actions';
import * as selectors from './selectors';
import {parseUrlQuery} from './utils';
import {initFreerideMap, getBuildingsStyle, postBuilding} from './io';
import cache from './cache';
import * as Ls from 'io/localStorage';
import {TYPE_BUILDING} from 'modules/usersApp/tagsPage/constants';
import {appName} from '../constants';

const nsStr = namespace.join('.');

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const initMap = (getState, dispatch) => {
	const apiToken = rootSelectors.apiToken(getState());
	const org = rootSelectors.activeOrganization(getState());

	const storedQuery = Ls.getJson(`${nsStr}:query`) || {};
	// if visibility not present in url, use the values from localStorage
	const sourceQuery = {...storedQuery, ...getQuery()};
	const {buildingsQuery} = parseUrlQuery(sourceQuery);
	dispatch(actions._updateBuildingsQuery(buildingsQuery));

	const {
		z,
		x,
		y,
		encounterState,
		minYear,
		maxYear,
		tagIds,
		selectionId,
		mapSource,
		groundwaterAreasLayer,
		propertyLinesLayer,
	} = buildingsQuery;

	decorateWithNotifications(
		{id: 'map-init'},
		initFreerideMap({
			apiToken,
			organizationId: org.id,
			initialZoom: z,
			initialCenter: [x, y],
			initialFilters: {encounterState, minYear, maxYear, tagIds},
			selectionId,
			manufacturingLimit: org.meta.manufacturingLimit,
			mapSourceProps: {sourceId: mapSource},
			layersVisibility: {
				groundwaterAreasLayer,
				propertyLinesLayer,
			},
			getLayersVisibility: () => selectors.layersVisibility(getState()),
			onBuildingClick: (buildingId, coords) =>
				dispatch(actions.openBuilding({buildingId, coords})),
			onMapLocationChanged: location => dispatch(actions.updateBuildingsQuery(location)),
			onGeolocationChanged: location => {
				// remove loading state once we get the first geolocation
				const loading = selectors.geolocationLoading(getState());
				if (loading) dispatch(actions._setGeolocationLoading(false));
			},
			onGeolocationError: error => {
				dispatch(actions._setGeolocationLoading(false));
				const id = 'geolocation-error';
				// error.code === 1 means user denied geolocation
				// TODO: add intl.formatted msg for that error?
				error.description = intl.formatMessage({
					id: 'Could not determine location. Try refreshing the page.',
				});
				warn(nActions.warning, {id, duration: medDur}, error)(getState, dispatch);
			},
			// passed as a function in order to pass it by reference
			getFollowingGeolocation: () => selectors.followingGeolocation(getState()),
			setFollowingGeolocation: following =>
				dispatch(actions._setFollowingGeolocation(following)),
		}),
	)(getState, dispatch)
		.then(resources => {
			cache.update(over(['freerideMap'], merge(__, resources)));
			dispatch(actions._mapInitialized());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};

const _removeBuildingMarker = () => {
	const {
		freerideMap: {map, markerLayer, dragInteraction},
	} = cache.read();

	if (markerLayer) {
		// remove marker
		const source = markerLayer.getSource();
		source.clear();
	}
	if (map && dragInteraction) {
		// clean up drag interaction
		map.removeInteraction(dragInteraction);
	}
};

export let initialize = () => (getState, dispatch) => {
	initMap(getState, dispatch);
};
initialize = creator('initialize', initialize);

export let openPlacesSuggestion = placeId => (getState, dispatch) => {
	geocodeGooglePlaceId(placeId)
		.then(res => {
			const {lat, lng} = res.geometry.location;
			const coord = transform([lng(), lat()], 'EPSG:4326', 'EPSG:3857');
			const {
				freerideMap: {map, markerLayer},
			} = cache.read();

			// stop map from following geolocation when opening places suggestion
			dispatch(actions._setFollowingGeolocation(false));

			const view = map.getView();
			view.animate({center: coord, zoom: buildingFocusZoom});

			if (markerLayer) {
				// remove old marker
				_removeBuildingMarker();

				// add marker at place's location
				const source = markerLayer.getSource();
				addMarker({id: placeId, coord, source});
			}
		})
		.catch(describeThrow(intl.formatMessage({id: 'Search failed'})))
		.catch(catchNonFatalDefault(getState, dispatch));
};
openPlacesSuggestion = creator('openPlacesSuggestion', openPlacesSuggestion, P.String);

export let updateBuildingsQuery = prevQuery => (getState, dispatch) => {
	const currQuery = selectors.urlQuery(getState());
	replaceQuery(merge(__, currQuery));

	if (
		currQuery.encounterState !== prevQuery.encounterState ||
		currQuery.minYear !== prevQuery.minYear ||
		currQuery.maxYear !== prevQuery.maxYear ||
		currQuery.z !== prevQuery.z ||
		currQuery.tagIds !== prevQuery.tagIds
	) {
		// update buildings layer style based on new query
		const {
			freerideMap: {buildingsLayer},
		} = cache.read();

		if (buildingsLayer) {
			const {z, encounterState, minYear, maxYear, selectionId, tagIds} = currQuery;

			const org = rootSelectors.activeOrganization(getState());
			getBuildingsStyle({
				zoom: z,
				filters: {encounterState, minYear, maxYear, tagIds},
				selectionId,
				manufacturingLimit: org.meta.manufacturingLimit,
			})
				.then(style => buildingsLayer.setStyle(style))
				.catch(catchNonFatalDefault(getState, dispatch));
		}
	}
};
updateBuildingsQuery = creator('updateBuildingsQuery', updateBuildingsQuery);

export let updateMap = () => (getState, dispatch) => {
	const {
		freerideMap: {buildingsLayer, map},
	} = cache.read();

	// handle edge case where the map can get "unmounted" while the module is on background if the window size changes
	if (!map.isRendered()) {
		map.dispose();
		initMap(getState, dispatch);
	}

	buildingsLayer.getSource().refresh();
	// TODO: proto for updating the the map without re-fetching data
	//const {z, encounterState, minYear, maxYear, selectionId} = selectors.urlQuery(getState());
	//getBuildingsStyle({
	//zoom: z,
	//filters: {encounterState, minYear, maxYear},
	//selectionId,
	//localStates: {1769915: {encounterState: 'installed'}},
	//}).then(x => buildingsLayer.setStyle(x));
};
updateMap = creator('updateMap', updateMap);

export let toggleTracking = () => (getState, dispatch) => {
	const {
		freerideMap: {map, geolocation, locationLayer},
	} = cache.read();

	const view = map.getView();
	const geoCenter = geolocation.getPosition();
	const trackingEnabled = geolocation.getTracking();
	const followingGeolocation = selectors.followingGeolocation(getState());

	if (!trackingEnabled) {
		// turn on geolocation
		dispatch(actions._setFollowingGeolocation(true));
		dispatch(actions._setGeolocationLoading(true));
		geolocation.setTracking(true);
		locationLayer.setVisible(true);
	} else if (!followingGeolocation) {
		// user has moved screen away from current geolocation, focus back on it
		dispatch(actions._setFollowingGeolocation(true));
		view.animate({center: geoCenter, zoom: maxZoom});
	} else {
		// turn off geolocation
		dispatch(actions._setFollowingGeolocation(false));
		dispatch(actions._setGeolocationLoading(false));
		geolocation.setTracking(false);
		locationLayer.setVisible(false);
	}
};
toggleTracking = creator('toggleTracking', toggleTracking);

export let setMapSource = id => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	const {
		freerideMap: {mapLayer},
	} = cache.read();

	if (mapLayer) {
		getMapTileSource({user: rootSelectors.user(getState()), sourceId: id})
			.then(s => mapLayer.setSource(s))
			.catch(catchNonFatalDefault(getState, dispatch));
	}
};
setMapSource = creator('setMapSource', setMapSource, P.String);

export let openBuilding =
	({buildingId, coords}) =>
	(getState, dispatch) => {
		// update selected buildingId to url query
		const currQuery = selectors.urlQuery(getState());
		replaceQuery(merge(__, currQuery));

		// highlight on map
		const {z, encounterState, minYear, maxYear, selectionId, tagIds} = currQuery;
		const {
			freerideMap: {buildingsLayer, map},
		} = cache.read();

		if (buildingsLayer) {
			const org = rootSelectors.activeOrganization(getState());
			getBuildingsStyle({
				zoom: z,
				filters: {encounterState, minYear, maxYear, tagIds},
				selectionId,
				manufacturingLimit: org.meta.manufacturingLimit,
			})
				.then(style => buildingsLayer.setStyle(style))
				.catch(catchNonFatalDefault(getState, dispatch));
		}

		if (map) {
			// recalculate map viewport size
			// setTimeout for React to have enough time to rerender
			setTimeout(() => {
				map.updateSize();
				// center map to building's coords if provided
				if (coords) {
					const view = map.getView();
					view.animate({center: coords, duration: 200});
				}
			}, 200);
		}
	};
openBuilding = creator('openBuilding', openBuilding);

export let destroy = () => (getState, dispatch) => {
	cache.reset();
};
destroy = creator('destroy', destroy);

export let addBuildingMarker = () => (getState, dispatch) => {
	const {
		freerideMap: {map, markerLayer},
	} = cache.read();

	// add marker at current center
	if (map && markerLayer) {
		const coord = map.getView().getCenter();
		const source = markerLayer.getSource();
		source.clear();

		addMarker({
			id: 'new-building-marker',
			coord,
			source,
			map,
			draggable: true,
			onDragEnd: coords => dispatch(actions._setNewBuildingCoords(coords)),
		})
			.then(dragInteraction => {
				// draggable marker returns dragInteraction so that we can clean it up later
				cache.update(over(['freerideMap'], merge(__, {dragInteraction})));
				dispatch(actions._setNewBuildingCoords(coord));
				dispatch(
					nActions.info({
						id: 'drag-marker-info',
						message: intl.formatMessage({
							id: 'You can adjust the position of the building by dragging the marker',
						}),
						duration: medDur,
					}),
				);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	}
};
addBuildingMarker = creator('addBuildingMarker', addBuildingMarker);

export let removeBuildingMarker = () => (getState, dispatch) => {
	_removeBuildingMarker();
};
removeBuildingMarker = creator('removeBuildingMarker', removeBuildingMarker);

export let addBuilding = building => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'add-building',
			failureStyle: 'error',
			loading: intl.formatMessage({id: 'Saving'}),
			success: intl.formatMessage({id: 'Building created'}),
		},
		postBuilding(building),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(() => {
			dispatch(actions._addBuilding());
			_removeBuildingMarker();

			// update map buildings
			const {
				freerideMap: {buildingsLayer},
			} = cache.read();

			if (buildingsLayer) {
				buildingsLayer.getSource().refresh();
			}
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
addBuilding = creator('addBuilding', addBuilding);

export let setLayerVisibility =
	({layer, visible}) =>
	(getState, dispatch) => {
		pushQuery(mergeLeft(selectors.urlQuery(getState())));
		const {z} = selectors.buildingsQuery(getState());
		const {freerideMap} = cache.read();

		if (freerideMap[layer]) {
			if (layer === 'groundwaterAreasLayer' && z >= groundwaterAreasMinZoom) {
				freerideMap[layer].setVisible(visible);
			}

			if (layer === 'propertyLinesLayer' && z >= propertyLinesMinZoom) {
				freerideMap[layer].setVisible(visible);
			}
		}
		Ls.updateJson(`${nsStr}:query`, pipe(defaultTo({}), assoc(layer, visible)));
	};
setLayerVisibility = creator('setLayerVisibility', setLayerVisibility);

export let getAvailableTags = () => (getState, dispatch) => {
	getTags({getAllTags: false, type: TYPE_BUILDING, view: appName}).then(
		({data: tags}) => {
			dispatch(actions._getAvailableTags(tags));
		},
	);
};
getAvailableTags = creator('getAvailableTags', getAvailableTags);
