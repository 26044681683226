import {pipeHandlers} from 'utils/reduxEffects';
import common from './common/handler';
import overview from './overviewPage/handler';
import freeCalResReport from './freeCalResReportPage/handler';
import workingHoursReportPage from './workingHoursReportPage/handler';
import callsOverviewPage from './callsPage/handler';
import trackingCallsPage from './trackingPage/handler';
import encounterableEndingReasonsPage from './encounterableEndingReasonsPage/handler';
import smsLogsPage from './smsLogsPage/handler';

export default pipeHandlers(
	common,
	overview,
	freeCalResReport,
	workingHoursReportPage,
	callsOverviewPage,
	trackingCallsPage,
	encounterableEndingReasonsPage,
	smsLogsPage,
);
