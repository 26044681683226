import {path, pipe, prop} from 'ramda';
import {formValueSelector, getFormError, hasSubmitFailed} from 'redux-form';
import namespace from './namespace';
import {createSelector} from 'reselect';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import createDatePickSelectors from 'fragments/calendarResourcePicker/selectors';
import createCallReminderSelectors from 'fragments/callReminder/selectors';
import {formatSalesmanVisitFormInput, formatClientFormInput} from './utils';
import {userCanCancelVisit} from 'utils/perms';
import * as commonSelectors from 'modules/common/selectors';
import {createEmptyClient} from './constants';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, s => !!s.calendarResource);

export const processing = pipe(root, prop('processing'));

export const isNew = pipe(root, s => !s.salesmanVisit);

export const calendarResource = pipe(root, prop('calendarResource'));

export const calendarResourceDateEditOpen = pipe(
	root,
	prop('calendarResourceDateEditOpen'),
);

export const building = pipe(root, prop('building'));

export const buildingPreviewOpen = pipe(root, prop('buildingPreviewOpen'));

export const salesmanVisitClient = pipe(root, prop('salesmanVisitClient'));

export const salesmanVisit = pipe(root, prop('salesmanVisit'));

export const encounters = pipe(root, prop('encounters'));

export const encounterPreviewOpen = pipe(root, prop('encounterPreviewOpen'));

export const prevVisitState = pipe(root, prop('prevVisitState'));

export const previewableEncounter = pipe(root, prop('previewableEncounter'));

export const salesmen = pipe(root, prop('salesmen'));

export const newAppointment = pipe(root, prop('newAppointment'));

export const clients = pipe(root, prop('clients'));

export const formDataInitialized = pipe(
	root,
	s => !!s.calendarResource && !s.salesmenLoading,
);

export const clientEditorOpen = pipe(root, prop('clientEditorOpen'));
export const clientChangerOpen = pipe(root, prop('clientChangerOpen'));

export const client = pipe(root, prop('client'));

// comments, TEMP
export const comments = pipe(root, prop('comments'));
export const commentEditOpen = pipe(root, prop('commentEditOpen'));
// ...

export const productStatesLoading = pipe(root, prop('productStatesLoading'));

export const allProductStates = pipe(root, prop('allProductStates'));

export const activeTab = pipe(root, prop('activeTab'));

export const calResDateFormInitValues = createSelector(
	[calendarResource],
	calendarResource => ({
		dateFrom: calendarResource && calendarResource.dateFrom,
	}),
);

export const salesmanVisitFormInitValues = createSelector(
	[salesmanVisit],
	salesmanVisit => formatSalesmanVisitFormInput({salesmanVisit}),
);

export const clientFormInitValues = createSelector([client], client =>
	formatClientFormInput({
		client: client || createEmptyClient(),
	}),
);

const datePickSelectors = createDatePickSelectors({namespace});

export const {
	freeCalendarResources,
	pendingCalendarResourceSelection,
	lastCalendarResourceSelection,
} = datePickSelectors;

const callReminderSelectors = createCallReminderSelectors({namespace});

export const {
	callReminder,
	callReminderFormInitValues,
	callReminderLoading,
	callReminderEditorOpen,
} = callReminderSelectors;

export const canCancelVisit = createSelector(
	[commonSelectors.user, calendarResource],
	(user, calendarResource) =>
		calendarResource && userCanCancelVisit({user, calendarResource}),
);

export const salesmanVisitFormState = state =>
	formValueSelector('salesmanVisitForm')(state, 'state');

// form-wide error, the one set with the special _error property
export const salesmanVisitFormError = state => getFormError('salesmanVisitForm')(state);

export const salesmanVisitFormSubmitFailed = state =>
	hasSubmitFailed('salesmanVisitForm')(state);

export const salesmanVisits = pipe(root, prop('salesmanVisits'));

export const availableTags = pipe(root, prop('availableTags'));

export const tags = pipe(root, prop('tags'));

export const processingTags = pipe(root, prop('processingTags'));

export const processingTag = pipe(root, prop('processingTag'));
