import services from 'services';
import {map, pipe, path} from 'ramda';
import {describeThrow} from 'utils/errors';
import {reportsQueryBase, reportInclude} from './constants';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {getResponseData, mapResponseData} from 'utils/app';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getOrganizations = () =>
	httpJson('get', '/organizations', {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.organization)));

export const getTeams = query =>
	httpJson('get', '/teams', query)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.team)));

export const getReports = query =>
	httpJson('get', '/reports', {...reportsQueryBase, include: reportInclude, ...query})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.report)));

export const getUserTeams = () =>
	httpJson('get', '/users/me', {include: 'teams'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(pipe(path(['teams', 'data']), map(normalize.team))));

export const getUsers = query =>
	httpJson('get', '/users', query)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.user)));

export const postReport = report =>
	httpJson('post', '/reports', {}, {body: report})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save report'})))
		.then(getResponseData(map(normalize.report)));

export const deleteReport = id =>
	httpJson('delete', `/reports/${id}`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to delete report'})),
	);
