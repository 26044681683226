import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize(payload)];
		}

		case actions.getPdf.type: {
			return [{...state, processing: true}, effects.fetchPdf()];
		}

		case actions.getExcel.type: {
			return [{...state, processing: true}, effects.fetchExcel()];
		}

		case actions.removeReport.type: {
			return [state, effects.removeReport()];
		}

		case actions.setSort.type: {
			const newState = {...state, sort: payload, pdfUrl: null, excelUrl: null};

			return [newState, effects.setSort(payload)];
		}

		case actions.destroy.type: {
			return [initState, effects.destroy()];
		}

		case actions._setReport.type: {
			return [{...state, report: payload}, null];
		}

		case actions._setPdf.type: {
			return [{...state, pdfUrl: payload, processing: false}, null];
		}

		case actions._setExcel.type: {
			return [{...state, excelUrl: payload, processing: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
