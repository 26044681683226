import {action} from 'utils/redux';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const fetchReport = creator('fetchReport');

export const _setReport = creator('_setReport');

export const _updateReportQuery = creator('_updateReportQuery');
