import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {createSelector} from 'reselect';
import * as commonSelectors from '../common/selectors';
import {formatTeamFormInput, formatUrlQuery} from './utils';
import {createEmptyTeam} from './constants';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const teamsPagination = pipe(root, prop('teamsPagination'));

export const processing = pipe(root, prop('processing'));

export const teamsLoading = pipe(root, prop('teamsLoading'));

export const selectedTeam = pipe(root, prop('selectedTeam'));

export const teams = pipe(root, prop('teams'));

export const teamsQueryRaw = pipe(root, prop('teamsQuery'));

export const teamCreatorOpen = pipe(root, prop('teamCreatorOpen'));

export const teamAreasSelectorOpen = pipe(root, prop('teamAreasSelectorOpen'));

export const teamAreasSelectorSearch = pipe(root, prop('teamAreasSelectorSearch'));

export const teamAreasSelectorSuggestionsOpen = pipe(
	root,
	prop('teamAreasSelectorSuggestionsOpen'),
);

export const teamAreasSelectorSuggestions = pipe(
	root,
	prop('teamAreasSelectorSuggestions'),
);

export const teamsQueryFetchable = teamsQueryRaw;

export const urlQuery = createSelector([teamsQueryRaw], teamsQuery => {
	return formatUrlQuery({teamsQuery});
});

export const teamFormInitValues = createSelector(
	[selectedTeam, commonSelectors.organizations],
	(selectedTeam, organizations) =>
		formatTeamFormInput({
			team: selectedTeam || createEmptyTeam({organizations}),
			organizations,
		}),
);
