import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	stats: {},
	statsLoading: true,
	targets: {},
	targetsLoading: true,
	timeEntryModalOpen: false,
	timeEntry: {},
	targetOpen: null,
	deals: [],
	dealsLoading: true,
	callReminders: null,
	callRemindersLoading: true,
};
