import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const saveTargets = creator('saveTargets', P.Object);

export const updateTeamTargetsQuery = creator('updateTeamTargetsQuery', P.Object);

export const _setUserTeams = creator('_setUserTeams', P.Array);

export const _saveTargets = creator('_saveTargets');

export const _setTeamTargets = creator('_setTeamTargets', P.Object);

export const _updateTeamTargetsQuery = creator('_updateTeamTargetsQuery', P.Object);

export const _opFailed = creator('_opFailed');
