import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	initialized: false,
	processing: false,
	availableTeams: [],
	activeTeamId: null,
	dashboardTargets: [],
	dashboardTargetsQuery: {teamId: [], date: null},
	dashboardTargetsLoading: false,
};
