import {effect} from 'utils/redux';
import {decorateWithNotifications} from 'io/app';
import {catchNonFatalDefault} from 'io/errors';
import {postCallReminder, putCallReminder, deleteCallReminder} from './io';
import msgs from 'dicts/messages';
import services from 'services';
import {reset} from 'redux-form';
import * as confirmerActions from 'modules/confirmer/actions';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

export default ({namespace, actions}) => {
	const creator = effect(namespace);

	const saveCallReminder = ({callReminder, callReminderId}) => (getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'save-call-reminder',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Reminder saved'}),
			},
			callReminderId
				? putCallReminder(callReminder, callReminderId)
				: postCallReminder(callReminder),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._setCallReminderLoading(false));
				throw e;
			})
			.then(callReminder => {
				dispatch(actions._setCallReminder(callReminder));
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	const saveCalendarCallReminder = ({callReminder}) => (getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'save-call-reminder',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Reminder saved'}),
			},
			postCallReminder(callReminder),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._setCallReminderLoading(false));
				throw e;
			})
			.then(callReminder => {
				dispatch(reset('callReminder'));
				dispatch(actions._setCalendarCallReminder(callReminder));
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	const removeCallReminder = callReminderId => (getState, dispatch) => {
		const onConfirm = () => {
			dispatch(actions._setCallReminderLoading(true));
			decorateWithNotifications(
				{
					id: 'remove-call-reminder',
					failureStyle: 'error',
					loading: intl.formatMessage({id: msgs.processing}),
					success: intl.formatMessage({id: 'Reminder deleted'}),
				},
				deleteCallReminder(callReminderId),
			)(getState, dispatch)
				.catch(e => {
					dispatch(actions._setCallReminderLoading(false));
					throw e;
				})
				.then(() => {
					dispatch(actions._setCallReminder(null));
				})
				.catch(catchNonFatalDefault(getState, dispatch));
		};

		dispatch(
			confirmerActions.show({
				message: intl.formatMessage({id: 'Delete reminder?'}),
				cancelText: intl.formatMessage({id: msgs.cancel}),
				onCancel: () => {},
				onOk: onConfirm,
			}),
		);
	};

	const setReminderSeen = callReminderId => (getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'set-reminder-seen',
				failureStyle: 'warning',
				loading: intl.formatMessage({id: msgs.processing}),
			},
			putCallReminder({seen: true}, callReminderId),
		)(getState, dispatch)
			.catch(e => {
				throw e;
			})
			.then(reminder => {
				dispatch(actions._setCalendarCallReminderSeen(reminder));
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	return {
		saveCallReminder: creator('saveCallReminder', saveCallReminder),
		removeCallReminder: creator('removeCallReminder', removeCallReminder),
		setReminderSeen: creator('setReminderSeen', setReminderSeen),
		saveCalendarCallReminder: creator(
			'saveCalendarCallReminder',
			saveCalendarCallReminder,
		),
	};
};
