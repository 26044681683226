import {effect} from 'utils/redux';
import namespace from './namespace';
import * as actions from './actions';
import services from 'services';
import {decorateWithNotifications, setPageTitleMessage} from 'io/app';
import * as nActions from 'modules/notifications/actions';
import {medDur} from 'constants/notifications';
import {
	getOrganizations as getOrganizationsIO,
	getTeams as getTeamsIO,
	fetchTeamWithUsers as fetchTeamWithUsersIO,
	fetchTeamsWithUsers as fetchTeamsWithUsersIO,
	getCalls as getCallsIO,
	getSalesmanVisits as getSalesmanVisitsIO,
	getVisits as getVisitsIO,
	getCityOptions,
} from './io';
import {catchNonFatalDefault} from 'io/errors';
import {TYPE_CALL, TYPE_SALESMAN_VISIT, TYPE_VISIT} from './constants';
import {omit} from 'ramda';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const creator = effect(namespace);

export let getTeams =
	(query = {}) =>
	(getState, dispatch) => {
		return decorateWithNotifications(
			{
				id: 'fetch-teams',
				failuterStyle: 'error',
			},
			getTeamsIO(query),
		)(getState, dispatch).then(result => {
			dispatch(actions._getTeams(result));
		});
	};

getTeams = creator('getTeams', getTeams);

export let getOrganizations = () => (getState, dispatch) => {
	return decorateWithNotifications(
		{
			id: 'fetch-accounts',
			failureStyle: 'error',
		},
		getOrganizationsIO(),
	)(getState, dispatch).then(result => {
		dispatch(actions._getOrganizations(result));
	});
};

getOrganizations = creator('getOrganizations', getOrganizations);

export let initialize = () => (getState, dispatch) => {
	setPageTitleMessage('Overview [app]')(getState, dispatch);
	dispatch(actions.getTeams());
	dispatch(actions.getCities());
	dispatch(actions._initialize());
};
initialize = creator('initialize', initialize);

export let fetchTeamWithUsers = data => (getState, dispatch) => {
	const store = services.get('store');
	const teamId = data.toString();

	if (!teamId) {
		const invalidTeamIdMsg =
			intl.formatMessage({id: 'Required field'}) +
			' - ' +
			intl.formatMessage({id: 'Team'});
		store.dispatch(
			nActions.warning({
				id: '',
				message: invalidTeamIdMsg,
				duration: medDur,
			}),
		);
		return;
	}
	return decorateWithNotifications(
		{
			id: 'get-team-with-users',
			failureStyle: 'error',
		},
		fetchTeamWithUsersIO(teamId),
	)(getState, dispatch).then(result => {
		dispatch(actions._setUsers(result.users.data));
	});
};

fetchTeamWithUsers = creator('fetchTeamWithUsers', fetchTeamWithUsers);

export let fetchTeamsWithUsers = teamIds => (getState, dispatch) => {
	const store = services.get('store');

	if (!Array.isArray(teamIds)) {
		const invalidTeamIdMsg =
			intl.formatMessage({id: 'Required field'}) +
			' - ' +
			intl.formatMessage({id: 'Team'});
		store.dispatch(
			nActions.warning({
				id: '',
				message: invalidTeamIdMsg,
				duration: medDur,
			}),
		);
		return;
	}

	if (teamIds.length <= 0) {
		return dispatch(actions._setUsers([]));
	}

	return decorateWithNotifications(
		{
			id: 'get-team-with-users',
			failureStyle: 'error',
		},
		fetchTeamsWithUsersIO(teamIds.toString()),
	)(getState, dispatch).then(result => {
		const users = [];
		result.forEach(team => {
			team.users?.data.forEach(user => {
				if (!users.some(u => u.id === user.id)) users.push(user);
			});
		});

		dispatch(actions._setUsers(users));
	});
};

fetchTeamsWithUsers = creator('fetchTeamsWithUsers', fetchTeamsWithUsers);

export let setTeamType = teamType => (getState, dispatch) => {
	dispatch(actions._setTeamType(teamType));
	getTeams(teamType)(getState, dispatch);
};

setTeamType = creator('setTeamType', setTeamType);

export let getCities = () => (getState, dispatch) => {
	return decorateWithNotifications(
		{
			id: 'fetch-cities',
			failuterStyle: 'error',
		},
		getCityOptions(),
	)(getState, dispatch).then(result => {
		dispatch(actions._getCities(result.map(({title}) => title)));
	});
};

getCities = creator('getCities', getCities);

const toTypeData = (q, type) => {
	const common = {
		_page: q._page,
		_sort: q._sort,
	};
	if (type === TYPE_CALL) {
		return {
			...common,
			createdAtStart: q.createdAtStart,
			createdAtEnd: q.createdAtEnd,
			userId: q.userId,
			teamId: q.teamId,
			municipalName: q.municipality,
			teamType: q.teamType,
		};
	}

	if (type === TYPE_SALESMAN_VISIT) {
		return {
			...common,
			dateFromStart: q.createdAtStart,
			dateFromEnd: q.createdAtEnd,
			salesmanIds: q.salesmanIds,
			reserverIds: q.reserverIds,
			teamId: q.teamId,
			municipalName: q.municipality,
			state: q.state,
			teamType: q.teamType,
			ihSource: q.ihSource,
		};
	}

	if (type === TYPE_VISIT) {
		return {
			...common,
			createdAtStart: q.createdAtStart,
			createdAtEnd: q.createdAtEnd,
			userId: q.userId,
			teamId: q.teamId,
			municipalName: q.municipality,
			teamType: q.teamType,
		};
	}
};

export let getEncounterables = q => (getState, dispatch) => {
	const ioFns = {
		[TYPE_CALL]: getCallsIO,
		[TYPE_SALESMAN_VISIT]: getSalesmanVisitsIO,
		[TYPE_VISIT]: getVisitsIO,
	};
	const ioFn = ioFns[q.type];
	const data = omit(['type'], toTypeData(q, q.type));
	return decorateWithNotifications(
		{
			id: 'fetch-encounterables',
			failuterStyle: 'error',
		},
		ioFn(data),
	)(getState, dispatch)
		.then(result => {
			dispatch(actions._getEncounterables(result));
		})
		.catch(catchNonFatalDefault);
};

getEncounterables = creator('getEncounterables', getEncounterables);
