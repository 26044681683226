import React from 'react';

const SvgLunch = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="#FFF"
			fillRule="evenodd"
			d="M7.875,0 C9.041,0 10.218,3.0275 10.2500099,6.6215 L10.2500099,6.6215 C10.2509378,6.79104601 10.1853909,6.95056264 10.0679768,7.06797678 C9.95056264,7.18539091 9.79104601,7.25093776 9.625,7.25 L9.625,7.25 L8.75,7.25 C8.61192881,7.25 8.5,7.36192881 8.5,7.5 L8.5,7.5 L8.5,11.5 C8.5,11.7761424 8.27614237,12 8,12 C7.72385763,12 7.5,11.7761424 7.5,11.5 L7.5,11.5 L7.5,0.375 C7.5,0.167893219 7.66789322,0 7.875,0 Z M5.5,0 C5.77614237,0 6,0.223857625 6,0.5 L6,0.5 L6,3 C5.99879218,3.84732156 5.46493909,4.60236237 4.6665,4.886 C4.56674908,4.92134664 4.50006065,5.01567171 4.5,5.1215 L4.5,5.1215 L4.5,11.5 C4.5,11.7761424 4.27614237,12 4,12 C3.72385763,12 3.5,11.7761424 3.5,11.5 L3.5,11.5 L3.5,5.1215 C3.49993935,5.01567171 3.43325092,4.92134664 3.3335,4.886 C2.53506091,4.60236237 2.00120782,3.84732156 2,3 L2,3 L2,0.5 C2,0.223857625 2.22385763,0 2.5,0 C2.77614237,0 3,0.223857625 3,0.5 L3,0.5 L3,3 C2.99979171,3.2614296 3.10232711,3.51247073 3.2855,3.699 C3.32120846,3.73492019 3.37500874,3.74585501 3.4219062,3.72672434 C3.46880366,3.70759367 3.49960481,3.66214779 3.5,3.6115 L3.5,3.6115 L3.5,0.5 C3.5,0.223857625 3.72385763,0 4,0 C4.27614237,0 4.5,0.223857625 4.5,0.5 L4.5,0.5 L4.5,3.6125 C4.50039519,3.66314779 4.53119634,3.70859367 4.5780938,3.72772434 C4.62499126,3.74685501 4.67879154,3.73592019 4.7145,3.7 C4.89791598,3.51322388 5.00047091,3.2617757 5,3 L5,3 L5,0.5 C5,0.223857625 5.22385763,0 5.5,0 Z"
		/>
	</svg>
);

export default SvgLunch;
