import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const processing = pipe(root, prop('processing'));

export const loading = pipe(root, prop('loading'));

export const importHistory = pipe(root, prop('importHistory'));

export const isFileUploadModalOpen = pipe(root, prop('isFileUploadModalOpen'));

export const importHistoryQuery = pipe(root, prop('importHistoryQuery'));

export const importHistoryPagination = pipe(root, prop('importHistoryPagination'));
