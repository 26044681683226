import {action} from 'utils/redux';
import namespace from './namespace';
import {P, PaginatedData} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const changePage = creator('changePage', P.Number);

export const searchBuildings = creator('searchBuildings', P.Object);

export const recheckQuery = creator('recheckQuery');

export const openBuilding = creator('openBuilding', P.Number);

export const restoreBuilding = creator('restoreBuilding', P.Number);

export const openAddBuildingModal = creator('openAddBuildingModal');

export const closeAddBuildingModal = creator('closeAddBuildingModal');

export const addBuilding = creator('addBuilding', P.Object);

export const _setBuildings = creator('_setBuildings', PaginatedData(P.Array));

export const _updateBuildingsQuery = creator('_updateBuildingsQuery', P.Object);

export const _showPrompt = creator('_showPrompt');

export const _opOk = creator('_opOk');

export const _opFailed = creator('_opFailed');
