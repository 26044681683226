import {language, supportedLanguages} from 'constants/loc';

export const getUserLanguage = user => {
	// backend uses incorrect code for Swedish
	const userLang = user.language === 'se' ? 'sv' : user.language;
	return supportedLanguages.includes(userLang) ? userLang : language;
};

export const getNavigatorLanguage = () =>
	navigator.languages && navigator.languages.length
		? navigator.languages[0]
		: navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
