import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const saveTargets = creator('saveTargets', P.Object);

export const updateOrganizationTargetsQuery = creator(
	'updateOrganizationTargetsQuery',
	P.Object,
);

export const _saveTargets = creator('_saveTargets');

export const _setOrganizationTargets = creator('_setOrganizationTargets', P.Object);

export const _updateOrganizationTargetsQuery = creator(
	'_updateOrganizationTargetsQuery',
	P.Object,
);

export const _opFailed = creator('_opFailed');
