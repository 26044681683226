import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const openPlacesSuggestion = creator('openPlacesSuggestion', P.String);

export const setAreasSelectorType = creator('setAreasSelectorType', P.String);

export const openAddAreaModal = creator('openAddAreaModal', P.Object);

export const closeAddAreaModal = creator('closeAddAreaModal');

export const updateTeamAreas = creator('updateTeamAreas', P.Object);

export const removeTeamArea = creator('removeTeamArea', P.Object);

export const changeTeam = creator('changeTeam', P.Object);

export const recheckQuery = creator('recheckQuery');

export const _setTeam = creator('_setTeam', P.Object);

export const _updateTeamAreas = creator('_updateTeamAreas', P.Object);

export const _startOp = creator('_startOp');

export const _opFailed = creator('_opFailed');

export const _setTeams = creator('_setTeams');
