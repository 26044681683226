import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	initialized: false,
	marketingCategories: [],
	marketingChannels: [],
	marketingCustomerTypes: [],
	marketingSources: [],
	deleteLeadModalOpen: false,
	leadToDelete: null,
};
