import React from 'react';

const SvgDashboard = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M6 8.125a8.396 8.396 0 0 0-4.576 1.368.25.25 0 0 1-.342-.06A5.997 5.997 0 0 1 6.247.005 6.055 6.055 0 0 1 12 5.94a5.965 5.965 0 0 1-1.083 3.494.25.25 0 0 1-.341.06A8.395 8.395 0 0 0 6 8.125zM5.625 1.75v.75a.375.375 0 1 0 .75 0v-.75a.375.375 0 0 0-.75 0zM2.5 6.375a.375.375 0 0 0 0-.75h-.75a.375.375 0 1 0 0 .75h.75zM3.79 3.79a.375.375 0 0 0 0-.53l-.53-.53a.375.375 0 0 0-.53.53l.53.53a.374.374 0 0 0 .53 0zm2.17 3.433c.314.003.617-.119.842-.34.378-.377 2.316-3.52 2.536-3.877a.25.25 0 0 0-.344-.344c-.357.22-3.5 2.158-3.878 2.535a1.183 1.183 0 0 0 .843 2.026zm3.54-.848h.75a.375.375 0 1 0 0-.75H9.5a.375.375 0 1 0 0 .75zM6 8.875a7.523 7.523 0 0 1 4.015 1.15.253.253 0 0 1 .038.397 5.987 5.987 0 0 1-8.107 0 .254.254 0 0 1 .038-.397A7.525 7.525 0 0 1 6 8.875z"
		/>
	</svg>
);

export default SvgDashboard;
