import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const handleFeedback = creator('handleFeedback', P.Object);

export const changePage = creator('changePage', P.Number);

export const _setFeedback = creator('_setFeedback', P.Object);

export const _updateFeedback = creator('_updateFeedback', P.Object);

export const _updateFeedbackQuery = creator('_updateFeedbackQuery', P.Object);

export const _setProcessing = creator('_setProcessing', P.Boolean);
