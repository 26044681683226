import {IntlProvider} from '@meiko/react-intl';
import {allMessages, language as localeLang} from 'constants/loc';

// NOTE: also see views/IntlProvider! both are used.

// note: needs to be re-created every time language changes
export default ({language: lang}) => {
	// note that locale default language is static. we want to ensure uniform display of dates and currencies across users, using the current locale's convention regardless of the selected message language.
	const p = new IntlProvider(
		{locale: localeLang, messages: allMessages[lang], defaultLocale: localeLang},
		{},
	);
	return p.getChildContext().intl;
};
