import services from 'services';
import {pipe, prop} from 'ramda';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {overviewQueryBase, overviewInclude, overviewIncludeWithTeams} from './constants';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getCallPools = () =>
	httpJson('get', '/callPools', {
		...overviewQueryBase,
		include: overviewIncludeWithTeams,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), normalize.callPool));

export const boostCallPoolArea = ({areaId, callPoolId}) =>
	httpJson('post', `/callPools/${callPoolId}/areas/${areaId}/boost`, {
		...overviewQueryBase,
		include: overviewInclude,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentPostFailed})))
		.then(pipe(prop('data'), normalize.callPool));

export const getCallPoolWithAreaInfo = cid =>
	httpJson('get', `/callPools/${cid}`, {...overviewQueryBase, include: overviewInclude})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), normalize.callPool));

export const setAreaActivity = ({areaId, callPoolId, active}) =>
	httpJson(
		'put',
		`/callPools/${callPoolId}/areas/${areaId}`,
		{},
		{
			body: {active},
		},
	)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), normalize.callPool));

export const deleteCallPoolArea = ({areaId, callPoolId}) =>
	httpJson('delete', `/callPools/${callPoolId}/areas/${areaId}`)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentPostFailed})))
		.then(pipe(prop('data'), normalize.callPool));

export const getAreas = () =>
	httpJson('get', '/areas', {
		select: 'title,subtitle,id',
		withoutTransform: true,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const postAddAreaToCallPool = ({callPoolId, areaData}) =>
	httpJson('post', `/callPools/${callPoolId}/areas`, {}, {body: areaData})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to add areas'})))
		.then(prop('data'));
