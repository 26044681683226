import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';

export default {
	...lifecycle,
	initialized: false,
	processing: false,
	smsTemplates: [],
	smsTemplatesQuery: {trashed: false},
	smsTemplatesLoading: true,
	smsTemplatesPagination: emptyPagination,
	isSmsTemplateModalOpen: false,
	activeSmsTemplate: null,
};
