import * as actions from './actions';
import * as selectors from './selectors';
import {catchNonFatalDefault} from 'io/errors';
import {
	getTeams,
	getUserTeams,
	getReports,
	getUsers,
	postReport,
	deleteReport,
} from './io';
import {effect} from 'utils/redux';
import namespace from './namespace';
import {decorateWithNotifications} from 'io/app';
import {getQuery, pushQuery} from 'io/history';
import {equals, mergeLeft} from 'ramda';
import {resolveObject} from 'utils/promises';
import {parseUrlQuery} from './utils';
import services from 'services';
import msgs from 'dicts/messages';
import * as confirmerActions from 'modules/confirmer/actions';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const history = services.get('history');

const fetchReportsData = (getState, dispatch) => {
	const reportsQuery = selectors.reportsQueryFetchable(getState());
	const usersQuery = {
		...selectors.allUsersQueryFetchable(getState()),
		_limit: '999',
	};

	return Promise.all([
		resolveObject({
			allTeams: getTeams({_limit: '999'}),
			allUsers: getUsers(usersQuery),
		}).then(data => {
			dispatch(actions._setInitMeta(data));
		}),
		getReports(reportsQuery).then(reports => {
			dispatch(actions._setReports(reports));
		}),
	]);
};

const fetchReports = ({notifyOpts = {}}) => (getState, dispatch) => {
	return decorateWithNotifications(
		{id: 'get-reports', failureStyle: 'error', ...notifyOpts},
		getReports(selectors.reportsQueryFetchable(getState())),
	)(getState, dispatch).then(reports => {
		dispatch(actions._setReports(reports));
	});
};

export let initialize = () => (getState, dispatch) => {
	const {reportsQuery} = parseUrlQuery(getQuery());
	dispatch(actions._updateReportsQuery(reportsQuery));

	decorateWithNotifications(
		{id: 'init-reports', failureStyle: 'error'},
		// setup user teams first since other fetches depend on the information
		getUserTeams()
			.then(teams => {
				dispatch(actions._setUserTeams(teams));
			})
			.then(() => fetchReportsData(getState, dispatch)),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let recheckQuery = () => (getState, dispatch) => {
	const urlQueries = parseUrlQuery(getQuery());
	const reportsQuery = selectors.reportsQuery(getState());
	if (!equals(reportsQuery, urlQueries.reportsQuery)) {
		dispatch(actions._updateReportsQuery(urlQueries.reportsQuery));

		fetchReports({})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}
};

export let updateReports = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));

	fetchReports({})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
updateReports = creator('updateReports', updateReports);

export let createReport = report => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'post-report',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		postReport(report),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(report => {
			dispatch(actions._opOk());

			history.push(`/reports/reports/${report.id}`);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
createReport = creator('createReport', createReport);

export let removeReport = id => (getState, dispatch) => {
	const onConfirm = () => {
		dispatch(actions._startOp());

		decorateWithNotifications(
			{
				id: 'delete-report',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Report deleted'}),
			},
			deleteReport(id),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._opOk());

				const {_page: page} = selectors.reportsQuery(getState());
				if (selectors.reports(getState()).length <= 1 && page > 1) {
					dispatch(actions._updateReportsQuery({_page: page - 1}));
					pushQuery(mergeLeft(selectors.urlQuery(getState())));
				}

				return fetchReports({
					notifyOpts: {loading: intl.formatMessage({id: msgs.loading})},
				})(getState, dispatch);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete report?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
removeReport = creator('removeReport', removeReport);
