import {scopedHandler} from 'utils/redux';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {formatStatistics} from './utils';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.navigateToTargetPage.type: {
			return [state, effects.navigateToTargetPage(payload)];
		}

		case actions.setStatsType.type: {
			const newState = {
				...state,
				statsQuery: {
					...state.statsQuery,
					statsType: payload,
				},
				statsLoading: true,
				targetsLoading: true,
				statistics: initState.statistics,
				salesPriceTarget: initState.salesPriceTarget,
				offerCountTarget: initState.offerCountTarget,
			};

			return [newState, effects.fetchAllData()];
		}

		case actions._setTargets.type: {
			// use initial target values if api doesn't return anything
			const salesPriceTarget = payload[0][0] ? payload[0][0] : initState.salesPriceTarget;
			const offerCountTarget = payload[1][0] ? payload[1][0] : initState.offerCountTarget;

			return [
				{...state, salesPriceTarget, offerCountTarget, targetsLoading: false},
				null,
			];
		}

		case actions.fetchData.type: {
			const selectedDate = new Date(payload).toISOString().split('T')[0].slice(0, -3);

			const newState = {
				...state,
				statsQuery: {...state.statsQuery, selectedDate},
				targetsLoading: true,
				statsLoading: true,
			};

			return [newState, effects.fetchAllData()];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._setStatistics.type: {
			const statistics = formatStatistics(payload, state.statsQuery.statsType);

			return [{...state, statistics: statistics, statsLoading: false}, null];
		}

		case actions._setUserTeam.type: {
			return [{...state, userTeam: payload}, null];
		}

		case actions._updateStatsQuery.type: {
			return [{...state, statsQuery: {...state.statsQuery, ...payload}}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
