import React from 'react';

const SvgPin = props => (
	<svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M16 14.667a5.339 5.339 0 0 1-5.333-5.334A5.339 5.339 0 0 1 16 4a5.339 5.339 0 0 1 5.333 5.333A5.339 5.339 0 0 1 16 14.667zm0-9.334c-2.205 0-4 1.795-4 4 0 2.206 1.795 4 4 4s4-1.794 4-4c0-2.205-1.795-4-4-4zm-.001 23.472a1.336 1.336 0 0 1-1.12-.613C12.84 25.005 6.193 14.269 6.193 9.808a9.742 9.742 0 0 1 2.875-6.932A9.738 9.738 0 0 1 15.998 0c5.41.001 9.809 4.401 9.809 9.808 0 4.464-6.647 15.199-8.684 18.383a1.329 1.329 0 0 1-1.124.614zm0-27.472c-4.672.002-8.474 3.803-8.472 8.475 0 4.1 6.485 14.556 8.473 17.664 1.988-3.107 8.473-13.563 8.473-17.664 0-4.672-3.801-8.473-8.474-8.475V1v.333zM16 32c-3.616 0-7.445-.253-10.245-.679C1.883 30.735 0 29.865 0 28.667c0-1.51 3.103-2.542 9.22-3.064a.66.66 0 0 1 .721.608.664.664 0 0 1-.608.721c-5.977.512-7.772 1.473-7.992 1.753.335.544 5.208 1.982 14.659 1.982 9.36 0 14.23-1.41 14.65-1.991-.249-.292-2.078-1.243-8.04-1.745a.664.664 0 0 1-.608-.721.67.67 0 0 1 .725-.609C28.879 26.12 32 27.152 32 28.667c0 1.198-1.883 2.068-5.755 2.654-2.8.426-6.629.679-10.245.679z"
		/>
	</svg>
);

export default SvgPin;
