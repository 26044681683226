import {mergeLeft} from 'ramda';
import {effect} from 'utils/redux';
import namespace from './namespace';
import {decorateWithNotifications} from 'io/app';
import {catchNonFatalDefault} from 'io/errors';
import {pushQuery, getQuery} from 'io/history';
import services from 'services';
import {getTargets, getStatistics, getUserTeams} from './io';
import * as actions from './actions';
import * as selectors from './selectors';
import {createReferrerUrl, encodeQuery} from 'utils/url';
import * as commonSelectors from 'modules/common/selectors';
import {parseUrlQuery} from './utils';

const creator = effect(namespace);

const history = services.get('history');

const fetchData = (getState, dispatch) => {
	const {targetsQueries, statsQuery} = selectors.allQueries(getState());
	return decorateWithNotifications(
		{id: 'get-targets', failureStyle: 'warning'},
		Promise.all([
			...targetsQueries.map(q => getTargets(q)),
			getStatistics(statsQuery).then(stats => dispatch(actions._setStatistics(stats))),
		]),
	)(getState, dispatch)
		.then(t => dispatch(actions._setTargets(t)))
		.catch(catchNonFatalDefault(getState, dispatch));
};

export let initialize = () => (getState, dispatch) => {
	const user = commonSelectors.user(getState());
	const isSalesManager = selectors.isSalesmanagerUser(getState());
	const isAdmin = selectors.isAdminUser(getState());

	const {statsQuery} = parseUrlQuery(getQuery(), user, isSalesManager, isAdmin);
	dispatch(actions._updateStatsQuery(statsQuery));

	decorateWithNotifications(
		{id: 'get-user-teams', failureStyle: 'warning'},
		getUserTeams().then(teams => {
			if (teams.length) dispatch(actions._setUserTeam(teams[0]));
		}),
	)(getState, dispatch)
		.then(_ => fetchData(getState, dispatch))
		.then(_ => dispatch(actions._initialize()))
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let navigateToTargetPage = ({type, date}) => (getState, dispatch) => {
	const referrerUrl = createReferrerUrl(history.location);
	const targetPage = type === 'organization' ? 'organization-target' : 'team-target';

	history.push(
		`/salesman-app/${targetPage}${encodeQuery({
			date,
			referrer: 'stats',
			referrerUrl,
		})}`,
	);
};
navigateToTargetPage = creator('navigateToTargetPage', navigateToTargetPage);

export let fetchAllData = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	fetchData(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
