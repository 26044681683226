import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {
	formatFetchableLeadsQuery,
	formatLeadsFilterFormInput,
	formatUrlQuery,
} from './utils';
import * as commonSelectors from 'modules/common/selectors';
import * as perms from 'utils/perms';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const leads = pipe(root, prop('leads'));

export const leadsLoading = pipe(root, prop('leadsLoading'));

export const leadsPagination = pipe(root, prop('leadsPagination'));

export const leadsQuery = pipe(root, prop('leadsQuery'));

export const urlQuery = createSelector(leadsQuery, q => formatUrlQuery(q));

export const sources = pipe(root, prop('sources'));

export const utmCampaigns = pipe(root, prop('utmCampaigns'));

export const utmContents = pipe(root, prop('utmContents'));

export const domains = pipe(root, prop('domains'));

export const products = pipe(root, prop('products'));

export const organizations = pipe(root, prop('organizations'));

export const showAllMarketingLeads = createSelector([commonSelectors.user], user =>
	perms.showAllMarketingLeads(user),
);

export const showActiveOrganizationMarketingLeads = createSelector(
	[commonSelectors.user],
	user => perms.showActiveOrganizationMarketingLeads(user),
);

export const leadsQueryFetchable = createSelector(
	[leadsQuery, commonSelectors.user, commonSelectors.activeOrganizationId],
	(leadsQuery, user, organizationId) =>
		formatFetchableLeadsQuery({
			leadsQuery,
			user,
			organizationId,
			showAllMarketingLeads,
			showActiveOrganizationMarketingLeads,
		}),
);

export const userId = pipe(root, prop('userId'));

export const processing = pipe(root, prop('processing'));

export const leadsFilterFormInitValues = createSelector([leadsQuery], leadsQuery =>
	formatLeadsFilterFormInput({leadsQuery}),
);

export const deleteLeadModalOpen = pipe(root, prop('deleteLeadModalOpen'));

export const leadToDelete = pipe(root, prop('leadToDelete'));

export const deleteLeadFormInitValues = createSelector([leadToDelete], lead => ({
	lead,
	deletedReason: null,
}));

export const showTrashedLeads = createSelector(
	[leadsQuery],
	leadsQuery => leadsQuery.trashed,
);

export const handlers = pipe(root, prop('handlers'));

export const commentModalOpen = pipe(root, prop('commentModalOpen'));

export const leadToComment = pipe(root, prop('leadToComment'));

export const areas = pipe(root, prop('areas'));
