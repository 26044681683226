import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';

export default {
	...lifecycle,
	initialized: false,
	processing: false,
	reasonMappings: [],
	reasonMappingsQuery: {trashed: false},
	reasonMappingsLoading: true,
	reasonMappingsPagination: emptyPagination,
	isReasonMappingModalOpen: false,
	activeReasonMapping: null,
	availableTags: [],
};
