export const teamQueryBase = {
	_limit: 15,
};

export const createEmptyTeam = () => ({
	title: '',
	type: '',
	areas: [],
	additionalCalendarOrganizations: [],
});
