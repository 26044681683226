import {state as lifecycle} from 'fragments/lifecycle';
import {formatTimeEntriesSearchInput} from './utils';

export default {
	...lifecycle,
	initialized: false,
	processing: false,
	timeEntries: {},
	loadingTimeEntries: true,
	users: [],
	selectedUserId: null,
	creatorOpen: false,
	editorOpen: false,
	entryInEdit: null,
	timeEntriesQuery: {...formatTimeEntriesSearchInput(), userId: null},
};
