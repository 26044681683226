import services from 'services';
import {map} from 'ramda';
import {describeThrow} from 'utils/errors';
import {formFillsQueryBase, formFillsInclude} from './constants';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {getResponseData, mapResponseData} from 'utils/app';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getFormFills = query =>
	httpJson('get', '/formFills', {
		...formFillsQueryBase,
		include: formFillsInclude,
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.formFill)));

export const getTeams = query =>
	httpJson('get', '/teams', query)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.team)));

export const getTrackingForm = query =>
	httpJson('get', '/forms/2', query)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(normalize.form));

export const postTrackingForm = form =>
	httpJson('post', '/formFills', {include: 'formAttributes,user'}, {body: form})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save tracking form'})))
		.then(getResponseData(normalize.form));

export const deleteTrackingForm = id =>
	httpJson('delete', `/formFills/${id}`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to delete tracking form'})),
	);
