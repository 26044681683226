import React from 'react';

const SvgHeadset = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M3.5 5.75c.315.237.5.607.5 1v2c0 .394-.185.764-.5 1l-.225.169a.25.25 0 0 1-.4-.2V5.781a.25.25 0 0 1 .4-.2l.225.17zM8.725 9.92L8.5 9.75a1.25 1.25 0 0 1-.5-1v-2c0-.393.185-.763.5-1l.225-.169a.25.25 0 0 1 .4.2V9.72a.25.25 0 0 1-.4.2zM11 5.025c.582.119 1 .631 1 1.225v3a1.25 1.25 0 0 1-1.053 1.235.25.25 0 0 0-.193.155c-.264.67-.91 1.11-1.629 1.11H7.661a.25.25 0 0 0-.166.063A.745.745 0 0 1 7 12h-.5a.75.75 0 0 1 0-1.5H7a.75.75 0 0 1 .495.188.25.25 0 0 0 .166.062h1.464a.75.75 0 0 0 .75-.75V4.875a3.875 3.875 0 1 0-7.75 0v5.375a.25.25 0 0 1-.25.25H1.25C.56 10.5 0 9.94 0 9.25v-3c0-.595.42-1.108 1.005-1.225a.153.153 0 0 0 .122-.15 4.875 4.875 0 1 1 9.75 0c0 .073.052.135.123.15z"
		/>
	</svg>
);

export default SvgHeadset;
