// TODO: some of these are hardly used, instead local definitions littered everywhere

export const salesRoleTypes = new Set(['salesman', 'salesmanager']);

export const subcontractorRoleSlugs = new Set([
	'aaa.palvelut.buukkari',
	'aaa.palvelut.paallikko',
]);

export const thirdPartyRoleSlugs = new Set(['asiakas', 'asiakasmyyjae']);

export const roleSlugsWithIssueCreation = new Set(['admin', 'aluemyyntijohtaja']);

//admin role slugs for salesman app
export const salesmanAppAdminRoleSlugs = new Set([
	'admin',
	'aluemyyntijohtaja',
	'myyntijohtaja',
]);

export const marketingLeadsRoles = new Set([
	'admin',
	'aluemyyntijohtaja',
	'myyntijohtaja',
]);
