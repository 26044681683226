import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';
import {getResponseData, mapResponseData} from 'utils/app';
import * as normalize from 'utils/normalize';
import {map} from 'ramda';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getSmsLogs = q =>
	httpJson('get', '/smsLogs', {
		...q,
		include: 'organization,user',
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(res => {
			const {meta} = res;
			const data = getResponseData(normalize.smsLog)(res);
			return {
				data,
				meta,
			};
		});

export const getUsers = query =>
	httpJson('get', '/users', query)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.user)));
