import {throttle} from 'throttle-debounce';
import services from 'services';
import {state} from 'dicts/encounters';
import importMaps from 'services/importMaps';
import {fontWeightMedium} from 'styles/constants';
import {getHereTileUrl, getMapboxTileUrl} from 'constants/providers';
import {describeThrow} from 'utils/errors';
import * as nActions from 'modules/notifications/actions';
import {longestDur} from 'constants/notifications';
import {apiKeys} from 'modules/common/selectors';
import {fullName} from 'utils/people';
import {
	styles as mapStyles,
	maxZoom,
	localeCenterWeb,
	localeZoomFactor,
	areasMaxDetailZoom,
} from 'constants/maps';
import {getJsonProperties, getJsonProperty} from 'utils/maps';
import {encodeQuery} from 'utils/url';
import {deleteChildren} from 'utils/dom';
import {apiUrl} from 'constants/app';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const decorateMapMethod = method => args =>
	importMaps()
		.then(method(args))
		.catch(describeThrow(intl.formatMessage({id: 'Error loading map'})));

const dateFmtOpts = {
	weekday: 'short',
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
};

const dateFmtOptsShort = {
	year: 'numeric',
	month: '2-digit',
	day: '2-digit',
};

// OL seems to throw an exception sometimes when a layer is still "mounting". just catch any exceptions thrown.
export const safeGetFeaturesAtPixel = (map, pixel, opts) => {
	try {
		const features = map.getFeaturesAtPixel(pixel, opts);
		return features && features.length ? features : null;
	} catch (err) {
		return null;
	}
};

export const safeHasFeatureAtPixel = (map, pixel, opts) => {
	try {
		return map.hasFeatureAtPixel(pixel, opts);
	} catch (err) {
		return null;
	}
};

// layers array should be in priority order
// if there is feature in multiple layers at the same pixel,
// updateTooltip is called for the layer that comes first in the array
export const addTooltipToLayers = ({
	mapEl,
	map,
	layers = [],
	tooltipEl,
	tooltip,
	updateTooltip: updateTooltipContent,
	getDrawing = () => false,
}) => {
	map.addOverlay(tooltip);

	tooltipEl.className += ' show';

	const _updateTooltip = e => {
		if (getDrawing()) {
			tooltip.setPosition(null);
			return;
		}

		let foundFeatures = false;
		for (let i = 0; i < layers.length; i++) {
			const features = safeGetFeaturesAtPixel(map, e.pixel, {
				layerFilter: l => l === layers[i],
			});
			if (features) {
				tooltip.setPosition(e.coordinate);
				updateTooltipContent(features[0], layers[i]);
				foundFeatures = true;
				return;
			}
		}

		if (!foundFeatures) {
			tooltip.setPosition(null);
		}
	};
	const updateTooltip = throttle(50, _updateTooltip);

	map.on('pointermove', updateTooltip);

	mapEl.addEventListener('touchstart', e => {
		tooltipEl.className = tooltipEl.className.replace(/(^|\s)show($|\s)/, ' ');
		map.removeOverlay(tooltip);
		map.un('pointermove', updateTooltip);
	});
};

// domain-specific map data helpers

export const renderBuildingTooltip = ({
	tooltipEl,
	address,
	manufacturingYear,
	encounterState,
	encounterDate,
	distance,
	clientId,
	clientFirstName,
	clientLastName,
}) => {
	const addressEl = document.createElement('div');
	addressEl.textContent = address;
	addressEl.style.fontWeight = fontWeightMedium;
	addressEl.style.marginBottom = '5px';
	tooltipEl.appendChild(addressEl);

	const manufacturingYearEl = document.createElement('div');
	manufacturingYearEl.textContent = intl.formatMessage(
		{id: 'Manufacturing year: {manufacturingYear}'},
		{
			manufacturingYear: manufacturingYear
				? manufacturingYear
				: intl.formatMessage({id: 'Not known'}),
		},
	);
	tooltipEl.appendChild(manufacturingYearEl);

	const encounterStateEl = document.createElement('div');
	encounterStateEl.textContent = intl.formatMessage(
		{id: 'Building status: {encounterState}'},
		{
			// TODO: should probably use the state returned by utils/buildings.state here, but this is ok too
			encounterState: encounterState
				? intl.formatMessage({id: state[encounterState]})
				: intl.formatMessage({id: 'Not known'}),
		},
	);
	tooltipEl.appendChild(encounterStateEl);

	const encDate = encounterDate ? new Date(encounterDate) : null;
	const encounterDateEl = document.createElement('div');
	encounterDateEl.textContent = intl.formatMessage(
		{id: 'Status updated: {time}'},
		{
			time: encDate
				? intl.formatDate(encDate, dateFmtOpts)
				: intl.formatMessage({id: 'Not known'}),
		},
	);
	tooltipEl.appendChild(encounterDateEl);

	if (distance) {
		const distanceEl = document.createElement('div');
		distanceEl.textContent = intl.formatMessage(
			{id: 'Distance: {distance}'},
			{
				distance: distance ? distance : intl.formatMessage({id: 'Not known'}),
			},
		);
		tooltipEl.appendChild(distanceEl);
	}

	const clientEl = document.createElement('div');
	clientEl.textContent = `${intl.formatMessage({id: 'Client'})}: ${
		clientId
			? fullName({
					firstName: clientFirstName,
					lastName: clientLastName,
			  })
			: intl.formatMessage({id: 'No client'})
	}`;
	tooltipEl.appendChild(clientEl);
};

export const renderAreaTooltip = ({
	tooltipEl,
	title,
	subtitle,
	info,
	dateFrom,
	dateTo,
}) => {
	const titleEl = document.createElement('div');
	titleEl.textContent = subtitle ? `${subtitle} ${title}` : title;
	titleEl.style.fontWeight = fontWeightMedium;
	tooltipEl.appendChild(titleEl);

	if (info) {
		titleEl.style.marginBottom = '5px';
		const encCoverageEl = document.createElement('div');
		encCoverageEl.textContent = `${intl.formatMessage({
			id: 'Contact coverage',
		})}: ${info.encounterCoverage} %`;
		tooltipEl.appendChild(encCoverageEl);

		const encCoverage3mEl = document.createElement('div');
		encCoverage3mEl.textContent = `${intl.formatMessage({
			id: 'Contact coverage 3 months',
		})}: ${info.encounterCoverage3m} %`;
		tooltipEl.appendChild(encCoverage3mEl);

		const buildingAgeEl = document.createElement('div');
		buildingAgeEl.textContent = `${intl.formatMessage({
			id: 'Average age of buildings',
		})}: ${info.buildingAge}`;
		tooltipEl.appendChild(buildingAgeEl);

		const salesCountEl = document.createElement('div');
		salesCountEl.textContent = `${intl.formatMessage({id: 'Sales amount'})}: ${
			info.salesCount
		}`;
		tooltipEl.appendChild(salesCountEl);
	} else if (dateFrom && dateTo) {
		// show dateFrom - dateTo in tooltip for temp areas
		titleEl.style.marginBottom = '5px';
		const dateRangeEl = document.createElement('div');
		dateRangeEl.textContent = `${intl.formatDate(
			new Date(dateFrom.split('T')[0]),
			dateFmtOptsShort,
		)} - ${intl.formatDate(new Date(dateTo.split('T')[0]), dateFmtOptsShort)}`;
		dateRangeEl.style.fontSize = '12px';
		tooltipEl.appendChild(dateRangeEl);
	}
};

export const renderCallPoolsTooltip = ({
	tooltipEl,
	title,
	subtitle,
	callPoolTitle,
	organizationTitle,
}) => {
	const titleEl = document.createElement('div');
	titleEl.textContent = subtitle ? `${subtitle} ${title}` : title;
	tooltipEl.appendChild(titleEl);

	const callPoolTitleEl = document.createElement('div');
	callPoolTitleEl.textContent = callPoolTitle;
	callPoolTitleEl.style.fontWeight = fontWeightMedium;
	tooltipEl.appendChild(callPoolTitleEl);

	const organizationTitleEl = document.createElement('div');
	organizationTitleEl.textContent = organizationTitle;
	tooltipEl.appendChild(organizationTitleEl);
};

export const createMarkerLayer = imports => {
	const {openLayers: ol} = imports;

	const markerSvg = `<svg width="1em" height="1em" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
		<path fill="rgb(43, 131, 203)" fillRule="evenodd" d="M6 0a4.756 4.756 0 0 0-4.75 4.75c0 3.274 3.269 6.244 4.27 7.076.278.231.681.231.959 0 1-.832 4.271-3.803 4.271-7.077A4.756 4.756 0 0 0 6 0zm0 7.25a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z" />
		</svg>`;

	const markerSource = new ol.source.VectorSource();
	const markerStyle = [
		new ol.style.Style({
			image: new ol.style.Icon({
				src: `data:image/svg+xml;utf8,${markerSvg}`,
				scale: 1.5,
			}),
		}),
		// larger hitbox for marker
		new ol.style.Style({
			image: new ol.style.Circle({
				stroke: new ol.style.Stroke({color: [0, 0, 0, 0]}),
				fill: new ol.style.Fill({color: [0, 0, 0, 0]}),
				radius: 30,
			}),
		}),
	];

	const markerLayer = new ol.layer.VectorLayer({
		source: markerSource,
		style: markerStyle,
	});

	return markerLayer;
};

export const _addMarker =
	({id, coord, source, map, draggable = false, onDragEnd = () => {}}) =>
	imports => {
		const {openLayers: ol} = imports;

		const iconFeature = new ol.Feature({
			geometry: new ol.geom.Point(coord),
		});

		iconFeature.setId(id);
		source.addFeature(iconFeature);

		if (draggable && map) {
			const dragInteraction = new ol.interaction.Translate({
				features: new ol.Collection([iconFeature]),
			});
			map.addInteraction(dragInteraction);

			dragInteraction.on('translateend', e => {
				const coords = e.features.getArray()[0].getGeometry().getCoordinates();

				onDragEnd(coords);
			});

			return dragInteraction;
		}
	};
export const addMarker = decorateMapMethod(_addMarker);

// resolve a source id to a concrete basemap OL tile source. also handles defaults.
export const _getMapTileSource =
	({sourceId: _sourceId = null}) =>
	imports => {
		const {openLayers: ol} = imports;

		const sourceId = _sourceId || 'here-light';

		if (sourceId === 'mapbox-streets') {
			return new ol.source.XYZ({
				url: getMapboxTileUrl(
					'streets',
					apiKeys(services.get('store').getState()).MAPBOX_API_KEY,
				),
				tileSize: 512,
			});
		}

		const type = sourceId.split('-')[1];
		const serverId = Math.floor(Math.random() * Math.floor(4)) + 1;
		const url = getHereTileUrl(
			type,
			apiKeys(services.get('store').getState()).HERE_MAPS_API_KEY,
		).replace('{1-4}', serverId);
		const source = new ol.source.XYZ({url, tileSize: 512});
		let alreadyReported = false;

		source.on('tileloaderror', err => {
			if (!alreadyReported) {
				services.get('store').dispatch(
					nActions.warning({
						id: 'tile-load-err',
						duration: longestDur,
						message: services.get('intl').formatMessage({
							id: 'Base map load failed. Try changing to another map type.',
						}),
					}),
				);
				alreadyReported = true;
			}
		});

		return source;
	};
export const getMapTileSource = decorateMapMethod(_getMapTileSource);

const initZoom = 4 * localeZoomFactor;

const _getAreasStyle =
	({selectedAreaIds}) =>
	imports => {
		const {openLayers: ol} = imports;

		const sty = mapStyles(ol);

		return feature =>
			new ol.style.Style({
				fill: selectedAreaIds.has(getJsonProperty(feature, 'id'))
					? sty.areasHlFill
					: sty.areasFill,
				stroke: sty.areasStroke,
				// note: could move labels into a separate json layer at some point
				text: new ol.style.Text({
					text: getJsonProperty(feature, 'title'),
					fill: sty.areasTextFill,
					stroke: sty.areasTextStroke,
					font: sty.areasFont,
				}),
			});
	};
export const getAreasStyle = decorateMapMethod(_getAreasStyle);
const _getAreasSource =
	({apiToken, areasType}) =>
	imports => {
		const {openLayers: ol} = imports;

		return new ol.source.VectorTile({
			format: new ol.format.MVT({dataProjection: 'EPSG:4326'}),
			url: `${apiUrl}/maps/areas/mvt/{z}/{x}/{y}${encodeQuery({
				type: areasType,
				token: apiToken,
			})}`,
			maxZoom: areasMaxDetailZoom,
		});
	};
export const getAreasSource = decorateMapMethod(_getAreasSource);

const createAreasLayer =
	({apiToken, areasType, selectedAreaIds}) =>
	imports => {
		const {openLayers: ol} = imports;

		const layer = new ol.layer.VectorTile({
			source: _getAreasSource({apiToken, areasType})(imports),
			style: _getAreasStyle({selectedAreaIds})(imports),
		});

		return layer;
	};
const _initAreasMap =
	({apiToken, areasType, selectedAreaIds, onAreaClick}) =>
	imports => {
		// keep some local state
		let currHoverAreaId = null;

		const {openLayers: ol} = imports;

		const mapEl = document.querySelector('#buildings-areas-selection-map');
		if (!mapEl) {
			return Promise.reject(new Error('Map element not found'));
		}

		const view = new ol.View({
			projection: 'EPSG:3857',
			center: localeCenterWeb,
			zoom: initZoom,
			maxZoom,
			enableRotation: false,
			constrainResolution: true,
		});

		const areasLayer = createAreasLayer({apiToken, areasType, selectedAreaIds})(imports);

		const map = new ol.Map({
			target: 'buildings-areas-selection-map',
			view,
			layers: [
				new ol.layer.Tile({
					source: _getMapTileSource({sourceId: 'here-light'})(imports),
				}),
				// TODO: generic toggling for google maps - MVT layers don't work with it so need to use geojson sources and strict min zooms
				//new olgm.layer.Google(),
				areasLayer,
			],
			//interactions: olgm.interaction.defaults(),
		});

		const areaTooltipEl = document.getElementById(
			'buildings-areas-selection-map-area-tooltip',
		);

		const areaTooltip = new ol.Overlay({
			element: areaTooltipEl,
			positioning: 'bottom-center',
			offset: [0, -5],
		});

		const updateAreaTooltip = areaFeature => {
			const {id, title, subtitle} = getJsonProperties(areaFeature, [
				'id',
				'title',
				'subtitle',
			]);
			if (id === currHoverAreaId) return;
			currHoverAreaId = id;
			deleteChildren(areaTooltipEl);
			renderAreaTooltip({tooltipEl: areaTooltipEl, title, subtitle});
		};

		addTooltipToLayers({
			mapEl,
			map,
			layers: [areasLayer],
			tooltipEl: areaTooltipEl,
			tooltip: areaTooltip,
			updateTooltip: updateAreaTooltip,
		});

		map.on('click', e => {
			const features = safeGetFeaturesAtPixel(map, e.pixel, {
				layerFilter: l => l === areasLayer,
			});
			if (!features) return;
			onAreaClick(features[0]);
		});

		map.on('pointermove', e => {
			const pixel = map.getEventPixel(e.originalEvent);
			const hit = safeHasFeatureAtPixel(map, pixel, {
				layerFilter: l => l === areasLayer,
			});
			map.getViewport().style.cursor = hit ? 'pointer' : '';
		});

		//const olgmInst = new olgm.GoogleMaps({map});
		//olgmInst.activate();
		// use when removing olgm layer
		//olgmInst.deactivate();

		return Promise.resolve({map, areasLayer});
	};
export const initAreasMap = decorateMapMethod(_initAreasMap);
