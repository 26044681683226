import {mergeLeft, equals} from 'ramda';
import * as actions from './actions';
import * as selectors from './selectors';
import {catchNonFatalDefault} from 'io/errors';
import {getReferences, getAreas, getProducts, postReferencesSearchLog} from './io';
import {effect} from 'utils/redux';
import namespace from './namespace';
import {decorateWithNotifications} from 'io/app';
import {getQuery, pushQuery} from 'io/history';
import {parseUrlQuery, launchIntoFullscreen, exitFullscreen} from './utils';

const creator = effect(namespace);

const fetchReferences = (getState, dispatch) => {
	return decorateWithNotifications(
		{id: 'fetch-references', failureStyle: 'error'},
		getReferences(selectors.referencesQueryFetchable(getState())),
	)(getState, dispatch).then(res => {
		dispatch(actions._setReferences(res.data));
	});
};

export let initialize = () => (getState, dispatch) => {
	const {referencesQuery} = parseUrlQuery(getQuery());
	const {dismissPrompt} = referencesQuery;

	if (dismissPrompt) {
		dispatch(actions._updateReferencesQuery(referencesQuery));
		dispatch(actions._updateReferencesPagination({currentPage: referencesQuery._page}));
	}

	decorateWithNotifications(
		{id: 'init-references', failureStyle: 'error'},
		Promise.all([
			getAreas().then(areas => dispatch(actions._setAreas(areas))),
			getProducts().then(products => dispatch(actions._setProducts(products))),
			dismissPrompt
				? getReferences(selectors.referencesQueryFetchable(getState())).then(res =>
						dispatch(actions._setReferences(res.data)),
				  )
				: Promise.resolve(null),
		]),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let updateReferences = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	fetchReferences(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
updateReferences = creator('updateReferences', updateReferences);

export let recheckQuery = () => (getState, dispatch) => {
	const urlQueries = parseUrlQuery(getQuery());
	const referencesQuery = selectors.referencesQuery(getState());
	if (!equals(referencesQuery, urlQueries.referencesQuery)) {
		dispatch(actions._updateReferencesQuery(urlQueries.referencesQuery));
		fetchReferences(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}
};

export let updateUrlQuery = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
};
updateUrlQuery = creator('updateUrlQuery', updateUrlQuery);

export let toggleBook = isOpen => (getState, dispatch) => {
	// open book in fullscreen
	const fullscreenElement =
		document.fullscreenElement ||
		document.mozFullScreenElement ||
		document.webkitFullscreenElement;

	if (!isOpen && !fullscreenElement) {
		launchIntoFullscreen(document.documentElement);
	} else if (fullscreenElement) {
		exitFullscreen();
	}
};
toggleBook = creator('toggleBook', toggleBook);

export let referenceSearchLog = searchParam => (getState, dispatch) => {
	decorateWithNotifications(
		{id: 'init-references', failureStyle: 'error'},
		postReferencesSearchLog(searchParam),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
