import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';

export default {
	...lifecycle,
	initialized: false,
	processing: false,
	noticesQuery: {_page: 1, _sort: '-createdAt'},
	notices: [],
	noticesLoading: true,
	noticesPagination: emptyPagination,
	noticeCreatorOpen: false,
	organizations: [],
	roles: [],
	noticeFormInitValues: {
		title: '',
		content: '',
		startDate: null,
		organizations: [],
		teams: [],
		roles: [],
	},
	noticeInEdit: null,
};
