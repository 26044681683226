import {mergeLeft, equals} from 'ramda';
import {effect} from 'utils/redux';
import namespace from './namespace';
import * as actions from './actions';
import {
	getAreas,
	getSalesmanVisits,
	getUserTeams,
	getCalendarResources,
	getUsers,
	checkTeamCalendarVisibility,
} from './io';
import {decorateWithNotifications} from 'io/app';
import * as selectors from './selectors';
import {getQuery, pushQuery} from 'io/history';
import {parseUrlQuery} from './utils';
import {catchNonFatalDefault} from 'io/errors';
import * as rootSelectors from 'modules/common/selectors';
import * as calendarSelectors from 'modules/calendarApp/common/selectors';
import services from 'services';

const creator = effect(namespace);

const history = services.get('history');

const fetchSalesmanVisits =
	({notifyOpts = {}}) =>
	(getState, dispatch) => {
		const isUnacknowledged = selectors.checkUnacknowledged(getState());
		const isUnacknowledgedFinal =
			typeof isUnacknowledged === 'string'
				? JSON.parse(isUnacknowledged)
				: isUnacknowledged;
		const queryValues = selectors.salesmanVisitsQueryFetchable(getState());
		return decorateWithNotifications(
			{id: 'get-salesman-visits', failureStyle: 'error', ...notifyOpts},
			// Fetch calendarResources instead of salesmanVisits when 'unvisited' state is selected
			isUnacknowledgedFinal === true
				? getCalendarResources(queryValues)
				: getSalesmanVisits(queryValues),
		)(getState, dispatch).then(visits => {
			dispatch(actions._setVisits(visits));
		});
	};

export let initialize = () => (getState, dispatch) => {
	if (rootSelectors.isSubcontractorUser(getState())) {
		history.push('/');
		return;
	}

	const doFetchUserTeams = calendarSelectors.showTeamUsers(getState());

	const {salesmanVisitsQuery} = parseUrlQuery(getQuery());
	if (!salesmanVisitsQuery.userId && doFetchUserTeams) {
		// If there's no userId in query and user has permission to view team users
		// fetch own team's visits by default
		dispatch(actions._updateQuery({...salesmanVisitsQuery, userId: 'team'}));
	} else {
		dispatch(actions._updateQuery(salesmanVisitsQuery));
	}

	const activeOrganizationId = rootSelectors.activeOrganizationId(getState());

	// fetch certain users to show in user selection if the current user has permissions for it
	let userRoleTypes = [];

	if (calendarSelectors.showOrganizationSalesmen(getState())) {
		userRoleTypes = [...userRoleTypes, 'salesman', 'salesmanager'];
	}

	if (calendarSelectors.showOrganizationTelemen(getState())) {
		userRoleTypes = [...userRoleTypes, 'teleman'];
	}

	decorateWithNotifications(
		{id: 'init-salesman-visits', failureStyle: 'error'},
		Promise.all([
			getAreas().then(areas => dispatch(actions._setAreas(areas))),
			doFetchUserTeams
				? getUserTeams().then(teams => {
						dispatch(actions._setUserTeams(teams));
						if (teams.length) {
							return checkTeamCalendarVisibility(teams[0].id).then(({lockFrom}) =>
								dispatch(actions._setLockFrom(lockFrom)),
							);
						}
				  })
				: Promise.resolve(null),
			userRoleTypes.length
				? getUsers({
						organizationId: activeOrganizationId,
						withTrashed: true,
						roleTypes: userRoleTypes,
				  }).then(users => dispatch(actions._setUsers(users)))
				: Promise.resolve(null),
		]),
	)(getState, dispatch)
		.then(() => {
			dispatch(actions._initialize());
			return fetchSalesmanVisits({})(getState, dispatch);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let recheckQuery = () => (getState, dispatch) => {
	const urlQueries = parseUrlQuery(getQuery());
	const salesmanVisitsQuery = selectors.salesmanVisitsQuery(getState());
	if (!equals(salesmanVisitsQuery, urlQueries.salesmanVisitsQuery)) {
		dispatch(actions._updateQuery(urlQueries.salesmanVisitsQuery));
		fetchSalesmanVisits({})(getState, dispatch).catch(
			catchNonFatalDefault(getState, dispatch),
		);
	}
};

export let updateSalesmanVisits = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	fetchSalesmanVisits({})(getState, dispatch).catch(
		catchNonFatalDefault(getState, dispatch),
	);
};
updateSalesmanVisits = creator('updateSalesmanVisits', updateSalesmanVisits);
