import React from 'react';

const FilesApp = props => {
	return (
		<svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
			<path
				fill="currentColor"
				d="M19.036 26.144a1 1 0 001 1h24a1 1 0 000-2h-24a1 1 0 00-1 1zM44.036 35.144h-24a1 1 0 000 2h24a1 1 0 000-2zM44.036 45.144h-24a1 1 0 000 2h24a1 1 0 000-2z"
			/>
			<path
				fill="currentColor"
				d="M56.648 15.65L41.118.15a1.035 1.035 0 00-.721-.294l-30.303.002C8.444-.142 7 1.2 7 2.85v57.876c0 .836.421 1.766 1.013 2.359.592.59 1.473 1.06 2.309 1.06h43.584c.795 0 1.601-.454 2.164-1.016.561-.563.93-1.453.93-2.247V16.358c0-.265-.165-.52-.352-.707zM40.083 2.26l13.883 13.884H40.047c.086-9 .09-12.12.036-13.885zM55 60.88c0 .258-.164.653-.345.834-.184.184-.488.43-.75.43H10.323c-.302 0-.682-.262-.895-.475-.214-.214-.427-.642-.427-.944V2.849c0-.546.54-.99 1.086-.99l27.957-.002c.049 2.521.021 10.092-.03 14.989-.004.267.112.668.3.857.188.19.456.44.723.44H55v42.738z"
			/>
		</svg>
	);
};

export default FilesApp;
