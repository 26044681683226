import React from 'react';

const SvgUser = props => (
	<svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M16 32C7.177 32 0 24.823 0 16S7.177 0 16 0s16 7.177 16 16-7.177 16-16 16zm0-30.667C7.913 1.333 1.333 7.913 1.333 16S7.913 30.667 16 30.667 30.667 24.087 30.667 16 24.087 1.333 16 1.333zM16 18a6.34 6.34 0 0 1-6.333-6.333A6.34 6.34 0 0 1 16 5.333a6.34 6.34 0 0 1 6.333 6.334A6.34 6.34 0 0 1 16 18zm0-11.333c-2.757 0-5 2.242-5 5 0 2.757 2.243 5 5 5s5-2.243 5-5c0-2.758-2.243-5-5-5zM7.577 25.333a.667.667 0 0 1-.601-.953 10.002 10.002 0 0 1 4.756-4.756 9.883 9.883 0 0 1 4.253-.957c3.85 0 7.399 2.242 9.042 5.714a.669.669 0 0 1-1.207.571A8.7 8.7 0 0 0 15.985 20c-1.28 0-2.52.279-3.684.83a8.675 8.675 0 0 0-4.121 4.122.67.67 0 0 1-.603.381z"
		/>
	</svg>
);

export default SvgUser;
