import {effect} from 'utils/redux';
import {decorateWithNotifications} from 'io/app';
import {catchNonFatalDefault} from 'io/errors';
import {describeThrow} from 'utils/errors';
import namespace from './namespace';
import * as actions from './actions';
import * as commonActions from 'modules/teamCalendarApp/calendarPage/actions';
import {
	initRoutePlannerMap,
	getCalendarResources,
	getTeam,
	getRoutes,
	createDirections,
	postRoutePlannerLog,
	updateSalesmanIds,
} from './io';
import importGoogleMaps from 'services/importGoogleMaps';
import {markerUrl} from './constants';
import services from 'services';
import {getQuery} from 'io/history';
import * as confirmerActions from 'modules/confirmer/actions';
import {
	parseUrlQuery,
	setRoutesDirectionData,
	parseDates,
	formatSaveRoutesOutput,
	formatRoutePlannerLog,
} from './utils';
import * as selectors from './selectors';
import cache from './cache';
import {setHour} from 'utils/time';
import msgs from 'dicts/messages';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const creator = effect(namespace);

export let initialize = () => (getState, dispatch) => {
	const {calendarQuery} = parseUrlQuery(getQuery());
	dispatch(actions._updateCalendarQuery(calendarQuery));

	decorateWithNotifications(
		{
			id: 'init-route-planner-page',
			failureStyle: 'error',
		},
		Promise.all([
			getCalendarResources(selectors.calendarQueryFetchable(getState())).then(
				resources => {
					dispatch(actions._setCalendarResources(resources));
					return importGoogleMaps()
						.catch(describeThrow(intl.formatMessage({id: 'Error loading map'})))
						.then(() => initRoutePlannerMap(selectors.routes(getState())));
				},
			),
			getTeam(calendarQuery.teamId).then(t => {
				dispatch(actions._setTeam(t));
			}),
		]),
	)(getState, dispatch)
		.then(res => {
			// set directions data for routes

			const routes = selectors.routes(getState());
			const _routes = setRoutesDirectionData({
				routes,
				directions: res[0].directions,
			});
			dispatch(actions._setRoutes(_routes));

			// set google maps stuff to cache so we can access them later
			cache.update(c => ({
				...c,
				map: res[0].map,
				directions: res[0].directions,
			}));

			dispatch(actions._initialize());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let toggleRouteVisibility = ({routeNum, visible}) => (getState, dispatch) => {
	const {map, directions} = cache.read();

	if (map && directions && directions[routeNum]) {
		const {directionsDisplay, markerArray} = directions[routeNum];

		directionsDisplay.setMap(visible ? map : null);
		markerArray.map(m => m && m.setMap(visible ? map : null));
	}
};
toggleRouteVisibility = creator('toggleRouteVisibility', toggleRouteVisibility);

export let createRoutes = maxRoutes => (getState, dispatch) => {
	const calendarQuery = selectors.calendarQuery(getState());
	const team = selectors.team(getState());
	// set hours to 23:59:59, otherwise toISOString returns previous date due to timezone
	const date = setHour(calendarQuery.date, 23, 59, 59, 999);

	// post log of the initial situation (before fetching routes from backend)
	const routes = selectors.routes(getState());
	const total = selectors.routesTotalDistance(getState());
	if (routes.length) {
		postRoutePlannerLog(formatRoutePlannerLog({team, date, routes, total}));
	}

	decorateWithNotifications(
		{
			id: 'create-routes',
			loading: intl.formatMessage({id: msgs.loading}),
			failureStyle: 'error',
		},
		getRoutes({
			teamId: team.id,
			dateFrom: date.toISOString().split('T')[0],
			maxRoutes,
		}).then(res => {
			const routes = res.routes
				? res.routes.map((r, i) => {
						const {salesmanId, ...calendarResources} = r;
						return {
							routeNum: i,
							visible: true,
							calendarResources: parseDates(Object.values(calendarResources)),
							salesmanId,
						};
				  })
				: [];
			const extra = res.extra;
			if (extra) {
				dispatch(actions._setExtra(parseDates(extra)));
			}

			const {map} = cache.read();
			if (map) {
				return createDirections({routes, map, extra})
					.then(directions => {
						// set directions data for routes
						const _routes = setRoutesDirectionData({
							routes,
							directions,
						});
						dispatch(actions._setRoutes(_routes));

						// update directions in cache
						cache.update(c => ({
							...c,
							directions: directions,
						}));
					})
					.then(_ => {
						const newRoutes = selectors.routes(getState());
						const newTotal = selectors.routesTotalDistance(getState());

						if (newRoutes.length) {
							postRoutePlannerLog(
								formatRoutePlannerLog({team, date, routes: newRoutes, total: newTotal}),
							);
						}
					});
			}
		}),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(() => dispatch(actions._createRoutes()))
		.catch(catchNonFatalDefault(getState, dispatch));
};
createRoutes = creator('createRoutes', createRoutes);

export let updateMarker = ({location, clearAll, nextRoutes}) => (getState, dispatch) => {
	const {coordinates} = location;
	const {map, highLightMarker, nextHourMarkers} = cache.read();

	highLightMarker && highLightMarker.setMap(null);
	nextHourMarkers && nextHourMarkers.map(m => m.setMap(null));
	if (!clearAll) {
		const marker = new window.google.maps.Marker({
			map: map,
			position: {lat: coordinates[0][1], lng: coordinates[0][0]},
		});
		const newHighLightMarker = marker;

		let markers = [];

		const image = {
			url: markerUrl({index: null, color: '#0000000', borderColor: 'FFB6C1'}),
			scaledSize: new window.google.maps.Size(27, 43),
		};

		nextRoutes.map(
			r =>
				r &&
				r.building &&
				markers.push(
					new window.google.maps.Marker({
						map: map,
						icon: image,
						opacity: 0.2,
						position: {
							lat: r.building.location.coordinates[0][1],
							lng: r.building.location.coordinates[0][0],
						},
					}),
				),
		);

		cache.update(c => ({
			...c,
			highLightMarker: newHighLightMarker,
			nextHourMarkers: markers,
		}));
	} else {
		cache.update(c => ({
			...c,
			highLightMarker: null,
			nextHourMarkers: null,
		}));
	}
};
updateMarker = creator('updateMarker', updateMarker);

export let updateRoutes = () => (getState, dispatch) => {
	const {map} = cache.read();
	const routes = selectors.routes(getState());
	const extra = selectors.extra(getState());
	if (map) {
		decorateWithNotifications(
			{
				id: 'update-routes',
				failureStyle: 'error',
			},
			createDirections({routes, map, fitBounds: false, extra}).then(directions => {
				// set directions data for routes
				const _routes = setRoutesDirectionData({
					routes,
					directions,
				});
				dispatch(actions._setRoutes(_routes));

				// update directions in cache
				cache.update(c => ({
					...c,
					directions: directions,
				}));
			}),
		)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}
};
updateRoutes = creator('updateRoutes', updateRoutes);

export let focusRoute = routeNum => (getState, dispatch) => {
	const {map, directions} = cache.read();

	if (map && directions && directions[routeNum]) {
		const {directionsDisplay} = directions[routeNum];
		// zoom and center the map to show the route
		const bounds = directionsDisplay.getDirections().routes[0].bounds;
		map.fitBounds(bounds);

		// set route visible if it's not already
		if (!directionsDisplay.getMap()) {
			directionsDisplay.setMap(map);
		}
	}
};
focusRoute = creator('focusRoute', focusRoute);

export let saveRoutes = () => (getState, dispatch) => {
	const routes = selectors.routes(getState());
	const extra = selectors.extra(getState());
	const calendarResources = formatSaveRoutesOutput({routes, extra});

	// post log of the situation we are saving
	const calendarQuery = selectors.calendarQuery(getState());
	const team = selectors.team(getState());
	const date = setHour(calendarQuery.date, 23, 59, 59, 999);
	const total = selectors.routesTotalDistance(getState());

	const onConfirm = () => {
		if (routes.length) {
			postRoutePlannerLog(formatRoutePlannerLog({team, date, routes, total}));
		}
		decorateWithNotifications(
			{
				id: 'save-routes',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Saved'}),
			},
			updateSalesmanIds(calendarResources),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			// update calendarResources for calendar page
			.then(_ => dispatch(commonActions.updateCalendarResources()))
			.then(_ => dispatch(actions._opOk()))
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	const routesWithoutSalesmanIds = routes
		.map((r, idx) => {
			return r.calendarResources.length && !r.salesmanId ? idx + 1 : null;
		})
		.filter(ro => ro);

	if (extra.length || routesWithoutSalesmanIds.length) {
		const noSalesmanIdMsg =
			routesWithoutSalesmanIds.length &&
			intl.formatMessage(
				{id: 'Save routes ({routes}) without salesman?'},
				{routes: routesWithoutSalesmanIds.join(', ')},
			);

		const unallocatedVisitsMsg =
			extra.length && intl.formatMessage({id: 'Save routes with unallocated visits?'});

		const messages = [noSalesmanIdMsg, unallocatedVisitsMsg].filter(msg => msg);

		dispatch(
			confirmerActions.show({
				// eslint-disable-next-line
				messages: messages,
				cancelText: intl.formatMessage({id: msgs.cancel}),
				onCancel: () => {
					dispatch(actions._opOk());
				},
				onOk: onConfirm,
			}),
		);
	} else {
		onConfirm();
	}
};
saveRoutes = creator('saveRoutes', saveRoutes);
