import styled from 'styled-components';
import {h1, h2, h3, h4, h5} from 'styles/fragments';

const appearances = {
	h1: h1,
	h2: h2,
	h3: h3,
	h4: h4,
	h5: h5,
};

export default type => styled[type]`
	${({appearance}) => appearances[appearance || type]};
`;
