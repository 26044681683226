import {state as lifecycle} from 'fragments/lifecycle';
import createDatePickState from 'fragments/calendarResourcePicker/state';
import createBuildingModalState from 'fragments/buildingModalActions/state';
import createCallReminderState from 'fragments/callReminder/state';

const datePickState = createDatePickState({});
const buildingModalState = createBuildingModalState({});
const callReminderState = createCallReminderState({});

export default {
	...lifecycle,
	// note: freeCalendarResources loading not handled because they easily manage to load before the user has a chance to open the call form
	...datePickState,
	...buildingModalState,
	...callReminderState,
	initialized: false,
	processing: false,
	// TODO: clean up unused call pool references from this module in case it's not refactored
	activeCallPool: null,
	activeTab: 'activities',
	allActivities: [],
	attachingBuilding: false,
	building: null,
	buildings: [],
	callFormInitValues: {},
	calendarResources: [],
	clientEditorOpen: false,
	client: {},
	clients: [],
	clientSearchOpen: false,
	clientType: null,
	comments: [],
	condo: null,
	condoEditorOpen: false,
	condoNotFound: false,
	disableReinit: false,
	encounters: [],
	fetchingNextBuilding: false,
	loading: false,
	newClients: [],
	newHouseManagerClient: {},
	newManagerClient: {},
	newDateCreatorOpen: false,
	openedAt: null,
	products: [],
	projectSalesmen: [],
	projectCustomerAcquisitionUsers: [],
	tooltip: null,
	dateEditorOpen: null,
	calResProcessing: false,
	additionalInfos: [],
};
