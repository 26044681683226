import {pipe, prop, map, path} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';
import * as normalize from 'utils/normalize';
import {mapResponseData, getResponseData} from 'utils/app';
import {
	userTeamsInclude,
	salesmanVisitsInclude,
	calendarResourceInclude,
	calendarResourceQueryBase,
} from './constants';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getUserTeams = () =>
	httpJson('get', '/users/me', {include: userTeamsInclude})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load users'})))
		.then(getResponseData(pipe(path(['teams', 'data']), map(normalize.team))));

export const getSalesmanVisits = query => {
	return httpJson('get', '/salesmanVisit', {
		include: salesmanVisitsInclude,
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.salesmanVisit)));
};

export const getAreas = () =>
	httpJson('get', '/areas', {
		select: 'id,type,title,municipal_name,county',
		withoutTransform: true,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getCalendarResources = query =>
	httpJson('get', '/calendarResource', {
		include: calendarResourceInclude,
		...calendarResourceQueryBase,
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.calendarResource)));

export const getCalendarResourcesCount = query =>
	httpJson('get', '/calendarResource/count', {
		...calendarResourceQueryBase,
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), map(normalize.calendarResourceCount)));

export const getUsers = query =>
	httpJson('get', '/users', {_limit: 999, include: 'roles', ...query})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), map(normalize.user)));

export const checkTeamCalendarVisibility = teamId =>
	httpJson('get', '/checkTeamCalendarVisibility', {teamId})
		.then(mapResponseData(map(normalize.calendarResource)))
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})));
