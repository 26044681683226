import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {getFormValues} from 'redux-form';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.openTarget.type: {
			const targetOpen = payload;
			return [{...state, targetOpen}, null];
		}

		case actions.saveCallTarget.type: {
			const form = getFormValues(`targetForm${state.targetOpen}`)(fullState);
			const target = {
				...form,
				type: 'call',
				timespan: state.targetOpen,
				id: state.targets[state.targetOpen] ? state.targets[state.targetOpen].id : null,
			};

			return [state, effects.saveVisitTarget(target)];
		}

		case actions._setCallReminders.type: {
			return [{...state, callReminders: payload, callRemindersLoading: false}, null];
		}

		case actions._setStats.type: {
			return [{...state, stats: payload, statsLoading: false}, null];
		}

		case actions._setTargets.type: {
			return [{...state, targets: payload, targetsLoading: false}, null];
		}

		case actions._updateTargets.type: {
			const targets = {
				...state.targets,
				[payload.timespan]: {...state.targets[payload.timespan], target: payload.target},
			};

			const newState = {...state, targetOpen: null, targets};
			return [newState, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
