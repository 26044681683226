import {action} from 'utils/redux';
import {P, Nullable} from 'utils/types';

export default ({namespace}) => {
	const creator = action(namespace);

	return {
		createCallReminder: creator('createCallReminder', P.Object),
		createCalendarCallReminder: creator('createCalendarCallReminder'),
		updateCallReminder: creator('updateCallReminder', P.Object),
		removeCallReminder: creator('removeCallReminder', P.Number),
		toggleCallReminderEditor: creator('toggleCallReminderEditor'),
		setReminderSeen: creator('setReminderSeen'),
		_setCalendarCallReminderSeen: creator('_setCalendarCallReminderSeen'),
		_setCallReminder: creator('_setCallReminder', Nullable(P.Object)),
		_setCalendarCallReminder: creator('_setCalendarCallReminder'),
		_setCallReminderLoading: creator('_setCallReminderLoading', P.Boolean),
		_setCallReminders: creator('_setCallReminders', Nullable(P.Array)),
		_setCallRemindersLoading: creator('_setCallRemindersLoading', P.Boolean),
	};
};
