import {scopedHandler} from 'utils/redux';
import {getFormValues} from 'redux-form';
import {prepend} from 'ramda';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.changePage.type: {
			const newState = {
				...state,
				formsLoading: true,
				formFillsQuery: {...state.formFillsQuery, _page: payload},
			};

			return [newState, effects.updateForms()];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		case actions.openFormModal.type: {
			return [{...state, formModalOpen: true, formInModal: payload}, null];
		}

		case actions.closeFormModal.type: {
			return [{...state, formModalOpen: false, formInModal: null}, null];
		}

		case actions.openFormCreator.type: {
			return [{...state, formCreatorOpen: true}, null];
		}

		case actions.closeFormCreator.type: {
			return [{...state, formCreatorOpen: false}, null];
		}

		case actions.saveNewTrackingForm.type: {
			const values = getFormValues('trackingForm')(fullState) || {};
			const formAttributes = [
				{id: 10, value: values.salesman, comment: null},
				...values.formAttributes,
			];
			const form = {formId: values.formId, formAttributes};

			return [{...state, processing: true}, effects.saveNewTrackingForm(form)];
		}

		case actions.removeTrackingForm.type: {
			return [state, effects.removeTrackingForm(payload)];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions._setInitMeta.type: {
			return [{...state, ...payload}, null];
		}

		case actions._setTrackingForms.type: {
			const {data, pagination} = payload;
			const newState = {
				...state,
				trackingForms: data,
				formsPagination: pagination,
				formsLoading: false,
			};

			return [newState, null];
		}

		case actions._updateTrackingFormsQuery.type: {
			const newState = {
				...state,
				formsLoading: true,
				formFillsQuery: {...state.formFillsQuery, ...payload},
			};
			return [newState, null];
		}

		case actions._newTrackingFormSaved.type: {
			const trackingForms = prepend(payload, state.trackingForms);

			return [{...state, trackingForms, processing: false, formCreatorOpen: false}, null];
		}

		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false, formCreatorOpen: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
