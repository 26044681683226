import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {createSelector} from 'reselect';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {formatUrlQuery} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(seq, x => !!x);

export const buildingsQuery = pipe(root, prop('buildingsQuery'));

export const urlQuery = createSelector([buildingsQuery], buildingsQuery => {
	return formatUrlQuery({buildingsQuery});
});

export const followingGeolocation = pipe(root, prop('followingGeolocation'));

export const geolocationLoading = pipe(root, prop('geolocationLoading'));

export const mapSource = createSelector(
	[buildingsQuery],
	buildingsQuery => buildingsQuery.mapSource,
);

export const addBuildingMenuOpen = pipe(root, prop('addBuildingMenuOpen'));

export const addBuildingModalOpen = pipe(root, prop('addBuildingModalOpen'));

export const processing = pipe(root, prop('processing'));

export const mapInitialized = pipe(root, prop('mapInitialized'));

export const layersVisibility = createSelector([buildingsQuery], buildingsQuery => ({
	groundwaterAreasLayer: buildingsQuery.groundwaterAreasLayer,
	propertyLinesLayer: buildingsQuery.propertyLinesLayer,
}));

export const availableTags = pipe(root, prop('availableTags'));
