import {omit} from 'ramda';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {scopedHandler} from 'utils/redux';
import {getFormValues} from 'redux-form';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {formatClientFormOutput} from './utils';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize(payload)];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.openEncounterModal.type: {
			return [{...state, processing: true}, effects.getPreviewableEncounter(payload)];
		}

		case actions.closeEncounterModal.type: {
			return [{...state, previewableEncounter: null}, null];
		}

		case actions.openClientEditor.type: {
			return [{...state, clientEditorOpen: true}, null];
		}

		case actions.closeClientEditor.type: {
			return [{...state, clientEditorOpen: false}, null];
		}

		case actions.setClientBanState.type: {
			return [{...state, processing: true}, effects.setClientBanState(payload)];
		}

		case actions.restoreClient.type: {
			return [{...state, processing: true}, effects.restoreClient()];
		}

		case actions.removeClient.type: {
			return [state, effects.removeClient()];
		}

		case actions.saveClient.type: {
			const form = getFormValues('clientForm')(fullState);
			const client = formatClientFormOutput(form);

			return [{...state, processing: true}, effects.saveClient(client)];
		}

		case actions._setClient.type: {
			return [{...state, client: payload, clientLoading: false, processing: false}, null];
		}

		case actions._setCalendarResources.type: {
			return [
				{...state, calendarResources: payload, calendarResourcesLoading: false},
				null,
			];
		}

		case actions._setEncounters.type: {
			return [{...state, encounters: payload, encountersLoading: false}, null];
		}

		case actions._setPreviewableEncounter.type: {
			const newState = {
				...state,
				processing: false,
				previewableEncounter: payload,
			};

			return [newState, null];
		}

		case actions._setClientBan.type: {
			const newState = {
				...state,
				processing: false,
				client: {
					...state.client,
					ban: payload,
				},
			};

			return [newState, null];
		}

		case actions._removeClientBan.type: {
			const unbannedClient = omit(['ban'], state.client);
			const newState = {
				...state,
				processing: false,
				client: unbannedClient,
			};

			return [newState, null];
		}

		case actions._setClientTrashed.type: {
			const newState = {
				...state,
				processing: false,
				client: {
					...state.client,
					trashed: payload,
				},
			};

			return [newState, null];
		}

		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
