import React from 'react';

const SvgNoticeSuccess = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M6 0a6 6 0 1 0 6 6 6.007 6.007 0 0 0-6-6zm3.464 4.1L6.04 8.745a.505.505 0 0 1-.715.094L2.882 6.885a.5.5 0 0 1 .625-.781l2.038 1.63 3.114-4.226a.5.5 0 1 1 .805.592z"
		/>
	</svg>
);

export default SvgNoticeSuccess;
