export const getSmsFormInitialValues = draft => {
	const receivers = draft?.receivers;
	const client = draft?.client;
	const actualReceivers = client
		? [{value: client.phone, label: client.phone}]
		: (receivers ?? []).map(r => ({value: r, label: r}));

	const currentTemplate = (draft?.availableTemplates ?? []).find(
		t => t.key === draft?.defaultTemplateKey,
	);
	return {
		currentTemplate: currentTemplate
			? {value: currentTemplate.id, label: currentTemplate.title}
			: null,
		message: null,
		receivers: actualReceivers,
	};
};
