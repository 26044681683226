import {pipe, over, map, merge, lensProp, take} from 'ramda';
import {formatTeamsForDisplay} from 'utils/stats';

const normalizeTimeSpanStats = stats =>
	merge(stats, {
		totalContacts: stats.totalCalls + stats.totalVisits,
		reachedContacts: stats.reachedCalls + stats.reachedVisits,
		appointmentContacts: stats.appointmentCalls + stats.appointmentVisits,
	});

export const normalizeStats = map(normalizeTimeSpanStats);

export const normalizeTeam = pipe(
	over(lensProp('stats'), normalizeStats),
	over(lensProp('users'), map(over(lensProp('stats'), normalizeStats))),
);

export const formatTeams = (settings, allTeams, timeSpan) => {
	const teams =
		settings.teams.length > 0
			? allTeams.filter(team => settings.teams.find(t => team.id === t.id))
			: allTeams;

	const displayedTeams =
		teams.length > 0
			? settings.topTeams
				? take(5, formatTeamsForDisplay(timeSpan, teams))
				: formatTeamsForDisplay(timeSpan, teams)
			: [];

	return {displayedTeams};
};
