import {pipeHandlers} from 'utils/reduxEffects';
import common from './common/handler';
import marketingLeadPage from './marketingLeadPage/handler';
import marketingLeadsPage from './marketingLeadsPage/handler';
import marketingSettingsPage from './marketingSettingsPage/handler';
import marketingRemindersPage from './marketingRemindersPage/handler';
import marketingTrackingPage from './marketingTrackingPage/handler';
import marketingChangelogPage from './marketingChangelogPage/handler';
import marketingClientsPage from './marketingClientsPage/handler';

export default pipeHandlers(
	common,
	marketingLeadPage,
	marketingLeadsPage,
	marketingSettingsPage,
	marketingRemindersPage,
	marketingTrackingPage,
	marketingChangelogPage,
	marketingClientsPage,
);
