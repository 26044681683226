import services from 'services';
import {prop, pipe} from 'ramda';
import {describeThrow} from 'utils/errors';
import {createFormData} from 'utils/http';
import {csvImportTypes} from 'dicts/buildings';

let http = null;
services.waitFor('api').then(x => (http = x.http));

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));

let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getImportHistory = query =>
	httpJson('GET', '/imports', {
		types: Object.keys(csvImportTypes),
		include: 'user',
		...query,
	}).catch(e => {
		console.error(e);
		describeThrow(
			intl.formatMessage({
				id: 'Import data could not be loaded. Try refreshing the page.',
			}),
		);
	});

export const saveCsvImport = data => {
	return http('POST', '/imports', {}, {body: createFormData(data)})
		.catch(
			describeThrow(
				intl.formatMessage({
					id: 'Failed to save',
				}),
			),
		)
		.then(res => res.json())
		.then(pipe(prop('data')));
};
