import services from 'services';
import {map} from 'ramda';
import {describeThrow, describeError} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {getResponseData} from 'utils/app';
import {buildingInclude, encountersInclude, encounterInclude} from './constants';
import {
	createEncounterFormFill,
	getFreeCalendarResources,
	getUserTeams,
	getBuildingSalesTeams,
	createEncounterCalendarResource,
} from 'io/data';
import {describeBuildingGetError} from 'utils/data';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export {getUserTeams, getBuildingSalesTeams, getFreeCalendarResources};

export const getBuilding = buildingId =>
	httpJson('get', `/buildings/${buildingId}`, {include: buildingInclude})
		.catch(e => {
			throw describeBuildingGetError(intl, e);
		})
		.then(getResponseData(normalize.building));

export const getEncounters = buildingId =>
	httpJson('get', `/encounters`, {
		buildingId: buildingId,
		include: encountersInclude,
		_limit: '999',
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.encounter)));

export const postFormFill = createEncounterFormFill;

// NOTE: no normalization
export const postVisit = visit =>
	httpJson('post', '/visits', {}, {body: visit}).catch(
		describeThrow(intl.formatMessage({id: msgs.saveFailed})),
	);

export const updateBuilding = (building, query) =>
	httpJson(
		'put',
		`/buildings/${building.id}`,
		{include: buildingInclude, ...query},
		{body: building},
	)
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save visit'})))
		.then(getResponseData(normalize.building));

export const postCalendarResource = createEncounterCalendarResource;

// encounter modal
export const getEncounter = encounterId =>
	httpJson('get', `/encounters/${encounterId}`, {
		include: encounterInclude,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(normalize.encounter));

// client modal
export const postClient = (client, buildingId) =>
	httpJson('post', '/clients', {}, {body: {buildingId, ...client}}).catch(e => {
		const conflict = !!e.response && e.response.status === 409;
		e.causedByMarketingPrevented = conflict;
		return conflict
			? e.response.json().then(body => describeThrow(body.message, e))
			: Promise.reject(describeError(msgs.saveFailed, e));
	});

export const updateClient = client =>
	httpJson('put', `/clients/${client.id}`, {}, {body: client}).catch(e => {
		const conflict = !!e.response && e.response.status === 409;
		e.causedByMarketingPrevented = conflict;
		return conflict
			? e.response.json().then(body => describeThrow(body.message, e))
			: Promise.reject(describeError(msgs.saveFailed, e));
	});

export const deleteClient = id =>
	httpJson('delete', `/clients/${id}`).catch(
		describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})),
	);
