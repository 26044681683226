import {action} from 'utils/redux';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const searchClients = creator('searchClients');

export const getExcel = creator('getExcel');

export const _updateClientsQuery = creator('_updateClientsQuery');

export const _setClients = creator('_setClients');

export const _setOrganizations = creator('_setOrganizations');

export const _setProducts = creator('_setProducts');

export const _searchFailed = creator('_searchFailed');

export const _setProcessing = creator('_setProcessing');
