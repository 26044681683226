import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import * as rootSelectors from 'modules/common/selectors';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {formatAreas, filterProducts} from './utils';
import {referencesPerPage} from './constants';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.searchReferences.type: {
			const newState = {
				...state,
				referencesLoading: true,
				referencesQuery: {
					_page: 1,
					city: payload.city,
					zip: payload.zip,
					county: payload.county,
					products: payload.products,
					dismissPrompt: true,
				},
				referencesPagination: initState.referencesPagination,
			};

			return [newState, effects.updateReferences()];
		}

		case actions.changePage.type: {
			const newState = {
				...state,
				referencesPagination: {
					...state.referencesPagination,
					currentPage: payload,
				},
				referencesQuery: {...state.referencesQuery, _page: payload},
			};

			return [newState, effects.updateUrlQuery()];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		case actions.updateReferencesQuery.type: {
			const newState = {
				...state,
				referencesQuery: {...state.referencesQuery, ...payload},
			};

			return [newState, null];
		}

		case actions.toggleBook.type: {
			return [{...state, bookOpen: !state.bookOpen}, effects.toggleBook(state.bookOpen)];
		}

		case actions.highlightSearchResults.type: {
			const newState = {
				...state,
				references: state.references.map(r =>
					payload.find(p => p.id === r.id)
						? {...r, highlight: true}
						: {...r, highlight: false},
				),
			};

			return [newState, null];
		}

		case actions.referencesSearchLogging.type: {
			return [state, effects.referenceSearchLog(payload)];
		}

		case actions._setReferences.type: {
			// total amount of references
			const total = payload.length;

			// total amount of pages in listview
			const totalPages = Math.ceil(total / referencesPerPage);

			const newState = {
				...state,
				references: payload,
				referencesPagination: {
					...state.referencesPagination,
					totalPages,
					total,
				},
				referencesLoading: false,
			};

			return [newState, null];
		}

		case actions._updateReferencesPagination.type: {
			return [
				{...state, referencesPagination: {...state.referencesPagination, ...payload}},
				null,
			];
		}

		case actions._updateReferencesQuery.type: {
			const newState = {
				...state,
				referencesLoading: true,
				referencesQuery: {...state.referencesQuery, ...payload},
			};

			return [newState, null];
		}

		case actions._setAreas.type: {
			const areas = formatAreas(payload);
			return [{...state, areas, areasLoading: false}, null];
		}

		case actions._setProducts.type: {
			const products = filterProducts({
				products: payload,
				activeOrganizationId: rootSelectors.activeOrganizationId(fullState),
			});
			return [{...state, products: products, productsLoading: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
