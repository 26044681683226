import {pipe, prop, map} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';
import * as normalize from 'utils/normalize';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getAreaCounties = () =>
	httpJson('get', '/areas', {
		type: 'county',
		select: 'title,id',
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getCondominiums = query =>
	httpJson('get', '/condominiums', {
		groupBy: 'customerAcquisitionStates',
		checkUserRole: 'customerAcquisition',
		include: 'activities',
		...query,
	}).catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})));

export const getUsers = () =>
	httpJson('get', '/users', {
		organizationId: 5,
		type: 'talotekniikkauser',
		_limit: '999',
		_sort: 'lastName',
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), map(normalize.user)));
