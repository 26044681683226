import React from 'react';

const SvgUsers = props => (
	<svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M16 30.733c-8.47 0-15.36-6.89-15.36-15.36C.64 6.903 7.53.013 16 .013c8.47 0 15.36 6.89 15.36 15.36 0 8.47-6.89 15.36-15.36 15.36zm0-29.44c-7.763 0-14.08 6.317-14.08 14.08S8.237 29.453 16 29.453s14.08-6.317 14.08-14.08S23.763 1.293 16 1.293zm-5.12 16.64a6.087 6.087 0 0 1-6.08-6.08 6.087 6.087 0 0 1 6.08-6.08 6.087 6.087 0 0 1 6.08 6.08 6.087 6.087 0 0 1-6.08 6.08zm0-10.88a4.805 4.805 0 0 0-4.8 4.8c0 2.647 2.153 4.8 4.8 4.8s4.8-2.153 4.8-4.8-2.153-4.8-4.8-4.8zm7.508 16.875a.641.641 0 0 1-.533-.292 8.303 8.303 0 0 0-6.992-3.78 8.27 8.27 0 0 0-4.494 1.325.642.642 0 0 1-.884-.19.632.632 0 0 1-.087-.482.632.632 0 0 1 .279-.401 9.548 9.548 0 0 1 5.186-1.53 9.643 9.643 0 0 1 7.551 3.644 6.996 6.996 0 0 1 5.271-2.368c.805 0 1.599.137 2.36.408a.64.64 0 0 1-.428 1.207 5.76 5.76 0 0 0-6.694 2.182.638.638 0 0 1-.53.28h-.005v-.003zm5.292-4.715c-2.47 0-4.48-2.01-4.48-4.48 0-2.47 2.01-4.48 4.48-4.48 2.47 0 4.48 2.01 4.48 4.48 0 2.47-2.01 4.48-4.48 4.48zm0-7.68a3.204 3.204 0 0 0-3.2 3.2c0 1.764 1.436 3.2 3.2 3.2 1.764 0 3.2-1.436 3.2-3.2 0-1.765-1.436-3.2-3.2-3.2z"
		/>
	</svg>
);

export default SvgUsers;
