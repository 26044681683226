import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const request = pipe(root, prop('request'));

export const processing = pipe(root, prop('processing'));

export const previewableEncounter = pipe(root, prop('previewableEncounter'));

export const comments = pipe(root, prop('comments'));

export const clients = pipe(root, prop('clients'));

export const clientsLoading = pipe(root, prop('clientsLoading'));

export const encounters = createSelector([clients], clients => {
	return clients.reduce((acc, c) => {
		if (c.building && c.building.allEncounters) {
			return [...acc, ...c.building.allEncounters];
		}
		return acc;
	}, []);
});
