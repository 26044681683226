import services from 'services';
import {describeError} from 'utils/errors';
import * as normalize from 'utils/normalize';
import {getResponseData} from 'utils/app';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getNextBuilding = callPoolId =>
	httpJson('get', '/callReservations/nextBuilding', {callPoolId})
		.catch(e => {
			const noBuildings = !!e.response && e.response.status === 404;
			e.causedByNoBuildingsAvailable = noBuildings;
			const errMsgKey = noBuildings
				? 'No more callable buildings'
				: 'Failed to load next callable building';
			throw describeError(intl.formatMessage({id: errMsgKey}), e);
		})
		.then(getResponseData(normalize.building));
