import React from 'react';

const SvgAnalytics = props => (
	<svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M1.925 27.52a1.922 1.922 0 0 1-1.92-1.92V6.4c0-1.059.862-1.92 1.92-1.92h26.88c1.059 0 1.92.861 1.92 1.92v19.2c0 1.059-.861 1.92-1.92 1.92H1.925zm0-21.76a.64.64 0 0 0-.64.64v19.2c0 .353.287.64.64.64h26.88a.64.64 0 0 0 .64-.64V6.4a.64.64 0 0 0-.64-.64H1.925zm3.84 16.64a.64.64 0 0 1-.64-.64V19.2a.64.64 0 0 1 1.28 0v2.56a.64.64 0 0 1-.64.64zm3.84 0a.64.64 0 0 1-.64-.64v-6.4a.64.64 0 0 1 1.28 0v6.4a.64.64 0 0 1-.64.64zm3.84 0a.64.64 0 0 1-.64-.64v-3.84a.64.64 0 0 1 1.28 0v3.84a.64.64 0 0 1-.64.64zm11.52 0a.64.64 0 0 1-.64-.64V12.8a.64.64 0 0 1 1.28 0v8.96a.64.64 0 0 1-.64.64zm-3.84 0a.64.64 0 0 1-.64-.64V16a.64.64 0 0 1 1.28 0v5.76a.64.64 0 0 1-.64.64zm-3.84 0a.64.64 0 0 1-.64-.64V10.24a.64.64 0 0 1 1.28 0v11.52a.64.64 0 0 1-.64.64z"
		/>
	</svg>
);

export default SvgAnalytics;
