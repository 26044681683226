import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	initialized: false,
	loadingCallPools: true,
	callPools: [],
	automatedFetching: true,
	refreshRate: 6,
	processingAreaId: null,
	lastCallpoolFetched: 0,
	callPoolAreaModalOpen: false,
	selectedCallPool: null,
	allAreas: [],
	allAreasLoading: true,
	processing: false,
};
