import services from 'services';
import {map, prop} from 'ramda';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import {getResponseData, mapResponseData} from 'utils/app';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getClients = query =>
	httpJson('get', '/clients/marketingRegistrySearch', query)
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load clients'})))
		.then(mapResponseData(map(normalize.client)));

export const getOrganizations = () =>
	httpJson('get', '/organizations', {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.organization)));

export const getProducts = () =>
	httpJson('get', '/products/all', {_limit: 999, include: 'organizations'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.product)));

export const getExcel = query =>
	httpJson('get', '/clients/marketingRegistrySearch', {...query, getExcel: true})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load Excel'})))
		.then(prop('data'));
