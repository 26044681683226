import React, {useEffect} from 'react';
import {compose, prop} from 'ramda';
import {connect} from 'react-redux';
import {reduxForm, formValueSelector} from 'redux-form';
import {applyDispatch, applyState} from 'utils/redux';
import {FormattedMessage, injectIntl} from '@meiko/react-intl';
import Field from 'components/generic/Field';
import FieldError from 'components/generic/FieldError';
import FormBlock from 'components/generic/FormBlock';
import Label from 'components/generic/Label';
import TextArea from 'components/generic/TextArea';
import msgs from 'dicts/messages';
import {CUSTOM} from 'modules/sms/constants';
import {isValidPhoneNumber, normalizePhone} from 'utils/phone-number';
import ReactSelect from 'components/generic/ReactSelect';
import {logError} from 'io/errors';
import {render} from 'utils/mustache';

const validate = (values, {intl}) => {
	let errors = {};

	const requiredMsg = intl.formatMessage({id: msgs.requiredField});

	if (!values?.currentTemplate) {
		errors.currentTemplate = requiredMsg;
	}

	const message = (values?.message ?? '').trim();

	if (!(message.length > 0)) {
		errors.message = requiredMsg;
	}

	const receivers = values?.receivers ?? [];

	if (receivers.length === 0) {
		errors.receivers = requiredMsg;
	} else {
		receivers.forEach(({value}) => {
			if (!isValidPhoneNumber(value)) {
				errors.receivers = intl.formatMessage({id: msgs.invalidField});
			}
		});
	}

	return errors;
};

export const SEND_SMS_FORM = 'sendSmsForm';

const fieldValue = path => state => formValueSelector(SEND_SMS_FORM)(state, path);

const SmsForm = ({
	handleSubmit,
	canEditSmsMessageField,
	initialValues,
	availableTemplates,
	currentTemplateValue,
	smsDraft,
	change,
}) => {
	const currentTemplate = availableTemplates.find(
		t => t.id === currentTemplateValue?.value,
	);
	const isCustom = currentTemplate?.key === CUSTOM;

	useEffect(() => {
		let message = null;
		try {
			message = render(currentTemplate?.message, prop('data', smsDraft));
			change('message', message);
		} catch (e) {
			// This should never happen as new templates cannot be created dynamically.
			logError(e);
			return;
		}
	}, [currentTemplate?.id]);

	return (
		<form id={SEND_SMS_FORM} onSubmit={handleSubmit}>
			<Field
				name="currentTemplate"
				props={{
					availableTemplatesProp: availableTemplates,
				}}
				component={({input, inputId, meta, availableTemplatesProp}) => (
					<FormBlock>
						<Label htmlFor={inputId}>
							<FormattedMessage id="SmsTemplate" />
						</Label>
						<ReactSelect
							{...input}
							meta={meta}
							id={inputId}
							block
							useOptionsAsValues
							options={(availableTemplatesProp ?? []).map(t => ({
								value: t.id,
								label: t.title,
							}))}
						/>
						<FieldError {...meta} />
					</FormBlock>
				)}
			/>
			<Field
				name="receivers"
				normalize={values => {
					// Make sure that the values are unique and formatted before the are dispatched to the store
					return Object.values(
						(values ?? []).reduce((acc, v) => {
							const entry = {
								label: normalizePhone((v?.label ?? '').trim()),
								value: normalizePhone((v?.value ?? '').trim()),
							};
							acc[entry.value] = entry;
							return acc;
						}, {}),
					);
				}}
				props={{isCustomProp: isCustom}}
				component={({input, inputId, meta, isCustomProp}) => (
					<FormBlock>
						<Label htmlFor={inputId}>
							<FormattedMessage id="Receivers [sms]" />
						</Label>
						<ReactSelect
							{...input}
							meta={meta}
							id={inputId}
							block
							useOptionsAsValues
							options={initialValues?.receivers ?? []}
							creatable
							disabled={!isCustomProp}
							isMulti
						/>
						<FieldError {...meta} />
					</FormBlock>
				)}
			/>
			<Field
				name="message"
				type="text"
				props={{canEditSmsMessageFieldProp: canEditSmsMessageField}}
				component={({input, inputId, meta, canEditSmsMessageFieldProp}) => (
					<FormBlock>
						<Label htmlFor={inputId}>
							<FormattedMessage id="Message" />
						</Label>
						<TextArea
							{...input}
							block
							stretch
							disabled={!canEditSmsMessageFieldProp}
							rows="10"
						/>
						<FieldError {...meta} />
					</FormBlock>
				)}
			/>
		</form>
	);
};

export default compose(
	injectIntl,
	connect(
		applyState({
			currentTemplateValue: fieldValue('currentTemplate'),
		}),
		applyDispatch({}),
	),
	reduxForm({
		form: SEND_SMS_FORM,
		validate,
	}),
)(SmsForm);
