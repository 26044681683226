import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const openFileModal = creator('openFileModal');

export const closeFileModal = creator('closeFileModal');

export const saveFile = creator('saveFile', P.Object);

export const openCategoryModal = creator('openCategoryModal');

export const closeCategoryModal = creator('closeCategoryModal');

export const saveCategory = creator('saveCategory', P.Object);

export const removeCategory = creator('removeCategory');

export const removeFile = creator('removeFile');

export const _setProcessing = creator('_setProcessing', P.Boolean);

export const _setCategories = creator('_setCategories', P.Array);

export const _setRoles = creator('_setRoles', P.Array);

export const _setOrganizations = creator('_setOrganizations', P.Array);
