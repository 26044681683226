import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';

export default {
	...lifecycle,
	processing: false,
	eventsQuery: {_page: 1},
	events: [],
	eventsLoading: true,
	eventsPagination: emptyPagination,
	fetchingMore: false,
	options: {
		categories: [],
		types: [],
	},
};
