import {action} from 'utils/redux';
import namespace from './namespace';
import {P, Nullable} from 'utils/types';
import createBuildingModalActions from 'fragments/buildingModalActions/actions';

const creator = action(namespace);

export const initialize = creator('initialize', P.Number);

export const reinitialize = creator('reinitialize', P.Number);

export const destroy = creator('destroy');

export const toggleClientEditor = creator('toggleClientEditor', Nullable(P.Object));

export const saveClient = creator('saveClient');

export const removeClient = creator('removeClient');

export const openEncounterModal = creator('openEncounterModal', P.Number);

export const closeEncounterModal = creator('closeEncounterModal');

export const fetchClientData = creator('fetchClientData');

export const _initialize = creator('_initialize');

export const _startOp = creator('_startOp');

export const _opOk = creator('_opOk');

export const _opFailed = creator('_opFailed');

export const _setInitData = creator('_setInitData');

export const _updateClients = creator('_updateClients', P.Object);

export const _setEncounterData = creator('_setEncounterData');

export const _set16100Clients = creator('_set16100Clients');

export const _set16100Client = creator('_set16100Client');

export const _search16100Failed = creator('_search16100Failed');

export const banClient = creator('banClient', P.Number);

export const _banClient = creator('_banClient');

export const unbanClient = creator('unbanClient', P.Object);

export const _unbanClient = creator('_unbanClient');

// buildingPanel modal actions
const buildingModalActions = createBuildingModalActions({namespace});
export const {
	createOtherAddress,
	setPrimaryAddress,
	removeOtherAddress,
	saveBuildingData,
	removeBuilding,
	_updateBuilding,
	toggleBuildingModal,
	_setBuildingModalProcessing,
} = buildingModalActions;
// ---

export const _setAvailableTags = creator('_setAvailableTags', P.Array);

export const getTags = creator('getTags', P.Number);

export const _getTags = creator('_getTags', P.Array);

export const attachTag = creator('attachTag', P.Object);

export const _attachTag = creator('_attachTag');

export const detachTag = creator('detachTag', P.Object);

export const _detachTag = creator('_detachTag');
