import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';

export default {
	...lifecycle,
	processing: false,
	teamsQuery: {_page: 1},
	teams: [],
	teamsLoading: true,
	teamsPagination: emptyPagination,
	teamCreatorOpen: false,
	selectedTeam: null,
	teamAreasSelectorOpen: false,
	teamAreasSelectorSearch: '',
	teamAreasSelectorSuggestionsOpen: false,
	teamAreasSelectorSuggestions: [],
};
