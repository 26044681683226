import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {getFormValues} from 'redux-form';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.fetchReport.type: {
			const form = getFormValues('activityChartSearchForm')(fullState);
			const newState = {
				...state,
				reportQuery: {
					...state.reportQuery,
					...form,
				},
				loading: true,
			};

			return [newState, effects.fetchReport()];
		}

		case actions._setReport.type: {
			return [{...state, report: payload, loading: false}, null];
		}

		case actions._updateReportQuery.type: {
			const newState = {
				...state,
				reportQuery: {
					...state.reportQuery,
					...payload,
				},
			};

			return [newState, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
