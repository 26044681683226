import {effect} from 'utils/redux';
import namespace from './namespace';
import {decorateWithNotifications} from 'io/app';
import * as actions from './actions';
import * as selectors from './selectors';
import {catchNonFatalDefault} from 'io/errors';
import {equals, mergeLeft} from 'ramda';
import {
	getBans,
	getBuildingsBans,
	deleteBan,
	updateBan as ioUpdateBan,
	postBan,
} from './io';
import {getQuery, pushQuery} from 'io/history';
import services from 'services';
import msgs from 'dicts/messages';
import {parseUrlQuery} from './utils';
import {createReferrerUrl, encodeQuery} from 'utils/url';
import * as confirmerActions from 'modules/confirmer/actions';

const creator = effect(namespace);
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const history = services.get('history');

export let initialize = () => (getState, dispatch) => {
	const {bansQuery} = parseUrlQuery(getQuery());
	const params = new URLSearchParams(document.location.search.substring(1));
	const type = params.get('type');
	if (type === 'buildings') {
		dispatch(actions._setBansType('buildings'));
	}

	dispatch(actions._updateBansQuery(bansQuery));

	fetchBans({})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

const fetchBans = ({notifyOpts = {}}) => (getState, dispatch) => {
	const bansType = selectors.bansType(getState());
	return decorateWithNotifications(
		{
			id: 'get-bans',
			failureStyle: 'warning',
			...notifyOpts,
		},
		bansType === 'phone'
			? getBans(selectors.bansQuery(getState()))
			: getBuildingsBans(selectors.bansQuery(getState())),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(b => {
			bansType === 'phone'
				? dispatch(actions._setBans(b))
				: dispatch(actions._setBuildingsBans(b));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};

export let updateBans = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));

	fetchBans({})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
updateBans = creator('updateReports', updateBans);

export let recheckQuery = () => (getState, dispatch) => {
	const urlQueries = parseUrlQuery(getQuery());
	const bansQuery = selectors.bansQuery(getState());
	if (!equals(bansQuery, urlQueries.bansQuery)) {
		dispatch(actions._updateBansQuery(urlQueries.bansQuery));

		fetchBans({})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}
};

export let searchBans = query => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'get-bans',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.loading}),
		},
		getBans(query),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(b => dispatch(actions._setBans(b)))
		.catch(catchNonFatalDefault(getState, dispatch));
};

export let navigateToBuildingPage = buildingId => (getState, dispatch) => {
	const referrerUrl = createReferrerUrl(history.location) + '&type=buildings';
	history.push(`/buildings/buildings/${buildingId}${encodeQuery({referrerUrl})}`);
};

export let removeBan = banId => (getState, dispatch) => {
	const onConfirm = () => {
		dispatch(actions._startOp());

		decorateWithNotifications(
			{
				id: 'delete-ban',
				failureStyle: 'warning',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Ban removed'}),
			},
			deleteBan(banId),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._opOk());
				dispatch(actions.closeBanModal());

				const {_page: page} = selectors.bansQuery(getState());
				if (selectors.bans(getState()).length <= 1 && page > 1) {
					dispatch(actions._updateBansQuery({_page: page - 1}));
					pushQuery(mergeLeft(selectors.urlQuery(getState())));
				}

				return fetchBans({})(getState, dispatch);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Remove from ban list?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};

export let updateBan = ban => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'update-ban',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: 'Saving'}),
			success: intl.formatMessage({id: 'Ban saved'}),
		},
		ioUpdateBan(ban),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(b => {
			dispatch(actions._updateBan(b));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};

export let createBan = ban => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'create-ban',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: 'Saving'}),
			success: intl.formatMessage({id: 'Ban saved'}),
		},
		postBan(ban),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(b => {
			dispatch(actions._addNewBan(b));
			return fetchBans({})(getState, dispatch);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
