import {state as lifecycle} from 'fragments/lifecycle';
import createBuildingModalState from 'fragments/buildingModalActions/state';

const buildingModalState = createBuildingModalState({});

export default {
	...lifecycle,
	...buildingModalState,
	processing: false,
	buildingDataLoading: true,
	building: null,
	encounters: [],
	clients: [],
	clientEditorOpen: false,
	clientInEdit: null,
	previewableEncounter: null,
	newClients: [],
	loading16100Clients: false,
	buildingModalOpen: false,
	areas: {
		counties: [],
		cities: [],
		postcodes: [],
	},
	availableTags: [],
	tags: [],
	processingTags: false,
	processingTag: false,
};
