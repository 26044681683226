import React from 'react';

const SvgAdd = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M0 6c0 .414.336.75.75.75h4.375c.069 0 .125.056.125.125v4.375a.75.75 0 1 0 1.5 0V6.875c0-.069.056-.125.125-.125h4.375a.75.75 0 1 0 0-1.5H6.875a.125.125 0 0 1-.125-.125V.75a.75.75 0 0 0-1.5 0v4.375a.125.125 0 0 1-.125.125H.75A.75.75 0 0 0 0 6z"
		/>
	</svg>
);

export default SvgAdd;
