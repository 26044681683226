import {effect} from 'utils/redux';
import namespace from './namespace';
import * as actions from './actions';
import {decorateWithNotifications} from 'io/app';
import {catchNonFatalDefault} from 'io/errors';
import services from 'services';
import msgs from 'dicts/messages';
import {createFormData} from 'utils/http';
import {
	getCategories,
	getRoles,
	postCategory,
	putCategory,
	deleteCategory,
	deleteFile,
	getOrganizations,
} from './io';
import * as selectors from './selectors';
import * as confirmerActions from 'modules/confirmer/actions';

let intl = null;
services.waitFor('intl').then(x => (intl = x));
let http = null;
services.waitFor('api').then(x => (http = x.http));

const creator = effect(namespace);

const fetchCategories =
	({notifyOpts = {}}) =>
	(getState, dispatch) => {
		return decorateWithNotifications(
			{id: 'get-categories', failureStyle: 'warning', ...notifyOpts},
			getCategories(),
		)(getState, dispatch).then(categories => {
			dispatch(actions._setCategories(categories));
		});
	};

export let initialize = () => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'init-files',
			failureStyle: 'warning',
		},
		Promise.all([
			getCategories().then(categories => dispatch(actions._setCategories(categories))),
			getRoles().then(roles => dispatch(actions._setRoles(roles))),
			getOrganizations().then(orgs => dispatch(actions._setOrganizations(orgs))),
		]),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let saveFile = fields => (getState, dispatch) => {
	const fileInEdit = selectors.fileInEdit(getState());
	const isNew = !fileInEdit;

	// NOTE: we used to also alter the form data's filename a bit to match the inputted file title instead of the original uploaded file name, but that's not necessary so it was omitted when changing the fetch logic. see 7d08f257ca1291d8ff198f80e49e15b7cff1bd85.
	const url = isNew ? '/files' : `/files/${fileInEdit.id}`;
	const method = isNew ? 'POST' : 'PUT';
	decorateWithNotifications(
		{
			id: 'save-file',
			failureStyle: 'error',
			success: intl.formatMessage({id: 'Saved'}),
			loading: intl.formatMessage({id: 'Saving'}),
		},
		http(method, url, {}, {body: createFormData(fields)}),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._setProcessing(false));
			throw e;
		})
		.then(() => {
			dispatch(actions._setProcessing(false));
			dispatch(actions.closeFileModal());

			return fetchCategories({
				notifyOpts: {loading: intl.formatMessage({id: msgs.loading})},
			})(getState, dispatch);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
saveFile = creator('saveFile', saveFile);

export let saveCategory = category => (getState, dispatch) => {
	const categoryInEdit = selectors.categoryInEdit(getState());
	const isNew = !categoryInEdit;

	decorateWithNotifications(
		{
			id: 'save-category',
			failureStyle: 'error',
			success: intl.formatMessage({id: 'Saved'}),
			loading: intl.formatMessage({id: 'Saving'}),
		},
		isNew ? postCategory(category) : putCategory({...category, id: categoryInEdit.id}),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._setProcessing(false));
			throw e;
		})
		.then(() => {
			dispatch(actions._setProcessing(false));
			dispatch(actions.closeCategoryModal());

			return fetchCategories({
				notifyOpts: {loading: intl.formatMessage({id: msgs.loading})},
			})(getState, dispatch);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
saveCategory = creator('saveCategory', saveCategory);

export let removeCategory = id => (getState, dispatch) => {
	const onConfirm = () => {
		dispatch(actions._setProcessing(true));
		decorateWithNotifications(
			{
				id: 'remove-category',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: msgs.deleted}),
			},
			deleteCategory(id),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._setProcessing(false));
				throw e;
			})
			.then(() => {
				dispatch(actions._setProcessing(false));
				dispatch(actions.closeCategoryModal());

				return fetchCategories({notifyOpts: {loading: msgs.loading}})(getState, dispatch);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete category?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
removeCategory = creator('removeCategory', removeCategory);

export let removeFile = id => (getState, dispatch) => {
	const onConfirm = () => {
		dispatch(actions._setProcessing(true));
		decorateWithNotifications(
			{
				id: 'remove-file',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: msgs.deleted}),
			},
			deleteFile(id),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._setProcessing(false));
				throw e;
			})
			.then(() => {
				dispatch(actions._setProcessing(false));
				dispatch(actions.closeFileModal());

				return fetchCategories({notifyOpts: {loading: msgs.loading}})(getState, dispatch);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete file?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
removeFile = creator('removeFile', removeFile);
