import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';

export default {
	...lifecycle,
	logsQuery: {_page: 1, type: 'stateChanged'},
	logs: [],
	logsLoading: true,
	logsPagination: emptyPagination,
	fetchingMore: false,
};
