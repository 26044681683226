import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import * as actions from './actions';
import * as effects from './effects';
import initState from './state';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {getFormValues} from 'redux-form';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.toggleSettingsModal.type: {
			const selectedItem = payload ? payload : {};
			const settingsModalOpen = payload ? true : false;
			return [{...state, settingsModalOpen, selectedItem}, null];
		}

		case actions.createSetting.type: {
			const form = getFormValues('settingsForm')(fullState);
			return [state, effects.createSetting({...payload, ...form})];
		}

		case actions.updateSetting.type: {
			const form = getFormValues('settingsForm')(fullState);
			return [state, effects.updateSetting({...payload, ...form})];
		}

		case actions.removeSetting.type: {
			return [state, effects.removeSetting(payload)];
		}

		case actions.restoreSetting.type: {
			return [state, effects.restoreSetting(payload)];
		}

		case actions.setShowDeleted.type: {
			const loading = payload.type + 'Loading';
			const trashed = !state[payload.type + 'ShowDeleted'];

			return [
				{...state, ...payload, [loading]: true},
				effects.fetchSettings({type: payload.type, trashed}),
			];
		}

		case actions.openUploadModal.type: {
			return [{...state, uploadModalOpen: true}, null];
		}

		case actions.closeUploadModal.type: {
			return [{...state, uploadModalOpen: false}, null];
		}

		case actions.uploadExcel.type: {
			return [{...state, processing: true}, effects.uploadExcel(payload)];
		}

		case actions._setCategories.type: {
			return [{...state, categories: payload, categoriesLoading: false}, null];
		}
		case actions._setChannels.type: {
			return [{...state, channels: payload, channelsLoading: false}, null];
		}
		case actions._setCustomerTypes.type: {
			return [{...state, customerTypes: payload, customerTypesLoading: false}, null];
		}
		case actions._setSources.type: {
			return [{...state, sources: payload, sourcesLoading: false}, null];
		}

		case actions._addToSettings.type: {
			const updatedSettingsList = [...state[payload.type], payload];

			return [
				{...state, [payload.type]: updatedSettingsList, settingsModalOpen: false},
				null,
			];
		}

		case actions._updateToSettings.type: {
			const updatedSettingsList = state[payload.type].map(s =>
				s.id === payload.id ? {...s, ...payload} : s,
			);

			return [
				{...state, [payload.type]: updatedSettingsList, settingsModalOpen: false},
				null,
			];
		}

		case actions._updateLists.type: {
			const updatedSettingsList = state[payload.type].filter(c => c.id !== payload.id);

			return [
				{...state, [payload.type]: updatedSettingsList, settingsModalOpen: false},
				null,
			];
		}

		case actions._setTags.type: {
			return [{...state, tags: payload, tagsLoading: false}, null];
		}

		case actions._setServiceMappings.type: {
			return [{...state, serviceMappings: payload, serviceMappingsLoading: false}, null];
		}

		case actions._setOrganizations.type: {
			return [{...state, organizations: payload}, null];
		}

		case actions._setProducts.type: {
			return [{...state, products: payload}, null];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true, processing: false}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._excelUploaded.type: {
			return [{...state, processing: false, uploadModalOpen: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
