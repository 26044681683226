import services from 'services';
import {catchNonFatalDefault} from 'io/errors';
import {effect} from 'utils/redux';
import namespace from './namespace';
import {getTags as getAvailableTags} from 'modules/usersApp/tagsPage/io';
import * as confirmerActions from 'modules/confirmer/actions';
import * as actions from './actions';
import * as selectors from './selectors';
import {
	getSmsTemplates as getSmsTemplatesIo,
	createSmsTemplate as createSmsTemplateIo,
	updateSmsTemplate as updateSmsTemplateIo,
	deleteSmsTemplate as deleteSmsTemplateIo,
	restoreSmsTemplate as restoreSmsTemplateIo,
} from './io';
import {decorateWithNotifications} from 'io/app';
import {getQuery, pushQuery} from 'io/history';
import messages from 'dicts/messages';
import {parseUrlQuery} from './utils';
import {equals, mergeLeft} from 'ramda';
import {TYPE_BUILDING} from '../tagsPage/constants';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

export let initialize = () => (getState, dispatch) => {
	const {trashed} = parseUrlQuery(getQuery());
	dispatch(actions._setShowDeleted(trashed));
	dispatch(actions._initialize());

	getAvailableTags({
		type: TYPE_BUILDING,
		getAllTags: false,
	}).then(({data: tags}) => {
		dispatch(actions._setAvailableTags(tags));
	});
};
initialize = creator('initialize', initialize);

export let getSmsTemplates = () => (getState, dispatch) => {
	const query = selectors.smsTemplatesQuery(getState());
	getSmsTemplatesIo(query)
		.then(({data: smsTemplates}) => {
			dispatch(actions._getSmsTemplates(smsTemplates));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
getSmsTemplates = creator('getSmsTemplates', getSmsTemplates);

export let createSmsTemplate = values => (getState, dispatch) => {
	decorateWithNotifications(
		{id: 'create-smsTemplate', loading: intl.formatMessage({id: messages.processing})},
		createSmsTemplateIo(values),
	)(getState, dispatch)
		.then(smsTemplate => {
			const smsTemplates = selectors.smsTemplates(getState());
			dispatch(actions._getSmsTemplates([smsTemplate, ...smsTemplates]));
			dispatch(actions.closeSmsTemplateModal());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
createSmsTemplate = creator('createSmsTemplate', createSmsTemplate);

export let updateSmsTemplate =
	({id, values}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'update-smsTemplate',
				loading: intl.formatMessage({id: messages.processing}),
			},
			updateSmsTemplateIo(id, values),
		)(getState, dispatch)
			.then(smsTemplate => {
				const smsTemplates = selectors
					.smsTemplates(getState())
					.map(t => (t.id === smsTemplate.id ? smsTemplate : t));
				dispatch(actions._getSmsTemplates(smsTemplates));
				dispatch(actions.closeSmsTemplateModal());
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
updateSmsTemplate = creator('updateSmsTemplate', updateSmsTemplate);

export let deleteSmsTemplate = id => (getState, dispatch) => {
	const onConfirm = () => {
		decorateWithNotifications(
			{
				id: 'delete-smsTemplate',
				loading: intl.formatMessage({id: messages.processing}),
			},
			deleteSmsTemplateIo(id),
		)(getState, dispatch)
			.then(() => {
				const smsTemplates = selectors.smsTemplates(getState()).filter(t => t.id !== id);
				dispatch(actions._getSmsTemplates(smsTemplates));
				dispatch(actions.closeSmsTemplateModal());
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete smsTemplate?'}),
			cancelText: intl.formatMessage({id: messages.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
deleteSmsTemplate = creator('deleteSmsTemplate', deleteSmsTemplate);

export let restoreSmsTemplate = id => (getState, dispatch) => {
	decorateWithNotifications(
		{id: 'update-smsTemplate', loading: intl.formatMessage({id: messages.processing})},
		restoreSmsTemplateIo(id),
	)(getState, dispatch)
		.then(smsTemplate => {
			const smsTemplates = selectors
				.smsTemplates(getState())
				.filter(t => t.id !== smsTemplate.id);
			dispatch(actions._getSmsTemplates(smsTemplates));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
restoreSmsTemplate = creator('restoreSmsTemplate', restoreSmsTemplate);

export let recheckQuery = () => (getState, dispatch) => {
	const {trashed} = parseUrlQuery(getQuery());
	const currentTrashed = selectors.showDeleted(getState());
	if (!equals(trashed, currentTrashed)) {
		dispatch(actions._setShowDeleted(trashed));
	}
	dispatch(actions.getSmsTemplates());
};
recheckQuery = creator('recheckQuery', recheckQuery);

export let setShowDeleted = shouldShow => (getState, dispatch) => {
	pushQuery(
		mergeLeft({
			trashed: shouldShow,
		}),
	);
	dispatch(actions._setShowDeleted(shouldShow));
};
setShowDeleted = creator('setShowDeleted', setShowDeleted);
