export default {
	ok: 'Ok',
	cancel: 'Cancel',
	save: 'Save',
	remove: 'Delete',
	close: 'Close',
	clear: 'Clear',
	selectorPrompt: 'Choose...',
	selectorEmpty: 'No selection',
	requiredField: 'Required field',
	requiredFieldWithName: 'Required field {name}',
	requiredLength: 'Required length: {length}',
	conflictingFieldWithName: 'Conflicting field: {name}',
	invalidField: 'Invalid field',
	maxLength: 'Max length: {length}',
	minLength: 'Min length: {length}',
	processing: 'Processing',
	loading: 'Loading',
	appDataFetchFailed: 'Could not load application data. Try refreshing the page',
	contentFetchFailed: 'Content loading failed. Try refreshing the page',
	invalidDateRange: 'Invalid date range',
	// NOTE: legacy
	contentPostFailed: 'Failed to save',
	saveFailed: 'Failed to save',
	saved: 'Saved',
	// TODO: should add a generic "done" or "finished" message also
	deleted: 'Deleted',
	deleteFailed: 'Failed to delete',
};
