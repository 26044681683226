import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const getDashboard = creator('getDashboard', P.Object);
export const _getDashboard = creator('_getDashboard', P.Object);

export const getTeams = creator('getTeams', P.Object);
export const _getTeams = creator('_getTeams');

export const _getUnacknowledgedVisitsCount = creator(
	'_getUnacknowledgedVisitsCount',
	P.Number,
);
