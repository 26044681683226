import {effect} from 'utils/redux';
import {catchNonFatalDefault} from 'io/errors';
import namespace from './namespace';
import * as actions from './actions';
import {getOrganizations, getRoles, getTeams, getPermissions} from './io';
import * as commonSelectors from 'modules/common/selectors';
import {appName} from '../constants';
import {resolveObject} from 'utils/promises';
import {ensureAccess, setPageTitleMessage, decorateWithNotifications} from 'io/app';

const creator = effect(namespace);

const fetchData = (getState, dispatch) => {
	// const usersQuery = {
	// 	...selectors.allUsersQueryFetchable(getState()),
	// 	_limit: '999',
	// };

	return decorateWithNotifications(
		{id: 'fetch-users-app-data', failureStyle: 'warning'},
		Promise.all([
			resolveObject({
				organizations: getOrganizations({_limit: '999'}),
				roles: getRoles({_limit: '999', include: 'permissions'}),
				teams: getTeams({_limit: '999'}),
				permissions: getPermissions({_limit: '999'}),
			}).then(data => {
				dispatch(actions._setInitMeta(data));
			}),
		]).catch(catchNonFatalDefault(getState, dispatch)),
	);
};

export let initialize = () => (getState, dispatch) => {
	setPageTitleMessage('Users [app]')(getState, dispatch);

	ensureAccess(appName, commonSelectors.user(getState()))(getState, dispatch)
		.then(() => fetchData(getState, dispatch))
		.then(() => {
			dispatch(actions._initialize());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let updateData = () => (getState, dispatch) => {
	fetchData(getState, dispatch);
};
