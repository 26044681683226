import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {REASON_MAPPING_FORM} from 'views/UsersApp/ReasonMappingsPage/components/ReasonMappingForm';
import {getFormValues} from 'redux-form';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [
				{
					...state,
				},
				effects.initialize(),
			];
		}

		case actions._initialize.type: {
			return [
				{
					...state,
					initialized: true,
				},
				null,
			];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions._setAvailableTags.type: {
			return [{...state, availableTags: payload}, null];
		}

		case actions.getReasonMappings.type: {
			return [
				{
					...state,
					reasonMappingsLoading: true,
				},
				effects.getReasonMappings(),
			];
		}

		case actions._getReasonMappings.type: {
			return [
				{
					...state,
					reasonMappingsLoading: false,
					reasonMappings: payload,
				},
				null,
			];
		}

		case actions.openReasonMapping.type: {
			return [
				{
					...state,
					isReasonMappingModalOpen: true,
					activeReasonMapping: payload,
				},
				null,
			];
		}

		case actions.openReasonMappingModal.type: {
			return [
				{
					...state,
					isReasonMappingModalOpen: true,
				},
				null,
			];
		}

		case actions.closeReasonMappingModal.type: {
			return [
				{
					...state,
					isReasonMappingModalOpen: false,
					activeReasonMapping: null,
				},
				null,
			];
		}

		case actions.createReasonMapping.type: {
			const form = getFormValues(REASON_MAPPING_FORM)(fullState);
			const values = {
				...form,
				tags: (form?.tags || []).map(t => ({id: t})),
			};

			return [
				{
					...state,
					processing: true,
				},
				effects.createReasonMapping(values),
			];
		}

		case actions.updateReasonMapping.type: {
			const id = payload;
			const form = getFormValues(REASON_MAPPING_FORM)(fullState);

			const values = {
				...form,
				tags: (form?.tags || []).map(t => ({id: t})),
			};

			return [
				{
					...state,
					processing: true,
				},
				effects.updateReasonMapping({id, values}),
			];
		}

		case actions.deleteReasonMapping.type: {
			const id = payload;
			return [state, effects.deleteReasonMapping(id)];
		}

		case actions.setShowDeleted.type: {
			return [state, effects.setShowDeleted(payload)];
		}

		case actions._setShowDeleted.type: {
			return [
				{
					...state,
					reasonMappingsQuery: {
						...state.reasonMappingsQuery,
						trashed: payload,
					},
				},
				null,
			];
		}

		case actions.restoreReasonMapping.type: {
			const id = payload;
			return [state, effects.restoreReasonMapping(id)];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
