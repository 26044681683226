import styled from 'styled-components';
import {brandColor} from 'styles/constants';
import {textButton} from 'styles/fragments';

const HeaderTabButton = styled.button`
	${textButton};
	margin-left: 20px;
	height: 100%;
	border-radius: 0;
	border-bottom: 2px solid transparent;

	&:first-of-type {
		margin-left: 0;
	}

	&:hover,
	&:active,
	&:focus {
		border-bottom: 2px solid ${brandColor};
		cursor: pointer;
	}
	${({active}) =>
		active
			? `
		background-color: transparent;
		color: ${brandColor};
		border-bottom: 2px solid ${brandColor};`
			: null};
`;

export default HeaderTabButton;
