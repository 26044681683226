import React from 'react';

const SvgDoubleArrowLeft = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M5.208 6c0-.352.139-.689.388-.938l4.77-4.77a.884.884 0 0 1 1.25 1.25l-4.37 4.37a.125.125 0 0 0 0 .176l4.37 4.37a.884.884 0 0 1-1.25 1.25l-4.77-4.77A1.322 1.322 0 0 1 5.208 6zM.125 6c0-.352.139-.689.387-.938L5.283.292a.884.884 0 0 1 1.25 1.25l-4.37 4.37a.125.125 0 0 0 0 .176l4.37 4.37a.884.884 0 0 1-1.25 1.25l-4.77-4.77A1.322 1.322 0 0 1 .124 6z"
		/>
	</svg>
);

export default SvgDoubleArrowLeft;
