import importMaps from 'services/importMaps';
import {locale} from 'constants/app';

const decorateMapMethod = method => args => importMaps().then(method(args));

let autosuggestService = null;
let geocodeService = null;
let placesService = null;

const _autosuggestAreas = ({input, types}) => imports => {
	if (!autosuggestService) {
		autosuggestService = new imports.googleMaps.places.AutocompleteService();
	}
	return new Promise((resolve, reject) => {
		autosuggestService.getPlacePredictions(
			{input, componentRestrictions: {country: locale}, types},
			(res, status) => {
				if (status === 'ZERO_RESULTS') {
					resolve([]);
				} else if (status !== 'OK') {
					reject(new Error(`Request failed: ${status}`));
				} else {
					resolve(res);
				}
			},
		);
	});
};
export const autosuggestAreas = decorateMapMethod(_autosuggestAreas);

const _geocodeGooglePlaceId = id => imports => {
	if (!geocodeService) {
		geocodeService = new imports.googleMaps.Geocoder();
	}
	return new Promise((resolve, reject) => {
		geocodeService.geocode({placeId: id}, (res, status) => {
			if (status !== 'OK') {
				reject(new Error('Request failed'));
			} else {
				resolve(res[0]);
			}
		});
	});
};
export const geocodeGooglePlaceId = decorateMapMethod(_geocodeGooglePlaceId);

const _getLocationByAddress = address => imports => {
	if (!geocodeService) {
		geocodeService = new imports.googleMaps.Geocoder();
	}
	return new Promise((resolve, reject) => {
		geocodeService.geocode({address, region: locale.toUpperCase()}, (res, status) => {
			if (status !== 'OK') {
				reject(new Error('Request failed'));
			} else {
				resolve(res[0]);
			}
		});
	});
};
export const getLocationByAddress = decorateMapMethod(_getLocationByAddress);

const _getPlaceDetails = ({placeId, fields}) => imports => {
	if (!placesService) {
		placesService = new imports.googleMaps.places.PlacesService(
			document.createElement('div'), // hack to use PlacesService without map element
		);
	}
	return new Promise((resolve, reject) => {
		placesService.getDetails({placeId, fields}, (res, status) => {
			if (status !== 'OK') {
				reject(new Error('Request failed'));
			} else {
				resolve(res);
			}
		});
	});
};
export const getPlaceDetails = decorateMapMethod(_getPlaceDetails);
