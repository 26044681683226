import {state as lifecycle} from 'fragments/lifecycle';
import {localeCenterWeb, localeZoomFactor} from 'constants/maps';

export const initialFilters = {
	encounterState: 'any',
	minYear: null,
	maxYear: null,
	tagIds: '',
};

export default {
	...lifecycle,
	buildingsQuery: {
		z: localeZoomFactor * 5,
		x: localeCenterWeb[0],
		y: localeCenterWeb[1],
		selectionId: null,
		mapSource: 'here-streets',
		groundwaterAreasLayer: false,
		propertyLinesLayer: false,
		...initialFilters,
	},
	followingGeolocation: false,
	geolocationLoading: false,
	addBuildingMenuOpen: false,
	newBuildingCoords: [],
	addBuildingModalOpen: false,
	processing: false,
	mapInitialized: false,
};
