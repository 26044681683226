import {scopedHandler} from 'utils/redux';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {sortTeamUsers, formatTeamTargetFormOutput} from './utils';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.saveTargets.type: {
			const targets = formatTeamTargetFormOutput({
				form: payload,
				teamId: state.userTeams[0].id,
			});
			return [{...state, processing: true}, effects.saveTargets(targets)];
		}

		case actions.updateTeamTargetsQuery.type: {
			const newState = {
				...state,
				teamTargetsLoading: true,
				teamTargetsQuery: {...state.teamTargetsQuery, ...payload},
			};

			return [newState, effects.updateTeamTargets()];
		}

		case actions._setUserTeams.type: {
			return [
				{...state, userTeams: sortTeamUsers(payload), userTeamsLoading: false},
				null,
			];
		}

		case actions._saveTargets.type: {
			return [{...state, processing: false}, null];
		}

		case actions._setTeamTargets.type: {
			return [{...state, teamTargetsLoading: false, teamTargets: payload}];
		}

		case actions._updateTeamTargetsQuery.type: {
			const newState = {
				...state,
				teamTargetsQuery: {...state.teamTargetsQuery, ...payload},
			};

			return [newState, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
