import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const _setImportHistory = creator('_setImportHistory', P.Object);

export const getImportHistory = creator('_getImportHistory');

export const showFileUploadModal = creator('showFileUploadModal');

export const hideFileUploadModal = creator('hideFileUploadModal');

export const saveCsvImport = creator('saveCsvImport');

export const saveCsvImportSuccess = creator('saveCsvImportSuccess');

export const navigatetoCsvImportPage = creator('navigatetoCsvImportPage');

export const changePage = creator('changePage', P.Number);
