import React, {useState} from 'react';
import Collapse from 'components/generic/Collapse';
import {FormattedMessage, injectIntl} from '@meiko/react-intl';
import {compose} from 'redux';
import FormBlock from 'components/generic/FormBlock';
import Label from 'components/generic/Label';
import FieldError from 'components/generic/FieldError';
import Field from 'components/generic/Field';
import {salesmanVisitStates} from 'dicts/salesmanVisits';
import {state as encounterState} from 'dicts/encounters';
import {statesByType} from 'modules/usersApp/reasonMappingsPage/constants';
import {
	reasonMappingTypeCall,
	reasonMappingTypeSalesmanVisit,
	reasonMappingTypeVisit,
} from 'modules/common/constants';
import {uniq} from 'ramda';
import ReactSelect from 'components/generic/ReactSelect';

const LatestEncounterReasonMappingFormlet = ({
	intl,
	change,
	latestEncounterTypeValue: latestEncounterTypeValueStr,
	latestEncounterStateValue: latestEncounterStateValueStr,
	reasonMappings = [],
	defaultCollapsed = false,
	disabled = false,
}) => {
	const latestEncounterTypeValue = (latestEncounterTypeValueStr || '')
		.split(',')
		.filter(v => !!v);
	const latestEncounterStateValue = (latestEncounterStateValueStr || '')
		.split(',')
		.filter(v => !!v);

	const reasonMappingsById = reasonMappings.reduce((acc, cur) => {
		acc[cur.id] = cur;
		return acc;
	}, {});
	const [isReasonMappingFilterVisible, setIsReasonMappingFilterVisible] =
		useState(defaultCollapsed);

	const latestEncounterStateDict =
		latestEncounterTypeValue &&
		latestEncounterTypeValue.length &&
		latestEncounterTypeValue[0] === reasonMappingTypeSalesmanVisit
			? salesmanVisitStates
			: encounterState;

	const latestEncounterStateOptions = (
		latestEncounterTypeValue && latestEncounterTypeValue.length > 0
			? latestEncounterTypeValue.reduce((acc, cur) => {
					return uniq([...acc, ...statesByType[cur]]);
			  }, [])
			: uniq(Object.values(statesByType).flat())
	).map(v => ({
		value: v,
		label: intl.formatMessage({id: latestEncounterStateDict[v]}),
	}));

	const defaultOptions = reasonMappings
		.filter(reasonMapping => {
			if (
				latestEncounterTypeValue &&
				latestEncounterTypeValue.length > 0 &&
				!latestEncounterTypeValue.includes(reasonMapping.type)
			) {
				return false;
			}

			if (
				latestEncounterStateValue &&
				latestEncounterStateValue.length > 0 &&
				!latestEncounterStateValue.includes(reasonMapping.state)
			) {
				return false;
			}
			return true;
		})
		.map(reasonMapping => ({
			value: reasonMapping.id,
			label: reasonMapping.title,
		}));

	const loadOptions = inputValue =>
		new Promise(resolve => {
			if (reasonMappings.length > 0) {
				resolve(
					reasonMappings
						.filter(reasonMapping => {
							const title = (reasonMapping?.title ?? '').toLowerCase();
							return title.includes(inputValue.toLowerCase());
						})
						.map(reasonMapping => ({
							value: reasonMapping.id,
							label: reasonMapping.title,
						})),
				);
			} else {
				resolve([]);
			}
		});

	return (
		<Collapse
			onToggle={() => setIsReasonMappingFilterVisible(!isReasonMappingFilterVisible)}
			isVisible={isReasonMappingFilterVisible}
			title={intl.formatMessage({id: 'Filter by reasonMapping'})}
		>
			<Field
				name="latestEncounterType"
				format={value => {
					return (value || '')
						.split(',')
						.filter(v => !!v)
						.map(value => ({
							value,
							label: intl.formatMessage({
								id: `ReasonMapping type:${value.toLowerCase()}`,
							}),
						}));
				}}
				parse={value => {
					return [...new Set(value.map(v => (v.value ? v.value : v)))].join(',');
				}}
				props={{
					disabled,
					defaultOptions: [
						reasonMappingTypeCall,
						reasonMappingTypeVisit,
						reasonMappingTypeSalesmanVisit,
					].map(v => ({
						value: v,
						label: intl.formatMessage({
							id: `ReasonMapping type:${v.toLowerCase()}`,
						}),
					})),
				}}
				onChange={() => {
					change('latestEncounterState', '');
					change('latestEncounterReasonMappingId', '');
				}}
				component={({input, inputId, meta, defaultOptions, disabled}) => (
					<FormBlock>
						<Label htmlFor={inputId}>
							<FormattedMessage id="Encounter type" />
						</Label>
						<ReactSelect
							{...input}
							minWidth="150px"
							id={inputId}
							async
							block
							isMulti
							defaultOptions={defaultOptions}
							closeMenuOnSelect={false}
							hideSelectedOptions={false}
							disabled={disabled}
						/>
						<FieldError {...meta} />
					</FormBlock>
				)}
			/>

			{latestEncounterStateOptions && latestEncounterStateOptions.length > 1 && (
				<Field
					name="latestEncounterState"
					format={value => {
						return (value || '')
							.split(',')
							.filter(v => !!v)
							.map(value => ({
								value,
								label: intl.formatMessage({
									id: latestEncounterStateDict[value],
								}),
							}));
					}}
					parse={value => {
						return [...new Set(value.map(v => (v.value ? v.value : v)))].join(',');
					}}
					props={{
						disabled,
						defaultOptions: latestEncounterStateOptions,
					}}
					onChange={() => {
						change('latestEncounterReasonMappingId', '');
					}}
					component={({input, inputId, meta, defaultOptions, disabled}) => (
						<FormBlock>
							<Label htmlFor={inputId}>
								<FormattedMessage id="Encounter state" />
							</Label>
							<ReactSelect
								{...input}
								minWidth="150px"
								id={inputId}
								async
								block
								isMulti
								defaultOptions={defaultOptions}
								closeMenuOnSelect={false}
								hideSelectedOptions={false}
								disabled={disabled}
							/>
							<FieldError {...meta} />
						</FormBlock>
					)}
				/>
			)}
			<Field
				name="latestEncounterReasonMappingId"
				format={value => {
					return (value || '')
						.split(',')
						.filter(id => !!id && !!reasonMappingsById[id])
						.map(id => ({
							value: id,
							label: reasonMappingsById[id].title,
						}));
				}}
				parse={value => {
					return [...new Set(value.map(v => (v.value ? v.value : v)))].join(',');
				}}
				props={{
					disabled,
					defaultOptions,
					loadOptions,
				}}
				component={({input, inputId, meta, defaultOptions, loadOptions, disabled}) => (
					<FormBlock>
						<Label htmlFor={inputId}>
							<FormattedMessage id="ReasonMapping" />
						</Label>
						<ReactSelect
							{...input}
							minWidth="150px"
							id={inputId}
							async
							block
							isMulti
							loadOptions={loadOptions}
							defaultOptions={defaultOptions}
							closeMenuOnSelect={false}
							hideSelectedOptions={false}
							disabled={disabled}
						/>
						<FieldError {...meta} />
					</FormBlock>
				)}
			/>
		</Collapse>
	);
};

export default compose(injectIntl)(LatestEncounterReasonMappingFormlet);
