import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {getFormValues} from 'redux-form';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.changePage.type: {
			const newState = {
				...state,
				bansLoading: true,
				bansQuery: {...state.bansQuery, _page: payload},
			};

			return [newState, effects.updateBans()];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		case actions.banSearch.type: {
			const form = getFormValues('banSearchForm')(fullState);
			const _q = form ? form.banSearch : null;
			return [state, effects.searchBans({_q: _q})];
		}

		case actions.closeBanModal.type: {
			return [
				{
					...state,
					banModalOpen: false,
					activeBan: {},
					banFormInitialValues: initState.banFormInitialValues,
				},
				null,
			];
		}

		case actions.navigateToBuildingPage.type: {
			return [state, effects.navigateToBuildingPage(payload)];
		}

		case actions.openBanModal.type: {
			return [
				{
					...state,
					banModalOpen: true,
					activeBan: payload.id ? payload : {},
					banFormInitialValues: payload,
				},
				null,
			];
		}

		case actions.removeBan.type: {
			const banId = state.activeBan.id;
			return [state, effects.removeBan(banId)];
		}

		case actions.updateBan.type: {
			const form = getFormValues('banForm')(fullState);
			return [
				{...state, processing: true},
				state.activeBan.id ? effects.updateBan(form) : effects.createBan(form),
			];
		}

		case actions.setBansType.type: {
			return [
				{...state, bansType: payload, bansLoading: true, bansQuery: initState.bansQuery},
				effects.updateBans(),
			];
		}

		case actions._updateBan.type: {
			const bans = state.bans.map(b => {
				return b.id === payload.id ? {...b, ...payload} : b;
			});
			return [
				{
					...state,
					bans,
					bansLoading: false,
					banModalOpen: false,
					banFormInitialValues: initState.banFormInitialValues,
					processing: false,
				},
				null,
			];
		}
		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._setBansType.type: {
			return [{...state, bansType: payload}, null];
		}

		case actions._setBans.type: {
			const bansPagination = payload.pagination;
			const bans = payload.data;
			return [{...state, bans, bansPagination, bansLoading: false}, null];
		}

		case actions._setBuildingsBans.type: {
			const buildingsBans = payload.data;
			const buildingsBansPagination = payload.pagination;
			return [
				{...state, buildingsBans, bansLoading: false, buildingsBansPagination},
				null,
			];
		}

		case actions._updateBansQuery.type: {
			const newState = {
				...state,
				bansLoading: true,
				bansQuery: {...state.bansQuery, ...payload},
			};

			return [newState, null];
		}

		case actions._addNewBan.type: {
			return [
				{
					...state,
					banModalOpen: false,
					banFormInitialValues: initState.banFormInitialValues,
					processing: false,
				},
				null,
			];
		}

		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
