import React from 'react';
import styled from 'styled-components';
//
import {
	textColorBase,
	linkColor,
	organizationPrimaryColors,
	fontSizeBase,
	fontSizeS,
	textColorLight,
	notificationColor,
	fontWeightMedium,
	borderColorBase,
	navHeight,
	successColor,
} from 'styles/constants';
import {textStyles, buttonUnstyled} from 'styles/fragments';
import {
	FormattedNumber,
	FormattedDate,
	FormattedMessage,
	injectIntl,
} from '@meiko/react-intl';
import {createReferrerUrl, encodeQuery} from 'utils/url';
import services from 'services';
// components
import Icon from './Icon';
import RouterLink from 'components/generic/RouterLink';
import P from 'components/generic/P';
import LinkButton from 'components/generic/LinkButton';
import Button from 'components/generic/Button';
import TextButton from './TextButton';
import ContentLoader from './ContentLoader';

const history = services.get('history');

const navBorderStatic = `1px solid ${borderColorBase}`;

const Notification = styled.span`
	width: 16px;
	height: 16px;
	color: white;
	font-size: ${fontSizeS};
	text-align: center;
	background: ${notificationColor};
	border-radius: 50%;
	margin-left: 8px;
	display: flex;
	justify-content: center;

	${
		/*prettier-ignore*/ ({organizationId}) => organizationId ? `
		margin-left: -10px;
		margin-top: -16px;
		border: 2px solid ${organizationPrimaryColors[organizationId]}
	` : `
		padding: 1px;
		width: 16px;
		> span {
			margin-top: auto;
			margin-bottom: auto;
		}
	`
	}
`;

/* eslint-disable react/prop-types */
export const NoticeNotification = ({length, organizationId, ...rest}) => {
	return (
		!!length && (
			<Notification organizationId={organizationId} {...rest}>
				<FormattedNumber value={length} />
			</Notification>
		)
	);
};

// invisible animation & position container for notices
// lots of stuff copied from Navbar menu styles, refer there
const NoticesContainer = styled.div`
	z-index: 52;
	/* note: this is no longer necessary - it just hides the translated notices menu, but it's not visible on-screen without this and doesn't cause scrollbars to appear. */
	overflow: hidden;
	position: absolute;
	right: 0;
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - ${navHeight} - 10px);
	/* RightMenu is expected to never be wider than 250px or so. cover it and then some. */
	width: 360px;
	max-width: calc(100vh - 48px);
`;

// lots of stuff copied from Navbar menu styles, refer there
const NoticesWrapper = styled.div`
	overflow: auto;
	display: flex;
	flex-direction: column;
	border-left: ${navBorderStatic};
	border-right: ${navBorderStatic};
	border-bottom: ${navBorderStatic};
	background: white;

	animation: slide-in 0.15s ease-out;
	@keyframes slide-in {
		from {
			transform: translateX(100%);
		}
		to {
			transform: translateX(0);
		}
	}
`;

const NoticeList = styled.div`
	flex: 1;
	overflow: auto;
`;

const NoticesHeader = styled.div`
	display: flex;
	flex-shrink: 0;
	justify-content: space-between;
	padding: 16px;
	align-items: center;
	border-bottom: 1px solid ${borderColorBase};
`;

const CloseNoticesButton = styled.button`
	${buttonUnstyled};
	padding: 16px;
	margin-right: -16px;
	margin-top: -16px;
	margin-bottom: -16px;
	color: ${textColorLight};
	&:hover,
	&:active,
	&:focus {
		color: ${textColorBase};
	}
`;

const NoticeItem = styled.div`
	display: flex;
	color: ${textColorBase};
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid ${borderColorBase};
	padding: 16px;
	background: white;
`;

const NoticeItemContent = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledIcon = styled(Icon)`
	margin-left: 8px;
`;

const NoticeContent = styled.span`
	padding: 4px 0px;
	white-space: pre-line;
`;

const NoticeItemFirstRow = styled.span`
	padding: 4px 0px;
	> :not(:last-child) {
		margin-right: 8px;
		color: ${textColorLight};
	}
`;

const NoticesTitle = styled.div`
	${textStyles.baseMedium};
	color: ${textColorLight};
`;

const ShowMoreButton = styled(TextButton)`
	padding: 16px;
	text-align: center;
	width: 100%;
`;

const NoNotices = styled(P)`
	padding: 0 16px;
	color: ${textColorBase};
`;

const NoticesHeaderActions = styled.div`
	display: flex;
	flex-direction: row;
`;

const IconButton = styled(Button)`
	margin-right: -16px;
	color: ${textColorLight};
	cursor: pointer;

	&:not(:disabled) {
		&:hover,
		&:focus {
			color: ${successColor};
		}
	}

	&&& {
		background: none;
	}
`;

export const NoticesList = injectIntl(
	({
		intl,
		personalNotices,
		showNotices,
		toggleNotices,
		onItemSelect,
		personalNoticesPagination,
		getMoreNotices,
		loadingPersonalNotices,
		personalNoticesProcessing,
		setAllNoticesSeen,
		setNoticeSeen,
		hasUnseenNotices,
	}) => {
		// don't render anything unnecessarily since our animations don't depend on it, and calling "createReferrerUrl" when not visible would be wasteful.
		if (!showNotices) {
			return null;
		}
		const referrerUrl = createReferrerUrl(history.location);
		const {currentPage, totalPages} = personalNoticesPagination;

		return (
			<NoticesContainer>
				<NoticesWrapper showNotices={showNotices}>
					<NoticesHeader>
						<NoticesTitle>
							<FormattedMessage id="Notifications" />
						</NoticesTitle>
						<NoticesHeaderActions>
							{hasUnseenNotices && (
								<LinkButton
									onClick={() => setAllNoticesSeen()}
									disabled={personalNoticesProcessing}
								>
									<FormattedMessage id="Mark all as read" />
								</LinkButton>
							)}
							<CloseNoticesButton onClick={() => toggleNotices()}>
								<Icon name="close" height="10px" width="10px" />
							</CloseNoticesButton>
						</NoticesHeaderActions>
					</NoticesHeader>
					{!loadingPersonalNotices && !personalNotices.length ? (
						<NoNotices>
							<FormattedMessage id="No notifications" />
						</NoNotices>
					) : (
						<NoticeList>
							{personalNotices.map(n => {
								return (
									<NoticeItem key={n.id}>
										<NoticeItemContent>
											<NoticeItemFirstRow>
												{!n.seen && (
													<Icon name="noticeAlert" height="12px" width="12px" />
												)}
												<FormattedDate value={n.createdAt} />
												<span>{n.title}</span>
											</NoticeItemFirstRow>
											{n.content && (
												<NoticeContent
													dangerouslySetInnerHTML={{
														__html: n.hideContent ? null : n.content,
													}}
												/>
											)}
											{n.type === 'appUpdate' ? (
												<LinkButton onClick={() => window.location.reload(true)}>
													<FormattedMessage id="Please update the application" />
													<StyledIcon name="chevronRight" height="12px" width="12px" />
												</LinkButton>
											) : n.meta?.leadId ? (
												//prettier-ignore
												<RouterLink
														to={n.meta.marketingLeadMail ?
														`/marketing/lead/${n.meta.leadId}${encodeQuery({ referrerUrl })}` :
														`/leads/lead/${n.meta.leadId}${encodeQuery({ referrerUrl })}`}
													onClick={() => onItemSelect({id: n.id, metaField: 'leadId'})}
												>
													<FormattedMessage id="Go to the lead" />
													<StyledIcon name="chevronRight" height="12px" width="12px" />
												</RouterLink>
											) : n.meta?.calendarResourceId ? (
												<RouterLink
													to={`/sales/${n.meta.calendarResourceId}${encodeQuery({
														referrerUrl,
													})}`}
													onClick={() =>
														onItemSelect({id: n.id, metaField: 'calendarResourceId'})
													}
												>
													<FormattedMessage id="Go to the sales app" />
													<StyledIcon name="chevronRight" height="12px" width="12px" />
												</RouterLink>
											) : n.meta?.buildingId ? (
												<RouterLink
													to={`/buildings/buildings/${n.meta.buildingId}${encodeQuery({
														referrerUrl,
													})}`}
													onClick={() =>
														onItemSelect({id: n.id, metaField: 'buildingId'})
													}
												>
													<FormattedMessage id="Go to the buildings app" />
													<StyledIcon name="chevronRight" height="12px" width="12px" />
												</RouterLink>
											) : n.meta?.callAt ? (
												<RouterLink
													to={`/team-calendar/calendar${encodeQuery({
														openToDate: n.meta.callAt,
														referrerUrl,
													})}`}
													onClick={() => onItemSelect({id: n.id, metaField: 'callAt'})}
												>
													<FormattedMessage id="Go to team calendar" />
													<StyledIcon name="chevronRight" height="12px" width="12px" />
												</RouterLink>
											) : n.meta?.adminEventsCount ? (
												<RouterLink
													to="/control-center/events"
													onClick={() =>
														onItemSelect({id: n.id, metaField: 'adminEventsCount'})
													}
												>
													<FormattedMessage id="Go to events" />
													<StyledIcon name="chevronRight" height="12px" width="12px" />
												</RouterLink>
											) : null}
										</NoticeItemContent>
										{!n.seen && (
											<IconButton
												onClick={() => setNoticeSeen({id: n.id, showLoading: true})}
												title={intl.formatMessage({id: 'Mark as read'})}
												disabled={personalNoticesProcessing}
											>
												<Icon name="noticeSuccess" width="18px" height="18px" />
											</IconButton>
										)}
									</NoticeItem>
								);
							})}
						</NoticeList>
					)}
					{loadingPersonalNotices ? (
						<ContentLoader />
					) : currentPage < totalPages ? (
						<ShowMoreButton onClick={() => getMoreNotices(currentPage + 1)}>
							<FormattedMessage id="Show more" />
						</ShowMoreButton>
					) : null}
				</NoticesWrapper>
			</NoticesContainer>
		);
	},
);

const NoticesButtonWrapper = styled.button`
	${buttonUnstyled};
	display: flex;
	width: 100%;
	padding: 16px 16px;
	color: ${textColorBase};
	font-size: ${fontSizeBase};
	font-weight: ${fontWeightMedium};
	&:hover,
	&:active,
	&:focus {
		color: ${linkColor};
	}
`;

export const NoticesButton = ({toggleNotices, unseenNoticesCount}) => {
	return (
		<NoticesButtonWrapper onClick={() => toggleNotices()}>
			<FormattedMessage id="Notifications" />
			<NoticeNotification length={unseenNoticesCount} marginLeft />
		</NoticesButtonWrapper>
	);
};
