import services from 'services';
import {prop, map, pipe, path} from 'ramda';
import * as normalize from 'utils/normalize';
import {describeThrow, describeError} from 'utils/errors';
import {getResponseData} from 'utils/app';
import msgs from 'dicts/messages';
import {dashboardCallReminderInclude, freeCalendarResourceInclude} from 'constants/data';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const describeContentThrow = e =>
	describeThrow(intl.formatMessage({id: msgs.contentFetchFailed}), e);

export const getUserStats = ({userId}) =>
	httpJson('get', '/statistics', {users: JSON.stringify([{id: userId}])}, {})
		.catch(describeContentThrow)
		.then(normalize.userStatsCollection);

export const getPersonalTargets = ({type}) =>
	httpJson('get', '/targets/forUser', {type})
		.catch(describeContentThrow)
		.then(prop('data'));

export const getDashboardCallReminders = ({userId}) =>
	httpJson('get', '/callReminders', {
		userId,
		include: dashboardCallReminderInclude,
		_limit: 999,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.callReminder)));

export const getUserTeams = ({includeTeamUsers = false}) =>
	httpJson(
		'get',
		'/users/me',
		includeTeamUsers ? {include: 'teams,teams.users'} : {include: 'teams'},
	)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(pipe(path(['teams', 'data']), map(normalize.team))));

// for encounter forms. if teamId passed, query by team
export const getFreeCalendarResources = (buildingId, teamId) => {
	return httpJson('get', '/calendarResource/free', {
		include: freeCalendarResourceInclude,
		buildingId,
		...(teamId ? {teamId} : {}),
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.calendarResource)));
};

// for encounter forms
export const getBuildingSalesTeams = buildingId =>
	httpJson('get', '/teams/salesTeams', {buildingId})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.team)));

export const createEncounterFormFill = formFill =>
	httpJson('post', '/formFills', {}, {body: formFill})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.formFill));

export const createEncounterCalendarResource = resource =>
	httpJson('post', '/calendarResource', {}, {body: resource})
		.catch(e => {
			const conflict = !!e.response && e.response.status === 409;
			e.causedByCalendarResourceConflict = conflict;
			return conflict
				? e.response.json().then(body => describeThrow(body.message, e))
				: Promise.reject(describeError(msgs.saveFailed, e));
		})
		.then(getResponseData(normalize.calendarResource));

export const getAllProducts = () =>
	httpJson('get', '/products/all', {_limit: 999, include: 'organizations'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.product)));
