import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import * as commonSelectors from 'modules/common/selectors';
import {
	calendarShowTeamUsers,
	calendarShowOrganizationSalesmen,
	calendarShowOrganizationTelemen,
} from 'utils/perms';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

/**
 * Permissions to view other users calendars and salesman visits
 */

export const showTeamUsers = createSelector(commonSelectors.user, user =>
	calendarShowTeamUsers(user),
);

export const showOrganizationSalesmen = createSelector(commonSelectors.user, user =>
	calendarShowOrganizationSalesmen(user),
);

export const showOrganizationTelemen = createSelector(commonSelectors.user, user =>
	calendarShowOrganizationTelemen(user),
);

export const canSelectUser = createSelector(
	[showTeamUsers, showOrganizationSalesmen, showOrganizationTelemen],
	(showTeamUsers, showOrganizationSalesmen, showOrganizationTelemen) =>
		showTeamUsers || showOrganizationSalesmen || showOrganizationTelemen,
);
