import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.handleFeedback.type: {
			return [{...state, processing: true}, effects.handleFeedback(payload)];
		}

		case actions.changePage.type: {
			const newState = {
				...state,
				feedbackLoading: true,
				feedbackQuery: {...state.feedbackQuery, _page: payload},
			};

			return [newState, effects.updateFeedback()];
		}

		case actions._setFeedback.type: {
			const newState = {
				...state,
				feedback: payload.data,
				feedbackPagination: payload.pagination,
				feedbackLoading: false,
			};

			return [newState, null];
		}

		case actions._updateFeedback.type: {
			const newState = {
				...state,
				processing: false,
				feedback: state.feedback.map(f => (f.id === payload.id ? {...f, ...payload} : f)),
			};

			return [newState, null];
		}

		case actions._updateFeedbackQuery.type: {
			const newState = {
				...state,
				feedbackLoading: true,
				feedbackQuery: {...state.feedbackQuery, ...payload},
			};

			return [newState, null];
		}

		case actions._setProcessing.type: {
			return [{...state, processing: payload}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
