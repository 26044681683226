export const leadFormInitialValues = {
	buildingNotFound: false,
	clientNotFound: false,
	building: null,
	address: '',
	zip: '',
	city: '',
	clientId: '',
	clientFirstName: '',
	clientLastName: '',
	phone: '',
	products: [],
	comment: '',
	type: 'socialMediaLead',
	marketingAuthorization: null,
	marketingChannelId: null,
	marketingCategoryId: null,
	marketingCustomerTypeId: null,
	marketingSourceId: null,
	salesmanagerId: null,
	handlerComment: '',
};

export const salesmanagersQuery = {
	_limit: 999,
	type: 'salesmanager',
	include: 'organizations',
};

export const leadCalendarResourceIncludes = [
	'team',
	'salesmanVisit',
	'salesmanVisit.products',
	'salesmanVisit.user',
	'organization',
];

const leadCalendarResourceIncludeStr = leadCalendarResourceIncludes
	.map(x => `calendarResource.${x}`)
	.join(',');

export const leadInclude = `user,building,client,building.clients,salesmanager.organizations,marketingChannel,marketingSource,marketingCustomerType,marketingCategory,products,tags,${leadCalendarResourceIncludeStr}`;
