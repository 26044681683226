import services from 'services';
import {map, pipe, prop} from 'ramda';
import {describeThrow, describeError} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {
	buildingInclude,
	encountersInclude,
	encounterInclude,
	callSurveyId,
	callReviewSurveyId,
} from './constants';
import {getResponseData} from 'utils/app';
import {
	createEncounterFormFill,
	getFreeCalendarResources,
	getUserTeams,
	getBuildingSalesTeams,
	createEncounterCalendarResource,
	getAllProducts,
} from 'io/data';
import {describeBuildingGetError} from 'utils/data';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export {getUserTeams, getBuildingSalesTeams, getFreeCalendarResources, getAllProducts};

export const getBuilding = buildingId =>
	httpJson('get', `/buildings/${buildingId}`, {include: buildingInclude})
		.catch(e => {
			throw describeBuildingGetError(intl, e);
		})
		.then(getResponseData(normalize.building));

export const getEncounters = buildingId =>
	httpJson('get', `/encounters`, {
		buildingId: buildingId,
		include: encountersInclude,
		_limit: '999',
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.encounter)));

export const getEncounter = encounterId =>
	httpJson('get', `/encounters/${encounterId}`, {include: encounterInclude})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(normalize.encounter));

export const postClient = (client, buildingId) =>
	httpJson('post', '/clients', {}, {body: {buildingId, ...client}})
		.catch(e => {
			const conflict = !!e.response && e.response.status === 409;
			e.causedByMarketingPrevented = conflict;
			return conflict
				? e.response.json().then(body => describeThrow(body.message, e))
				: Promise.reject(describeError(msgs.saveFailed, e));
		})
		.then(getResponseData(normalize.client));

export const updateClient = client =>
	httpJson('put', `/clients/${client.id}`, {}, {body: client})
		.catch(e => {
			const conflict = !!e.response && e.response.status === 409;
			e.causedByMarketingPrevented = conflict;
			return conflict
				? e.response.json().then(body => describeThrow(body.message, e))
				: Promise.reject(describeError(msgs.saveFailed, e));
		})
		.then(getResponseData(normalize.client));

export const deleteClient = ({id, reason}) =>
	httpJson('delete', `/clients/${id}`, {deletedReason: reason}).catch(
		describeThrow(intl.formatMessage({id: msgs.deleteFailed})),
	);

export const skipBuilding = bid =>
	httpJson('get', `/buildings/${bid}/skip`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to skip building, please try again'})),
	);

export const postCalendarResource = createEncounterCalendarResource;

export const postFormFill = createEncounterFormFill;

// NOTE: no normalization
export const postCall = call =>
	httpJson('post', '/calls', {}, {body: call}).catch(e => {
		const dateTaken = !!e.response && e.response.status === 409;
		e.causedByDateTaken = dateTaken;
		const errMsgKey = dateTaken
			? 'The appointment time you selected is no longer available - please try again'
			: msgs.saveFailed;
		throw describeError(intl.formatMessage({id: errMsgKey}), e);
	});

export const getCallSurvey = buildingId =>
	httpJson('get', `/surveys/${callSurveyId}`, {
		buildingId,
		include: 'surveyQuestions.product',
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load survey'})))
		.then(getResponseData(normalize.survey));

export const getSurveyCallReview = () =>
	httpJson('get', `/surveys/${callReviewSurveyId}`, {
		include: 'surveyQuestions',
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load survey'})))
		.then(getResponseData(normalize.survey));

export const postSurveyFill = survey =>
	httpJson('post', `/surveyFills`, {}, {body: survey})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save survey'})))
		.then(getResponseData(normalize.surveyFill));

export const getLead = id =>
	httpJson('get', `/leads/${id}`)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), normalize.lead));

export const getMarketingLeadSources = _ =>
	httpJson('get', `/marketingSources`, {}, {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));
