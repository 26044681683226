import {checkboxValsToIdObjectList, objectListToCheckboxVals} from 'utils/data';

export const formatSettingsFormOutput = ({form}) => {
	const settings = {
		teams: checkboxValsToIdObjectList(form.teams),
		timeSpan: form.timeSpan,
		interval: form.interval,
		topTeams: form.topTeams,
	};

	return settings;
};

export const formatSettingsFormInput = ({teams, settings}) => {
	const newSettings =
		settings.teams.length > 0
			? {
					teams: objectListToCheckboxVals(teams, settings.teams),
					topTeams: settings.topTeams,
					timeSpan: settings.timeSpan,
					interval: settings.interval,
			  }
			: {
					teams: objectListToCheckboxVals(teams, teams),
					topTeams: false,
					timeSpan: 'none',
					interval: 20,
			  };

	return newSettings;
};
