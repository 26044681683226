import {FormattedMessage, FormattedNumber} from '@meiko/react-intl';
import PanelHeaderTitle from 'components/generic/ModalHeaderTitle';
import Panel from 'components/generic/Panel';
import Table from 'components/generic/Table';
import {compose, sortBy} from 'ramda';
import React from 'react';

const TopList = ({data = []}) => {
	const toplist = sortBy(
		compose(a => {
			return -Number(a.sum);
		}),
		data,
	);
	return (
		<Panel
			header={
				<PanelHeaderTitle textAlign="center">
					<FormattedMessage id="Top list" />
				</PanelHeaderTitle>
			}
		>
			<Table>
				<thead>
					<tr>
						<th></th>
						<th>
							<FormattedMessage id="Name" />
						</th>
						<th>€</th>
					</tr>
				</thead>
				<tbody>
					{toplist.map((row, i) => (
						<tr key={i}>
							<td>{i + 1}</td>
							<td>{row.user}</td>
							<td>
								{/* eslint-disable-next-line */}
								<FormattedNumber value={row.sum} style="currency" currency="EUR" />
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</Panel>
	);
};
export default TopList;
