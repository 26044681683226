import React from 'react';

const SvgNoticeError = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M5.992 11.999a6.103 6.103 0 0 1-4.255-1.827A5.9 5.9 0 0 1 .001 5.896 5.889 5.889 0 0 1 5.9 0h.106A6.055 6.055 0 0 1 12 6.104a5.882 5.882 0 0 1-6.008 5.895zm3.004-3.745L6.743 6l2.253-2.254a.525.525 0 1 0-.743-.742L6 5.257 3.746 3.004a.525.525 0 0 0-.742.742L5.257 6 3.004 8.254a.525.525 0 0 0 .742.742L6 6.742l2.253 2.254a.525.525 0 1 0 .743-.742z"
		/>
	</svg>
);

export default SvgNoticeError;
