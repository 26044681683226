import {state as lifecycle} from 'fragments/lifecycle';
import {localeCenterWeb, localeZoomFactor} from 'constants/maps';

export default {
	...lifecycle,
	mapQuery: {
		z: localeZoomFactor * 5,
		x: localeCenterWeb[0],
		y: localeCenterWeb[1],
	},
	addAreaModalOpen: false,
	selectedArea: null,
	processing: false,
	teams: [],
	teamsLoading: true,
	team: {},
};
