import React from 'react';

const SvgArrowLeft = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M11.274 5.28H2.718a.125.125 0 0 1-.082-.219l4.319-3.8a.72.72 0 0 0-.95-1.08L.413 5.1a1.2 1.2 0 0 0 0 1.8l5.59 4.918a.72.72 0 0 0 .95-1.08l-4.319-3.8a.125.125 0 0 1 .082-.219h8.557a.72.72 0 0 0 0-1.44v.001z"
		/>
	</svg>
);

export default SvgArrowLeft;
