import {getMonth, getYear} from 'date-fns';
import services from 'services';
import {catchNonFatalDefault} from 'io/errors';
import {effect} from 'utils/redux';
import namespace from './namespace';
import * as actions from './actions';
import * as selectors from './selectors';
import {
	getDashboardTargets as getDashboardTargetsIo,
	createDashboardTarget as createDashboardTargetIo,
} from './io';
import {decorateWithNotifications} from 'io/app';
import {getQuery, pushQuery} from 'io/history';
import messages from 'dicts/messages';
import {decodeUserIdToFormInput, parseDate, parseUrlQuery} from './utils';
import {getTeams} from '../common/io';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

export let initialize = () => (getState, dispatch) => {
	const query = parseUrlQuery(getQuery());

	dispatch(actions.setQuery(query));
	dispatch(actions._initialize());

	getTeams({
		_limit: 999,
		include: 'users',
	}).then(teams => {
		dispatch(actions._setAvailableTeams(teams));
	});
};
initialize = creator('initialize', initialize);

export let getDashboardTargets = () => (getState, dispatch) => {
	const query = selectors.dashboardTargetsQuery(getState());
	const {teamId} = query;
	const date = parseDate(query.date);
	const year = getYear(date);
	const month = getMonth(date) + 1; // 0-indexed

	getDashboardTargetsIo({teamId, timeSpan: 'month', year, month})
		.then(({data: dashboardTargets}) => {
			dispatch(actions._getDashboardTargets(dashboardTargets));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
getDashboardTargets = creator('getDashboardTargets', getDashboardTargets);

export let createDashboardTarget = payload => (getState, dispatch) => {
	const selectedDate = selectors.dashboardTargetsQuery(getState()).date;
	const date = parseDate(selectedDate);
	const year = getYear(date);
	const month = getMonth(date) + 1; // 0-indexed

	const values = Object.keys(payload).reduce((acc, key) => {
		const userId = Number(decodeUserIdToFormInput(key));
		const teamId = selectors.activeTeam(getState()).id;
		const targets = payload[key];

		const updates = Object.keys(targets).map(targetKey => ({
			userId,
			teamId,
			type: targetKey,
			timespan: 'month',
			year,
			month,
			target: targets[targetKey],
		}));
		return [...acc, ...updates];
	}, []);
	decorateWithNotifications(
		{
			id: 'create-dashboardTarget',
			loading: intl.formatMessage({id: messages.processing}),
		},
		// TODO: This is not really efficient at all, we generate 3x queries per user
		Promise.all(values.map(v => createDashboardTargetIo(v))),
	)(getState, dispatch)
		.then(dashboardTarget => {
			dispatch(actions._getDashboardTargets(dashboardTarget));
			dispatch(actions._createDashboardTarget());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
createDashboardTarget = creator('createDashboardTarget', createDashboardTarget);

export let setQuery = () => (getState, dispatch) => {
	const query = selectors.dashboardTargetsQuery(getState());
	pushQuery(_q => query);
	dispatch(actions.getDashboardTargets());
};
setQuery = creator('setQuery', setQuery);
