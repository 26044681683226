import {
	initialize as reduxFormInit,
	destroy,
	formValueSelector,
	change,
} from 'redux-form';
import {effect} from 'utils/redux';
import namespace from './namespace';
import * as actions from './actions';
import {
	getUsers,
	getUserTeams,
	getTimeEntries,
	postTimeEntry,
	removeEntry,
	updateEntry,
} from './io';
import {getQuery, pushQuery} from 'io/history';
import * as selectors from './selectors';
import * as rootSelectors from 'modules/common/selectors';
import {decorateWithNotifications} from 'io/app';
import services from 'services';
import msgs from 'dicts/messages';
import {catchNonFatalDefault} from 'io/errors';
import * as confirmerActions from 'modules/confirmer/actions';
import {parseUrlQuery} from './utils';
import {mergeLeft} from 'ramda';

const creator = effect(namespace);

const history = services.get('history');

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const timeEntriesSearchFormVal = formValueSelector('timeEntriesSearch');

const _fetchTimeEntries = ({notifyOpts = {}}) => (getState, dispatch) => {
	const selectedUser = selectors.selectedUser(getState());
	const userId = selectedUser ? selectedUser.id : null;
	const {start, end} = timeEntriesSearchFormVal(getState(), 'start', 'end');

	return decorateWithNotifications(
		{id: 'fetch-time-entries', failureStyle: 'error', ...notifyOpts},
		getTimeEntries({userId, start, end}),
	)(getState, dispatch).then(entries => {
		dispatch(actions._setTimeEntries(entries));
	});
};

export let initialize = () => (getState, dispatch) => {
	if (rootSelectors.isSubcontractorUser(getState())) {
		history.push('/');
		return;
	}

	const isTeamLeader = selectors.isTeamLeader(getState());
	const hasTimeEntriesCrudPerm = selectors.userHasTimeEntriesCrud(getState());
	const organizationId = rootSelectors.activeOrganizationId(getState());

	const {timeEntriesQuery} = parseUrlQuery(getQuery());
	const {start, end, userId} = timeEntriesQuery;

	const queryUserId =
		(hasTimeEntriesCrudPerm || isTeamLeader) && userId ? Number(userId) : null;

	dispatch(actions._updateTimeEntriesQuery({...timeEntriesQuery, userId: queryUserId}));
	dispatch(actions._setSelectedUserId(queryUserId));

	dispatch(reduxFormInit('timeEntriesSearch', {start, end}));
	dispatch(reduxFormInit('userSelectForm', {selectedUser: queryUserId}));

	pushQuery(mergeLeft({start, end, userId: queryUserId})); // fill the query if its empty

	decorateWithNotifications(
		{id: 'init-time-entries', failureStyle: 'error'},
		Promise.all([
			getTimeEntries({start, end, userId: queryUserId}).then(entries =>
				dispatch(actions._setTimeEntries(entries)),
			),
			// fetch all users in active organization for admin, team's users for teamLeader, none for others
			// prettier-ignore
			hasTimeEntriesCrudPerm ? getUsers({organizationId}).then(users => dispatch(actions._setUsers(users)))
			: isTeamLeader ? getUserTeams().then(userTeams => dispatch(actions._setUsers(userTeams[0].users)))
			: Promise.resolve(null),
		]),
	)(getState, dispatch)
		.then(() => {
			dispatch(actions._initialize());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let destroyReduxForm = formName => (getState, dispatch) => {
	dispatch(destroy(formName));
};
destroyReduxForm = creator('destroyReduxForm', destroyReduxForm);

export let fetchTimeEntries = () => (getState, dispatch) => {
	const timeEntriesQuery = selectors.timeEntriesQuery(getState());
	pushQuery(mergeLeft(timeEntriesQuery));
	_fetchTimeEntries({})(getState, dispatch).catch(
		catchNonFatalDefault(getState, dispatch),
	);
};
fetchTimeEntries = creator('fetchTimeEntries', fetchTimeEntries);

export let createTimeEntry = entry => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'create-time-entry',
			failureStyle: 'error',
			success: intl.formatMessage({id: 'Saved'}),
			loading: intl.formatMessage({id: msgs.processing}),
		},
		postTimeEntry(entry),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(() => {
			dispatch(actions._timeEntryCreated());
			return _fetchTimeEntries({
				notifyOpts: {loading: msgs.loading, failureStyle: 'warning'},
			})(getState, dispatch);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
createTimeEntry = creator('createTimeEntry', createTimeEntry);

export let deleteTimeEntry = id => (getState, dispatch) => {
	const onConfirm = () => {
		dispatch(actions._startOp());
		decorateWithNotifications(
			{
				id: 'delete-time-entry',
				failureStyle: 'error',
				success: intl.formatMessage({id: 'Working hours removed'}),
				loading: intl.formatMessage({id: msgs.processing}),
			},
			removeEntry(id),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._timeEntryDeleted());
				return _fetchTimeEntries({
					notifyOpts: {loading: msgs.loading, failureStyle: 'warning'},
				})(getState, dispatch);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete work time entry?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
deleteTimeEntry = creator('deleteTimeEntry', deleteTimeEntry);

export let updateTimeEntry = entry => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'update-time-entry',
			failureStyle: 'error',
			success: intl.formatMessage({id: 'Working hours updated'}),
			loading: intl.formatMessage({id: msgs.processing}),
		},
		updateEntry(entry),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(res => {
			dispatch(actions._timeEntryUpdated(res));
			return _fetchTimeEntries({
				notifyOpts: {loading: msgs.loading, failureStyle: 'warning'},
			})(getState, dispatch);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
updateTimeEntry = creator('updateTimeEntry', updateTimeEntry);

const fetchDayInfo = date => (getState, dispatch) => {
	const selectedUser = selectors.selectedUser(getState());
	const userId = selectedUser ? selectedUser.id : null;

	return decorateWithNotifications(
		{
			id: 'fetch-day-info',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.loading}),
		},
		getTimeEntries({
			start: date,
			end: date,
			groupByDay: true,
			getOnlyDayInfo: true,
			userId,
		}),
	)(getState, dispatch)
		.then(entry => {
			const {perDiem, kmStart, kmEnd, kmTotal} = entry;
			dispatch(change('timeEntryForm', 'perDiem', perDiem));
			dispatch(change('timeEntryForm', 'kmStart', kmStart));
			dispatch(change('timeEntryForm', 'kmEnd', kmEnd));
			dispatch(change('timeEntryForm', 'kmTotal', kmTotal));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};

export let openTimeEntriesCreator = initialDate => (getState, dispatch) => {
	const date = initialDate || new Date().toISOString().split('T')[0];
	dispatch(
		reduxFormInit('timeEntryForm', {
			type: 'working',
			date,
			organizationId: rootSelectors.activeOrganizationId(getState()),
		}),
	);
	// if date already has time entry, get day info values from that
	fetchDayInfo(date)(getState, dispatch);
};
openTimeEntriesCreator = creator('openTimeEntriesCreator', openTimeEntriesCreator);

export let getDayInfo = date => (getState, dispatch) => {
	fetchDayInfo(date)(getState, dispatch);
};
getDayInfo = creator('getDayInfo', getDayInfo);
