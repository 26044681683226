import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';

const root = path(namespace);

export const reasonMappings = pipe(root, prop('reasonMappings'));

export const reasonMappingsPagination = pipe(root, prop('reasonMappingsPagination'));

export const reasonMappingsLoading = pipe(root, prop('reasonMappingsLoading'));

export const isReasonMappingModalOpen = pipe(root, prop('isReasonMappingModalOpen'));

export const activeReasonMapping = pipe(root, prop('activeReasonMapping'));

export const reasonMappingsQuery = pipe(root, prop('reasonMappingsQuery'));

export const showDeleted = createSelector([reasonMappingsQuery], q => {
	return q.trashed;
});

export const availableTags = pipe(root, prop('availableTags'));
