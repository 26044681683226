import {objectListToCheckboxVals, checkboxValsToIdObjectList} from 'utils/data';
import {evolve} from 'ramda';
import {over} from 'utils/lenses';
import {replace} from 'utils/objects';
import initState from './state';
import {sortByTitle as sortAreas} from 'utils/areas';

export const normalizeTeam = over(['areas'], sortAreas);

export const formatTeamFormInput = ({team, organizations}) => {
	return {
		...normalizeTeam(team),
		additionalCalendarOrganizations: objectListToCheckboxVals(
			organizations,
			team.additionalCalendarOrganizations,
		),
	};
};

export const formatTeamFormOutput = ({form}) => {
	const team = {
		...form,
		additionalCalendarOrganizations: checkboxValsToIdObjectList(
			form.additionalCalendarOrganizations,
		),
	};

	return {team};
};

export const parseUrlQuery = query => {
	const vals = evolve({_page: Number}, query);
	return {teamsQuery: replace(vals, initState.teamsQuery)};
};

export const formatUrlQuery = ({teamsQuery}) => teamsQuery;

export const getTeamArea = ({team, areaId}) =>
	team ? team.areas.find(a => a.id === areaId) : null;
