import {apiUrl} from 'constants/app';

export default (apiToken, apiKey) => {
	return new window.Pusher(apiKey, {
		cluster: 'eu',
		encrypted: true,
		authEndpoint: `${apiUrl}/pusherAuth`,
		auth: {headers: {Authorization: `Bearer ${apiToken}`}},
	});
};
