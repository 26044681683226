import {map} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import {getResponseData} from 'utils/app';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getCategories = () =>
	httpJson('get', '/fileCategories', {include: 'files.user,roles'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.category)));

export const getRoles = query =>
	httpJson('get', '/roles', query)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.roles)));

export const postCategory = category =>
	httpJson('post', '/fileCategories', {}, {body: category})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.category));

export const putCategory = category =>
	httpJson('put', `/fileCategories/${category.id}`, {}, {body: category})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.category));

export const deleteCategory = id =>
	httpJson('delete', `/fileCategories/${id}`).catch(
		describeThrow(intl.formatMessage({id: msgs.deleteFailed})),
	);

export const deleteFile = id =>
	httpJson('delete', `/files/${id}`).catch(
		describeThrow(intl.formatMessage({id: msgs.deleteFailed})),
	);

export const getOrganizations = () =>
	httpJson('get', '/organizations', {customerOrganizations: false})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.organization)));
