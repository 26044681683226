import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';

const root = path(namespace);

export const tags = pipe(root, prop('tags'));

export const tagsPagination = pipe(root, prop('tagsPagination'));

export const tagsLoading = pipe(root, prop('tagsLoading'));

export const isTagModalOpen = pipe(root, prop('isTagModalOpen'));

export const activeTag = pipe(root, prop('activeTag'));

export const tagsQuery = pipe(root, prop('tagsQuery'));

export const showDeleted = createSelector([tagsQuery], q => {
	return q.trashed;
});
