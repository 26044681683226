import {ihSource} from 'dicts/calendarResources';

export const defaultOrganizationId = 1;

export const appointmentsHourRange = [8, 20];

export const clientDeleteReasons = [
	{value: 'underage', label: 'Minor'},
	{value: 'overage', label: 'Too old'},
	{value: 'wrongContactInfo', label: 'Invalid contact info'},
	{value: 'noAuthority', label: 'Does not decide about house matters'},
	{value: 'requestedDelete', label: 'Requested data to be deleted'},
	{value: 'deceased', label: 'Deceased'},
];

export const ihSourceOpts = Object.keys(ihSource).map(key => ({
	value: key,
	label: ihSource[key],
}));
