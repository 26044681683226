import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const changePage = creator('changePage');

export const recheckQuery = creator('recheckQuery');

export const searchLeads = creator('searchLeads', P.Object);

export const clearQuery = creator('clearQuery');

export const updateLeadsQuery = creator('updateLeadsQuery', P.Object);

export const _initialize = creator('_initialize');

export const _setLeads = creator('_setLeads');

export const _updateLeadsQuery = creator('_updateLeadsQuery');

export const _setProcessing = creator('_setProcessing');

export const _setSources = creator('_setSources');

export const _setProducts = creator('_setProducts');

export const _setOrganizations = creator('_setOrganizations');

export const _setUtmOptions = creator('_setUtmOptions');

export const _setSalesTeams = creator('_setSalesTeams');

export const _setAreas = creator('_setAreas');

export const _setTags = creator('_setTags');
