import {effect} from 'utils/redux';
import {catchNonFatalDefault} from 'io/errors';
import namespace from './namespace';
import {decorateWithNotifications} from 'io/app';
import {
	getImportHistory as getImportHistoryIo,
	saveCsvImport as saveCsvImportIo,
} from './io';
import {importHistoryQuery} from './selectors';
import * as actions from './actions';
import * as normalize from 'utils/normalize';
import services from 'services';
import {encodeQuery} from 'utils/url';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const history = services.get('history');

export let initialize = () => (getState, dispatch) => {};
initialize = creator('initialize', initialize);

export let _getImportHistory = () => (getState, dispatch) => {
	const query = importHistoryQuery(getState());
	decorateWithNotifications(
		{
			id: 'get-imports',
			failureStyle: 'warning',
		},
		getImportHistoryIo(query),
	)(getState, dispatch)
		.catch(catchNonFatalDefault(getState, dispatch))
		.then(({data, meta: {pagination}}) => {
			dispatch(actions._setImportHistory({data: normalize.csvImport(data), pagination}));
		});
};
_getImportHistory = creator('_getImportHistory', _getImportHistory);

/**
 * Save CSV import and navigate to the newly created imports page
 */
export let saveCsvImport = payload => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'save-csv-import',
			failureStyle: 'error',
			success: intl.formatMessage({id: 'Saved'}),
			loading: intl.formatMessage({id: 'Saving'}),
		},
		saveCsvImportIo(payload),
	)(getState, dispatch)
		.catch(catchNonFatalDefault(getState, dispatch))
		.then(data => {
			if (data) {
				dispatch(actions.saveCsvImportSuccess());
				history.push(
					`/buildings/csv-imports/${data.id}${encodeQuery({
						referrerUrl: '/buildings/csv-imports',
					})}`,
				);
			}
		});
};
saveCsvImport = creator('saveCsvImport', saveCsvImport);
