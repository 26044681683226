import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize', P.Number);

export const _initialize = creator('_initialize');

export const destroy = creator('destroy');

export const openEditModal = creator('openEditModal');

export const closeEditModal = creator('closeEditModal');

export const startProcessing = creator('startProcessing', P.Number);

export const _startProcessing = creator('_startProcessing');

export const removeCsvImport = creator('removeCsvImport', P.Number);

export const _removeCsvImport = creator('_removeCsvImport');

export const cancelCsvImport = creator('cancelCsvImport', P.Number);

export const _cancelCsvImport = creator('_cancelCsvImport', P.Object);

export const deleteImportedClients = creator('deleteImportedClients', P.Number);

export const _deleteImportedClients = creator('_deleteImportedClients', P.Object);
