import {dissoc} from 'ramda';
import {replace} from 'utils/objects';
import initState from './state';

export const parseUrlQuery = query => {
	const parsed = replace(query, initState.logsQuery);
	return dissoc('_page', parsed);
};

export const formatUrlQuery = query => {
	return {type: query.type};
};

export const formatFetchableQuery = query => {
	const {type, ...fetchableQuery} = query;

	if (type === 'newLeads') {
		fetchableQuery.newLeads = true;
	} else if (type === 'stateChanged') {
		fetchableQuery.stateChanged = true;
	} else if (type === 'deletedLeads') {
		fetchableQuery.deletedLeads = true;
	}

	return fetchableQuery;
};
