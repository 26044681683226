import {action} from 'utils/redux';
import {P, Nullable} from 'utils/types';
import namespace from './namespace';
import createDatePickActions from 'fragments/calendarResourcePicker/actions';
import createBuildingModalActions from 'fragments/buildingModalActions/actions';
import createReminderActions from 'fragments/callReminder/actions';

const creator = action(namespace);

const reminderActions = createReminderActions({namespace});

export const {
	createCallReminder,
	createCalendarCallReminder,
	updateCallReminder,
	removeCallReminder,
	toggleCallReminderEditor,
	setReminderSeen,
	_setCalendarCallReminderSeen,
	_setCallReminder,
	_setCallReminderLoading,
	_setCallReminders,
	_setCallRemindersLoading,
	_setCalendarCallReminder,
} = reminderActions;

export const initialize = creator('initialize', P.Number);

export const destroy = creator('destroy');

export const changeBuilding = creator('changeBuilding', P.Number);

export const resetVisitForm = creator('resetVisitForm');

export const saveVisit = creator('saveVisit');

export const setClientSwedish = creator('setClientSwedish', P.Object);

export const notHome = creator('notHome');

export const toggleSalesVisitModal = creator('toggleSalesVisitModal');

export const _setMarketingLeadSources = creator('_setMarketingLeadSources');

export const saveSalesVisit = creator('saveSalesVisit');

export const _setBuilding = creator('_setBuilding');

export const _setEncounters = creator('_setEncounters', P.Array);

export const _startOp = creator('_startOp');

export const _opOk = creator('_opOk');

export const _opFailed = creator('_opFailed');

export const _setBuildingLoading = creator('_setBuildingLoading');

export const _setEncountersLoading = creator('_setEncountersLoading');

export const _setFreeCalResLoading = creator('_setFreeCalResLoading');

export const _visitSaved = creator('_visitSaved');

export const _visitSaveFailed = creator('_visitSaveFailed');

export const _setProducts = creator('_setProducts', P.Array);

// calendar
const datePickActions = createDatePickActions({namespace});

export const {
	selectCalendarResource,
	_calendarResourceSelected,
	_calendarResourceSelectionFailed,
	_calendarResourceReserved,
	_calendarResourceReleased,
} = datePickActions;

export const toggleNewDateCreator = creator('toggleNewDateCreator');

export const _setFreeCalRes = creator('_setFreeCalRes');

export const _setUserTeams = creator('_setUserTeams');

// encounter modal
export const openEncounterModal = creator('openEncounterModal', P.Number);

export const closeEncounterModal = creator('closeEncounterModal');

export const _setEncounterData = creator('_setEncounterData', P.Object);

// client modal
export const saveClient = creator('saveClient');

export const removeClient = creator('removeClient');

export const toggleClientEditor = creator('toggleClientEditor', Nullable(P.Object));

export const _updateClients = creator('_updateClients');

export const _setSalesTeams = creator('_setSalesTeams');

export const _setOpenedAt = creator('_setOpenedAt', P.Date);

// building modal
const buildingModalActions = createBuildingModalActions({namespace});

export const {
	toggleBuildingModal,
	createOtherAddress,
	setPrimaryAddress,
	removeOtherAddress,
	saveBuildingData,
	removeBuilding,
	_updateBuilding,
	_setBuildingModalProcessing,
} = buildingModalActions;

export const _setAvailableTags = creator('_setAvailableTags', P.Array);

export const getTags = creator('getTags', P.Number);

export const _getTags = creator('_getTags', P.Array);

export const attachTag = creator('attachTag', P.Object);

export const _attachTag = creator('_attachTag');

export const detachTag = creator('detachTag', P.Object);

export const _detachTag = creator('_detachTag');
