import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	initialized: false,
	leads: [],
	leadsLoading: true,
	leadsPagination: {},
	leadsQuery: {
		_page: 1,
		salesmanagerLeads: false,
	},
	leadToDelete: null,
};
