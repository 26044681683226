import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

const Inline = styled.span`
	position: relative;
	display: inline-block;
`;

const Block = styled.div`
	position: relative;
	${/*prettier-ignore*/ ({flex}) => flex ? `
		display: flex;
		align-items: center;
	` : ''};
`;

const FormField = ({block, ...rest}) =>
	block ? <Block {...rest} /> : <Inline {...rest} />;

FormField.propTypes = {
	block: propTypes.bool,
};

export default FormField;
