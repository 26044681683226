import {action} from 'utils/redux';
import {P, Maybe} from 'utils/types';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');

export const login = creator('login');

export const destroy = creator('destroy');

export const _gotRedirect = creator('gotRedirect', Maybe(P.String));

export const _loginOk = creator('_loginOk');

export const _loginFailed = creator('_loginFailed');

export const loginWithAd = creator('loginWithAd');

export const _setLoading = creator('_setLoading');
