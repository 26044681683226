import React from 'react';

const SvgSubtract = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M.75 6.75h10.5a.75.75 0 1 0 0-1.5H.75a.75.75 0 0 0 0 1.5z"
		/>
	</svg>
);

export default SvgSubtract;
