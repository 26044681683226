import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const _setStats = creator('_setStats', P.Object);

export const _setTargets = creator('_setTargets', P.Object);

export const _setCallReminders = creator('_setCallReminders', P.Array);

export const _startOp = creator('_startOp');

export const _opOk = creator('_opOk');

export const _opFailed = creator('_opFailed');

export const startCalling = creator('startCalling', P.Number);

export const openTimeEntryModal = creator('openTimeEntryModal', P.Object);

export const closeTimeEntryModal = creator('closeTimeEntryModal');

export const saveTimeEntry = creator('saveTimeEntry');

export const deleteTimeEntry = creator('deleteTimeEntry', P.Number);

export const openTarget = creator('openTarget', P.String);

export const saveCallTarget = creator('saveCallTarget');

export const _updateTargets = creator('_updateTargets');
