import {ascend, path, filter, pipe} from 'ramda';
import {sort, chainComparators} from 'utils/arrays';

export const sortProductStates = sort(
	chainComparators(
		// prettier-ignore
		ascend(s => s.product.title === 'Aurinkopaneelit' ? -2
			: {takeContact: -3, installed: -1}[s.encounterState] || 0,
		),
		ascend(path(['product', 'title'])),
	),
);

export const dropOtherProductStates = filter(s => s.product.type !== 'other');

export const processProductStatesForForms = pipe(
	dropOtherProductStates,
	sortProductStates,
);

export const getProductShortTitle = product => {
	if (!product) return '';

	switch (product.title) {
		case 'Käyttövesiremontti':
			return 'KV';

		case 'Lämpöverkkoremontti':
			return 'LV';

		case 'Lämmitysjärjestelmä IVLP':
			return 'IVLP';

		case 'Viemärihuolto':
			return 'VH';

		case 'Viemärisukitus':
			return 'VM';

		default:
			return product.title;
	}
};
