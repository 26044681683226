import {action} from 'utils/redux';
import {P} from 'utils/types';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');
export const _initialize = creator('_initialize');

export const destroy = creator('destroy');

export const getDashboardTargets = creator('getDashboardTargets');
export const _getDashboardTargets = creator('_getDashboardTargets', P.Array);

export const createDashboardTarget = creator('createDashboardTarget', P.Object);
export const _createDashboardTarget = creator('_createDashboardTarget');

export const setQuery = creator('setQuery');

export const _setAvailableTeams = creator('_setAvailableTeams', P.Array);
