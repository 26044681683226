import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {formatOrganizationTargetFormInput} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const processing = pipe(root, prop('processing'));

export const organizationTargets = pipe(root, prop('organizationTargets'));

export const organizationTargetsLoading = pipe(root, prop('organizationTargetsLoading'));

export const organizationTargetsQuery = pipe(root, prop('organizationTargetsQuery'));

export const urlQuery = createSelector(
	[organizationTargetsQuery],
	organizationTargetsQuery => organizationTargetsQuery,
);

export const selectedDate = createSelector(
	[organizationTargetsQuery],
	organizationTargetsQuery => organizationTargetsQuery.date,
);

export const organizationTargetFormInitValues = createSelector(
	[organizationTargets, selectedDate],
	(organizationTargets, selectedDate) =>
		formatOrganizationTargetFormInput({organizationTargets, date: selectedDate}),
);
