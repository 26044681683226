import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';

export default {
	...lifecycle,
	processing: false,
	loading: false,
	importHistory: [],
	importHistoryPagination: emptyPagination,
	importHistoryQuery: {_page: 1},
	isFileUploadModalOpen: false,
};
