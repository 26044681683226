import {visitFormBase} from './constants';
import {processProductStatesForForms} from 'utils/products';
import {toLocaleDateString, increaseDay} from 'utils/time';
import {getNextDayEncounterSuggestion} from 'utils/encounters';
import {
	formatEncounterFormFillFormOutput,
	formatFormCalendarResourceOutput,
	formatFormBonusCalendarResourceOutput,
} from 'utils/formData';
import {pickIfNot} from 'utils/objects';
import memoize from 'memoize-one';
import {ihSource} from 'dicts/calendarResources';
import {decodeQuery} from 'utils/url';

// Clients form
export const formatClientFormInput = ({client}) => {
	return client;
};
export const formatClientFormOutput = ({form}) => {
	const client = {...form, emailMarketing: form.emailMarketing ? new Date() : null};
	return client;
};

export const processProductStates = processProductStatesForForms;

// NOTE: similar to Calls etc
export const _getVisitStates = state => {
	const visit = state === 'visit';
	const notReached = state === 'notHome';
	const contacted = state === 'contacted';
	const cancelled = state === 'cancelled';
	const reached = !notReached;
	const lost = contacted || cancelled;

	return {visit, notReached, contacted, cancelled, reached, lost};
};
// easy optimization
export const getVisitStates = memoize(_getVisitStates);

// NOTE: similar to Calls
export const formatVisitFormInput = ({userTeams, productStates, openedAt}) => {
	return {
		...visitFormBase,
		calendarResource: {
			...visitFormBase.calendarResource,
			teamId: userTeams.length ? userTeams[0].id : null,
			dateFrom: openedAt ? getNextDayEncounterSuggestion(openedAt) : null,
		},
		bonusCalendarResource: {
			...visitFormBase.bonusCalendarResource,
			dateFrom: openedAt ? toLocaleDateString(increaseDay(openedAt, 1)) : null,
		},
		productStates,
	};
};

export const formatVisitFormOutput = ({form, building, teamId}) => {
	const {
		dateCreatorOpen,
		state,
		isBonus,
		calendarResource,
		bonusCalendarResource,
		contactClientId,
		reason,
		formFill,
		productStates,
		...visitVals
	} = form;

	const ss = getVisitStates(state);

	const visitData = {
		...visitVals,
		contactClientId,
		// "other" is a virtual reason value which really corresponds to null
		reason: reason === 'other' ? null : reason,
		// keep fields if they match criteria, otherwise clear them to base values
		...pickIfNot(
			{
				calendarResourceId: ss.visit && !dateCreatorOpen,
				contactClientId: ss.visit || (ss.contacted && isBonus),
				reason: ss.lost,
				description: ss.reached,
			},
			visitFormBase,
		),
		appointment: ss.visit,
		reached: ss.reached,
		preferredEncounterState:
			// prettier-ignore
			ss.notReached ? 'notHome'
			: ss.contacted ? 'contacted'
			: ss.cancelled ? 'cancelled'
			: null,
	};

	const calendarResourceData =
		// prettier-ignore
		ss.visit && dateCreatorOpen
			? formatFormCalendarResourceOutput({calendarResource, contactClientId})
		: ss.contacted && isBonus
			? formatFormBonusCalendarResourceOutput({bonusCalendarResource, contactClientId, teamId})
		: null;

	const formFillData = ss.visit ? formatEncounterFormFillFormOutput(formFill) : null;

	const buildingData = {
		id: building.id,
		productStates,
	};

	return {
		visit: visitData,
		calendarResource: calendarResourceData,
		formFill: formFillData,
		building: buildingData,
	};
};

export const getDefaultIhSource = ({allEncounters, referrer, referrerUrl}) => {
	if (allEncounters && allEncounters.find(e => e.state === 'installed')) {
		return 'installed';
	} else if (!allEncounters || !allEncounters.length) {
		return 'notContacted';
	} else if (referrer === 'freeride') {
		return 'd2d';
	} else if (referrer === 'salesman-app-contacts' && referrerUrl) {
		// read the selected encounterState (in SalesmanApp search form) from referrerUrl
		const {encounterState} = decodeQuery(referrerUrl);
		const isValidIhSource = encounterState
			? Object.keys(ihSource).includes(encounterState)
			: false;

		return isValidIhSource ? encounterState : null;
	}

	return null;
};
