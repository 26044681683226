import React from 'react';
import ReactDOM from 'react-dom';
import propTypes from 'prop-types';
import {Manager, Reference, Popper} from 'react-popper';
import * as overlayControl from './overlayControl';

// this is basically a more complex and flexible version of "Overlay", using Popper instead of CSS.
// NOTE: the "rootClass" and "overlayClass" constructors must pass a ref to their root node. see react.forwardRef
// note: this works best if the nearest parent with relative positioning isn't inside an element with overflow control. if that happens, use react-popper's "strategy: 'fixed'" property.
// https://css-tricks.com/popping-hidden-overflow/

// btw: would have been better to use use material-ui's tooltip - it supports automatic positioning (unlike react-bootstrap and many others).

/* eslint-disable react/prop-types */
const PopperContainer = ({
	overlayComponentRef,
	popperProps,
	setUpdatePopper,
	OverlayComponent,
	show,
	overlayProps,
	unmountOverlayContentOnHide,
	overlayContent,
}) => (
	<Popper innerRef={overlayComponentRef} {...popperProps}>
		{({ref, update, ...popperData}) => {
			// ugly hack: store the update callback for use later
			setUpdatePopper(update);

			return (
				<OverlayComponent ref={ref} show={show} {...popperData} {...overlayProps}>
					{unmountOverlayContentOnHide && !show
						? null
						: overlayContent || overlayProps.children}
				</OverlayComponent>
			);
		}}
	</Popper>
);

class PopperOverlay extends React.Component {
	state = {...overlayControl.state, rootProps: {}};

	lastShown = false;
	updatePopper = () => {};

	rootComponentRef = React.createRef();
	overlayComponentRef = React.createRef();

	onDocumentClick = null;
	onDocumentKeyUp = e => {
		if (e.key === 'Escape') {
			this.setState({showOverlay: false});
		}
	};

	componentDidMount() {
		// triggers and hoverDelay aren't dynamically adjustable
		const {triggers, hoverDelay} = this.props;

		const {onMouseOver, onMouseOut, onClick, onKeyPress, onFocus, onBlur} =
			overlayControl.methods({
				thisRef: this,
				hoverDelay,
			});

		const addRootProps = obj => {
			this.setState(state => ({...state, rootProps: {...state.rootProps, ...obj}}));
		};

		const onDocumentClick = e => {
			if (
				this.state.showOverlay &&
				!this.rootComponentRef.current?.contains(e.target) &&
				!this.overlayComponentRef.current?.contains(e.target)
			) {
				this.setState({showOverlay: false});
			}
		};

		if (triggers.includes('hover')) {
			addRootProps({onMouseOver, onMouseOut});
		}

		if (triggers.includes('click')) {
			addRootProps({onClick});

			// popper's reference component uses `event.stopPropagation()`, but the third argument ensures we catch clicks that happen on peer overlays too regardless.
			document.addEventListener('click', onDocumentClick, true);
			this.onDocumentClick = onDocumentClick;
		}

		if (triggers.includes('keypress')) {
			addRootProps({onKeyPress});
		}

		if (triggers.includes('focus')) {
			addRootProps({onFocus, onBlur});
		}

		document.addEventListener('keyup', this.onDocumentKeyUp);
	}

	componentWillUnmount = () => {
		if (this.onDocumentClick) {
			document.removeEventListener('click', this.onDocumentClick);
		}
		document.removeEventListener('keyup', this.onDocumentKeyUp);
	};

	setUpdatePopper = f => {
		this.updatePopper = f;
	};

	render() {
		const {
			rootClass: Root,
			rootProps = {},
			rootContent,
			overlayClass: OverlayComponent,
			overlayProps = {},
			overlayContent,
			popperProps = {},
			disabled,
			triggers,
			hoverDelay,
			unmountOverlayContentOnHide,
			usePortal,
			portalTarget,
			...rest
		} = this.props;

		// ugly hack: force update popper when the overlay becomes shown, otherwise its positioning will be off if it had "display: none" earlier. not nice doing this in the render function but works.
		const show = !disabled && this.state.showOverlay;
		if (show && !this.lastShown) this.updatePopper();
		this.lastShown = show;

		const popperContainerProps = {
			overlayComponentRef: this.overlayComponentRef,
			popperProps,
			setUpdatePopper: this.setUpdatePopper,
			OverlayComponent,
			show,
			overlayProps,
			unmountOverlayContentOnHide,
			overlayContent,
		};

		return (
			<Manager {...rest}>
				<Reference innerRef={this.rootComponentRef}>
					{({ref}) => (
						<Root
							ref={ref}
							{...(disabled ? {} : {tabIndex: 0})}
							{...this.state.rootProps}
							{...rootProps}
						>
							{rootContent || rootProps.children}
						</Root>
					)}
				</Reference>
				{usePortal ? (
					ReactDOM.createPortal(
						<PopperContainer {...popperContainerProps} />,
						portalTarget || document.getElementById('app'),
					)
				) : (
					<PopperContainer {...popperContainerProps} />
				)}
			</Manager>
		);
	}
}

Object.entries(overlayControl.props).forEach(([key, val]) => {
	PopperOverlay.prototype[key] = val;
});

PopperOverlay.propTypes = {
	rootClass: propTypes.elementType.isRequired,
	rootProps: propTypes.object,
	rootContent: propTypes.node,
	overlayClass: propTypes.elementType.isRequired,
	overlayProps: propTypes.object,
	overlayContent: propTypes.node,
	popperProps: propTypes.object,
	disabled: propTypes.bool,
	triggers: propTypes.array.isRequired,
	hoverDelay: propTypes.number,
	unmountOverlayContentOnHide: propTypes.bool,
	usePortal: propTypes.bool,
	portalTarget: propTypes.node,
};

export default PopperOverlay;
