import {state as lifecycle} from 'fragments/lifecycle';
import {localeCenterWeb, localeZoomFactor} from 'constants/maps';
import {emptyPagination} from 'constants/pagination';

export const initialFilters = {
	encounterState: 'any',
	minYear: null,
	maxYear: null,
	aggregateType: 'encounter_coverage',
	areaType: 'city',
};

export default {
	...lifecycle,
	mapQuery: {
		z: localeZoomFactor * 5,
		x: localeCenterWeb[0],
		y: localeCenterWeb[1],
		...initialFilters,
		activeCallPoolId: null,
		selectionType: null,
		selectionIds: [],
		areasLayer: true,
		buildingsLayer: true,
		callPoolsLayer: true,
		groundwaterAreasLayer: false,
		propertyLinesLayer: false,
		mapSource: 'here-streets',
	},
	callPools: [],
	callPoolsLoading: true,
	activeCallPool: null,
	activeCallPoolLoading: false,
	selection: null,
	selectionLoading: false,
	processing: false,
	issues: [],
	teams: [],
	teamsLoading: true,
	selectionInfoTab: 'details',
	offers: [],
	offersPagination: emptyPagination,
	offersLoading: false,
	offersModalOpen: false,
	drawEndVisible: false,
	customDrawArea: null,
	customAreaCreatorOpen: false,
	showDrawMenu: false,
	drawType: null,
	drawing: false,
	callPoolCreatorOpen: false,
	products: [],
	otherOrganizationProducts: [],
	organizations: [],
	buildings: [],
	areaSettingsModalOpen: false,
	encounters: [],
	activeEncounterSource: null,
	activeEncounterSourceType: null,
	mobileSidebarOpen: false,
	// TODO: remove this when smart data is removed
	smartDataOptions: [],
	smartDataOptionsLoading: false,
	profinderListCreatorOpen: false,
	creatingProfinderList: false,
	profinderListsProcessing: false,
	availableTags: [],
	usersByTeamId: {},
	usersByTeamIdProcessing: false,
};
