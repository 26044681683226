import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';

export default {
	...lifecycle,
	condosQuery: {
		_page: 1,
		areas: [],
		productFilters: [],
		filter: null,
		dismissPrompt: false,
		manufacturingYearStart: null,
		manufacturingYearEnd: null,
	},
	condos: [],
	condosLoading: false,
	condosPagination: emptyPagination,
	showPrompt: false,
	processing: false,
	areasSelectorOpen: false,
	areasSelectorSearch: '',
	areasSelectorSuggestionsOpen: false,
	areasSelectorSuggestions: [],
	products: [],
};
