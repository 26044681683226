import React from 'react';

const SvgBin = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M9.25 3.75A.25.25 0 0 1 9.5 4v7a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1V4a.25.25 0 0 1 .25-.25h7.5zm-4.625 6.5v-4.5a.375.375 0 0 0-.75 0v4.5a.375.375 0 1 0 .75 0zm2.5 0v-4.5a.375.375 0 0 0-.75 0v4.5a.375.375 0 1 0 .75 0zM10.5 2a.5.5 0 1 1 0 1H.5a.5.5 0 1 1 0-1h2.375A.125.125 0 0 0 3 1.875V1.25C3 .56 3.56 0 4.25 0h2.5C7.44 0 8 .56 8 1.25v.625c0 .069.056.125.125.125H10.5zM4 1.875c0 .069.056.125.125.125h2.75A.125.125 0 0 0 7 1.875V1.25A.25.25 0 0 0 6.75 1h-2.5a.25.25 0 0 0-.25.25v.625z"
		/>
	</svg>
);

export default SvgBin;
