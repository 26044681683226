export const trackerHeaders = {
	need: 'Need',
	visit: 'Visit',
	offer: 'Offer',
	conclusion: 'Conclusion',
};
export const cATrackerHeaders = {
	callReminders: 'My reminders',
	notReached: 'Not reached',
	contactInAMonth: 'Contact in a month',
	contactIn3Months: 'Contact within 1-3 months',
	contactAfter3Months: 'Contact after 3 months',
	noContactTime: 'No contact time',
};

export const states = {
	visit: 'Visit booked',
	need: 'Need',
	offer: 'Offer',
	deal: 'Sale',
	noNeed: 'No need',
	noOffer: 'Not offered',
	cancelledOffer: 'Cancelled offer',
	waiting: 'Waiting',
	// items below are not actual condo states
	notReached: 'Not reached',
	contact: 'Contact',
};

export const additionalInfos = {
	1: null,
	2: 1,
	3: null,
	4: null,
	5: 2,
	6: 3,
};
