import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import * as rootSelectors from 'modules/common/selectors';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {
	formatBuildingsFilterFormOutput,
	formatAddBonusFormOutput,
	isCrossSales,
	formatAllProducts,
} from './utils';
import {buildingsPerPage} from './constants';
import {getFormValues} from 'redux-form';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, effects.destroy()];
		}

		case actions.reinitMap.type: {
			return [state, effects.reinitMap()];
		}

		case actions.searchBuildings.type: {
			const newState = {
				...state,
				buildingsLoading: true,
				buildingsPagination: {
					currentPage: 1,
					totalPages: 1,
				},
				contactsQuery: {
					...state.contactsQuery,
					...formatBuildingsFilterFormOutput({form: payload}),
				},
			};

			return [
				newState,
				payload.searchType === 'nearest'
					? effects.getNearestBuildings()
					: effects.getAreaBuildings(),
			];
		}

		case actions.changePage.type: {
			const newState = {
				...state,
				buildingsPagination: {
					...state.buildingsPagination,
					currentPage: payload,
				},
				contactsQuery: {...state.contactsQuery, _page: payload},
			};

			return [newState, effects.updateUrlQuery()];
		}

		case actions.setActiveTab.type: {
			const dismissPrompt = state.contactsQuery.dismissPrompt;
			return [
				{
					...state,
					contactsQuery: {...state.contactsQuery, activeTab: payload},
				},
				payload === 'map' ? effects.initMap({dismissPrompt}) : effects.updateUrlQuery(),
			];
		}

		case actions.setMapSource.type: {
			return [
				{...state, contactsQuery: {...state.contactsQuery, mapSource: payload}},
				effects.setMapSource(payload),
			];
		}

		case actions.updateContactsQuery.type: {
			const newState = {
				...state,
				contactsQuery: {...state.contactsQuery, ...payload},
			};

			return [newState, effects.updateContactsQuery(state.contactsQuery)];
		}

		case actions.openBuilding.type: {
			const {buildingId, app} = payload;
			const newState = {
				...state,
				contactsQuery: {
					...state.contactsQuery,
					selectionId: buildingId,
				},
			};

			return [newState, effects.openBuilding({buildingId, app})];
		}

		case actions.updateBuilding.type: {
			return [state, effects.updateBuilding(payload)];
		}

		case actions.openAddBonusModal.type: {
			const {productId} = getFormValues('buildingsFilterForm')(fullState);
			const showCrossSalesCount = isCrossSales({
				selectedProductId: productId,
				organizationProducts: state.organizationProducts,
				allEncounters: payload.allEncounters,
				activeOrganizationId: rootSelectors.activeOrganizationId(fullState),
			});

			return [
				{...state, addBonusModalOpen: true, bonusBuilding: payload, showCrossSalesCount},
				null,
			];
		}

		case actions.closeAddBonusModal.type: {
			return [
				{
					...state,
					addBonusModalOpen: false,
					bonusBuilding: null,
					showCrossSalesCount: false,
				},
				null,
			];
		}

		case actions.addBonus.type: {
			const {productId} = getFormValues('buildingsFilterForm')(fullState);

			const calendarResource = formatAddBonusFormOutput({
				...payload,
				teamId: state.userTeams.length ? state.userTeams[0].id : null,
				buildingId: state.bonusBuilding ? state.bonusBuilding.id : null,
				reserverId: rootSelectors.user(fullState).id,
				crossSales: isCrossSales({
					selectedProductId: productId,
					organizationProducts: state.organizationProducts,
					allEncounters: state.bonusBuilding ? state.bonusBuilding.allEncounters : null,
					activeOrganizationId: rootSelectors.activeOrganizationId(fullState),
				}),
			});

			return [{...state, processing: true}, effects.addBonus(calendarResource)];
		}

		case actions._updateManufacturingYears.type: {
			return [{...state, contactsQuery: {...state.contactsQuery, ...payload}}, null];
		}

		case actions._updateContactsQuery.type: {
			const newState = {
				...state,
				buildingsLoading: true,
				contactsQuery: {...state.contactsQuery, ...payload},
			};

			return [newState, null];
		}

		case actions._setBuildings.type: {
			return [
				{
					...state,
					buildings: payload,
					buildingsPagination: {
						...state.buildingsPagination,
						totalPages: Math.ceil(payload.length / buildingsPerPage),
					},
					buildingsLoading: false,
				},
				null,
			];
		}

		case actions._setAllBuildings.type: {
			const newState = {
				...state,
				allBuildings: payload,
			};

			return [newState, null];
		}

		case actions._setOrganizationProducts.type: {
			return [{...state, organizationProducts: payload}, null];
		}

		case actions._setOrganizations.type: {
			return [{...state, organizations: payload}, null];
		}

		case actions._setAllProducts.type: {
			const allProducts = formatAllProducts({
				products: payload,
				activeOrganizationId: rootSelectors.activeOrganizationId(fullState),
			});

			return [{...state, allProducts}, null];
		}

		case actions._setBuildingsLoading.type: {
			return [{...state, buildingsLoading: payload}, null];
		}

		case actions._updateBuildingsPagination.type: {
			return [
				{...state, buildingsPagination: {...state.buildingsPagination, ...payload}},
				null,
			];
		}

		case actions._updateBuilding.type: {
			const buildings = state.buildings.map(b => {
				return b.id === payload.id ? {...b, ...payload} : b;
			});

			const allBuildings = state.allBuildings.map(b => {
				return b.id === payload.id ? {...b, ...payload} : b;
			});

			return [{...state, buildings, allBuildings}, null];
		}

		case actions._setUserTeams.type: {
			return [{...state, userTeams: payload, userTeamsLoading: false}, null];
		}

		case actions._setAllAreas.type: {
			const allAreas = payload;

			return [{...state, allAreas, allAreasLoading: false}, null];
		}

		case actions._addBonus.type: {
			return [{...state, processing: false, addBonusModalOpen: false}, null];
		}

		case actions._setCrossSalesCount.type: {
			return [{...state, crossSalesCount: payload}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
