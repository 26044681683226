import {callFormBase, callSurveyId, callReviewSurveyId} from './constants';
import {toLocaleDateString, increaseDay} from 'utils/time';
import {pickIfNot, replace} from 'utils/objects';
import {getNextDayEncounterSuggestion} from 'utils/encounters';
import {
	formatEncounterFormFillFormOutput,
	formatFormCalendarResourceOutput,
	formatFormBonusCalendarResourceOutput,
} from 'utils/formData';
import {processProductStatesForForms} from 'utils/products';
import memoize from 'memoize-one';
import {decodeQuery} from 'utils/url';
import {ihSource} from 'dicts/calendarResources';
import {evolve} from 'ramda';
import {parseNullableNumber, parseBoolean, parseNullableString} from 'utils/fields';

// Clients form
export const formatClientFormInput = ({client}) => {
	return client;
};
export const formatClientFormOutput = ({form}) => {
	const client = {...form, emailMarketing: form.emailMarketing ? new Date() : null};
	return client;
};

export const processProductStates = processProductStatesForForms;

// NOTE: similar to ProjectSales etc
export const _getCallStates = state => {
	const visit = state === 'visit';
	const notReached = state === 'notReached';
	const contacted = state === 'contacted';
	const cancelled = state === 'cancelled';
	const reached = !notReached;
	const lost = contacted || cancelled;

	return {visit, notReached, contacted, cancelled, reached, lost};
};
// easy optimization
export const getCallStates = memoize(_getCallStates);

const formatSurveyInput = survey => {
	const currYear = new Date().getFullYear();

	if (survey && survey.surveyQuestions.length) {
		return survey.surveyQuestions.map(q => ({
			id: q.id,
			title: q.title,
			yearLimit: q.yearLimit,
			productId: q.product?.id,
			type: q.type,
			value: null,
			// set default value to middle between min and max value (min is current year - yearLimit, max is current year)
			year: currYear - Math.round(Number(q.yearLimit) / 2),
		}));
	}
	return [];
};

// NOTE: similar to D2d
export const formatCallFormInput = ({
	userTeams,
	openedAt,
	survey,
	surveyCallReview,
	lead,
}) => {
	const surveyInput = formatSurveyInput(survey);
	const surveyCallReviewInput = formatSurveyInput(surveyCallReview);

	return {
		...callFormBase,
		calendarResource: {
			...callFormBase.calendarResource,
			teamId: userTeams.length ? userTeams[0].id : null,
			dateFrom: openedAt ? getNextDayEncounterSuggestion(openedAt) : null,
		},
		bonusCalendarResource: {
			...callFormBase.bonusCalendarResource,
			dateFrom: openedAt ? toLocaleDateString(increaseDay(openedAt, 1)) : null,
		},
		requestDate: openedAt ? toLocaleDateString(openedAt) : null,
		survey: surveyInput,
		surveyCallReview: surveyCallReviewInput,
		formFill: {
			description: lead ? lead.comment : '',
		},
	};
};

const formatSurveyOutput = ({survey, building, surveyId}) => {
	const filledOnly = survey.filter(s => !!s.value);

	const surveyQuestions = filledOnly.map(a => ({
		id: a.id,
		value: a.value,
		year: a.value === 'no' ? a.year : null, // send year only if value is 'no'
	}));

	return {
		surveyId,
		buildingId: building.id,
		surveyQuestions,
		// sourceId (= callId) added to data after call saved
	};
};

export const formatCallFormOutput = ({form, building, teamId}) => {
	const {
		dateCreatorOpen,
		state,
		isBonus,
		isRequest,
		calendarResource,
		bonusCalendarResource,
		contactClientId,
		formFill,
		reason,
		reasonMappingId,
		survey,
		surveyCallReview,
		...callVals
	} = form;

	const isVisit = state === 'visit';
	const isLost = reason === 'allRepairsDone';

	const callData = {
		...callVals,
		contactClientId,
		// keep fields if they match criteria, otherwise clear them to base values
		...pickIfNot(
			{
				calendarResourceId: isVisit && !dateCreatorOpen,
				contactClientId: isVisit || (!isVisit && isBonus),
				requestSource: isRequest,
				requestDate: isRequest,
			},
			callFormBase,
		),
		appointment: isVisit,
		reached: true,
		preferredEncounterState: isLost ? 'cancelled' : !isVisit ? 'contacted' : null,
		reason: isVisit ? null : reason,
		reasonMappingId: isVisit ? null : reasonMappingId,
	};

	const calendarResourceData =
		// prettier-ignore
		isVisit && dateCreatorOpen
			? formatFormCalendarResourceOutput({calendarResource, contactClientId})
		: !isVisit && isBonus
			? formatFormBonusCalendarResourceOutput({bonusCalendarResource, contactClientId, teamId})
		: null;

	const formFillData = isVisit ? formatEncounterFormFillFormOutput(formFill, true) : null;

	const surveyData = formatSurveyOutput({survey, building, surveyId: callSurveyId});
	const surveyCallReviewData = formatSurveyOutput({
		survey: surveyCallReview,
		building,
		surveyId: callReviewSurveyId,
	});

	return {
		call: callData,
		calendarResource: calendarResourceData,
		formFill: formFillData,
		survey: surveyData,
		surveyCallReview: surveyCallReviewData,
	};
};

export const getDefaultIhSource = ({allEncounters, referrer, referrerUrl}) => {
	if (allEncounters && allEncounters.find(e => e.state === 'installed')) {
		return 'installed';
	} else if (!allEncounters || !allEncounters.length) {
		return 'notContacted';
	} else if (referrer === 'callPool') {
		return 'call';
	} else if (referrer === 'leads') {
		return 'lead';
	} else if (referrer === 'salesman-app-contacts' && referrerUrl) {
		// read the selected encounterState (in SalesmanApp search form) from referrerUrl
		const {encounterState} = decodeQuery(referrerUrl);
		const isValidIhSource = encounterState
			? Object.keys(ihSource).includes(encounterState)
			: false;

		return isValidIhSource ? encounterState : null;
	}

	return null;
};

export const parseUrlQuery = query => {
	const defaults = {
		allowAutoCall: false,
		leadId: null,
		callPoolId: null,
		referrer: null,
		referrerUrl: null,
	};

	const vals = evolve(
		{
			allowAutoCall: parseBoolean,
			leadId: parseNullableNumber,
			callPoolId: parseNullableNumber,
			referrer: parseNullableString,
			referrerUrl: parseNullableString,
		},
		query,
	);
	return replace(vals, defaults);
};
