import services from 'services';
import {map, prop, pipe} from 'ramda';
import {describeThrow, describeError} from 'utils/errors';
import {buildingSearchQueryBase} from './constants';
import * as normalize from 'utils/normalize';
import {mapResponseData} from 'utils/app';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getBuildings = query =>
	httpJson('get', '/buildings', {
		...buildingSearchQueryBase,
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.building)));

export const postRestoreBuilding = bid =>
	httpJson('post', `/buildings/${bid}/restore`, {}, {}).catch(
		describeThrow(intl.formatMessage({id: 'Failed to restore building'})),
	);

export const postBuilding = building =>
	httpJson('post', `/buildings`, {}, {body: building})
		.catch(e => {
			const validationFailed = !!e.response && e.response.status === 409;
			e.causedByValidationFailed = true;
			return validationFailed
				? e.response.json().then(body => describeThrow(body.message, e))
				: Promise.reject(describeError(msgs.saveFailed, e));
		})
		.then(pipe(prop('data'), normalize.building));
