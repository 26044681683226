import React from 'react';
import {FormattedMessage, injectIntl} from '@meiko/react-intl';
import Button from 'components/generic/Button';
import Col from 'components/generic/Col';
import Field from 'components/generic/Field';
import FieldError from 'components/generic/FieldError';
import FieldSet from 'components/generic/FieldSet';
import FormBlock from 'components/generic/FormBlock';
import Hr from 'components/generic/Hr';
import Input from 'components/generic/Input';
import Label from 'components/generic/Label';
import Row from 'components/generic/Row';
import messages from 'dicts/messages';
import {isEmpty, isNil} from 'ramda';
import {compose} from 'redux';
import {reduxForm} from 'redux-form';
import services from 'services';
import styled from 'styled-components';
import {valueAsNullableNumber} from 'utils/fields';
import {encodeUserIdToFormInput} from 'modules/usersApp/dashboardTargetsPage/utils';
import {
	OFFERS_TARGET,
	SALES_TARGET,
	TARGETS,
	VISITS_TARGET,
} from 'modules/usersApp/dashboardTargetsPage/constants';

const UserLabel = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
`;

const ButtonFieldSet = styled(FieldSet)`
	text-align: right;
`;

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const validate = (values, {users}) => {
	const errors = Object.keys(values).reduce((acc, key) => {
		const value = values[key];
		const errors = TARGETS.reduce((a, k) => {
			if (isNil(value?.[k] ?? null)) {
				a[k] = intl.formatMessage({id: messages.requiredField});
			} else if (value[k] < 0) {
				a[k] = intl.formatMessage({id: messages.invalidField});
			}
			return a;
		}, {});
		if (!isEmpty(errors)) {
			acc[key] = errors;
		}
		return acc;
	}, {});
	return errors;
};

const DashboardTargetsUsersForm = ({
	users,
	handleSubmit,
	registerField,
	change,
	initialValues,
	disabled,
	submitting,
	...rest
}) => {
	return (
		<form onSubmit={handleSubmit}>
			{users.map(user => (
				<div key={user.id}>
					<FieldSet>
						<Row>
							<Col>
								<UserLabel>
									{user.firstName} {user.lastName}
								</UserLabel>
							</Col>
							<Col size={2}>
								<Field
									name={`${encodeUserIdToFormInput(user.id)}.${SALES_TARGET}`}
									type="number"
									component={({input, inputId, meta}) => (
										<FormBlock>
											<Label htmlFor={inputId}>
												<FormattedMessage id="Sales" /> €
											</Label>
											<Input
												{...valueAsNullableNumber(input)}
												id={inputId}
												block
												stretch
											/>
											<FieldError {...meta} />
										</FormBlock>
									)}
								/>
							</Col>

							<Col size={2}>
								<Field
									name={`${encodeUserIdToFormInput(user.id)}.${OFFERS_TARGET}`}
									type="number"
									component={({input, inputId, meta}) => (
										<FormBlock>
											<Label htmlFor={inputId}>
												<FormattedMessage id="Offers" /> <FormattedMessage id="pcs" />
											</Label>
											<Input
												{...valueAsNullableNumber(input)}
												id={inputId}
												block
												stretch
											/>
											<FieldError {...meta} />
										</FormBlock>
									)}
								/>
							</Col>

							<Col size={2}>
								<Field
									name={`${encodeUserIdToFormInput(user.id)}.${VISITS_TARGET}`}
									type="number"
									component={({input, inputId, meta}) => (
										<FormBlock>
											<Label htmlFor={inputId}>
												<FormattedMessage id="Visits" /> <FormattedMessage id="pcs" />
											</Label>
											<Input
												{...valueAsNullableNumber(input)}
												id={inputId}
												block
												stretch
											/>
											<FieldError {...meta} />
										</FormBlock>
									)}
								/>
							</Col>
						</Row>
					</FieldSet>
					<Hr key={`${user.id}-hr`} />
				</div>
			))}
			<ButtonFieldSet>
				<Button disabled={submitting || disabled} type="submit" appearance="primary">
					<FormattedMessage id="Save" />
				</Button>
			</ButtonFieldSet>
		</form>
	);
};

const DASHBOARD_TARGETS_USERS_FORM = ' dashboardTargetsUsersForm';
export default compose(
	injectIntl,
	reduxForm({
		form: DASHBOARD_TARGETS_USERS_FORM,
		validate,
	}),
)(DashboardTargetsUsersForm);
