import services from 'services';
import {prop, pipe} from 'ramda';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import {buildingInclude} from './constants';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const updateBuilding = (building, include) =>
	httpJson(
		'put',
		`/buildings/${building.id}`,
		{include: include || buildingInclude},
		{body: building},
	)
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save'})))
		.then(pipe(prop('data'), normalize.building));

export const deleteBuilding = bid =>
	httpJson('delete', `/buildings/${bid}`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to delete'})),
	);
