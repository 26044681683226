// core dependencies
import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
// custom helpers & utils
import {FormattedMessage} from '@meiko/react-intl';
import {colors, fontSizeS} from 'styles/constants';
// components
import Button from 'components/generic/Button';

const InfoText = styled.div`
	display: inline-block;
	padding-bottom: 10px;
	line-height: 1.4;
	color: ${colors.white};
	font-size: ${fontSizeS};
`;

const AzureLogin = ({loginWithAd, loading}) => {
	return (
		<>
			<InfoText>
				<FormattedMessage id="Sign in with your Microsoft account. For example, you can use your Office 365 account" />
			</InfoText>
			<Button
				center
				size="xl"
				id="ad-login-button"
				type="submit"
				onClick={() => loginWithAd()}
				appearance="primary"
				disabled={loading}
				stretch
			>
				<span>
					<i className="fa fa-windows" />
				</span>{' '}
				<FormattedMessage id="Log in" />
			</Button>
		</>
	);
};

AzureLogin.propTypes = {
	loginWithAd: propTypes.func,
	loading: propTypes.bool,
};

export default AzureLogin;
