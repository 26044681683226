import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {formatUrlQuery} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const remindersQuery = pipe(root, prop('remindersQuery'));

export const remindersQueryFetchable = createSelector(remindersQuery, remindersQuery => {
	return {...remindersQuery, type: 'marketing'};
});

export const urlQuery = createSelector([remindersQuery], remindersQuery => {
	return formatUrlQuery({remindersQuery});
});

export const reminders = pipe(root, prop('reminders'));

export const remindersPagination = pipe(root, prop('remindersPagination'));

export const remindersLoading = pipe(root, prop('remindersLoading'));

export const processing = pipe(root, prop('processing'));
