import {hourMs} from 'constants/time';

const trim = s => (s ? s.trim() : '');

export const fullAddress = (building, withoutStreet = false) => {
	const street = withoutStreet
		? ''
		: trim(building.address) || trim(building.addressSwe) || '';

	const city = [building.zip, building.city]
		.map(trim)
		.filter(s => !!s)
		.join(' ');
	return [street, city].filter(s => !!s).join(', ');
};

const notAvoidableStates = ['takeContact', 'notReached', 'notEncountered', 'installing'];

// note: does I/O (new Date) but whatever
export const isAvoidable = building =>
	!notAvoidableStates.includes(building.encounterState) &&
	(new Date() - new Date(building.encounterDate)) / hourMs < 48;

export const isUninteresting = (building, manufacturingLimit) =>
	!!manufacturingLimit && building.manufacturingYear >= manufacturingLimit;

// manufacturingLimit and preferD2dState are optional
export const state = ({building, manufacturingLimit, preferD2dState}) =>
	// note: banned was previously a separate property and not considered a building state
	// prettier-ignore
	building.banned ? 'banned'
	: isAvoidable(building) ? 'avoid'
	: isUninteresting(building, manufacturingLimit) ? 'noInterest'
	: preferD2dState && building.encounterD2DState ? building.encounterD2DState
	: building.encounterState;

// note: prefer the other version with nicer api
export const getState = (building, organization = null, preferD2dState = false) =>
	state({
		building,
		manufacturingLimit: organization ? organization.meta.manufacturingLimit : null,
		preferD2dState,
	});
