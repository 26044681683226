import styled from 'styled-components';
import {labelUnstyled, button} from 'styles/fragments';
import {buttonFillActiveColors, buttonTextFocusColors} from 'styles/constants';

// note: intended to be used with the ButtonRadio component only

export default styled.label.attrs({appearance: 'radio'})`
	${labelUnstyled};
	${button};

	display: inline-block;
	line-height: inherit;

	input:checked + && {
		background: ${({appearance = 'radio'}) => buttonFillActiveColors[appearance]};
		color: ${({appearance = 'radio'}) => buttonTextFocusColors[appearance]};
	}
`;
