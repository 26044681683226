import {pipe, prop, map} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import {calResQueryBase, googleMapStyles, markerUrl, routeColors} from './constants';
import {colors, grayDark} from 'styles/constants';
import {fullAddress} from 'utils/buildings';
import {getResponseData} from 'utils/app';
import msgs from 'dicts/messages';
import cache from './cache';
import {localeCenterWeb, localeZoomFactor} from 'constants/maps';
import {transform} from 'ol/proj';
import {catchNonFatalDefault} from 'io/errors';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getCalendarResources = query =>
	httpJson('get', '/calendarResource', {
		include: 'salesman,building,reservation.encounter.source.formFill.formAttributes',
		...calResQueryBase,
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load calendar entries'})))
		.then(pipe(prop('data'), map(normalize.calendarResource)));

export const getTeam = teamId =>
	httpJson('get', `/teams/${teamId}`, {
		include: 'users',
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to fetch teams'})))
		.then(getResponseData(normalize.team));

export const getRoutes = query =>
	httpJson('get', '/calendarResource/routes', {
		...query,
	}).catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})));

export const updateSalesmanIds = body =>
	httpJson('put', `/calendarResource/updateSalesmanIds`, {}, {body}).catch(
		describeThrow(intl.formatMessage({id: msgs.contentPostFailed})),
	);

export const postRoutePlannerLog = body =>
	httpJson('post', '/calendarResource/routePlanner/log', {}, {body}).catch(
		catchNonFatalDefault,
	);

const createExtraMarkers = ({calendarResources, color, map}) => {
	const {extraMarkers} = cache.read();

	if (extraMarkers) {
		extraMarkers.map(m => m.setMap(null));
	}

	if (!calendarResources.length) return;

	const markerColor = colors.grey4.substring(1);
	const markers = calendarResources.map((cr, idx) => {
		const {building} = cr;
		const {coordinates} = building.location;
		const image = {
			url: markerUrl({index: idx, color: markerColor, borderColor: markerColor}),
			scaledSize: new window.google.maps.Size(27, 43),
		};
		// create infoWindow for the marker, show building full address
		const infoWindow = new window.google.maps.InfoWindow({
			content: fullAddress(building),
		});

		const extraMarker = new window.google.maps.Marker({
			map,
			icon: image,
			position: {lat: coordinates[0][1], lng: coordinates[0][0]},
		});

		extraMarker.addListener('click', () => {
			infoWindow.open(map, extraMarker);
		});

		return extraMarker;
	});

	cache.update(c => ({
		...c,
		extraMarkers: markers,
	}));
};

const calcRoute = ({buildings, color, visible, map, directionsService, bounds}) => {
	if (!buildings.length) return Promise.resolve(null);

	const waypoints = buildings.filter(
		(b, index) => index > 0 && index < buildings.length - 1,
	);

	const request = {
		origin: fullAddress(buildings[0]),
		destination: fullAddress(buildings[buildings.length - 1]),
		travelMode: window.google.maps.TravelMode.DRIVING,
		waypoints: waypoints.map(p => ({location: fullAddress(p), stopover: true})),
	};

	return new Promise((resolve, reject) => {
		directionsService.route(request, (result, status) => {
			if (status !== 'OK') {
				reject(new Error('Request failed'));
			} else {
				const markerArray = [];
				const directionsDisplay = new window.google.maps.DirectionsRenderer({
					suppressBicyclingLayer: true,
					preserveViewport: true,
					polylineOptions: {
						strokeColor: color,
					},
					markerOptions: {
						visible: false,
					},
				});

				directionsDisplay.setMap(visible ? map : null);
				directionsDisplay.setDirections(result);

				// combine the bounds of the responses
				bounds.union(result.routes[0].bounds);

				// add custom colored markers
				const _color = color.substring(1);
				buildings.map((b, index) => {
					const {coordinates} = b.location;

					const image = {
						url: markerUrl({index, color: _color, borderColor: _color}),
						scaledSize: new window.google.maps.Size(27, 43),
					};

					// create infoWindow for the marker, show building full address
					const infoWindow = new window.google.maps.InfoWindow({
						content: fullAddress(b),
					});

					//create new marker
					const marker = new window.google.maps.Marker({
						map,
						position: {lat: coordinates[0][1], lng: coordinates[0][0]},
						icon: image,
						fontSize: '16px',
					});

					marker.addListener('click', () => {
						infoWindow.open(map, marker);
					});

					markerArray.push(marker);
					return marker;
				});

				// calculate route total distance
				const legs = result.routes[0].legs;
				let totalDistance = 0;
				for (let i = 0; i < legs.length; i++) {
					totalDistance += legs[i].distance.value;
				}

				resolve({
					directionsDisplay,
					distance: Math.round(totalDistance / 1000),
					legs,
					markerArray,
				});
			}
		});
	});
};

export const createDirections = ({routes, map, fitBounds = true, extra = []}) => {
	const {directions} = cache.read();
	// clear old directions

	if (directions) {
		directions.forEach(d => {
			if (d) {
				d.directionsDisplay.setMap(null);
				const {markerArray} = d;
				if (markerArray) {
					markerArray.forEach(m => {
						if (m) m.setMap(null);
					});
				}
			}
		});
	}

	const directionsService = new window.google.maps.DirectionsService();
	const bounds = new window.google.maps.LatLngBounds();

	createExtraMarkers({
		calendarResources: extra,
		map,
		bounds,
	});

	return Promise.all(
		routes.map(r =>
			calcRoute({
				buildings: r.calendarResources.map(c => c.building),
				color: routeColors[r.routeNum] || grayDark,
				visible: r.visible,
				map,
				directionsService,
				bounds,
			}),
		),
	).then(dir => {
		// zoom and center the map to show all the routes
		if (dir.length && fitBounds) map.fitBounds(bounds);
		return dir;
	});
};

export const initRoutePlannerMap = routes => {
	const mapEl = document.querySelector('#route-planner-map');
	if (!mapEl) {
		return Promise.reject(new Error('Map element not found'));
	}

	const transformedCenter = transform(localeCenterWeb, 'EPSG:3857', 'EPSG:4326');

	const mapOptions = {
		zoom: localeZoomFactor * 5,
		center: new window.google.maps.LatLng(transformedCenter[1], transformedCenter[0]),
		styles: googleMapStyles,
		disableDefaultUI: true,
	};

	const map = new window.google.maps.Map(mapEl, mapOptions);

	return createDirections({routes, map}).then(directions => ({directions, map}));
};
