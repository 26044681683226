import {
	assoc,
	assocPath,
	omit,
	find,
	propEq,
	map,
	prepend,
	pipe,
	prop,
	mergeLeft,
} from 'ramda';
import {scopedHandler, pipeSubHandlers} from 'utils/redux';
import {getFormValues} from 'redux-form';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {
	processProductStates,
	formatSalesmanVisitFormOutput,
	formatClientFormOutput,
} from './utils';
import * as rootSelectors from 'modules/common/selectors';
import createDatePickHandler from 'fragments/calendarResourcePicker/handler';
import createCallReminderHandler from 'fragments/callReminder/handler';
import {processFreeCalendarResources} from 'fragments/calendarResourcePicker/utils';
import {getHourBoundaries} from 'utils/time';
import * as selectors from './selectors';

const pipeHandlers = pipeSubHandlers(ns);

const datePickHandler = createDatePickHandler({
	actions,
	effects,
	calendarResourceSelectionSelector: pipe(
		getFormValues('salesmanVisitForm'),
		prop('newCalendarResourceId'),
	),
});

const callReminderHandler = createCallReminderHandler({
	actions,
	effects,
	buildingSelector: selectors.building,
});

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize(payload)];
		}

		case actions.destroy.type: {
			return [initState, effects.destroy()];
		}

		case actions.toggleBuildingPreviewModal.type: {
			return [{...state, buildingPreviewOpen: !state.buildingPreviewOpen}];
		}

		case actions.openEncounterPreviewModal.type: {
			return [{...state, processing: true}, effects.fetchEncounter(payload)];
		}

		case actions.closeEncounterPreviewModal.type: {
			return [{...state, previewableEncounter: null}];
		}

		case actions.toggleCurEncounterPreviewModal.type: {
			return [{...state, encounterPreviewOpen: !state.encounterPreviewOpen}, null];
		}

		case actions.toggleCalendarResourceDateEdit.type: {
			return [
				{...state, calendarResourceDateEditOpen: !state.calendarResourceDateEditOpen},
				null,
			];
		}

		case actions.reinitialize.type: {
			return [initState, effects.initialize(payload)];
		}

		case actions.saveCalendarResourceDateEdit.type: {
			const values = getFormValues('calendarResourceDateForm')(fullState) || {};

			const [dateFrom, dateTo] = values.dateFrom
				? getHourBoundaries(values.dateFrom)
				: [null, null];

			const calendarResource = {
				id: state.calendarResource.id,
				dateFrom,
				dateTo,
			};

			return [
				{...state, processing: true},
				effects.updateCalendarResource(calendarResource),
			];
		}

		case actions.saveSalesmanVisit.type: {
			const isNew = !state.salesmanVisit;
			const clientId = state.calendarResource.clientId;
			const calendarResourceId = state.calendarResource.id;

			const salesmanVisitForm = getFormValues('salesmanVisitForm')(fullState);

			const {salesmanVisit, callReminder} = formatSalesmanVisitFormOutput({
				form: salesmanVisitForm,
				salesmanVisit: state.salesmanVisit,
				building: state.building,
				clientId,
				calendarResourceId,
			});

			const userId = state.calendarResource.salesman
				? state.calendarResource.salesman.id
				: rootSelectors.user(fullState).id;

			const salesmanVisit_ = mergeLeft({
				clientId,
				calendarResourceId,
				// API requires this for some reason even for updates
				userId,
			})(salesmanVisit);

			return [
				{...state, processing: true},
				effects.saveSalesmanVisit({
					isNew,
					salesmanVisit: salesmanVisit_,
					callReminder,
				}),
			];
		}

		case actions.callLog.type: {
			const data = payload;
			return [state, effects.callLog(data)];
		}

		case actions.cancelVisit.type: {
			const calendarResource = {
				...state.calendarResource,
				cancelled: true,
			};

			return [state, effects.updateCalendarResource(calendarResource)];
		}

		case actions.restoreVisit.type: {
			const calendarResource = {
				...state.calendarResource,
				cancelled: false,
			};

			return [state, effects.updateCalendarResource(calendarResource)];
		}

		case actions.toggleClientEditor.type: {
			const newState = state.clientEditorOpen
				? {
						...state,
						clientEditorOpen: false,
						client: null,
						newClients: [],
				  }
				: {
						...state,
						clientEditorOpen: true,
						client: payload,
				  };

			return [newState, null];
		}

		case actions.changeClient.type: {
			let newState = assocPath(['calendarResource', 'clientId'], payload, state);
			newState = {
				...newState,
				clientChangerOpen: false,
			};

			const calendarResource = {
				id: state.calendarResource.id,
				clientId: payload,
			};

			return [newState, effects.updateCalendarResource(calendarResource)];
		}

		case actions.toggleClientChanger.type: {
			const newState = {
				...state,
				clientChangerOpen: !state.clientChangerOpen,
			};
			return [newState, null];
		}

		case actions.saveClient.type: {
			const form = getFormValues('clientForm')(fullState);
			const newClient = state.client ? false : true;
			const client = formatClientFormOutput({form});
			const buildingId = newClient ? state.building.id : null;
			return [{...state, processing: true}, effects.saveClient({client, buildingId})];
		}

		// comments, TEMP
		case actions.openCommentEditor.type: {
			const comments = state.comments.map(comment => {
				if (comment.id === payload) {
					comment.editing = !comment.editing;
					return comment;
				} else {
					comment.editing = false;
					return comment;
				}
			});

			return [{...state, comments}, null];
		}

		case actions.saveComment.type: {
			const form = payload
				? getFormValues('updateCommentsForm')(fullState)
				: getFormValues('commentsForm')(fullState);
			const data = {
				buildingId: state.building.id,
				comment: form.comment,
			};
			const id = payload ? payload : null;

			return [{...state, processing: true}, effects.createComment({data, id})];
		}

		case actions.removeComment.type: {
			return [
				{...state, processing: true},
				payload ? effects.removeComment(payload) : null,
			];
		}

		case actions.setActiveTab.type: {
			return [{...state, activeTab: payload}, null];
		}

		case actions._commentSaved.type: {
			const newComment = {
				...payload,
				editing: false,
			};
			const old = find(propEq('id', newComment.id), state.comments);
			const comments = old
				? map(c => (c.id === newComment.id ? newComment : c), state.comments)
				: prepend(newComment, state.comments);

			return [{...state, processing: false, comments}, null];
		}

		case actions._commentRemoved.type: {
			const comments = state.comments.filter(comment => comment.id !== payload);

			return [{...state, comments, processing: false}, null];
		}
		// ...

		case actions._setInitData.type: {
			const productStates = processProductStates(
				payload.building.allBuildingProductStates,
			);

			const building = assoc('allBuildingProductStates', productStates, payload.building);

			const salesmanVisit = payload.salesmanVisit
				? omit(['newCalendarResource'], payload.salesmanVisit)
				: null;
			const prevVisitState = salesmanVisit ? salesmanVisit.state : null;

			const newState = {
				...state,
				...payload,
				allProductStates: productStates,
				productStatesLoading: false,
				building,
				clients: building.clients,
				salesmanVisitClient: payload.calendarResource.client,
				salesmanVisit,
				newAppointment:
					payload.salesmanVisit && payload.salesmanVisit.newCalendarResource,
				comments: building.comments.reverse(),
				prevVisitState,
			};

			return [newState, null];
		}

		case actions._setFreeCalendarResources.type: {
			const newState = {
				...state,
				freeCalendarResources: processFreeCalendarResources(payload),
			};

			return [newState, null];
		}

		case actions._setOrganizationSalesmen.type: {
			return [{...state, salesmen: payload, salesmenLoading: false}, null];
		}

		case actions._setEncounters.type: {
			return [{...state, encounters: payload}, null];
		}

		case actions._openEncounterPreviewModal.type: {
			return [{...state, previewableEncounter: payload}, null];
		}

		case actions._calendarResourceUpdated.type: {
			const calendarResource = {
				...state.calendarResource,
				...payload,
			};
			return [
				{
					...state,
					processing: false,
					calendarResourceDateEditOpen: false,
					calendarResource,
					salesmanVisitClient: payload.client,
				},
				null,
			];
		}

		case actions._salesmanVisitSaved.type: {
			const newState = {
				...state,
				salesmanVisit: omit(['newCalendarResource'], payload),
				newAppointment: payload.newCalendarResource && payload.newCalendarResource,
				prevVisitState: payload.state,
			};

			return [newState, null];
		}

		// NOTE: legacy, don't use
		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false}, null];
		}

		case actions._clientUpdated.type: {
			const clients =
				payload.type === 'update'
					? state.clients.map(c => {
							return c.id === payload.client.id ? payload.client : c;
					  })
					: payload.type === 'add'
					? [...state.clients, payload.client]
					: state.clients.filter(c => c.id !== payload.id);

			const salesmanVisitClient =
				state.salesmanVisitClient && payload.client.id === state.salesmanVisitClient.id
					? payload.client
					: state.salesmanVisitClient;

			const newState = {
				...state,
				processing: false,
				clientEditorOpen: false,
				clients,
				salesmanVisitClient,
			};

			return [newState, null];
		}

		case actions._setSalesmanVisits.type: {
			return [{...state, salesmanVisits: payload}, null];
		}

		case actions._setAvailableTags.type: {
			return [{...state, availableTags: payload}, null];
		}

		case actions.getTags.type: {
			return [{...state, processingTags: true}, effects.getTags(payload)];
		}

		case actions._getTags.type: {
			return [{...state, processingTags: false, tags: payload}, null];
		}

		case actions.attachTag.type: {
			return [
				{
					...state,
					processingTag: true,
				},
				effects.attachTag(payload),
			];
		}

		case actions._attachTag.type: {
			return [
				{
					...state,
					processingTag: false,
				},
				null,
			];
		}

		case actions.detachTag.type: {
			return [
				{
					...state,
					processingTag: true,
				},
				effects.detachTag(payload),
			];
		}

		case actions._detachTag.type: {
			return [
				{
					...state,
					processingTag: false,
				},
				null,
			];
		}

		default:
			return pipeHandlers(datePickHandler, callReminderHandler)(state, fullState, {
				type,
				payload,
			});
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
