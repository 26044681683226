import * as React from 'react';

const FolderAdd = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 12 12"
			width="1em"
			height="1em"
			{...props}
		>
			<g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
				<path
					d="M.429 10.071V1.93a.857.857 0 01.857-.858h3.163a.857.857 0 01.857.652l.265 1.063h5.143a.857.857 0 01.857.857v6.428a.857.857 0 01-.857.858H1.286a.857.857 0 01-.857-.858zM6.111 5.357v3M4.611 6.857h3"
					strokeWidth={0.85714}
				/>
			</g>
		</svg>
	);
};

export default FolderAdd;
