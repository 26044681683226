import {merge, __, equals, mergeLeft} from 'ramda';
import {effect} from 'utils/redux';
import {P} from 'utils/types';
import {describeThrow} from 'utils/errors';
import {over} from 'utils/lenses';
import services from 'services';
import {decorateWithNotifications} from 'io/app';
import {catchNonFatalDefault} from 'io/errors';
import {replaceQuery, getQuery, pushQuery} from 'io/history';
import {geocodeGooglePlaceId} from 'io/geo';
import {addMarker, getMapTileSource} from 'io/maps';
import {transform} from 'ol/proj';
import {buildingFocusZoom} from 'constants/maps';
import * as rootSelectors from 'modules/common/selectors';
import namespace from './namespace';
import * as actions from './actions';
import * as selectors from './selectors';
import {parseUrlQuery} from './utils';
import {initFreerideMap, getBuildingsStyle, getBuilding} from './io';
import cache from './cache';
import initState from './state';
import {resolveObject} from 'utils/promises';
import * as condoActions from 'modules/projectSalesApp/condoPage/actions';
import * as condoSelectors from 'modules/projectSalesApp/condoPage/selectors';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const initMap = (getState, dispatch) => {
	const apiToken = rootSelectors.apiToken(getState());
	const org = rootSelectors.activeOrganization(getState());

	const {buildingsQuery} = parseUrlQuery(getQuery());
	dispatch(actions._updateBuildingsQuery(buildingsQuery));

	const {
		z,
		x,
		y,
		encounterState,
		minYear,
		maxYear,
		buildingType,
		maxApartments,
		minApartments,
		buildingId,
		projectBuildingId,
		mapSource,
	} = buildingsQuery;

	let fetchBuilding = false;
	if (
		buildingId &&
		z === initState.buildingsQuery.z &&
		x === initState.buildingsQuery.x &&
		y === initState.buildingsQuery.y
	) {
		// Fetch coords for the building and center map on it
		fetchBuilding = true;
	}

	decorateWithNotifications(
		{id: 'map-init'},
		resolveObject({
			building: fetchBuilding ? getBuilding(buildingId) : Promise.resolve(null),
		}).then(({building}) => {
			let _projectBuildingId = projectBuildingId;
			if (building) {
				_projectBuildingId = building.projectBuildingId;
				dispatch(actions._updateBuildingsQuery({projectBuildingId: _projectBuildingId}));
				const currQuery = selectors.urlQuery(getState());
				replaceQuery(merge(__, currQuery));
			}

			let initialZoom = z;
			let initialCenter = [x, y];
			if (building && building.location && building.location.coordinates) {
				initialZoom = buildingFocusZoom;
				initialCenter = transform(
					building.location.coordinates[0],
					'EPSG:4326',
					'EPSG:3857',
				);
			}
			return initFreerideMap({
				apiToken,
				organizationId: org.id,
				initialZoom,
				initialCenter,
				initialFilters: {
					encounterState,
					minYear,
					maxYear,
					buildingType,
					maxApartments,
					minApartments,
				},
				buildingId,
				projectBuildingId: _projectBuildingId,
				manufacturingLimit: org.meta.manufacturingLimit,
				mapSourceProps: {sourceId: mapSource},
				onBuildingClick: (building, coords) =>
					condoSelectors.attachingBuilding(getState())
						? dispatch(condoActions.attachBuilding(building))
						: dispatch(
								actions.openBuilding({
									buildingId: building.id,
									projectBuildingId: building.projectBuildingId,
									coords,
									transformCoords: false,
								}),
						  ),
				onMapLocationChanged: location =>
					dispatch(actions.updateBuildingsQuery(location)),
			});
		}),
	)(getState, dispatch)
		.then(resources => {
			cache.update(over(['freerideMap'], merge(__, resources)));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};

export let initialize = () => (getState, dispatch) => {
	initMap(getState, dispatch);
};
initialize = creator('initialize', initialize);

export let openPlacesSuggestion = placeId => (getState, dispatch) => {
	geocodeGooglePlaceId(placeId)
		.then(res => {
			const {lat, lng} = res.geometry.location;
			const coord = transform([lng(), lat()], 'EPSG:4326', 'EPSG:3857');
			const {
				freerideMap: {map, markerLayer},
			} = cache.read();
			const view = map.getView();
			view.animate({center: coord, zoom: buildingFocusZoom});

			// add marker at place's location
			if (markerLayer) {
				const source = markerLayer.getSource();
				source.clear();
				addMarker({id: placeId, coord, source});
			}
		})
		.catch(describeThrow(intl.formatMessage({id: 'Search failed'})))
		.catch(catchNonFatalDefault(getState, dispatch));
};
openPlacesSuggestion = creator('openPlacesSuggestion', openPlacesSuggestion, P.String);

export let updateBuildingsQuery = prevQuery => (getState, dispatch) => {
	const currQuery = selectors.urlQuery(getState());
	replaceQuery(merge(__, currQuery));

	if (
		currQuery.encounterState !== prevQuery.encounterState ||
		currQuery.minYear !== prevQuery.minYear ||
		currQuery.maxYear !== prevQuery.maxYear ||
		currQuery.z !== prevQuery.z ||
		currQuery.buildingType !== prevQuery.buildingType ||
		currQuery.minApartments !== prevQuery.minApartments ||
		currQuery.maxApartments !== prevQuery.maxApartments
	) {
		// update buildings layer style based on new query
		const {
			freerideMap: {buildingsLayer},
		} = cache.read();

		if (buildingsLayer) {
			const {
				z,
				encounterState,
				minYear,
				maxYear,
				buildingId,
				buildingType,
				minApartments,
				maxApartments,
				projectBuildingId,
			} = currQuery;
			const org = rootSelectors.activeOrganization(getState());
			getBuildingsStyle({
				zoom: z,
				filters: {
					encounterState,
					minYear,
					maxYear,
					buildingType,
					minApartments,
					maxApartments,
				},
				buildingId,
				projectBuildingId,
				manufacturingLimit: org.meta.manufacturingLimit,
			})
				.then(style => buildingsLayer.setStyle(style))
				.catch(catchNonFatalDefault(getState, dispatch));
		}
	}
};
updateBuildingsQuery = creator('updateBuildingsQuery', updateBuildingsQuery);

export let updateMap = () => (getState, dispatch) => {
	const {
		freerideMap: {buildingsLayer, map},
	} = cache.read();

	// handle edge case where the map can get "unmounted" while the module is on background if the window size changes
	if (!map.isRendered()) {
		map.dispose();
		initMap(getState, dispatch);
	}

	if (buildingsLayer) {
		buildingsLayer.getSource().refresh();
	}
};
updateMap = creator('updateMap', updateMap);

export let selectBuilding = ({
	buildingId,
	projectBuildingId,
	coords,
	transformCoords,
	refresh,
}) => (getState, dispatch) => {
	// update selected buildingId to url query
	const currQuery = selectors.urlQuery(getState());
	pushQuery(merge(__, currQuery));

	// highlight on map
	const {
		z,
		encounterState,
		minYear,
		maxYear,
		buildingId,
		buildingType,
		minApartments,
		maxApartments,
	} = currQuery;

	const {
		freerideMap: {buildingsLayer, map},
	} = cache.read();

	if (buildingsLayer) {
		if (refresh) {
			buildingsLayer.getSource().refresh();
		}
		const org = rootSelectors.activeOrganization(getState());
		getBuildingsStyle({
			zoom: z,
			filters: {
				encounterState,
				minYear,
				maxYear,
				buildingType,
				minApartments,
				maxApartments,
			},
			buildingId,
			projectBuildingId,
			manufacturingLimit: org.meta.manufacturingLimit,
		})
			.then(style => buildingsLayer.setStyle(style))
			.catch(catchNonFatalDefault(getState, dispatch));
	}
	if (map) {
		// recalculate map viewport size
		// setTimeout for React to have enough time to rerender
		setTimeout(() => {
			map.updateSize();
			// center map to building's coords if provided
			if (coords) {
				const center = transformCoords
					? transform(coords, 'EPSG:4326', 'EPSG:3857')
					: coords;
				const view = map.getView();
				view.animate({center, duration: 200});
			}
		}, 200);
	}
};
selectBuilding = creator('selectBuilding', selectBuilding);

export let setMapSource = id => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	const {
		freerideMap: {mapLayer},
	} = cache.read();

	if (mapLayer) {
		getMapTileSource({user: rootSelectors.user(getState()), sourceId: id})
			.then(s => mapLayer.setSource(s))
			.catch(catchNonFatalDefault(getState, dispatch));
	}
};
setMapSource = creator('setMapSource', setMapSource, P.String);

export let recheckQuery = () => (getState, dispatch) => {
	const urlQueries = parseUrlQuery(getQuery());
	const buildingsQuery = selectors.buildingsQuery(getState());

	if (!equals(buildingsQuery, urlQueries.buildingsQuery)) {
		dispatch(actions._updateBuildingsQuery(urlQueries.buildingsQuery));

		const {
			freerideMap: {buildingsLayer, map},
		} = cache.read();

		// update map
		const {
			z,
			x,
			y,
			encounterState,
			minYear,
			maxYear,
			buildingType,
			maxApartments,
			minApartments,
			buildingId,
			projectBuildingId,
		} = urlQueries.buildingsQuery;

		let fetchBuilding = false;

		if (
			buildingId &&
			z === initState.buildingsQuery.z &&
			x === initState.buildingsQuery.x &&
			y === initState.buildingsQuery.y
		) {
			// Fetch coords for the building and center map on it
			fetchBuilding = true;
		}

		decorateWithNotifications(
			{id: 'update-map'},
			resolveObject({
				building: fetchBuilding ? getBuilding(buildingId) : Promise.resolve(null),
			}).then(({building}) => {
				let _projectBuildingId = projectBuildingId;
				if (building) {
					_projectBuildingId = building.projectBuildingId;
					dispatch(
						actions._updateBuildingsQuery({projectBuildingId: _projectBuildingId}),
					);
					const currQuery = selectors.urlQuery(getState());
					replaceQuery(merge(__, currQuery));
				}

				let initialZoom = z;
				let initialCenter = [x, y];
				if (building && building.location && building.location.coordinates) {
					initialZoom = buildingFocusZoom;
					initialCenter = transform(
						building.location.coordinates[0],
						'EPSG:4326',
						'EPSG:3857',
					);
				}
				if (map) {
					map.updateSize();
					const view = map.getView();
					view.animate({center: initialCenter, zoom: initialZoom, duration: 200});
				}
				if (buildingsLayer) {
					const org = rootSelectors.activeOrganization(getState());
					getBuildingsStyle({
						zoom: initialZoom,
						filters: {
							encounterState,
							minYear,
							maxYear,
							buildingType,
							minApartments,
							maxApartments,
						},
						buildingId,
						projectBuildingId: _projectBuildingId,
						manufacturingLimit: org.meta.manufacturingLimit,
					}).then(style => buildingsLayer.setStyle(style));
				}
			}),
		)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}
};
recheckQuery = creator('recheckQuery', recheckQuery);

export let destroy = () => (getState, dispatch) => {
	cache.reset();
};
destroy = creator('destroy', destroy);
