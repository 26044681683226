import * as React from 'react';

const ChatWarning = props => {
	return (
		<svg viewBox="0 0 12 12" width="1em" height="1em" {...props}>
			<g
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				transform="scale(.85714)"
			>
				<path d="M4.5 12.5l-4 1 1-3v-9a1 1 0 011-1h10a1 1 0 011 1v10a1 1 0 01-1 1zM7.5 3v3" />
				<circle cx={7.5} cy={9} r={0.5} />
			</g>
		</svg>
	);
};

export default ChatWarning;
