import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const automaticDataFetcher = creator('automaticDataFetcher');

export const clearLastCallpoolFetched = creator('clearLastCallpoolFetched');

export const fetchCallPoolAreaInfo = creator('fetchCallPoolAreaInfo');

export const setAreaActivity = creator('setAreaActivity');

export const boostCallpoolArea = creator('boostCallpoolArea');

export const removeCallpoolArea = creator('removeCallpoolArea');

export const setAutomatedFetching = creator('setAutomatedFetching');

export const openCallpoolPanels = creator('openCallpoolPanels');

export const togglePanelVisibility = creator('togglePanelVisibility');

export const openCallPoolAreaModal = creator('openCallPoolAreaModal', P.Object);

export const closeCallPoolAreaModal = creator('closeCallPoolAreaModal');

export const addAreaToCallPool = creator('addAreaToCallPool', P.Object);

export const _setCallPools = creator('_setCallPools');

export const _setCallPoolArea = creator('_setCallPoolArea');

export const _setCallPoolWithAreaInfo = creator('_setCallPoolWithAreaInfo');

export const _setCallableBuildings = creator('_setCallableBuildings');

export const _setCallPoolsAfterRemoval = creator('_setCallPoolsAfterRemoval');

export const _removingCallPoolArea = creator('_removingPoolArea', P.Number);

export const _setAllAreas = creator('_setAllAreas', P.Array);

export const _addAreaToCallPool = creator('_addAreaToCallPool');

export const _addAreaToCallPoolFailed = creator('_addAreaToCallPoolFailed');

export const _setCallPoolLoading = creator('_setCallPoolLoading', P.Number);

export const _opFailed = creator('_opFailed');
