// core dependencies
import React from 'react';
import {reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'ramda';
import {smsTemplates} from 'modules/usersApp/smsTemplatesPage/selectors';
// custom helpers & utils
import {applyDispatch, applyState} from 'utils/redux';
import {injectIntl, FormattedMessage} from '@meiko/react-intl';
import messages from 'dicts/messages';
// components
import Input from 'components/generic/Input';
import Field from 'components/generic/Field';
import Label from 'components/generic/Label';
import FieldError from 'components/generic/FieldError';
import FormBlock from 'components/generic/FormBlock';
import FieldSet from 'components/generic/FieldSet';
import TextArea from 'components/generic/TextArea';
import {getTemplateVariables, render} from 'utils/mustache';
import {smsTemplatesConfig} from 'modules/sms/constants';
import P from 'components/generic/P';

export const SMS_TEMPLATE_FORM = 'smsTemplateForm';

function validate(values, {smsTemplates = [], activeSmsTemplate, intl}) {
	const requiredMsg = intl.formatMessage({id: messages.requiredField});
	let errors = {};

	if (!values.title) errors.title = requiredMsg;
	if (!values.message) errors.message = requiredMsg;
	if (values.message && activeSmsTemplate) {
		const m = values.message;
		const config = smsTemplatesConfig.find(t => t.key === activeSmsTemplate.key);
		if (config) {
			const {variables} = config;
			try {
				const createdVariables = getTemplateVariables(m);
				createdVariables.forEach(v => {
					if (!variables.includes(v)) {
						throw new Error('Invalid field');
					}
				});
				render(
					m,
					variables.reduce((acc, cur) => ({...acc, [cur]: '#VALUE#'}), {}),
				);
			} catch (e) {
				errors.message = intl.formatMessage({id: 'Invalid field'});
			}
		}
	}

	return errors;
}

const SmsTemplateForm = ({intl, handleSubmit, activeSmsTemplate}) => {
	const config = smsTemplatesConfig.find(t => t.key === activeSmsTemplate.key);
	return (
		<form id={SMS_TEMPLATE_FORM} onSubmit={handleSubmit}>
			<FieldSet noTopMargin noBottomMargin>
				<Field
					required
					name="key"
					type="text"
					component={({input, inputId, meta}) => (
						<FormBlock>
							<Label htmlFor={inputId}>
								<FormattedMessage id="Key" />
							</Label>
							<Input {...input} id={inputId} meta={meta} block stretch disabled />
							<FieldError {...meta} />
						</FormBlock>
					)}
				/>

				<Field
					required
					name="title"
					type="text"
					component={({input, inputId, meta}) => (
						<FormBlock>
							<Label htmlFor={inputId}>
								<FormattedMessage id="Title" />
							</Label>
							<Input {...input} id={inputId} meta={meta} block stretch />
							<FieldError {...meta} />
						</FormBlock>
					)}
				/>

				<Field
					required
					name="message"
					type="text"
					component={({input, inputId, meta}) => (
						<FormBlock>
							<Label htmlFor={inputId}>
								<FormattedMessage id="Message" />
							</Label>
							<TextArea {...input} id={inputId} meta={meta} block stretch rows="5" />
							<FieldError {...meta} />
							{config?.variables && config.variables.length > 0 ? (
								<P>
									<FormattedMessage id="Available variables" />:{' '}
									<ul>
										{config.variables.map(v => (
											<>
												<li key={v}>{v}</li>
											</>
										))}
									</ul>
								</P>
							) : (
								''
							)}
						</FormBlock>
					)}
				/>
				<Field
					name="senderName"
					type="text"
					component={({input, inputId, meta}) => (
						<FormBlock>
							<Label htmlFor={inputId}>
								<FormattedMessage id="Sendername" />
							</Label>
							<Input disabled {...input} id={inputId} meta={meta} block stretch />
							<FieldError {...meta} />
						</FormBlock>
					)}
				/>
			</FieldSet>
		</form>
	);
};

SmsTemplateForm.propTypes = {
	intl: PropTypes.object.isRequired,
	smsTemplates: PropTypes.array,
};

export default compose(
	injectIntl,
	connect(
		applyState({
			smsTemplates,
		}),
		applyDispatch({}),
	),
	reduxForm({
		form: SMS_TEMPLATE_FORM,
		validate,
	}),
)(SmsTemplateForm);
