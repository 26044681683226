import {action} from 'utils/redux';
import namespace from './namespace';
import {P, Nullable} from 'utils/types';
import createDatePickActions from 'fragments/calendarResourcePicker/actions';
import createBuildingModalActions from 'fragments/buildingModalActions/actions';
import createCallReminderActions from 'fragments/callReminder/actions';

const creator = action(namespace);

export const initialize = creator('initialize', P.Number);

export const reinitialize = creator('reinitialize', P.Number);

export const destroy = creator('destroy');

export const destroyCallClientForm = creator('destroyCallClientForm');

export const saveProductStates = creator('saveProductStates', P.Record);

export const _setCondo = creator('_setCondo', P.Record);

export const _setCalendarResources = creator('_setCalendarResources', P.Record);

export const saveComment = creator('saveComment');

export const saveCondo = creator('saveCondo', P.Object);

export const removeComment = creator('removeComment', P.Number);

export const openCommentEditor = creator('openCommentEditor', P.Number);

export const selectBuilding = creator('selectBuilding', P.Object);

export const detachBuilding = creator('detachBuilding', P.Number);

export const changeTab = creator('changeTab', P.String);

export const saveActivity = creator('saveActivity');

export const _setAdditionalInfos = creator('_setAdditionalInfos');

export const _setUsers = creator('_setUsers');

export const _setProducts = creator('_setProducts');

export const _saveActivity = creator('_saveActivity');

export const _initialize = creator('_initialize');

export const _selectBuilding = creator('_selectBuilding', P.Number);

export const _buildingDetached = creator('_buildingDetached', P.Number);

export const openClientEditor = creator('openClientEditor', Nullable(P.Object));

export const removeClient = creator('removeClient');

export const saveClient = creator('saveClient');

export const openActivitiesPopup = creator('openActivitiesPopup');

export const closeActivityPopup = creator('closeActivityPopup');

export const _startOp = creator('_startOp');

export const setDateEditorOpen = creator('setDateEditorOpen');

export const updateCalendarResource = creator('updateCalendarResource');

export const _setCalendarResource = creator('_setCalendarResource');

export const _opOk = creator('_opOk');

export const _commentRemoved = creator('_commentRemoved');

export const _commentSaved = creator('_commentSaved');

export const _opFailed = creator('_opFailed');

export const _setUserTeams = creator('_setUserTeams');

export const saveProjectCall = creator('saveProjectCall');

export const saveProjectVisit = creator('saveProjectVisit');

export const openTooltip = creator('openTooltip', P.String);

export const _updateClients = creator('_updateClients', P.Object);

export const toggleNewDateCreator = creator('toggleNewDateCreator');

export const _setFreeCalRes = creator('_setFreeCalRes');

export const _condoNotFound = creator('_condoNotFound');

export const openClientSearch = creator('openClientSearch');

export const _set16100Clients = creator('_set16100Clients');

export const getClientData = creator('getClientData');

export const _setNewClient = creator('_setNewClient', P.Object);

export const _setNewManagerClients = creator('_setNewManagerClients', P.Object);

export const closeClientSearch = creator('closeClientSearch');

export const _setBuilding = creator('_setBuilding');

export const removeCondo = creator('removeCondo');

export const _removeCondo = creator('_removeCondo');

export const toggleAttachingBuilding = creator('toggleAttachingBuilding');

export const attachBuilding = creator('attachBuilding', P.Object);

export const _attachBuilding = creator('_attachBuilding');

export const setDisableReinit = creator('setDisableReinit', P.Bool);

export const removeManager = creator('removeManager', P.String);

export const clearNewManagerClients = creator('clearNewManagerClients');

export const importClientAsManager = creator('importClientAsManager', P.Object);

export const setCondoEditorOpen = creator('setCondoEditorOpen', P.Boolean);

export const _saveCondo = creator('_saveCondo');

export const _setOpenedAt = creator('_setOpenedAt', P.Date);

export const _setActiveCallPool = creator('_setActiveCallPool', P.Object);

export const skip = creator('skip');

export const _setFetchingNextBuilding = creator('_setFetchingNextBuilding', P.Boolean);

export const _setLoading = creator('_setLoading', P.Boolean);

const datePickActions = createDatePickActions({namespace});

export const {
	selectCalendarResource,
	_calendarResourceSelected,
	_calendarResourceSelectionFailed,
	_calendarResourceReserved,
	_calendarResourceReleased,
} = datePickActions;

const buildingModalActions = createBuildingModalActions({namespace});

export const {
	createOtherAddress,
	setPrimaryAddress,
	removeOtherAddress,
	saveBuildingData,
	removeBuilding,
	_updateBuilding,
	toggleBuildingModal,
	_setBuildingModalProcessing,
} = buildingModalActions;

const callReminderActions = createCallReminderActions({namespace});

export const {
	createCallReminder,
	createCalendarCallReminder,
	updateCallReminder,
	removeCallReminder,
	toggleCallReminderEditor,
	setReminderSeen,
	_setCalendarCallReminderSeen,
	_setCallReminder,
	_setCallReminderLoading,
	_setCallReminders,
	_setCallRemindersLoading,
	_setCalendarCallReminder,
} = callReminderActions;
