import {scopedHandler} from 'utils/redux';
import {getFormValues} from 'redux-form';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {formatOrganizationFormOutput} from './utils';
import {sort, ascend, prop} from 'ramda';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.toggleOrganizationEditor.type: {
			return [
				{
					...state,
					selectedOrganization: payload,
					organizationEditorOpen: !state.organizationEditorOpen,
				},
				null,
			];
		}

		case actions.updateOrganization.type: {
			const form = getFormValues('organizationForm')(fullState) || {};
			const {organization: organizationData} = formatOrganizationFormOutput({
				form,
				organization: state.selectedOrganization ? state.selectedOrganization : null,
			});
			const organization = {
				...organizationData,
				id: state.selectedOrganization ? state.selectedOrganization.id : null,
			};

			return [
				{...state, organizationEditorOpen: false},
				effects.updateOrganization({organization}),
			];
		}

		case actions._setOrganizations.type: {
			const {data} = payload;
			const organizations = sort(ascend(prop('title')), data);
			const newState = {
				...state,
				organizations,
				organizationsLoading: false,
			};
			return [newState, null];
		}

		case actions._setProducts.type: {
			const {data} = payload;
			const products = sort(ascend(prop('title')), data);
			const newState = {
				...state,
				products,
			};
			return [newState, null];
		}

		case actions._setProcessing.type: {
			return [{...state, processing: payload, organizationEditorOpen: payload}, null];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
