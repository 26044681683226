import * as actions from './actions';
import {effect} from 'utils/redux';
import namespace from './namespace';
import {decorateWithNotifications} from 'io/app';
import {catchNonFatalDefault} from 'io/errors';
import {getMetrics, getLogs, getFeedback} from './io';

const creator = effect(namespace);

export let initialize = () => (getState, dispatch) => {
	decorateWithNotifications(
		{id: 'init-dashboard', failureStyle: 'error'},
		Promise.all([
			getMetrics().then(metrics => dispatch(actions._setMetrics(metrics))),
			getLogs({_limit: 5, logModel: 'piimega', status: 'Error'}).then(({data}) =>
				dispatch(actions._setPiimegaLogs(data)),
			),
			getFeedback({_limit: 5}).then(({data}) => dispatch(actions._setFeedback(data))),
		]),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);
