import {pipe, prop, map, path} from 'ramda';
import services from 'services';
import {describeThrow, describeError} from 'utils/errors';
import * as normalize from 'utils/normalize';
import {getResponseData} from 'utils/app';
import {timeEntryQueryBase, userTeamsInclude} from './constants';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getUsers = query =>
	httpJson('get', '/users', {
		_limit: 999,
		withRecentlyDeleted: true,
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load users'})))
		.then(pipe(prop('data'), map(normalize.user)));

export const getUserTeams = () =>
	httpJson('get', '/users/me', {include: userTeamsInclude})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load users'})))
		.then(getResponseData(pipe(path(['teams', 'data']), map(normalize.team))));

export const getTimeEntries = query =>
	httpJson('get', '/timeEntries', {
		...timeEntryQueryBase,
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load working hours'})))
		.then(pipe(prop('data'), map(normalize.timeEntry)));

export const postTimeEntry = entry =>
	httpJson('post', '/timeEntries', {}, {body: entry})
		.catch(e => {
			const conflict = !!e.response && e.response.status === 500;
			e.causedByTimeEntryConflict = conflict;
			return conflict
				? e.response.json().then(body => describeThrow(body.message, e))
				: Promise.reject(describeError(msgs.saveFailed, e));
		})
		.then(pipe(prop('data'), normalize.timeEntry));

export const removeEntry = id =>
	httpJson('delete', `/timeEntries/${id}`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to delete working time'})),
	);

export const updateEntry = entry =>
	httpJson('put', `/timeEntries/${entry.id}`, {}, {body: entry})
		.catch(e => {
			const conflict = !!e.response && e.response.status === 500;
			e.causedByTimeEntryConflict = conflict;
			return conflict
				? e.response.json().then(body => describeThrow(body.message, e))
				: Promise.reject(describeError(msgs.saveFailed, e));
		})
		.then(pipe(prop('data')));
