import {action} from 'utils/redux';
import {P} from 'utils/types';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');
export const _initialize = creator('_initialize');

export const destroy = creator('destroy');

export const getSmsTemplates = creator('getSmsTemplates');
export const _getSmsTemplates = creator('_getSmsTemplates', P.Array);

export const openSmsTemplate = creator('openSmsTemplate', P.Object);

export const openSmsTemplateModal = creator('openSmsTemplateModal');
export const closeSmsTemplateModal = creator('closeSmsTemplateModal');

export const createSmsTemplate = creator('createSmsTemplate');

export const updateSmsTemplate = creator('updateSmsTemplate', P.Number);

export const deleteSmsTemplate = creator('deleteSmsTemplate', P.Number);

export const setShowDeleted = creator('setShowDeleted', P.Boolean);
export const _setShowDeleted = creator('_setShowDeleted', P.Boolean);

export const restoreSmsTemplate = creator('restoreSmsTemplate', P.Number);

export const recheckQuery = creator('recheckQuery');

export const _setAvailableTags = creator('_setAvailableTags', P.Array);
