import {formatISO, startOfWeek, endOfWeek} from 'date-fns';
import {evolve, pick} from 'ramda';
import {parseNullableString} from 'utils/fields';

export const normalizeDashboardFilterFormValues = (query, props) => {
	const keys = ['startsAt', 'endsAt', 'organizationId', 'teamId', 'userId'];
	const toIsoDate = date => formatISO(date, {representation: 'date'});
	const queryValues = pick(keys, {
		// Defaults
		startsAt: null,
		endsAt: null,
		teamId: '',
		userId: '',
		organizationId: '',
		...query,
		...props,
	});

	const toIdArray = val =>
		(parseNullableString(val) ?? '')
			.trim()
			.split(',')
			.filter(v => !!v)
			.map(Number);

	const vals = evolve(
		{
			startsAt: val =>
				val ||
				toIsoDate(
					startOfWeek(new Date(), {
						weekStartsOn: 1,
					}),
				),
			endsAt: val =>
				val ||
				toIsoDate(endOfWeek(new Date()), {
					weekStartsOn: 1,
				}),
			teamId: toIdArray,
			userId: toIdArray,
			organizationId: toIdArray,
		},
		queryValues,
	);
	return vals;
};
