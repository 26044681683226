import {scopedHandler} from 'utils/redux';
import {getFormValues} from 'redux-form';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {
	formatCallPoolCreatorOutput,
	formatCustomAreaCreatorFormOutput,
	formatProducts,
	addOrRemoveToSelection,
} from './utils';
import * as rootSelectors from 'modules/common/selectors';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, effects.destroy()];
		}

		case actions.openPlacesSuggestion.type: {
			return [state, effects.openPlacesSuggestion(payload)];
		}

		case actions.updateMapQuery.type: {
			const newState = {
				...state,
				mapQuery: {...state.mapQuery, ...payload},
			};

			return [newState, effects.updateMapQuery(state.mapQuery)];
		}

		case actions.setLayerVisibility.type: {
			const newState = {
				...state,
				mapQuery: {...state.mapQuery, [payload.layer]: payload.visible},
			};

			return [newState, effects.setLayerVisibility(payload)];
		}

		case actions.setActiveCallPool.type: {
			const newState = {
				...state,
				mapQuery: {...state.mapQuery, activeCallPoolId: payload},
				activeCallPoolLoading: true,
			};

			return [newState, effects.updateActiveCallPool()];
		}

		case actions.clearActiveCallPool.type: {
			const newState = {
				...state,
				mapQuery: {
					...state.mapQuery,
					activeCallPoolId: initState.mapQuery.activeCallPoolId,
				},
				activeCallPool: initState.activeCallPool,
				activeCallPoolLoading: false,
				selectionInfoTab: initState.selectionInfoTab,
			};

			return [newState, effects.updateActiveCallPool()];
		}

		case actions.boostCallPoolArea.type: {
			return [state, effects.boostCallPoolArea(payload)];
		}

		case actions.removeCallPoolArea.type: {
			return [state, effects.removeCallPoolArea(payload)];
		}

		case actions.setSelection.type: {
			const newState = {
				...state,
				mapQuery: {
					...state.mapQuery,
					selectionIds: [payload.selectionId],
					selectionType: payload.selectionType,
				},
				selectionLoading: true,
				selectionInfoTab: initState.selectionInfoTab,
				mobileSidebarOpen: true,
			};

			return [newState, effects.updateSelection(state.mapQuery)];
		}

		case actions.setAreaMultiSelection.type: {
			// just replace selection if area wasn't selected previously
			const selectionIds =
				state.mapQuery.selectionType !== 'area'
					? [payload.selectionId]
					: addOrRemoveToSelection(state.mapQuery.selectionIds, payload.selectionId);

			// clear selection if we deselected last area
			const clearSelection = !selectionIds.length;

			const newState = {
				...state,
				mapQuery: {
					...state.mapQuery,
					selectionIds,
					selectionType: clearSelection
						? initState.mapQuery.selectionType
						: payload.selectionType,
				},
				selection: clearSelection ? initState.selection : state.selection,
				selectionLoading: clearSelection ? false : true,
				mobileSidebarOpen: clearSelection ? false : true,
				selectionInfoTab: initState.selectionInfoTab,
			};

			return [newState, effects.updateSelection(state.mapQuery)];
		}

		case actions.clearSelection.type: {
			const newState = {
				...state,
				mapQuery: {
					...state.mapQuery,
					selectionIds: initState.mapQuery.selectionIds,
					selectionType: initState.mapQuery.selectionType,
				},
				selection: initState.selection,
				selectionLoading: false,
				selectionInfoTab: initState.selectionInfoTab,
			};

			return [newState, effects.updateSelection(state.mapQuery)];
		}

		case actions.openMassEditor.type: {
			return [state, effects.openMassEditor(payload)];
		}

		case actions.addAreasToCallPool.type: {
			const form = getFormValues('callPoolAreaForm')(fullState) || {};
			const callPool = state.activeCallPool;
			const areas = state.selection.ids.map(id => ({id, ...form}));

			return [
				{...state, processing: true},
				effects.addAreasToCallPool({areas, callPool}),
			];
		}

		case actions.createIssue.type: {
			return [{...state, processing: true}, effects.createIssue(payload)];
		}

		case actions.removeIssue.type: {
			return [state, effects.removeIssue(payload)];
		}

		case actions.saveCallPoolArea.type: {
			return [state, effects.saveCallPoolArea(payload)];
		}

		case actions.setSelectionInfoTab.type: {
			return [{...state, selectionInfoTab: payload}, null];
		}

		case actions.openOffersModal.type: {
			return [
				{...state, offersModalOpen: true, offersLoading: true},
				effects.getOffers({areaId: state.selection.ids[0], _page: 1}),
			];
		}

		case actions.closeOffersModal.type: {
			const newState = {
				...state,
				offersModalOpen: false,
				offersLoading: false,
				offers: initState.offers,
				offersPagination: initState.offersPagination,
			};

			return [newState, null];
		}

		case actions.changeOffersPage.type: {
			return [
				{...state, offersLoading: true},
				effects.getOffers({areaId: state.selection.ids[0], _page: payload}),
			];
		}

		case actions.openOffer.type: {
			return [{...state, offersModalOpen: false}, effects.openOffer(payload)];
		}

		case actions.updateActiveCallPoolAreas.type: {
			return [state, effects.updateActiveCallPoolAreas(payload)];
		}

		case actions.startDrawArea.type: {
			return [
				{...state, drawing: true, drawType: payload},
				effects.startDrawArea(payload),
			];
		}

		case actions.clearDraw.type: {
			return [state, effects.clearDraw()];
		}

		case actions.removeLastPoint.type: {
			return [state, effects.removeLastPoint()];
		}

		case actions.toggleCustomAreaCreator.type: {
			return [{...state, customAreaCreatorOpen: !state.customAreaCreatorOpen}, null];
		}

		case actions.createCustomArea.type: {
			const form = getFormValues('customAreaCreatorForm')(fullState) || {};
			const data = formatCustomAreaCreatorFormOutput(form, state.customDrawArea);

			return [{...state, customAreaCreatorOpen: false}, effects.createCustomArea(data)];
		}

		case actions.removeCustomArea.type: {
			return [state, effects.removeCustomArea(payload)];
		}

		case actions.toggleCallPoolCreator.type: {
			const newState = {
				...state,
				callPoolCreatorOpen: !state.callPoolCreatorOpen,
			};

			return [newState, null];
		}

		case actions.removeCallPool.type: {
			return [state, effects.removeCallPool(payload)];
		}

		case actions.saveCallPool.type: {
			const form = getFormValues('callPoolCreatorForm')(fullState) || {};

			const data = formatCallPoolCreatorOutput(form, state.smartDataOptions);

			const id = state.activeCallPool ? state.activeCallPool.id : null;

			return [{...state, callPoolCreatorOpen: false}, effects.saveCallPool({id, data})];
		}

		case actions.searchBuildings.type: {
			return [state, effects.searchBuildings(payload)];
		}

		case actions.showBuildingOnMap.type: {
			const building = payload;
			const coords =
				building.location &&
				building.location.coordinates &&
				building.location.coordinates[0];

			const newState = {
				...state,
				mapQuery: {
					...state.mapQuery,
					selectionIds: [building.id],
					selectionType: 'building',
				},
				selectionInfoTab: initState.selectionInfoTab,
			};

			return [newState, effects.showBuildingOnMap({coords, prevQuery: state.mapQuery})];
		}

		case actions.setMapSource.type: {
			return [
				{...state, mapQuery: {...state.mapQuery, mapSource: payload}},
				effects.setMapSource(payload),
			];
		}

		case actions.updateMapBuildings.type: {
			return [state, effects.updateMapBuildings()];
		}

		case actions.openAreaSettingsModal.type: {
			return [{...state, areaSettingsModalOpen: true}, null];
		}

		case actions.closeAreaSettingsModal.type: {
			return [{...state, areaSettingsModalOpen: false}, null];
		}

		case actions.callBuilding.type: {
			return [state, effects.callBuilding(payload)];
		}

		case actions.editBuilding.type: {
			return [state, effects.editBuilding(payload)];
		}

		case actions.openEncounterModal.type: {
			return [state, effects.getEncounterData(payload)];
		}

		case actions.closeEncounterModal.type: {
			return [
				{...state, activeEncounterSource: null, activeEncounterSourceType: null},
				null,
			];
		}

		case actions.toggleMobileSidebar.type: {
			const mobileSidebarOpen = !state.mobileSidebarOpen;
			return [{...state, mobileSidebarOpen}, null];
		}

		case actions.openProfinderListCreator.type: {
			return [
				{...state, profinderListCreatorOpen: true, creatingProfinderList: false},
				null,
			];
		}

		case actions.closeProfinderListCreator.type: {
			return [
				{
					...state,
					profinderListCreatorOpen: false,
					creatingProfinderList: payload,
				},
				null,
			];
		}

		case actions.deleteProfinderList.type: {
			return [state, effects.deleteProfinderList(payload)];
		}

		case actions._updateMapQuery.type: {
			const newState = {
				...state,
				mapQuery: {...state.mapQuery, ...payload},
			};

			return [newState, null];
		}

		case actions._setCallPools.type: {
			return [{...state, callPools: payload, callPoolsLoading: false}, null];
		}

		case actions._setActiveCallPool.type: {
			return [{...state, activeCallPool: payload, activeCallPoolLoading: false}, null];
		}

		case actions._setActiveCallPoolLoading.type: {
			return [{...state, activeCallPoolLoading: payload}, null];
		}

		case actions._addAreasToCallPool.type: {
			const newState = {
				...state,
				areaSettingsModalOpen: false,
				activeCallPool: {
					...state.activeCallPool,
					areas: [...state.activeCallPool.areas, ...payload],
				},
			};

			return [newState, null];
		}

		case actions._removeCallPoolArea.type: {
			const areas = state.activeCallPool.areas.filter(area => area.id !== payload);
			const newState = {
				...state,
				activeCallPool: {...state.activeCallPool, areas},
				selectionInfoTab: initState.selectionInfoTab,
			};

			return [newState, null];
		}

		case actions._setSelection.type: {
			return [{...state, selection: payload, selectionLoading: false}, null];
		}

		case actions._setSelectionLoading.type: {
			return [{...state, selectionLoading: payload}, null];
		}

		case actions._setIssues.type: {
			return [{...state, issues: payload}, null];
		}

		case actions._createIssue.type: {
			return [{...state, issues: [...state.issues, payload], processing: false}];
		}

		case actions._removeIssue.type: {
			const issues = state.issues.filter(i => i.id !== payload);
			const newState = {...state, issues, processing: false};

			return [newState, null];
		}

		case actions._setIssueCSV.type: {
			const newIssues = state.issues.map(i => {
				if (i.id === payload.issueId) {
					return {...i, fileUrl: payload.csvUrl};
				}
				return i;
			});

			return [{...state, issues: newIssues}, null];
		}

		case actions._setCallableBuildings.type: {
			const newAreas = state.activeCallPool.areas.map(a => {
				if (a.id === payload.areaId) {
					return {
						...a,
						callableBuildings: payload.countOfCallableBuildings,
						callableBuildingsLoading: false,
					};
				}
				return a;
			});

			const newState = {
				...state,
				activeCallPool: {
					...state.activeCallPool,
					areas: newAreas,
				},
			};

			return [newState, null];
		}

		case actions._setCallableBuildingsLoading.type: {
			const newAreas = state.activeCallPool.areas.map(a => {
				if (a.id === payload) {
					return {...a, callableBuildingsLoading: true};
				}
				return a;
			});

			const newState = {
				...state,
				activeCallPool: {
					...state.activeCallPool,
					areas: newAreas,
				},
			};

			return [newState, null];
		}

		case actions._setAllCallableBuildingsLoading.type: {
			const newAreas = state.activeCallPool.areas.map(a => ({
				...a,
				callableBuildingsLoading: true,
			}));

			const newState = {
				...state,
				activeCallPool: {
					...state.activeCallPool,
					areas: newAreas,
				},
			};

			return [newState, null];
		}

		case actions._saveCallPoolArea.type: {
			const newAreas = state.activeCallPool.areas.map(a => {
				if (a.id === payload.id) {
					return payload;
				}
				return a;
			});

			const newState = {
				...state,
				activeCallPool: {
					...state.activeCallPool,
					areas: newAreas,
				},
			};

			return [newState, null];
		}

		case actions._setTeams.type: {
			return [{...state, teams: payload, teamsLoading: false}, null];
		}

		case actions._setOffers.type: {
			const newState = {
				...state,
				offers: payload.data,
				offersPagination: payload.pagination,
				offersLoading: false,
			};

			return [newState, null];
		}

		case actions._setCallPoolArea.type: {
			const newAreas = state.activeCallPool.areas.map(a => {
				if (a.id === payload.id) {
					return {...a, ...payload};
				}
				return a;
			});

			const newState = {
				...state,
				activeCallPool: {
					...state.activeCallPool,
					areas: newAreas,
				},
			};

			return [newState, null];
		}

		case actions._updateActiveCallPoolAreas.type: {
			const newState = {
				...state,
				activeCallPool: {
					...state.activeCallPool,
					areas: payload,
				},
			};

			return [newState, null];
		}

		case actions._setProducts.type: {
			const {activeOrganizationProducts, otherOrganizationProducts} = formatProducts({
				products: payload,
				activeOrganizationId: rootSelectors.activeOrganizationId(fullState),
			});

			return [
				{...state, products: activeOrganizationProducts, otherOrganizationProducts},
				null,
			];
		}

		case actions._removeCallPool.type: {
			const callPools = state.callPools.filter(c => c.id !== payload);
			const newState = {
				...state,
				callPools,
				callPoolCreatorOpen: false,
				processing: false,
			};

			return [newState, null];
		}

		case actions._updateCallPool.type: {
			const newCallPools = state.callPools.map(c => {
				if (c.id === payload.id) {
					return {...c, ...payload};
				}
				return c;
			});

			return [{...state, callPools: newCallPools}, null];
		}

		case actions._addCallPool.type: {
			const newState = {
				...state,
				callPools: [...state.callPools, payload],
			};

			return [newState, null];
		}

		case actions._updateActiveCallPoolData.type: {
			return [{...state, activeCallPool: {...state.activeCallPool, ...payload}}, null];
		}

		case actions._clearActiveCallPool.type: {
			const newState = {
				...state,
				mapQuery: {
					...state.mapQuery,
					activeCallPoolId: initState.mapQuery.activeCallPoolId,
				},
				activeCallPool: initState.activeCallPool,
				activeCallPoolLoading: false,
				selectionInfoTab: initState.selectionInfoTab,
			};

			return [newState, null];
		}

		case actions._setActiveCallPoolQuery.type: {
			const newState = {
				...state,
				mapQuery: {...state.mapQuery, activeCallPoolId: payload},
				activeCallPoolLoading: true,
			};

			return [newState, null];
		}

		case actions._endDrawArea.type: {
			const newState = {
				...state,
				customDrawArea: payload,
				drawEndVisible: true,
				showDrawMenu: false,
			};

			return [newState, null];
		}

		case actions._endDrawing.type: {
			return [{...state, drawing: false}, null];
		}

		case actions._clearDraw.type: {
			const newState = {
				...state,
				customDrawArea: null,
				drawEndVisible: false,
				showDrawMenu: false,
			};

			return [newState, null];
		}

		case actions._setShowDrawMenu.type: {
			return [{...state, showDrawMenu: payload}, null];
		}

		case actions._setLayerVisibility.type: {
			const newState = {
				...state,
				mapQuery: {...state.mapQuery, [payload.layer]: payload.visible},
			};

			return [newState, null];
		}

		case actions._clearSelection.type: {
			const newState = {
				...state,
				mapQuery: {
					...state.mapQuery,
					selectionIds: initState.mapQuery.selectionIds,
					selectionType: initState.mapQuery.selectionType,
				},
				selection: initState.selection,
				selectionLoading: false,
				selectionInfoTab: initState.selectionInfoTab,
			};

			return [newState, null];
		}

		case actions._setBuildings.type: {
			return [{...state, buildings: payload}, null];
		}

		case actions._setEncounters.type: {
			return [{...state, encounters: payload}, null];
		}

		case actions._setActiveEncounterSource.type: {
			return [
				{
					...state,
					activeEncounterSource: payload.source,
					activeEncounterSourceType: payload.sourceType,
				},
				null,
			];
		}

		case actions._setOrganizations.type: {
			return [
				{
					...state,
					organizations: payload.filter(
						o => o.id !== rootSelectors.activeOrganizationId(fullState),
					),
				},
				null,
			];
		}

		case actions._setSmartDataOptions.type: {
			return [
				{...state, smartDataOptions: payload, smartDataOptionsLoading: false},
				null,
			];
		}

		case actions._setProfinderListsProcessing.type: {
			return [{...state, profinderListsProcessing: payload}, null];
		}

		case actions._setCreatingProfinderList.type: {
			return [{...state, creatingProfinderList: false}, null];
		}

		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false}, null];
		}

		case actions._setAvailableTags.type: {
			return [{...state, availableTags: payload}, null];
		}

		case actions.fetchUsersByTeamId.type: {
			return [
				{
					...state,
					usersByTeamIdProcessing: true,
				},
				effects.fetchUsersByTeamId(payload),
			];
		}

		case actions._fetchUsersByTeamId.type: {
			const {id, users} = payload;
			return [
				{
					...state,
					usersByTeamIdProcessing: false,
					usersByTeamId: {
						...state.usersByTeamId,
						[id]: users,
					},
				},
				null,
			];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
