export const calendarResourceInclude =
	'client.building,salesman,salesmanVisit.user,salesmanVisit.products,salesmanVisit.secondUser,areas,reserver.roles,building,reservation.encounter.source.formFill.formAttributes,reservation.calendarResource';

export const calResQueryBase = {
	_limit: 999,
	searchByPreviousDates: true,
};

export const calendarResourceEncounterInclude =
	'reservation.encounter.building,reservation.encounter.source.formFill.formAttributes,reservation.encounter.source.user,reservation.encounter.source.surveyFills';

export const userTeamsInclude = 'teams.users.roles,teams.areas:allAreas(true)';

export const salesUserTypes = ['salesman', 'salesmanager'];
