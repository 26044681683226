import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {getFormValues} from 'redux-form';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions._setStats.type: {
			return [{...state, stats: payload, statsLoading: false}, null];
		}

		case actions._setTargets.type: {
			return [{...state, targets: payload, targetsLoading: false}, null];
		}

		case actions._setCallReminders.type: {
			return [{...state, callReminders: payload, callRemindersLoading: false}, null];
		}

		case actions.startCalling.type: {
			const callPoolId = payload;
			return [{...state, startingCall: true}, effects.startCalling(callPoolId)];
		}

		case actions.openTarget.type: {
			const targetOpen = payload;
			return [{...state, targetOpen}, null];
		}

		case actions.saveCallTarget.type: {
			const form = getFormValues(`targetForm${state.targetOpen}`)(fullState);
			const target = {
				...form,
				type: 'call',
				timespan: state.targetOpen,
				id: state.targets[state.targetOpen] ? state.targets[state.targetOpen].id : null,
			};

			return [state, effects.saveCallTarget(target)];
		}

		case actions.openTimeEntryModal.type: {
			const p2 = n => (n < 10 ? `0${n}` : n);

			const formatDateTime = te => {
				const formattedTime =
					p2(new Date(te).getHours()) + ':' + p2(new Date(te).getMinutes());
				return formattedTime;
			};

			const timeEntry = {
				...payload,
				startTime: formatDateTime(payload.startTime),
				endTime: formatDateTime(payload.endTime),
			};

			return [{...state, timeEntry, timeEntryModalOpen: true}, null];
		}

		case actions.closeTimeEntryModal.type: {
			return [{...state, timeEntryModalOpen: false}, null];
		}

		case actions.saveTimeEntry.type: {
			const form = getFormValues('timeEntryForm')(fullState);
			const startTimes = form.startTime.split(':');
			const endTimes = form.endTime.split(':');

			const startTime = new Date().setHours(startTimes[0], startTimes[1]);
			const endTime = new Date().setHours(endTimes[0], endTimes[1]);
			const te = {
				...state.timeEntry,
				...form,
				endTime: new Date(endTime),
				startTime: new Date(startTime),
			};

			return [{...state, processingTimeEntries: true}, effects.saveTimeEntry(te)];
		}

		case actions.deleteTimeEntry.type: {
			return [state, effects.removeTimeEntry(payload)];
		}

		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false, startingCall: false}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false, processingTimeEntries: false}, null];
		}

		case actions._updateTargets.type: {
			const targets = {
				...state.targets,
				[payload.timespan]: {
					...state.targets[payload.timespan],
					id: payload.id,
					target: payload.target,
				},
			};

			const newState = {...state, targetOpen: null, targets};
			return [newState, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
