import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {createSelector} from 'reselect';
import {formatFileFormInput, formatCategoryFormInput} from './utils';
import * as commonSelectors from 'modules/common/selectors';
import * as perms from 'utils/perms';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const processing = pipe(root, prop('processing'));

export const categories = pipe(root, prop('categories'));

export const categoriesLoading = pipe(root, prop('categoriesLoading'));

export const fileModalOpen = pipe(root, prop('fileModalOpen'));

export const categoryModalOpen = pipe(root, prop('categoryModalOpen'));

export const roles = pipe(root, prop('roles'));

export const fileInEdit = pipe(root, prop('fileInEdit'));

export const categoryInEdit = pipe(root, prop('categoryInEdit'));

export const fileFormInitValues = createSelector([fileInEdit], fileInEdit =>
	formatFileFormInput(fileInEdit),
);

export const categoryFormInitValues = createSelector([categoryInEdit], categoryInEdit =>
	formatCategoryFormInput(categoryInEdit),
);

export const canManageFiles = createSelector([commonSelectors.user], user =>
	user ? perms.filesManagement(user) : false,
);

export const organizations = pipe(root, prop('organizations'));
