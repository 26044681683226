import services from 'services';
import {prop} from 'ramda';
import {describeThrow} from 'utils/errors';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const updateVisitTarget = target =>
	httpJson('put', `/targets/${target.id}`, {}, {body: target})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save goal'})))
		.then(prop('data'));

export const postVisitTarget = target =>
	httpJson('post', '/targets', {}, {body: target})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save goal'})))
		.then(prop('data'));
