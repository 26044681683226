import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {formatAddBuildingFormOutput} from './utils';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, effects.destroy()];
		}

		case actions.openPlacesSuggestion.type: {
			return [
				{
					...state,
					addBuildingMenuOpen: false,
					newBuildingCoords: initState.newBuildingCoords,
				},
				effects.openPlacesSuggestion(payload),
			];
		}

		case actions.updateBuildingsQuery.type: {
			const newState = {
				...state,
				buildingsQuery: {...state.buildingsQuery, ...payload},
			};

			return [newState, effects.updateBuildingsQuery(state.buildingsQuery)];
		}

		case actions.updateMap.type: {
			return [state, effects.updateMap()];
		}

		case actions.toggleTracking.type: {
			return [state, effects.toggleTracking()];
		}

		case actions.setMapSource.type: {
			return [
				{...state, buildingsQuery: {...state.buildingsQuery, mapSource: payload}},
				effects.setMapSource(payload),
			];
		}

		case actions.openBuilding.type: {
			const newState = {
				...state,
				buildingsQuery: {
					...state.buildingsQuery,
					selectionId: payload.buildingId,
				},
			};

			return [newState, effects.openBuilding(payload)];
		}

		case actions.closeBuilding.type: {
			const newState = {
				...state,
				buildingsQuery: {
					...state.buildingsQuery,
					selectionId: null,
				},
			};

			return [newState, effects.openBuilding({buildingId: null, coords: null})];
		}

		case actions.addBuildingMarker.type: {
			return [{...state, addBuildingMenuOpen: true}, effects.addBuildingMarker()];
		}

		case actions.removeBuildingMarker.type: {
			return [
				{
					...state,
					addBuildingMenuOpen: false,
					newBuildingCoords: initState.newBuildingCoords,
				},
				effects.removeBuildingMarker(),
			];
		}

		case actions.openAddBuildingModal.type: {
			return [{...state, addBuildingModalOpen: true}, null];
		}

		case actions.closeAddBuildingModal.type: {
			return [{...state, addBuildingModalOpen: false}, null];
		}

		case actions.addBuilding.type: {
			const building = formatAddBuildingFormOutput({
				form: payload,
				coords: state.newBuildingCoords,
			});

			return [{...state, processing: true}, effects.addBuilding(building)];
		}

		case actions.setLayerVisibility.type: {
			const newState = {
				...state,
				buildingsQuery: {...state.buildingsQuery, [payload.layer]: payload.visible},
			};

			return [newState, effects.setLayerVisibility(payload)];
		}

		case actions._updateBuildingsQuery.type: {
			const newState = {
				...state,
				buildingsQuery: {...state.buildingsQuery, ...payload},
			};

			return [newState, null];
		}

		case actions._setFollowingGeolocation.type: {
			return [{...state, followingGeolocation: payload}, null];
		}

		case actions._setGeolocationLoading.type: {
			return [{...state, geolocationLoading: payload}, null];
		}

		case actions._setNewBuildingCoords.type: {
			return [{...state, newBuildingCoords: payload}, null];
		}

		case actions._addBuilding.type: {
			return [
				{
					...state,
					processing: false,
					addBuildingModalOpen: false,
					addBuildingMenuOpen: false,
					newBuildingCoords: initState.newBuildingCoords,
				},
				null,
			];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._mapInitialized.type: {
			return [{...state, mapInitialized: true}, null];
		}

		case actions.getAvailableTags.type: {
			return [state, effects.getAvailableTags()];
		}

		case actions._getAvailableTags.type: {
			return [
				{
					...state,
					availableTags: payload,
				},
				null,
			];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
