import {scopedHandler} from 'utils/redux';
import {getFormValues} from 'redux-form';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {mergeLeft} from 'ramda';
import {over} from 'utils/lenses';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {formatUserFormOutput, formatSearchOutput, formatExcelOutput} from './utils';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions._updateUsersQuery.type: {
			const newState = {
				...state,
				usersLoading: true,
				usersQuery: {...state.usersQuery, ...payload},
			};

			return [newState, null];
		}

		case actions.changePage.type: {
			const newState = {
				...state,
				usersQuery: {...state.usersQuery, _page: payload},
				usersLoading: true,
			};
			return [newState, effects.updateUsers()];
		}

		case actions.clearForm.type: {
			return [state, effects.clearForm(payload)];
		}

		case actions.cancelExcelEditor.type: {
			return [{...state, excelEditorOpen: false}];
		}

		case actions.openExcelEditor.type: {
			return [{...state, excelEditorOpen: true}];
		}

		case actions.getExcel.type: {
			const form = getFormValues('excelForm')(fullState);
			const roles = formatExcelOutput({form});
			return [{...state, processing: true}, effects.fetchExcel(roles)];
		}

		case actions.setShowDeleted.type: {
			const newState = {
				...state,
				usersQuery: {...state.usersQuery, trashed: payload, _page: 1},
				showDeleted: payload,
				usersLoading: true,
			};

			return [newState, effects.updateUsers()];
		}

		case actions.initUserEditor.type: {
			return [{...state, activeUser: payload, userCreatorOpen: true}, null];
		}

		case actions.cancelUserEditor.type: {
			return [{...state, activeUser: null, userCreatorOpen: false}, null];
		}

		case actions.saveUser.type: {
			const form = getFormValues('userForm')(fullState);
			const {user: userData} = formatUserFormOutput({form});

			const user = {...userData, id: state.activeUser ? state.activeUser.id : null};
			return [{...state, processing: true}, effects.saveUser({id: user.id, user})];
		}

		case actions.reviveUser.type: {
			const id = state.activeUser.id;
			return [{...state, processing: true}, effects.restoreUser({id: id})];
		}
		case actions.deleteUser.type: {
			const id = state.activeUser.id;
			return [{...state}, effects.removeUser({id: id})];
		}

		case actions.searchUsers.type: {
			const form = getFormValues('searchForm')(fullState);
			const trashed = state.showDeleted;
			const searchQuery = formatSearchOutput({form});
			const newState = over(['usersQuery'], mergeLeft(searchQuery), {
				...state,
				usersLoading: true,
				processing: true,
				usersQuery: {...state.usersQuery, _page: 1},
			});
			const newSearchQuery = trashed ? {trashed: trashed, ...searchQuery} : searchQuery;

			return [newState, effects.searchUsers(newSearchQuery)];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._toggleUserCreator.type: {
			return [{...state, userCreatorOpen: !state.userCreatorOpen}, null];
		}

		case actions._setUsers.type: {
			const {data, pagination} = payload;
			const newState = {
				...state,
				users: data,
				usersPagination: pagination,
				usersLoading: false,
			};
			return [newState, null];
		}

		case actions._newUserSaved.type: {
			return [{...state, processing: false, userCreatorOpen: false}, null];
		}

		case actions._setInitMeta.type: {
			return [{...state, ...payload}, null];
		}

		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false, userCreatorOpen: false}, null];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		case actions.createEniocallerDetails.type: {
			return [{...state}, effects.createEniocallerDetails(payload)];
		}
		case actions.deleteEniocallerDetails.type: {
			return [{...state}, effects.deleteEniocallerDetails(payload)];
		}
		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
