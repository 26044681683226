import React from 'react';

const SvgNoticeAlert = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M5.992.001a6.103 6.103 0 0 0-4.255 1.827A5.9 5.9 0 0 0 .001 6.104 5.889 5.889 0 0 0 5.9 12h.106A6.055 6.055 0 0 0 12 5.896 5.882 5.882 0 0 0 5.992.001zm-.742 8.27a.738.738 0 0 1 .725-.765h.013c.41.001.746.326.762.735a.737.737 0 0 1-.725.765h-.013a.764.764 0 0 1-.762-.735zm.25-2.02v-3a.5.5 0 0 1 1 0v3a.5.5 0 0 1-1 0z"
		/>
	</svg>
);

export default SvgNoticeAlert;
