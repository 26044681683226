import React from 'react';

const SvgIconLostClient = props => (
	<svg viewBox="0 0 42.52 42.52" {...props} width="1em" height="1em">
		<path
			d="M21.26 1.321c-10.995 0-19.939 8.946-19.939 19.94.002 10.993 8.947 19.938 19.939 19.938 10.995 0 19.939-8.944 19.939-19.938 0-10.995-8.945-19.94-19.939-19.94zM7.698 32.087a17.153 17.153 0 0 1-3.799-10.826c0-9.574 7.788-17.363 17.36-17.363 3.976 0 7.72 1.314 10.827 3.8l.506.406L8.104 32.592l-.406-.505zm13.562 6.536a17.16 17.16 0 0 1-10.827-3.801l-.506-.406L34.416 9.925l.406.507a17.162 17.162 0 0 1 3.799 10.829c-.001 9.574-7.789 17.362-17.361 17.362z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgIconLostClient;
