import {concat, reject, contains, pipe, assoc, append} from 'ramda';
import {scopedHandler, pipeSubHandlers} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import * as selectors from './selectors';
import * as commonSelectors from 'modules/common/selectors';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {
	formatCalendarResourceAddFormOutput,
	formatCalendarEncounterAddFormOutput,
	formatItemReservationCancellationOutput,
	formatCalendarResourceEditFormOutput,
	sortTeamUsers,
	formatCalendarResourceDateFormOutput,
	formatClientFormOutput,
} from './utils';
import {normalizeCalendarResources, normalizeItem} from 'utils/calendarResources';
import createReminderHandler from 'fragments/callReminder/handler';

import {getFormValues} from 'redux-form';

const pipeHandlers = pipeSubHandlers(ns);

const reminderHandler = createReminderHandler({
	actions,
	effects,
	weekSampleSelector: selectors.weekSample,
	buildingSelector: selectors.callReminderBuilding,
});

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.updateCalendarQuery.type: {
			const newState = {
				...state,
				calResLoading: true,
				calendarQuery: {
					...state.calendarQuery,
					...payload,
				},
			};

			return [newState, effects.updateCalendarResources()];
		}

		case actions.createCalendarResources.type: {
			const item = formatCalendarResourceAddFormOutput({
				form: payload,
				slotSelection: state.slotSelection,
			});

			return [{...state, processing: true}, effects.createCalendarResources([item])];
		}

		case actions.removeCalendarResources.type: {
			return [state, effects.removeCalendarResources(payload)];
		}

		case actions.updateCalendarResource.type: {
			return [{...state, processing: true}, effects.updateCalendarResource(payload)];
		}

		case actions.toggleItemsViewer.type: {
			return [{...state, itemsViewerOpen: payload, itemsCreatorOpen: false}, null];
		}

		case actions.toggleItemsCreator.type: {
			return [
				{...state, itemsCreatorOpen: payload, itemsViewerOpen: false},
				effects.toggleItemsCreator(),
			];
		}

		case actions.setSlotSelection.type: {
			return [{...state, slotSelection: payload}, null];
		}

		case actions.clearSlotSelection.type: {
			return [
				{
					...state,
					slotSelection: initState.slotSelection,
					itemsViewerOpen: false,
					itemsCreatorOpen: false,
				},
				null,
			];
		}
		case actions.toggleClientEditor.type: {
			const newState = {
				...state,
				clientEditorOpen: !state.clientEditorOpen,
			};

			return [newState, null];
		}

		case actions.searchBuildings.type: {
			return [state, effects.searchBuildings(payload)];
		}

		case actions.searchUsers.type: {
			return [state, effects.searchUsers(payload)];
		}

		case actions.saveClient.type: {
			const client = formatClientFormOutput(payload);
			const encounterAddForm = getFormValues('calendarEncounterAddForm')(fullState);
			const buildingId = encounterAddForm.building?.id;

			return [{...state, processing: true}, effects.createClient({client, buildingId})];
		}

		case actions.createEncounter.type: {
			const resource = formatCalendarEncounterAddFormOutput({
				form: payload,
				slotSelection: state.slotSelection,
			});

			return [{...state, processing: true}, effects.createCalendarResources([resource])];
		}

		case actions.setSelectedItem.type: {
			return [
				{
					...state,
					selectedItem: payload,
					areasEditorOpen: false,
					dateEditorOpen: false,
				},
				null,
			];
		}

		case actions._setMarketingLeadSources.type: {
			return [{...state, marketingLeadSources: payload}, null];
		}

		case actions.openEncounterPreview.type: {
			return [{...state, processing: true}, effects.getPreviewableEncounter(payload)];
		}

		case actions.closeEncounterPreview.type: {
			return [{...state, previewableEncounter: null}, null];
		}

		case actions.setItemReservationCancellation.type: {
			const item = formatItemReservationCancellationOutput(state.selectedItem, payload);

			return [{...state, processing: true}, effects.updateCalendarResource(item)];
		}

		case actions.setAreasEditorOpen.type: {
			return [{...state, areasEditorOpen: payload}, null];
		}

		case actions.setDateEditorOpen.type: {
			return [{...state, dateEditorOpen: payload}, null];
		}

		case actions.saveCalendarResourceEditForm.type: {
			const resource = formatCalendarResourceEditFormOutput({
				form: payload,
				selectedItem: state.selectedItem,
			});

			return [{...state, processing: true}, effects.updateCalendarResource(resource)];
		}

		case actions.fetchBuildingCalendarResources.type: {
			return [
				{
					...state,
					buildingCalendarResourcesLoading: true,
				},
				effects.fetchBuildingCalendarResources(payload),
			];
		}

		case actions.clearBuildingCalendarResources.type: {
			return [
				{
					...state,
					buildingCalendarResources: [],
					buildingCalendarResourcesLoading: false,
				},
				null,
			];
		}

		case actions.openBonusItemReservationModal.type: {
			return [
				{...state, bonusItemReservationModalOpen: true},
				effects.setBonusItemBeingReserved(payload),
			];
		}

		case actions.closeBonusItemReservationModal.type: {
			return [
				{...state, bonusItemReservationModalOpen: false, bonusItemBeingReserved: null},
				null,
			];
		}

		case actions.reserveBonusItem.type: {
			const resource = formatCalendarResourceDateFormOutput({
				form: payload,
				item: state.bonusItemBeingReserved,
				userId: commonSelectors.user(fullState).id,
			});

			return [{...state, processing: true}, effects.reserveBonusItem(resource)];
		}

		case actions._setUserTeams.type: {
			return [{...state, userTeams: sortTeamUsers(payload)}, null];
		}

		case actions._updateCalendarQuery.type: {
			return [{...state, calendarQuery: {...state.calendarQuery, ...payload}}, null];
		}

		case actions._setCalendarResources.type: {
			const [hourlessItems, hourItems] = normalizeCalendarResources(
				state.calendarQuery.weekSample,
				payload,
			);

			const newState = {
				...state,
				hourlessItems,
				hourItems,
				itemsSource: payload,
				calResLoading: false,
			};

			return [newState, null];
		}

		case actions._addCalendarResources.type: {
			const itemsSource = concat(state.itemsSource, payload);
			const [hourlessItems, hourItems] = normalizeCalendarResources(
				state.calendarQuery.weekSample,
				itemsSource,
			);

			const newState = {
				...state,
				hourlessItems,
				hourItems,
				itemsSource,
			};

			return [newState, null];
		}

		case actions._removeCalendarResources.type: {
			const removedIds = payload.map(r => r.id);
			const itemsSource = reject(i => contains(i.id, removedIds), state.itemsSource);
			const [hourlessItems, hourItems] = normalizeCalendarResources(
				state.calendarQuery.weekSample,
				itemsSource,
			);

			const newState = {
				...state,
				hourlessItems,
				hourItems,
				itemsSource,
			};

			return [newState, null];
		}

		case actions._updateCalendarResource.type: {
			let newItemsSource = state.itemsSource;

			if (state.selectedItem && state.selectedItem.teamId !== payload.teamId) {
				// item's team was changed, add or remove it from itemsSource depending on active team
				// item's team can only be changed if it's selected, so we can check the initial team from selectedItem
				const activeTeamId = state.calendarQuery.teamId;
				if (payload.teamId === activeTeamId) {
					newItemsSource = append(payload, state.itemsSource);
				} else {
					newItemsSource = reject(i => i.id === payload.id, state.itemsSource);
				}
			} else {
				// update the item in itemsSource
				newItemsSource = state.itemsSource.map(i => {
					if (i.id === payload.id) return payload;
					return i;
				});
			}

			const [hourlessItems, hourItems] = normalizeCalendarResources(
				state.calendarQuery.weekSample,
				newItemsSource,
			);

			// if the updated item is currently selected, update the selected item
			const selectedItem =
				state.selectedItem && state.selectedItem.id === payload.id
					? pipe(normalizeItem, assoc('virtual', state.selectedItem.virtual))(payload)
					: state.selectedItem;

			// if the updated item is in buildingCalendarResources, update it there too
			const buildingCalendarResources = state.buildingCalendarResources.length
				? state.buildingCalendarResources.map(r => {
						if (r.id === payload.id) return payload;
						return r;
				  })
				: state.buildingCalendarResources;

			const newState = {
				...state,
				hourlessItems,
				hourItems,
				itemsSource: newItemsSource,
				selectedItem,
				buildingCalendarResources,
			};

			return [newState, null];
		}

		case actions.onReminderClick.type: {
			return [state, effects.onReminderClick(payload)];
		}

		case actions._setPreviewableEncounter.type: {
			return [{...state, processing: false, previewableEncounter: payload}, null];
		}

		case actions._resetEditors.type: {
			return [{...state, areasEditorOpen: false, dateEditorOpen: false}, null];
		}

		case actions._setBuildingCalendarResources.type: {
			return [
				{
					...state,
					buildingCalendarResources: payload,
					buildingCalendarResourcesLoading: false,
				},
				null,
			];
		}

		case actions._setBonusItemBeingReserved.type: {
			return [{...state, bonusItemBeingReserved: payload}, null];
		}

		case actions._setUsers.type: {
			return [{...state, users: payload}, null];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false}, null];
		}

		case actions._setLockFrom.type: {
			return [{...state, lockFrom: payload ? new Date(payload) : null}, null];
		}

		case actions.setCallReminderBuilding.type: {
			return [{...state, callReminderBuilding: payload}, null];
		}
		case actions._clientSaved.type: {
			return [{...state, processing: false, clientEditorOpen: false}, null];
		}

		default:
			return pipeHandlers(reminderHandler)(state, fullState, {
				type,
				payload,
			});
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
