import {map, pipe, prop, path} from 'ramda';
import services from 'services';
import importMaps from 'services/importMaps';
import {describeThrow, describeError} from 'utils/errors';
import {deleteChildren} from 'utils/dom';
import * as normalize from 'utils/normalize';
import {mapResponseData, getResponseData} from 'utils/app';
import msgs from 'dicts/messages';
import {
	safeGetFeaturesAtPixel,
	safeHasFeatureAtPixel,
	addTooltipToLayers,
	renderBuildingTooltip,
	_getMapTileSource,
} from 'io/maps';
import {
	maxZoom,
	styles as mapStyles,
	buildingsYearMinZoom,
	buildingsMinZoom,
} from 'constants/maps';
import {userTeamsInclude, allAreasBaseQuery} from './constants';
import {concreteBuildingStyle} from 'utils/maps';
import {mapTooltip} from 'styles/fragments';
import {catchNonFatalDefault} from 'io/errors';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const decorateMapMethod = method => args =>
	importMaps()
		.then(method(args))
		.catch(describeThrow(intl.formatMessage({id: 'Error loading map'})));

// resources

export const getBuildings = (query, include) =>
	httpJson('get', '/buildings/getBuildingsByEncounters', {
		include,
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.building)));

export const getOrganizationProducts = () =>
	httpJson('get', '/products', {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.product)));

export const getAllProducts = () =>
	httpJson('get', '/products', {include: 'organizations', ownOrganizationsProducts: true})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.product)));

export const getBuilding = (buildingId, include) =>
	httpJson('get', `/buildings/${buildingId}`, {
		include,
	})
		.catch(e => {
			const noPermission = !!e.response && e.response.status === 403;
			const errMsgKey = noPermission
				? 'You do not have access to the building'
				: 'The building could not be loaded. Try refreshing the page.';
			throw describeError(intl.formatMessage({id: errMsgKey}), e);
		})
		.then(pipe(prop('data'), normalize.building));

export const getUserTeams = () =>
	httpJson('get', '/users/me', {include: userTeamsInclude})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(pipe(path(['teams', 'data']), map(normalize.team))));

export const getAllAreas = () =>
	httpJson('get', '/areas', allAreasBaseQuery)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const postCalendarResource = resource =>
	httpJson('post', '/calendarResource', {include: 'building'}, {body: resource})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save calendar entry'})))
		.then(pipe(prop('data'), normalize.calendarResource));

export const getCrossSalesCount = () =>
	httpJson('get', '/salesAssignments/crossSalesCount', {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(prop('data'));

export const postSalesmanLog = ({url, buildingId}) =>
	httpJson('post', '/salesmanAppLog', {}, {body: {url, buildingId}}).catch(
		catchNonFatalDefault,
	);

export const getOrganizations = () =>
	httpJson('get', '/organizations', {customerOrganizations: false})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.organization)));

// maps

const _getBuildingsStyle =
	({zoom, selectionId, manufacturingLimit}) =>
	imports => {
		const {openLayers: ol} = imports;

		const sty = mapStyles(ol);

		return feature => {
			const {id, encounterState, manufacturingYear, encounterDate, banned} =
				feature.getProperties();

			const selected = selectionId === id;
			const building = {encounterState, encounterDate, banned, manufacturingYear};

			return new ol.style.Style({
				...concreteBuildingStyle(ol, {
					geomType: feature.getGeometry().getType(),
					zoom,
					style: sty.buildingStyleProps({building, manufacturingLimit, selected}),
				}),
				zIndex: selected ? 1 : 0,
				text:
					zoom >= buildingsYearMinZoom
						? new ol.style.Text({
								text: manufacturingYear ? `${manufacturingYear}` : '',
								overflow: true,
						  })
						: null,
			});
		};
	};
export const getBuildingsStyle = decorateMapMethod(_getBuildingsStyle);

const _getFeaturesFromGeoJSON = geoJSON => imports => {
	const {openLayers: ol} = imports;

	return new ol.format.GeoJSON().readFeatures(geoJSON, {
		dataProjection: 'EPSG:4326',
		featureProjection: 'EPSG:3857',
	});
};
export const getFeaturesFromGeoJSON = decorateMapMethod(_getFeaturesFromGeoJSON);

const createBuildingsLayer =
	({initialZoom, selectionId, manufacturingLimit}) =>
	imports => {
		const {openLayers: ol} = imports;

		const layer = new ol.layer.VectorLayer({
			source: new ol.source.VectorSource(),
			style: _getBuildingsStyle({zoom: initialZoom, selectionId, manufacturingLimit})(
				imports,
			),
			visible: initialZoom >= buildingsMinZoom,
		});

		return layer;
	};

const _initializeMap =
	({
		initialZoom,
		initialCenter,
		onMapLocationChanged,
		onBuildingClick,
		selectionId,
		manufacturingLimit,
		getHideBuildingStreet,
		mapSourceProps,
	}) =>
	imports => {
		// keep some local state
		let currHoverBuildingId = null;

		const {openLayers: ol} = imports;

		const mapEl = document.querySelector('#contacts-map');
		if (!mapEl) {
			return Promise.reject(new Error('Map element not found'));
		}

		const view = new ol.View({
			projection: 'EPSG:3857',
			center: initialCenter,
			zoom: initialZoom,
			maxZoom,
			enableRotation: false,
			constrainResolution: true,
		});

		const mapSource = _getMapTileSource(mapSourceProps)(imports);
		const mapLayer = new ol.layer.Tile({source: mapSource});

		const buildingsLayer = createBuildingsLayer({
			initialZoom,
			selectionId,
			manufacturingLimit,
		})(imports);

		const map = new ol.Map({
			target: 'contacts-map',
			view,
			layers: [mapLayer, buildingsLayer],
		});

		map.on('moveend', e => {
			const zoom = map.getView().getZoom();
			const center = map.getView().getCenter();
			onMapLocationChanged({z: zoom, x: center[0], y: center[1]});

			// toggle buildings layer
			if (zoom >= buildingsMinZoom) {
				buildingsLayer.setVisible(true);
			} else {
				buildingsLayer.setVisible(false);
			}
		});

		map.on('click', e => {
			const features = safeGetFeaturesAtPixel(map, e.pixel, {
				layerFilter: l => l === buildingsLayer,
			});
			if (!features) return;
			const buildingId = features[0].get('id');
			onBuildingClick(buildingId);
			// clear tooltip
			buildingTooltip.setPosition(null);
			currHoverBuildingId = null;
		});

		map.on('pointermove', e => {
			const pixel = map.getEventPixel(e.originalEvent);
			const hit = safeHasFeatureAtPixel(map, pixel, {
				layerFilter: l => l === buildingsLayer,
			});
			map.getViewport().style.cursor = hit ? 'pointer' : '';
		});

		const buildingTooltipEl = document.createElement('div');
		buildingTooltipEl.id = 'contacts-building-tooltip';
		buildingTooltipEl.style.cssText = mapTooltip;

		const buildingTooltip = new ol.Overlay({
			element: buildingTooltipEl,
			positioning: 'bottom-center',
			offset: [0, -5],
		});

		const updateBuildingTooltip = buildingFeature => {
			const {
				id,
				address,
				zip,
				city,
				manufacturingYear,
				encounterState,
				encounterDate,
				distance,
				clientId,
				clientFirstName,
				clientLastName,
			} = buildingFeature.getProperties();

			if (id === currHoverBuildingId) return;

			currHoverBuildingId = id;
			deleteChildren(buildingTooltipEl);
			renderBuildingTooltip({
				tooltipEl: buildingTooltipEl,
				address: getHideBuildingStreet() ? `${zip} ${city}` : address,
				manufacturingYear,
				encounterState,
				encounterDate,
				distance,
				clientId,
				clientFirstName,
				clientLastName,
			});
		};

		addTooltipToLayers({
			mapEl,
			map,
			layers: [buildingsLayer],
			tooltipEl: buildingTooltipEl,
			tooltip: buildingTooltip,
			updateTooltip: updateBuildingTooltip,
		});

		return Promise.resolve({map, buildingsLayer, mapLayer});
	};
export const initializeMap = decorateMapMethod(_initializeMap);
