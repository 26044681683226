import services from 'services';
import {pipe, prop} from 'ramda';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getOrganizations = () =>
	httpJson('get', '/organizations')
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getUsersByAccountID = id =>
	httpJson('get', '/organization')
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getUsers = () =>
	httpJson('get', '/users')
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

// NOTE: almost duplicate of searchCallLogs src/modules/common/io.js
export const searchCallLogs = query =>
	httpJson('get', `/callLogs/tracking${query}`)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getCallLogs = dateQuery =>
	httpJson('get', '/callLogs', {include: 'organization'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getTeams = teamType =>
	httpJson('get', `/teams?getAllTeams=true&_limit=999&include=organization${teamType}`)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const fetchTeamWithUsers = teamId =>
	httpJson('get', `/teams/${teamId}`, {include: 'users'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const fetchTeamsWithUsers = teamIds =>
	httpJson('get', `/teams/`, {getAllTeams: true, teamIds, include: 'users'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));
