import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {getFormValues} from 'redux-form';
import {colMappingForm} from './constants';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize(payload)];
		}

		case actions._initialize.type: {
			const csvImport = payload[0];
			return [
				{
					...state,
					initialized: true,
					csvImport,
				},
			];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.openEditModal.type: {
			return [
				{
					...state,
					isEditModalOpen: true,
				},
				null,
			];
		}

		case actions.closeEditModal.type: {
			return [
				{
					...state,
					isEditModalOpen: false,
				},
				null,
			];
		}

		case actions.startProcessing.type: {
			const values = getFormValues(colMappingForm)(fullState) || {};
			const headers = Object.values(values);

			return [
				{
					...state,
					processing: true,
				},
				effects.startProcessing({
					id: payload,
					headers,
				}),
			];
		}

		case actions._startProcessing.type: {
			const csvImport = payload[0];
			return [
				{
					...state,
					csvImport,
					processing: false,
					isEditModalOpen: false,
				},
				null,
			];
		}

		case actions.removeCsvImport.type: {
			return [
				{
					...state,
					processing: true,
				},
				effects.removeCsvImport(payload),
			];
		}

		case actions._removeCsvImport.type: {
			return [
				{
					...state,
					processing: false,
					csvImport: initState.csvImport,
				},
				null,
			];
		}

		case actions.cancelCsvImport.type: {
			return [state, effects.cancelCsvImport(payload)];
		}

		case actions._cancelCsvImport.type: {
			return [
				{
					...state,
					csvImport: payload,
				},
				null,
			];
		}

		case actions.deleteImportedClients.type: {
			return [
				{
					...state,
					processing: true,
				},
				effects.deleteImportedClients(payload),
			];
		}

		case actions._deleteImportedClients.type: {
			return [
				{
					...state,
					csvImport: payload,
					processing: false,
				},
				null,
			];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
