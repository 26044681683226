export const calendarResourceInclude =
	'client.building,salesman,salesmanVisit.user,salesmanVisit.products,salesmanVisit.secondUser,areas,reserver.roles,building,reservation.encounter,reservation,team,latestActivity.additionalInfo,products,reservation.calendarResource';

export const selectableTeamsInclude = 'users,areas:allAreas(true),organization';

export const teamsQueryBase = {
	type: 'Sales',
	isCalendar: true,
	_limit: 999,
};

export const calResQueryBase = {
	_limit: 999,
	searchByPreviousDates: true,
};

export const calendarResourceEncounterInclude =
	'reservation.encounter.building,reservation.encounter.source.formFill.formAttributes,reservation.encounter.source.user,reservation.encounter.source.surveyFills';
