import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {formatMessageFallbacked} from 'utils/app';

const ensureTranslations = fullState => state => {
	const formatMessage = id => formatMessageFallbacked(id)(fullState);
	return {
		...state,
		title: formatMessage('Confirm'),
		message: formatMessage('The action you are taking requires confirmation'),
		cancelText: formatMessage('Cancel'),
		okText: formatMessage('Confirm'),
	};
};

export default scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.show.type: {
			const {onCancel = null, onOk = null, ...conf} = payload;
			const baseState = ensureTranslations(fullState)(initState);
			const newState = {...baseState, ...conf};

			return [newState, effects.setCallbacks({onCancel, onOk})];
		}

		case actions.ok.type: {
			return [initState, effects.ok()];
		}

		case actions.cancel.type: {
			return [initState, effects.cancel()];
		}

		// delay showing until callbacks are set. by performing a state update after callbacks are available, our selectors using the callbacks will pick the change up
		case actions._callbacksSet.type: {
			return [{...state, show: true}, null];
		}

		default:
			return [state, null];
	}
});
