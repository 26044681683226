import {mergeLeft} from 'ramda';
import * as actions from './actions';
import {catchNonFatalDefault} from 'io/errors';
import {effect} from 'utils/redux';
import namespace from './namespace';
import {decorateWithNotifications} from 'io/app';
import services from 'services';
import msgs from 'dicts/messages';
import {getQuery, pushQuery} from 'io/history';
import {getFeedback, putFeedback} from './io';
import * as selectors from './selectors';
import {parseUrlQuery} from './utils';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const fetchFeedback = (getState, dispatch) => {
	return decorateWithNotifications(
		{id: 'get-feedback', failureStyle: 'error'},
		getFeedback(selectors.feedbackQueryFetchable(getState())),
	)(getState, dispatch).then(reminders => {
		dispatch(actions._setFeedback(reminders));
	});
};

export let initialize = () => (getState, dispatch) => {
	const {feedbackQuery} = parseUrlQuery(getQuery());
	dispatch(actions._updateFeedbackQuery(feedbackQuery));
	fetchFeedback(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let updateFeedback = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	fetchFeedback(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
updateFeedback = creator('updateFeedback', updateFeedback);

export let handleFeedback = feedback => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'handle-feedback',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: msgs.saved}),
		},
		putFeedback({id: feedback.id, state: 'handled'}),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._setProcessing(false));
			throw e;
		})
		.then(f => {
			dispatch(actions._updateFeedback(f));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
handleFeedback = creator('handleFeedback', handleFeedback);
