import {action} from 'utils/redux';
import {P} from 'utils/types';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');
export const _initialize = creator('_initialize');

export const destroy = creator('destroy');

export const getReasonMappings = creator('getReasonMappings');
export const _getReasonMappings = creator('_getReasonMappings', P.Array);

export const openReasonMapping = creator('openReasonMapping', P.Object);

export const openReasonMappingModal = creator('openReasonMappingModal');
export const closeReasonMappingModal = creator('closeReasonMappingModal');

export const createReasonMapping = creator('createReasonMapping');

export const updateReasonMapping = creator('updateReasonMapping', P.Number);

export const deleteReasonMapping = creator('deleteReasonMapping', P.Number);

export const setShowDeleted = creator('setShowDeleted', P.Boolean);
export const _setShowDeleted = creator('_setShowDeleted', P.Boolean);

export const restoreReasonMapping = creator('restoreReasonMapping', P.Number);

export const recheckQuery = creator('recheckQuery');

export const _setAvailableTags = creator('_setAvailableTags', P.Array);
