import {effect} from 'utils/redux';
import {catchNonFatalDefault} from 'io/errors';
import namespace from './namespace';
import * as actions from './actions';
import * as commonSelectors from 'modules/common/selectors';
import {getTeams} from './io';
import {appName} from '../constants';
import {ensureAccess, setPageTitleMessage, decorateWithNotifications} from 'io/app';
import services from 'services';

const history = services.get('history');
const creator = effect(namespace);

const fetchInitData = (getState, dispatch) => {
	decorateWithNotifications(
		{id: 'get-teams', failureStyle: 'warning'},
		getTeams()
			.then(teams => {
				dispatch(actions._setTeams(teams));
			})
			.then(() => dispatch(actions._initialize()))
			.catch(catchNonFatalDefault(getState, dispatch)),
	);
};

export let initialize = () => (getState, dispatch) => {
	setPageTitleMessage('Statistics [app]')(getState, dispatch);
	const organizationId = commonSelectors.activeOrganizationId(getState());

	const settings = JSON.parse(
		localStorage.getItem(`organization_${organizationId}:settings`),
	);

	if (settings) dispatch(actions._setSettings(settings));

	ensureAccess(appName, commonSelectors.user(getState()))(getState, dispatch).then(() => {
		fetchInitData(getState, dispatch);
	});
};
initialize = creator('initialize', initialize);

export let saveSettings = settings => (getState, disaptch) => {
	const organizationId = commonSelectors.activeOrganizationId(getState());
	localStorage.setItem(
		`organization_${organizationId}:settings`,
		JSON.stringify(settings),
	);

	history.push('/stats');
};
