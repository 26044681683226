import {leadFormInitialValues} from './constants';
import {isEmpty} from 'ramda';
import {transform} from 'ol/proj';
import {formatLocalDateInput, formatLocalDateOutput} from 'utils/fields';
import {fullName} from 'utils/people';
import {fullAddress} from 'utils/buildings';
import {parseIntPhoneNumber} from 'utils/phone-number';

export const formatLeadFormOutput = ({form}) => {
	const data = {
		buildingId: !form.buildingNotFound && form.building ? form.building.id : null,
		clientId:
			!form.buildingNotFound && !form.clientNotFound && form.clientId
				? form.clientId
				: null,
		products: form.products ? form.products.map(id => ({id})) : null,
		zip: form.buildingNotFound && form.zip ? form.zip : null,
		city: form.buildingNotFound && form.city ? form.city : null,
		address: form.buildingNotFound ? form.address : null,
		clientFirstName:
			form.clientNotFound || form.buildingNotFound ? form.clientFirstName : null,
		clientLastName:
			form.clientNotFound || form.buildingNotFound ? form.clientLastName : null,
		phone: form.clientNotFound || form.buildingNotFound ? form.phone : null,
		comment: form.comment,
		organizationId: form.organizationId,
		type: form.type,
		url: form.domain,
		service: form.service,
		class: form.class,
		heardFrom: form.heardFrom,
		email: form.email,
		marketingCategoryId: form.marketingCategoryId,
		marketingChannelId: form.marketingChannelId,
		marketingCustomerTypeId: form.marketingCustomerTypeId,
		marketingSourceId: form.marketingSourceId,
		marketingAuthorization: form.marketingAuthorization,
		tags: form.tags ? form.tags.map(id => ({id})) : null,
		message: form.message,
		salesmanagerId: form.salesmanagerId,
		handlerComment: form.handlerComment,
	};

	return data;
};

export const formatMarketingLeadFormClient = client => {
	const _client = {
		id: client.id,
		firstName: client.firstName,
		lastName: client.lastName,
		phone: client.phone,
		email: client.email,
		emailMarketing: client.emailMarketing
			? formatLocalDateInput(new Date(client.emailMarketing))
			: null,
	};

	return _client;
};

export const formatLeadFormInput = ({lead = {}, clients}) => {
	const client = lead.clientId ? clients.find(c => c.id === lead.clientId) || {} : {};
	const {marketingChannel, marketingCategory, marketingCustomerType, marketingSource} =
		lead;
	const initValues = isEmpty(lead)
		? leadFormInitialValues
		: {
				buildingNotFound: lead.buildingId ? false : true,
				clientNotFound: lead.clientId ? false : true,
				building: lead.buildingId ? lead.building : null,
				address: !lead.buildingId ? lead.address : '',
				zip: !lead.buildingId ? lead.zip : '',
				city: !lead.buildingId ? lead.city : '',
				clientId:
					lead.clientId && !lead.clientNotFound && !lead.buildingNotFound
						? lead.clientId
						: '',
				clientFirstName: lead.clientFirstName ? lead.clientFirstName : '',
				clientLastName: lead.clientLastName ? lead.clientLastName : '',
				// marketing lead's phone is often saved in national format => parse international version
				phone: lead.phone ? parseIntPhoneNumber(lead.phone) : '',
				email: lead.email ? lead.email : '',
				marketingAuthorization: lead.marketingAuthorization
					? formatLocalDateInput(new Date(lead.marketingAuthorization))
					: null,
				products: lead.products ? lead.products.map(p => p.id) : [],
				salesmanager: lead.salesmanagerId,
				comment: lead.comment ? lead.comment : '',
				type: lead.type ? lead.type : '',
				domain: lead.url,
				source: lead.utm_source,
				campaign: lead.utm_campaign,
				utmContent: lead.utm_content,
				utmSource: lead.utm_source,
				formId: lead.form_id,
				utmMedium: lead.utm_medium,
				utmCampaign: lead.utm_campaign,
				gclid: lead.gclid,
				organizationId: lead.organizationId,
				class: lead.class,
				heardFrom: lead.heardFrom,
				message: lead.message,
				service: lead.service,
				marketingChannelId: marketingChannel ? marketingChannel.id : '',
				marketingCategoryId: marketingCategory ? marketingCategory.id : '',
				marketingSourceId: marketingSource ? marketingSource.id : '',
				marketingCustomerTypeId: marketingCustomerType ? marketingCustomerType.id : '',
				marketing_authorization: lead.marketingAuthorization,
				client: formatMarketingLeadFormClient(client),
				tags: lead.tags ? lead.tags.map(t => t.id) : [],
				salesmanagerId: lead.salesmanagerId,
				handlerComment: lead.handlerComment,
		  };

	return initValues;
};

export const formatClientOutput = ({form}) => {
	const client = {
		buildingId: form.building.id,
		firstName: form.clientFirstName,
		lastName: form.clientLastName,
		phone: form.phone,
		email: form.email,
		emailMarketing: form.marketingAuthorization
			? formatLocalDateInput(new Date(form.marketingAuthorization))
			: null,
	};

	return client;
};

/*
copy pasted from teamcalendar
got to remove duplicates and make sure they are good for generic use for these kinds of uses
*/
export const parseAddBuildingFormInitValues = ({address, zip}) => {
	if (!address && !zip) return {address: '', zip: ''};

	return {
		address,
		zip,
	};
};

export const formatAddBuildingOutput = building => {
	const transformedCoords = transform(building.coords, 'EPSG:3857', 'EPSG:4326');

	return {
		address: building.address,
		zip: building.zip,
		type: building.type,
		manufacturingYear: building.manufacturingYear,
		location: {lng: transformedCoords[0], lat: transformedCoords[1]},
	};
};

export const formatClient = ({client, lead}) => {
	const _client = {
		...client,
		email: client.email ? client.email : lead.email,
		emailMarketing: client.emailMarketing
			? formatLocalDateOutput(new Date(client.emailMarketing))
			: null,
	};
	return _client;
};

export const formatLeadEventFormInput = lead => {
	return {
		reminderDescription:
			(lead.building
				? fullAddress(lead.building)
				: fullAddress({address: lead.address, city: lead.city, zip: lead.zip})) +
			'\n' +
			(lead.client
				? fullName(lead.client)
				: lead.clientLastName + ' ' + lead.clientFirstName) +
			'\n' +
			(lead.client ? lead.client.phone : lead.phone),
	};
};

export const formatLeadEventFormOutput = ({form, lead}) => {
	const {
		state,
		comment,
		addCallReminder,
		reminderCallAt,
		sendReminderMail,
		reminderDescription,
		reminderUserId,
		crTeamId,
		crDate,
	} = form;

	return {
		leadEvent: {leadId: lead.id, state, comment, crTeamId, crDate},
		callReminder: addCallReminder
			? {
					buildingId: lead.building ? lead.building.id : null,
					clientId: lead.client ? lead.client.id : null,
					description: reminderDescription,
					callAt: reminderCallAt,
					sendReminderMail,
					leadId: lead.id,
					type: 'marketing',
					userId: state === 'transferredToSales' ? reminderUserId : null,
					createNotice: state === 'transferredToSales',
			  }
			: null,
	};
};
