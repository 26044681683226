import ReactDom from 'react-dom';
import {curry} from 'ramda';
import * as nActions from 'modules/notifications/actions';
import {longerDur} from 'constants/notifications';
import {formatMessageFallbacked} from 'utils/app';
import {formatMessageRaw} from 'io/app';
import services from 'services';
import {rootStyle} from 'styles/fragments';

const isObj = x => x && typeof x === 'object';

const bugsnagNotify = (severity, error) => {
	const bugsnag = services.get('bugsnag');
	if (!bugsnag) {
		return;
	}
	bugsnag.notify(error, {severity});
};

// type = 'navigation', 'request', 'process', 'log', 'user', 'state', 'error', 'manual'
export const leaveBreadcrumb = (message, metadata = {}, type = 'log') => {
	const bugsnag = services.get('bugsnag');
	if (!bugsnag) {
		return;
	}
	bugsnag.leaveBreadcrumb(message, metadata, type);
};

// logError doesn't do full logging because it assumes error won't get caught again afterwards, this just logs everything
export const logErrorFully = error => {
	if (isObj(error)) error.wasLogged = true;
	console.error(error);
	bugsnagNotify('error', error);
	return error;
};

export const logError = error => {
	if (isObj(error)) error.wasLogged = true;
	// note: no console.error calls necessary since errors are supposed to always be allowed to be propagated to execution top level where the browser can log them
	bugsnagNotify('error', error);
	return error;
};

// note: if the error doesn't have a description, consider it unexpected and log it as an error level event instead
export const logWarning = error => {
	if (!error || error.description === undefined) {
		return logErrorFully(error);
	}
	if (isObj(error)) error.wasLogged = true;
	console.warn(error);
	bugsnagNotify('warning', error);
	return error;
};

// note: if the error doesn't have a description, consider it unexpected and log it as an error level event instead
export const logInfo = error => {
	if (!error || error.description === undefined) {
		return logErrorFully(error);
	}
	if (isObj(error)) error.wasLogged = true;
	console.warn(error);
	bugsnagNotify('info', error);
	return error;
};

export const quitWithMessage = text => {
	if (window.didQuitDueToError) {
		return;
	}
	window.didQuitDueToError = true;

	// if user navigates away from the error page using history, enforce a page refresh so that the app restarts
	window.addEventListener('popstate', e => {
		window.location.reload();
	});

	const root = document.querySelector('#root');

	root.setAttribute('hidden', '');
	ReactDom.unmountComponentAtNode(root);

	const body = document.querySelector('body');

	const errorPrev = document.querySelector('#app-error-view');
	if (errorPrev) body.removeChild(errorPrev);

	const container = document.createElement('div');
	container.id = 'app-error-view';
	/* prettier-ignore */
	container.setAttribute('style', `
		${rootStyle}

		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 20px 20px;
	`);

	const errors = document.createElement('div');

	const header = document.createElement('h1');
	header.textContent = formatMessageRaw('Error');
	errors.appendChild(header);

	const message = document.createElement('h2');
	message.textContent = text;
	errors.appendChild(message);

	const quitInfo = document.createElement('div');
	quitInfo.textContent = formatMessageRaw(
		'The application must close. Try refreshing the page or returning to the previous page.',
	);
	errors.appendChild(quitInfo);

	const linkStyle = `
		display: inline-block;
		margin-top: 1em;
		cursor: pointer;
		color: #419fc6;
		text-decoration: underline;
	`;

	const feedbackLink = document.createElement('a');
	feedbackLink.textContent = formatMessageRaw('Report bug');
	feedbackLink.setAttribute('href', '/feedback');
	feedbackLink.style = linkStyle;

	const refreshLink = document.createElement('a');
	refreshLink.textContent = formatMessageRaw('Refresh page');
	refreshLink.onclick = () => window.location.reload(true);
	refreshLink.style = linkStyle;

	const frontLink = document.createElement('a');
	frontLink.textContent = formatMessageRaw('Front page');
	frontLink.setAttribute('href', '/');
	frontLink.style = linkStyle;

	const separator = document.createElement('span');
	separator.innerHTML = '&bull;';
	separator.style = `
		display: inline-block;
		margin-top: 1em;
		margin-left: 1em;
		margin-right: 1em;
		font-family: sans-serif;
	`;

	errors.appendChild(feedbackLink);
	errors.appendChild(separator);
	errors.appendChild(refreshLink);
	errors.appendChild(separator.cloneNode(true));
	errors.appendChild(frontLink);

	container.appendChild(errors);

	body.appendChild(container);
};

const handleFatal = error => {
	logError(error);
	quitWithMessage((error && error.description) || formatMessageRaw('Unexpected error'));
	if (isObj(error)) error._didCrash = true;
	return error;
};

// handle uncaught errors by attaching to global listeners
export const handleUncaught = error => {
	if (error && error._didCrash) {
		return;
	}
	handleFatal(error);
};

// handle fatal errors by quitting the app. also re-throw the error to stop any further execution.
export const handleAsFatal = error => {
	throw handleFatal(error);
};

// handle non-fatal errors that require notifying the user
export const warn = curry((warningAction, warningSpec, error) => (getState, dispatch) => {
	logWarning(error);
	const message =
		error.description || formatMessageFallbacked('Unexpected error')(getState());
	dispatch(warningAction({...warningSpec, message}));
	return error;
});

// handle "non-errors" that require notifying the user (like insufficient permissions)
export const inform = curry((action, spec, error) => (getState, dispatch) => {
	logInfo(error);
	const message =
		error.description || formatMessageFallbacked('Unexpected error')(getState());
	dispatch(action({...spec, message}));
	return error;
});

// finalize promise or try-catch chains by catching any errors that weren't fatal and have already been handled. errors are considered handled if they've been logged earlier (and hopefully otherwise handled at the same time).
export const catchHandled = error => {
	if (!error || error._didCrash || !error.wasLogged) {
		throw error;
	}
};

// finalize promise or try-catch chains by catching any errors that weren't fatal. also, enforce blanket handling for errors that weren't explicitly handled (logged) earlier.
export const catchNonFatal = curry((handleUnhandled, error) => {
	if (!error || error._didCrash) {
		throw error;
	}
	if (!error.wasLogged) {
		logErrorFully(error);
		handleUnhandled(error);
	}
});

// useful as a default argument to catchNonFatal
export const unhandledNotifier = (getState, dispatch) => () => {
	dispatch(
		nActions.error({
			id: 'unexpected-caught',
			message: formatMessageFallbacked(
				'Unexpected error. The application may malfunction if you do not refresh the page',
			)(getState()),
			duration: longerDur,
		}),
	);
};

export const catchNonFatalDefault = (getState, dispatch) => error =>
	catchNonFatal(unhandledNotifier(getState, dispatch), error);
