import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	initialized: false,
	processing: false,
	progressData: {},
	condosMeta: {},
	condosQuery: {
		fullAddress: '',
		companyName: '',
		manufacturingYearStart: '',
		manufacturingYearEnd: '',
		userId: '',
		_sort: 'state',
		_stageFilters: {},
	},
	currentLane: 'need',
	offerTotalSum: 0,
	conclusionTotalSum: 0,
	additionalInfos: [],
	salesmen: [],
};
