export const leadFormInitialValues = {
	buildingNotFound: false,
	clientNotFound: false,
	building: null,
	address: '',
	zip: '',
	city: '',
	clientId: '',
	clientFirstName: '',
	clientLastName: '',
	phone: '',
	products: [],
	salesmanager: '',
	comment: '',
	type: '',
};

export const salesmanagersQuery = {
	_limit: 999,
	type: 'salesmanager',
	include: 'allTeams,organizations',
};
