import {sort, chainComparators} from './arrays';
import {ascend, prop} from 'ramda';
import {dateIsBetween} from './time';

export const sortByTitle = sort(
	chainComparators(ascend(prop('title')), ascend(prop('subtitle'))),
);

// filter out team's temp areas that are not available on the given date
export const filterTeamTempAreas = (areas, date) =>
	areas.filter(a =>
		a.temporaryArea
			? dateIsBetween({
					date,
					from: a.dateFrom.split('T')[0],
					to: a.dateTo.split('T')[0],
			  })
			: true,
	);
