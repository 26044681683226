import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.changePage.type: {
			const newState = {
				...state,
				reservationsLoading: true,
				reservationsQuery: {...state.reservationsQuery, _page: payload},
			};

			return [newState, effects.updateReservations()];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		case actions.navigateToBuilding.type: {
			return [state, effects.navigateToBuilding(payload)];
		}

		case actions._setReservations.type: {
			const newState = {
				...state,
				reservations: payload.data,
				reservationsPagination: payload.pagination,
				reservationsLoading: false,
			};

			return [newState, null];
		}

		case actions._updateReservationsQuery.type: {
			const newState = {
				...state,
				reservationsLoading: true,
				reservationsQuery: {...state.reservationsQuery, ...payload},
			};

			return [newState, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
