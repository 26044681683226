import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	processing: false,
	categories: [],
	categoriesLoading: true,
	fileModalOpen: false,
	categoryModalOpen: false,
	roles: [],
	fileInEdit: null,
	categoryInEdit: null,
	organizations: [],
};
