import React from 'react';
import PopperOverlay from 'components/generic/PopperOverlay';
import {popperOverlayAnimated, popup} from 'styles/fragments';
import styled from 'styled-components';

const Root = styled.span``;
const Overlay = styled.div`
	${popperOverlayAnimated};
	${popup};
`;

/**
 * Popover
 *
 */
const Popover = ({
	placement = 'bottom',
	triggers = ['click', 'hover'],
	children,
	overlayContent,
	usePortal = false,
}) => {
	return (
		<PopperOverlay
			triggers={triggers}
			rootClass={Root}
			rootContent={children}
			popperProps={{
				placement,
				modifiers: [{name: 'offset', options: {offset: [0, 5]}}],
			}}
			overlayClass={Overlay}
			overlayContent={overlayContent}
			usePortal={usePortal}
		/>
	);
};
export default Popover;
