import namespace from './namespace';
import {effect} from 'utils/redux';
import cache from './cache';
import * as actions from './actions';
import {P, Record, Nullable} from 'utils/types';

const creator = effect(namespace);

const clearCallbacks = () => {
	cache.update(c => ({...c, onCancel: null, onOk: null}));
};

export let setCallbacks = cbs => (getState, dispatch) => {
	cache.update(c => ({...c, ...cbs}));
	dispatch(actions._callbacksSet());
};
setCallbacks = creator(
	'setCallbacks',
	setCallbacks,
	Record({onCancel: Nullable(P.Function), onOk: Nullable(P.Function)}),
);

export let ok = () => (getState, dispatch) => {
	cache.read().onOk();
	clearCallbacks();
};
ok = creator('ok', ok);

export let cancel = () => (getState, dispatch) => {
	cache.read().onCancel();
	clearCallbacks();
};
cancel = creator('cancel', cancel);
