import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const changePage = creator('changePage');

export const recheckQuery = creator('recheckQuery');

export const removeLead = creator('removeLead', P.Object);

export const searchLeads = creator('searchLeads', P.Object);

export const clearQuery = creator('clearQuery');

export const openDeleteLeadModal = creator('openDeleteLeadModal', P.Object);

export const closeDeleteLeadModal = creator('closeDeleteLeadModal');

export const restoreLead = creator('restoreLead', P.Number);

export const updateLeadsAfterRestore = creator('updateLeadsAfterRestore', P.Number);

export const updateLeadsQuery = creator('updateLeadsQuery', P.Object);

export const openCommentModal = creator('openCommentModal', P.Object);

export const closeCommentModal = creator('closeCommentModal');

export const updateLead = creator('updateLead', P.Object);

export const _setUtmOptions = creator('_setUtmOptions');

export const _initialize = creator('_initialize');

export const _setLeads = creator('_setLeads');

export const _updateLeadsQuery = creator('_updateLeadsQuery');

export const _removedLead = creator('_removedLead');

export const _opFailed = creator('_opFailed');

export const _setProcessing = creator('_setProcessing');

export const _setSources = creator('_setSources');

export const _setProducts = creator('_setProducts');

export const _setOrganizations = creator('_setOrganizations');

export const _setHandlers = creator('_setHandlers', P.Array);

export const _leadUpdated = creator('_leadUpdated', P.Object);

export const _leadHandlerSet = creator('_leadHandlerSet', P.Object);

export const _setAreas = creator('_setAreas', P.Array);
