import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const fetchMore = creator('fetchMore');

export const searchLogs = creator('searchLogs', P.Object);

export const searchUsers = creator('searchUsers', P.Object);

export const searchBuildings = creator('searchBuildings', P.Object);

export const setLogModel = creator('setLogModel', P.String);

export const _setLogs = creator('_setLogs', P.Object);

export const _updateLogsQuery = creator('_updateLogsQuery', P.Object);

export const _initialize = creator('_initialize');

export const _setOrganizations = creator('_setOrganizations', P.Array);

export const _setTeams = creator('_setTeams', P.Array);
