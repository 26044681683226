import {addTimezoneError, removeTimezoneError} from 'utils/time';
import {parse} from 'date-fns';

// lazy export
export const minDate = new Date(0);
export const maxDate = new Date('01-01-2038');

// note that formatters are primarily expected to be applied on the string-based "value" prop, as inputs don't always seem to properly react to changes on their alternative value props (such as valueAsNumber)

export const parseNullableNumber = s => (s !== '' ? Number(s) : null);

export const formatNullableNumber = n => (n !== null ? n : '');

export const parseNullableString = s => (s !== '' ? s : null);

export const formatNullableString = s => (s !== null ? s : '');

// Use more strict comparison for truthy boolean values (instead default boolean coercion: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Boolean#boolean_coercion)
export const parseBoolean = s => ['true', true, 1].includes(s);

// formatters for making datetime-local work with dates that have timezone info attached. values are handed in and out as nullable Date objects.
export const formatLocalDateInput = d => {
	if (!d) return '';

	const dNew = addTimezoneError(d);
	if (isNaN(dNew.getTime())) return '';

	return dNew
		.toISOString()
		.replace(/:[^:]+$/, '')
		.split('+')[0];
};
export const formatLocalDateOutput = s => {
	if (!s) return null;

	const d = removeTimezoneError(new Date(s + 'Z'));
	if (isNaN(d.getTime()) || d >= maxDate || d <= minDate) return null;

	return d;
};

// helpers for format similar to redux-form
export const valueAsNullableNumber = ({value, onChange, onBlur, ...rest}) => ({
	...rest,
	value: formatNullableNumber(value),
	onChange: e => onChange(parseNullableNumber(e.target.value)),
	onBlur: e => onBlur(parseNullableNumber(e.target.value)),
});

export const valueAsNullableString = ({value, onChange, onBlur, ...rest}) => ({
	...rest,
	value: formatNullableString(value),
	onChange: e => onChange(parseNullableString(e.target.value)),
	onBlur: e => onBlur(parseNullableString(e.target.value)),
});

export const valueInLocalDateFormat = ({value, onChange, onBlur, ...rest}) => ({
	...rest,
	value: formatLocalDateInput(value),
	onChange: e => onChange(formatLocalDateOutput(e.target.value)),
	onBlur: e => onBlur(formatLocalDateOutput(e.target.value)),
});

// used for ReactSelect which expects value to be null instead of empty string
// redux-form sets null input value as empty string (https://github.com/redux-form/redux-form/issues/2062)
export const emptyAsNull = val => (val === '' ? null : val);

export const parseNullableDate = (s, fmt = 'yyyy-mm-dd') =>
	s !== '' ? parse(s, fmt, new Date()) : null;
