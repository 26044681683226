import {mergeLeft} from 'ramda';
import * as actions from './actions';
import {effect} from 'utils/redux';
import namespace from './namespace';
import {catchNonFatalDefault} from 'io/errors';
import {decorateWithNotifications} from 'io/app';
import {getQuery, pushQuery} from 'io/history';
import {getFreeCalResReport} from './io';
import * as selectors from './selectors';

const creator = effect(namespace);

const fetchReportData = (getState, dispatch) => {
	return decorateWithNotifications(
		{id: 'fetch-report-data', failureStyle: 'error'},
		getFreeCalResReport(selectors.reportQuery(getState())),
	)(getState, dispatch).then(report => {
		dispatch(actions._setReport(report));
	});
};

export let initialize = () => (getState, dispatch) => {
	const {date} = getQuery();
	if (date) {
		dispatch(actions._updateReportQuery({date}));
	}
	fetchReportData(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let fetchReport = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.reportQuery(getState())));
	fetchReportData(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
fetchReport = creator('fetchReport', fetchReport);
