export const parseDate = date => {
	const startOfDay = `${date} 00:00:01`;
	const endOfDay = `${date} 23:59:59`;
	return `createdAtStart=${startOfDay}&createdAtEnd=${endOfDay}&`;
};

export const parseBothDates = (from, to) => {
	const startOfDay = `${from} 00:00:01`;
	const endOfDay = `${to} 23:59:59`;
	return `createdAtStart=${startOfDay}&createdAtEnd=${endOfDay}&`;
};
