export const leadsQueryBase = {
	_limit: '25',
	withSummary: true,
	socialMediaLeads: true,
};

export const emptyLeadsFilters = {
	createdAtStart: '',
	createdAtEnd: '',
	dealAtStart: '',
	dealAtEnd: '',
	buildingAddress: '',
	clientName: '',
	domains: [],
	utmCampaign: '',
	utmContent: '',
	products: [],
	organizationId: null,
	state: [],
	trashed: false,
	service: '',
	marketingSourceId: '',
	processingStatus: '',
	salesmanagerId: null,
	phone: '',
	cityId: null,
};
