import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import initState from './state';

export const parseUrlQuery = query => {
	const vals = evolve({_page: Number}, query);
	return {feedbackQuery: replace(vals, initState.feedbackQuery)};
};

export const formatFetchableQuery = feedbackQuery => feedbackQuery;

export const formatUrlQuery = feedbackQuery => feedbackQuery;
