import {effect} from 'utils/redux';
import {P} from 'utils/types';
import {decorateWithNotifications} from 'io/app';
import {catchNonFatalDefault, logInfo} from 'io/errors';
import {postCalendarResourceReservation, postCalendarResourceRelease} from './io';

export default ({namespace, actions, selectResource}) => {
	const creator = effect(namespace);

	const selectCalendarResource = ({dateId, currentDateId}) => (getState, dispatch) => {
		if (currentDateId) {
			// we don't want to wait for releases, they go through on their own time if they do, but it's not relevant to the user
			// note: we could update a state variable after the release to keep track of the resource being released ("calendarResourceGroupReleasing") so that double clicks when releasing dates wouldn't immediately send a new reservation. not necessary in practice, though?
			postCalendarResourceRelease(currentDateId).catch(logInfo);
		}

		decorateWithNotifications(
			{id: 'reserving-calendar-resource', failureStyle: 'warning'},
			dateId ? postCalendarResourceReservation(dateId) : Promise.resolve(),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._calendarResourceSelectionFailed());
				selectResource(null)(getState, dispatch);
				throw e;
			})
			.then(() => {
				dispatch(actions._calendarResourceSelected());
				selectResource(dateId)(getState, dispatch);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	return {
		selectCalendarResource: creator(
			'selectCalendarResource',
			selectCalendarResource,
			P.Object,
		),
	};
};
