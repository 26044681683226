import {action} from 'utils/redux';
import {P} from 'utils/types';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const _initialize = creator('_initialize');

export const navigateTo = creator('navigateTo', P.Object);

export const createLead = creator('createLead');

export const editLead = creator('editLead', P.Object);
