import React from 'react';

const SvgTime = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M6 0a6.007 6.007 0 0 1 6 6 6 6 0 1 1-6-6zm0 11A5 5 0 1 0 6 1a5 5 0 0 0 0 10zm2.567-3.095a.5.5 0 1 1-.675.737l-2.48-2.273A.5.5 0 0 1 5.25 6V3.25a.5.5 0 0 1 1 0v2.53l2.317 2.125z"
		/>
	</svg>
);

export default SvgTime;
