export const formatClientFormInput = client => {
	return {
		id: client.id,
		firstName: client.firstName,
		lastName: client.lastName,
		phone: client.phone,
		email: client.email,
		swedish: client.swedish,
		comments: client.comments,
		emailMarketing: !!client.emailMarketing,
	};
};

export const formatClientFormOutput = form => {
	const client = {...form, emailMarketing: form.emailMarketing ? new Date() : null};
	return client;
};
