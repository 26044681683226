import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {fontSizeBase} from 'styles/constants';
// old icons
import personIconWhite from '../icons/old/PersonMenuIconWhite';
import personIconBlack from '../icons/old/PersonMenuIcon';
import addressIcon from '../icons/old/IconAddress';
import clientIcon from '../icons/old/IconClient';
import calendarIcon from '../icons/old/IconCalendar';
import callBusyIcon from '../icons/old/IconCallBusy';
import lostClientIcon from '../icons/old/IconLostClient';
import startCallIcon from '../icons/old/IconStartCall';
import streetViewIcon from '../icons/old/IconStreetview';
import workHoursIcon from '../icons/old/IconWorkhours';
import groupLogo from '../icons/old/GroupLogo';
// icons 32
import statsApp from '../icons/32/Analytics';
import buildingsApp from '../icons/32/Building';
import calendarApp from '../icons/32/Calendar';
import callsApp from '../icons/32/Headset';
import dashboardApp from '../icons/32/Dashboard';
import d2dApp from '../icons/32/Conversation';
import hrApp from '../icons/32/MultipleUsers';
import leadsApp from '../icons/32/Lead';
import mapsApp from '../icons/32/Pin';
import marketingApp from '../icons/32/MarketingApp';
import monitorChart from '../icons/32/MonitorChart';
import projectSalesApp from '../icons/32/Projects';
import reportsApp from '../icons/32/Reports';
import teamCalendarApp from '../icons/32/Teamcalendar';
import usersApp from '../icons/32/Users';
import user from '../icons/32/User';
import salesmanApp from '../icons/32/SalesmanApp';
import waylaApp from '../icons/32/Wayla';
import referencesApp from '../icons/32/Book';
import filesApp from '../icons/32/FilesApp';
import file from '../icons/32/File';
import upload from '../icons/32/Upload';
// icons 12
import add from '../icons/12/Add';
import arrowDown from '../icons/12/ArrowDown';
import arrowLeft from '../icons/12/ArrowLeft';
import arrowRight from '../icons/12/ArrowRight';
import arrowUp from '../icons/12/ArrowUp';
import bell from '../icons/12/Bell';
import bin from '../icons/12/Bin';
import calendar from '../icons/12/Calendar';
import chevronDown from '../icons/12/ChevronDown';
import chevronLeft from '../icons/12/ChevronLeft';
import chevronRight from '../icons/12/ChevronRight';
import chevronUp from '../icons/12/ChevronUp';
import close from '../icons/12/Close';
import coffee from '../icons/12/Coffee';
import dashboard from '../icons/12/Dashboard';
import deleteIcon from '../icons/12/Delete';
import doubleArrowLeft from '../icons/12/DoubleArrowLeft';
import doubleArrowRight from '../icons/12/DoubleArrowRight';
import headset from '../icons/12/Headset';
import lunch from '../icons/12/Lunch';
import marker from '../icons/12/Marker';
import noticeAlert from '../icons/12/NoticeAlert';
import noticeError from '../icons/12/NoticeError';
import noticeInfo from '../icons/12/NoticeInfo';
import noticeSuccess from '../icons/12/NoticeSuccess';
import pen from '../icons/12/Pen';
import person from '../icons/12/Person';
import phone from '../icons/12/Phone';
import play from '../icons/12/Play';
import search from '../icons/12/Search';
import subtract from '../icons/12/Subtract';
import time from '../icons/12/Time';
import houseAparment from '../icons/12/HouseApartment';
import notesBook from '../icons/12/NotesBook';
import taskList from '../icons/12/TaskList';
import download from '../icons/12/Download';
import fileAdd from '../icons/12/FileAdd';
import folderAdd from '../icons/12/FolderAdd';
import lock from '../icons/12/Lock';
import chat from '../icons/12/Chat';
import chatWarning from '../icons/12/ChatWarning';
import userCheck from '../icons/12/UserCheck';

const icons = {
	// old icons
	personIconWhite,
	personIconBlack,
	addressIcon,
	clientIcon,
	calendarIcon,
	callBusyIcon,
	lostClientIcon,
	startCallIcon,
	streetViewIcon,
	workHoursIcon,
	groupLogo,
	// 32
	buildingsApp,
	callsApp,
	d2dApp,
	hrApp,
	mapsApp,
	calendarApp,
	projectSalesApp,
	reportsApp,
	statsApp,
	teamCalendarApp,
	usersApp,
	dashboardApp,
	user,
	monitorChart,
	leadsApp,
	salesmanApp,
	waylaApp,
	referencesApp,
	marketingApp,
	filesApp,
	file,
	upload,
	// 12
	add,
	arrowDown,
	arrowLeft,
	arrowRight,
	arrowUp,
	bell,
	bin,
	calendar,
	chevronDown,
	chevronLeft,
	chevronRight,
	chevronUp,
	close,
	coffee,
	dashboard,
	delete: deleteIcon,
	doubleArrowLeft,
	doubleArrowRight,
	headset,
	houseAparment,
	lunch,
	marker,
	notesBook,
	noticeAlert,
	noticeError,
	noticeInfo,
	noticeSuccess,
	pen,
	person,
	phone,
	play,
	search,
	subtract,
	taskList,
	time,
	download,
	fileAdd,
	folderAdd,
	lock,
	chat,
	chatWarning,
	userCheck,
};

const Wrapper = styled.span`
	${({smallRightSpacing}) => (smallRightSpacing ? 'margin-right: 5px;' : '')};
	${({smallLeftSpacing}) => (smallLeftSpacing ? 'margin-left: 5px;' : '')};
	height: ${({sizeProps: {height = '13px'}}) => height};
	width: ${({sizeProps: {width = '13px'}}) => width};
	display: inline-block;
	${({color}) => color && `color: ${color};`}

	> * {
		height: 100%;
		width: 100%;
	}
`;

const Icon = ({name, width, height, ...rest}) => {
	const sizeProps = {
		...(width ? {width} : {}),
		// prettier-ignore
		...(
			height ? {height}
			: width ? {}
			: {height: fontSizeBase}
		),
	};
	const IconClass = icons[name];

	return (
		<Wrapper sizeProps={sizeProps} aria-hidden={!rest['aria-label']} {...rest}>
			<IconClass />
		</Wrapper>
	);
};

Icon.propTypes = {
	name: propTypes.oneOf(Object.keys(icons)),
	width: propTypes.string,
	height: propTypes.string,
};

export default styled(Icon)``;
