import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	initialized: false,
	callLogs: [],
	users: [],
	organizations: [],
	searchQuery: {_page: 1},
	teams: [],
	teamType: '',
};
