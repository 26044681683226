import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions._setImportHistory.type: {
			const {data: importHistory, pagination: importHistoryPagination} = payload;
			return [{...state, loading: false, importHistory, importHistoryPagination}, null];
		}

		case actions.getImportHistory.type: {
			return [{...state, loading: true}, effects._getImportHistory()];
		}

		case actions.changePage.type: {
			const newPage = Math.max(1, payload);

			return [
				{
					...state,
					importHistoryQuery: {
						...state.importHistoryQuery,
						_page: newPage,
					},
				},
				null,
			];
		}

		case actions.showFileUploadModal.type: {
			return [{...state, isFileUploadModalOpen: true}, null];
		}

		case actions.hideFileUploadModal.type: {
			return [{...state, isFileUploadModalOpen: false}, null];
		}

		case actions.saveCsvImport.type: {
			return [
				{
					...state,
					processing: true,
				},
				effects.saveCsvImport(payload),
			];
		}
		case actions.saveCsvImportSuccess.type: {
			return [
				{
					...state,
					processing: false,
					isFileUploadModalOpen: false,
				},
			];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
