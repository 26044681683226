import services from 'services';
import {map, pipe, path, prop} from 'ramda';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {mapResponseData, getResponseData} from 'utils/app';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getNotices = query =>
	httpJson('get', `/notices`, {...query, _limit: 5})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.notice)));

export const getStatistics = query =>
	httpJson('post', '/statistics/forSalesmanApp', {}, {body: {_method: 'GET', ...query}})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(prop('data'));

export const getUserTeams = () =>
	httpJson('get', '/users/me', {include: 'teams'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(pipe(path(['teams', 'data']), map(normalize.team))));

export const getTargets = query =>
	httpJson('get', '/targets', query)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.target)));
