import services from 'services';
import {map} from 'ramda';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import {mapResponseData, getResponseData} from 'utils/app';
import {bansInclude, buildingsBansInclude} from './utils';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getBans = query =>
	httpJson('get', '/bans', {...query, include: bansInclude})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load ban list'})))
		.then(mapResponseData(map(normalize.client)));

export const getBuildingsBans = query =>
	httpJson('get', '/buildings', {...query, include: buildingsBansInclude, banned: true})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load ban list'})))
		.then(mapResponseData(map(normalize.building)));

export const deleteBan = banId =>
	httpJson('delete', `/bans/${banId}`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to remove ban'})),
	);

export const updateBan = ban =>
	httpJson('put', `/bans/${ban.id}`, {}, {body: ban})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to update ban'})))
		.then(getResponseData(map(normalize.ban)));

export const postBan = ban =>
	httpJson('post', '/bans', {}, {body: ban})
		.catch(describeThrow(intl.formatMessage({id: 'Kiellon lisäys epäonnistui'})))
		.then(getResponseData(map(normalize.ban)));
