import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	stats: {},
	statsLoading: true,
	targets: {},
	targetsLoading: true,
	targetOpen: null,
	callReminders: [],
	callRemindersLoading: true,
};
