import React from 'react';
import propTypes from 'prop-types';
import {connect} from 'react-redux';
import {applyState, applyDispatch} from 'utils/redux';
import {initialize} from 'modules/redirect/actions';
import View from 'components/generic/View';
import Page from 'components/generic/Page';
import Content from 'components/generic/Content';
import PageLoader from 'components/generic/PageLoader';

class RedirectPage extends React.Component {
	componentDidMount() {
		this.props.initialize();
	}

	render() {
		return (
			<View>
				<Page>
					<Content>
						<PageLoader />
					</Content>
				</Page>
			</View>
		);
	}
}

RedirectPage.propTypes = {
	initialize: propTypes.func,
	login: propTypes.func,
};

export default connect(applyState({}), applyDispatch({initialize}))(RedirectPage);
