import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {convertSecondsToTimer} from 'utils/time';
import styled from 'styled-components';
import {applyDispatch, applyState} from 'utils/redux';
import {browserSettingsLink} from 'utils/browserSettingsLink';
import * as nActions from 'modules/notifications/actions';
import {shortDur} from 'constants/notifications';
import {callSession, deviceId, enioCallerVisible} from 'modules/common/selectors';
import {toggleEnioCallerVisibility} from 'modules/common/actions';
import {compose} from 'ramda';
import {FormattedMessage} from '@meiko/react-intl';
import services from 'services';
import Button from 'components/generic/Button';
const history = services.get('history');

const CallContainer = styled.div`
	position: fixed;
	bottom: 20px;
	width: 600px;
	max-width: 100%;
	left: 50%;
	transform: translateX(-50%);
	height: 60px;
	background-color: white;
	color: black;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	border-radius: 10px;
	box-shadow: 0px 4px 8px -3px;
	z-index: 51;

	${props => {
		if (!props.visible) {
			return `
				left: calc(100%);
				transform: translateX(0%);
			`;
		}
	}}

	@media all and (max-width: 600px) {
		height: 120px;
		bottom: 0px !important;
		top: inherit !important;
	}
`;
const CallColumn = styled.div`
	position: relative;
	width: 100%;
	width: calc(100% - 120px);
	display: flex;
	flex-direction: column;

	@media all and (max-width: 600px) {
		order: 3;
		width: 100%;
	}
`;
const CallRow = styled.div`
	position: relative;
	width: calc(100% - 10px);
	padding: 0 0 0 10px;
	height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;

	&:first-child {
		border-bottom: 1px solid grey;
	}
`;
const CallIcon = styled.div`
	position: relative;
	width: 60px;
	height: 60px;
	font-size: 32px;
	color: white;
	background-color: ${props => props.bg};
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	${props => {
		return props.status === 'Ringing'
			? `
			animation: calling 4s infinite;

			@keyframes calling {
				0%,
				50%,
				100% {
					background-color: grey
				}

				25%,
				75% {
					background-color: #73c8a0
				}
			}

		`
			: '';
	}}

	@media all and (max-width: 600px) {
		border-radius: 0;
		width: 50%;
	}
`;
const CallIconButton = styled(Button)`
	width: 60px;
	height: 60px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 32px;
	cursor: pointer;
	border-radius: 0;

	@media all and (max-width: 600px) {
		border-radius: 0;
		width: 50%;
	}
`;
const CallIconButtonRounded = styled(CallIconButton)`
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
`;
const CallSettingsText = styled.p`
	position: absolute;
	margin: 0;
	right: 75px;
	top: 7px;
	cursor: pointer;
`;
const CallDetails = styled.p`
	position: relative;
	margin: 0;
`;
const CallDetailsLink = styled(CallDetails)`
	color: #419fc6;
	cursor: pointer;
`;
const CallDetailsLabel = styled.p`
	font-weight: bold;
	margin: 0;
`;

const callIconFunction = status => {
	let retVal;
	switch (status) {
		case 'In Call':
			retVal = '#73c8a0';
			break;
		case 'Media Added':
			retVal = '#73c8a0';
			break;
		case 'Busy Here':
			retVal = '#e67866';
			break;
		default:
			retVal = 'grey';
			break;
	}
	return retVal;
};

const callStatusMessage = status => {
	let callStatusMsg = '';
	switch (status) {
		case 'In Call':
			callStatusMsg = 'Call duration';
			break;
		case 'Ringing':
			callStatusMsg = 'Calling [waiting for answer]';
			break;
		case 'Trying':
			callStatusMsg = 'Connecting call';
			break;
		case 'Ok':
			callStatusMsg = 'Connection established';
			break;
		case 'Media Added':
			callStatusMsg = 'Connection established [call]';
			break;
		case 'Busy Here':
			callStatusMsg = 'Busy [call in progress]';
			break;
		case 'Call terminated':
			callStatusMsg = 'Call terminated';
			break;
		case 'Call terminating...':
			callStatusMsg = 'Call terminating...';
			break;
		case 'Not Found':
			callStatusMsg = 'Phonenumber not found';
			break;
		case 'Request Terminated':
			callStatusMsg = 'Request Terminated [calling]';
			break;
		default:
			callStatusMsg = 'No active call';
	}
	return callStatusMsg;
};

const EnioCallerSettings = styled.div`
	position: absolute;
	top: -${props => props.height}px;
	width: calc(100% - 118px);
	left: 0;
	right: 0;
	margin: 0 auto;
	height: ${props => props.height}px;
	background-color: white;
	border-top: 1px solid #e3e3e3;
	border-left: 1px solid #e3e3e3;
	border-right: 1px solid #e3e3e3;
	padding: 5px;
	display: flex;
	flex-direciton: row;
	flex-wrap: wrap;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	justify-content: center;

	> p {
		width: 100%;
		text-align: center;
		margin: 5px 0;
	}
	> input {
		margin: 0;
		width: 50%;
	}
	> button {
		width: 50%;
	}
`;
// const AudioSetting = styled.div`
// 	width: 50%;
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// 	align-items: center;
// `;
// const AudioSelect = styled.select`
// 	width: calc(100% - 47px);
// `;
// const setOutputDevice = async e => {
// 	const deviceId = e.target.value;
// 	const audio = document.getElementById('audio-remote');
// 	audio.setSinkId(deviceId);
// };

// const setInputDevice = async e => {
// 	console.log('test');
// };
// const Settings = props => {
// 	const audioDevices = props.audioDevices;
// 	return (
// 		<EnioCallerSettings>
// 			{/* <AudioSetting>
// 				<Label>Sisääntulo</Label>
// 				<AudioSelect onChange={setInputDevice}>
// 					{audioDevices.map(device => {
// 						return device?.kind === 'audioinput' ? (
// 							<option value={device.deviceId} key={device.deviceId}>
// 								{device.label}
// 							</option>
// 						) : null;
// 					})}
// 				</AudioSelect>
// 			</AudioSetting> */}
// 			<AudioSetting>
// 				<Label>Ulostulo</Label>
// 				<AudioSelect onChange={setOutputDevice}>
// 					{audioDevices.map(device => {
// 						return device?.kind === 'audiooutput' ? (
// 							<option value={device.deviceId} key={device.deviceId}>
// 								{device.label}
// 							</option>
// 						) : null;
// 					})}
// 				</AudioSelect>
// 			</AudioSetting>
// 		</EnioCallerSettings>
// 	);
// };

const copySettingToClipBoard = async value => {
	services.get('store').dispatch(
		nActions.success({
			id: 'clipboard-copied',
			duration: shortDur,
			message: services.get('intl').formatMessage({id: 'Link copied to clipboard'}),
		}),
	);
	navigator.clipboard.writeText(value);
};

const Settings = props => {
	const settingsLink = browserSettingsLink();
	if (!settingsLink) {
		return (
			<EnioCallerSettings height="30">
				<p>
					<FormattedMessage id="Microphone setting does not exist" />
				</p>
			</EnioCallerSettings>
		);
	}
	return (
		<EnioCallerSettings height="75">
			<p>1. Kopioi linkki 2. Avaa uusi vällilehti 3. Liitä linkki osoitekenttään</p>
			<input value={settingsLink} readOnly="readonly"></input>
			<Button appearance="success" onClick={() => copySettingToClipBoard(settingsLink)}>
				<i className="fa fa-clipboard" aria-hidden="true"></i>&nbsp;
				<FormattedMessage id="Copy link" />
			</Button>
		</EnioCallerSettings>
	);
};

const HideButton = styled.button`
	height: 40px;
	width: 20px;
	position: absolute;
	top: 10px;
	${props => {
		if (props.visible) {
			return 'right: -20px;';
		} else {
			return 'left: -20px;';
		}
	}}

	@media all and (max-width: 600px) {
		${props => {
			if (props.visible) {
				return 'right: 0px;';
			}
		}}
	}
`;

const HideButtonAction = e => {
	const parent = document.getElementById('callContainer');
	if (parent) {
		if (parent.hasAttribute('style')) {
			parent.removeAttribute('style');
		}
	}
};

const dragStop = e => {
	if (window.innerWidth < 650) {
		return;
	}
	if (
		e.pageX < 0 ||
		e.pageY < 0 ||
		e.pageX > window.innerWidth ||
		e.pageY > window.innerHeight
	) {
		return;
	}
	e.target.style.top = `${e.pageY}px`;
	e.target.style.left = `${e.pageX}px`;
};

const isMobileWidth = () => {
	if (window.innerWidth < 650) {
		return 'false';
	}
	return 'true';
};

const EnioCallerClient = props => {
	const {callSession, toggleEnioCallerVisibility, enioCallerVisible} = props;
	const [callTimer, setTimer] = useState(0);
	const [settingsVisible, toggleVisibility] = useState(false);
	// const [audioDevices, setAudiodevices] = useState({});
	// console.log(callSession?.customerDetails);

	const dragBoolean = isMobileWidth();

	useEffect(() => {
		if (window.innerWidth < 600) {
			toggleEnioCallerVisibility();
		}
	}, []);

	useEffect(() => {
		let timeoutId;
		//console.log(callSession?.status);
		if (callSession?.status === 'Ringing' && callTimer !== 0) {
			setTimer(0);
		}
		if (callSession?.status === 'Call terminated') {
			const callStopped = callSession?.endTime - callSession?.answerTime;
			if (callTimer !== callStopped && callTimer !== 0) {
				setTimer(callSession?.endTime - callSession?.answerTime);
			}
			clearTimeout(timeoutId);
			return;
		}
		if (callSession?.status === 'Media Added') {
			timeoutId = setTimeout(() => {
				setTimer(callTimer + 1);
			}, 1000);
		}
	}, [callSession, callTimer]);

	// useEffect(() => {
	// 	const audioDevicesGet = navigator.mediaDevices.enumerateDevices();
	// 	audioDevicesGet.then(devices => {
	// 		setAudiodevices(devices);
	// 	});
	// 	console.log(audioDevices);
	// }, [settingsVisible]);
	//console.log(callSession);

	return (
		<CallContainer
			id="callContainer"
			draggable={dragBoolean}
			onDragEnd={dragStop}
			visible={enioCallerVisible}
		>
			<HideButton
				visible={enioCallerVisible}
				onClick={() => {
					toggleEnioCallerVisibility();
					HideButtonAction();
				}}
			>
				{enioCallerVisible ? (
					<i className="fa fa-caret-right" aria-hidden="true"></i>
				) : (
					<i className="fa fa-caret-left" aria-hidden="true"></i>
				)}
			</HideButton>
			{/* prettier-ignore */}
			{!settingsVisible ? null : <Settings></Settings>}
			<CallIcon bg={callIconFunction(callSession.status)} status={callSession.status}>
				<i className="fa fa-phone" aria-hidden="true"></i>
			</CallIcon>
			<CallColumn>
				<CallRow>
					<CallDetailsLabel>
						<FormattedMessage id={callStatusMessage(callSession.status)} />
					</CallDetailsLabel>
					&nbsp;
					<CallDetails>
						{callSession?.status === 'Media Added' ||
						callSession?.status === 'Call terminating...' ||
						callSession?.status === 'Call terminated' ? (
							<>{convertSecondsToTimer(callTimer)}</>
						) : null}
					</CallDetails>
				</CallRow>
				<CallRow>
					{callSession?.status === 'Media Added' ||
					callSession?.status === 'Call terminated' ||
					callSession?.status === 'Call terminating...' ||
					callSession?.status === 'Busy Here' ||
					callSession?.status === 'Ringing' ? (
						<CallDetailsLink
							// This title needs translation
							title="Asiakkaan tiedot"
							onClick={() => {
								history.push(callSession.customerURI);
							}}
						>
							<i className="fa fa-user" aria-hidden="true"></i>&nbsp;
							{callSession?.customerDetails?.firstName}{' '}
							{callSession?.customerDetails?.lastName}
							{' - '}
							{callSession?.customerDetails?.building?.address}
							{', '}
							{callSession?.customerDetails?.building?.city}
						</CallDetailsLink>
					) : null}
				</CallRow>
			</CallColumn>

			<CallSettingsText onClick={() => toggleVisibility(!settingsVisible)}>
				<FormattedMessage id="Settings" />
			</CallSettingsText>

			{callSession?.status === 'Ringing' ||
			callSession?.status === 'In Call' ||
			callSession?.status === 'Trying' ||
			callSession?.status === 'Media Added' ? (
				<CallIconButtonRounded appearance="error" id="endCall">
					<i className="fa fa-times" aria-hidden="true"></i>
				</CallIconButtonRounded>
			) : null}
		</CallContainer>
	);
};
// window.addEventListener('devicechange', e => {
// 	console.log(e);
// 	navigator.mediaDevices.enumerateDevices().then(devices => {
// 		console.log(devices);
// 	});
// });
export default compose(
	withRouter,
	connect(
		applyState({callSession, deviceId, enioCallerVisible}),
		applyDispatch({
			toggleEnioCallerVisibility,
		}),
	),
)(EnioCallerClient);
