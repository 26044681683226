import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {createSelector} from 'reselect';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {formatClientFormInput, formatBuilding} from './utils';
import {createEmptyClient} from './constants';
import createBuildingPanelSelectors from 'fragments/buildingModalActions/selectors';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const processing = pipe(root, prop('processing'));

export const buildingDataLoading = pipe(root, prop('buildingDataLoading'));

export const buildingData = pipe(root, prop('building'));

export const building = createSelector([buildingData], building =>
	formatBuilding(building),
);

export const encounters = pipe(root, prop('encounters'));

export const clients = pipe(root, prop('clients'));

export const clientEditorOpen = pipe(root, prop('clientEditorOpen'));

export const clientInEdit = pipe(root, prop('clientInEdit'));

export const previewableEncounter = pipe(root, prop('previewableEncounter'));

export const newClients = pipe(root, prop('newClients'));

export const loading16100Clients = pipe(root, prop('loading16100Clients'));

export const clientFormInitValues = createSelector([clientInEdit], client =>
	formatClientFormInput({
		client: client || createEmptyClient(),
	}),
);

const buildingPanelSelectors = createBuildingPanelSelectors({namespace});

export const {buildingModalOpen} = buildingPanelSelectors;

export const areas = pipe(root, prop('areas'));

export const availableTags = pipe(root, prop('availableTags'));

export const tags = pipe(root, prop('tags'));

export const processingTags = pipe(root, prop('processingTags'));

export const processingTag = pipe(root, prop('processingTag'));
