import React from 'react';

const SvgIconCallBusy = props => (
	<svg viewBox="0 0 42.52 42.52" {...props} width="1em" height="1em">
		<g fill="currentColor">
			<path d="M21.26 1.476c-10.909 0-19.783 8.875-19.783 19.783 0 10.909 8.874 19.784 19.783 19.784s19.783-8.875 19.783-19.784c0-10.908-8.874-19.783-19.783-19.783zm0 37.691c-9.875 0-17.908-8.033-17.908-17.908 0-9.874 8.033-17.907 17.908-17.907s17.908 8.033 17.908 17.907c0 9.875-8.033 17.908-17.908 17.908z" />
			<path d="M30.878 20.106H11.836a1.153 1.153 0 1 0 0 2.308h19.042a1.153 1.153 0 1 0 0-2.308z" />
		</g>
	</svg>
);

export default SvgIconCallBusy;
