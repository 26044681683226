import {scopedHandler} from 'utils/redux';
import {getFormValues} from 'redux-form';
import ns from './namespace';
import initState from './state';
import {reject, isEmpty, sortBy, prop} from 'ramda';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {getNextFetchableCallpool, getRefreshRate} from './utils';
import {sortByTitle as sortAreas} from 'utils/areas';
import * as Ls from 'io/localStorage';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, effects.destroy()];
		}

		case actions.automaticDataFetcher.type: {
			const {callPools, cid, lastCallpoolFetched} = getNextFetchableCallpool(
				state.callPools,
				state.lastCallpoolFetched,
			);
			// set callPoolLoading to false if theres no next callPool to be fetched
			const callPoolLoading = cid ? true : false;

			return [
				{...state, lastCallpoolFetched, callPools, callPoolLoading},
				cid ? effects.fetchCallPoolAreaInfo(cid) : null,
			];
		}

		case actions.clearLastCallpoolFetched.type: {
			return [{...state, lastCallpoolFetched: 0}, null];
		}

		case actions.fetchCallPoolAreaInfo.type: {
			const cid = payload;
			const callPools = state.callPools.map(c =>
				c.id === cid ? {...c, loading: true} : c,
			);
			return [{...state, callPools}, effects.fetchCallPoolAreaInfo(cid)];
		}

		case actions.setAreaActivity.type: {
			const {areaId, callPoolId, active} = payload;
			return [
				{...state, processingAreaId: areaId, processingCallpoolId: callPoolId},
				effects.setAreaActivity({areaId, callPoolId, active}),
			];
		}

		case actions.boostCallpoolArea.type: {
			const {areaId, callPoolId} = payload;
			return [
				{...state, processingAreaId: areaId, processingCallpoolId: callPoolId},
				effects.boostCallPoolArea({areaId, callPoolId}),
			];
		}

		case actions.removeCallpoolArea.type: {
			const {areaId, callPoolId} = payload;
			return [state, effects.removeCallPoolArea({areaId, callPoolId})];
		}

		case actions.setAutomatedFetching.type: {
			const {refreshRate, automatedFetching} = getFormValues('settings-form')(fullState);
			const rfRate = getRefreshRate(refreshRate);

			Ls.setJson('overviewApp:settings', {refreshRate: rfRate, automatedFetching});

			return [
				{
					...state,
					refreshRate: rfRate,
					automatedFetching,
				},
				null,
			];
		}

		case actions.openCallpoolPanels.type: {
			const callPools = state.callPools.map(c => {
				return {...c, panelIsOpen: payload};
			});
			const newState = {
				...state,
				callPools,
			};

			return [newState, null];
		}

		case actions.togglePanelVisibility.type: {
			const {cid, panelIsOpen} = payload;
			const callPools = state.callPools.map(c =>
				c.id === cid ? {...c, panelIsOpen} : c,
			);
			const newState = {
				...state,
				callPools,
			};
			return [newState, null];
		}

		case actions.openCallPoolAreaModal.type: {
			return [{...state, callPoolAreaModalOpen: true, selectedCallPool: payload}, null];
		}

		case actions.closeCallPoolAreaModal.type: {
			return [{...state, callPoolAreaModalOpen: false, selectedCallPool: null}, null];
		}

		case actions.addAreaToCallPool.type: {
			return [
				{...state, processing: true},
				effects.addAreaToCallPool({
					areaData: payload,
					callPoolId: state.selectedCallPool.id,
				}),
			];
		}

		case actions._setCallPools.type: {
			const callPools = reject(isEmpty, Object.values(payload)).map(c => ({
				...c,
				panelIsOpen: true,
			}));

			const settings = Ls.getJson('overviewApp:settings');

			return [
				{
					...state,
					callPools: sortBy(prop('organizationId'), callPools),
					loadingCallPools: false,
					...settings,
				},
				null,
			];
		}

		case actions._removingCallPoolArea.type: {
			return [{...state, processingAreaId: payload}, null];
		}

		case actions._setCallPoolArea.type: {
			const area = payload[0];
			const cid = payload[1];
			// TODO WIP
			const callPools = state.callPools.map(c =>
				c.id === cid
					? {
							...c,
							areas: c.areas.map(a =>
								a.id === area.id
									? {
											...a,
											active: area.active,
											boostCount: area.boostCount,
											info: {...a.info, callableBuildings: area.callableBuildings},
									  }
									: a,
							),
					  }
					: c,
			);

			return [{...state, callPools, processingAreaId: null}, null];
		}

		case actions._setCallableBuildings.type: {
			const {callPoolId, areaId, countOfCallableBuildings} = payload;

			const callPools = state.callPools.map(c =>
				c.id === callPoolId
					? c.areas.map(a =>
							a.id === areaId
								? {...a, info: {callableBuildings: countOfCallableBuildings}}
								: a,
					  )
					: c,
			);
			return [{...state, callPools}, null];
		}

		case actions._setCallPoolWithAreaInfo.type: {
			const callPool = {...payload, loading: false};
			const callPools = state.callPools.map(c =>
				c.id !== callPool.id ? c : {...c, ...callPool},
			);
			return [{...state, callPools: callPools, callPoolLoading: false}, null];
		}

		case actions._setCallPoolsAfterRemoval.type: {
			const {callPoolId, areaId} = payload;
			const callPools = state.callPools.map(c =>
				c.id === callPoolId ? {...c, areas: c.areas.filter(a => a.id !== areaId)} : c,
			);

			return [{...state, callPools, processingAreaId: null}, null];
		}

		case actions._setAllAreas.type: {
			return [{...state, allAreas: sortAreas(payload), allAreasLoading: false}, null];
		}

		case actions._addAreaToCallPool.type: {
			return [
				{
					...state,
					processing: false,
					callPoolAreaModalOpen: false,
					selectedCallPool: null,
				},
				null,
			];
		}

		case actions._addAreaToCallPoolFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._setCallPoolLoading.type: {
			const cid = payload;
			const callPools = state.callPools.map(c =>
				c.id === cid ? {...c, loading: true} : c,
			);
			return [{...state, callPools}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processingAreaId: null, loadingCallPools: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
