import {evolve, groupBy, prop} from 'ramda';
import {replace} from 'utils/objects';
import {setHour} from 'utils/time';
import initState from './state';

export const parseUrlQuery = query => {
	const vals = evolve(
		{
			teamId: Number,
			date: d => (d ? new Date(Number(d)) : new Date()),
		},
		query,
	);

	return {calendarQuery: replace(vals, initState.calendarQuery)};
};

export const formatUrlQuery = ({calendarQuery}) => {
	const {teamId, date} = calendarQuery;

	return {
		teamId,
		date: date.getTime(),
	};
};

export const formatFetchableCalendarQuery = ({calendarQuery}) => {
	const {teamId, date} = calendarQuery;
	const dateFrom = date.toJSON();
	const dateTo = setHour(date, 23, 59, 59, 999).toJSON();

	return {
		teamId,
		dateFrom,
		dateTo,
	};
};

export const sortByDateFrom = resources =>
	resources.sort((a, b) => a.dateFrom - b.dateFrom);

export const getRoutesFromCalendarResources = resources => {
	const bySalesman = groupBy(
		prop('salesmanId'),
		resources.filter(
			r => !!r.salesmanId && !r.cancelled && ['reserved', 'completed'].includes(r.type),
		),
	);

	const routes = Object.values(bySalesman).map((r, i) => ({
		routeNum: i,
		visible: true,
		salesmanId: r.length ? r[0].salesmanId : null,
		calendarResources: sortByDateFrom(r),
	}));

	return routes;
};

export const getUnallocated = resources => {
	return sortByDateFrom(
		resources.filter(
			r => !r.salesmanId && !r.cancelled && ['reserved', 'completed'].includes(r.type),
		),
	);
};

export const setRoutesDirectionData = ({routes, directions}) =>
	routes.map(r => {
		const rDirections = directions[r.routeNum];
		return {
			...r,
			distance: rDirections ? rDirections.distance : null,
			calendarResources: r.calendarResources.map((cr, i) => {
				// add distanceToNext/durationToNext attribute to each calendarResource on the route
				const leg = rDirections && rDirections.legs[i] ? rDirections.legs[i] : null;
				return {
					...cr,
					distanceToNext: leg ? leg.distance : null,
					durationToNext: leg ? leg.duration : null,
				};
			}),
		};
	});

export const getRoutesTotalDistance = routes => {
	let total = 0;
	for (let i = 0; i < routes.length; i++) {
		total += routes[i].distance;
	}
	return total;
};

const getDiffInSeconds = (d1, d2) => Math.abs(d1.getTime() - d2.getTime()) / 1000;

// checks if next stop is too far away (hours till next CR - driving duration to next stop - visit duration <= 0)
export const isNextStopTooFar = ({route, crIndex}) => {
	const visitDur = 2700; // 45 minutes
	const cr = route.calendarResources[crIndex];
	const nextCr = route.calendarResources[crIndex + 1];

	if (nextCr) {
		// time difference till next CR (in seconds)
		const diffToNext = getDiffInSeconds(cr.dateFrom, nextCr.dateFrom);
		// driving duration to the next place (in seconds)
		const durationToNext = cr.durationToNext ? cr.durationToNext.value : 0;
		return diffToNext - durationToNext - visitDur <= 0;
	}

	return false;
};

export const parseDates = calendarResources => {
	return calendarResources.map(cr => ({
		...cr,
		dateFrom: cr.dateFrom ? new Date(cr.dateFrom) : null,
		dateTo: cr.dateTo ? new Date(cr.dateTo) : null,
		previousDateFrom: cr.previousDateFrom ? new Date(cr.previousDateFrom) : null,
		previousDateTo: cr.previousDateTo ? new Date(cr.previousDateTo) : null,
	}));
};

export const formatSaveRoutesOutput = ({routes, extra}) => {
	let calendarResources = [];

	// set CR's salesmanId to route's salesmanId for each CR on the route
	routes.forEach(route => {
		route.calendarResources.forEach(cr => {
			calendarResources = [
				...calendarResources,
				{calendarResourceId: cr.id, salesmanId: route.salesmanId},
			];
		});
	});

	// set salesmanId to null for unallocated CRs
	extra.forEach(e => {
		calendarResources = [...calendarResources, {calendarResourceId: e.id, salesmanId: 0}];
	});

	return calendarResources;
};

export const formatRoutePlannerLog = ({team, date, routes, total}) => {
	return {
		teamId: team.id,
		date: date.toISOString().split('T')[0],
		totalKm: total,
		routes: routes.map(r => ({salesmanId: r.salesmanId, distance: r.distance})),
	};
};

export const getNextPossibleRoutes = ({routes, calRes, extra, clearAll = false}) => {
	const calResDateFrom = new Date(calRes.dateFrom);
	const dateFromEnd = new Date(calResDateFrom);
	dateFromEnd.setHours(dateFromEnd.getHours() + 3);

	const _routes = routes.map(r => {
		const calendarResources = r.calendarResources.map(cr =>
			new Date(cr.dateFrom) < dateFromEnd &&
			new Date(cr.dateFrom) > calResDateFrom &&
			!clearAll
				? {...cr, closeBy: true}
				: {...cr, closeBy: false},
		);

		return {...r, calendarResources};
	});

	const _extra = extra.map(cr =>
		new Date(cr.dateFrom) <= dateFromEnd &&
		new Date(cr.dateFrom) > calResDateFrom &&
		!clearAll
			? {...cr, closeBy: true}
			: {...cr, closeBy: false},
	);

	return {routes: _routes, extra: _extra};
};
