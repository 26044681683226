import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions._setMetrics.type: {
			return [{...state, metrics: payload, metricsLoading: false}, null];
		}

		case actions._setPiimegaLogs.type: {
			return [{...state, piimegaLogs: payload, piimegaLogsLoading: false}, null];
		}

		case actions._setFeedback.type: {
			return [{...state, feedback: payload, feedbackLoading: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
