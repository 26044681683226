import {setCallSession} from 'modules/common/actions';
import {callSession} from 'modules/common/selectors';
import {logWarning, logError} from 'io/errors';
import * as nActions from 'modules/notifications/actions';
import services from 'services';
import {longDur} from 'constants/notifications';

const busyhere = document.getElementById('busyhereBeta');
const callEndedAudio = document.getElementById('callended');

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const PLAY_ACTION = 'play';
const PAUSE_ACTION = 'pause';
// Safely interact with audio elements.
// Prevents page crashing if audio elements are started/stopped due to autoplay restrictions (see: https://developer.chrome.com/blog/autoplay/)
const handleAudioAction = async (el, action) => {
	try {
		await el[action]();
	} catch (e) {
		//
	}
};
const playAudio = el => handleAudioAction(el, PLAY_ACTION);
const pauseAudio = el => handleAudioAction(el, PAUSE_ACTION);

export const endCall = () => {
	if (window?.enioCallSession === undefined) return;
	if (typeof window?.enioCallSession?.hangup !== 'function') return;
	window?.enioCallSession?.hangup();
};

const showError = (callDesc, store) => {
	const msg = intl.formatMessage({id: 'Handled eniocaller event'});
	const stateMsg = intl.formatMessage({id: callDesc + ' [calling]'});
	const notification = nActions.warning({
		id: 'handled-enio-error',
		message: msg + ' - ' + stateMsg,
		duration: longDur,
	});
	store.dispatch(notification);
};

const generalErrorHandle = async (e, store, errorMsg = null) => {
	// Report every general error to bugsnag
	const sipStateError = new Error('Call session error - ' + e?.description);
	logError(sipStateError);

	const callStatus = callSession(store.getState());
	const retVal = {
		...callStatus,
		status: e.description,
		active: false,
	};
	store.dispatch(setCallSession(retVal));
	const msg = errorMsg !== null ? errorMsg : e.description;
	showError(msg, store);
	callEndedAudio.currentTime = 0;
	playAudio(callEndedAudio);
	endCall();
};

export const enioCallDescriptions = {
	'Call in progress...': async (e, store, callData = {}) => {},
	Trying: async (e, store, callData = {}) => {
		//console.log('Yhdistetään verkkoon...');
		// When call status is "Trying", call needs to have property "active" set to true to prevent duplicate calls
		const retVal = {
			id: 'test',
			status: e.description,
			active: true,
			startTime: Math.floor(Date.now() / 1000),
			answerTime: null,
			endTime: null,
			customerURI: window.location.pathname + window.location.search,
			customerDetails: callData,
		};
		store.dispatch(setCallSession(retVal));
		busyhere.currentTime = 0;
		return retVal;
	},
	Ringing: async (e, store, callData = {}) => {
		//console.log('Soittaa...');
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: true,
			startTime: callStatus.startTime,
			answerTime: null,
			endTime: null,
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		await busyhere.pause();
		return retVal;
	},
	OK: async (e, store, callData = {}) => {
		const callStatus = callSession(store.getState());
		//console.log('Puhelu yhdistetty');
		const retVal = {
			id: 'test',
			status: e.description,
			active: true,
			startTime: callStatus?.startTime ? callStatus?.startTime : null,
			answerTime: null,
			endTime: null,
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	// Call is in
	'In Call': async (e, store, callData = {}) => {
		const callStatus = callSession(store.getState());
		//console.log('Puhelussa');
		let retVal = {
			id: 'test',
			status: e.description,
			active: true,
			startTime: callStatus.startTime,
			answerTime: Math.floor(Date.now() / 1000),
			endTime: null,
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		//console.log(callStatus);
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	// Proper connection is made
	'Media Added': async (e, store, callData = {}) => {
		//console.log('Media lisätty');
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: true,
			startTime: callStatus.startTime,
			answerTime: callStatus.answerTime,
			endTime: callStatus?.endTime ? callStatus?.endTime : null,
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	// Call terminated = Client has terminated the call
	'Call terminated': async (e, store, callData = {}) => {
		//console.log('Puhelu päättyi');
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: false,
			startTime: callStatus.startTime,
			answerTime: callStatus.answerTime,
			endTime: Math.floor(Date.now() / 1000),
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		if (callStatus.status !== 'Call terminating...') {
			callEndedAudio.currentTime = 0;
			playAudio(callEndedAudio);
		}
		return retVal;
	},
	// Call terminating... = User has terminated the phone
	'Call terminating...': async (e, store, callData = {}) => {
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: false,
			startTime: callStatus.startTime,
			answerTime: callStatus.answerTime,
			endTime: Math.floor(Date.now() / 1000),
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	// Busy Here = Client does not want to answer or line is in use
	'Busy Here': async (e, store, callData = {}) => {
		//console.log('Varattu');
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: false,
			startTime: callStatus.startTime,
			answerTime: null,
			endTime: Math.floor(Date.now() / 1000),
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		playAudio(busyhere);
		setTimeout(async () => {
			pauseAudio(busyhere);
		}, 2500);
		return retVal;
	},
	'Request Terminated': async (e, store, callData = {}) => {
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: false,
			startTime: callStatus.startTime,
			answerTime: null,
			endTime: Math.floor(Date.now() / 1000),
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	'Not Found': async (e, store, callData = {}) => {
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: false,
			startTime: callStatus.startTime,
			answerTime: null,
			endTime: Math.floor(Date.now() / 1000),
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		playAudio(busyhere);
		setTimeout(async () => {
			pauseAudio(busyhere);
		}, 5500);
		return retVal;
	},
	'Session Progress': async (e, store, client = {}) => {
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: true,
			startTime: callStatus.startTime,
			answerTime: null,
			endTime: null,
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	'Temporarily Unavailable': async (e, store, callData = {}) => {
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: false,
			startTime: callStatus.startTime,
			answerTime: null,
			endTime: Math.floor(Date.now() / 1000),
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	Forbidden: async (e, store, callData = {}) => await generalErrorHandle(e, store),

	'Call Is Being Forwarded': async (e, store, callData = {}) => {
		const callStatus = callSession(store.getState());
		const retVal = {
			...callStatus,
			status: e.description,
		};
		store.dispatch(setCallSession(retVal));
	},
	sipError: async (e, store, callData = {}) =>
		await generalErrorHandle(e, store, 'SIP error'),
	'Does Not Exist Anywhere': async (e, store, callData = {}) =>
		await generalErrorHandle(e, store),
	'Concurrent third party phone calls exceeds configured value for user': async (
		e,
		store,
		callData = {},
	) => await generalErrorHandle(e, store),
	'Not Acceptable': async (e, store, callData = {}) => await generalErrorHandle(e, store),
	'Server Time-out': async (e, store, callData = {}) =>
		await generalErrorHandle(e, store),
	'Transport error': async (e, store, callData = {}) =>
		await generalErrorHandle(e, store),
	'Address Incomplete': async (e, store, callData = {}) =>
		await generalErrorHandle(e, store),
	'Temporarily not available': async (e, store, callData = {}) =>
		await generalErrorHandle(e, store),

	unknown: async (e, store, callData = {}) => {
		// This include check is here because handling SIP error would require use of regex and it is very hard to implement this kind of system to function calls
		// That is why we just check that if event description contains this kind of string then we run sipError function.
		if (e?.description.includes(`can't be found in`)) {
			enioCallDescriptions.sipError(e, store, callData);
			return;
		}
		const callDetails = callSession(store.getState());
		// We set call active status to false just in case. It exposes system to possible duplicate calls, but
		// it wont prevent user from continuing calling after this. We do not want to terminate call when
		// unknown state happends because it could happen in middle of call.
		const retVal = {
			...callDetails,
			active: false,
			errors: {
				eventName: e?.description,
			},
		};
		logWarning(new Error('Unknown call event happened - ' + e?.description));
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
};
