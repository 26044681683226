import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {FormattedMessage} from '@meiko/react-intl';
import memoize from 'memoize-one';
import {
	screenSmMin,
	screenLgMin,
	textColorBase,
	linkColor,
	colors,
	radiusLarge,
} from 'styles/constants';
import {listUnstyled, textStyles} from 'styles/fragments';
import {pageDefs} from 'constants/pageDefs';
//components
import Icon from 'components/generic/Icon';
import RouterLink from 'components/generic/RouterLink';
import Link from 'components/generic/Link';
import HelpButton from 'components/generic/HelpButton';
import P from 'components/generic/P';

const AppIcon_ = ({size, ...rest}) => <Icon width={size} height={size} {...rest} />;

const AppIcon = styled(AppIcon_)`
	color: ${colors.secondary2};
`;

AppIcon_.propTypes = {size: propTypes.string.isRequired};

const appLink = `
	${textStyles.display2};
	display: inline-flex;
	align-items: center;
	padding: 25px 25px;
	border-radius: ${radiusLarge};
	white-space: nowrap;
	text-transform: uppercase;
	background: white;
	&& {
		text-decoration: none;
		color: ${textColorBase};
	}

	&:hover {
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
		color: ${linkColor};

		> ${AppIcon} {
			color: ${linkColor};
		}
	}

	> ${AppIcon} {
		margin-right: 15px;
	}
`;

const AppLink = styled(RouterLink)`
	${appLink};
`;

const AppLinkExternal = styled(Link)`
	${appLink};
`;

const AppItemWrapper = styled.li`
	flex-grow: 1;
	flex-basis: 0;
	position: relative;
	max-width: 450px;
	min-width: 290px;

	> * {
		width: 100%;
	}
`;

const HelpContainer = styled.span`
	position: absolute;
	right: 10px;
	top: 10px;
	width: auto;
`;

const AppItem = ({children, to, href, help, ...rest}) =>
	to ? (
		<AppItemWrapper {...rest}>
			<AppLink to={to}>{children}</AppLink>
			{help && (
				<HelpContainer>
					<HelpButton big>
						<P>
							<FormattedMessage id={help} />
						</P>
					</HelpButton>
				</HelpContainer>
			)}
		</AppItemWrapper>
	) : (
		<AppItemWrapper {...rest}>
			<AppLinkExternal href={href} target="_blank" rel="noopener noreferrer">
				{children}
			</AppLinkExternal>
		</AppItemWrapper>
	);

const Apps = styled.ul`
	${listUnstyled};
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 5px;
	margin: -10px 0px;

	> * {
		margin: 5px;
	}

	@media (min-width: ${screenSmMin}) {
		> * {
			margin: 10px;
		}
	}

	@media (min-width: ${screenLgMin}) {
		padding: 0;
		> * {
			margin: 15px;
		}
	}
`;

const AppName = styled.span`
	padding-left: 10px;
`;

class AppItems extends React.Component {
	getPageDefs = memoize(pageDefs);

	render() {
		const {user, activeOrganization} = this.props;
		const pageDefs = this.getPageDefs(user, activeOrganization);

		return (
			<Apps>
				{pageDefs.map(app => (
					<AppItem
						key={app.id}
						app={app}
						isActive={false}
						user={user}
						to={app.to}
						href={app.href}
						help={app.help}
					>
						<AppIcon name={app.iconName} size="32px" />
						<AppName>
							<FormattedMessage id={app.name} />
						</AppName>
					</AppItem>
				))}
			</Apps>
		);
	}
}

export default AppItems;
