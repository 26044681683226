import {scopedHandler} from 'utils/redux';
import {getFormValues, getFormInitialValues} from 'redux-form';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {formatProfileFormOutput} from './utils';
import * as commonSelectors from 'modules/common/selectors';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.updateUser.type: {
			const form = getFormValues('profileForm')(fullState);
			const formInitial = getFormInitialValues('profileForm')(fullState);
			const needsReload =
				form.language !== formInitial.language ||
				// soittolinja gets loaded on common module init, so just refresh the page if it's toggled on
				// defaultCall needs to be updated for each case so refresh always
				form.defaultCall !== formInitial.defaultCall;
			const user = formatProfileFormOutput(form);
			const id = commonSelectors.user(fullState).id;

			return [
				{...state, processing: true},
				effects.updateUserData({id, user, needsReload}),
			];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._userUpdated.type: {
			return [{...state, processing: false}, null];
		}

		default:
			return [state, null];
	}
});
export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
