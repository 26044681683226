import {action} from 'utils/redux';
import namespace from './namespace';
import {P, Nullable} from 'utils/types';
const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const _setCallLogs = creator('_setCallLogs', Nullable(P.Array));

export const _setTimeEntries = creator('_setTimeEntries', Nullable(P.Object));

export const _setUsers = creator('_setUsers', P.array);

export const _setOrganizations = creator('_setOrganizations', P.array);

export const _initialize = creator('_initialize');

export const searchCallLogs = creator('searchCallLogs');

export const getTeams = creator('getTeams');

export const _setTeams = creator('_setTeams');

export const fetchTeamWithUsers = creator('fetchTeamWithUsers', Nullable(P.Array));

export const fetchTeamsWithUsers = creator('fetchTeamsWithUsers', Nullable(P.Array));

export const setTeamType = creator('setTeamType');

export const _setTeamType = creator('_setTeamType', P.String);

export const _setTrackingData = creator('_setTrackingData', P.Array);
