import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import initState from './state';

export const parseUrlQuery = query => {
	const vals = evolve({_page: Number}, query);
	return {remindersQuery: replace(vals, initState.remindersQuery)};
};

export const formatUrlQuery = ({remindersQuery}) => remindersQuery;
