import {action} from 'utils/redux';
import namespace from './namespace';
import {P, PaginatedData} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const getTrackerStatistics = creator('getTrackerStatistics');

export const getNotices = creator('getNotices', P.Number);

export const _setUserNotices = creator('_setUserNotices', PaginatedData(P.Array));

export const _setTrackerStatistics = creator('_setTrackerStatistics', P.Object);

export const _setTeamTrackerStatistics = creator('_setTeamTrackerStatistics', P.Object);

export const _opOk = creator('_opOk');

export const _setUserTargets = creator('_setUserTargets', P.Array);

export const _setTrackerLoading = creator('_setTrackerLoading');

export const _setTargets = creator('_setTargets', P.Object);

export const _setStats = creator('_setStats', P.Object);

export const _noticesFetchFailed = creator('_noticesFetchFailed');
