import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import initState from './state';
import {parseNullableNumber} from 'utils/fields';

export const parseUrlQuery = query => {
	const vals = evolve(
		{
			z: Number,
			x: Number,
			y: Number,
			maxYear: parseNullableNumber,
			minYear: parseNullableNumber,
			buildingId: parseNullableNumber,
			projectBuildingId: parseNullableNumber,
			minApartments: parseNullableNumber,
			maxApartments: parseNullableNumber,
		},
		query,
	);
	return {buildingsQuery: replace(vals, initState.buildingsQuery)};
};

export const formatUrlQuery = ({buildingsQuery}) => buildingsQuery;
