import {state as lifecycle} from 'fragments/lifecycle';
import {localeCenterWeb, localeZoomFactor} from 'constants/maps';

export const initialFilters = {
	encounterState: 'any',
	minYear: null,
	maxYear: null,
	buildingType: 'any',
	minApartments: null,
	maxApartments: null,
};

export default {
	...lifecycle,
	buildingsQuery: {
		z: localeZoomFactor * 5,
		x: localeCenterWeb[0],
		y: localeCenterWeb[1],
		buildingId: null,
		projectBuildingId: null,
		mapSource: 'here-streets',
		...initialFilters,
	},
};
