import services from 'services';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {getResponseData, mapResponseData} from 'utils/app';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const defaultIncludes = '';

export const getDashboardTargets = (query = {}) =>
	httpJson('GET', '/targets', {include: defaultIncludes, ...query}, {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(normalize.dashboardTarget));

export const createDashboardTarget = input =>
	httpJson('POST', '/targets', {}, {body: input})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.dashboardTarget));
