import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {spacedItems} from 'styles/fragments';

const Block = styled.div`
	${spacedItems};
`;

const Inline = styled.span`
	${spacedItems};
`;

const SpacedItems = ({inline, ...rest}) =>
	inline ? <Inline {...rest} /> : <Block {...rest} />;

SpacedItems.propTypes = {inline: propTypes.bool};

export default styled(SpacedItems)``;
