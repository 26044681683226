import {objectListToCheckboxVals, checkboxValsToIdObjectList} from 'utils/data';

export const formatRoleFormInput = ({role, permissions}) => {
	return {
		...role,
		permissions: objectListToCheckboxVals(permissions, role.permissions),
	};
};

export const formatRoleFormOutput = ({form}) => {
	const role = {
		...form,
		permissions: checkboxValsToIdObjectList(form.permissions),
	};

	return {role};
};

export const updateRolesList = ({roles, role}) => {
	if (roles.find(r => r.id === role.id)) {
		return roles.map(r => (r.id === role.id ? {...r, ...role} : r));
	} else {
		return [...roles, role];
	}
};
