import createCache from 'services/createCache';

export default createCache({
	freerideMap: {
		map: null,
		buildingsLayer: null,
		locationLayer: null,
		geolocation: null,
		mapLayer: null,
		markerLayer: null,
		dragInteraction: null,
		groundwaterAreasLayer: null,
		propertyLinesLayer: null,
	},
});
