import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {createSelector} from 'reselect';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {
	formatUrlQuery,
	formatBuildingsSearchInput,
	formatAddBuildingInput,
} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const buildingsQuery = pipe(root, prop('buildingsQuery'));

export const buildingsQueryFetchable = buildingsQuery;

export const urlQuery = createSelector([buildingsQuery], buildingsQuery => {
	return formatUrlQuery({buildingsQuery});
});

export const buildingsSearchInitValues = createSelector(
	[buildingsQuery],
	buildingsQuery => formatBuildingsSearchInput({buildingsQuery}),
);

export const addBuildingInitValues = createSelector([buildingsQuery], buildingsQuery =>
	formatAddBuildingInput({buildingsQuery}),
);

export const buildings = pipe(root, prop('buildings'));

export const buildingsPagination = pipe(root, prop('buildingsPagination'));

export const buildingsLoading = pipe(root, prop('buildingsLoading'));

export const showPrompt = pipe(root, prop('showPrompt'));

export const addBuildingModalOpen = pipe(root, prop('addBuildingModalOpen'));

export const processing = pipe(root, prop('processing'));
