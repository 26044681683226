import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const teams = pipe(root, prop('teams'));

export const roles = pipe(root, prop('roles'));

export const organizations = pipe(root, prop('organizations'));

export const selectedOrganizations = pipe(root, prop('selectedOrganizations'));

export const appPermissions = pipe(root, prop('appPermissions'));

export const otherPermissions = pipe(root, prop('otherPermissions'));

export const permissions = pipe(root, prop('permissions'));

// export const permissions = createSelector([user], ({roles}) => {
// 	const isAdmin = !!roles.find(r => adminRoles.includes(r.slug));
// 	return {statsPage: isAdmin, trackingPage: isAdmin};
// });
