import {FormattedMessage, injectIntl} from '@meiko/react-intl';
import PanelHeaderTitle from 'components/generic/ModalHeaderTitle';
import Panel from 'components/generic/Panel';
import useWindowWidth from 'hooks/windowWidth';
import React from 'react';
import Chart from 'react-chartjs-2';
import {compose} from 'redux';

const options = {
	scales: {
		x: {
			stacked: true,
		},
		y: {
			stacked: true,
		},
	},
	maintainAspectRatio: false,
};

const SalesChart = ({data, intl}) => {
	const {width: windowWidth} = useWindowWidth();

	const datasByType = data.reduce((acc, row) => {
		(row?.values ?? []).forEach(({label, value}) => {
			const cur = acc[label] ?? [];
			acc[label] = [...cur, value];
		});
		return acc;
	}, {});
	const chartData = {
		labels: data.map(entry => entry.columnLabel),
		datasets: [
			{
				type: 'line',
				label: intl.formatMessage({id: 'Target'}),
				borderColor: 'rgb(255, 99, 132)',
				borderWidth: 2,
				fill: false,
				data: data.map(entry => entry.target),
				datalabels: {
					display: false,
				},
			},
			...Object.keys(datasByType).map((key, index) => {
				const d = datasByType[key];
				const total = d.reduce((acc, cur) => acc + cur, 0);
				return {
					type: 'bar',
					label: `${key} (${intl.formatMessage({id: 'In total'})}: ${intl.formatNumber(
						total,
					)})`,
					backgroundColor: `rgba(75, 192, 192, ${1 - index * 0.2})`,
					data: d,
					borderColor: 'transparent',
				};
			}),
		],
	};
	return (
		<Panel
			header={
				<PanelHeaderTitle textAlign="center">
					<FormattedMessage id="Sales target - realization" />
				</PanelHeaderTitle>
			}
		>
			<Chart
				type="bar"
				height={windowWidth > 800 ? 300 : 250}
				res
				data={chartData}
				options={options}
			/>
		</Panel>
	);
};

export default compose(injectIntl)(SalesChart);
