import services from 'services';
import {map, pipe, path} from 'ramda';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {getResponseData, mapResponseData} from 'utils/app';
import {usersQueryBase, userInclude} from './constants';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getUsers = query =>
	httpJson('get', '/users', {...usersQueryBase, include: userInclude, ...query})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.user)));

export const getUserTeams = () =>
	httpJson('get', '/users/me', {include: 'teams'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(pipe(path(['teams', 'data']), map(normalize.team))));

export const putUser = (id, user) =>
	httpJson('put', `/users/${id}`, {}, {body: user})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save user'})))
		.then(getResponseData(pipe(normalize.user)));

export const postUser = user =>
	httpJson('post', '/users', {}, {body: user}).catch(
		describeThrow(intl.formatMessage({id: 'Failed to save user'})),
	);

export const deleteUser = id =>
	httpJson('delete', `/users/${id}`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to delete user'})),
	);

export const reviveUser = id =>
	httpJson('post', `/users/${id}/restore`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to restore'})),
	);

export const getExcel = roles =>
	httpJson('post', '/users/excel', {}, {body: {_method: 'GET', ...roles}}).catch(
		describeThrow(intl.formatMessage({id: 'Failed to load Excel'})),
	);

export const createEniocallerDetails = user =>
	httpJson('post', '/enioCaller/createUserAndTicket/' + user.id, {})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to create eniodetails'})))
		.then(data => {
			return data;
		});

export const deleteEniocallerDetails = user =>
	httpJson('post', '/enioCaller/deleteUserAndTicket/' + user.id, {})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to delete eniodetails'})))
		.then(userFromServer => {
			return userFromServer;
		});
