import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.openFileModal.type: {
			return [{...state, fileModalOpen: true, fileInEdit: payload}, null];
		}

		case actions.closeFileModal.type: {
			return [{...state, fileModalOpen: false, fileInEdit: null}, null];
		}

		case actions.saveFile.type: {
			return [{...state, processing: true}, effects.saveFile(payload)];
		}

		case actions.openCategoryModal.type: {
			return [{...state, categoryModalOpen: true, categoryInEdit: payload}, null];
		}

		case actions.closeCategoryModal.type: {
			return [{...state, categoryModalOpen: false, categoryInEdit: null}, null];
		}

		case actions.saveCategory.type: {
			return [{...state, processing: true}, effects.saveCategory(payload)];
		}

		case actions.removeCategory.type: {
			return [state, effects.removeCategory(payload)];
		}

		case actions.removeFile.type: {
			return [state, effects.removeFile(payload)];
		}

		case actions._setProcessing.type: {
			return [{...state, processing: payload}, null];
		}

		case actions._setCategories.type: {
			return [{...state, categories: payload, categoriesLoading: false}, null];
		}

		case actions._setRoles.type: {
			return [{...state, roles: payload}, null];
		}

		case actions._setOrganizations.type: {
			return [{...state, organizations: payload}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
