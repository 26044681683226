import services from 'services';
import importMaps from 'services/importMaps';
import {map, pipe, path, prop} from 'ramda';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {getResponseData} from 'utils/app';
import {apiUrl} from 'constants/app';
import {
	styles as mapStyles,
	maxZoom,
	localeCenterWeb,
	localeZoomFactor,
	areasMaxDetailZoom,
} from 'constants/maps';
import {deleteChildren} from 'utils/dom';
import {
	safeGetFeaturesAtPixel,
	safeHasFeatureAtPixel,
	addTooltipToLayers,
	renderAreaTooltip,
	_getMapTileSource,
} from 'io/maps';
import {encodeQuery} from 'utils/url';
import {getJsonProperties} from 'utils/maps';
import {getTeamArea} from './utils';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const decorateMapMethod = method => args =>
	importMaps()
		.then(method(args))
		.catch(describeThrow(intl.formatMessage({id: 'Error loading map'})));

// resources

export const getUserTeams = query =>
	httpJson('get', '/users/me', {include: 'teams.areas:allAreas(true)', ...query})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(pipe(path(['teams', 'data']), map(normalize.team))));

export const getTeams = query =>
	httpJson('get', '/teams', {
		include: 'areas:allAreas(true)',
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to fetch teams'})))
		.then(pipe(prop('data'), map(normalize.team)));

export const updateTeam = team =>
	httpJson('put', `/teams/${team.id}`, {include: 'areas:allAreas(true)'}, {body: team})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save team'})))
		.then(getResponseData(normalize.team));

// maps

const initZoom = 5 * localeZoomFactor;

const _getAreasStyle = ({team}) => imports => {
	const {openLayers: ol} = imports;

	const sty = mapStyles(ol);
	const keys = ['id', 'title'];

	return feature => {
		const {id, title} = getJsonProperties(feature, keys);

		const teamArea = getTeamArea({team, areaId: id});
		const isTemp = teamArea ? teamArea.temporaryArea : false;

		return new ol.style.Style({
			fill: teamArea ? sty.areasHlFill : sty.areasFill,
			stroke: isTemp ? sty.areasHlStroke : sty.areasStroke,
			zIndex: isTemp ? 1 : 0,
			text: new ol.style.Text({
				text: title,
				fill: sty.areasTextFill,
				stroke: sty.areasTextStroke,
				font: sty.areasFont,
			}),
		});
	};
};
export const getAreasStyle = decorateMapMethod(_getAreasStyle);

const _getAreasSource = ({apiToken, areasType}) => imports => {
	const {openLayers: ol} = imports;

	return new ol.source.VectorTile({
		format: new ol.format.MVT({dataProjection: 'EPSG:4326'}),
		url: `${apiUrl}/maps/areas/mvt/{z}/{x}/{y}${encodeQuery({
			type: areasType,
			token: apiToken,
		})}`,
		maxZoom: areasMaxDetailZoom,
	});
};
export const getAreasSource = decorateMapMethod(_getAreasSource);

const createAreasLayer = ({apiToken, areasType, team}) => imports => {
	const {openLayers: ol} = imports;

	const layer = new ol.layer.VectorTile({
		source: _getAreasSource({apiToken, areasType})(imports),
		style: _getAreasStyle({team})(imports),
	});

	return layer;
};

const _initTeamAreasMap = ({
	apiToken,
	organizationId,
	areasType,
	onAreaClick,
	getTeam,
}) => imports => {
	// keep some local state
	let currHoverAreaId = null;

	const {openLayers: ol} = imports;

	const mapEl = document.querySelector('#team-areas-map');
	if (!mapEl) {
		return Promise.reject(new Error('Map element not found'));
	}

	const view = new ol.View({
		projection: 'EPSG:3857',
		center: localeCenterWeb,
		zoom: initZoom,
		maxZoom,
		enableRotation: false,
		constrainResolution: true,
	});

	const areasLayer = createAreasLayer({apiToken, areasType, team: getTeam()})(imports);

	const map = new ol.Map({
		target: 'team-areas-map',
		view,
		layers: [
			new ol.layer.Tile({
				source: _getMapTileSource({sourceId: 'here-light'})(imports),
			}),
			areasLayer,
		],
	});

	const areaTooltipEl = document.getElementById('team-areas-map-area-tooltip');

	const areaTooltip = new ol.Overlay({
		element: areaTooltipEl,
		positioning: 'bottom-center',
		offset: [0, -5],
	});

	const updateAreaTooltip = areaFeature => {
		const {id, title, subtitle} = getJsonProperties(areaFeature, [
			'id',
			'title',
			'subtitle',
		]);
		if (id === currHoverAreaId) return;
		currHoverAreaId = id;
		deleteChildren(areaTooltipEl);

		const teamArea = getTeamArea({team: getTeam(), areaId: id});
		const isTemp = teamArea ? teamArea.temporaryArea : false;

		renderAreaTooltip({
			tooltipEl: areaTooltipEl,
			title,
			subtitle,
			dateFrom: isTemp ? teamArea.dateFrom : null,
			dateTo: isTemp ? teamArea.dateTo : null,
		});
	};

	addTooltipToLayers({
		mapEl,
		map,
		layers: [areasLayer],
		tooltipEl: areaTooltipEl,
		tooltip: areaTooltip,
		updateTooltip: updateAreaTooltip,
	});

	map.on('click', e => {
		const features = safeGetFeaturesAtPixel(map, e.pixel, {
			layerFilter: l => l === areasLayer,
		});
		if (!features) return;
		onAreaClick(features[0]);
	});

	map.on('pointermove', e => {
		const pixel = map.getEventPixel(e.originalEvent);
		const hit = safeHasFeatureAtPixel(map, pixel, {
			layerFilter: l => l === areasLayer,
		});
		map.getViewport().style.cursor = hit ? 'pointer' : '';
	});

	return Promise.resolve({map, areasLayer});
};
export const initTeamAreasMap = decorateMapMethod(_initTeamAreasMap);
