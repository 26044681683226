import {scopedHandler} from 'utils/redux';
import {getFormValues} from 'redux-form';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		case actions.changePage.type: {
			const newState = {
				...state,
				reportsLoading: true,
				reportsQuery: {...state.reportsQuery, _page: payload},
			};

			return [newState, effects.updateReports()];
		}

		case actions.openReportCreator.type: {
			return [{...state, reportCreatorOpen: true}, null];
		}
		case actions.closeReportCreator.type: {
			return [{...state, reportCreatorOpen: false}, null];
		}

		case actions.saveReport.type: {
			const {sub, ...rest} = getFormValues('reportForm')(fullState);
			const values = {...rest, ...sub[rest.type]};
			const teams = values.teams ? values.teams.map(t => ({id: t})) : null;
			const userId =
				!values.teams && !values.userId ? null : !teams.length ? values.userId : null;
			const report = {...values, teams, userId};

			return [{...state, processing: true}, effects.createReport(report)];
		}

		case actions.removeReport.type: {
			return [state, effects.removeReport(payload)];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		// should run in init
		case actions._updateReportsQuery.type: {
			const newState = {
				...state,
				reportsLoading: true,
				reportsQuery: {...state.reportsQuery, ...payload},
			};

			return [newState, null];
		}

		case actions._setUserTeams.type: {
			return [{...state, userTeams: payload}, null];
		}

		case actions._setInitMeta.type: {
			return [{...state, ...payload}, null];
		}

		case actions._setReports.type: {
			const {data, pagination} = payload;
			const newState = {
				...state,
				reports: data,
				reportsPagination: pagination,
				reportsLoading: false,
			};

			return [newState, null];
		}

		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false, reportCreatorOpen: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
