import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {createSelector} from 'reselect';
import {createEmptyOrganization} from './constants';
import {formatOrganizationFormInput} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const processing = pipe(root, prop('processing'));

export const organizationsLoading = pipe(root, prop('organizationsLoading'));

export const organizations = pipe(root, prop('organizations'));

export const organizationEditorOpen = pipe(root, prop('organizationEditorOpen'));

export const organizationsQueryRaw = pipe(root, prop('organizationsQuery'));

export const selectedOrganization = pipe(root, prop('selectedOrganization'));

export const products = pipe(root, prop('products'));

export const organizationsQueryFetchable = createSelector(
	[organizationsQueryRaw],
	raw => {
		return {
			...raw,
		};
	},
);

export const orgFormInitValues = createSelector(
	[selectedOrganization, products],
	(selectedOrganization, products) =>
		formatOrganizationFormInput({
			organization: selectedOrganization || createEmptyOrganization(),
			products,
		}),
);
