import {path, pipe, prop} from 'ramda';
import {mapStateKeys} from './_internal';

export default ({namespace, stateRemaps = {}}) => {
	const K = mapStateKeys(stateRemaps);

	const root = path(namespace);

	const freeCalendarResources = pipe(root, prop(K.freeCalendarResources));

	const pendingCalendarResourceSelection = pipe(
		root,
		prop(K.pendingCalendarResourceSelection),
	);

	const calendarResourceSelectionLoading = pipe(
		pendingCalendarResourceSelection,
		x => !!x,
	);

	return {
		freeCalendarResources,
		calendarResourceSelectionLoading,
		pendingCalendarResourceSelection,
	};
};
