import {pipeHandlers} from 'utils/reduxEffects';
import {scopedHandler} from 'utils/redux';
import {reducer as formReducer} from 'redux-form';
import notifications from './notifications/handler';
import confirmer from './confirmer/handler';
import sms from './sms/handler';
import notices from './notices/handler';
import common from './common/handler';
import login from './login/handler';
import launcher from './launcher/handler';
import reports from './reportsApp/handler';
import calls from './callsApp/handler';
import sales from './salesApp/handler';
import users from './usersApp/handler';
import profile from './profile/handler';
import teamCalendar from './teamCalendarApp/handler';
import calendar from './calendarApp/handler';
import stats from './statsApp/handler';
import projectSales from './projectSalesApp/handler';
import buildings from './buildingsApp/handler';
import d2d from './d2dApp/handler';
import redirect from './redirect/handler';
import overview from './overviewApp/handler';
import maps from './mapsApp/handler';
import leads from './leadsApp/handler';
import salesmanApp from './salesmanApp/handler';
import references from './referencesApp/handler';
import marketing from './marketingApp/handler';
import controlCenter from './controlCenterApp/handler';
import files from './filesApp/handler';
import dashboardApp from './dashboardApp/handler';

const form = scopedHandler(['form'], (state, _, action) => {
	return [formReducer(state, action), null];
});

export default pipeHandlers(
	form,
	notifications,
	confirmer,
	notices,
	sms,
	common,
	// try to keep ones below sorted for better debugging experience
	buildings,
	calendar,
	calls,
	controlCenter,
	d2d,
	dashboardApp,
	files,
	launcher,
	leads,
	login,
	maps,
	marketing,
	overview,
	profile,
	projectSales,
	redirect,
	references,
	reports,
	sales,
	salesmanApp,
	stats,
	teamCalendar,
	users,
);
