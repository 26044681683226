import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	initialized: false,
	processing: false,
	buildings: [],
	condoCreatorOpen: false,
	condoCreatorInitValues: {},
};
