import React from 'react';

const SvgSearch = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M11.707 10.293l-2.32-2.321a5.125 5.125 0 1 0-1.414 1.413l2.321 2.32c.393.384 1.02.384 1.413 0a1 1 0 0 0 0-1.412zM5.13 1.505a3.623 3.623 0 1 1 0 7.246 3.623 3.623 0 0 1 0-7.246z"
		/>
	</svg>
);

export default SvgSearch;
