import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import initState from './state';
import {parseNullableNumber, parseNullableString} from 'utils/fields';
import {transform} from 'ol/proj';

const parseNullableJson = deflt => str => str !== '' ? JSON.parse(str) : deflt;

export const parseUrlQuery = query => {
	const vals = evolve(
		{
			z: Number,
			x: Number,
			y: Number,
			maxYear: parseNullableNumber,
			minYear: parseNullableNumber,
			selectionId: parseNullableNumber,
			groundwaterAreasLayer: parseNullableJson(false),
			propertyLinesLayer: parseNullableJson(false),
			tagIds: parseNullableString,
		},
		query,
	);
	return {buildingsQuery: replace(vals, initState.buildingsQuery)};
};

export const formatUrlQuery = ({buildingsQuery}) => buildingsQuery;

export const formatAddBuildingFormOutput = ({form, coords}) => {
	const transformedCoords = transform(coords, 'EPSG:3857', 'EPSG:4326');

	return {
		...form,
		location: {lng: transformedCoords[0], lat: transformedCoords[1]},
	};
};
