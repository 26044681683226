import {effect} from 'utils/redux';
import {catchNonFatalDefault} from 'io/errors';
import namespace from './namespace';
import * as actions from './actions';
import {
	getSettings,
	postSetting,
	putSetting,
	deleteSetting,
	postRestoreSetting,
	getOrganizations,
	getProducts,
	postImportFacebookExcel,
} from './io';
import {getCategories, getChannels, getCustomerTypes, getSources} from '../common/io';
import {decorateWithNotifications} from 'io/app';
import * as nActions from 'modules/notifications/actions';
import {longDur} from 'constants/notifications';
import {capitalize} from 'utils/strings';
import msgs from 'dicts/messages';
import services from 'services';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

export let initialize = () => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'init-settings',
			failureStyle: 'warning',
		},
		Promise.all([
			getCategories().then(categories => dispatch(actions._setCategories(categories))),
			getChannels().then(channels => dispatch(actions._setChannels(channels))),
			getCustomerTypes().then(types => dispatch(actions._setCustomerTypes(types))),
			getSources().then(sources => dispatch(actions._setSources(sources))),
			getSettings({type: 'tags'}).then(tags => dispatch(actions._setTags(tags))),
			getSettings({type: 'serviceMappings'}).then(m =>
				dispatch(actions._setServiceMappings(m)),
			),
			getOrganizations().then(orgs => dispatch(actions._setOrganizations(orgs))),
			getProducts().then(products => dispatch(actions._setProducts(products))),
		])
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(data => {
				dispatch(actions._initialize(data));
			}),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let removeSetting = item => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'remove-setting',
			failureStyle: 'error',
			success: intl.formatMessage({id: 'Removed'}),
		},
		deleteSetting(item)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._updateLists(item));
			}),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};

export let createSetting = item => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'create-setting',
			failureStyle: 'error',
			success: intl.formatMessage({id: msgs.saved}),
		},
		postSetting(item)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(data => {
				dispatch(actions._addToSettings({...data, type: item.type}));
			}),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};

export let updateSetting = item => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'update-setting',
			failureStyle: 'error',
			success: intl.formatMessage({id: msgs.saved}),
		},
		putSetting(item)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(data => {
				dispatch(actions._updateToSettings({...data, type: item.type}));
			}),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};

export let fetchSettings =
	({type, trashed}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'get-settings',
				failureStyle: 'warning',
			},
			getSettings({type, trashed})
				.catch(e => {
					dispatch(actions._opFailed());
					throw e;
				})
				.then(data => {
					const marketingType = `_set${capitalize(type)}`;
					//eslint-disable-next-line
					dispatch(actions[marketingType](data));
				}),
		)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	};

export let restoreSetting =
	({type, id}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'restore-setting',
				failureStyle: 'error',
			},
			postRestoreSetting({type, id})
				.catch(e => {
					dispatch(actions._opFailed());
					throw e;
				})
				.then(() => {
					dispatch(actions._updateLists({type, id}));
				}),
		)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	};

export let uploadExcel = data => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'upload-excel',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: msgs.saved}),
		},
		postImportFacebookExcel(data)
			.catch(e => {
				dispatch(actions._opFailed());

				if (e.response && e.response.body) {
					e.response.json().then(res => {
						dispatch(
							nActions.error({
								id: 'facebook-excel-error',
								message: res.message,
								duration: longDur,
							}),
						);
					});
				}

				throw e;
			})
			.then(res => {
				dispatch(
					nActions.success({
						id: 'facebook-excel-imported',
						message: intl.formatMessage(
							{
								id: 'Leads imported!\nNew count: {leadsCreated}\nNot imported count: {leadsNotCreated}\nRow numbers not imported: {notCreatedRows}',
							},
							res,
						),
					}),
				);
				dispatch(actions._excelUploaded());
			}),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
uploadExcel = creator('uploadExcel', uploadExcel);
