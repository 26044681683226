import {scopedHandler, pipeSubHandlers} from 'utils/redux';
import {getFormValues} from 'redux-form';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import * as selectors from './selectors';
import * as rootSelectors from 'modules/common/selectors';

import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {propEq, map, prepend, find, pipe, prop, sortBy} from 'ramda';
import {
	formatClientFormOutput,
	formatCallFormOutput,
	formatVisitFormOutput,
	formatActivityFormOutput,
	formatCalendarResourceEditFormOutput,
	formatCalendarResources,
	sortCondoClients,
	isProjectSalesUser,
	isProjectCustomerAcquisitionUser,
} from './utils';
import createDatePickHandler from 'fragments/calendarResourcePicker/handler';
import {processFreeCalendarResources} from 'fragments/calendarResourcePicker/utils';
import createBuildingModalHandler from 'fragments/buildingModalActions/handler';
import createCallReminderHandler from 'fragments/callReminder/handler';

const pipeHandlers = pipeSubHandlers(ns);

const datePickHandler = createDatePickHandler({
	actions,
	effects,
	calendarResourceSelectionSelector: pipe(
		getFormValues('callClientForm'),
		prop('calendarResourceId'),
	),
});

const buildingModalHandler = createBuildingModalHandler({
	actions,
	effects,
});

const callReminderHandler = createCallReminderHandler({
	actions,
	effects,
	buildingSelector: selectors.building,
});

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize({buildingId: payload})];
		}

		case actions.reinitialize.type: {
			return [
				{
					...initState,
					// do not clear these on reinitialze
					userTeams: state.userTeams,
					activeCallPool: state.activeCallPool,
					seq: state.seq,
					nextSeq: state.nextSeq,
				},
				effects.initialize({buildingId: payload, isReinitialize: true}),
			];
		}

		case actions.openClientEditor.type: {
			const newState = state.clientEditorOpen
				? {
						...state,
						clientEditorOpen: false,
						clientSearchOpen: false,
						client: null,
						newClients: [],
				  }
				: {
						...state,
						clientEditorOpen: true,
						client: payload,
				  };

			return [newState, null];
		}

		case actions.destroy.type: {
			return [initState, effects.destroy()];
		}

		case actions.saveComment.type: {
			const form = payload
				? getFormValues('updateCommentsForm')(fullState)
				: getFormValues('commentsForm')(fullState);
			const data = {
				buildingId: state.condoNotFound
					? state.building.id
					: state.condo.projectBuildingId,
				comment: form.comment,
			};
			const id = payload ? payload : null;

			return [{...state, processing: true}, effects.createComment({data, id})];
		}

		case actions.openCommentEditor.type: {
			const comments = state.comments.map(comment => {
				if (comment.id === payload) {
					comment.editing = !comment.editing;
					return comment;
				} else {
					comment.editing = false;
					return comment;
				}
			});

			return [{...state, comments}, null];
		}

		case actions.removeComment.type: {
			return [
				{...state, processing: true},
				payload ? effects.removeComment(payload) : null,
			];
		}

		case actions.changeTab.type: {
			return [{...state, activeTab: payload}];
		}

		case actions.saveClient.type: {
			const form = getFormValues('clientForm')(fullState);
			const newClient = state.client ? state.client.isNew : true;
			const client = formatClientFormOutput({form});
			const buildingId = newClient
				? state.condoNotFound
					? state.building.id
					: state.condo.projectBuildingId
				: null;

			return [{...state, processing: true}, effects.saveClient({client, buildingId})];
		}

		case actions.removeClient.type: {
			const clientId = state.client.id;
			return [{...state, processing: true}, effects.removeClient(clientId)];
		}

		case actions.selectBuilding.type: {
			return [{...state, building: payload}, null];
		}

		case actions.saveProductStates.type: {
			return [
				{...state, processing: true},
				effects.saveProductStates(payload.productStates),
			];
		}

		case actions.saveCondo.type: {
			return [{...state, processing: true}, effects.saveCondo(payload)];
		}

		case actions.openActivitiesPopup.type: {
			return [{...state, activitiesPopupOpen: true}, null];
		}

		case actions.closeActivityPopup.type: {
			return [{...state, activitiesPopupOpen: false}, null];
		}

		case actions.detachBuilding.type: {
			return [state, effects.detachBuilding(payload)];
		}

		case actions.saveProjectCall.type: {
			const form = getFormValues('callClientForm')(fullState);
			const projectBuildingId = state.condo.projectBuildingId;
			const userId = rootSelectors.user(fullState).id;

			const {projectCall, calendarResource, formFill} = formatCallFormOutput({form});

			const projectCall2 = {
				...projectCall,
				projectBuildingId,
			};

			const calendarResource2 = calendarResource
				? {
						...calendarResource,
						reserverId: userId,
						salesmanId: userId,
				  }
				: null;

			return [
				{...state, processing: true},
				effects.createProjectCall({
					calendarResource: calendarResource2,
					projectCall: projectCall2,
					formFill,
				}),
			];
		}

		case actions.saveProjectVisit.type: {
			const form = getFormValues('visitClientForm')(fullState);
			const projectBuildingId = state.condo.projectBuildingId;
			const teamId = state.userTeams[0].id;
			const userId = rootSelectors.user(fullState).id;

			const {calendarResource} = formatVisitFormOutput({
				form,
				projectBuildingId,
				userId,
				teamId,
			});

			return [
				{...state, processing: true},
				effects.createProjectVisit({calendarResource}),
			];
		}

		case actions.openTooltip.type: {
			return [
				{
					...state,
					tooltip: state.tooltip !== payload ? payload : null,
					activitiesPopupOpen: false,
				},
				null,
			];
		}

		case actions.destroyCallClientForm.type: {
			return [state, effects.destroyCallClientForm()];
		}

		case actions.openClientSearch.type: {
			const buildings = state.condoNotFound ? [state.building] : state.buildings;
			const newState = {
				...state,
				clientSearchOpen: true,
				clientType: payload || null,
				processing: true,
			};

			return [newState, effects.searchClients(buildings)];
		}

		case actions.getClientData.type: {
			const clientId = payload;
			return [state, effects.getClientData(clientId)];
		}

		case actions.closeClientSearch.type: {
			return [
				{...state, clientSearchOpen: false, newClients: [], clientType: null},
				null,
			];
		}

		case actions.removeCondo.type: {
			return [state, effects.removeCondo(state.condo.id)];
		}

		case actions.toggleAttachingBuilding.type: {
			return [
				{...state, attachingBuilding: !state.attachingBuilding},
				effects.toggleAttachingBuilding(),
			];
		}

		case actions.attachBuilding.type: {
			return [state, effects.attachBuilding(payload)];
		}

		case actions.setDisableReinit.type: {
			return [{...state, disableReinit: payload}, null];
		}

		case actions.setCondoEditorOpen.type: {
			return [{...state, condoEditorOpen: payload}, null];
		}

		case actions.removeManager.type: {
			const managerType = payload;
			return [state, effects.removeManager(managerType)];
		}

		case actions.clearNewManagerClients.type: {
			return [
				{
					...state,
					newManagerClient: initState.newManagerClient,
					newHouseManagerClient: initState.newHouseManagerClient,
				},
				null,
			];
		}

		case actions.importClientAsManager.type: {
			const {firstName, lastName, phone} = payload;

			const newManagerClient =
				state.clientType === 'manager'
					? {
							managerFirstName: firstName,
							managerLastName: lastName,
							managerPhone: phone,
					  }
					: state.newManagerClient;

			const newHouseManagerClient =
				state.clientType === 'houseManager'
					? {
							houseManagerFirstName: firstName,
							houseManagerLastName: lastName,
							houseManagerPhone: phone,
					  }
					: state.newHouseManagerClient;

			const newState = {
				...state,
				newManagerClient,
				newHouseManagerClient,
				clientSearchOpen: false,
				clientType: null,
				processing: false,
				newClients: [],
			};

			return [newState, null];
		}

		case actions.saveActivity.type: {
			const condominiumId = state.condo.id;
			const form = getFormValues('activityForm')(fullState);

			const activity = formatActivityFormOutput(form, condominiumId);

			return [{...state, processing: true}, effects.saveActivity({activity})];
		}

		case actions.setDateEditorOpen.type: {
			return [{...state, dateEditorOpen: payload}, null];
		}

		case actions.updateCalendarResource.type: {
			const form = getFormValues(`dateForm-${payload}`)(fullState);
			const calRes = formatCalendarResourceEditFormOutput({
				form,
				selectedItemId: payload,
			});
			return [state, effects.updateCalendarResource(calRes)];
		}

		case actions._setCalendarResource.type: {
			const calendarResources = formatCalendarResources({
				calendarResources: state.calendarResources,
				calRes: payload,
			});

			return [{...state, dateEditorOpen: null, calendarResources}, null];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._setCondo.type: {
			const {
				condo: {buildings, ...condo},
				buildingId,
			} = payload;

			const userId = condo.user ? condo.user.id : null;
			const customerAcquisitionUserId = condo.customerAcquisitionUser
				? condo.customerAcquisitionUser.id
				: null;

			const primaryBuilding = buildings.find(b => b.id === condo.projectBuildingId);
			const {clients, comments} = primaryBuilding;
			const condoClients = sortCondoClients(clients, condo);

			comments.reverse();
			return [
				{
					...state,
					condo: {...condo, userId, customerAcquisitionUserId},
					buildings,
					building: buildingId
						? buildings.find(b => b.id === buildingId)
						: state.building,
					comments,
					clients: condoClients,
					condoNotFound: false,
				},
				null,
			];
		}

		case actions._setCalendarResources.type: {
			const calendarResources = payload.data;
			return [{...state, calendarResources}, null];
		}

		case actions._commentRemoved.type: {
			const comments = state.comments.filter(comment => comment.id !== payload);

			return [{...state, comments, processing: false}, null];
		}

		case actions._commentSaved.type: {
			const newComment = {
				...payload,
				editing: false,
			};
			const old = find(propEq('id', newComment.id), state.comments);
			const comments = old
				? map(c => (c.id === newComment.id ? newComment : c), state.comments)
				: prepend(newComment, state.comments);

			return [{...state, processing: false, comments}, null];
		}

		case actions._buildingDetached.type: {
			const newBuildings = state.buildings.filter(b => b.id !== payload);
			const newState = {
				...state,
				buildings: newBuildings,
				building: newBuildings.find(b => b.id === state.condo.projectBuildingId),
				processing: false,
			};

			return [newState, null];
		}

		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opOk.type: {
			return [
				{...state, processing: false, clientEditorOpen: false, tooltip: null},
				null,
			];
		}

		case actions._setAdditionalInfos.type: {
			const newState = {...state, additionalInfos: payload};
			return [newState, null];
		}

		case actions._setUserTeams.type: {
			return [{...state, userTeams: payload}];
		}

		case actions._setFreeCalRes.type: {
			const resources = processFreeCalendarResources(payload);
			return [{...state, freeCalendarResources: resources}];
		}

		case actions._setUsers.type: {
			return [
				{
					...state,
					projectSalesmen: payload.filter(u => isProjectSalesUser(u)),
					projectCustomerAcquisitionUsers: payload.filter(u =>
						isProjectCustomerAcquisitionUser(u),
					),
				},
				null,
			];
		}

		case actions._updateClients.type: {
			const clients =
				payload.type === 'update'
					? state.clients.map(c => {
							return c.id === payload.client.id ? payload.client : c;
					  })
					: payload.type === 'add'
					? [...state.clients, payload.client]
					: state.clients.filter(c => c.id !== payload.id);

			const newState = {...state, clients, processing: false, clientEditorOpen: false};

			return [newState, null];
		}

		case actions._condoNotFound.type: {
			return [{...state, condo: null, condoNotFound: true, activeTab: 'buildings'}, null];
		}

		case actions._set16100Clients.type: {
			const sortByName = sortBy(prop('name'));
			const searchedClients = sortByName(payload);

			return [{...state, newClients: searchedClients, processing: false}, null];
		}

		case actions._setNewClient.type: {
			const names = payload.name.split(' ', 2);
			const client = {
				...payload,
				firstName: names[1] ? names[1] : '',
				lastName: names[0],
				isNew: true,
			};

			return [
				{...state, client, clientSearchOpen: false, processing: false, newClients: []},
				null,
			];
		}

		case actions._setNewManagerClients.type: {
			const names = payload.name.split(' ', 2);
			const firstName = names[1] ? names[1] : '';
			const lastName = names[0];
			const phone = payload.phone;

			const newManagerClient =
				state.clientType === 'manager'
					? {
							managerFirstName: firstName,
							managerLastName: lastName,
							managerPhone: phone,
					  }
					: state.newManagerClient;

			const newHouseManagerClient =
				state.clientType === 'houseManager'
					? {
							houseManagerFirstName: firstName,
							houseManagerLastName: lastName,
							houseManagerPhone: phone,
					  }
					: state.newHouseManagerClient;

			const newState = {
				...state,
				newManagerClient,
				newHouseManagerClient,
				clientSearchOpen: false,
				clientType: null,
				processing: false,
				newClients: [],
			};

			return [newState, null];
		}

		case actions._setBuilding.type: {
			const {encounters, clients, comments, ...building} = payload;

			const newState = {
				...state,
				building,
				encounters,
				clients,
				comments,
			};

			return [newState, null];
		}

		case actions._removeCondo.type: {
			const newState = {
				...state,
				processing: false,
				condo: null,
				condoNotFound: true,
				condoEditorOpen: false,
				newManagerClient: initState.newManagerClient,
				newHouseManagerClient: initState.newHouseManagerClient,
			};
			return [newState, null];
		}

		case actions._attachBuilding.type: {
			return [{...state, attachingBuilding: false, processing: false}, null];
		}

		case actions._saveCondo.type: {
			const newState = {
				...state,
				processing: false,
				condoEditorOpen: false,
				newManagerClient: initState.newManagerClient,
				newHouseManagerClient: initState.newHouseManagerClient,
			};

			return [newState, null];
		}

		case actions._saveActivity.type: {
			const {activities} = state.condo;
			const newActivities = [{...payload}, ...activities];
			let _calendarResources = state.calendarResources;

			if (payload.calendarResource) {
				_calendarResources = [payload.calendarResource, ..._calendarResources];
			}

			// Condo state is not saved for certain activites
			const newCondoState = !['notReached', 'contact'].includes(payload.state)
				? {
						activityState: payload.state,
						activityDate: payload.createdAt,
				  }
				: {};

			const newState = {
				...state,
				condo: {
					...state.condo,
					...newCondoState,
					activities: newActivities,
				},
				calendarResources: _calendarResources,
				processing: false,
				tooltip: null,
			};
			return [newState, null];
		}

		case actions._setOpenedAt.type: {
			return [{...state, openedAt: payload}, null];
		}

		// overwrite buildingModal fragment's handler as we need to update buildings list too
		case actions._updateBuilding.type: {
			const newBuildings = state.buildings.map(b => {
				if (b.id === payload.id) return {...b, ...payload};
				return b;
			});

			return [
				{
					...state,
					buildings: newBuildings,
					building: {...state.building, ...payload},
					buildingModalOpen: false,
					buildingModalProcessing: false,
				},
				null,
			];
		}

		case actions._setActiveCallPool.type: {
			return [{...state, activeCallPool: payload}, null];
		}

		case actions.skip.type: {
			return [
				{
					...state,
					loading: true,
				},
				effects.skip(),
			];
		}

		case actions._setFetchingNextBuilding.type: {
			return [{...state, fetchingNextBuilding: payload}, null];
		}

		case actions._setLoading.type: {
			return [{...state, loading: payload}, null];
		}

		case actions._setProducts.type: {
			return [{...state, products: payload.data}, null];
		}

		default:
			return pipeHandlers(datePickHandler, buildingModalHandler, callReminderHandler)(
				state,
				fullState,
				{
					type,
					payload,
				},
			);
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
