import services from 'services';
import {prop, pipe} from 'ramda';
import {describeThrow, describeError} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getUserTimeEntries = () =>
	httpJson('get', '/timeEntries/userEntriesToday', {})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load working hours'})))
		.then(pipe(prop('data'), normalize.timeEntry));

export const updateTimeEntry = entry =>
	httpJson('put', `/timeEntries/${entry.id}`, {}, {body: entry})
		.catch(e => {
			const conflict = !!e.response && e.response.status === 500;
			e.causedByTimeEntryConflict = conflict;
			return conflict
				? e.response.json().then(body => describeThrow(body.message, e))
				: Promise.reject(describeError(msgs.saveFailed, e));
		})
		.then(pipe(prop('data'), normalize.timeEntry));

export const deleteTimeEntry = timeEntryId =>
	httpJson('delete', `/timeEntries/${timeEntryId}`)
		.catch(describeThrow(intl.formatMessage({id: 'Failed to delete working time'})))
		.then(prop('data'));

export const updateCallTarget = target =>
	httpJson('put', `/targets/${target.id}`, {}, {body: target})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save goal'})))
		.then(prop('data'));

export const postCallTarget = target =>
	httpJson('post', '/targets', {}, {body: target})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save goal'})))
		.then(prop('data'));
