import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {colors} from 'styles/constants';
import Icon from 'components/generic/Icon';
import Loader from 'components/generic/Loader';

const typeToIcon = {
	info: 'noticeInfo',
	success: 'noticeSuccess',
	warning: 'noticeAlert',
	error: 'noticeError',
};

const typeToBackgroundColor = {
	info: colors.info3,
	success: colors.success3,
	warning: colors.warning3,
	error: colors.error3,
	loading: colors.info3,
};

const typeToTextColor = {
	info: colors.info1,
	success: colors.success1,
	warning: colors.warning1,
	error: colors.error1,
	loading: colors.info1,
};

const typeToIconAndBorderColor = {
	info: colors.info2,
	success: colors.success2,
	warning: colors.warning2,
	error: colors.error2,
	loading: colors.info2,
};

const Wrapper = styled.div`
	border: 1px solid ${({type}) => typeToIconAndBorderColor[type]};
	background: ${({type}) => typeToBackgroundColor[type]};
	border-radius: 3px;
	color: ${({type}) => typeToTextColor[type]};
	cursor: default;
	display: flex;
	flex-direction: row;
	outline: none;
`;

const IconWrapper = styled.div`
	background: ${({type}) => typeToIconAndBorderColor[type]};
	padding: 8px;
	display: flex;
	align-items: center;

	> i {
		font-size: 22px;
		width: 22px;
		height: 22px;
		color: white;
	}
`;

const MessageWrapper = styled.div`
	flex: 1;
	padding: 8px;
	display: flex;
	align-items: center;
	white-space: pre-wrap;
`;

const CloseWrapper = styled.div`
	padding: 8px;
	display: flex;
	align-items: center;
`;

const Notification = props => {
	const {id = '', type, message, remove, showClose = true, ...rest} = props;

	return (
		<Wrapper
			id={id}
			role={remove ? 'button' : null}
			tabIndex={remove ? 0 : null}
			type={type}
			data-ti={`notification-${id}`}
			data-type={type}
			onClick={() => {
				if (remove) remove();
			}}
			onKeyPress={e => {
				if (remove && e.key === 'Enter') remove();
			}}
			{...rest}
		>
			<IconWrapper type={type}>
				{type === 'loading' ? (
					<Loader size={22} color="white" />
				) : (
					<Icon name={typeToIcon[type]} width="22px" height="22px" color="white" />
				)}
			</IconWrapper>
			<MessageWrapper>{message}</MessageWrapper>
			{showClose && (
				<CloseWrapper>
					<Icon
						name="close"
						color={typeToTextColor[type]}
						width="12px"
						height="12px"
						smallRightSpacing
					/>
				</CloseWrapper>
			)}
		</Wrapper>
	);
};

Notification.propTypes = {
	id: propTypes.string,
	type: propTypes.string,
	message: propTypes.node,
	remove: propTypes.func,
	showClose: propTypes.bool,
};

export default Notification;
