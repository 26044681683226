export const TYPE_CALL = 'call';
export const TYPE_VISIT = 'visit';
export const TYPE_SALESMANVISIT = 'salesmanVisit';

export const STATE_NOT_REACHED = 'notReached';
export const STATE_CANCELLED_DEAL = 'cancelledDeal';
export const STATE_CONTACTED = 'contacted';
export const STATE_CANCELLED_OFFER = 'cancelledOffer';
export const STATE_OFFER = 'offer';

export const statesByType = {
	[TYPE_CALL]: [STATE_NOT_REACHED],
	[TYPE_VISIT]: [STATE_NOT_REACHED],
	[TYPE_SALESMANVISIT]: [
		STATE_NOT_REACHED,
		STATE_CANCELLED_DEAL,
		STATE_CONTACTED,
		STATE_CANCELLED_OFFER,
		STATE_OFFER,
	],
};
