import React from 'react';

const SvgPersonMenuIcon = props => (
	<svg viewBox="0 0 42.52 42.52" {...props} width="1em" height="1em">
		<path
			d="M35.94 6.492C32.085 2.638 26.962.516 21.512.516S10.939 2.638 7.085 6.492A20.27 20.27 0 0 0 1.109 20.92a20.274 20.274 0 0 0 5.976 14.428 20.27 20.27 0 0 0 14.428 5.976 20.27 20.27 0 0 0 14.428-5.976 20.274 20.274 0 0 0 5.976-14.428A20.275 20.275 0 0 0 35.94 6.492zm-1.214 28.126c-3.571 3.446-8.263 5.344-13.213 5.344S11.87 38.064 8.3 34.618l-.247-.237.107-.326a14.016 14.016 0 0 1 13.353-9.672c6.094 0 11.46 3.887 13.353 9.672l.106.326-.246.237zM14.828 16.337c0-3.686 2.999-6.685 6.685-6.685s6.685 2.999 6.685 6.685-2.998 6.685-6.685 6.685-6.685-2.999-6.685-6.685zm21.226 16.898l-.367-.86a15.437 15.437 0 0 0-9.308-8.562l-1.008-.336.86-.625a8.08 8.08 0 0 0 3.329-6.514c0-4.437-3.609-8.046-8.046-8.046-4.437 0-8.046 3.61-8.046 8.046 0 2.565 1.244 5 3.329 6.514l.86.625-1.008.336a15.434 15.434 0 0 0-9.308 8.562l-.367.86-.569-.741A18.833 18.833 0 0 1 2.47 20.92c0-10.5 8.543-19.043 19.043-19.043S40.555 10.42 40.555 20.92c0 4.222-1.36 8.224-3.932 11.574l-.569.741z"
			fill="#595959"
		/>
	</svg>
);

export default SvgPersonMenuIcon;
