import {action} from 'utils/redux';
import {P} from 'utils/types';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const toggleOrganizationEditor = creator('toggleOrganizationEditor');

export const updateOrganization = creator('updateOrganization');

export const _setOrganizations = creator('_setOrganizations', P.Object);

export const _setProducts = creator('_setProducts', P.Object);

export const _setProcessing = creator('_setProcessing');

export const _updateOrganizationsQuery = creator('_updateOrganizationsQuery', P.Object);

export const _initialize = creator('_initialize');
