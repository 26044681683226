import {createStore, compose as reduxCompose, applyMiddleware} from 'redux';
import {createEnhancer as createEffectsEnhancer} from 'utils/reduxEffects';
import {stackInjectorMiddleware} from 'utils/redux';
import handler from 'modules/handler';
import {inDev} from 'constants/app';

export default ({actionHookEnhancer = null}) => {
	const compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || reduxCompose;

	const middleware = inDev ? [stackInjectorMiddleware] : [];

	const enhancers = [
		createEffectsEnhancer(),
		...(actionHookEnhancer ? [actionHookEnhancer] : []),
		applyMiddleware(...middleware),
	];

	const enhancer = compose(...enhancers);

	return createStore(handler, enhancer);
};
