import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	initialized: false,
	processing: false,
	dashboard: null,
	teams: [],
	unacknowledgedVisitsCount: null,
};
