import {inDev} from 'constants/app';

// NOTE: this file contains stuff used by redux utils that depend on our app state. they were separated from other utils so that utils/redux can be loaded without dependencies to most things in our app, apart from constants/app. this prevents earlier circular dependency problems, see commit 8f0ba975.

export const assertType = inDev ? (type, value) => type.assert(value) : () => {};

const assertTypeAndTransform = (type, value, errorTransformer) => {
	try {
		type.assert(value);
	} catch (e) {
		throw errorTransformer(e);
	}
};
export const assertTypeAndTransformError = inDev ? assertTypeAndTransform : () => {};

const _assertTruthy = val => {
	if (!val) {
		throw new Error(`Assertion failed, value not truthy: \`${val}\``);
	}
};
export const assertTruthy = inDev ? _assertTruthy : () => {};
