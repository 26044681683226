import {action} from 'utils/redux';
import {P} from 'utils/types';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize', P.Number);

export const destroy = creator('destroy');

export const getPdf = creator('getPdf');

export const getExcel = creator('getExcel');

export const removeReport = creator('removeReport');

export const setSort = creator('setSort', P.String);

export const _setReport = creator('_setReport', P.Object);

export const _setPdf = creator('_setPdf', P.String);

export const _setExcel = creator('_setExcel', P.String);
