import {mapStateKeys as _mapStateKeys} from 'utils/redux';

export const stateVals = {
	buildingModalOpen: false,
	buildingModalProcessing: false,
};

export const stateKeys = Object.keys(stateVals);

export const mapStateKeys = _mapStateKeys(stateKeys);
