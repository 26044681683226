import {mergeLeft} from 'ramda';
import {effect} from 'utils/redux';
import namespace from './namespace';
import {getQuery, pushQuery} from 'io/history';
import {parseUrlQuery} from './utils';
import {decorateWithNotifications} from 'io/app';
import * as actions from './actions';
import * as selectors from './selectors';
import {catchNonFatalDefault} from 'io/errors';
import msgs from 'dicts/messages';
import services from 'services';
import {getClients, getOrganizations, getProducts, getExcel as ioGetExcel} from './io';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const creator = effect(namespace);

const fetchClients =
	({notifyOpts = {}}) =>
	(getState, dispatch) => {
		return decorateWithNotifications(
			{id: 'get-clients', failureStyle: 'warning', ...notifyOpts},
			getClients(selectors.clientsQueryFetchable(getState())),
		)(getState, dispatch).then(res => {
			dispatch(actions._setClients(res));
		});
	};

export let initialize = () => (getState, dispatch) => {
	const {clientsQuery} = parseUrlQuery(getQuery());
	const {dismissPrompt} = clientsQuery;

	if (dismissPrompt) {
		dispatch(actions._updateClientsQuery(clientsQuery));
	}

	decorateWithNotifications(
		{
			id: 'init-marketing-clients',
			failureStyle: 'error',
		},
		Promise.all([
			dismissPrompt
				? getClients(selectors.clientsQueryFetchable(getState()))
						.then(clients => {
							dispatch(actions._setClients(clients));
						})
						.catch(e => {
							dispatch(actions._searchFailed());
							throw e;
						})
				: Promise.resolve(null),
			getOrganizations().then(orgs => dispatch(actions._setOrganizations(orgs))),
			getProducts().then(prods => dispatch(actions._setProducts(prods))),
		]),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let updateClients = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	fetchClients({})(getState, dispatch)
		.catch(e => {
			dispatch(actions._searchFailed());
			throw e;
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
updateClients = creator('updateClients', updateClients);

export let getExcel = () => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'get-excel',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		ioGetExcel(selectors.clientsQueryFetchable(getState())),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._setProcessing(false));
			throw e;
		})
		.then(res => {
			dispatch(actions._setProcessing(false));
			const a = document.createElement('a');
			a.href = res.url;
			a.dispatchEvent(new MouseEvent('click'));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
getExcel = creator('getExcel', getExcel);
