import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	initialized: false,
	statsLoading: false,
	teamsLoading: true,
	teams: [],
	settings: {
		teams: [],
		timeSpan: 'none',
		interval: 20,
	},
};
