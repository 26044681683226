import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import initState from './state';

export const parseUrlQuery = query => {
	const vals = evolve({_page: Number}, query);
	return {noticesQuery: replace(vals, initState.noticesQuery)};
};

export const formatUrlQuery = ({noticesQuery}) => noticesQuery;
