import {action} from 'utils/redux';
import namespace from './namespace';
import {P, PaginatedData} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const closeAreasSelector = creator('closeAreasSelector');

export const changePage = creator('changePage', P.Number);

export const navigateToCondo = creator('navigateToCondo', P.Number);

export const openAreasSelector = creator('openAreasSelector');

export const openAreasSelectorSuggestion = creator('openAreasSelectorSuggestion');

export const recheckQuery = creator('recheckQuery');

export const searchCondos = creator('searchCondos');

export const setAreasSelectorSearch = creator('setAreasSelectorSearch');

export const setAreasSelectorType = creator('setAreasSelectorType');

export const _opOk = creator('_opOk');

export const _opFailed = creator('_opFailed');

export const _setCondos = creator('_setCondos', PaginatedData(P.Array));

export const _setProducts = creator('_setProducts');

export const _updateCondosQuery = creator('_updateCondosQuery', P.Object);
