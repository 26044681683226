import React from 'react';

const SvgArrowRight = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M11.754 5.76c-.001-.33-.143-.645-.391-.864L5.997.173a.691.691 0 0 0-.912 1.038l4.146 3.648a.12.12 0 0 1-.08.21H.938a.691.691 0 0 0 0 1.382H9.15a.12.12 0 0 1 .08.21L5.083 10.31a.691.691 0 1 0 .912 1.038l5.367-4.723c.248-.219.39-.533.39-.864z"
		/>
	</svg>
);

export default SvgArrowRight;
