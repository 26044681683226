import styled from 'styled-components';
import {colors} from 'styles/constants';

const appearances = {
	warning: `
		background: ${colors.warning3};
		border: 1px solid ${colors.warning2};
		color: ${colors.warning1};
	`,
	light: `
		background: ${colors.grey8};
		border: 1px solid ${colors.grey7};
	`,
};
const Alert = styled.div`
	border-radius: 10px;
	padding: 15px;
	${({appearance = 'light'}) => appearances[appearance] ?? appearances['light']};
`;

export default Alert;
