export const buildingInclude =
	'clients:withoutBanned(true),buildingProductStates.product,allBuildingProductStates.product,allEncounters,callable';

export const encountersInclude = 'source.user';

export const encounterInclude =
	'building,source.formFill.formAttributes,source.calendarResource.client,source.calendarResource.salesmanVisit,source.calendarResource.salesman,source.calendarResource.team,source.user,reasonMapping';

export const forwardingReferrers = ['callPool', 'freeride', 'listview'];

export const callFormBase = {
	// meta
	state: 'visit',
	dateCreatorOpen: false,
	isRequest: false,
	isBonus: false,
	// values
	clientId: null,
	calendarResourceId: null,
	calendarResource: {
		dateFrom: null,
		teamId: null,
		ihSource: null,
	},
	bonusCalendarResource: {
		dateFrom: null,
		teamId: null,
		salesmanId: null,
	},
	contactClientId: null,
	reason: null,
	reasonMappingId: null,
	description: '',
	requestSource: null,
	requestDate: null,
	formFill: {
		description: '',
	},
	clientEmail: null,
	clientEmailMarketing: false,
};

export const createEmptyClient = () => ({
	firstName: '',
	lastName: '',
	email: '',
	password: '',
	swedish: false,
	comments: '',
	title: '',
	address: '',
	zip: '',
	city: '',
});

export const notReachedCallBase = {
	appointment: false,
	reached: false,
};

export const callSurveyId = 2;

export const callReviewSurveyId = 4;
