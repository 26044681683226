import {sortByTitle as sortAreas} from 'utils/areas';
import {evolve} from 'ramda';
import {parseNullableNumber} from 'utils/fields';
export const formatAddAreaFormInput = area => {
	return {
		id: area ? area.id : null,
		dateFrom: new Date().toISOString().split('T')[0],
		dateTo: '',
	};
};

export const formatAddAreaFormOutput = ({team, area}) => {
	return {
		id: team.id,
		areas: [...team.areas, area],
	};
};

export const getTeamArea = ({team, areaId}) =>
	team && team.areas ? team.areas.find(a => a.id === areaId) : null;

export const formatTeamAreas = team => {
	let areas = [];
	let tempAreas = [];

	if (team && team.areas) {
		team.areas.forEach(a => {
			if (a.temporaryArea) {
				tempAreas.push(a);
			} else {
				areas.push(a);
			}
		});
	}

	return {
		areas: sortAreas(areas),
		tempAreas: sortAreas(tempAreas),
	};
};

export const parseUrlQuery = query => {
	return evolve({teamId: parseNullableNumber}, query);
};
