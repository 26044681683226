const hex3colorToRgb = colorCode => {
	const r = colorCode.substring(1, 2);
	const g = colorCode.substring(3, 4);
	const b = colorCode.substring(4, 5);
	return [parseInt(r + r, 16), parseInt(g + g, 16), parseInt(b + b, 16)];
};

export const hexColorToRgb = colorCode => {
	if (colorCode.length === 4) {
		return hex3colorToRgb(colorCode);
	}
	const r = colorCode.substring(1, 3);
	const g = colorCode.substring(3, 5);
	const b = colorCode.substring(5, 7);
	return [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)];
};
