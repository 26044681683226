import {getFormValues} from 'redux-form';
import {find, map, prepend, propEq} from 'ramda';
import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import * as commonSelectors from 'modules/common/selectors';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize(payload)];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.verifyRemoval.type: {
			return [
				state,
				effects.updateRemovalRequest({id: state.request.id, status: 'removalPending'}),
			];
		}

		case actions.openEncounterModal.type: {
			return [{...state, processing: true}, effects.fetchEncounter(payload)];
		}

		case actions.closeEncounterModal.type: {
			return [{...state, previewableEncounter: null}, null];
		}

		case actions.openCommentEditor.type: {
			const comments = state.comments.map(comment => {
				if (comment.id === payload) {
					comment.editing = !comment.editing;
					return comment;
				} else {
					comment.editing = false;
					return comment;
				}
			});

			return [{...state, comments}, null];
		}

		case actions.saveComment.type: {
			const form = payload
				? getFormValues('updateCommentsForm')(fullState)
				: getFormValues('commentsForm')(fullState);

			const data = {
				removalRequestId: state.request.id,
				comment: form.comment,
				commentFrom: commonSelectors.user(fullState).fullName,
			};

			const id = payload ? payload : null;

			return [{...state, processing: true}, effects.createComment({data, id})];
		}

		case actions.removeComment.type: {
			return [state, payload ? effects.removeComment(payload) : null];
		}

		case actions.removeRemovalRequest.type: {
			return [state, effects.removeRemovalRequest()];
		}

		case actions._commentSaved.type: {
			const newComment = {
				...payload,
				editing: false,
			};
			const old = find(propEq('id', newComment.id), state.comments);
			const comments = old
				? map(c => (c.id === newComment.id ? newComment : c), state.comments)
				: prepend(newComment, state.comments);

			return [{...state, processing: false, comments}, null];
		}

		case actions._commentRemoved.type: {
			const comments = state.comments.filter(comment => comment.id !== payload);

			return [{...state, comments, processing: false}, null];
		}

		case actions._setRequest.type: {
			const newState = {
				...state,
				request: payload,
				comments: payload.removalRequestComments.reverse(),
			};

			return [newState, null];
		}

		case actions._updateRequest.type: {
			return [{...state, request: {...state.request, ...payload}}, null];
		}

		case actions._setEncounterData.type: {
			const newState = {
				...state,
				processing: false,
				previewableEncounter: payload,
			};

			return [newState, null];
		}

		case actions._setClients.type: {
			return [{...state, clients: payload, clientsLoading: false}, null];
		}

		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
