import React from 'react';
import {reduxForm} from 'redux-form';
import {compose} from 'ramda';
// custom helpers & utils
import {injectIntl, FormattedMessage} from '@meiko/react-intl';
import {splitGlobalAttrs} from 'utils/html';
import {handleFormSubmitFail} from 'fragments/helpers';
import {formatNullableString, parseNullableString} from 'utils/fields';
// components
import Label from 'components/generic/Label';
import FormBlock from 'components/generic/FormBlock';
import Field from 'components/generic/Field';
import SpacedInputs from 'components/generic/SpacedInputs';
import Input from 'components/generic/Input';
import FormField from 'components/generic/FormField';
import FieldError from 'components/generic/FieldError';

/* eslint-disable react/prop-types */

function validate(values, {intl}) {
	let errors = {};

	if (values.password !== values.passwordConfirmation) {
		errors.passwordConfirmation = intl.formatMessage({id: 'Salasanat eivät täsmää'});
	}

	return errors;
}

const langOpts = [
	{value: 'fi', title: 'Suomi'},
	{value: 'sv', title: 'Svenska'},
	{value: 'en', title: 'English'},
];

export const SOMIC_CALLER = 'somic';
const callOpts = [
	{value: '', title: <FormattedMessage id="Device's calling application" />},
	{value: 'soittolinja', title: 'Soittolinja'},
	{value: 'enioCaller', title: <FormattedMessage id="Enio caller" />},
	{value: SOMIC_CALLER, title: <FormattedMessage id="Somic" />},
];
// FIX

const ProfileForm = ({intl, handleSubmit, socialAccount, accountId, ...rest}) => {
	const [props] = splitGlobalAttrs(rest);
	return (
		<form onSubmit={handleSubmit} autoComplete="off" {...props}>
			<FormBlock noTopMargin>
				<Label>
					<FormattedMessage id="Language" />
				</Label>
				<SpacedInputs>
					{langOpts.map(l => (
						<Field
							key={l.value}
							name="language"
							type="radio"
							value={l.value}
							component={({input, inputId, meta}) => (
								<FormField>
									<Input {...input} id={inputId} />
									<Label htmlFor={inputId}>&nbsp;{l.title}</Label>
								</FormField>
							)}
						/>
					))}
				</SpacedInputs>
			</FormBlock>
			<FormBlock>
				<Label>
					<FormattedMessage id="Default calling application" />
				</Label>
				<SpacedInputs>
					{callOpts.map(c => {
						if (
							accountId !== 1 &&
							(c.value === 'leaddeskTalk' || c.value === 'soittolinja')
						)
							return null;
						else
							return (
								<Field
									key={c.value}
									name="defaultCall"
									type="radio"
									value={c.value}
									format={formatNullableString}
									parse={parseNullableString}
									component={({input, inputId, meta}) => (
										<FormField>
											<Input {...input} id={inputId} />
											<Label htmlFor={inputId}>&nbsp;{c.title}</Label>
										</FormField>
									)}
								/>
							);
					})}
				</SpacedInputs>
			</FormBlock>
			{!socialAccount && (
				<React.Fragment>
					<Field
						name="password"
						type="password"
						component={({input, inputId, meta}) => (
							<FormBlock>
								<Label htmlFor={inputId}>
									<FormattedMessage id={'Change password'} />
								</Label>
								<Input
									{...input}
									id={inputId}
									block
									stretch
									placeholder={intl.formatMessage({id: 'No change'})}
								/>
								<FieldError {...meta} />
							</FormBlock>
						)}
					/>
					<Field
						name="passwordConfirmation"
						type="password"
						component={({input, inputId, meta}) => (
							<FormBlock>
								<Label htmlFor={inputId}>
									<FormattedMessage id={'Repeat password'} />
								</Label>
								<Input
									{...input}
									id={inputId}
									meta={meta}
									block
									stretch
									placeholder={intl.formatMessage({id: 'No change'})}
								/>
								<FieldError {...meta} />
							</FormBlock>
						)}
					/>
				</React.Fragment>
			)}
		</form>
	);
};

export default compose(
	injectIntl,
	reduxForm({
		form: 'profileForm',
		validate,
		onSubmitFail: handleFormSubmitFail,
	}),
)(ProfileForm);
