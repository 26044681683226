import React from 'react';

const SvgReports = props => (
	<svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M12.667 16.665A6 6 0 0 1 7.7 14.031a.454.454 0 0 1-.06-.094 5.952 5.952 0 0 1-.974-3.272c0-3.308 2.692-6 6-6s6 2.692 6 6-2.692 6-6 6zm-3.468-2.888a4.627 4.627 0 0 0 3.468 1.555 4.672 4.672 0 0 0 4.666-4.667 4.677 4.677 0 0 0-4-4.613v4.613c0 .224-.11.431-.297.555l-3.837 2.557zM12 6.052c-2.27.327-4 2.288-4 4.613 0 .692.157 1.378.457 2.004L12 10.308V6.052zM4.667 32c-1.103 0-2-.897-2-2V2c0-1.103.897-2 2-2H22.39c.561.016 1.046.217 1.413.583l4.944 4.946c.377.378.585.88.585 1.414V30c0 1.103-.897 2-2 2H4.667zm0-30.667A.667.667 0 0 0 4 2v28c0 .368.299.667.667.667h22.666A.667.667 0 0 0 28 30V6.943a.668.668 0 0 0-.195-.472l-4.942-4.946a.668.668 0 0 0-.474-.192H4.667zM21.333 8a.667.667 0 0 1 0-1.334h3.334a.667.667 0 0 1 0 1.334h-3.334zm-14 12a.667.667 0 0 1 0-1.334h17.334a.667.667 0 0 1 0 1.334H7.333zm0 4a.667.667 0 0 1 0-1.334h17.334a.667.667 0 0 1 0 1.334H7.333zm0 4a.667.667 0 0 1 0-1.334h17.334a.667.667 0 0 1 0 1.334H7.333zm14-16a.667.667 0 0 1 0-1.334h3.334a.667.667 0 0 1 0 1.334h-3.334zm0 4a.667.667 0 0 1 0-1.334h3.334a.667.667 0 0 1 0 1.334h-3.334z"
		/>
	</svg>
);

export default SvgReports;
