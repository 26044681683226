import {effect} from 'utils/redux';
import namespace from './namespace';
import {getQuery} from 'io/history';
// import {parseUrlQuery} from './utils';
import {decorateWithNotifications} from 'io/app';
import * as actions from './actions';
import {catchNonFatalDefault} from 'io/errors';
import {getClients} from './io';
import {destroy as reduxFormDestroy} from 'redux-form';

const creator = effect(namespace);

export let initialize = () => (getState, dispatch) => {
	const query = getQuery();

	dispatch(actions._updateMetaData(query));
};
initialize = creator('initialize', initialize);

export let destroy = () => (getState, dispatch) => {
	dispatch(reduxFormDestroy('clientsFilterForm'));
};
destroy = creator('destroy', destroy);

export let requestingBuildingsByQuery = () => (getState, dispatch) => {
	const query = getQuery();

	// localStorage.setItem('lastClientsQuery', JSON.stringify(query));

	decorateWithNotifications(
		{id: 'get-buildings', failureStyle: 'error'},
		getClients(query),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(data => {
			dispatch(actions._setClients(data));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
requestingBuildingsByQuery = creator(
	'requestingBuildingsByQuery',
	requestingBuildingsByQuery,
);
