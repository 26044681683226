import {effect} from 'utils/redux';
import namespace from './namespace';
import {getUserStats, getPersonalTargets, getDashboardCallReminders} from 'io/data';
import {catchNonFatalDefault} from 'io/errors';
import * as rootSelectors from 'modules/common/selectors';
import * as rootActions from 'modules/common/actions';
import {decorateWithNotifications} from 'io/app';
import * as actions from './actions';
import {
	updateTimeEntry,
	deleteTimeEntry,
	updateVisitTarget,
	postVisitTarget,
	getDeals,
	getUserTimeEntries,
} from './io';
import services from 'services';
import msgs from 'dicts/messages';
import * as confirmerActions from 'modules/confirmer/actions';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

export let initialize = () => (getState, dispatch) => {
	const user = rootSelectors.user(getState());
	const isPilotUser = rootSelectors.isPilotUser(getState());

	decorateWithNotifications(
		{id: 'get-dashboard-data', failureStyle: 'warning'},
		Promise.all([
			getUserStats({userId: user.id}).then(stats => {
				dispatch(actions._setStats(stats));
			}),
			getPersonalTargets({type: 'visit'}).then(targets => {
				dispatch(actions._setTargets(targets));
			}),
			getDeals({reserverId: user.id}).then(deals => {
				dispatch(actions._setDeals(deals));
			}),
			getUserTimeEntries().then(te => {
				dispatch(rootActions._setTimeEntries(te));
			}),
			isPilotUser &&
				getDashboardCallReminders({userId: user.id}).then(reminders => {
					dispatch(actions._setCallReminders(reminders));
				}),
		]),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let saveTimeEntry = entry => (getState, dispatch) => {
	dispatch(rootActions._startProcessingTimeEntries());
	decorateWithNotifications(
		{
			id: 'update-time-entry',
			loading: intl.formatMessage({id: msgs.processing}),
			failureStyle: 'error',
		},
		updateTimeEntry(entry)
			.then(t => {
				dispatch(rootActions._setTimeEntries(t));
				dispatch(actions.closeTimeEntryModal());
			})
			.catch(e => {
				dispatch(rootActions._stopProcessingTimeEntries());
				throw e;
			}),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
saveTimeEntry = creator('saveTimeEntry', saveTimeEntry);

export let removeTimeEntry = timeEntryId => (getState, dispatch) => {
	const onConfirm = () => {
		dispatch(rootActions._startProcessingTimeEntries());
		decorateWithNotifications(
			{
				id: 'remove-time-entry',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Working hours removed'}),
				failureStyle: 'error',
			},
			deleteTimeEntry(timeEntryId)
				.then(t => {
					dispatch(rootActions._setTimeEntries(t));
					dispatch(actions.closeTimeEntryModal());
				})
				.catch(e => {
					dispatch(rootActions._stopProcessingTimeEntries());
					throw e;
				}),
		)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete work time entry?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
removeTimeEntry = creator('removeTimeEntry', removeTimeEntry);

export let saveVisitTarget = target => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'post-visit-target',
			loading: intl.formatMessage({id: msgs.processing}),
			failureStyle: 'warning',
		},
		target.id ? updateVisitTarget(target) : postVisitTarget(target),
	)(getState, dispatch)
		.then(t => dispatch(actions._updateTargets(t)))
		.catch(catchNonFatalDefault(getState, dispatch));
};
saveVisitTarget = creator('saveVisitTarget', saveVisitTarget);
