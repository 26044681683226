import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	processing: false,
	organizations: [],
	organizationsLoading: true,
	organizationEditorOpen: false,
	selectedOrganization: null,
};
