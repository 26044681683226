import {mapStateKeys} from './_internal';
import {setDateReservationState} from './utils';

export default ({
	stateRemaps = {},
	actions,
	effects,
	calendarResourceSelectionSelector,
}) => {
	const K = mapStateKeys(stateRemaps);

	return (state, fullState, {type, payload}) => {
		switch (type) {
			case actions.selectCalendarResource.type: {
				const currentDateId = calendarResourceSelectionSelector(fullState);

				// note that deselections don't trigger a pending state and essentially are accepted immediately
				return [
					{...state, [K.pendingCalendarResourceSelection]: payload},
					effects.selectCalendarResource({dateId: payload, currentDateId}),
				];
			}

			case actions._calendarResourceSelected.type: {
				return [{...state, [K.pendingCalendarResourceSelection]: null}, null];
			}

			case actions._calendarResourceSelectionFailed.type: {
				// if selection fails, treat the date as a reserved date even if the event hasn't come in yet
				const freeCalendarResources = setDateReservationState(
					true,
					state[K.pendingCalendarResourceSelection],
					state[K.freeCalendarResources],
				);

				const newState = {
					...state,
					[K.pendingCalendarResourceSelection]: null,
					[K.freeCalendarResources]: freeCalendarResources,
				};

				return [newState, null];
			}

			case actions._calendarResourceReserved.type: {
				const freeCalendarResources = setDateReservationState(
					true,
					payload,
					state[K.freeCalendarResources],
				);

				const newState = {
					...state,
					[K.freeCalendarResources]: freeCalendarResources,
				};

				return [newState, null];
			}

			case actions._calendarResourceReleased.type: {
				const freeCalendarResources = setDateReservationState(
					false,
					payload,
					state[K.freeCalendarResources],
				);

				const newState = {
					...state,
					[K.freeCalendarResources]: freeCalendarResources,
				};

				return [newState, null];
			}

			default:
				return [state, null];
		}
	};
};
