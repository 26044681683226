import React from 'react';

const SvgChevronLeft = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M2.4 5.76c0-.318.136-.62.374-.83L8.127.22a.887.887 0 0 1 1.171 1.332L4.618 5.67a.12.12 0 0 0 0 .18l4.68 4.118a.887.887 0 1 1-1.17 1.331L2.775 6.591A1.109 1.109 0 0 1 2.4 5.76z"
		/>
	</svg>
);

export default SvgChevronLeft;
