import React from 'react';

const SvgMultipleUsers = props => (
	<svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M19 22.333a.666.666 0 0 1-.472-.194L16 19.609l-2.528 2.528a.666.666 0 1 1-.944-.943l2.805-2.805v-3.056a.667.667 0 0 1 1.334 0v3.058l2.805 2.805A.666.666 0 0 1 19 22.333zm-3-15a3.671 3.671 0 0 1-3.667-3.666A3.671 3.671 0 0 1 16 0a3.671 3.671 0 0 1 3.667 3.667A3.671 3.671 0 0 1 16 7.333zm0-6a2.336 2.336 0 0 0-2.333 2.334A2.336 2.336 0 0 0 16 6a2.336 2.336 0 0 0 2.333-2.333A2.336 2.336 0 0 0 16 1.333zm-6 12a.664.664 0 0 1-.527-.257.664.664 0 0 1-.12-.575A6.852 6.852 0 0 1 16 7.337a6.854 6.854 0 0 1 6.645 5.166.664.664 0 0 1-.644.83H10zM21.07 12a5.518 5.518 0 0 0-6.435-3.157A5.5 5.5 0 0 0 10.929 12h10.142zM6.668 26A3.671 3.671 0 0 1 3 22.333a3.671 3.671 0 0 1 3.667-3.666 3.671 3.671 0 0 1 3.666 3.666A3.671 3.671 0 0 1 6.667 26zm0-6a2.336 2.336 0 0 0-2.334 2.333 2.336 2.336 0 0 0 2.334 2.334A2.336 2.336 0 0 0 9 22.333 2.336 2.336 0 0 0 6.667 20zm-6 12a.664.664 0 0 1-.646-.83 6.852 6.852 0 0 1 4.951-4.951 6.923 6.923 0 0 1 1.707-.216c1.221 0 2.428.333 3.489.962a6.816 6.816 0 0 1 3.145 4.204.664.664 0 0 1-.647.83l-12 .001zm11.07-1.333a5.524 5.524 0 0 0-2.25-2.555 5.51 5.51 0 0 0-4.187-.604 5.505 5.505 0 0 0-3.704 3.159h10.141zM25.333 26a3.671 3.671 0 0 1-3.666-3.667 3.671 3.671 0 0 1 3.666-3.666A3.671 3.671 0 0 1 29 22.333 3.671 3.671 0 0 1 25.333 26zm0-6A2.336 2.336 0 0 0 23 22.333a2.336 2.336 0 0 0 2.333 2.334 2.336 2.336 0 0 0 2.334-2.334A2.336 2.336 0 0 0 25.333 20zm-6 12a.668.668 0 0 1-.645-.83 6.852 6.852 0 0 1 4.95-4.951 6.92 6.92 0 0 1 1.706-.216c1.221 0 2.428.333 3.49.962a6.816 6.816 0 0 1 3.145 4.204.669.669 0 0 1-.646.831h-12zm11.071-1.333a5.524 5.524 0 0 0-2.25-2.555 5.514 5.514 0 0 0-4.186-.604 5.503 5.503 0 0 0-3.705 3.157h10.141v.002z"
		/>
	</svg>
);

export default SvgMultipleUsers;
