import {evolve, pick} from 'ramda';
import {over} from 'utils/lenses';

export const computeCondosQuery = ({filterForm, sortForm, laneFilterForm}) => {
	// see "LaneFilterForm.js". "noOffer/waiting" option for the offer lane is a special case.
	const stageFilters = over(
		['offer'],
		filters =>
			filters &&
			(filters.additionalInfoId === 'noOffer' || filters.additionalInfoId === 'waiting')
				? {state: filters.additionalInfoId}
				: filters,
		laneFilterForm,
	);
	return {
		...pick(
			[
				'fullAddress',
				'companyName',
				'manufacturingYearStart',
				'manufacturingYearEnd',
				'userId',
			],
			filterForm,
		),
		_sort: sortForm.sort,
		_stageFilters: stageFilters,
	};
};

export const formatCondosUrlQuery = query => {
	return {
		...query,
		_sort: query._sort ? `${query._sort}:asc` : '',
		_stageFilters: JSON.stringify(query._stageFilters),
	};
};

export const parseCondosUrlQuery = query => {
	return evolve(
		{
			manufacturingYearStart: x => (x ? Number(x) : null),
			manufacturingYearEnd: x => (x ? Number(x) : null),
			userId: x => (x ? Number(x) : null),
			_sort: x => (x ? x.split(':')[0] : null),
			_stageFilters: x => JSON.parse(x),
		},
		query,
	);
};
