import React, {useMemo} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {FormattedMessage} from '@meiko/react-intl';
import Media from 'react-responsive';
import Input from 'components/generic/Input';
import LabelLike from 'components/generic/LabelLike';
import FormBlock from 'components/generic/FormBlock';
import Button from 'components/generic/Button';
import SpacedItems from 'components/generic/SpacedItems';
import Modal from 'components/generic/Modal';
import ModalHeaderTitle from 'components/generic/ModalHeaderTitle';
import Map from 'components/generic/Map';
import MapTopWidgetsResponsive from 'components/generic/MapTopWidgetsResponsive';
import PlacesAutosuggest from 'components/generic/PlacesAutosuggest';
import Text from 'components/generic/Text';
import ButtonGroup from 'components/generic/ButtonGroup';
import ButtonRadio from 'components/generic/ButtonRadio';
import ButtonRadioLabel from 'components/generic/ButtonRadioLabel';
import msgs from 'dicts/messages';
import {screenSmMax, screenMdMin, borderColorInput} from 'styles/constants';
import {mapTooltip} from 'styles/fragments';

const smallQuery = `(max-width: ${screenSmMax})`;

const getAreasStr = areas =>
	areas
		? areas.map(a => `${a.title}${a.subtitle ? ` ${a.subtitle}` : ''}`).join(', ')
		: '';

const MapContainer = styled.div`
	position: relative;
`;

const MapTooltip = styled.div`
	${mapTooltip};

	:not(.show) {
		display: none;
	}
`;

const AreasTypeSelectorWrapper = styled(ButtonGroup)`
	@media (min-width: ${screenMdMin}) {
		border: 1px solid ${borderColorInput};
	}

	> :not(:first-child) {
		border-left: 1px solid ${borderColorInput};
	}
`;

const AreaAutoSuggest = styled(PlacesAutosuggest)`
	width: 200px;
`;

// eslint-disable-next-line react/prop-types
const AreasTypeSelector = ({setAreasSelectorType}) => (
	<AreasTypeSelectorWrapper>
		<ButtonRadio>
			<Input
				id="field-areaTypeCounty"
				type="radio"
				name="areaType"
				value="county"
				onChange={() => setAreasSelectorType('county')}
			/>
			<ButtonRadioLabel htmlFor="field-areaTypeCounty" size="l">
				<FormattedMessage id="Provinces" />
			</ButtonRadioLabel>
		</ButtonRadio>
		<ButtonRadio>
			<Input
				id="field-areaTypeCity"
				type="radio"
				name="areaType"
				value="city"
				defaultChecked={true}
				onChange={() => setAreasSelectorType('city')}
			/>
			<ButtonRadioLabel htmlFor="field-areaTypeCity" size="l">
				<FormattedMessage id="Cities" />
			</ButtonRadioLabel>
		</ButtonRadio>
		<ButtonRadio>
			<Input
				id="field-areaTypePostcode"
				type="radio"
				name="areaType"
				value="postcode"
				onChange={() => setAreasSelectorType('postcode')}
			/>
			<ButtonRadioLabel htmlFor="field-areaTypePostcode" size="l">
				<FormattedMessage id="ZIP codes" />
			</ButtonRadioLabel>
		</ButtonRadio>
		<ButtonRadio>
			<Input
				id="field-areaTypeCustom"
				type="radio"
				name="areaType"
				value="custom"
				onChange={() => setAreasSelectorType('custom')}
			/>
			<ButtonRadioLabel htmlFor="field-areaTypeCustom" size="l">
				<FormattedMessage id="Custom areas" />
			</ButtonRadioLabel>
		</ButtonRadio>
	</AreasTypeSelectorWrapper>
);

const AreasSelector = ({
	input,
	areasSelectorOpen,
	areasSelectorSearch,
	openAreasSelector,
	closeAreasSelector,
	setAreasSelectorType,
	setAreasSelectorSearch,
	openAreasSelectorSuggestion,
	clearAreasSelection,
	disabled,
}) => {
	const areasStr = useMemo(() => getAreasStr(input.value), [input.value]);
	return (
		<FormBlock>
			<LabelLike>
				<FormattedMessage id="Areas" />
			</LabelLike>
			<SpacedItems>
				<div>{areasStr}</div>
				{!!input.value.length && (
					<Button onClick={() => clearAreasSelection()} disabled={disabled}>
						<FormattedMessage id="Clear" />
					</Button>
				)}
				<Button onClick={() => openAreasSelector()} disabled={disabled}>
					<FormattedMessage id="Select areas" />
				</Button>
			</SpacedItems>

			{areasSelectorOpen && (
				<Modal
					className="header-dropdown-close-immune"
					verticalPadding
					hide={closeAreasSelector}
					headerTitle={
						<ModalHeaderTitle>
							<FormattedMessage id="Select areas" />
						</ModalHeaderTitle>
					}
					cancelButton={
						<Button appearance="success" onClick={() => closeAreasSelector()}>
							<FormattedMessage id={msgs.close} />
						</Button>
					}
				>
					<SpacedItems>
						<MapContainer>
							<Media query={smallQuery}>
								{isSmall => (
									<Map
										id="buildings-areas-selection-map"
										height={isSmall ? '400px' : '500px'}
									/>
								)}
							</Media>
							<MapTooltip id="buildings-areas-selection-map-area-tooltip" />
							<MapTopWidgetsResponsive>
								<AreasTypeSelector setAreasSelectorType={setAreasSelectorType} />
								<AreaAutoSuggest
									stretch
									inputProps={{
										value: areasSelectorSearch,
										onChange: (e, {newValue, method}) => setAreasSelectorSearch(newValue),
									}}
									highlightFirstSuggestion
									onSuggestionSelected={(e, {suggestion}) =>
										openAreasSelectorSuggestion(suggestion.place_id)
									}
									suggestionClass={({suggestion}) => (
										<span>{suggestion.description}</span>
									)}
								/>
							</MapTopWidgetsResponsive>
						</MapContainer>
						<div>
							{input.value.length ? (
								areasStr
							) : (
								<Text light>
									<FormattedMessage id="Select areas" />
								</Text>
							)}
						</div>
					</SpacedItems>
				</Modal>
			)}
		</FormBlock>
	);
};

AreasSelector.propTypes = {
	input: propTypes.object,
	areasSelectorOpen: propTypes.bool,
	areasSelectorSearch: propTypes.string,
	openAreasSelector: propTypes.func,
	closeAreasSelector: propTypes.func,
	setAreasSelectorType: propTypes.func,
	setAreasSelectorSearch: propTypes.func,
	openAreasSelectorSuggestion: propTypes.func,
	clearAreasSelection: propTypes.func,
	disabled: propTypes.bool,
};

export default AreasSelector;
