import services from 'services';
import {map, pipe, prop} from 'ramda';
import {describeThrow, describeError} from 'utils/errors';
import {
	initCalendarResourceInclude,
	tinyEncounterInclude,
	encounterInclude,
	salesmanVisitInclude,
	salesmanVisitsQueryBase,
} from './constants';
import {freeCalendarResourceInclude} from 'constants/data';
import {getResponseData} from 'utils/app';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {postCallLog as doPostCallLog} from 'io/calls';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getInitData = id =>
	httpJson('get', `/calendarResource/${id}`, {include: initCalendarResourceInclude})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(normalize.calendarResource));

export const getEncounters = buildingId =>
	httpJson('get', '/encounters', {
		buildingId,
		include: tinyEncounterInclude,
		_limit: '999',
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), map(normalize.encounter)));

export const getEncounter = id =>
	httpJson('get', `/encounters/${id}`, {include: encounterInclude})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(map(normalize.encounter));

export const getFreeCalendarResources = (buildingId, salesmanId) =>
	httpJson('get', '/calendarResource/free', {
		buildingId,
		salesmanId,
		include: freeCalendarResourceInclude,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), map(normalize.calendarResource)));

export const getSalesmen = organizationId =>
	httpJson('get', '/users', {
		organizationId,
		type: 'salesman',
		_limit: '999',
		_sort: 'lastName',
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), map(normalize.user)));

export const putCalendarResource = calRes =>
	httpJson('put', `/calendarResource/${calRes.id}`, {include: 'client'}, {body: calRes})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentPostFailed})))
		.then(getResponseData(normalize.calendarResource));

export const postCallLog = doPostCallLog;

export const postSalesmanVisit = salesmanVisit =>
	httpJson(
		'post',
		'/salesmanVisit',
		{include: salesmanVisitInclude},
		{body: salesmanVisit},
	)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentPostFailed})))
		.then(getResponseData(normalize.salesmanVisit));

export const putSalesmanVisit = salesmanVisit =>
	httpJson(
		'put',
		`/salesmanVisit/${salesmanVisit.id}`,
		{include: salesmanVisitInclude},
		{body: salesmanVisit},
	)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentPostFailed})))
		.then(getResponseData(normalize.salesmanVisit));

export const putBuilding = building =>
	httpJson('put', `/buildings/${building.id}`, {}, {body: building}).catch(
		describeThrow(intl.formatMessage({id: msgs.contentPostFailed})),
	);

export const postClient = (client, buildingId) =>
	httpJson('post', '/clients', {}, {body: {buildingId, ...client}}).catch(e => {
		const conflict = !!e.response && e.response.status === 409;
		e.causedByMarketingPrevented = conflict;
		return conflict
			? e.response.json().then(body => describeThrow(body.message, e))
			: Promise.reject(describeError(msgs.saveFailed, e));
	});

export const putClient = client =>
	httpJson('put', `/clients/${client.id}`, {}, {body: client}).catch(e => {
		const conflict = !!e.response && e.response.status === 409;
		e.causedByMarketingPrevented = conflict;
		return conflict
			? e.response.json().then(body => describeThrow(body.message, e))
			: Promise.reject(describeError(msgs.saveFailed, e));
	});

// comments, TEMP
export const postComment = comment =>
	httpJson('post', `/buildingComments`, {include: 'user'}, {body: comment})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(normalize.buildingComment));

export const putComment = (comment, id) =>
	httpJson('put', `/buildingComments/${id}`, {include: 'user'}, {body: comment})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(normalize.buildingComment));

export const deleteComment = id =>
	httpJson('delete', `/buildingComments/${id}`).catch(
		describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})),
	);
// ...

export const getSalesmanVisits = query =>
	httpJson('get', '/salesmanVisit', {...salesmanVisitsQueryBase, ...query})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), map(normalize.salesmanVisit)));
