import * as actions from './actions';
import * as selectors from './selectors';
import * as rootActions from 'modules/common/actions';
import * as rootSelectors from 'modules/common/selectors';
import {catchNonFatalDefault} from 'io/errors';
import {change, reset} from 'redux-form';
import {
	getUsers,
	postUser,
	putUser,
	deleteUser,
	reviveUser,
	getExcel,
	createEniocallerDetails as createEniocallerDetailsIO,
	deleteEniocallerDetails as deleteEniocallerDetailsIO,
} from './io';
import {effect} from 'utils/redux';
import namespace from './namespace';
import {decorateWithNotifications} from 'io/app';
import {getQuery, pushQuery} from 'io/history';
import {parseUrlQuery} from './utils';
import {equals, mergeLeft} from 'ramda';
import services from 'services';
import msgs from 'dicts/messages';
import * as confirmerActions from 'modules/confirmer/actions';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const fetchUsersData = (getState, dispatch) => {
	const usersQuery = selectors.usersQueryFetchable(getState());

	return getUsers(usersQuery).then(users => {
		dispatch(actions._setUsers(users));
	});
};

const fetchUsers =
	({notifyOpts = {}}) =>
	(getState, dispatch) => {
		return decorateWithNotifications(
			{id: 'get-users', failureStyle: 'error', ...notifyOpts},
			getUsers(selectors.usersQueryFetchable(getState())),
		)(getState, dispatch).then(users => {
			dispatch(actions._setUsers(users));
		});
	};

export let initialize = () => (getState, dispatch) => {
	const {usersQuery} = parseUrlQuery(getQuery());
	dispatch(actions._updateUsersQuery(usersQuery));

	decorateWithNotifications(
		{id: 'init-users', failureStyle: 'error'},
		fetchUsersData(getState, dispatch),
	)(getState, dispatch)
		.then(() => {
			dispatch(actions._opOk());
			dispatch(actions._initialize());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let updateUsers = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	const usersQuery = selectors.usersQueryRaw(getState());
	decorateWithNotifications(
		{id: 'get-users', failureStyle: 'error'},
		getUsers(usersQuery),
	)(getState, dispatch)
		.then(users => {
			dispatch(actions._setUsers(users));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
updateUsers = creator('updateUsers', updateUsers);

export let recheckQuery = () => (getState, dispatch) => {
	const urlQueries = parseUrlQuery(getQuery());
	const usersQuery = selectors.usersQueryRaw(getState());
	if (!equals(usersQuery, urlQueries.usersQuery)) {
		dispatch(actions._updateUsersQuery(urlQueries.usersQuery));
		fetchUsers({})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}
};

export let saveUser =
	({id, user}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'save-user',
				failureStyle: 'warning',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Saved'}),
			},
			id ? putUser(id, user) : postUser(user),
		)(getState, dispatch)
			.then(() => {
				updateUsers()(getState, dispatch);

				if (rootSelectors.user(getState()).id === user.id) {
					dispatch(rootActions.reinitialize());
				}
			})
			.then(() => dispatch(actions._opOk()))
			.catch(err => {
				dispatch(actions._opFailed());
				catchNonFatalDefault(getState, dispatch);
			});
	};
saveUser = creator('saveUser', saveUser);

export let clearForm = formName => (getState, dispatch) => {
	dispatch(reset(formName));
};
clearForm = creator('clearForm', clearForm);

export let removeUser =
	({id}) =>
	(getState, dispatch) => {
		const onConfirm = () => {
			dispatch(actions._startOp());
			decorateWithNotifications(
				{
					id: 'remove-user',
					failureStyle: 'warning',
					loading: intl.formatMessage({id: msgs.processing}),
					success: intl.formatMessage({id: 'User deleted'}),
				},
				deleteUser(id),
			)(getState, dispatch)
				.catch(e => {
					dispatch(actions._opFailed());
					throw e;
				})
				.then(() => {
					dispatch(actions._opOk());
					const {_page: page} = selectors.usersQueryRaw(getState());
					if (selectors.users(getState()).length <= 1 && page > 1) {
						dispatch(actions._updateUsersQuery({_page: page - 1}));
						pushQuery(mergeLeft(selectors.urlQuery(getState())));
					}

					return fetchUsers({notifyOpts: {loading: msgs.loading}})(getState, dispatch);
				})
				.catch(catchNonFatalDefault(getState, dispatch));
		};

		dispatch(
			confirmerActions.show({
				message: intl.formatMessage({id: 'Delete user?'}),
				cancelText: intl.formatMessage({id: msgs.cancel}),
				onCancel: () => {},
				onOk: onConfirm,
			}),
		);
	};
removeUser = creator('removeUser', removeUser);

export let restoreUser =
	({id}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'revive-user',
				failureStyle: 'warning',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'User restored'}),
			},
			reviveUser(id),
		)(getState, dispatch)
			.then(() => {
				updateUsers()(getState, dispatch);
				dispatch(actions._opOk());
				const {_page: page} = selectors.usersQueryRaw(getState());
				if (selectors.users(getState()).length <= 1 && page > 1) {
					dispatch(actions._updateUsersQuery({_page: page - 1}));
					pushQuery(mergeLeft(selectors.urlQuery(getState())));
				}
				return fetchUsers({notifyOpts: {loading: msgs.loading}})(getState, dispatch);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
restoreUser = creator('restoreUser', restoreUser);

export let searchUsers = () => (getState, dispatch) => {
	const searchQuery = selectors.usersQueryRaw(getState());
	pushQuery(mergeLeft(selectors.urlQuery(getState())));

	decorateWithNotifications(
		{
			id: 'get-users',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		getUsers(searchQuery),
	)(getState, dispatch)
		.then(users => {
			dispatch(actions._setUsers(users));
			dispatch(actions._opOk());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
searchUsers = creator('searchUsers', searchUsers);

export let fetchExcel = roles => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'get-excel',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Excel file loaded'}),
		},
		getExcel(roles),
	)(getState, dispatch)
		.then(res => {
			dispatch(actions._opOk());
			dispatch(actions.cancelExcelEditor());
			window.open(res.data, '_self');
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
fetchExcel = creator('getExcel', fetchExcel);

export let createEniocallerDetails = user => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'update-eniodetails',
			failureStyle: 'error',
		},
		createEniocallerDetailsIO(user),
	)(getState, dispatch)
		.then(newUserDetails => {
			if (newUserDetails?.meta?.enioCallerToken !== null) {
				dispatch(
					change('userForm', 'enioCallerToken', newUserDetails?.meta?.enioCallerToken),
				);
			}
			if (newUserDetails?.meta?.enioCallerUser !== null) {
				dispatch(
					change('userForm', 'enioCallerUser', newUserDetails?.meta?.enioCallerUser),
				);
			}
			if (newUserDetails?.meta?.enioCallerPassword !== null) {
				dispatch(
					change(
						'userForm',
						'enioCallerPassword',
						newUserDetails?.meta?.enioCallerPassword,
					),
				);
			}
			const newEnioCallerDetails = {
				enioCallerUser: newUserDetails?.meta?.enioCallerUser || null,
				enioCallerPassword: newUserDetails?.meta?.enioCallerPassword || null,
				enioCallerToken: newUserDetails?.meta?.enioCallerToken || null,
				domain: newUserDetails?.meta?.domain || null,
				currentRoleId: null,
			};
			dispatch(rootActions.updateUserMeta(newUserDetails.meta));
			dispatch(rootActions.updateEnioCallerDetails(newEnioCallerDetails));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};

createEniocallerDetails = creator('createEniocallerDetails', createEniocallerDetails);

export let deleteEniocallerDetails = user => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'delete-eniodetails',
			failureStyle: 'error',
		},
		deleteEniocallerDetailsIO(user),
	)(getState, dispatch)
		.then(newUserDetails => {
			dispatch(change('userForm', 'enioCallerUser', ''));
			dispatch(change('userForm', 'enioCallerPassword', ''));
			dispatch(rootActions.updateUserMeta(newUserDetails.meta));
			dispatch(
				rootActions.updateEnioCallerDetails({
					domain: null,
					enioCallerUser: null,
					enioCallerPassword: null,
					enioCallerToken: null,
					currentRoleId: null,
				}),
			);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
