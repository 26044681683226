import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import initState from './state';

export const parseUrlQuery = query => {
	const vals = evolve({_page: Number}, query);
	return {buildingsQuery: replace(vals, initState.buildingsQuery)};
};

export const formatUrlQuery = ({buildingsQuery}) => buildingsQuery;

export const formatBuildingsSearchInput = ({buildingsQuery}) => ({
	city: buildingsQuery._city,
	street: buildingsQuery._street,
	streetNumber: buildingsQuery.streetNumber,
	zip: buildingsQuery.zip,
});

export const formatBuildingsSearchOutput = form => ({
	_page: 1,
	_city: form.city,
	_street: form.street,
	streetNumber: form.streetNumber,
	zip: form.zip,
});

export const formatAddBuildingInput = ({buildingsQuery}) => ({
	address: `${buildingsQuery._street} ${buildingsQuery.streetNumber || ''}`.trim(),
	city: buildingsQuery._city || '',
	zip: buildingsQuery.zip || '',
	type: '',
});
