import React from 'react';

const SvgBell = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M6.118,10.4999981 C6.18171187,10.499649 6.23532139,10.5476382 6.242,10.611 C6.24744857,10.6571313 6.25012005,10.7035482 6.24999613,10.75 C6.24999613,11.4403559 5.69035594,11.9999981 5,11.9999981 C4.30964406,11.9999981 3.74999613,11.4403559 3.74999613,10.75 C3.74987995,10.7035482 3.75255143,10.6571313 3.758,10.611 C3.76511603,10.5478629 3.81846326,10.5001085 3.882,10.4999981 L3.882,10.4999981 Z M5,0 C5.41421356,0 5.75,0.335786438 5.75,0.75 L5.75,0.75 L5.75,1.318 C6.16554124,1.39226713 6.56220675,1.5482821 6.917,1.777 C8.15392277,2.64776891 8.8781295,4.07557639 8.85,5.588 L8.85,5.588 L8.85,5.972 C8.85182125,7.24527722 9.15074749,8.50056198 9.723,9.638 C9.76203187,9.71495893 9.75812514,9.80711411 9.71267767,9.88099171 C9.66723021,9.95486932 9.58673733,10 9.5,10 L9.5,10 L0.5,10 C0.413406965,9.999824 0.333071506,9.95485012 0.28765414,9.88112328 C0.242236774,9.80739643 0.238206061,9.71541715 0.277,9.638 C0.849252513,8.50056198 1.14817875,7.24527722 1.15,5.972 L1.15,5.972 L1.15,5.588 C1.12727409,4.18777123 1.74363944,2.85344793 2.8245,1.963 C3.2430865,1.64143412 3.73137539,1.42272317 4.25,1.3245 L4.25,1.3245 L4.25,0.75 C4.25,0.335786438 4.58578644,0 5,0 Z"
			transform="translate(1)"
		/>
	</svg>
);

export default SvgBell;
