import {toLookup} from './arrays';
import {curry} from 'ramda';
import msgs from 'dicts/messages';
import {describeError} from 'utils/errors';

// TODO: the below should be somewhere else, such as "appData" or just "app". "data" files elsewhere (constants, io) are used for concrete app resources rather than more generic (but still app-specific) transformations like the ones here

// useful for normalizing include lists into a suitable format for checkbox lists on forms
export const objectListToCheckboxVals = (allOpts, selectedOpts) => {
	const selectedIds = new Set(selectedOpts.map(o => o.id));
	return toLookup(
		o => o.id,
		o => selectedIds.has(o.id),
		allOpts,
	);
};

// useful for denormalizing form checkbox values into an id object list, suitable for posting to API
export const checkboxValsToIdObjectList = checkboxVals =>
	Object.keys(checkboxVals).reduce(
		(acc, idStr) => (checkboxVals[idStr] ? [...acc, {id: Number(idStr)}] : acc),
		[],
	);

// like "checkboxValsToIdObjectList", but returns a list of full objects instead of just objects with id's
export const checkboxValsToObjectList = (opts, checkboxVals) => {
	const idToObj = toLookup(
		o => o.id,
		o => o,
		opts,
	);
	return Object.keys(checkboxVals).reduce(
		(acc, id) => (checkboxVals[id] ? [...acc, idToObj[id]] : acc),
		[],
	);
};

// the main stuff begins here

export const describeBuildingGetError = curry((intlMethods, e) => {
	const noPermission = !!e.response && e.response.status === 403;
	e.causedByNoPermission = noPermission;
	const errMsgKey = noPermission
		? 'You do not have access to the building'
		: msgs.contentFetchFailed;
	return describeError(intlMethods.formatMessage({id: errMsgKey}), e);
});
