import {reduce, assoc, keys} from 'ramda';

export default function removeUnassignedProperties(x) {
	return reduce(
		(acc, cur) => {
			if (x[cur] !== null && x[cur] !== '' && x[cur] !== false) {
				return assoc(cur, x[cur], acc);
			}

			return acc;
		},
		{},
		keys(x),
	);
}
