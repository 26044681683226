import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {colors} from 'styles/constants';

const LoaderContainer = styled.div`
	display: inline-block;
	position: relative;
	width: ${({size}) => `${Math.round(size)}px`};
	height: ${({size}) => `${Math.round(size)}px`};

	div:nth-child(1) {
		transform: rotate(0deg);
		animation-delay: -1.1s;
	}
	div:nth-child(2) {
		transform: rotate(30deg);
		animation-delay: -1s;
	}
	div:nth-child(3) {
		transform: rotate(60deg);
		animation-delay: -0.9s;
	}
	div:nth-child(4) {
		transform: rotate(90deg);
		animation-delay: -0.8s;
	}
	div:nth-child(5) {
		transform: rotate(120deg);
		animation-delay: -0.7s;
	}
	div:nth-child(6) {
		transform: rotate(150deg);
		animation-delay: -0.6s;
	}
	div:nth-child(7) {
		transform: rotate(180deg);
		animation-delay: -0.5s;
	}
	div:nth-child(8) {
		transform: rotate(210deg);
		animation-delay: -0.4s;
	}
	div:nth-child(9) {
		transform: rotate(240deg);
		animation-delay: -0.3s;
	}
	div:nth-child(10) {
		transform: rotate(270deg);
		animation-delay: -0.2s;
	}
	div:nth-child(11) {
		transform: rotate(300deg);
		animation-delay: -0.1s;
	}
	div:nth-child(12) {
		transform: rotate(330deg);
		animation-delay: 0s;
	}
`;

const LoaderBar = styled.div`
	transform-origin: ${({size}) =>
		`${Math.round(size * 0.5)}px ${Math.round(size * 0.5)}px`};
	animation: lds-spinner 1.2s linear infinite;

	&:after {
		content: ' ';
		display: block;
		position: absolute;
		top: ${({size, barRadius, barHeight}) =>
			`${Math.round(size * 0.5 - barRadius - barHeight * 0.5)}px`};
		left: ${({size, barWidth}) => `${Math.round(size * 0.5 - barWidth * 0.5)}px`};
		width: ${({barWidth}) => `${Math.round(barWidth)}px`};
		height: ${({barHeight}) => `${Math.round(barHeight)}px`};
		border-radius: 20%;
		background: ${({color}) => color};
	}

	@keyframes lds-spinner {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
`;

const Loader = ({size = 24, color = colors.grey3, ...rest}) => {
	const barRadius = size * 0.35;
	const barWidth = size * 0.08;
	const barHeight = size * 0.22;

	const barProps = {
		size,
		color,
		barRadius,
		barWidth,
		barHeight,
	};

	return (
		<LoaderContainer size={size} {...rest}>
			<LoaderBar {...barProps} />
			<LoaderBar {...barProps} />
			<LoaderBar {...barProps} />
			<LoaderBar {...barProps} />
			<LoaderBar {...barProps} />
			<LoaderBar {...barProps} />
			<LoaderBar {...barProps} />
			<LoaderBar {...barProps} />
			<LoaderBar {...barProps} />
			<LoaderBar {...barProps} />
			<LoaderBar {...barProps} />
			<LoaderBar {...barProps} />
		</LoaderContainer>
	);
};

Loader.propTypes = {
	size: propTypes.number,
	color: propTypes.string,
};

export default Loader;
