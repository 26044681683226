import {scopedHandler} from 'utils/redux';
import {getFormValues} from 'redux-form';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {computeCondosQuery} from './utils';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize(payload)];
		}
		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.filter.type: {
			const filterForm = getFormValues('filterForm')(fullState);
			const sortForm = getFormValues('sortForm')(fullState);
			const laneFilterForm = getFormValues('laneFilterForm')(fullState);

			const condosQuery = computeCondosQuery({filterForm, sortForm, laneFilterForm});

			return [{...state, condosQuery, processing: true}, effects.updateCondos()];
		}

		case actions.changeLane.type: {
			return [{...state, currentLane: payload}, null];
		}

		case actions.navigateToCondo.type: {
			const projectBuildingId = payload[0];
			const options = payload[2] || {};
			return [state, effects.navigateToCondo([projectBuildingId, options])];
		}

		case actions._initialize.type: {
			const {condos: condosData, condosQuery, lane, additionalInfos, salesmen} = payload;
			const {data: condos, meta: condosMeta} = condosData;
			return [
				{
					...state,
					initialized: true,
					progressData: condos,
					condosMeta,
					condosQuery,
					currentLane: lane,
					additionalInfos,
					salesmen,
				},
				null,
			];
		}

		case actions._setCondos.type: {
			const {data: condos, meta: condosMeta} = payload;
			return [{...state, progressData: condos, condosMeta, processing: false}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
