import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const fetchMore = creator('fetchMore');

export const setLogsType = creator('setLogsType', P.String);

export const _setLogs = creator('_setLogs', P.Object);

export const _updateLogsQuery = creator('_updateLogsQuery', P.Object);
