import styled from 'styled-components';
import {borderColorInput} from 'styles/constants';

// note: only a rough draft

export default styled.div`
	border-radius: 3px;

	> * {
		border-radius: 0;
	}

	> :first-child {
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	}

	> :last-child {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}

	> :not(:first-child) {
		border-left: 1px solid ${borderColorInput};
	}
`;
