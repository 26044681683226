import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import {getFormValues} from 'redux-form';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {getBuildingsType, formatUrlQuery} from './utils';
import {pushQuery, replaceQuery} from 'io/history';
import {mergeLeft, always, contains, reject, append, equals} from 'ramda';
import removeUnassignedProperties from 'utils/remove-unassigned-props';
import {emptyBuildingsFilters} from './constants';
import {emptyPagination} from 'constants/pagination';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, effects.destroy()];
		}

		case actions.recheckQuery.type: {
			const reInit = payload;

			const newState = {
				...state,
				processing: !reInit,
				buildingsLoading: !reInit,
				buildingsNotYetLoaded: reInit,
				buildings: reInit ? [] : state.buildings,
				buildingsPagination: emptyPagination,
			};

			return [newState, !reInit ? effects.requestingBuildingsByQuery() : null];
		}

		case actions.changePage.type: {
			const buildingsQuery = {...state.buildingsQuery, _page: payload};
			const newState = {
				...state,
				buildingsLoading: true,
				buildingsQuery,
			};

			const urlQuery = formatUrlQuery({buildingsQuery});
			pushQuery(mergeLeft(removeUnassignedProperties(urlQuery)));

			return [newState, null];
		}

		case actions.setBuildingsType.type: {
			const q = getBuildingsType(payload);

			const newQuery = {
				...state.buildingsQuery,
				...q,
				_page: '1',
			};

			const urlQuery = formatUrlQuery({buildingsQuery: newQuery});
			replaceQuery(always(removeUnassignedProperties(urlQuery)));

			return [
				{
					...state,
					buildingsType: payload,
					buildingsQuery: newQuery,
				},
				null,
			];
		}

		case actions.setPerPage.type: {
			const newQuery = {
				...state.buildingsQuery,
				_limit: payload,
				_page: '1',
			};

			const urlQuery = formatUrlQuery({buildingsQuery: newQuery});
			pushQuery(mergeLeft(removeUnassignedProperties(urlQuery)));

			return [
				{
					...state,
					buildingsQuery: newQuery,
				},
				null,
			];
		}

		case actions.setSort.type: {
			const newQuery = {
				...state.buildingsQuery,
				_sortColumn: payload,
			};

			const urlQuery = formatUrlQuery({buildingsQuery: newQuery});
			pushQuery(mergeLeft(removeUnassignedProperties(urlQuery)));

			return [
				{
					...state,
					buildingsQuery: newQuery,
				},
				null,
			];
		}

		case actions.setSortDir.type: {
			const newQuery = {
				...state.buildingsQuery,
				_sortDirection: payload,
			};

			const urlQuery = formatUrlQuery({buildingsQuery: newQuery});
			pushQuery(mergeLeft(removeUnassignedProperties(urlQuery)));

			return [
				{
					...state,
					buildingsQuery: newQuery,
				},
				null,
			];
		}

		case actions.setQuery.type: {
			const values = getFormValues('buildingsFilterForm')(fullState) || {};

			const newQuery = {
				...state.buildingsQuery,
				...values,
				...getBuildingsType(state.buildingsType, values.types),
			};

			const urlQuery = formatUrlQuery({buildingsQuery: newQuery});
			// very very lazy... since this function is used for searching, but it's actually the changing of the url query that triggers the search, we use this dummy url query change to ensure that pressing the search button certainly triggers a search.
			const secsNow = Math.trunc(new Date().getTime() / 1000);
			const urlQueryWithDate = {...urlQuery, _time: secsNow};
			replaceQuery(always(removeUnassignedProperties(urlQueryWithDate)));

			return [
				{
					...state,
					buildingsQuery: newQuery,
				},
				null,
			];
		}

		case actions.clearQuery.type: {
			const newQuery = {
				...state.buildingsQuery,
				...emptyBuildingsFilters,
			};

			const urlQuery = formatUrlQuery({buildingsQuery: newQuery});
			replaceQuery(always(removeUnassignedProperties(urlQuery)));

			return [
				{
					...state,
					buildingsQuery: newQuery,
				},
				null,
			];
		}

		case actions.toggleMassEditor.type: {
			return [
				{...state, massEditorOpen: payload ? payload : !state.massEditorOpen},
				null,
			];
		}

		case actions.selectBuilding.type: {
			const selection = state.massSelection;

			const newSelection = contains(payload, selection)
				? reject(equals(payload), selection)
				: append(payload, selection);

			return [{...state, massSelection: newSelection}, null];
		}

		case actions.clearMassSelection.type: {
			return [{...state, massSelection: []}, null];
		}

		case actions.massSelect.type: {
			return [state, effects.requestingBuildingsIdsByQuery()];
		}

		case actions.updateSelectedBuildings.type: {
			return [
				state,
				effects.updatingSelectedBuildings({
					payload,
					ids: state.massSelection,
					selectionQuery: state.buildingsQuery,
				}),
			];
		}

		case actions.openAreasSelector.type: {
			return [{...state, areasSelectorOpen: true}, effects.initAreasMap()];
		}

		case actions.closeAreasSelector.type: {
			const newState = {
				...state,
				areasSelectorOpen: false,
				areasSelectorSearch: '',
			};
			return [newState, null];
		}

		case actions.setAreasSelectorType.type: {
			return [state, effects.setAreasMapType(payload)];
		}

		case actions.setAreasSelectorSearch.type: {
			return [{...state, areasSelectorSearch: payload}, null];
		}

		case actions.openAreasSelectorSuggestion.type: {
			return [state, effects.openAreasSelectorSuggestion(payload)];
		}

		case actions.openCsvFileSelector.type: {
			return [{...state, csvFileSelectorOpen: true}, null];
		}

		case actions.closeCsvFileSelector.type: {
			const newState = {
				...state,
				csvFileSelectorOpen: false,
			};
			return [newState, null];
		}

		case actions.csvFileSelectorUpload.type: {
			return [
				{
					...state,
					csvFileSelectorProcessing: true,
				},
				effects.handleCsvFileSelectorUpload(payload),
			];
		}

		case actions._csvFileSelectorUpload.type: {
			return [
				{
					...state,
					csvFileSelectorProcessing: false,
				},
				null,
			];
		}
		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false, buildingsLoading: false}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false}, null];
		}

		case actions.updateMetaData.type: {
			const q = getBuildingsType(payload.type);

			const newQuery = {
				...state.buildingsQuery,
				...payload.query,
				...q,
			};

			const urlQuery = formatUrlQuery({buildingsQuery: newQuery});
			replaceQuery(always(removeUnassignedProperties(urlQuery)));

			const newState = {
				...state,
				processing: payload.updateList,
				buildingsLoading: payload.updateList,
				buildingsNotYetLoaded: !payload.updateList,
				buildingsType: payload.type,
				contactUpdater: payload.contactUpdater,
				buildingsQuery: newQuery,
			};

			return [newState, payload.updateList ? effects.requestingBuildingsByQuery() : null];
		}

		case actions._setBuildings.type: {
			const newState = {
				...state,
				buildings: payload.data,
				buildingsPagination: payload.pagination,
				buildingsLoading: false,
				processing: false,
			};

			return [newState, null];
		}

		case actions._setMassSelection.type: {
			const newState = {
				...state,
				processing: false,
				massSelection: payload,
			};

			return [newState, null];
		}

		case actions._resetMassEditor.type: {
			const newState = {
				...state,
				processing: false,
				massSelection: [],
			};

			return [newState, null];
		}

		case actions.getAvailableTags.type: {
			return [state, effects.getAvailableTags()];
		}

		case actions._getAvailableTags.type: {
			return [
				{
					...state,
					availableTags: payload,
				},
				null,
			];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
