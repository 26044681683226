import {split, trim, join, dropLast, last} from 'ramda';
import {sortByTitle as sortAreas} from 'utils/areas';

export const formatClientFormInput = ({client}) => {
	return client;
};

export const formatClientFormOutput = ({form}) => {
	const client = {...form, emailMarketing: form.emailMarketing ? new Date() : null};

	return client;
};

export const format16100data = data => {
	const names = split(' ', trim(data.name));
	const lastName = last(names);
	const firstName = join(' ', dropLast(1, names));

	const client = {
		fullName: data.name,
		phone: data.phone,
		firstName,
		lastName,
		source: '16100',
		isNew: true,
	};

	return client;
};

export const formatBuilding = building => {
	if (!building) return null;

	return {
		...building,
		zipArea: building.areas?.find(a => a.type === 'postcode'),
		cityArea: building.areas?.find(a => a.type === 'city'),
		countyArea: building.areas?.find(a => a.type === 'county'),
	};
};

export const formatAreas = _areas => {
	const areas = sortAreas(_areas);
	const counties = areas.filter(a => a.type === 'county');
	const cities = areas.filter(a => a.type === 'city');
	const postcodes = areas.filter(a => a.type === 'postcode');

	return {
		counties,
		cities,
		postcodes,
	};
};
