import {map} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import {mapResponseData, getResponseData} from 'utils/app';
import * as normalize from 'utils/normalize';
import {noticesQueryBase} from './constants';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getNotices = query =>
	httpJson('get', `/notices`, {...noticesQueryBase, ...query, getAll: true})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.notice)));

export const getOrganizations = query =>
	httpJson('get', '/organizations', query)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.organization)));

export const getRoles = query =>
	httpJson('get', '/roles', query)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.roles)));

export const deleteNotice = id =>
	httpJson('delete', `/notices/${id}`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to delete internal release'})),
	);

export const postNotice = notice =>
	httpJson('post', '/notices', {}, {body: notice})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save internal release'})))
		.then(getResponseData(map(normalize.report)));

export const putNotice = (notice, id) =>
	httpJson('put', `/notices/${id}`, {}, {body: notice})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save internal release'})))
		.then(getResponseData(map(normalize.report)));
