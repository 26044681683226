import {effect} from 'utils/redux';
import {catchNonFatalDefault} from 'io/errors';
import namespace from './namespace';
import * as actions from './actions';
import * as commonSelectors from 'modules/common/selectors';
import {appName} from '../constants';
import {ensureAccess, setPageTitleMessage, decorateWithNotifications} from 'io/app';
import {getBuildings} from './io';
import services from 'services';
import {reset} from 'redux-form';
import {encodeQuery} from 'utils/url';

const creator = effect(namespace);
const history = services.get('history');

export let initialize = () => (getState, dispatch) => {
	setPageTitleMessage('Visits [app]')(getState, dispatch);

	ensureAccess(appName, commonSelectors.user(getState()))(getState, dispatch)
		.then(() => {
			dispatch(actions._initialize());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let searchBuildings = ({text, callback}) => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'search-buildings',
			failureStyle: 'warning',
		},
		getBuildings({_q: text, limit: '20', include: 'clients'}),
	)(getState, dispatch)
		.then(buildings => {
			callback(buildings);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
searchBuildings = creator('searchBuildings', searchBuildings);

export let navigateToBuilding = ({buildingId, query}) => (getState, dispatch) => {
	history.push(`/d2d/buildings/${buildingId}${encodeQuery(query)}`);
	dispatch(reset('searchForm'));
};
navigateToBuilding = creator('navigateToBuilding', navigateToBuilding);
