import {action} from 'utils/redux';
import {P, PaginatedData, Record, Maybe} from 'utils/types';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const changePage = creator('changePage', P.Number);

export const changeSort = creator('changeSort', P.String);

export const removeNotice = creator('removeNotice', P.Number);

export const toggleNoticeCreator = creator('toggleNoticeCreator', Maybe(P.Object));

export const saveNewNotice = creator('saveNewNotice');

export const editNotice = creator('editNotice');

export const _initialize = creator('_initialize');

export const _setInitData = creator(
	'_setInitData',
	Record({organizations: P.Array, roles: P.Array}),
);

export const _setNotices = creator('_setNotices', PaginatedData(P.Array));

export const _updateNoticesQuery = creator('_updateNoticesQuery', P.Object);

export const _noticeSaved = creator('_newNoticeSaved');

export const _startOp = creator('_startOp');

export const _opOk = creator('_opOk');

export const _opFailed = creator('_opFailed');

export const recheckQuery = creator('recheckQuery');
