import React from 'react';

const SvgIconWorkhours = props => (
	<svg viewBox="0 0 42.52 42.52" {...props} width="1em" height="1em">
		<g fill="currentColor">
			<path d="M17.196 10.626a.947.947 0 0 0-1.483.783V31.11a.944.944 0 0 0 .95.947.954.954 0 0 0 .533-.164l14.447-9.85a.947.947 0 0 0 0-1.569l-14.447-9.848zm12.232 10.633l-11.817 8.056V13.204l11.817 8.055z" />
			<path d="M21.26 1.268c-11.024 0-19.991 8.968-19.991 19.991 0 11.024 8.967 19.992 19.991 19.992s19.991-8.968 19.991-19.992c0-11.023-8.967-19.991-19.991-19.991zm18.096 19.991c0 9.979-8.118 18.096-18.096 18.096S3.164 31.238 3.164 21.259c0-9.977 8.118-18.095 18.096-18.095s18.096 8.118 18.096 18.095z" />
		</g>
	</svg>
);

export default SvgIconWorkhours;
