import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {formatFetchableLeadsQuery} from './utils';
import * as commonSelectors from 'modules/common/selectors';
import * as perms from 'utils/perms';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const leads = pipe(root, prop('leads'));

export const leadsLoading = pipe(root, prop('leadsLoading'));

export const leadsPagination = pipe(root, prop('leadsPagination'));

export const urlQuery = pipe(root, prop('urlQuery'));

export const leadsQuery = pipe(root, prop('leadsQuery'));

export const showOwnSalesmanagerLeads = createSelector([commonSelectors.user], user =>
	perms.showSalesmanagerLeads(user),
);

export const showAllSalesmanagerLeads = createSelector([commonSelectors.user], user =>
	perms.showAllSalesmanagerLeads(user),
);

export const showOwnOrgSalesmanagerLeads = createSelector([commonSelectors.user], user =>
	perms.showOwnOrgSalesmanagerLeads(user),
);

/*prettier-ignore*/
export const leadsQueryFetchable = createSelector(
	[leadsQuery,commonSelectors.user,showOwnSalesmanagerLeads,showAllSalesmanagerLeads,showOwnOrgSalesmanagerLeads,commonSelectors.activeOrganizationId],
	(leadsQuery,user,showOwnSalesmanagerLeads,showAllSalesmanagerLeads,showOwnOrgSalesmanagerLeads,organizationId) =>
		formatFetchableLeadsQuery({
			leadsQuery,
			userId: user.id,
			showOwnSalesmanagerLeads,
			showAllSalesmanagerLeads,
			showOwnOrgSalesmanagerLeads,
			organizationId,
		}),
);

/*prettier-ignore*/
export const salesmanagerLeads = createSelector(
	[leadsQuery, showOwnSalesmanagerLeads, showAllSalesmanagerLeads, showOwnOrgSalesmanagerLeads],
	(leadsQuery, showOwnSalesmanagerLeads, showAllSalesmanagerLeads, showOwnOrgSalesmanagerLeads) =>
		leadsQuery.salesmanagerLeads &&
		(showOwnSalesmanagerLeads || showAllSalesmanagerLeads || showOwnOrgSalesmanagerLeads),
);

export const processing = pipe(root, prop('processing'));

export const leadToDelete = pipe(root, prop('leadToDelete'));
