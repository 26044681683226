import {FormattedMessage} from '@meiko/react-intl';
import PanelHeaderTitle from 'components/generic/ModalHeaderTitle';
import Panel from 'components/generic/Panel';
import React from 'react';
import {Doughnut} from 'react-chartjs-2';

export const COLORS_BY_LABEl = {
	Tele: {
		bg: 'rgba(255, 99, 132, 1)',
		fg: 'rgba(255, 99, 132, 1)',
	},
	D2D: {
		bg: 'rgba(54, 162, 235, 1)',
		fg: 'rgba(54, 162, 235, 1)',
	},
	Markkinointi: {
		bg: 'rgba(75, 192, 192, 1)',
		fg: 'rgba(75, 192, 192, 1)',
	},
	'Myynti IH': {
		bg: 'rgba(255, 206, 86, 1)',
		fg: 'rgba(255, 206, 86, 1)',
	},
};

export const options = {
	indexAxis: 'y',
	responsive: true,
	plugins: {
		legend: {
			position: 'right',
		},
	},
};

const SalesBySourcesChart = ({data}) => {
	const labels = Object.keys(data ?? []).map(
		key => `${key} ${(data[key] * 100).toFixed(0)} %`,
	);
	const chartData = {
		labels,
		datasets: [
			{
				data: Object.keys(data ?? []).map(key => (data[key] * 100).toFixed(0)),
				backgroundColor: Object.keys(data ?? []).map(key => COLORS_BY_LABEl[key].bg),
				borderColor: Object.keys(data ?? []).map(key => COLORS_BY_LABEl[key].fg),
				borderWidth: 1,
			},
		],
	};
	return (
		<Panel
			header={
				<PanelHeaderTitle textAlign="center">
					<FormattedMessage id="Sales by source" />
					{' - '}
					<FormattedMessage id="Percentage (%)" />
				</PanelHeaderTitle>
			}
		>
			<Doughnut options={options} data={chartData} />
		</Panel>
	);
};

export default SalesBySourcesChart;
