import {mergeLeft, equals} from 'ramda';
import * as actions from './actions';
import * as selectors from './selectors';
import {catchNonFatalDefault} from 'io/errors';
import {getBuildings, postRestoreBuilding, postBuilding} from './io';
import {effect} from 'utils/redux';
import namespace from './namespace';
import {decorateWithNotifications} from 'io/app';
import {getQuery, pushQuery} from 'io/history';
import {parseUrlQuery} from './utils';
import services from 'services';
import msgs from 'dicts/messages';
import {createReferrerUrl, encodeQuery} from 'utils/url';

const creator = effect(namespace);

const history = services.get('history');

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const fetchBuildings = (getState, dispatch) => {
	return decorateWithNotifications(
		{id: 'get-buildings', failureStyle: 'error'},
		getBuildings(selectors.buildingsQueryFetchable(getState())),
	)(getState, dispatch).then(buildings => {
		dispatch(actions._setBuildings(buildings));
	});
};

export let initialize = () => (getState, dispatch) => {
	const {buildingsQuery} = parseUrlQuery(getQuery());
	const {_street} = buildingsQuery;
	if (!_street) {
		// no street in query
		// do not fetch buildings initially and show prompt instead
		dispatch(actions._showPrompt());
	} else {
		dispatch(actions._updateBuildingsQuery(buildingsQuery));
		fetchBuildings(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}
};
initialize = creator('initialize', initialize);

export let updateBuildings = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	fetchBuildings(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
updateBuildings = creator('updateBuildings', updateBuildings);

export let recheckQuery = () => (getState, dispatch) => {
	const urlQueries = parseUrlQuery(getQuery());
	const buildingsQuery = selectors.buildingsQuery(getState());
	if (!equals(buildingsQuery, urlQueries.buildingsQuery)) {
		dispatch(actions._updateBuildingsQuery(urlQueries.buildingsQuery));
		fetchBuildings(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}
};
recheckQuery = creator('recheckQuery', recheckQuery);

export let openBuilding = bid => (getState, dispatch) => {
	const referrerUrl = createReferrerUrl(history.location);
	history.push(`/buildings/buildings/${bid}${encodeQuery({referrerUrl})}`);
};
openBuilding = creator('openBuilding', openBuilding);

export let restoreBuilding = bid => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'restore-building',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		postRestoreBuilding(bid),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(() => {
			dispatch(actions._opOk());
			history.push(`buildings/${bid}`);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
restoreBuilding = creator('restoreBuilding', restoreBuilding);

export let addBuilding = building => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'add-building',
			failureStyle: 'error',
			loading: intl.formatMessage({id: 'Saving'}),
		},
		postBuilding(building),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(b => {
			dispatch(actions._opOk());
			history.push(`buildings/${b.id}`);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
addBuilding = creator('addBuilding', addBuilding);
