import namespace from './namespace';
import {action} from 'utils/redux';
import {map} from 'ramda';
import {P, Record, Maybe} from 'utils/types';

const creator = action(namespace);

const ShowArgs = Record(
	map(t => Maybe(t), {
		onCancel: P.Function,
		onOk: P.Function,
		title: P.String,
		message: P.String,
		cancelText: P.String,
		okText: P.String,
	}),
);

export const show = creator('show', ShowArgs);

export const cancel = creator('cancel');

export const ok = creator('ok');

export const _callbacksSet = creator('_callbacksSet');
