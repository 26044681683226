import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {fontSizeBase} from 'styles/constants';

const Wrapper = styled.div`
	text-align: ${({left}) => (left ? 'left' : 'center')};
	font-size: ${fontSizeBase};
	padding-top: ${({noTopPadding, noPadding}) => (noTopPadding || noPadding ? 0 : '20px')};
	padding-bottom: ${({noBottomPadding, noPadding}) =>
		noBottomPadding || noPadding ? 0 : '20px'};
`;

const ContentEmpty = ({message, left, ...rest}) => (
	<Wrapper left={left} {...rest}>
		{message}
	</Wrapper>
);

ContentEmpty.propTypes = {
	message: propTypes.node,
	left: propTypes.bool,
};

export default ContentEmpty;
