import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {
	formatUrlQuery,
	formatFetchableCalendarQuery,
	getUnallocated,
	getRoutesTotalDistance,
} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const calendarQuery = pipe(root, prop('calendarQuery'));

export const urlQuery = createSelector([calendarQuery], calendarQuery =>
	formatUrlQuery({calendarQuery}),
);

export const calendarQueryFetchable = createSelector([calendarQuery], calendarQuery =>
	formatFetchableCalendarQuery({calendarQuery}),
);

export const date = createSelector([calendarQuery], calendarQuery => calendarQuery.date);

export const calendarResources = pipe(root, prop('calendarResources'));

export const routes = pipe(root, prop('routes'));

export const extra = pipe(root, prop('extra'));

export const unallocated = createSelector([calendarResources], calendarResources =>
	getUnallocated(calendarResources),
);

export const team = pipe(root, prop('team'));

export const processing = pipe(root, prop('processing'));

export const routesCreated = pipe(root, prop('routesCreated'));

export const routesTotalDistance = createSelector([routes], routes =>
	getRoutesTotalDistance(routes),
);

export const processingCloseByRoutes = pipe(root, prop('processingCloseByRoutes'));

export const processingRoutes = pipe(root, prop('processingRoutes'));
