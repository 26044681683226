import React from 'react';

const SvgIconStartCall = props => (
	<svg viewBox="0 0 42.52 42.52" {...props} width="1em" height="1em">
		<g fill="currentColor">
			<path d="M32.746 15.84c-.124-.248-.311-.373-.559-.373h-7.328l7.266-11.489a.565.565 0 0 0 0-.621.654.654 0 0 0-.559-.311H21.63a.651.651 0 0 0-.559.311l-9.315 18.63a.565.565 0 0 0 0 .621.743.743 0 0 0 .559.311h6.396L11.756 39.5c-.124.248 0 .559.248.745.062.062.186.062.311.062a.706.706 0 0 0 .497-.186l19.872-23.599c.124-.185.186-.434.062-.682zM14.488 36.147l5.713-13.662a.605.605 0 0 0-.062-.559c-.124-.124-.311-.248-.497-.248h-6.334L22.002 4.29h8.446L23.12 15.778a.565.565 0 0 0 0 .621.654.654 0 0 0 .559.311h7.204L14.488 36.147z" />
			<path d="M12.315 40.601c-.165 0-.371 0-.52-.148-.343-.256-.483-.724-.3-1.085l6.775-16.156h-5.956c-.3 0-.629-.182-.803-.442a.853.853 0 0 1 .002-.946L20.81 3.227c.211-.317.494-.474.819-.474h9.936a.95.95 0 0 1 .803.441c.19.283.19.663.002.945l-6.98 11.035h6.798c.357 0 .65.19.822.534a.96.96 0 0 1-.08.976L13.036 40.31a1.006 1.006 0 0 1-.721.291zM21.629 3.34c-.13 0-.233.06-.316.182l-9.295 18.596c-.078.121-.078.239-.018.327a.452.452 0 0 0 .316.181h6.835l-.169.406-6.954 16.582c-.062.124.009.29.153.398.009.002.085.002.135.002a.42.42 0 0 0 .291-.102L32.46 16.334a.366.366 0 0 0 .023-.364c-.092-.184-.195-.209-.295-.209h-7.86l7.551-11.939a.27.27 0 0 0 .002-.301c-.107-.162-.231-.181-.316-.181h-9.936zm-8.148 34.455l6.45-15.423a.293.293 0 0 0-.034-.283c-.062-.054-.179-.119-.254-.119h-6.807l.213-.423 8.773-17.55h9.16l-7.613 11.939a.267.267 0 0 0-.005.3c.108.163.231.181.316.181h7.833l-.405.482-17.627 20.896zm.302-16.411h5.86c.334 0 .604.233.705.334.201.29.233.564.13.862l-4.983 11.918 14.759-17.496H23.68a.942.942 0 0 1-.803-.442.853.853 0 0 1 .002-.946l7.036-11.031h-7.732l-8.4 16.801z" />
		</g>
	</svg>
);

export default SvgIconStartCall;
