import React, {useMemo} from 'react';
import styled from 'styled-components';
import {compose} from 'ramda';
import {withRouter} from 'react-router-dom';
import Icon from 'components/generic/Icon';
import {buttonUnstyled} from 'styles/fragments';
import {connect} from 'react-redux';
import {applyState, applyDispatch} from 'utils/redux';
import {leaddeskTalkExpanded, user} from 'modules/common/selectors';
import {expandLeaddeskTalk, closeLeaddeskTalk} from 'modules/common/actions';

const Wrapper = styled.div`
	display: ${({show}) => (show ? 'flex' : 'none')};
	padding-left: 6px;
	height: 100%;
	background: #e8ebf4;
`;

const Toggler = styled.button`
	${buttonUnstyled};

	height: 100%;
	width: 12px;
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
	text-align: center;
	background: #eaeaea;

	:hover {
		background: #dfdfdf;
	}
`;

const LDContainer = styled.div`
	display: ${props => (props.show ? 'block' : 'none')};
`;

const enabledRoutesRegex = /^\/calls($|\/)/;

const LeaddeskClient = props => {
	const {location, user, expanded, expand, close, ...rest} = props;

	const winHeight = useMemo(() => window.innerHeight, []);

	// don't show leaddesk functionality if the user doesn't have this set as their call method
	if (user.defaultCall !== 'leaddeskTalk') {
		return null;
	}

	// we want this to show only in certain apps. we can't remove the iframe, because the leaddesk talk API expect it to be continuously present, so we just hide everything instead.
	const enabledInRoute = enabledRoutesRegex.test(location.pathname);

	return (
		<Wrapper show={enabledInRoute} {...rest}>
			<Toggler onClick={() => (expanded ? close() : expand())}>
				<Icon width="10px" name={expanded ? 'chevronRight' : 'chevronLeft'} />
			</Toggler>
			{/* note: no-referrer is actually needed, won't login automatically otherwise! */}
			<LDContainer show={expanded}>
				<iframe
					id="LD_talk"
					title="LeadDesk"
					allow="microphone *"
					referrerPolicy="no-referrer"
					src="https://login-ce2.leaddesk.com/"
					width={280}
					height={winHeight - 20}
					style={{border: 'none'}}
				></iframe>
			</LDContainer>
		</Wrapper>
	);
};

export default compose(
	withRouter,
	connect(
		applyState({user, expanded: leaddeskTalkExpanded}),
		applyDispatch({expand: expandLeaddeskTalk, close: closeLeaddeskTalk}),
	),
)(LeaddeskClient);
