import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {fontWeightLight, fontSizeXXL} from 'styles/constants';
import Loader from './Loader';

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const Message = styled.span`
	display: block;
	margin-top: 16px;
	font-size: ${fontSizeXXL};
	color: #777f88;
	font-weight: ${fontWeightLight};
`;

const PageLoader = ({message, ...rest}) => (
	<Wrapper {...rest}>
		<Loader size={64} />
		{message && <Message>{message}</Message>}
	</Wrapper>
);

PageLoader.propTypes = {
	message: propTypes.node,
};

export default PageLoader;
