import {catchNonFatalDefault} from 'io/errors';
import {updateUser} from './io';
import {effect} from 'utils/redux';
import namespace from './namespace';
import services from 'services';
import * as actions from './actions';
import {setPageTitleMessage, decorateWithNotifications} from 'io/app';
import msgs from 'dicts/messages';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

export let initialize = () => (getState, dispatch) => {
	setPageTitleMessage('Profile')(getState, dispatch);
};
initialize = creator('initialize', initialize);

export let updateUserData = ({id, user, needsReload}) => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'update-user',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Saved'}),
		},
		updateUser(id, user),
	)(getState, dispatch)
		.then(() => {
			if (needsReload) {
				window.location.reload();
			} else {
				dispatch(actions._userUpdated());
			}
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
updateUserData = creator('updateUserData', updateUserData);
