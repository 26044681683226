import services from 'services';
import {apiKeys} from 'modules/common/selectors';
import createScriptImporter from './createScriptImporter';

let store = null;
services.waitFor('store').then(x => (store = x));

window.googleMapsNullCallback = () => {};

export default createScriptImporter(
	// note the null callback - it's to silence google maps's warnings. google now suggests the script should be loaded with the `async` parameter and a callback function should be used to get notified about when it's done. but since we append the script into the DOM dynamically, our solution is already non-blocking, and thus we don't need the `async` parameter on the script. and because we load the script synchronously, we don't need the maps callback, we know maps is available once the script is loaded.
	() =>
		`https://maps.googleapis.com/maps/api/js?key=${
			apiKeys(store.getState()).GOOGLE_API_KEY
		}&libraries=places&callback=googleMapsNullCallback`,
	() => window.google.maps,
);
