import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const ModalFooter = ({left, right, ...rest}) => (
	<Wrapper {...rest}>
		<div>{left}</div>
		<div>{right}</div>
	</Wrapper>
);

ModalFooter.propTypes = {
	left: propTypes.node,
	right: propTypes.node,
};

export default ModalFooter;
