import {scopedHandler} from 'utils/redux';
import {getFormValues} from 'redux-form';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {formatRoleFormOutput, updateRolesList} from './utils';
import {sort, ascend, prop} from 'ramda';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.initRoleEditor.type: {
			return [{...state, selectedRole: payload, roleEditorOpen: true}, null];
		}
		case actions.cancelRoleEditor.type: {
			return [{...state, selectedRole: null, roleEditorOpen: false}, null];
		}

		case actions.saveRole.type: {
			const form = getFormValues('roleForm')(fullState) || {};

			const {role: roleData} = formatRoleFormOutput({form});
			const role = {...roleData, id: state.selectedRole ? state.selectedRole.id : null};

			return [{...state}, effects.saveRole({id: role.id, role})];
		}

		case actions._setPermissions.type: {
			const appPermissions = sort(
				ascend(prop('name')),
				payload.filter(p => p.slug.startsWith('access')),
			);

			const otherPermissions = sort(
				ascend(prop('slug')),
				payload.filter(p => !p.slug.startsWith('access')),
			);
			return [{...state, appPermissions, otherPermissions, permissions: payload}, null];
		}

		case actions._setRoles.type: {
			const {data} = payload;
			const roles = sort(ascend(prop('name')), data);

			const newState = {
				...state,
				roles,
				rolesLoading: false,
			};
			return [newState, null];
		}

		case actions._setProcessing.type: {
			return [{...state, processing: payload, roleEditorOpen: payload}, null];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._updateRoles.type: {
			const roles = updateRolesList({roles: state.roles, role: payload});

			const newState = {
				...state,
				roles: sort(ascend(prop('name')), roles),
				rolesLoading: false,
				roleEditorOpen: false,
				processing: false,
			};
			return [newState, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
