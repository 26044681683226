import namespace from './namespace';
import {effect} from 'utils/redux';
import {P, Record} from 'utils/types';
import services from 'services';
import {warn, catchNonFatalDefault} from 'io/errors';
import {describeError} from 'utils/errors';
import {messages} from 'constants/loc';
import {setPageTitleMessage} from 'io/app';
import {getQuery} from 'io/history';
import {longerDur} from 'constants/notifications';
import * as actions from './actions';
import * as globalActions from 'modules/common/actions';
import * as nActions from 'modules/notifications/actions';

const creator = effect(namespace);

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));

const adLogin = () => (getState, dispatch) => {
	localStorage.setItem('redirectUrl', window.location.pathname);
	return httpJson('get', '/auth/social/azure')
		.catch(e => {
			dispatch(actions._loginFailed());
			throw e;
		})
		.then(token => {
			window.location.assign(token.redirectUrl);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};

export let initialize = () => (getState, dispatch) => {
	setPageTitleMessage('Log in')(getState, dispatch);

	const {error, source} = getQuery();

	if (error) {
		dispatch(nActions.error({id: 'login-failed', message: error, duration: longerDur}));
	}

	if (source === 'intra' || source === 'piimega') {
		dispatch(actions._setLoading());
		adLogin()(getState, dispatch);
	}
	dispatch(actions._gotRedirect(getQuery().redirect));
};
initialize = creator('initialize', initialize);

export let login = credentials => (getState, dispatch) => {
	httpJson('post', '/login', {}, {body: credentials})
		.catch(e => {
			dispatch(actions._loginFailed());

			const msgKey =
				e.response && [401, 422].includes(e.response.status)
					? 'Login failed - Invalid email address or password'
					: 'Login failed';
			const eNew = describeError(messages[msgKey], e);
			warn(
				nActions.warning,
				{id: 'login-fail', duration: longerDur},
				eNew,
			)(getState, dispatch);

			throw eNew;
		})
		.then(({token}) => {
			dispatch(actions._loginOk());

			dispatch(globalActions.afterLogin(token));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
login = creator('login', login, Record({email: P.String, password: P.String}));

export let loginWithAd = () => (getState, dispatch) => {
	adLogin()(getState, dispatch);
};
loginWithAd = creator('loginWithAd', loginWithAd);
