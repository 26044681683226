import React from 'react';
import {FormattedMessage} from '@meiko/react-intl';

const NotFound = props => (
	<div>
		<FormattedMessage id="The content you requested was not found." />
	</div>
);

export default NotFound;
