import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {createSelector} from 'reselect';
import {formatSettingsFormInput} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const settings = pipe(root, prop('settings'));

export const teams = pipe(root, prop('teams'));

export const teamsLoading = pipe(root, prop('teamsLoading'));

export const settingsFormInitValues = createSelector(
	[teams, settings],
	(teams, settings) => formatSettingsFormInput({teams, settings}),
);
