export const getJson = key => {
	const str = localStorage.getItem(key);
	return str === null ? undefined : JSON.parse(str);
};

export const setJson = (key, val) => {
	localStorage.setItem(key, JSON.stringify(val));
};

export const updateJson = (key, func) => {
	const item = getJson(key);
	setJson(key, func(item));
};

export const deleteJson = key => {
	localStorage.removeItem(key);
};
