import {mergeLeft} from 'ramda';
import {reset} from 'redux-form';
import * as actions from './actions';
import * as selectors from './selectors';
import {catchNonFatalDefault} from 'io/errors';
import {getQuery, pushQuery} from 'io/history';
import {
	getLogs,
	getCallLogs,
	getOrganizations,
	searchUsers as ioSearchUsers,
	searchBuildings as ioSearchBuildings,
	getTeams,
} from './io';
import {effect} from 'utils/redux';
import namespace from './namespace';
import {decorateWithNotifications} from 'io/app';
import {parseUrlQuery} from './utils';

const creator = effect(namespace);

const fetchLogs = (getState, dispatch) => {
	const fetchableQuery = selectors.logsQueryFetchable(getState());
	const {logModel} = fetchableQuery;

	return decorateWithNotifications(
		{id: 'get-logs', failureStyle: 'error'},
		logModel === 'call' ? getCallLogs(fetchableQuery) : getLogs(fetchableQuery),
	)(getState, dispatch).then(logs => {
		dispatch(actions._setLogs(logs));
	});
};

export let initialize = () => (getState, dispatch) => {
	const logsQuery = parseUrlQuery(getQuery());
	dispatch(actions._updateLogsQuery(logsQuery));

	decorateWithNotifications(
		{id: 'init-logs', failureStyle: 'error'},
		Promise.all([
			fetchLogs(getState, dispatch),
			getOrganizations().then(organizations =>
				dispatch(actions._setOrganizations(organizations)),
			),
			getTeams().then(teams => dispatch(actions._setTeams(teams))),
		]),
	)(getState, dispatch)
		.then(() => {
			dispatch(actions._initialize());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let updateLogs = clearForm => (getState, dispatch) => {
	if (clearForm) {
		dispatch(reset('logSearchForm'));
	}

	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	fetchLogs(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
updateLogs = creator('updateLogs', updateLogs);

export let searchUsers =
	({text, callback}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'search-users',
				failureStyle: 'warning',
			},
			ioSearchUsers({_q: text, _limit: 5}),
		)(getState, dispatch)
			.then(users => {
				callback(users);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
searchUsers = creator('searchUsers', searchUsers);

export let searchBuildings =
	({text, callback}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'search-buildings',
				failureStyle: 'warning',
			},
			ioSearchBuildings({_q: text, _limit: 5}),
		)(getState, dispatch)
			.then(buildings => {
				callback(buildings);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
searchBuildings = creator('searchBuildings', searchBuildings);
