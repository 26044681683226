import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';

export default {
	...lifecycle,
	initialized: false,
	processing: false,
	usersQuery: {_page: 1, organizationId: null, teamId: null, roleId: null, _q: ''},
	users: [],
	usersLoading: true,
	usersPagination: emptyPagination,
	userCreatorOpen: false,
	excelEditorOpen: false,
	showDeleted: false,
	activeUser: null,
};
