import {findIndex, adjust, assoc, any, curry, values, groupBy, pipe} from 'ramda';

// for internal use mainly

// find a date by id and transform it
export const mapDate = curry((f, dateId, dates) => {
	if (!dates) return dates;

	const iGroup = findIndex(
		any(d => d.id === dateId),
		dates,
	);
	const iDate = iGroup >= 0 ? findIndex(d => d.id === dateId, dates[iGroup]) : -1;
	return iDate >= 0 ? adjust(iGroup, adjust(iDate, f), dates) : dates;
});

export const setDateReservationState = curry((reserved, dateId, dates) =>
	mapDate(assoc('reserved', reserved), dateId, dates),
);

// for external use

// note that you shouldn't put just any helpers here (they're better located under root utils), only stuff that is intended to be used with this particular fragment and mostly that (if they're needed elsewhere too, you can move them to root utils and put an alias here).

export const processFreeCalendarResources = pipe(
	// TODO: have a look at this? dates won't get grouped unless they have exactly identical timings
	groupBy(
		a =>
			`${a.dateFrom.toString()}${a.dateTo.toString()}${a.teamId}` +
			// group CRs with same products together
			`${
				a.products
					?.map(p => p.id)
					.sort()
					.join(',') || ''
			}`,
	),
	values,
	ds =>
		ds.sort((a, b) => {
			const aFrom = a[0].dateFrom;
			const bFrom = b[0].dateFrom;
			// prettier-ignore
			return aFrom > bFrom ? 1
				: aFrom < bFrom ? -1
				: a.title > b.title ? 1
				: a.title < b.title ? -1
				: 0;
		}),
);
