import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {sort, ascend, map, lensProp, over, prop} from 'ramda';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.updateData.type: {
			return [state, effects.updateData()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions._setInitMeta.type: {
			const {organizations, teams, roles, permissions} = payload;
			const appPermissions = sort(
				ascend(prop('name')),
				permissions.filter(p => p.slug.startsWith('access')),
			);

			const otherPermissions = sort(
				ascend(prop('slug')),
				permissions.filter(p => !p.slug.startsWith('access')),
			);

			const sortedOrgs = sort(
				ascend(prop('title')),
				map(over(lensProp(['teams']), sort(ascend(t => t.title))), organizations),
			);

			const sortedRoles = sort(ascend(prop('name')), roles);

			return [
				{
					...state,
					organizations: sortedOrgs,
					roles: sortedRoles,
					teams,
					otherPermissions,
					appPermissions,
					permissions: permissions,
				},
				null,
			];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
