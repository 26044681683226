import React from 'react';

const SvgIconCalendar = props => (
	<svg viewBox="0 0 42.52 42.52" {...props} width="1em" height="1em">
		<g fill="currentColor">
			<path d="M35.602 7.318h-4.69V5.173a1.07 1.07 0 1 0-2.144 0v2.145h-6.435V5.173a1.071 1.071 0 1 0-2.144 0v2.145h-6.435V5.173a1.071 1.071 0 1 0-2.144 0v2.145H6.92a2.817 2.817 0 0 0-2.817 2.817V35.6a2.818 2.818 0 0 0 2.818 2.818h28.683a2.817 2.817 0 0 0 2.817-2.817V10.135a2.82 2.82 0 0 0-2.819-2.817zm.672 28.283c0 .37-.303.673-.673.673H6.919a.673.673 0 0 1-.673-.673V10.136c0-.37.303-.673.673-.673h4.689v2.145a1.071 1.071 0 1 0 2.144 0V9.463h6.435v2.145a1.071 1.071 0 1 0 2.144 0V9.463h6.435v2.145a1.07 1.07 0 1 0 2.144 0V9.463h4.69c.37 0 .672.302.672.672v25.466z" />
			<path d="M11.608 16.97h4.29v3.217h-4.29zM11.608 22.332h4.29v3.217h-4.29zM11.608 27.694h4.29v3.217h-4.29zM19.115 27.694h4.29v3.217h-4.29zM19.115 22.332h4.29v3.217h-4.29zM19.115 16.97h4.29v3.217h-4.29zM26.622 27.694h4.29v3.217h-4.29zM26.622 22.332h4.29v3.217h-4.29zM26.622 16.97h4.29v3.217h-4.29z" />
		</g>
	</svg>
);

export default SvgIconCalendar;
