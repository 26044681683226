import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	loading: true,
	timeSpan: 'day',
	displayedTeams: [],
	stats: {},
	allTeams: [],
	date: new Date().toISOString().split('T')[0],
};
