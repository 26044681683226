export const IMPORT_TYPE_CLIENT = 'client';

export const STATE_DRAFT = 'draft';
export const STATE_CANCELLED = 'cancelled';
export const STATE_FAILED = 'failed';
export const STATE_COMPLETED = 'completed';
export const STATE_DRAFT_START_PROCESSING = 'startProcessing';
export const STATE_DRAFT_PROCESSING = 'draftProcessing';
export const STATE_DRAFT_PROCESSED = 'draftProcessed';
export const STATE_PROCESSING = 'processing';
export const STATE_CLIENTS_DELETED = 'clientsDeleted';
