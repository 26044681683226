import {state as lifecycle} from 'fragments/lifecycle';
import {emptyClientsFilters} from './constants';

export default {
	...lifecycle,
	processing: false,
	clientsQuery: {
		dismissPrompt: false,
		...emptyClientsFilters,
	},
	clientsLoading: false,
	clients: [],
	clientsTotal: null,
};
