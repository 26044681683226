import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';

export default {
	...lifecycle,
	reservationsQuery: {_page: 1},
	reservations: [],
	reservationsLoading: true,
	reservationsPagination: emptyPagination,
};
