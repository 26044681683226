import {effect} from 'utils/redux';
import {catchNonFatalDefault} from 'io/errors';
import namespace from './namespace';
import * as selectors from './selectors';
import * as actions from './actions';
import {
	getLeads,
	getProducts,
	getOrganizations,
	getSalesTeams,
	getAreas,
	getTags,
} from './io';
import {getUtmOptions} from '../common/io';
import {decorateWithNotifications} from 'io/app';
import {getQuery, pushQuery} from 'io/history';
import {mergeLeft, equals} from 'ramda';
import {parseUrlQuery} from './utils';
import {leadsQueryBase} from './constants';

const creator = effect(namespace);

const fetchLeads =
	({notifyOpts = {}}) =>
	(getState, dispatch) => {
		return decorateWithNotifications(
			{id: 'get-leads', failureStyle: 'warning', ...notifyOpts},
			getLeads({...selectors.leadsQueryFetchable(getState()), ...leadsQueryBase}),
		)(getState, dispatch).then(leads => {
			dispatch(actions._setLeads(leads));
		});
	};

export let initialize = () => (getState, dispatch) => {
	const {leadsQuery} = parseUrlQuery(getQuery());
	dispatch(actions._updateLeadsQuery(leadsQuery));

	decorateWithNotifications(
		{id: 'init-marketing-tracking', failureStyle: 'warning'},
		Promise.all([
			getLeads({...selectors.leadsQueryFetchable(getState()), ...leadsQueryBase}).then(
				leads => dispatch(actions._setLeads(leads)),
			),
			getProducts().then(data => {
				dispatch(actions._setProducts(data.data));
			}),
			getOrganizations().then(data => {
				dispatch(actions._setOrganizations(data.data));
			}),
			getUtmOptions().then(c => dispatch(actions._setUtmOptions(c))),
			getSalesTeams().then(teams => dispatch(actions._setSalesTeams(teams))),
			getAreas().then(areas => dispatch(actions._setAreas(areas))),
			getTags().then(tags => dispatch(actions._setTags(tags))),
		]),
	)(getState, dispatch)
		.then(_ => dispatch(actions._initialize()))
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let updateLeads = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	fetchLeads({})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
updateLeads = creator('updateLeads', updateLeads);

export let recheckQuery = () => (getState, dispatch) => {
	const urlQueries = parseUrlQuery(getQuery());
	const leadsQuery = selectors.leadsQuery(getState());
	if (!equals(leadsQuery, urlQueries.leadsQuery)) {
		dispatch(actions._updateLeadsQuery(urlQueries.leadsQuery));
		fetchLeads({})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}
};
