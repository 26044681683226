const globalAttrs = new Set([
	'accessKey',
	'autoCapitalize',
	'className',
	'contentEditable',
	'dir',
	'draggable',
	'hidden',
	'id',
	'inputMode',
	'is',
	'itemId',
	'itemProp',
	'itemRef',
	'itemScope',
	'itemType',
	'lang',
	'nonce',
	'slot',
	'spellCheck',
	'style',
	'tabIndex',
	'title',
	'translate',
]);

export const splitGlobalAttrs = attrs => {
	let other = {...attrs};
	let global = {};
	Object.keys(other).forEach(k => {
		if (globalAttrs.has(k) || k.startsWith('data-') || k.startsWith('aria-')) {
			global[k] = other[k];
			delete other[k];
		}
	});
	return [global, other];
};
