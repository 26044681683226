import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const search = creator('search', P.Object);

export const recheckQuery = creator('recheckQuery');

export const changePage = creator('changePage');

export const _setAreas = creator('_setAreas');

export const _setShowRemovedUsers = creator('_setShowRemovedUsers');

export const _setUserTeams = creator('_setUserTeams');

export const _setVisits = creator('_setVisits');

export const _updateQuery = creator('_updateQuery');

export const _initialize = creator('_initialize');

export const _setUsers = creator('_setUsers', P.Array);

export const _setLockFrom = creator('_setLockFrom');
