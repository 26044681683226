import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {
	fontSizeM,
	textColorLight,
	borderColorBase,
	screenSmMax,
	headerHeight,
} from 'styles/constants';

const Wrapper = styled.header`
	/* prevent going under regular overlays (in the content area), but not nav backdrop */
	z-index: 49;
	display: flex;
	flex-wrap: nowrap;
	align-items: stretch;
	border-bottom: 1px solid ${borderColorBase};
	padding: 0 25px;
	height: ${headerHeight};
	background: white;

	@media (max-width: ${screenSmMax}) {
		padding: 0 15px;
	}
`;
const ScrollWrapper = styled.div`
	display: flex;
	flex-wrap: nowrap;
	flex-grow: 1;

	> * {
		flex-shrink: 0;
	}
`;

const TitleWrapper = styled.div`
	flex-shrink: 0;
	display: flex;
	align-items: center;
`;

const CrumbArrow = styled.i.attrs({
	className: 'fa fa-angle-left',
})`
	margin: 0 10px;
	line-height: inherit;
	font-size: ${fontSizeM};
	color: ${textColorLight};
`;

const ContentWrapper = styled.div`
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: ${({justifyContent = 'flex-start'}) => justifyContent};
`;

const LeftActions = styled.div`
	display: flex;
	align-items: center;
`;

const Header = ({title, leftActions, crumb, content, justifyContent, ...rest}) => {
	return (
		<Wrapper {...rest}>
			<ScrollWrapper>
				<TitleWrapper>
					{crumb}
					{crumb && <CrumbArrow />}
					{title}
				</TitleWrapper>
				{leftActions && <LeftActions>{leftActions}</LeftActions>}
				{content && (
					<ContentWrapper justifyContent={justifyContent}>{content}</ContentWrapper>
				)}
			</ScrollWrapper>
		</Wrapper>
	);
};

Header.propTypes = {
	title: propTypes.node,
	leftActions: propTypes.node,
	crumb: propTypes.node,
	content: propTypes.node,
	justifyContent: propTypes.string,
};

export default styled(Header)``;
