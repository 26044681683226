import {action} from 'utils/redux';
import namespace from './namespace';
import {P, Nullable} from 'utils/types';
const creator = action(namespace);

export const initialize = creator('initialize');
export const _initialize = creator('_initialize');

export const destroy = creator('destroy');

export const _setUsers = creator('_setUsers', P.Array);

export const getOrganizations = creator('getOrganizations');
export const _getOrganizations = creator('_getOrganizations', P.Array);

export const getTeams = creator('getTeams');
export const _getTeams = creator('_getTeams');

export const fetchTeamWithUsers = creator('fetchTeamWithUsers', Nullable(P.Array));
export const fetchTeamsWithUsers = creator('fetchTeamsWithUsers', Nullable(P.Array));

export const setTeamType = creator('setTeamType');
export const _setTeamType = creator('_setTeamType', P.String);

export const getCities = creator('getCities');
export const _getCities = creator('_getCities', P.Array);

export const getEncounterables = creator('getEncounterables', P.Object);
export const _getEncounterables = creator('_getEncounterables', P.Object);
