// colors

export const colors = {
	primary1: '#73C8A0', // Primary button bg
	primary2: '#34845e', // Primary button bg hover state
	primary3: '#205039', // Primary button bg focus state
	secondary1: '#419fc6', // Link
	secondary2: '#323232', // Secondary button bg
	secondary3: '#4d4d4d', // Secondary hover state
	secondary4: '#666666', // Secondary focus state
	grey1: '#323232', // Base text color, Button dark bg focus
	grey2: '#4d4d4d', // Button dark hover
	grey3: '#666666', // Button dark bg
	grey4: '#808080', // Muted text
	grey5: '#b3b3b3', // Disable BG
	grey6: '#e3e3e3', // Border input
	grey7: '#ededed', // Border base (card, row)
	grey8: '#f7f7f7', // Bg color, row hover
	grey9: '#fcfcfc', // Card title bg
	success1: '#143325', // Success text color
	success2: '#73c8a0', // Success icon and border
	success3: '#def2e9', // Success bg
	warning1: '#57370c', // Warning text color
	warning2: '#edba79', // Warning icon and border
	warning3: '#f9ead7', // Warning bg
	error1: '#7e2315', // Error text color
	error2: '#e67866', // Error icon and border
	error3: '#fbebe9', // Error bg
	info1: '#21566d', // Info text color
	info2: '#419fc6', // Info icon and border
	info3: '#d8ebf3', // Info bg
	white: '#ffffff',
};

// descriptive variables for commonly used colors

export const brandColor = colors.secondary2;
export const linkColor = colors.secondary1;
export const highlightColor = colors.secondary1;
export const notificationColor = colors.error1;

export const successColor = colors.primary1;
export const infoColor = colors.info2;
export const warningColor = colors.warning2;
export const errorColor = colors.error2;

export const successColorDark = colors.primary2;
export const infoColorDark = colors.info1;
export const warningColorDark = colors.warning1;
export const errorColorDark = colors.error1;

export const infoColorLight = colors.info3;
export const successColorLight = colors.success3;
export const warningColorLight = colors.warning3;
export const errorColorLight = colors.error3;

export const textColorBase = colors.grey1;
export const textColorLight = colors.grey4;
export const borderColorInput = colors.grey6;
export const borderColorBase = colors.grey7;
export const disabledInputColor = colors.grey7;
export const backgroundColor = colors.grey8;
export const rowHoverColor = colors.grey8;
export const cardTitleBgColor = colors.grey9;
export const barFillColor = colors.primary1;

// old color variables, mapped to new

export const grayDark = colors.grey1;
export const gray = colors.grey5;
export const grayLight = colors.grey6;
export const grayLighter = colors.grey7;
export const grayLightest = colors.grey8;
export const grayExtraLight = colors.grey9;

// button colors

export const buttonFillColors = {
	primary: colors.primary1,
	success: colors.primary1,
	secondary: colors.secondary2,
	brand: colors.secondary2,
	default: colors.grey7,
	dark: colors.grey3,
	info: colors.info2,
	warning: colors.warning2,
	error: colors.error2,
	radio: colors.grey8,
};

export const basicColors = buttonFillColors;

export const buttonFillHoverColors = {
	primary: colors.primary2,
	success: colors.primary2,
	secondary: colors.secondary3,
	brand: colors.secondary3,
	default: colors.grey6,
	dark: colors.grey2,
	info: colors.info1,
	warning: colors.warning1,
	error: colors.error1,
	radio: colors.grey6,
};

export const buttonFillFocusColors = {
	primary: colors.primary3,
	success: colors.primary3,
	secondary: colors.secondary4,
	brand: colors.secondary4,
	default: colors.grey5,
	dark: colors.grey1,
	info: colors.info1,
	warning: colors.warning1,
	error: colors.error1,
	radio: colors.secondary2,
};

export const buttonFillActiveColors = buttonFillFocusColors;

export const buttonTextColors = {
	primary: colors.primary3,
	secondary: 'white',
	dark: 'white',
	default: textColorBase,
	info: 'white',
	success: colors.primary3,
	warning: colors.warning1,
	error: 'white',
	brand: 'white',
	radio: textColorBase,
};

export const buttonTextHoverColors = {
	...buttonTextColors,
	warning: 'white',
	primary: 'white',
	success: 'white',
};

export const buttonTextFocusColors = {
	...buttonTextHoverColors,
	radio: 'white',
	primary: 'white',
	success: 'white',
};

// organization colors

// TODO: remove references to this, instead use organization.meta.companyColors
export const organizationPrimaryColors = {
	1: '#ffffff',
	2: '#444444',
	3: '#73C8A0',
	4: '#806a4f',
	5: '#64427a',
	6: '#ff6d0e',
	7: '#E30314',
};
// TODO: same here
export const organizationNavBorderColors = {
	1: '#e3e3e3',
	2: '#363636',
	3: '#68b490',
	4: '#695a47',
	5: '#4d3061',
	6: '#dc5f0e',
	7: '#b80210',
};

// This is set later in modules/common/effects
export const organizationMapPrimaryColors = {current: null};

// state colors

export const salesmanVisitStateColors = {
	deal: successColor,
	offer: warningColor,
	notReached: warningColor,
	notHome: warningColor,
	contacted: errorColor,
	newAppointment: infoColor,
	cancelledOffer: errorColor,
	cancelledDeal: errorColor,
};

// concrete encounter states
// NOTE: these include both encounter states and d2d encounter states, primarily due to legacy reasons
export const encounterStateColors = {
	call: 'rgba(255,229,86,1)',
	cancelled: 'rgba(166,166,166,1)',
	cancelledDeal: 'rgba(85,234,245,1)',
	cancelledOffer: 'rgba(255,229,86,1)',
	contacted: 'rgba(237,150,30,1)',
	deal: 'rgba(186,228,255,1)',
	installed: 'rgba(245,33,222,1)',
	installing: 'rgba(240,177,178,1)',
	newAppointment: 'rgba(255,166,0,1)',
	notEncountered: 'rgba(16,170,79,1)',
	notReached: 'rgba(255,239,217,1)',
	offer: 'rgba(0,137,224,1)',
	takeContact: 'rgba(160,245,195,1)',
	unknown: 'rgba(233,233,233,1)',
	visit: 'rgba(255,229,86,1)',
	salesAssignment: 'rgba(245,198,17,1)',
	salesAssignmentExpired: 'rgba(245,198,17,0.4)',
	cancelledCalendarResource: 'rgb(249, 168, 20)',
	removed: 'rgba(166,166,166,1)',
	removedFromAllOrganizations: 'rgba(166,166,166,1)',
	// the ones below are actually d2d encounter states
	notHome: 'rgba(241,91,30,1)',
};

// virtual building states
export const extraStateColors = {
	avoid: 'rgba(241,91,30,1)',
	banned: 'rgba(49,47,45,1)',
	noInterest: 'rgba(233,233,233,1)',
};

export const buildingStateColors = {...encounterStateColors, ...extraStateColors};

export const condoStateColors = {
	noNeed: errorColor,
	need: infoColorDark,
	visit: successColor,
	noOffer: errorColor,
	offer: successColor,
	cancelledOffer: errorColor,
	deal: successColor,
	default: successColor,
	notReached: errorColor,
	contact: successColor,
	waiting: warningColor,
};

export const additionalInfoColors = {
	1: successColor,
	2: successColor,
	3: infoColorDark,
	4: successColor,
	5: infoColorDark,
	6: errorColor,
	deal: successColor,
	cancelledOffer: errorColor,
	noOffer: errorColor,
	waiting: warningColor,
};

// concrete encounter states
export const encounterStateHighlightColors = {
	notEncountered: 'rgba(255,255,255,1)',
	takeContact: 'rgba(16,170,79,1)',
	contacted: 'rgba(239,245,72,1)',
	notReached: 'rgba(241,91,30,1)',
	call: 'rgba(237,150,30,1)',
	visit: 'rgba(237,150,30,1)',
	offer: 'rgba(222,195,255,1)',
	deal: 'rgba(0,137,224,1)',
	cancelledDeal: 'rgba(81,152,131,1)',
	cancelledOffer: 'rgba(237,150,30,1)',
	cancelled: 'rgba(233,233,233,1)',
	installed: 'rgba(255,255,255,1)',
	installing: 'rgba(236,217,173,1)',
	unknown: 'rgba(166,166,166,1)',
	newAppointment: 'rgba(136,136,136,1)',
	notHome: 'rgba(255, 192,167,1)',
	salesAssignment: 'rgba(245,198,17,1)',
	salesAssignmentExpired: 'rgba(245,198,17,0.4)',
	cancelledCalendarResource: 'rgb(162, 81, 23)',
	removed: 'rgba(166,166,166,1)',
};

// virtual building states
export const extraStateHighlightColors = {
	noInterest: 'rgba(166,166,166,1)',
	avoid: 'rgba(255, 192,167,1)',
	banned: 'rgba(187,0,0,1)',
};

export const buildingStateHighlightColors = {
	...encounterStateHighlightColors,
	...extraStateHighlightColors,
};

// stats colors

export const statsDayColor = '#52AC3A';
export const statsWeekColor = '#458dda';
export const statsMonthColor = '#9e64ac';

// typography

export const fontSizeXS = '11px';
export const fontSizeS = '12px';
export const fontSizeBase = '13px';
export const fontSizeM = '14px';
export const fontSizeL = '16px';
export const fontSizeXL = '18px';
export const fontSizeXXL = '20px';
export const fontSizeXXXL = '24px';

export const fontWeightLight = 300;
export const fontWeightRegular = 400;
export const fontWeightMedium = 600;

// layout

// export viewport widths as integers for easier programmatic check
export const screenXsMaxValue = 767;
export const screenSmMinValue = 768;
export const screenSmMaxValue = 991;
export const screenMdMinValue = 992;
export const screenMdMaxValue = 1199;
export const screenLgMinValue = 1200;
export const screenLgMaxValue = 1439;
export const screenXlMinValue = 1440;
export const screenXlMaxValue = 1919;
export const screenXxlMinValue = 1920;

export const screenXsMax = `${screenXsMaxValue}px`;
export const screenSmMin = `${screenSmMinValue}px`;
export const screenSmMax = `${screenSmMaxValue}px`;
export const screenMdMin = `${screenMdMinValue}px`;
export const screenMdMax = `${screenMdMaxValue}px`;
export const screenLgMin = `${screenLgMinValue}px`;
export const screenLgMax = `${screenLgMaxValue}px`;
export const screenXlMin = `${screenXlMinValue}px`;
export const screenXlMax = `${screenXlMaxValue}px`;
export const screenXxlMin = `${screenXxlMinValue}px`;

export const navHeight = '54px';
export const headerHeight = '50px';

// animations

export const transitionDropdown = 'transition: all 0.2s ease-out';
export const transitionDefault = 'transition: all 0.09s ease-out';
export const transitionLong = 'transition: all 0.5s ease-out';

// other

export const openLayersWidgetMargin = '0.5em';
export const radiusDefault = '4px';
export const radiusLarge = '8px';
export const radiusCircle = '50%';
export const radiusNone = '0';

// components

export const helpButtonDefaultSize = '16px';
export const helpButtonBigSize = '20px';
export const helpButtonSmallSize = '14px';
