import {path, pipe, prop, toPairs, reject, propEq, map} from 'ramda';
import namespace from './namespace';
import {createSelector} from 'reselect';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {formatUrlQuery} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const formFillsQuery = pipe(root, prop('formFillsQuery'));

export const processing = pipe(root, prop('processing'));

export const trackingForms = pipe(root, prop('trackingForms'));

export const formsLoading = pipe(root, prop('formsLoading'));

export const formsPagination = pipe(root, prop('formsPagination'));

export const formInModal = pipe(root, prop('formInModal'));

export const formModalOpen = pipe(root, prop('formModalOpen'));

export const formCreatorOpen = pipe(root, prop('formCreatorOpen'));

export const allTeams = pipe(root, prop('allTeams'));

export const form = pipe(root, prop('form'));

export const urlQuery = createSelector([formFillsQuery], formFillsQuery => {
	return formatUrlQuery({formFillsQuery});
});

const attributesToFormFields = pipe(
	reject(propEq('id', 10)),
	toPairs,
	map(([key, val]) => ({id: val.id, value: 2, comment: ''})),
);

export const trackingFormInitValues = createSelector(
	[form, allTeams],
	(form, allTeams) => ({
		formId: form ? form.id : null,
		team: null,
		salesman: null,
		formAttributes: form ? attributesToFormFields(form.formAttributes) : [],
	}),
);
