import React from 'react';

const SvgArrowDown = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M11.646 5.12a.707.707 0 0 0-.997.063L6.925 9.416a.125.125 0 0 1-.219-.083V.706a.706.706 0 0 0-1.412 0v8.627a.125.125 0 0 1-.219.083L1.35 5.183a.707.707 0 1 0-1.06.934L5.116 11.6a1.176 1.176 0 0 0 1.768 0l4.825-5.483a.706.706 0 0 0-.063-.997z"
		/>
	</svg>
);

export default SvgArrowDown;
