import services from 'services';
import importMaps from 'services/importMaps';
import {map} from 'ramda';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {mapResponseData, getResponseData} from 'utils/app';
import {teamQueryBase} from './constants';
import {apiUrl} from 'constants/app';
import {
	styles as mapStyles,
	maxZoom,
	localeCenterWeb,
	localeZoomFactor,
	areasMaxDetailZoom,
} from 'constants/maps';
import {deleteChildren} from 'utils/dom';
import {
	safeGetFeaturesAtPixel,
	safeHasFeatureAtPixel,
	addTooltipToLayers,
	renderAreaTooltip,
	_getMapTileSource,
} from 'io/maps';
import {encodeQuery} from 'utils/url';
import {getJsonProperties} from 'utils/maps';
import {getTeamArea} from './utils';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const decorateMapMethod = method => args =>
	importMaps()
		.then(method(args))
		.catch(describeThrow(intl.formatMessage({id: 'Error loading map'})));

// resources

export const getTeams = query =>
	httpJson('get', '/teams', {...query, ...teamQueryBase, include: 'areas:allAreas(true)'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.team)));

export const postTeam = team =>
	httpJson('post', `/teams/`, {}, {body: team})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save team'})))
		.then(getResponseData(map(normalize.team)));

export const updateTeam = (id, team) =>
	httpJson('put', `/teams/${id}`, {}, {body: team})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save team'})))
		.then(getResponseData(map(normalize.team)));

export const deleteTeam = id =>
	httpJson('delete', `/teams/${id}`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to delete team'})),
	);

// maps

const initZoom = 4 * localeZoomFactor;

const _getAreasStyle = ({selectedAreaIds, team}) => imports => {
	const {openLayers: ol} = imports;

	const sty = mapStyles(ol);
	const keys = ['id', 'title'];

	return feature => {
		const {id, title} = getJsonProperties(feature, keys);

		const isSelected = selectedAreaIds.has(id);
		const teamArea = getTeamArea({team, areaId: id});
		const isTemp = teamArea ? teamArea.temporaryArea : false;

		return new ol.style.Style({
			fill: isSelected ? sty.areasHlFill : sty.areasFill,
			stroke: isTemp && isSelected ? sty.areasHlStroke : sty.areasStroke,
			zIndex: isTemp && isSelected ? 1 : 0,
			// note: could move labels into a separate json layer at some point
			text: new ol.style.Text({
				text: title,
				fill: sty.areasTextFill,
				stroke: sty.areasTextStroke,
				font: sty.areasFont,
			}),
		});
	};
};
export const getAreasStyle = decorateMapMethod(_getAreasStyle);

const _getAreasSource = ({apiToken, areasType}) => imports => {
	const {openLayers: ol} = imports;

	return new ol.source.VectorTile({
		format: new ol.format.MVT({dataProjection: 'EPSG:4326'}),
		url: `${apiUrl}/maps/areas/mvt/{z}/{x}/{y}${encodeQuery({
			type: areasType,
			token: apiToken,
		})}`,
		maxZoom: areasMaxDetailZoom,
	});
};
export const getAreasSource = decorateMapMethod(_getAreasSource);

const createAreasLayer = ({apiToken, areasType, selectedAreaIds, team}) => imports => {
	const {openLayers: ol} = imports;

	const layer = new ol.layer.VectorTile({
		source: _getAreasSource({apiToken, areasType})(imports),
		style: _getAreasStyle({selectedAreaIds, team})(imports),
	});

	return layer;
};

const _initTeamAreasMap = ({
	apiToken,
	organizationId,
	areasType,
	selectedAreaIds,
	onAreaClick,
	getTeam,
}) => imports => {
	// keep some local state
	let currHoverAreaId = null;

	const {openLayers: ol} = imports;

	const mapEl = document.querySelector('#team-areas-selection-map');
	if (!mapEl) {
		return Promise.reject(new Error('Map element not found'));
	}

	const view = new ol.View({
		projection: 'EPSG:3857',
		center: localeCenterWeb,
		zoom: initZoom,
		maxZoom,
		enableRotation: false,
		constrainResolution: true,
	});

	const areasLayer = createAreasLayer({
		apiToken,
		areasType,
		selectedAreaIds,
		team: getTeam(),
	})(imports);

	const map = new ol.Map({
		target: 'team-areas-selection-map',
		view,
		layers: [
			new ol.layer.Tile({
				source: _getMapTileSource({sourceId: 'here-light'})(imports),
			}),
			// TODO: generic toggling for google maps - MVT layers don't work with it so need to use geojson sources and strict min zooms
			//new olgm.layer.Google(),
			areasLayer,
		],
		//interactions: olgm.interaction.defaults(),
	});

	const areaTooltipEl = document.getElementById('team-areas-selection-map-area-tooltip');

	const areaTooltip = new ol.Overlay({
		element: areaTooltipEl,
		positioning: 'bottom-center',
		offset: [0, -5],
	});

	const updateAreaTooltip = areaFeature => {
		const {id, title, subtitle} = getJsonProperties(areaFeature, [
			'id',
			'title',
			'subtitle',
		]);
		if (id === currHoverAreaId) return;
		currHoverAreaId = id;
		deleteChildren(areaTooltipEl);

		const teamArea = getTeamArea({team: getTeam(), areaId: id});
		const isTemp = teamArea ? teamArea.temporaryArea : false;

		renderAreaTooltip({
			tooltipEl: areaTooltipEl,
			title,
			subtitle,
			dateFrom: isTemp ? teamArea.dateFrom : null,
			dateTo: isTemp ? teamArea.dateTo : null,
		});
	};

	addTooltipToLayers({
		mapEl,
		map,
		layers: [areasLayer],
		tooltipEl: areaTooltipEl,
		tooltip: areaTooltip,
		updateTooltip: updateAreaTooltip,
	});

	map.on('click', e => {
		const features = safeGetFeaturesAtPixel(map, e.pixel, {
			layerFilter: l => l === areasLayer,
		});
		if (!features) return;
		onAreaClick(features[0]);
	});

	map.on('pointermove', e => {
		const pixel = map.getEventPixel(e.originalEvent);
		const hit = safeHasFeatureAtPixel(map, pixel, {
			layerFilter: l => l === areasLayer,
		});
		map.getViewport().style.cursor = hit ? 'pointer' : '';
	});

	//const olgmInst = new olgm.GoogleMaps({map});
	//olgmInst.activate();
	// use when removing olgm layer
	//olgmInst.deactivate();

	return Promise.resolve({map, areasLayer});
};
export const initTeamAreasMap = decorateMapMethod(_initTeamAreasMap);
