// core dependencies
import React from 'react';
import propTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import styled from 'styled-components';
// custom helpers & utils
import {FormattedMessage} from '@meiko/react-intl';
import {locale} from 'constants/app';
import {colors} from 'styles/constants';
// components
import Input from 'components/generic/Input';
import Label from 'components/generic/Label';
import FieldError from 'components/generic/FieldError';
import FormBlock from 'components/generic/FormBlock';
import Field from 'components/generic/Field';
import Button from 'components/generic/Button';

const StyledButton = styled(Button)`
	color: ${colors.white};
	background: ${colors.grey2};

	&:not(:disabled) {
		&:hover {
			background: ${colors.grey3};
			color: ${colors.white};
		}
		&:focus {
			background: ${colors.grey4};
			color: ${colors.white};
		}
	}
`;

const StyledLabel = styled(Label)`
	color: ${colors.white};
`;

const InfoText = styled.div`
	display: inline-block;
	line-height: 1.4;
	color: ${colors.white};
`;

const Form = ({handleSubmit, loading}) => {
	// remove autoComplete when sundahem gets AD login
	const autoComplete = locale === 'se' ? 'on' : 'off';
	return (
		<form onSubmit={handleSubmit} autoComplete={autoComplete}>
			<InfoText>
				<FormattedMessage id="If you do not have a Microsoft account, you can log in with your username and password" />
			</InfoText>
			<Field
				name="email"
				type="email"
				component={({input, inputId, meta}) => (
					<FormBlock>
						<StyledLabel htmlFor={inputId}>
							<FormattedMessage id="Email address" />
						</StyledLabel>
						<Input
							{...input}
							id={inputId}
							type="email"
							block
							stretch
							data-ti="login-email-field"
						/>
						<FieldError {...meta} />
					</FormBlock>
				)}
			/>
			<Field
				name="password"
				type="password"
				component={({input, inputId, meta}) => (
					<FormBlock>
						<StyledLabel htmlFor={inputId}>
							<FormattedMessage id="Password" />
						</StyledLabel>
						<Input
							{...input}
							id={inputId}
							type="password"
							block
							stretch
							data-ti="login-password-field"
						/>
						<FieldError {...meta} />
					</FormBlock>
				)}
			/>
			<StyledButton
				center
				appearance="secondary"
				size="xl"
				id="login-button"
				type="submit"
				data-ti="login-btn"
				disabled={loading}
				stretch
			>
				<FormattedMessage id="Log in" />
			</StyledButton>
		</form>
	);
};

Form.propTypes = {handleSubmit: propTypes.func, loading: propTypes.bool};

export default reduxForm({form: 'loginForm'})(Form);
