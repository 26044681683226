import React from 'react';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';
import {colors, errorColor, borderColorInput} from 'styles/constants';
import H2 from 'components/generic/H2';
import Icon from 'components/generic/Icon';

const Dropzone = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background: ${colors.grey7};
	padding: 10px;
	border-radius: 12px;
	height: 200px;
	border-width: 1px;
	border-style: solid;
	border-color: ${({isValid}) => (isValid ? borderColorInput : errorColor)};

	&:hover {
		cursor: pointer;
		background: ${colors.grey6};
	}
`;

const StyledH2 = styled(H2)`
	margin-top: 10px;
	margin-bottom: 5px;
`;

// redux-form compatible dropzone component
const ReactDropzone = ({value, onChange, meta, title, subtitle, disabled = false}) => {
	const isValid = meta ? !((meta.submitFailed || meta.touched) && meta.error) : true;

	const {getRootProps, getInputProps} = useDropzone({
		multiple: false,
		onDrop: files => onChange(files[0]),
		disabled,
	});

	return (
		<Dropzone {...getRootProps({className: 'dropzone'})} isValid={isValid}>
			<input {...getInputProps()} />
			{value ? (
				<div>
					<Icon name="file" width="32px" height="32px" />
					<StyledH2>{value.path} </StyledH2>
				</div>
			) : (
				<div>
					<Icon name="upload" width="32px" height="32px" />
					<StyledH2>{title}</StyledH2>
					<span>{subtitle}</span>
				</div>
			)}
		</Dropzone>
	);
};

export default ReactDropzone;
