import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const _setMetrics = creator('_setMetrics', P.Object);

export const _setPiimegaLogs = creator('_setPiimegaLogs', P.Array);

export const _setFeedback = creator('_setFeedback', P.Array);
