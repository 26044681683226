import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.fetchMore.type: {
			const newState = {
				...state,
				fetchingMore: true,
				logsQuery: {...state.logsQuery, _page: state.logsPagination.currentPage + 1},
			};

			return [newState, effects.updateLogs()];
		}

		case actions.setLogsType.type: {
			const newState = {
				...state,
				logs: [],
				logsQuery: {...state.logsQuery, _page: 1, type: payload},
				logsLoading: true,
			};

			return [newState, effects.updateLogs()];
		}

		case actions._setLogs.type: {
			const newState = {
				...state,
				logs: [...state.logs, ...payload.data],
				logsPagination: payload.pagination,
				logsLoading: false,
				fetchingMore: false,
			};

			return [newState, null];
		}

		case actions._updateLogsQuery.type: {
			const newState = {
				...state,
				logsQuery: {...state.logsQuery, ...payload},
			};

			return [newState, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
