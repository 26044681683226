import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import {formValueSelector} from 'redux-form';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {formatClientFormInput, formatVisitFormInput} from './utils';
import {createEmptyClient} from './constants';
import createDatePickSelectors from 'fragments/calendarResourcePicker/selectors';
import createBuildingPanelSelectors from 'fragments/buildingModalActions/selectors';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

const datePickSelectors = createDatePickSelectors({namespace});

export const {
	freeCalendarResources,
	pendingCalendarResourceSelection,
	lastCalendarResourceSelection,
} = datePickSelectors;

const buildingPanelSelectors = createBuildingPanelSelectors({namespace});

export const {buildingModalOpen} = buildingPanelSelectors;

export const processing = pipe(root, prop('processing'));

export const building = pipe(root, prop('building'));

export const buildingLoading = pipe(root, prop('buildingLoading'));

export const clients = pipe(building, prop('clients'));

export const initialized = pipe(root, s => !s.buildingLoading);

export const encounters = pipe(root, prop('encounters'));

export const encountersLoading = pipe(root, prop('encountersLoading'));

export const userTeams = pipe(root, prop('userTeams'));

export const userTeamsLoading = pipe(root, prop('userTeamsLoading'));

export const salesTeams = pipe(root, prop('salesTeams'));

export const salesTeamsLoading = pipe(root, prop('salesTeamsLoading'));

export const productStates = pipe(root, prop('productStates'));

export const visitJustSaved = pipe(root, prop('visitJustSaved'));

export const openedAt = pipe(root, prop('openedAt'));

export const visitFormInitValues = createSelector(
	[userTeams, productStates, openedAt],
	(userTeams, productStates, openedAt) =>
		formatVisitFormInput({userTeams, productStates, openedAt}),
);

export const visitFormState = state => formValueSelector('visitForm')(state, 'state');

export const freeCalendarResourcesLoading = pipe(
	root,
	prop('freeCalendarResourcesLoading'),
);

// encounter modal
export const encounter = pipe(root, prop('encounter'));

// client modal
export const client = pipe(root, prop('client'));

export const clientEditorOpen = pipe(root, prop('clientEditorOpen'));

export const clientFormInitValues = createSelector(
	[client, building],
	(client, building) =>
		formatClientFormInput({
			client: client || createEmptyClient(),
		}),
);
