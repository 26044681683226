import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {formatClientsFiltersOutput} from './utils';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.searchClients.type: {
			const newState = {
				...state,
				clientsLoading: true,
				clientsQuery: {
					...state.clientsQuery,
					...formatClientsFiltersOutput(payload),
					dismissPrompt: true,
				},
			};

			return [newState, effects.updateClients()];
		}

		case actions.getExcel.type: {
			return [{...state, processing: true}, effects.getExcel()];
		}

		case actions._updateClientsQuery.type: {
			const newState = {
				...state,
				clientsLoading: true,
				clientsQuery: {...state.clientsQuery, ...payload},
			};

			return [newState, null];
		}

		case actions._setClients.type: {
			const newState = {
				...state,
				clients: payload.data,
				clientsTotal: payload.total,
				clientsLoading: false,
			};

			return [newState, null];
		}

		case actions._setOrganizations.type: {
			return [{...state, organizations: payload}, null];
		}

		case actions._setProducts.type: {
			return [{...state, products: payload}, null];
		}

		case actions._searchFailed.type: {
			return [
				{
					...state,
					clients: initState.clients,
					clientsTotal: initState.clientsTotal,
					clientsLoading: false,
				},
				null,
			];
		}

		case actions._setProcessing.type: {
			return [{...state, processing: payload}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
