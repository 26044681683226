import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import PopperOverlay from 'components/generic/PopperOverlay';
import {buttonUnstyled, popperOverlayAnimated, popup, helpButton} from 'styles/fragments';
import {helpButtonDefaultSize, helpButtonBigSize} from 'styles/constants';

export const defaultSize = helpButtonDefaultSize;
export const bigSize = helpButtonBigSize;

const Root = styled.button.attrs({type: 'button'})`
	${buttonUnstyled};
	${helpButton};
`;

const Overlay = styled.div`
	${popperOverlayAnimated};
	${popup};
	overflow: auto;
	max-width: 500px;
	@media (max-width: 550px) {
		max-width: calc(100vw - 20px);
	}
`;

const HelpButton = ({
	primary,
	small,
	dark,
	darker,
	absolute,
	hZero,
	vZero,
	transX,
	transY,
	marginTop = '0',
	marginBottom = '0',
	marginLeft = '0',
	marginRight = '0',
	rootCss,
	popupPadding,
	popperProps = {},
	children,
}) => {
	return (
		<PopperOverlay
			popperProps={{
				strategy: 'fixed',
				modifiers: [{name: 'offset', options: {offset: [0, 5]}}],
				...popperProps,
			}}
			triggers={['click']}
			rootClass={Root}
			rootProps={{
				primary,
				small,
				dark,
				darker,
				absolute,
				hZero,
				vZero,
				transX,
				transY,
				marginTop,
				marginBottom,
				marginLeft,
				marginRight,
				rootCss,
			}}
			rootContent={<span className="fa fa-question-circle" />}
			overlayProps={{noPadding: !popupPadding}}
			overlayClass={Overlay}
			overlayContent={children}
		/>
	);
};

HelpButton.propTypes = {
	absolute: propTypes.bool,
	small: propTypes.bool,
	dark: propTypes.bool,
	darker: propTypes.bool,
	hZero: propTypes.bool,
	vZero: propTypes.bool,
	transX: propTypes.string,
	transY: propTypes.string,
	popupPadding: propTypes.bool,
	primary: propTypes.bool,
	marginTop: propTypes.string,
	marginBottom: propTypes.string,
	marginLeft: propTypes.string,
	marginRight: propTypes.string,
	rootCss: propTypes.string,
	popperProps: propTypes.object,
	children: propTypes.node,
};

export default HelpButton;
