import {action} from 'utils/redux';
import namespace from './namespace';
import {P, PaginatedData} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const changePage = creator('changePage', P.Number);

export const recheckQuery = creator('recheckQuery');

export const navigateToBuilding = creator('navigateToBuilding', P.Number);

export const _setReservations = creator('_setReservations', PaginatedData(P.Array));

export const _updateReservationsQuery = creator('_updateReservationsQuery', P.Object);
