import createScriptImporter from './createScriptImporter';
//import services from 'services';
//import {logInfo} from 'io/errors';

export default createScriptImporter(
	'https://kotisun.soittolinja.fi/js/soittolinja-plugin-replacement.js',
	// perform setup
	async () => {
		if (!window.soittolinja_install_plugin_functions) return;

		// setup soittolinja url logging

		window.soittolinja_install_plugin_functions();

		//const logSoittolinja = url => {
		//// just in case
		//if (!window.top.postMessage) return;
		//try {
		//window.top.postMessage({soittolinja_plugin_location: url}, '*');
		//} catch (e) {
		//logInfo(e);
		//}
		//};
		//
		//logSoittolinja(window.location.toString());
		//
		//services.get('history').listen(location => {
		//const {pathname, search, hash} = location;
		//// just window.location could be enough, but use this instead since it's guaranteed to be up-to-date in all environments
		//logSoittolinja(window.location.origin + pathname + search + hash);
		//});
	},
);
