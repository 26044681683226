import {curry, pipe, prop} from 'ramda';
import {over} from 'utils/lenses';
import {spreadProp} from 'utils/objects';
import {getUserLanguage} from 'utils/loc';
import {allMessages, messages} from 'constants/loc';
// NOTE: this used to cause a circular dependency. if it happens again, you can circumvent it by using "state.common.user" directly.
import {user as userSel} from 'modules/common/selectors';

export const formatMessageFallbacked = id => state => {
	const user = userSel(state);
	return (user ? allMessages[getUserLanguage(user)][id] : messages[id]) || id;
};

export const getResponseData = curry((transf, res) => pipe(prop('data'), transf)(res));

export const mapResponseData = curry((transf, res) =>
	pipe(over(['data'], transf), spreadProp('meta'))(res),
);

export const isApiTokenError = error => !!error.response && error.response.status === 401;
