import services from 'services';
import {map} from 'ramda';
import {describeThrow} from 'utils/errors';
import {clientsInclude} from './constants';
import * as normalize from 'utils/normalize';
import {mapResponseData} from 'utils/app';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getClients = query =>
	httpJson('get', '/clients/registrySearch', {include: clientsInclude, ...query})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load clients'})))
		.then(mapResponseData(map(normalize.client)));
