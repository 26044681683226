import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const searchBuildings = creator('searchBuildings', P.Object);

export const navigateToBuilding = creator('navigateToBuilding', P.Object);

export const _setBuildings = creator('_setBuildings');

export const _initialize = creator('_initialize');
