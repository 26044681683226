import * as actions from './actions';
import * as commonSelectors from '../common/selectors';
import {catchNonFatalDefault} from 'io/errors';
import {effect} from 'utils/redux';
import namespace from './namespace';
import {decorateWithNotifications} from 'io/app';
import {getStatistics} from './io';
import services from 'services';
import {assocStats} from '../utils';

const creator = effect(namespace);

// eslint-disable-next-line
let intl = null;

services.waitFor('intl').then(x => (intl = x));

const fetchStats = (getState, dispatch) => {
	const teams = commonSelectors.teams(getState());
	decorateWithNotifications(
		{
			id: 'update-statistics',
			failureStyle: 'error',
		},
		getStatistics({teams}).then(stats => {
			dispatch(actions._setStatistics(assocStats(stats, teams)));
		}),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};

export let initialize = () => (getState, dispatch) => {
	fetchStats(getState, dispatch);
};
initialize = creator('initialize', initialize);

export let updateStats = () => (getState, dispatch) => {
	fetchStats(getState, dispatch);
};
updateStats = creator('updateStats', updateStats);

export let getStatsByDate = date => (getState, dispatch) => {
	const teams = commonSelectors.teams(getState());
	decorateWithNotifications(
		{
			id: 'update-statistics',
			failureStyle: 'error',
		},
		getStatistics({teams, date}).then(stats => {
			dispatch(actions._setStatistics(assocStats(stats, teams)));
		}),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
