import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {createSelector} from 'reselect';
import {formatClientFormInput, formatCallFormInput} from './utils';
import {createEmptyClient} from './constants';
import createDatePickSelectors from 'fragments/calendarResourcePicker/selectors';
import createBuildingPanelSelectors from 'fragments/buildingModalActions/selectors';
import createReminderSelectors from 'fragments/callReminder/selectors';
import {isPilotUser} from 'modules/common/selectors';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

const datePickSelectors = createDatePickSelectors({namespace});

export const {
	freeCalendarResources,
	pendingCalendarResourceSelection,
	lastCalendarResourceSelection,
} = datePickSelectors;

const buildingPanelSelectors = createBuildingPanelSelectors({namespace});

export const {buildingModalOpen} = buildingPanelSelectors;

const reminderSelectors = createReminderSelectors({namespace});

export const {
	callReminder,
	callReminderFormInitValues,
	callReminderLoading,
	callReminderEditorOpen,
} = reminderSelectors;

export const building = pipe(root, prop('building'));

export const buildingLoading = pipe(root, prop('buildingLoading'));

export const productStates = pipe(root, prop('productStates'));

export const freeCalendarResourcesLoading = pipe(
	root,
	prop('freeCalendarResourcesLoading'),
);

export const clients = pipe(building, prop('clients'));

export const initialized = pipe(root, s => s.reservedSuccessfully && !s.buildingLoading);

export const processing = pipe(root, prop('processing'));

export const processingEncounter = pipe(root, prop('processingEncounter'));

export const encounters = pipe(root, prop('encounters'));

export const encountersLoading = pipe(root, prop('encountersLoading'));

export const encounter = pipe(root, prop('encounter'));

export const clientEditorOpen = pipe(root, prop('clientEditorOpen'));

export const client = pipe(root, prop('client'));

export const userTeams = pipe(root, prop('userTeams'));

export const userTeamsLoading = pipe(root, prop('userTeamsLoading'));

export const salesTeams = pipe(root, prop('salesTeams'));

export const salesTeamsLoading = pipe(root, prop('salesTeamsLoading'));

export const activeCallPool = pipe(root, prop('activeCallPool'));

export const callJustSaved = pipe(root, prop('callJustSaved'));

export const fetchingNextBuilding = pipe(root, prop('fetchingNextBuilding'));

export const autoCallEnabled = state =>
	activeCallPool(state) ? activeCallPool(state).meta.autoCall : false;

export const fullyAutoCallEnabled = createSelector(
	[activeCallPool, isPilotUser],
	(activeCallPool, isPilotUser) => {
		return activeCallPool ? isPilotUser && activeCallPool?.meta?.fullyAutoCall : false;
	},
);

export const openedAt = pipe(root, prop('openedAt'));

export const survey = pipe(root, prop('survey'));

export const surveyCallReview = pipe(root, prop('surveyCallReview'));

export const surveyLoading = pipe(root, prop('surveyLoading'));

export const lead = pipe(root, prop('lead'));

export const clientFormInitValues = createSelector(
	[client, building],
	(client, building) =>
		formatClientFormInput({
			client: client || createEmptyClient(),
		}),
);

export const callFormInitValues = createSelector(
	[userTeams, openedAt, survey, surveyCallReview, lead],
	(userTeams, openedAt, survey, surveyCallReview, lead) =>
		formatCallFormInput({userTeams, openedAt, survey, surveyCallReview, lead}),
);

export const modalProcessing = pipe(root, prop('modalProcessing'));

// note: we've disabled this for leaddesk talk, but the logic for showing an end call button for leaddesk talk is still present, so just add `s.leaddeskTalkInProgress` back here if needed.
export const callInProgress = pipe(root, s => s.leaddeskCallInProgress);

export const leaddeskCallInProgress = pipe(root, s => s.leaddeskCallInProgress);

export const leaddeskTalkInProgress = pipe(root, s => s.leaddeskTalkInProgress);

export const marketingLeadSources = pipe(root, prop('marketingLeadSources'));

export const products = pipe(root, prop('products'));

export const availableTags = pipe(root, prop('availableTags'));

export const tags = pipe(root, prop('tags'));

export const processingTags = pipe(root, prop('processingTags'));

export const processingTag = pipe(root, prop('processingTag'));

export const leaveCallPoolAfterEncountedSaved = pipe(
	root,
	prop('leaveCallPoolAfterEncountedSaved'),
);
