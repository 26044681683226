import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	initialized: false,
	encounterables: [],
	users: [],
	organizations: [],
	teams: [],
	cities: [],
};
