import {scopedHandler} from 'utils/redux';
import {map} from 'ramda';
import ns from './namespace';
import * as actions from './actions';
import * as effects from './effects';
import initState from './state';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {normalizeTeam, formatTeams, normalizeStats} from './utils';
import * as commonSelectors from 'modules/statsApp/common/selectors';
import {addDays} from 'utils/time';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.fetchStats.type: {
			return [{...state}, effects.updateStats()];
		}

		case actions.setTimeSpan.type: {
			const settings = commonSelectors.settings(fullState);
			const allTeams = map(normalizeTeam, state.allTeams);
			const timeSpan = payload;
			const {displayedTeams} = formatTeams(settings, allTeams, timeSpan);

			return [{...state, timeSpan: payload, displayedTeams}, null];
		}

		case actions._setStatistics.type: {
			const allTeams = map(normalizeTeam, payload.teams);
			const settings = commonSelectors.settings(fullState);
			const stats = normalizeStats(payload.totalStats);
			const timeSpan = settings.timeSpan === 'none' ? state.timeSpan : settings.timeSpan;
			const {displayedTeams} = formatTeams(settings, allTeams, timeSpan);
			return [
				{
					...state,
					allTeams,
					stats,
					loading: false,
					displayedTeams,
					timeSpan,
				},
				null,
			];
		}

		case actions.getStatsByDate.type: {
			const date = addDays(new Date(state.date), payload).toISOString().split('T')[0];

			return [{...state, date, loading: true}, effects.getStatsByDate(date)];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
