import {mergeLeft, equals} from 'ramda';
import {over} from 'utils/lenses';
import {P} from 'utils/types';
import {formValueSelector, change as formChange} from 'redux-form';
import {sortByTitle as sortAreas} from 'utils/areas';
import * as actions from './actions';
import * as selectors from './selectors';
import {createReferrerUrl, encodeQuery} from 'utils/url';
import {catchNonFatalDefault, logWarning} from 'io/errors';
import {describeThrow} from 'utils/errors';
import {
	getCondos,
	initAreasMap as doInitAreasMap,
	getAreasStyle,
	getAreasSource,
	getProducts,
} from './io';
import {effect} from 'utils/redux';
import namespace from './namespace';
import {decorateWithNotifications} from 'io/app';
import {getQuery, pushQuery} from 'io/history';
import {transform} from 'ol/proj';
import {parseUrlQuery} from './utils';
import services from 'services';
import {geocodeGooglePlaceId} from 'io/geo';
import cache from './cache';
import {areaFocusZoom} from 'constants/maps';
import * as rootSelectors from 'modules/common/selectors';
import {getAllJsonProperties} from 'utils/maps';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));
const history = services.get('history');

const condosSearchFormVal = formValueSelector('condosSearchForm');

export let initialize = () => (getState, dispatch) => {
	const {condosQuery} = parseUrlQuery(getQuery());
	const {dismissPrompt} = condosQuery;

	decorateWithNotifications(
		{
			id: 'get-products',
			failureStyle: 'warning',
		},
		getProducts(),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(data => {
			dispatch(actions._setProducts(data));
		})
		.catch(catchNonFatalDefault(getState, dispatch));

	if (dismissPrompt) {
		dispatch(actions._updateCondosQuery(condosQuery));
		fetchCondos(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}
};
initialize = creator('initialize', initialize);

const getActiveAreaIds = (getState, dispatch) => {
	const areas = condosSearchFormVal(getState(), 'areas').map(a => a.id);
	return new Set(areas);
};

const selectArea = props => (getState, dispatch) => {
	const areas = condosSearchFormVal(getState(), 'areas');
	const newAreas = areas.find(a => a.id === props.id)
		? areas.filter(a => a.id !== props.id)
		: sortAreas([...areas, props]);
	dispatch(formChange('condosSearchForm', 'areas', newAreas));

	const selectedAreaIds = getActiveAreaIds(getState, dispatch);
	const {
		areasMap: {areasLayer},
	} = cache.read();
	getAreasStyle({selectedAreaIds})
		.then(x => areasLayer.setStyle(x))
		.catch(logWarning);
};

const fetchCondos = (getState, dispatch) => {
	return decorateWithNotifications(
		{id: 'get-condos', failureStyle: 'error'},
		getCondos(selectors.condosQueryFetchable(getState())),
	)(getState, dispatch).then(condos => {
		dispatch(actions._setCondos(condos));
	});
};

export let navigateToCondo = projectBuildingId => (getState, dispatch) => {
	const referrerUrl = createReferrerUrl(history.location);
	history.push(
		`/project-sales/map${encodeQuery({
			buildingId: projectBuildingId,
			projectBuildingId,
			referrerUrl,
		})}`,
	);
};
navigateToCondo = creator('navigateToCondo', navigateToCondo);

export let updateCondos = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	fetchCondos(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
updateCondos = creator('updateCondos', updateCondos);

export let recheckQuery = () => (getState, dispatch) => {
	const urlQueries = parseUrlQuery(getQuery());
	const condosQuery = selectors.condosQuery(getState());
	if (!equals(condosQuery, urlQueries.condosQuery)) {
		dispatch(actions._updateCondosQuery(urlQueries.condosQuery));
		fetchCondos(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}
};

export let initAreasMap = () => (getState, dispatch) => {
	const apiToken = rootSelectors.apiToken(getState());
	const org = rootSelectors.activeOrganization(getState());
	const selectedAreaIds = getActiveAreaIds(getState, dispatch);
	const onAreaClick = feature =>
		selectArea(getAllJsonProperties(feature))(getState, dispatch);

	decorateWithNotifications(
		{id: 'map-init'},
		doInitAreasMap({
			apiToken,
			organizationId: org.id,
			areasType: 'city',
			selectedAreaIds,
			onAreaClick,
		}),
	)(getState, dispatch)
		.then(resources => {
			cache.update(over(['areasMap'], mergeLeft(resources)));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initAreasMap = creator('initAreasMap', initAreasMap);

export let setAreasMapType = type => (getState, dispatch) => {
	const apiToken = rootSelectors.apiToken(getState());
	const {
		areasMap: {areasLayer},
	} = cache.read();
	getAreasSource({apiToken, areasType: type})
		.then(x => areasLayer.setSource(x))
		.catch(catchNonFatalDefault(getState, dispatch));
};
setAreasMapType = creator('setAreasMapType', setAreasMapType, P.String);

export let openAreasSelectorSuggestion = placeId => (getState, dispatch) => {
	geocodeGooglePlaceId(placeId)
		.then(res => {
			const {lat, lng} = res.geometry.location;
			const coord = transform([lng(), lat()], 'EPSG:4326', 'EPSG:3857');
			const {
				areasMap: {map},
			} = cache.read();
			const view = map.getView();
			view.animate({center: coord, zoom: areaFocusZoom});
		})
		.catch(describeThrow(intl.formatMessage({id: 'Search failed'})))
		.catch(catchNonFatalDefault(getState, dispatch));
};
openAreasSelectorSuggestion = creator(
	'openAreasSelectorSuggestion',
	openAreasSelectorSuggestion,
	P.String,
);
