import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {compose} from 'ramda';
import propTypes from 'prop-types';
import {applyStateDefault, applyDispatchDefault} from 'utils/redux';
import {
	user,
	activeOrganization,
	personalNotices,
	personalNoticesPagination,
	userCanCreateTimeEntries,
	activeTimerRunning,
	processingTimeEntries,
	userEntriesToday,
	feedbackModalOpen,
	feedbackLoading,
	feedbackFormInitValues,
	loadingPersonalNotices,
	personalNoticesProcessing,
} from 'modules/common/selectors';
import {
	setOrganization,
	logout,
	setNoticeSeen,
	setAllNoticesSeen,
	startTimer,
	stopTimer,
	openFeedbackModal,
	closeFeedbackModal,
	createFeedback,
	getMoreNotices,
} from 'modules/common/actions';
import Navbar from 'components/generic/Navbar';
import TimeTracker from 'components/views/TimeTracker';

const DefaultNavbar = ({
	location: {pathname},
	user,
	organizations,
	personalNotices,
	personalNoticesPagination,
	loadingPersonalNotices,
	getMoreNotices,
	setNoticeSeen,
	setAllNoticesSeen,
	activeTimerRunning,
	startTimer,
	stopTimer,
	processingTimeEntries,
	userEntriesToday,
	userCanCreateTimeEntries,
	openFeedbackModal,
	closeFeedbackModal,
	feedbackModalOpen,
	createFeedback,
	feedbackFormInitValues,
	feedbackLoading,
	personalNoticesProcessing,
	...rest
}) => (
	<Navbar
		{...rest}
		pathname={pathname}
		user={user}
		organizations={organizations || user.organizations}
		personalNotices={personalNotices}
		personalNoticesPagination={personalNoticesPagination}
		loadingPersonalNotices={loadingPersonalNotices}
		getMoreNotices={getMoreNotices}
		setNoticeSeen={setNoticeSeen}
		setAllNoticesSeen={setAllNoticesSeen}
		personalNoticesProcessing={personalNoticesProcessing}
		openFeedbackModal={openFeedbackModal}
		feedbackModalOpen={feedbackModalOpen}
		closeFeedbackModal={closeFeedbackModal}
		createFeedback={createFeedback}
		feedbackLoading={feedbackLoading}
		feedbackFormInitValues={feedbackFormInitValues}
		primaryActions={
			userCanCreateTimeEntries ? (
				<TimeTracker
					userEntries={userEntriesToday}
					activeTimerRunning={activeTimerRunning}
					startTimer={startTimer}
					stopTimer={stopTimer}
					processingTimeEntries={processingTimeEntries}
				/>
			) : null
		}
	/>
);

DefaultNavbar.propTypes = {
	location: propTypes.object.isRequired,
	appId: propTypes.string,
	user: propTypes.object.isRequired,
	organizations: propTypes.array,
	activeOrganization: propTypes.object.isRequired,
	setOrganization: propTypes.func,
	logout: propTypes.func.isRequired,
	personalNotices: propTypes.array,
	personalNoticesPagination: propTypes.object,
	loadingPersonalNotices: propTypes.bool,
	getMoreNotices: propTypes.func,
	setNoticeSeen: propTypes.func,
	setAllNoticesSeen: propTypes.func,
	userCanCreateTimeEntries: propTypes.bool,
	startTimer: propTypes.func,
	stopTimer: propTypes.func,
	activeTimerRunning: propTypes.string,
	processingTimeEntries: propTypes.bool,
	userEntriesToday: propTypes.object,
	openFeedbackModal: propTypes.func,
	closeFeedbackModal: propTypes.func,
	feedbackModalOpen: propTypes.bool,
	createFeedback: propTypes.func,
	feedbackFormInitValues: propTypes.object,
	feedbackLoading: propTypes.bool,
	personalNoticesProcessing: propTypes.bool,
};

export default compose(
	withRouter,
	connect(
		applyStateDefault({
			user,
			activeOrganization,
			personalNotices,
			personalNoticesPagination,
			loadingPersonalNotices,
			userCanCreateTimeEntries,
			activeTimerRunning,
			processingTimeEntries,
			userEntriesToday,
			feedbackModalOpen,
			feedbackLoading,
			feedbackFormInitValues,
			personalNoticesProcessing,
		}),
		applyDispatchDefault({
			setOrganization,
			logout,
			setNoticeSeen,
			setAllNoticesSeen,
			startTimer,
			stopTimer,
			openFeedbackModal,
			closeFeedbackModal,
			createFeedback,
			getMoreNotices,
		}),
	),
)(DefaultNavbar);
