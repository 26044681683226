import services from 'services';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {getResponseData, mapResponseData} from 'utils/app';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const defaultIncludes = 'organizations';

export const getTags = (query = {}) =>
	httpJson('GET', '/tags', {getAllTags: true, include: defaultIncludes, ...query}, {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(normalize.tag));

export const createTag = input =>
	httpJson('POST', '/tags', {include: defaultIncludes}, {body: input})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.tag));

export const updateTag = (id, input) =>
	httpJson('PUT', `/tags/${id}`, {include: defaultIncludes}, {body: input})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.tag));

export const deleteTag = id =>
	httpJson('DELETE', `/tags/${id}`, {}, {}).catch(
		describeThrow(intl.formatMessage({id: msgs.deleteFailed})),
	);

export const restoreTag = id =>
	httpJson('POST', `/tags/${id}/restore`, {}, {})
		.then(getResponseData(normalize.tag))
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})));
