import {pipeHandlers} from 'utils/reduxEffects';
import common from './common/handler';
import contacts from './contactsPage/handler';
import teamAreas from './teamAreasPage/handler';
import teamTarget from './teamTargetPage/handler';
import stats from './statsPage/handler';
import organizationTarget from './organizationTargetPage/handler';

export default pipeHandlers(
	common,
	contacts,
	teamAreas,
	teamTarget,
	stats,
	organizationTarget,
);
