import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import * as commonSelectors from 'modules/common/selectors';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {formatUrlQuery} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const reservationsQuery = pipe(root, prop('reservationsQuery'));

export const reservationsQueryFetchable = createSelector(
	[reservationsQuery, commonSelectors.user],
	(reservationsQuery, user) => {
		return {
			...reservationsQuery,
			reserverId: user.id,
		};
	},
);

export const urlQuery = createSelector([reservationsQuery], reservationsQuery => {
	return formatUrlQuery({reservationsQuery});
});

export const reservations = pipe(root, prop('reservations'));

export const reservationsPagination = pipe(root, prop('reservationsPagination'));

export const reservationsLoading = pipe(root, prop('reservationsLoading'));
