import services from 'services';
import {map, pick} from 'ramda';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {getResponseData} from 'utils/app';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getStatistics = ({teams, date}) =>
	httpJson(
		'post',
		'/statistics',
		{_method: 'GET'},
		{body: {allStats: true, date, teams: teams.map(pick(['id']))}},
	).catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})));

export const getTeams = query =>
	httpJson('get', '/teams', {...query, include: 'users'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.team)));
