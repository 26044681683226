import React from 'react';

const SvgNoticeInfo = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M5.992 11.999a6.103 6.103 0 0 1-4.255-1.827A5.9 5.9 0 0 1 .001 5.896 5.889 5.889 0 0 1 5.9 0h.106A6.055 6.055 0 0 1 12 6.104a5.882 5.882 0 0 1-6.008 5.895zm-.742-8.27a.738.738 0 0 0 .725.765h.013c.41-.001.746-.326.762-.735a.737.737 0 0 0-.725-.765h-.013a.764.764 0 0 0-.762.735zm.25 2.02v3a.5.5 0 0 0 1 0v-3a.5.5 0 0 0-1 0z"
		/>
	</svg>
);

export default SvgNoticeInfo;
