import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize', P.Number);

export const destroy = creator('destroy');

export const openEncounterModal = creator('openEncounterModal');

export const closeEncounterModal = creator('closeEncounterModal');

export const openClientEditor = creator('openClientEditor');

export const closeClientEditor = creator('closeClientEditor');

export const setClientBanState = creator('setClientBanState', P.Boolean);

export const restoreClient = creator('restoreClient');

export const removeClient = creator('removeClient');

export const saveClient = creator('saveClient');

export const _setClient = creator('_setClient', P.Object);

export const _setCalendarResources = creator('_setCalendarResources', P.Array);

export const _setEncounters = creator('_setEncounters', P.Array);

export const _setPreviewableEncounter = creator('_setPreviewableEncounter', P.Object);

export const _setClientBan = creator('_setClientBan', P.Object);

export const _removeClientBan = creator('_removeClientBan');

export const _setClientTrashed = creator('_setClientTrashed', P.Boolean);

export const _startOp = creator('_startOp');

export const _opOk = creator('_opOk');

export const _opFailed = creator('_opFailed');
