import {path, pipe, prop, sortBy, uniqBy} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import * as commonSelectors from 'modules/common/selectors';
import * as calendarSelectors from 'modules/calendarApp/common/selectors';
import {
	formatFetchableQuery,
	formatUrlQuery,
	formatSearchFormInput,
	isSalesmanUser,
} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const loading = pipe(root, prop('loading'));

export const userTeams = pipe(root, prop('userTeams'));

export const areas = pipe(root, prop('areas'));

export const showRemovedUsers = pipe(root, prop('showRemovedUsers'));

export const users = pipe(root, prop('users'));

export const team = createSelector([userTeams], userTeams =>
	userTeams.length ? userTeams[0] : null,
);

export const selectableUsers = createSelector(
	[users, team, calendarSelectors.showTeamUsers],
	(users, team, showTeamUsers) =>
		team && showTeamUsers
			? sortBy(prop('lastName'), uniqBy(prop('id'), [...users, ...team.users]))
			: users,
);

// PERM: ignore-visible-for-salesman
export const isSalesman = createSelector([commonSelectors.user], user =>
	isSalesmanUser(user),
);

export const salesmanVisits = pipe(root, prop('salesmanVisits'));

export const visitsPagination = pipe(root, prop('visitsPagination'));

export const salesmanVisitsQuery = pipe(root, prop('salesmanVisitsQuery'));

export const salesmanVisitsQueryFetchable = createSelector(
	[
		salesmanVisitsQuery,
		commonSelectors.user,
		userTeams,
		calendarSelectors.showTeamUsers,
		calendarSelectors.canSelectUser,
		isSalesman,
	],
	(salesmanVisitsQuery, user, userTeams, showTeamUsers, canSelectUser, isSalesman) =>
		formatFetchableQuery({
			salesmanVisitsQuery,
			user,
			userTeams,
			canSelectTeam: showTeamUsers,
			canSelectUser,
			isSalesman,
		}),
);

export const urlQuery = createSelector([salesmanVisitsQuery], salesmanVisitsQuery => {
	return formatUrlQuery({salesmanVisitsQuery});
});

export const searchFormInitValues = createSelector(
	[salesmanVisitsQuery, commonSelectors.user],
	(salesmanVisitsQuery, user) => formatSearchFormInput({salesmanVisitsQuery, user}),
);

export const selectedState = createSelector(
	[salesmanVisitsQuery],
	salesmanVisitsQuery => salesmanVisitsQuery.state,
);
export const checkUnacknowledged = createSelector(
	[salesmanVisitsQuery],
	salesmanVisitsQuery => salesmanVisitsQuery.unacknowledged,
);

export const lockFrom = pipe(root, prop('lockFrom'));
