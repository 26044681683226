import {objectListToCheckboxVals, checkboxValsToIdObjectList} from 'utils/data';
import {defaultColors} from 'constants/app';

export const formatOrganizationFormInput = ({organization, products}) => {
	//TODO FIX THIS
	// Way to selectedOrganizations products from product list, and change it to array of objects containing product ids
	// for objecListToCheckboxVals helper
	const mapProducts = products => {
		const organizationProducts = [];
		products.map(p =>
			p.organizations.map(o =>
				o.id === organization.id ? organizationProducts.push({id: p.id}) : null,
			),
		);
		return organizationProducts;
	};

	return {
		...organization,
		products: products ? objectListToCheckboxVals(products, mapProducts(products)) : null,
		primaryColor: organization.meta?.companyColors?.bg || '',
		primaryHighlightColor: organization.meta?.companyColors?.bgHover || '',
		textColor: organization.meta?.companyColors?.text || '',
		textHighlightColor: organization.meta?.companyColors?.textHover || '',
		borderColor: organization.meta?.companyColors?.border || '',
	};
};

export const formatOrganizationFormOutput = ({form, organization}) => {
	const {
		primaryColor,
		primaryHighlightColor,
		textColor,
		textHighlightColor,
		borderColor,
		...orgValues
	} = form;

	let meta = {};
	if (organization) {
		const colors = {
			bg: primaryColor || defaultColors.bg,
			bgHover: primaryHighlightColor || defaultColors.bgHover,
			text: textColor || defaultColors.text,
			textHover: textHighlightColor || defaultColors.textHover,
			border: borderColor || defaultColors.border,
		};
		const prevColors = organization.meta.companyColors || {};
		meta = {
			...organization.meta,
			companyColors: {...prevColors, ...colors},
		};
	}

	const orgData = {
		...orgValues,
		products: checkboxValsToIdObjectList(form.products),
		meta,
	};

	return {organization: orgData};
};
