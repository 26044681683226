import {action} from 'utils/redux';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const changeLane = creator('changeLane');

export const filter = creator('filter');

export const navigateToCondo = creator('navigateToCondo');

export const processing = creator('processing');

export const clearFormFields = creator('clearFormFields');

export const _initialize = creator('_initialize');

export const _setCondos = creator('_setCondos');

export const _opFailed = creator('_opFailed');
