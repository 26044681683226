import {pipeHandlers} from 'utils/reduxEffects';
import common from './common/handler';
import dashboardPage from './dashboardPage/handler';
import reservationsPage from './reservationsPage/handler';
import buildingPage from './buildingPage/handler';
import freeridePage from './freeridePage/handler';
import visitPage from './visitPage/handler';

export default pipeHandlers(
	common,
	dashboardPage,
	reservationsPage,
	buildingPage,
	freeridePage,
	visitPage,
);
