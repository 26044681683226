import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	loading: true,
	report: null,
	reportQuery: {
		date: new Date().toISOString().split('T')[0],
	},
};
