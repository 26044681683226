export const clientQueryBase = {
	withTrashed: 'true',
	include: 'building,ban.user,ban.organization',
	includeTrashed: 'building',
};

export const calendarResourceInclude = {
	include: 'reserver,salesman,reservation.encounter',
};

export const encountersInclude = {
	include: 'source.user',
};

export const encounterInclude = [
	'building',
	'source.formFill.formAttributes',
	'source.calendarResource.client',
	'source.calendarResource.salesmanVisit',
	'source.calendarResource.salesman',
	'source.calendarResource.team',
	'source.user',
	'source.products',
	'source.canceler',
	'reasonMapping',
].join(',');
