import {effect} from 'utils/redux';
import {catchNonFatalDefault} from 'io/errors';
import namespace from './namespace';
import * as actions from './actions';
import * as selectors from './selectors';
import {decorateWithNotifications} from 'io/app';
import {getQuery} from 'io/history';
import {pick} from 'ramda';
import services from 'services';
import msgs from 'dicts/messages';
import * as commonActions from '../common/actions';
import {updateRole, postRole, getRoles, getPermissions} from './io';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const rolesQueryKeys = ['_page'];

const fetchRolesData = (getState, dispatch) => {
	// const usersQuery = {
	// 	...selectors.allUsersQueryFetchable(getState()),
	// 	_limit: '999',
	// };
	const usersQuery = selectors.rolesQueryFetchable(getState());

	return Promise.all([
		getRoles(usersQuery).then(roles => {
			dispatch(actions._setRoles(roles));
		}),
		getPermissions().then(permissions => dispatch(actions._setPermissions(permissions))),
	]);
};

export let saveRole = ({id, role}) => (getState, dispatch) => {
	dispatch(actions._setProcessing(true));
	decorateWithNotifications(
		{
			id: 'save-role',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Saved'}),
		},
		id ? updateRole(id, role) : postRole(role),
	)(getState, dispatch)
		.then(role => {
			dispatch(actions._updateRoles(role));
			dispatch(commonActions.updateData());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
saveRole = creator('saveRole', saveRole);

export let initialize = () => (getState, dispatch) => {
	dispatch(actions._updateRolesQuery(pick(rolesQueryKeys, getQuery())));

	decorateWithNotifications(
		{id: 'init-roles', failureStyle: 'error'},
		fetchRolesData(getState, dispatch),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);
