import styled from 'styled-components';
import {borderColorBase} from 'styles/constants';
import {hrUnstyled} from 'styles/fragments';

const unpadCss = `
	margin-left: -20px;
	margin-right: -20px;
`;

export default styled.hr`
	${hrUnstyled};

	margin: 20px 0;
	${({unpad}) => (unpad ? unpadCss : '')};
	border-top: 1px solid ${borderColorBase};
`;
