import React from 'react';
import styled from 'styled-components';
import Loader from './Loader';

const Overlay = styled.div`
	position: relative;
	${({isLoading}) => (isLoading ? 'opacity: 0.3;' : '')};
	${({isLoading}) => (isLoading ? 'pointer-events: none;' : '')};
	transition: opacity 300ms;
`;
const LoaderWrapper = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: 20px;
	height: 20px;
`;
const LoaderOverlay = ({children, loading = false, showSpinner = true}) => {
	return (
		<>
			<Overlay isLoading={loading}>
				{loading && <LoaderWrapper>{showSpinner && <Loader />}</LoaderWrapper>}
				{children}
			</Overlay>
		</>
	);
};

export default LoaderOverlay;
