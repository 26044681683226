import cookies from 'js-cookie';
import {isSameSiteNoneCompatible} from 'should-send-same-site-none';
import {isLocalDeployment} from 'constants/app';

// TODO: should be moved to io/cookies

export const setCrossSiteCookie = (name, value, opts = {}) => {
	cookies.set(name, value, {
		domain: window.location.hostname,
		// Safari has bug where sameSite: none doesn't work (https://www.chromium.org/updates/same-site/incompatible-clients)
		// local deployment can't use "sameSite" since "secure" is needed in combination with it
		...(!isLocalDeployment && isSameSiteNoneCompatible(navigator.userAgent)
			? {sameSite: 'none'}
			: {}),
		// localhost does not use https, thus secure flag won't work
		secure: !isLocalDeployment,
		...opts,
	});
};
