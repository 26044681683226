import React, {useState, useRef} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import ReactAutosuggest from 'react-autosuggest';
import Input from './Input';
import {listUnstyled} from 'styles/fragments';
import {borderColorBase, rowHoverColor, textColorBase} from 'styles/constants';
import {splitGlobalAttrs} from 'utils/html';
import {dissocNulls} from 'utils/objects';

export const autosuggestSuggestionsZIndex = 8;

// note that these props need to be manually accounted for if using a custom renderInputComponent to display an Input
const renderAutosuggestInput = inputProps => (
	<Input type="text" block stretch {...inputProps} />
);

const AutosuggestItem = styled.div`
	${/*prettier-ignore*/ ({noPadding}) => !noPadding ? `
		padding: 10px 15px;
	` : ''}
	background: ${({isHighlighted}) => (isHighlighted ? rowHoverColor : 'white')};
	${({usePointerCursor}) => (usePointerCursor ? 'cursor: pointer;' : 'cursor: default;')};

	&:hover {
		background: ${rowHoverColor};
	}

	${
		/*prettier-ignore*/ ({noWrap}) => noWrap ? `
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	` : ''
	};
`;

// define all the style stuff that can't be customized with Autosuggest render* functions or should be set regardless of the render* function (e.g. the suggestion list should always be 100 % width)
// use styled-components instead of react-themeable offered by the component since otherwise we'd need to manually duplicate some styles for the component (react-themeable doesn't support dynamic CSS strings)
// note that these can still be overriden if necessary either by using another style wrapper
const StyleContainer = styled.div`
	.react-autosuggest__container {
		position: relative;

		.react-autosuggest__suggestions-container {
			width: 100%;
			${
				/*prettier-ignore*/ ({noSuggestionsFloat}) => !noSuggestionsFloat ? `
				position: absolute;
				z-index: ${autosuggestSuggestionsZIndex};
				` : ''
			}

			&:not(.react-autosuggest__suggestions-container--open) {
				display: none;
			}
		}

		.react-autosuggest__suggestions-list {
			${listUnstyled};

			overflow: auto;
			// put on top of input box shadow using this
			position: relative;
			margin-top: 2px;
			border: 1px solid ${borderColorBase};
			max-height: ${({suggestionsMaxHeight}) => suggestionsMaxHeight};
			border-radius: 4px;
			color: ${textColorBase};
		}

		.react-autosuggest__suggestion {
			&:not(:first-child) {
				> ${AutosuggestItem} {
					border-top: 1px solid ${borderColorBase};
				}
			}

			&:first-child {
				> ${AutosuggestItem} {
					border-top-left-radius: 4px;
					border-top-right-radius: 4px;
				}
			}
			&:last-child {
				> ${AutosuggestItem} {
					border-bottom-left-radius: 4px;
					border-bottom-right-radius: 4px;
				}
			}
		}
	}
`;

// minor issue with react-autosuggest is that it doesn't cache results and hides the suggestions list by calling "onSuggestionsClearRequested", so each time you blur and re-focus the component it re-fetches data.

let Autosuggest = (props, ref) => {
	const {
		noSuggestionsFloat,
		suggestionsMaxHeight = '272px',
		usePointerCursor,
		// this lets suggestionClass handle its own padding
		suggestionNoPadding,
		suggestionNoWrap,
		suggestionClass: Sugg,
		renderInputComponent = renderAutosuggestInput,
		inputProps,
		loadSuggestions,
		suggestions: parentSuggestions,
		onSuggestionsFetchRequested: parentOnSuggestionsFetchRequested,
		onSuggestionsClearRequested: parentOnSuggestionsClearRequested,
		loading: parentLoading,
		passLoadingToInput,
		...rest
	} = props;

	const [wrapperProps, compProps] = splitGlobalAttrs(rest);

	const [text, setText] = useState('');

	// this provides logic to support "loadSuggestions", similarly to react-selects's "loadOptions". if "loadSuggestions" is provided, "suggestions", "onSuggestionsFetchRequested" and "onSuggestionsClearRequested" shouldn't be.
	// "loadSuggestions" is superior to "suggestions" because it automatically takes only the latest results into account if there are multiple fetches going on in parallel. this way if an earlier request lasts longer, it won't overwrite the latest results. however, if the parent also needs the list of suggestions for whatever, this logic needs to be handled by the parent and the "suggestions" prop can be used instead.
	const [suggestions, setSuggestions] = useState([]);
	const [loading, setLoading] = useState(false);
	const fetchIdRef = useRef(0);
	const onSuggestionsFetchRequested = ({value, reason}) => {
		setLoading(true);
		fetchIdRef.current += 1;
		const fetchId = fetchIdRef.current;
		const cb = suggs => {
			// fetchIdRef will have increased if another fetch was triggered before this callback was called, in which case we ignore this in favor of the latter fetch
			if (fetchIdRef.current === fetchId) {
				setSuggestions(suggs);
				setLoading(false);
			}
		};
		loadSuggestions(value, cb);
	};
	const onSuggestionsClearRequested = () => {
		setSuggestions([]);
	};

	const loadingState = parentLoading != null ? parentLoading : loading;

	// react-autosuggest doesn't let us have an uncontrolled component, so do it here
	const inputProps2 =
		inputProps && inputProps.value !== undefined
			? inputProps
			: {
					...(inputProps || {}),
					value: text,
					onChange: (e, {newValue, method}) => {
						setText(newValue);
						if (inputProps && inputProps.onChange) {
							inputProps.onChange(e, {newValue, method});
						}
					},
			  };
	// react-autosuggest crashes if it receives a null "value" input, so ensure that doesn't happen
	if (inputProps2.value == null) {
		inputProps2.value = '';
	}

	// prevent selections while loading. not doing so easily leads to stale search results getting selected.
	const inputProps3 = {
		...inputProps2,
		onChange: (e, {newValue, method}) => {
			if (loadingState && method !== 'type') {
				return;
			}
			inputProps2.onChange(e, {newValue, method});
		},
	};

	const inputProps4 =
		passLoadingToInput || parentLoading != null
			? {...inputProps3, loading: loadingState}
			: inputProps3;

	let _renderSuggestion = null;
	if (Sugg) {
		// to satisfy component displayname requirements
		const renderSuggestion = (sugg, suggProps) => (
			<AutosuggestItem
				usePointerCursor={usePointerCursor}
				noPadding={suggestionNoPadding}
				noWrap={suggestionNoWrap}
				{...suggProps}
			>
				<Sugg suggestion={sugg} {...suggProps} />
			</AutosuggestItem>
		);
		_renderSuggestion = renderSuggestion;
	}

	return (
		<StyleContainer
			ref={ref}
			{...wrapperProps}
			suggestionsMaxHeight={suggestionsMaxHeight}
			noSuggestionsFloat={noSuggestionsFloat}
			usePointerCursor={usePointerCursor}
		>
			<ReactAutosuggest
				renderInputComponent={renderInputComponent}
				{...dissocNulls({renderSuggestion: _renderSuggestion})}
				{...compProps}
				inputProps={inputProps4}
				onSuggestionsFetchRequested={
					parentOnSuggestionsFetchRequested || onSuggestionsFetchRequested
				}
				onSuggestionsClearRequested={
					parentOnSuggestionsClearRequested || onSuggestionsClearRequested
				}
				suggestions={parentSuggestions || suggestions}
			/>
		</StyleContainer>
	);
};
Autosuggest = React.forwardRef(Autosuggest);

// note: could implement some stuff like the "block" prop that's available on inputs, currently this is always a block though
Autosuggest.propTypes = {
	// parent controls this
	noSuggestionsFloat: propTypes.bool,
	usePointerCursor: propTypes.bool,
	suggestionsMaxHeight: propTypes.string,
	// this lets suggestionClass handle its own padding
	suggestionNoPadding: propTypes.bool,
	// this is offered for convenience
	suggestionNoWrap: propTypes.bool,
	// should be provided if renderSuggestion isn't
	suggestionClass: propTypes.elementType,
	renderInputComponent: propTypes.elementType,
	inputProps: propTypes.object,
	// note that this is also passed to the wrapped input component if non-null
	loading: propTypes.bool,
	// whether to pass our internal loading state to the wrapped input, needed if "loading" isn't provided
	passLoadingToInput: propTypes.bool,
	// "loadSuggestions" should typically be used over "suggestions", see the comment in the rendering routine
	suggestions: propTypes.array,
	loadSuggestions: propTypes.func,
	onSuggestionsFetchRequested: propTypes.func,
	onSuggestionsClearRequested: propTypes.func,
};

export default Autosuggest;
