export const appName = 'stats';

export const zeroStats = {
	appointmentCalls: 0,
	appointmentVisits: 0,
	cancelledDealPrices: 0,
	cancelledDeals: 0,
	cancelledOfferPrices: 0,
	cancelledOffers: 0,
	dealPrices: 0,
	deals: 0,
	offerPrices: 0,
	offers: 0,
	ownDeals: 0,
	ownOffers: 0,
	reachedCalls: 0,
	reachedVisits: 0,
	successfulReservations: 0,
	successfulReservationPrices: 0,
	totalCalls: 0,
	totalVisits: 0,
};

export const statsTimeSpans = ['day', 'week', 'month'];
