import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';

export default {
	...lifecycle,
	processing: false,
	feedbackQuery: {_page: 1},
	feedback: [],
	feedbackLoading: true,
	feedbackPagination: emptyPagination,
};
