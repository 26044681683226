import {logWarning} from 'io/errors';
import services from 'services';
import {callSession, enioCallerCredentials} from 'modules/common/selectors';
import {enioCallDescriptions} from './enioCallActions';
import * as nActions from 'modules/notifications/actions';
import {shortDur, medDur} from 'constants/notifications';
import {browserPermissions} from 'utils/browserPermissions';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));

let intl = null;
services.waitFor('intl').then(x => (intl = x));
// TODO: make sales app use this too

export const postCallLog = ({
	clientId,
	buildingId,
	appName,
	//callMethod = null,
	startTime,
	...other
}) => {
	const apiData = {
		clientId,
		buildingId,
		appName,
		//callMethod,
		startTime,
		...other,
	};
	httpJson('post', '/callLogs', {}, {body: apiData}).catch(e => {
		logWarning(e);
	});
};

const sipmlAudioElementId = 'audio-remote';

const getSingleton = (key, creator) =>
	new Promise((resolve, reject) => {
		if (!window[key]) {
			window[key] = {value: null, ready: false, failed: false, callbacks: null};
		}
		const store = window[key];
		if (store.ready) {
			resolve(store.value);
		} else if (store.failed) {
			reject(new Error(`Failed to create "${key}" singleton`));
		} else if (store.callbacks) {
			store.callbacks.push({resolve, reject});
		} else {
			store.callbacks = [{resolve, reject}];

			const resolveAll = value => {
				store.value = value;
				store.ready = true;
				for (const cb of store.callbacks) {
					cb.resolve(value);
				}
				store.callbacks = null;
			};
			const rejectAll = error => {
				store.failed = true;
				for (const cb of store.callbacks) {
					cb.reject(error);
				}
				store.callbacks = null;
			};

			creator(resolveAll, rejectAll);
		}
	});

// Wait for sipML library to be initialized. The library can only be initialized once, so avoid doing it multiple times.
const initSipmlClient = () =>
	getSingleton('sipmlInit', (resolve, reject) => {
		const readyCallback = () => {
			resolve();
		};
		const errorCallback = e => {
			console.error('sipML: Failed to initialize the engine: ' + e.message);
			reject(e);
		};
		window.SIPml.init(readyCallback, errorCallback);
	});

const getSipStackSingleton = (key, conf) =>
	getSingleton(key, resolve => {
		const stackEventsListener = function (e) {
			//console.info('Sipml stack event = ' + e.type);
			if (e.type === 'started') {
				login();
			}
		};
		const sipStack = new window.SIPml.Stack({
			enable_rtcweb_breaker: true, // optional
			events_listener: {events: '*', listener: stackEventsListener},
			ice_servers: '[]',
			...conf,
		});

		sipStack.start();

		let registerSession;
		const registerEventsListener = function (e) {
			//console.info('Sipml session event = ' + e.type);
			if (e.type === 'connected' && e.session === registerSession) {
				resolve(sipStack);
			}
		};
		const login = function () {
			registerSession = sipStack.newSession('register', {
				events_listener: {events: '*', listener: registerEventsListener},
			});
			registerSession.register();
		};
	});

export const enioCallClient = async ({client, building, user, appName}) => {
	const store = services.get('store');
	const checkIfCallIsActive = callSession(store.getState());
	// Prevent duplicate calling
	if (checkIfCallIsActive.active) {
		const callInProgressMsg = intl.formatMessage({
			id: 'Call is in progress. End earlier call first',
		});
		store.dispatch(
			nActions.warning({
				id: 'call-in-progress-end-earlier-call-first',
				message: callInProgressMsg,
				duration: medDur,
			}),
		);
		return;
	}
	const microphonePerm = await browserPermissions('microphone');
	if (!microphonePerm.status) {
		const permMsg =
			intl.formatMessage({id: 'microphone'}) +
			' ' +
			intl.formatMessage({id: microphonePerm.msg});
		store.dispatch(
			nActions.warning({
				id: 'invalid-enio-credentials',
				message: permMsg,
				duration: medDur,
			}),
		);
		return;
	}
	await initSipmlClient();
	let ringingLimit = 35; //seconds
	let isCallActive = false;
	let isLogged = false;
	const credentials = enioCallerCredentials(store.getState());
	if (
		!credentials?.enioCallerUser ||
		!credentials?.enioCallerPassword ||
		!credentials?.domain
	) {
		const message = intl.formatMessage({id: 'Enio invalid credentials'});
		store.dispatch(
			nActions.warning({id: 'invalid-enio-credentials', message, duration: medDur}),
		);
		return;
	}

	const sipmlConf = {
		realm: credentials?.domain, // mandatory: domain name
		impi: `device.${credentials?.enioCallerUser}`, // mandatory: authorization name (IMS Private Identity)
		impu: `sip:device.${credentials.enioCallerUser}@${credentials?.domain}`, // mandatory: valid SIP Uri (IMS Public Identity)
		password: credentials?.enioCallerPassword, // optional
		display_name: user?.firstName + ' ' + user?.lastName, // optional
		websocket_proxy_url: 'wss://onecloud.setera.com', // optional
		//outbound_proxy_url: 'udp://example.org:5060', // optional
	};
	const sipStack = await getSipStackSingleton('sipStack', sipmlConf);
	const clientData = {
		...client,
		building,
	};

	const callEventsListener = function (e) {
		if (typeof enioCallDescriptions[e.description] === 'function') {
			enioCallDescriptions[e.description](e, store, clientData);
		} else {
			const unexpectedErr = intl.formatMessage({id: 'Unexpected error'});
			store.dispatch(
				nActions.warning({
					id: 'unknown-enio-caller-error',
					message: unexpectedErr,
					shortDur,
				}),
			);
			enioCallDescriptions['unknown'](e, store, clientData);
		}
		if (
			e.description === 'Call terminated' ||
			e.description === 'Busy Here' ||
			e.description === 'Request Terminated'
		) {
			const callDetails = callSession(store.getState());
			const callDuration =
				callDetails?.endTime && callDetails?.answerTime
					? callDetails.endTime - callDetails.answerTime
					: null;

			const answerTime = callDetails.answerTime
				? new Date(callDetails.answerTime * 1000)
				: null;

			const endTime = callDetails.endTime ? new Date(callDetails.endTime * 1000) : null;
			const callInfo = {
				clientId: client.id,
				buildingId: building.id,
				appName,
				startTime: new Date(callDetails.startTime * 1000),
				answerTime: answerTime,
				endTime: endTime,
				callDuration,
				callApp: 'enioCaller',
			};
			// This is here because Busy Here causes status "Call terminated" afterwards
			// This prevents duplicate log posting this way
			if (!isLogged) {
				postCallLog(callInfo);
				isLogged = true;
			}
		}
		switch (e.type) {
			case 'connected':
				isCallActive = true;
				break;
			case 'terminated':
				isCallActive = false;
				break;
			default:
				break;
		}
	};
	const enioCallSession = sipStack.newSession('call-audio', {
		audio_remote: document.getElementById(sipmlAudioElementId),
		events_listener: {events: '*', listener: callEventsListener},
	});
	//console.log(client);
	enioCallSession.call(client.phone);

	let ringingInterval;

	const startRingingInterval = () => {
		ringingInterval = setInterval(() => {
			const getStartTime = callSession(store.getState());
			if (getStartTime?.startTime) {
				const stopRinging = getStartTime?.startTime + ringingLimit;
				const currentTime = Math.floor(Date.now() / 1000);
				if (currentTime >= stopRinging && getStartTime.status === 'Ringing') {
					enioCallSession.hangup();
					clearInterval(ringingInterval);
				}
				if (getStartTime.status !== 'Ringing' && getStartTime.status !== 'Trying') {
					clearInterval(ringingInterval);
				}
			}
		}, 1000);
	};
	startRingingInterval();
	window.addEventListener('beforeunload', e => {
		if (isCallActive) {
			e.preventDefault();
			e.returnValue =
				'Oletko varma, että haluat poistua? Puhelu sulkeutuu, jos suljet tämän ikkunan.';
			return e.returnValue;
		}
	});
	window.addEventListener('onunload', e => {
		enioCallSession.hangup();
	});
};
