import React from 'react';
import Field from './Field';
import FormBlock from './FormBlock';
import Label from './Label';
import {FormattedMessage, injectIntl} from '@meiko/react-intl';
import Select from './Select';
import {pipe, sortBy, toPairs} from 'ramda';
import * as buildingDicts from 'dicts/buildings';
import FieldError from './FieldError';
import {compose} from 'redux';

const encounterStateOptions = pipe(
	toPairs,
	sortBy(([value, title]) => title),
)(buildingDicts.encounterStateNoD2d);

const BuildingEncounterStateSelectFormlet = ({intl, name = 'encounterState'}) => {
	return (
		<Field
			name={name}
			component={({input, inputId, meta}) => (
				<FormBlock>
					<Label htmlFor={inputId}>
						<FormattedMessage id="Status" />
					</Label>
					<Select {...input} id={inputId} block stretch>
						<option value="">{intl.formatMessage({id: 'No selection'})}</option>
						{encounterStateOptions.map(([val, title]) => (
							<option key={val} value={val}>
								{intl.formatMessage({id: title})}
							</option>
						))}
					</Select>
					<FieldError {...meta} />
				</FormBlock>
			)}
		/>
	);
};

export default compose(injectIntl)(BuildingEncounterStateSelectFormlet);
