import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	processing: false,
	client: {},
	clientLoading: true,
	calendarResources: [],
	calendarResourcesLoading: true,
	encounters: [],
	encountersLoading: true,
	previewableEncounter: null,
	clientEditorOpen: false,
};
