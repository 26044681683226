import {isEmpty} from 'ramda';

export const formatTeamStats = stats => {
	const dealPrices = stats.month
		.map(salesman => salesman.data.dealPrices)
		.reduce((a, b) => a + b, 0);

	const totalOffers = stats.month
		.map(
			salesman =>
				salesman.data.deals + salesman.data.offers + salesman.data.cancelledDeals,
		)
		.reduce((a, b) => a + b, 0);

	const totalAppointments = stats.month
		.map(salesman => salesman.data.appointmentCalls + salesman.data.appointmentVisits)
		.reduce((a, b) => a + b, 0);

	const offerPercentage = stats.month
		.map(
			salesman =>
				(salesman.data.deals +
					salesman.data.offers -
					salesman.data.cancelledDeals -
					salesman.data.cancelledOffers) /
				totalOffers,
		)
		.reduce((a, b) => a + b, 0);

	const formattedStats = {
		dealPrices,
		totalOffers,
		offerPercentage,
		totalAppointments,
	};

	return formattedStats;
};

export const formatUserStats = stats => {
	if (stats.month.length > 0) {
		const formattedStats = stats.month[0].data;
		const totalOffers =
			formattedStats.deals + formattedStats.offers + formattedStats.cancelledDeals;
		const offerPercentage = totalOffers
			? (formattedStats.deals +
					formattedStats.offers -
					formattedStats.cancelledDeals -
					formattedStats.cancelledOffers) /
			  totalOffers
			: 0;
		const totalAppointments =
			formattedStats.appointmentCalls + formattedStats.appointmentVisits;
		return {...formattedStats, totalOffers, offerPercentage, totalAppointments};
	} else {
		return {dealPrices: 0, totalOffers: 0, offerPercentage: 0, totalAppointments: 0};
	}
};

export const formatOrganiationStats = stats => {
	const formattedStats = stats.organizationTotalMonth;
	const totalOffers =
		formattedStats.deals + formattedStats.offers + formattedStats.cancelledDeals;

	const offerPercentage = totalOffers
		? (formattedStats.deals +
				formattedStats.offers -
				formattedStats.cancelledDeals -
				formattedStats.cancelledOffers) /
		  totalOffers
		: 0;

	const totalAppointments =
		formattedStats.appointmentCalls + formattedStats.appointmentVisits;

	return {
		...formattedStats,
		totalOffers,
		offerPercentage,
		totalAppointments,
		organizationStats: true,
	};
};

export const formatStats = (stats, isAdminUser = false) => {
	if (isAdminUser && !isEmpty(stats.organizationMonth)) {
		const {organizationMonth} = stats;
		return {
			dealPrices: organizationMonth.dealPrices,
			totalOffers: organizationMonth.offers,
		};
	} else if (!isEmpty(stats.month)) {
		const {month} = stats;
		return {
			dealPrices: month.dealPrices,
			totalOffers: month.offers,
		};
	} else {
		return {dealPrices: 0, totalOffers: 0};
	}
};
