import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	initialized: false,
	permissions: [],
	organizations: [],
	roles: [],
	teams: [],
	selectedOrganizations: [],
	appPermissions: [],
	otherPermissions: [],
};
