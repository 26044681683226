import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {formatTeamTargetFormInput} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const userTeams = pipe(root, prop('userTeams'));

export const userTeamsLoading = pipe(root, prop('userTeamsLoading'));

export const team = createSelector([userTeams], userTeams =>
	userTeams.length ? userTeams[0] : null,
);

export const processing = pipe(root, prop('processing'));

export const teamTargets = pipe(root, prop('teamTargets'));

export const teamTargetsLoading = pipe(root, prop('teamTargetsLoading'));

export const teamTargetsQuery = pipe(root, prop('teamTargetsQuery'));

export const urlQuery = createSelector(
	[teamTargetsQuery],
	teamTargetsQuery => teamTargetsQuery,
);

export const selectedDate = createSelector(
	[teamTargetsQuery],
	teamTargetsQuery => teamTargetsQuery.date,
);

export const teamTargetFormInitValues = createSelector(
	[team, teamTargets, selectedDate],
	(team, teamTargets, selectedDate) =>
		formatTeamTargetFormInput({team, teamTargets, date: selectedDate}),
);
