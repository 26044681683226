import {scopedHandler} from 'utils/redux';
import {getFormValues} from 'redux-form';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		case actions.changePage.type: {
			const newState = {
				...state,
				requestsLoading: true,
				requestsQuery: {...state.requestsQuery, _page: payload},
			};

			return [newState, effects.updateRequests()];
		}

		case actions.openRequestCreator.type: {
			return [{...state, requestCreatorOpen: true}, null];
		}

		case actions.closeRequestCreator.type: {
			return [{...state, requestCreatorOpen: false}, null];
		}

		case actions.createRequest.type: {
			const {comment, ...request} = getFormValues('removalRequestForm')(fullState);

			return [{...state, processing: true}, effects.createRequest({request, comment})];
		}

		case actions.searchClients.type: {
			const {clientFirstName, clientLastName, clientPhone} =
				getFormValues('removalRequestForm')(fullState);

			return [
				{...state, clientsLoading: true},
				effects.searchClients({clientFirstName, clientLastName, clientPhone}),
			];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions._updateRequestsQuery.type: {
			const newState = {
				...state,
				requestsLoading: true,
				requestsQuery: {...state.requestsQuery, ...payload},
			};

			return [newState, null];
		}

		case actions._setRequests.type: {
			const {data, pagination} = payload;
			const newState = {
				...state,
				requests: data,
				requestsPagination: pagination,
				requestsLoading: false,
			};

			return [newState, null];
		}

		case actions._setClients.type: {
			return [{...state, clients: payload, clientsLoading: false}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
