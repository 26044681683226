import {mapStateKeys} from './_internal';
import {normalizeCallReminders} from 'utils/calendarResources';

export default ({
	stateRemaps = {},
	actions,
	effects,
	buildingSelector,
	weekSampleSelector,
}) => {
	const K = mapStateKeys(stateRemaps);

	return (state, fullState, {type, payload}) => {
		switch (type) {
			case actions.createCallReminder.type: {
				const callReminder = {...payload, buildingId: buildingSelector(fullState).id};

				return [
					{...state, [K.callReminderLoading]: true},
					effects.saveCallReminder({callReminder}),
				];
			}

			case actions.createCalendarCallReminder.type: {
				const callReminder = {...payload, buildingId: buildingSelector(fullState).id};

				return [
					{...state, [K.callReminderLoading]: true},
					effects.saveCalendarCallReminder({callReminder}),
				];
			}

			case actions.updateCallReminder.type: {
				return [
					{...state, [K.callReminderLoading]: true},
					effects.saveCallReminder({
						callReminder: payload,
						callReminderId: state[K.callReminder].id,
					}),
				];
			}

			case actions.removeCallReminder.type: {
				return [state, effects.removeCallReminder(payload)];
			}

			case actions.toggleCallReminderEditor.type: {
				return [
					{...state, [K.callReminderEditorOpen]: !state[K.callReminderEditorOpen]},
					null,
				];
			}

			case actions.setReminderSeen.type: {
				return [state, effects.setReminderSeen(payload)];
			}

			case actions._setCallReminder.type: {
				return [
					{
						...state,
						[K.callReminder]: payload,
						[K.callReminderLoading]: false,
						[K.callReminderEditorOpen]: false,
					},
					null,
				];
			}

			case actions._setCalendarCallReminder.type: {
				const weekSample = weekSampleSelector(fullState);

				const callReminders = [...state.callReminders, payload];

				const [hourItems] = normalizeCallReminders(weekSample, callReminders);
				return [
					{
						...state,
						slotSelection: {},
						itemsViewerOpen: false,
						itemsCreatorOpen: false,
						callReminderBuilding: {},
						[K.callReminders]: callReminders,
						[K.callReminderLoading]: false,
						[K.callReminderEditorOpen]: false,
						[K.hourReminders]: hourItems,
					},
					null,
				];
			}

			case actions._setCalendarCallReminderSeen.type: {
				const weekSample = weekSampleSelector(fullState);

				const callReminders = [
					...state.callReminders.filter(c => c.id !== payload.id),
					payload,
				];

				const [hourItems] = normalizeCallReminders(weekSample, callReminders);
				return [
					{
						...state,
						[K.callReminders]: callReminders,
						[K.callReminderLoading]: false,
						[K.callReminderEditorOpen]: false,
						[K.hourReminders]: hourItems,
					},
					null,
				];
			}

			case actions._setCallReminderLoading.type: {
				return [{...state, [K.callReminderLoading]: payload}, null];
			}

			case actions._setCallReminders.type: {
				const weekSample = weekSampleSelector(fullState);

				const [hourItems] = normalizeCallReminders(weekSample, payload);

				return [
					{
						...state,
						[K.callReminders]: payload,
						[K.callRemindersLoading]: false,
						[K.hourReminders]: hourItems,
					},
					null,
				];
			}

			case actions._setCallRemindersLoading.type: {
				return [{...state, [K.callRemindersLoading]: payload}, null];
			}

			default:
				return [state, null];
		}
	};
};
