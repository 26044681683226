import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import {mapStateKeys} from './_internal';
import {formatCallReminderFormInput} from './utils';
import {emptyCallReminder} from './constants';

export default ({namespace, stateRemaps = {}}) => {
	const K = mapStateKeys(stateRemaps);

	const root = path(namespace);

	const callReminder = pipe(root, prop(K.callReminder));

	const callReminderFormInitValues = createSelector([callReminder], callReminder =>
		formatCallReminderFormInput({
			callReminder: callReminder || emptyCallReminder,
		}),
	);

	const callReminderLoading = pipe(root, prop(K.callReminderLoading));

	const callReminderEditorOpen = pipe(root, prop(K.callReminderEditorOpen));

	const hourReminders = pipe(root, prop(K.hourReminders));

	return {
		callReminder,
		callReminderFormInitValues,
		callReminderLoading,
		callReminderEditorOpen,
		hourReminders,
	};
};
