import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import Loader from './Loader';

const Wrapper = styled.div`
	display: flex;
	justify-content: ${({noCenter}) => (noCenter ? 'flex-start' : 'center')};
	align-items: center;
	padding-top: ${({noTopPadding, noPadding}) => (noTopPadding || noPadding ? 0 : '20px')};
	padding-bottom: ${({noBottomPadding, noPadding}) =>
		noBottomPadding || noPadding ? 0 : '20px'};
`;

const Message = styled.span`
	margin-left: 5px;
`;

const ContentLoader = ({message, noCenter, size, ...rest}) => (
	<Wrapper {...rest} noCenter={noCenter}>
		{message ? (
			<>
				<Loader size={size} />
				<Message>{message}</Message>
			</>
		) : (
			<Loader size={size} />
		)}
	</Wrapper>
);

ContentLoader.propTypes = {
	message: propTypes.node,
	noCenter: propTypes.bool,
	size: propTypes.number,
};

export default ContentLoader;
