import * as React from 'react';

const Download = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 12 12"
			width="1em"
			height="1em"
			{...props}
		>
			<g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
				<path
					d="M9 4.071h.857a.429.429 0 01.429.429v6.429a.429.429 0 01-.429.428H2.143a.429.429 0 01-.429-.428V4.5a.429.429 0 01.429-.429H3M6 .643V7.5"
					strokeWidth={0.85714}
				/>
				<path d="M4.286 5.786L6 7.5l1.714-1.714" strokeWidth={0.85714} />
			</g>
		</svg>
	);
};

export default Download;
