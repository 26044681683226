export const state = {showOverlay: false};

export const props = {
	mouseOver: false,
	overlayTriggeredByHover: false,
	overlayTriggeredByFocus: false,
};

export const methods = ({thisRef, hoverDelay}) => {
	const handleEnter = function () {
		if (thisRef.overlayTriggeredByHover) {
			thisRef.overlayTriggeredByHover = false;
		} else if (thisRef.overlayTriggeredByFocus) {
			thisRef.overlayTriggeredByFocus = false;
		} else if (thisRef.state.showOverlay) {
			thisRef.setState({showOverlay: false});
		} else {
			thisRef.setState({showOverlay: true});
		}
	};

	return {
		onMouseOver: function () {
			if (thisRef.state.showOverlay) {
				return;
			}
			thisRef.mouseOver = true;
			setTimeout(() => {
				if (thisRef.mouseOver) {
					thisRef.overlayTriggeredByHover = true;
					thisRef.setState({showOverlay: true});
				}
			}, hoverDelay);
		},

		onMouseOut: function () {
			thisRef.mouseOver = false;
			thisRef.setState(state => {
				const newState = {
					...state,
					showOverlay: thisRef.overlayTriggeredByHover ? false : state.showOverlay,
				};
				thisRef.overlayTriggeredByHover = false;
				return newState;
			});
		},

		onClick: e => {
			// do this in case the overlay root is inside an element that also has an event listener. if so, the parent shouldn't need to receive the event, this makes things easier.
			e.stopPropagation();
			handleEnter();
		},

		onKeyPress: function (e) {
			if (e.key === 'Enter') {
				// same as above
				e.stopPropagation();
				handleEnter.call(thisRef);
			}
		},

		onFocus: function () {
			if (thisRef.overlayTriggeredByHover) {
				thisRef.overlayTriggeredByHover = false;
				thisRef.overlayTriggeredByFocus = true;
			} else if (!thisRef.state.showOverlay) {
				thisRef.overlayTriggeredByFocus = true;
				thisRef.setState({showOverlay: true});
			}
		},

		onBlur: function () {
			if (thisRef.overlayTriggeredByFocus) {
				thisRef.overlayTriggeredByFocus = false;
				thisRef.setState({showOverlay: false});
			}
		},
	};
};
