import services from 'services';
import importMaps from 'services/importMaps';
import {describeThrow, describeError} from 'utils/errors';
import {apiUrl} from 'constants/app';
import {
	maxZoom,
	buildingsMinZoom,
	styles as mapStyles,
	buildingsYearMinZoom,
} from 'constants/maps';
import {
	safeGetFeaturesAtPixel,
	safeHasFeatureAtPixel,
	addTooltipToLayers,
	renderBuildingTooltip,
	createMarkerLayer,
	_getMapTileSource,
} from 'io/maps';
import {deleteChildren} from 'utils/dom';
import {encodeQuery} from 'utils/url';
import {getJsonProperties, concreteBuildingStyle} from 'utils/maps';
import * as normalize from 'utils/normalize';
import {getResponseData} from 'utils/app';
import {mapTooltip} from 'styles/fragments';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const decorateMapMethod = method => args =>
	importMaps()
		.then(method(args))
		.catch(describeThrow(intl.formatMessage({id: 'Error loading map'})));

// resources

export const getBuilding = buildingId =>
	httpJson('get', `/buildings/${buildingId}`, {})
		.catch(e => {
			const noPermission = !!e.response && e.response.status === 403;
			const errMsgKey = noPermission
				? 'You do not have access to the building'
				: 'The building could not be loaded. Try refreshing the page.';
			throw describeError(intl.formatMessage({id: errMsgKey}), e);
		})
		.then(getResponseData(normalize.building));

// maps

const _getBuildingsStyle =
	({zoom, filters, buildingId, projectBuildingId, manufacturingLimit}) =>
	imports => {
		const {openLayers: ol} = imports;
		const sty = mapStyles(ol);
		const keys = [
			'id',
			'encounterState',
			'manufacturingYear',
			'apartmentCount',
			'type',
			'projectBuildingId',
			'encounterDate',
			'banned',
		];

		return feature => {
			const {
				id,
				encounterState,
				manufacturingYear,
				apartmentCount,
				type,
				projectBuildingId: _projectBuildingId,
				encounterDate,
				banned,
			} = getJsonProperties(feature, keys);

			if (filters) {
				if (
					filters.encounterState &&
					filters.encounterState !== 'any' &&
					encounterState !== filters.encounterState
				)
					return null;
				if (
					filters.minYear &&
					(manufacturingYear < filters.minYear || !manufacturingYear)
				)
					return null;
				if (
					filters.maxYear &&
					(manufacturingYear > filters.maxYear || !manufacturingYear)
				)
					return null;
				if (
					filters.buildingType &&
					filters.buildingType !== 'any' &&
					type !== filters.buildingType
				)
					return null;
				if (
					filters.minApartments &&
					(apartmentCount < filters.minApartments || !apartmentCount)
				)
					return null;
				if (
					filters.maxApartments &&
					(apartmentCount > filters.maxApartments || !apartmentCount)
				)
					return null;
			}

			const isCondo = !!_projectBuildingId;
			const selected =
				id === buildingId || (isCondo && _projectBuildingId === projectBuildingId);
			const building = {encounterState, encounterDate, banned, manufacturingYear};

			return new ol.style.Style({
				...concreteBuildingStyle(ol, {
					geomType: feature.getGeometry().getType(),
					zoom,
					style: sty.condoStyleProps({isCondo, building, manufacturingLimit, selected}),
				}),
				zIndex: selected ? 1 : 0,
				text:
					zoom >= buildingsYearMinZoom
						? new ol.style.Text({
								text: manufacturingYear ? `${manufacturingYear}` : '',
								overflow: true,
						  })
						: null,
			});
		};
	};
export const getBuildingsStyle = decorateMapMethod(_getBuildingsStyle);

const _getBuildingsSource =
	({apiToken, organizationId}) =>
	imports => {
		const {openLayers: ol} = imports;

		return new ol.source.VectorTile({
			format: new ol.format.MVT({dataProjection: 'EPSG:4326'}),
			// prettier-ignore
			url: `${apiUrl}/maps/buildings/mvt/{z}/{x}/{y}${encodeQuery({organizationId, token: apiToken, project: true})}`,
			maxZoom: maxZoom,
		});
	};
export const getBuildingsSource = decorateMapMethod(_getBuildingsSource);

const createBuildingsLayer =
	({
		apiToken,
		organizationId,
		initialZoom,
		initialFilters,
		buildingId,
		projectBuildingId,
		manufacturingLimit,
	}) =>
	imports => {
		const {openLayers: ol} = imports;

		const layer = new ol.layer.VectorTile({
			source: _getBuildingsSource({apiToken, organizationId})(imports),
			style: _getBuildingsStyle({
				zoom: initialZoom,
				filters: initialFilters,
				buildingId,
				projectBuildingId,
				manufacturingLimit,
			})(imports),
			visible: initialZoom >= buildingsMinZoom,
		});

		return layer;
	};

const _initFreerideMap =
	({
		apiToken,
		organizationId,
		initialZoom,
		initialCenter,
		initialFilters,
		buildingId,
		projectBuildingId,
		onBuildingClick,
		onMapLocationChanged,
		manufacturingLimit,
		mapSourceProps,
	}) =>
	imports => {
		// keep some local state
		let currHoverBuildingId = null;

		const {openLayers: ol} = imports;

		const mapEl = document.querySelector('#freeride-map');
		if (!mapEl) {
			return Promise.reject(new Error('Map element not found'));
		}

		const view = new ol.View({
			projection: 'EPSG:3857',
			center: initialCenter,
			zoom: initialZoom,
			maxZoom,
			enableRotation: false,
			constrainResolution: true,
		});

		const buildingsLayer = createBuildingsLayer({
			apiToken,
			organizationId,
			initialZoom,
			initialFilters,
			buildingId,
			projectBuildingId,
			manufacturingLimit,
		})(imports);

		const mapSource = _getMapTileSource(mapSourceProps)(imports);
		const mapLayer = new ol.layer.Tile({source: mapSource});

		const markerLayer = createMarkerLayer(imports);

		// map

		const map = new ol.Map({
			target: 'freeride-map',
			view,
			layers: [mapLayer, buildingsLayer, markerLayer],
		});

		map.on('moveend', e => {
			const zoom = map.getView().getZoom();
			const center = map.getView().getCenter();

			onMapLocationChanged({z: zoom, x: center[0], y: center[1]});

			// toggle buildings layer
			if (zoom >= buildingsMinZoom) {
				buildingsLayer.setVisible(true);
			} else {
				buildingsLayer.setVisible(false);
			}
		});

		map.on('click', e => {
			const features = safeGetFeaturesAtPixel(map, e.pixel, {
				layerFilter: l => l === buildingsLayer,
			});
			if (!features) return;
			const building = getJsonProperties(features[0], [
				'id',
				'encounterState',
				'projectBuildingId',
				'address',
			]);

			onBuildingClick(building, e.coordinate);
		});

		map.on('pointermove', e => {
			const pixel = map.getEventPixel(e.originalEvent);
			const hit = safeHasFeatureAtPixel(map, pixel, {
				layerFilter: l => l === buildingsLayer,
			});
			map.getViewport().style.cursor = hit ? 'pointer' : '';
		});

		const buildingTooltipEl = document.createElement('div');
		buildingTooltipEl.id = 'freeride-building-tooltip';
		buildingTooltipEl.style.cssText = mapTooltip;

		const buildingTooltip = new ol.Overlay({
			element: buildingTooltipEl,
			positioning: 'bottom-center',
			offset: [0, -5],
		});

		const updateBuildingTooltip = buildingFeature => {
			const {
				id,
				address,
				manufacturingYear,
				encounterState,
				encounterDate,
				clientId,
				clientFirstName,
				clientLastName,
			} = getJsonProperties(buildingFeature, [
				'id',
				'address',
				'manufacturingYear',
				'encounterState',
				'encounterDate',
				'clientId',
				'clientFirstName',
				'clientLastName',
			]);

			if (id === currHoverBuildingId) return;

			currHoverBuildingId = id;
			deleteChildren(buildingTooltipEl);
			renderBuildingTooltip({
				tooltipEl: buildingTooltipEl,
				address,
				manufacturingYear,
				encounterState,
				encounterDate,
				clientId,
				clientFirstName,
				clientLastName,
			});
		};

		addTooltipToLayers({
			mapEl,
			map,
			layers: [buildingsLayer],
			tooltipEl: buildingTooltipEl,
			tooltip: buildingTooltip,
			updateTooltip: updateBuildingTooltip,
		});

		return Promise.resolve({map, buildingsLayer, mapLayer, markerLayer});
	};
export const initFreerideMap = decorateMapMethod(_initFreerideMap);
