export const buildingsInclude =
	'clients,encounters,encounters.products,encounters.organization';

export const salesmanagerBuildingsInclude =
	'clients,allEncounters,allEncounters.products,allEncounters.organization';

//used for offers and cancelledDeal
export const buildingsExtraInclude =
	'clients,encounters,encounters.products,encounters.organization,encounters.source';
//used for offers and cancelledDeal
export const salesmanagerBuildingsExtraInclude =
	'clients,allEncounters,allEncounters.products,allEncounters.organization,allEncounters.source';

export const userTeamsInclude =
	'teams.users,teams.users.roles,teams.areas:withCentroid(true)';

export const allAreasBaseQuery = {
	select: 'title,subtitle,id,type,municipal_name',
	withoutTransform: true,
	type: 'city',
};

export const distanceOptions = [
	{value: '5000', label: '5km'},
	{value: '10000', label: '10km'},
	{value: '15000', label: '15km'},
];

export const buildingsPerPage = 10;

export const crossSalesCap = 10;

export const salesmanDefaultQuery = {
	dismissPrompt: true,
	encounterState: 'notContacted',
	hasPhone: false,
};
