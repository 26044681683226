import * as actions from './actions';
import {catchNonFatalDefault} from 'io/errors';
import {effect} from 'utils/redux';
import namespace from './namespace';
import {decorateWithNotifications} from 'io/app';
import services from 'services';
import msgs from 'dicts/messages';
import {getEvents, putEvent, searchUsers as ioSearchUsers, getOptions} from './io';
import * as selectors from './selectors';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const fetchEvents = (getState, dispatch) => {
	return decorateWithNotifications(
		{id: 'get-events', failureStyle: 'error'},
		getEvents(selectors.eventsQueryFetchable(getState())),
	)(getState, dispatch).then(reminders => {
		dispatch(actions._setEvents(reminders));
	});
};

export let initialize = () => (getState, dispatch) => {
	decorateWithNotifications(
		{id: 'init-events', failureStyle: 'error'},
		Promise.all([
			fetchEvents(getState, dispatch),
			getOptions().then(opts => dispatch(actions._setOptions(opts))),
		]),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let updateEvents = () => (getState, dispatch) => {
	fetchEvents(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
updateEvents = creator('updateEvents', updateEvents);

export let handleEvent = event => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'handle-event',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: msgs.saved}),
		},
		putEvent({id: event.id, handled: true}),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._setProcessing(false));
			throw e;
		})
		.then(evt => {
			dispatch(actions._updateEvent(evt));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
handleEvent = creator('handleEvent', handleEvent);

export let searchUsers =
	({text, callback}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'search-users',
				failureStyle: 'warning',
			},
			ioSearchUsers({_q: text, _limit: 5}),
		)(getState, dispatch)
			.then(users => {
				callback(users);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
searchUsers = creator('searchUsers', searchUsers);
