import {locale} from './app';
import {
	buildingStateColors,
	buildingStateHighlightColors,
	organizationMapPrimaryColors,
} from 'styles/constants';
import {map} from 'ramda';
import {state as buildingState} from 'utils/buildings';

// note: web = epsg3857, wgs = epsg4326

export const finlandCenterWeb = [2847440, 9644430];
export const swedenCenterWeb = [1712840, 9111720];

export const localeCenterWeb = {
	fi: finlandCenterWeb,
	se: swedenCenterWeb,
}[locale];

// since initial viewport is defined by a center coord and zoom level in openlayers, different sizes of areas may require different zoom levels to comfortably fit in the map viewport. see below for the current locale zoom factor
export const finlandZoomFactor = 1;
export const swedenZoomFactor = 1;

// use this like "mapInitZoom * zoomFactor" to make sure your map zoom adjusts so that it covers the current country comfortably.
export const localeZoomFactor = {
	fi: finlandZoomFactor,
	se: swedenZoomFactor,
}[locale];

// note that OL never requests tiles for the maximum zoom level
export const maxZoom = 18;
// note that OL's default tile loading logic seems to request tiles for 0.5 smaller zoom than the current zoom actually is. so if your buildingsMinZoom is 14 and the API supports zooms >= 14, when your actual zoom is 14 OL will request tiles for zoom 13 and receive an empty response from the API. for that reason setting minimum zooms to be x.5 could be a good idea, but it's not necessary and just confuses things if OL changes later.
export const buildingsMinZoom = 13;
export const areasMaxDetailZoom = 13;
export const buildingsYearMinZoom = 16;
export const groundwaterAreasMinZoom = 13;
export const propertyLinesMinZoom = 15;

// use these as the go-to zoom levels when centering the map on different types of objects
export const areaFocusZoom = 12;
export const buildingFocusZoom = 16;

// styles
// NOTE: styles should really be under styles/maps, but too much trouble to change it now

const alphaRegex = /,[^,]\)/;

const changeAlpha = (rgba, opacity) => rgba.replace(alphaRegex, `,${opacity})`);

let cachedStyles = null;

// ol = openLayers
export const styles = ol => {
	if (cachedStyles) return cachedStyles;

	const buildingStateFills = map(
		color => new ol.style.Fill({color}),
		buildingStateColors,
	);
	const buildingStateFillsLight = map(
		color => new ol.style.Fill({color: changeAlpha(color, '0.5')}),
		buildingStateColors,
	);

	const buildingStateStrokes = map(
		color => new ol.style.Stroke({color}),
		buildingStateHighlightColors,
	);
	const buildingStateStrokesStrong = map(
		color => new ol.style.Stroke({color, width: 2}),
		buildingStateHighlightColors,
	);

	const activeBuildingStrokes = map(
		color => new ol.style.Stroke({color, width: 2, lineDash: [2, 4]}),
		buildingStateHighlightColors,
	);

	// NOTE: could seriously have used the buildings.getState util for this, in combination with the building state color dicts. now we need to be careful to keep both sites up-to-date
	const buildingStyleProps = ({
		building,
		manufacturingLimit,
		selected,
		// optional
		preferD2dState,
		lightFill,
		strongStroke,
	}) => {
		const state = buildingState({building, manufacturingLimit, preferD2dState});
		const stateFills = lightFill ? buildingStateFillsLight : buildingStateFills;
		const stateStrokes = strongStroke ? buildingStateStrokesStrong : buildingStateStrokes;

		return selected
			? {fill: stateFills[state], stroke: activeBuildingStrokes[state]}
			: {fill: stateFills[state], stroke: stateStrokes[state]};
	};

	const condoStyleProps = ({isCondo, building, manufacturingLimit, selected}) => {
		const state = buildingState({building, manufacturingLimit});
		const stateFills = isCondo ? buildingStateFills : buildingStateFillsLight;
		const stateStrokes = isCondo ? buildingStateStrokesStrong : buildingStateStrokes;

		return selected
			? {fill: stateFills[state], stroke: activeBuildingStrokes[state]}
			: {fill: stateFills[state], stroke: stateStrokes[state]};
	};

	const callPoolFills = map(
		color => new ol.style.Fill({color}),
		// We can trust that this has been initialized before this function is ever run
		organizationMapPrimaryColors.current,
	);

	cachedStyles = {
		areasFill: new ol.style.Fill({color: 'rgba(0,255,0,0.5)'}),
		areasHlFill: new ol.style.Fill({color: 'rgba(0,170,0,0.5)'}),
		areasStroke: new ol.style.Stroke({color: 'rgba(255,255,255,0.7)'}),
		areasHlStroke: new ol.style.Stroke({color: 'rgba(0,0,0,0.9)', width: 2}),
		areasTextFill: new ol.style.Fill({color: 'rgba(255,255,255,1.0)'}),
		areasTextStroke: new ol.style.Stroke({color: 'rgba(0,0,0,1.0)', width: 3}),
		areasFont: 'normal 12px arial',
		buildingStyleProps,
		condoStyleProps,
		callPoolFills,
		callPoolStroke: new ol.style.Stroke({color: 'rgba(0,0,0,0.7)'}),
		activeCallPoolStroke: new ol.style.Stroke({color: 'rgba(25,25,255,0.9)', width: 3}),
		activeAreaStroke: new ol.style.Stroke({color: 'rgba(255,255,255,1.0)', width: 3}),
		activeBuildingStrokes,
		groundwaterAreasFill: new ol.style.Fill({color: 'rgba(163,220,255,0.5)'}),
		groundwaterAreasStroke: new ol.style.Stroke({color: 'rgba(15,55,255,0.7)'}),
		propertyLinesFill: new ol.style.Fill({color: 'rgba(255, 99, 71, 0)'}),
		propertyLinesStroke: new ol.style.Stroke({color: 'rgba(0, 0, 0, 1)', width: 1}),
	};

	return cachedStyles;
};
