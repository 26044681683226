import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const searchReferences = creator('searchReferences', P.Object);

export const changePage = creator('changePage', P.Number);

export const recheckQuery = creator('recheckQuery');

export const updateReferencesQuery = creator('updateReferencesQuery', P.Object);

export const toggleBook = creator('toggleBook');

export const highlightSearchResults = creator('highlightSearchResults', P.Array);

export const referencesSearchLogging = creator('referencesSearchLogging');

export const _setReferences = creator('_setReferences', P.Array);

export const _updateReferencesPagination = creator(
	'_updateReferencesPagination',
	P.Object,
);

export const _updateReferencesQuery = creator('_updateReferencesQuery', P.Object);

export const _setAreas = creator('_setAreas', P.Array);

export const _setProducts = creator('_setProducts', P.Array);
