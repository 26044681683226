import {FormattedMessage} from '@meiko/react-intl';
import Field from 'components/generic/Field';
import React from 'react';
import ReactSelect from 'components/generic/ReactSelect';
import FormBlock from 'components/generic/FormBlock';
import Label from 'components/generic/Label';

const sortByNames = (a, b) => {
	if (`${a.firstName}${a.lastName}` < `${b.firstName}${b.lastName}`) return -1;
	if (`${a.firstName}${a.lastName}` > `${b.firstName}${b.lastName}`) return 1;
	return 0;
};

const UsersFormlet = ({name, users, label = 'User', disabled, ...rest}) => {
	return (
		<Field
			{...rest}
			name={name}
			props={{users, label}}
			component={({input, inputId, meta, users, label}) => (
				<FormBlock>
					<Label htmlFor={inputId}>
						<FormattedMessage id={label} />
					</Label>
					<br />
					<ReactSelect
						block
						{...input}
						id={inputId}
						isMulti
						isSearchable
						meta={meta}
						isClearable
						disabled={disabled}
						options={users.sort(sortByNames).map(user => {
							return {label: `${user.firstName} ${user.lastName}`, value: user.id};
						})}
					/>
				</FormBlock>
			)}
		/>
	);
};

export default UsersFormlet;
