import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, effects.destroy()];
		}

		case actions.openPlacesSuggestion.type: {
			return [state, effects.openPlacesSuggestion(payload)];
		}

		case actions.updateBuildingsQuery.type: {
			const newState = {
				...state,
				buildingsQuery: {...state.buildingsQuery, ...payload},
			};

			return [newState, effects.updateBuildingsQuery(state.buildingsQuery)];
		}

		case actions.updateMap.type: {
			return [state, effects.updateMap()];
		}

		case actions.openBuilding.type: {
			const newState = {
				...state,
				buildingsQuery: {
					...state.buildingsQuery,
					selectionId: payload.buildingId,
				},
			};

			return [newState, effects.openBuilding(payload)];
		}

		case actions.closeBuilding.type: {
			const newState = {
				...state,
				buildingsQuery: {
					...state.buildingsQuery,
					selectionId: null,
				},
			};

			return [newState, effects.openBuilding({buildingId: null, coords: null})];
		}

		case actions.setMapSource.type: {
			return [
				{...state, buildingsQuery: {...state.buildingsQuery, mapSource: payload}},
				effects.setMapSource(payload),
			];
		}

		case actions.setLayerVisibility.type: {
			const newState = {
				...state,
				buildingsQuery: {...state.buildingsQuery, [payload.layer]: payload.visible},
			};

			return [newState, effects.setLayerVisibility(payload)];
		}

		case actions._updateBuildingsQuery.type: {
			const newState = {
				...state,
				buildingsQuery: {...state.buildingsQuery, ...payload},
			};

			return [newState, null];
		}

		case actions.getAvailableTags.type: {
			return [state, effects.getAvailableTags()];
		}

		case actions._getAvailableTags.type: {
			return [
				{
					...state,
					availableTags: payload,
				},
				null,
			];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
