import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	statistics: [],
	organizationStats: false,
	teamStats: null,
	showTracker: false,
	loading: false,
	trackerLoading: false,
	targets: {
		salesPriceTarget: 0,
		offerCountTarget: 0,
	},
	stats: {
		dealPrices: 0,
		totalOffers: 0,
	},
	targetsLoading: true,
	statsLoading: true,
	notices: [],
	notiesPagination: {},
	noticesLoading: true,
};
