import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';

export default {
	...lifecycle,
	processing: false,
	formFillsQuery: {_page: 1},
	trackingForms: [],
	formsLoading: true,
	formsPagination: emptyPagination,
	formCreatorOpen: false,
	formInModal: null,
	formModalOpen: false,
	form: null,
	allTeams: [],
};
