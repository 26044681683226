export const reportInclude = 'user,creator';

export const reportsQueryBase = {
	_limit: '20',
	_sort: '-createdAt',
};

export const reportSubFormBases = {
	CustomerAcquisitionReport: {
		teams: [],
		parameters: {
			startDate: null,
			endDate: null,
		},
	},
	CustomerAcquisitionReportByProducts: {
		teams: [],
		parameters: {
			startDate: null,
			endDate: null,
		},
	},
	SalesReport: {
		teams: [],
		parameters: {
			startDate: null,
			endDate: null,
		},
	},
	SalesReportByTeams: {
		teams: [],
		parameters: {
			startDate: null,
			endDate: null,
		},
	},
	WorkingHoursReportByTeamsForSalesmen: {
		teams: [],
		parameters: {
			startDate: null,
			endDate: null,
		},
	},
	WorkingHoursReportByTeamsForCustomerAcquisition: {
		teams: [],
		parameters: {
			startDate: null,
			endDate: null,
		},
	},
	UserWorkingHoursReportForSalesmen: {
		userId: null,
		teams: [],
		subFormType: 'user',
		parameters: {
			startDate: null,
			endDate: null,
		},
	},
	UserWorkingHoursReportForCustomerAcquisition: {
		userId: null,
		teams: [],
		subFormType: 'user',
		parameters: {
			startDate: null,
			endDate: null,
		},
	},
	CommissionReport: {
		userId: null,
		teams: [],
		subFormType: 'user',
		parameters: {
			startDate: null,
			endDate: null,
		},
	},
	OrganizationReport: {
		parameters: {
			timespan: 'total',
			startDate: null,
			endDate: null,
		},
	},
	LeadReport: {
		parameters: {
			startDate: null,
			endDate: null,
		},
	},
	CallRequestReport: {
		parameters: {
			startDate: null,
			endDate: null,
		},
	},
	SuccessfulVisitsAppointmentsReport: {
		parameters: {
			startDate: null,
			endDate: null,
		},
	},
	IhSourceReport: {
		parameters: {
			startDate: null,
			endDate: null,
		},
	},
	MarketingReport: {
		parameters: {
			startDate: null,
			endDate: null,
		},
	},
	TeleReportOldVsNewClients: {
		parameters: {
			timespan: 'month',
			startDate: null,
			endDate: null,
		},
	},
	MarketingPermissionsReport: {
		parameters: {
			startDate: null,
			endDate: null,
		},
	},
};

export const reportFormBase = {
	title: '',
	type: null,
	hidden: false,
	sub: reportSubFormBases,
};
