import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const handleEvent = creator('handleEvent', P.Object);

export const fetchMore = creator('fetchMore');

export const searchEvents = creator('searchEvents', P.Object);

export const searchUsers = creator('searchUsers', P.Object);

export const _setEvents = creator('_setEvents', P.Object);

export const _updateEvent = creator('_updateEvent', P.Object);

export const _updateEventsQuery = creator('_updateEventsQuery', P.Object);

export const _setProcessing = creator('_setProcessing', P.Boolean);

export const _setOptions = creator('_setOptions', P.Object);
