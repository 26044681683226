import {appointmentsHourRange} from 'constants/domain';
import {increaseDay} from './time';

// note: very specific. for getting a suggestion for the next day's encounter based on current date.
export const getNextDayEncounterSuggestion = date => {
	const time = increaseDay(date, 1);
	const hour =
		time.getHours() < appointmentsHourRange[0] ||
		time.getHours() > appointmentsHourRange[1]
			? appointmentsHourRange[0]
			: time.getHours();
	time.setHours(hour, 0, 0, 0);
	return time;
};
