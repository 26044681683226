export const condoInclude =
	'manager,houseManager,buildings.clients,buildings.buildingProductStates.product,buildings.comments.user,repairs,activities,activities.client,user,activities.products,activities.user,activities.additionalInfo,customerAcquisitionUser';

export const buildingInclude = {
	include: 'clients,buildingProductStates.product,comments.user',
};

export const calendarInclude = 'client';

export const callFormBase = {
	// meta
	dateCreatorOpen: false,
	state: 'visit',
	// values
	clientId: null,
	calendarResourceId: null,
	calendarResource: {
		dateFrom: null,
		teamId: null,
	},
	contactClientId: null,
	reason: null,
	description: '',
	formFill: {
		bool: false,
		who: '',
		description: '',
	},
};

export const createEmptyClient = () => ({
	firstName: '',
	lastName: '',
	email: '',
	password: '',
	swedish: false,
	comments: '',
	title: '',
	address: '',
	zip: '',
	city: '',
});
