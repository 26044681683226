import {mergeLeft} from 'ramda';
import * as actions from './actions';
import * as selectors from './selectors';
import {catchNonFatalDefault} from 'io/errors';
import {getQuery, pushQuery} from 'io/history';
import {getLeadLogs} from './io';
import {effect} from 'utils/redux';
import namespace from './namespace';
import {decorateWithNotifications} from 'io/app';
import {parseUrlQuery} from './utils';

const creator = effect(namespace);

const fetchLogs = (getState, dispatch) => {
	return decorateWithNotifications(
		{id: 'get-logs', failureStyle: 'error'},
		getLeadLogs(selectors.logsQueryFetchable(getState())),
	)(getState, dispatch).then(logs => {
		dispatch(actions._setLogs(logs));
	});
};

export let initialize = () => (getState, dispatch) => {
	const logsQuery = parseUrlQuery(getQuery());
	dispatch(actions._updateLogsQuery(logsQuery));

	fetchLogs(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let updateLogs = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	fetchLogs(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
updateLogs = creator('updateLogs', updateLogs);
