export const buildingInclude =
	'clients:withoutBanned(true),buildingProductStates.product,allBuildingProductStates.product,allEncounters';

export const encountersInclude = 'source.user';

export const encounterInclude =
	'building,source.formFill.formAttributes,source.calendarResource.client,source.calendarResource.salesmanVisit,source.calendarResource.salesman,source.user,reasonMapping';

export const freeCalendarResourceInclude = 'organization,salesman,team';

export const visitFormBase = {
	// meta
	state: 'visit',
	dateCreatorOpen: false,
	isBonus: false,
	// values
	calendarResourceId: null,
	calendarResource: {
		dateFrom: null,
		teamId: null,
		ihSource: null,
	},
	bonusCalendarResource: {
		dateFrom: null,
		teamId: null,
		salesmanId: null,
	},
	contactClientId: null,
	reason: null,
	description: '',
	formFill: {
		bool: false,
		who: '',
		description: '',
	},
};

export const createEmptyClient = () => ({
	firstName: '',
	lastName: '',
	email: '',
	password: '',
	swedish: false,
	comments: '',
	title: '',
	address: '',
	zip: '',
	city: '',
});
