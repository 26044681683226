import {getHourBoundaries} from 'utils/time';
import services from 'services';
import {
	endOfDay,
	endOfMonth,
	endOfWeek,
	endOfYear,
	formatISO,
	startOfDay,
	startOfMonth,
	startOfWeek,
	startOfYear,
	subMonths,
} from 'date-fns';
import {locale} from 'constants/app';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const formatEncounterFormFillFormOutput = (form, descOnly = false) => {
	if (descOnly) {
		return {
			formId: 1,
			formAttributes: [{id: 9, value: form.description}],
		};
	}

	return {
		formId: 1,
		formAttributes: [
			{id: 1, value: form.bool},
			{id: 2, value: form.who},
			{id: 9, value: form.description},
		],
	};
};

// isBonus and bonusCalendarResource are optional
export const formatFormCalendarResourceOutput = ({contactClientId, calendarResource}) => {
	const bounds = getHourBoundaries(calendarResource.dateFrom);
	return {
		...calendarResource,
		dateFrom: bounds[0],
		dateTo: bounds[1],
		clientId: contactClientId,
	};
};

export const formatFormBonusCalendarResourceOutput = ({
	contactClientId,
	bonusCalendarResource,
	teamId,
}) => {
	const date = new Date(bonusCalendarResource.dateFrom + 'T00:00:00Z');

	const _teamId = bonusCalendarResource.teamId ? bonusCalendarResource.teamId : teamId;
	return {
		...bonusCalendarResource,
		bonus: true,
		dateFrom: date,
		dateTo: date,
		clientId: contactClientId,
		teamId: _teamId,
	};
};

// possibly a bad idea to connect validation and messages to the same function
export const validateCalendarResourceDate = date => {
	const hours = date.getHours();
	return hours === 0 ? intl.formatMessage({id: 'Invalid time'}) : null;
};

export const validateDateNotInThePast = date => {
	// Cast to Date if value is string
	const _date = date instanceof Date ? date : new Date(date);
	// Copy date so don't modify original
	const d = new Date(_date.getTime()).setHours(0, 0, 0, 0);
	const now = new Date().setHours(0, 0, 0, 0);

	return d < now ? intl.formatMessage({id: 'Selected date is in the past'}) : null;
};

export const TODAY = 'today';
export const WEEK = 'week';
export const MONTH = 'month';
export const LAST_MONTH = 'last-month';
export const THIS_YEAR = 'this-year';

export const RANGE_OPTIONS = [
	{value: TODAY, label: 'This day'},
	{value: WEEK, label: 'This week'},
	{value: MONTH, label: 'This month'},
	{value: LAST_MONTH, label: 'Last month'},
	{value: THIS_YEAR, label: 'This year'},
];
export const rangeToDates = range => {
	const now = new Date();
	const toIsoDate = date => formatISO(date, {representation: 'date'});
	const config = {
		locale,
		weekStartsOn: 1,
	};

	switch (range) {
		case TODAY:
			return [toIsoDate(startOfDay(now)), toIsoDate(endOfDay(now))];

		case WEEK:
			return [toIsoDate(startOfWeek(now, config)), toIsoDate(endOfWeek(now, config))];

		case MONTH:
			return [toIsoDate(startOfMonth(now)), toIsoDate(endOfMonth(now))];
		case LAST_MONTH:
			return [
				toIsoDate(startOfMonth(subMonths(now, 1))),
				toIsoDate(endOfMonth(subMonths(now, 1))),
			];

		case THIS_YEAR:
			return [toIsoDate(startOfYear(now)), toIsoDate(endOfYear(now))];

		default:
			return ['', ''];
	}
};
