import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';

export default {
	...lifecycle,
	processing: false,
	reportsQuery: {_page: 1},
	userTeams: [],
	// note that even though these two are renderable data, they don't need to block rendering of the view or display loaders since they have valid empty values and load quickly
	allTeams: [],
	allUsers: [],
	reports: [],
	reportsLoading: true,
	reportsPagination: emptyPagination,
	reportCreatorOpen: false,
};
