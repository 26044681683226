import {action} from 'utils/redux';
import {P, Record, PaginatedData} from 'utils/types';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const changePage = creator('changePage', P.Number);

export const openFormModal = creator('openFormModal', P.Object);

export const closeFormModal = creator('closeFormModal');

export const openFormCreator = creator('openFormCreator');

export const closeFormCreator = creator('closeFormCreator');

export const saveNewTrackingForm = creator('saveNewTrackingForm');

export const removeTrackingForm = creator('removeTrackingForm', P.Number);

export const _updateTrackingFormsQuery = creator('_updateTrackingFormsQuery', P.Object);

export const _setInitMeta = creator(
	'_setInitMeta',
	Record({allTeams: P.Array, form: P.Object}),
);

export const _setTrackingForms = creator('_setTrackingForms', PaginatedData(P.Array));

export const _newTrackingFormSaved = creator('_newTrackingFormSaved', P.Object);

export const _startOp = creator('_startOp');

export const _opOk = creator('_opOk');

export const _opFailed = creator('_opFailed');

export const recheckQuery = creator('recheckQuery');
