import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const changePage = creator('changePage');

export const recheckQuery = creator('recheckQuery');

export const banSearch = creator('banSearch');

export const closeBanModal = creator('closeBanModal');

export const navigateToBuildingPage = creator('navigateToBuildingPage');

export const openBanModal = creator('openBanModal', P.Object);

export const removeBan = creator('removeBan');

export const setBansType = creator('setBansType', P.String);

export const updateBan = creator('updateBan');

export const _initialize = creator('_initialize');

export const _addNewBan = creator('_addNewBan');

export const _setBans = creator('_setBans', P.Object);

export const _setBansType = creator('_setBansType');

export const _setBuildingsBans = creator('_setBuildingsBans');

export const _updateBansQuery = creator('_updateBansQuery');

export const _updateBan = creator('_updateBan');

export const _startOp = creator('_startOp');

export const _opOk = creator('_opOk');

export const _opFailed = creator('_opFailed');
