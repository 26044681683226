import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import * as commonSelectors from 'modules/common/selectors';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {
	formatTeamStats,
	formatUserStats,
	formatOrganiationStats,
	formatStats,
} from './utils';
import {isSalesmanAppAdmin} from 'utils/perms';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.getNotices.type: {
			return [{...state, noticesLoading: true}, effects.getNotices(payload)];
		}

		case actions._noticesFetchFailed.type: {
			return [{...state, noticesLoading: false}, null];
		}

		case actions._setUserNotices.type: {
			const {data, pagination} = payload;
			const newState = {
				...state,
				notices: [...state.notices, ...data],
				noticesPagination: pagination,
				noticesLoading: false,
			};

			return [newState, null];
		}

		case actions._setTrackerStatistics.type: {
			const trackerStatistics = payload.organizationTotalMonth
				? formatOrganiationStats(payload)
				: formatUserStats(payload);

			const newState = {
				...state,
				showTracker: true,
				trackerStatistics,
				trackerLoading: false,
			};
			return [newState, null];
		}

		case actions._setTeamTrackerStatistics.type: {
			const {team, stats} = payload;
			const newState = {
				...state,
				userTeam: team[0],
				teamStats: formatTeamStats(stats),
			};

			return [newState, null];
		}

		case actions._setUserTargets.type: {
			const zeroIndex = {id: 0, target: 0, reward: 0};

			const userTargets = payload;
			userTargets.unshift(zeroIndex);

			return [{...state, userTargets}, null];
		}

		case actions._opOk.type: {
			const newState = {...state, loading: false};
			return [newState, null];
		}

		case actions._setTrackerLoading.type: {
			return [{...state, trackerLoading: true}, null];
		}

		case actions._setTargets.type: {
			return [{...state, targets: payload, targetsLoading: false}, null];
		}

		case actions._setStats.type: {
			const isAdmin = isSalesmanAppAdmin(commonSelectors.user(fullState));
			const newState = {
				...state,
				stats: formatStats(payload, isAdmin),
				statsLoading: false,
			};

			return [newState, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
