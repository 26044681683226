import services from 'services';
import {map} from 'ramda';
import {describeThrow} from 'utils/errors';
import {
	clientQueryBase,
	calendarResourceInclude,
	encountersInclude,
	encounterInclude,
} from './constants';
import * as normalize from 'utils/normalize';
import {getResponseData} from 'utils/app';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getClient = clientId =>
	httpJson('get', `/clients/${clientId}`, clientQueryBase)
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load client'})))
		.then(getResponseData(normalize.client));

export const putClient = client =>
	httpJson('put', `/clients/${client.id}`, clientQueryBase, {body: client})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save'})))
		.then(getResponseData(normalize.client));

export const deleteClient = clientId =>
	httpJson('delete', `/clients/${clientId}`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to delete'})),
	);

export const postRestoreClient = clientId =>
	httpJson('post', `/clients/${clientId}/restore`, {}, {}).catch(
		describeThrow(intl.formatMessage({id: 'Failed to restore'})),
	);

export const banClient = clientId =>
	httpJson('post', '/bans/', {clientId, include: 'user,organization'})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to ban client'})))
		.then(getResponseData(normalize.client));

export const removeBan = clientId =>
	httpJson('delete', `/bans/${clientId}`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to remove ban from client'})),
	);

export const getCalendarResources = clientId =>
	httpJson('get', `/calendarResource`, {
		clientId,
		...calendarResourceInclude,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load calendar entries'})))
		.then(getResponseData(map(normalize.calendarResource)));

export const getEncounters = clientId =>
	httpJson('get', `/encounters`, {
		clientId,
		...encountersInclude,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.encounter)));

export const getEncounter = encounterId =>
	httpJson('get', `/encounters/${encounterId}`, {include: encounterInclude})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load contact'})))
		.then(getResponseData(normalize.encounter));
