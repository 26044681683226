import services from 'services';
import {map, pipe, prop} from 'ramda';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import {mapResponseData, getResponseData} from 'utils/app';
import msgs from 'dicts/messages';
import {referencesInclude} from './constants';
import {catchNonFatalDefault} from 'io/errors';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getReferences = query =>
	httpJson('get', '/references', {...query, include: referencesInclude})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.reference)));

export const getAreas = () =>
	httpJson('get', '/areas', {
		select: 'id,type,title,municipal_name,county',
		withoutTransform: true,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getProducts = () =>
	httpJson('get', '/products', {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.product)));

export const postReferencesSearchLog = searchParam =>
	httpJson('post', '/references/log', {}, {body: searchParam}).catch(
		catchNonFatalDefault,
	);
