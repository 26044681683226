import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {formatSearchFormInput} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const report = pipe(root, prop('report'));

export const loading = pipe(root, prop('loading'));

export const reportQuery = pipe(root, prop('reportQuery'));

export const timespan = createSelector(reportQuery, reportQuery => reportQuery.timespan);

export const searchFormInitValues = createSelector([reportQuery], reportQuery =>
	formatSearchFormInput(reportQuery),
);
