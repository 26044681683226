import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {textUnstyled} from 'styles/fragments';
import {errorColor, fontSizeXS} from 'styles/constants';
import {splitGlobalAttrs} from 'utils/html';

const Wrapper = styled.span`
	${textUnstyled};
	${/*prettier-ignore*/ ({standalone}) => !standalone ? `
		position: absolute;
		top: calc(100% + 2px);
		left: 0;
	` : ''}
	display: block;
	font-size: ${fontSizeXS};
	color: ${errorColor};
	${({noWrap}) => (noWrap ? 'white-space: nowrap;' : '')};
`;

const FieldError = ({touched, submitFailed, error, standalone, noWrap, ...rest}) => {
	if (!((submitFailed || touched) && error)) {
		return null;
	}
	const [props] = splitGlobalAttrs(rest);
	return (
		<Wrapper standalone={standalone} noWrap={noWrap} {...props} data-type="field-error">
			{error}
		</Wrapper>
	);
};

FieldError.propTypes = {
	touched: propTypes.bool,
	standalone: propTypes.bool,
	submitFailed: propTypes.bool,
	error: propTypes.string,
	noWrap: propTypes.bool,
};

export default FieldError;
