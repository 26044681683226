import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import initState from './state';

export const parseUrlQuery = query => {
	const vals = evolve(
		{
			dismissPrompt: val => (val ? JSON.parse(val) : false),
			productIds: val => (val ? JSON.parse(val) : []),
			organizationIds: val => (val ? JSON.parse(val) : []),
			withLeadClients: val => (val ? JSON.parse(val) : false),
		},
		query,
	);

	return {clientsQuery: replace(vals, initState.clientsQuery)};
};

export const formatUrlQuery = query => {
	const {productIds, organizationIds, ...q} = query;

	return {
		...q,
		productIds: productIds ? JSON.stringify(productIds) : null,
		organizationIds: organizationIds ? JSON.stringify(organizationIds) : null,
	};
};

export const formatFetchableClientsQuery = query => {
	const {searchType} = query;

	if (searchType === 'clients') {
		return {
			'clients[organizationIds]': query.organizationIds,
			'clients[productIds]': query.productIds,
			'clients[installedDateFrom]': query.installedDateFrom,
			'clients[installedDateTo]': query.installedDateTo,
		};
	} else if (searchType === 'offer') {
		return {
			'offer[organizationIds]': query.organizationIds,
			'offer[productIds]': query.productIds,
			'offer[dateFrom]': query.offerDateFrom,
			'offer[dateTo]': query.offerDateTo,
		};
	} else {
		return {
			'marketingPermission[withLeadClients]': query.withLeadClients,
		};
	}
};

export const formatClientsFiltersInput = query => {
	const {dismissPrompt, ...q} = query;
	return q;
};

export const formatClientsFiltersOutput = form => {
	return form;
};
