import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.navigateToBuilding.type: {
			const newState = {...state, buildings: []};
			return [newState, effects.navigateToBuilding(payload)];
		}

		case actions.searchBuildings.type: {
			return [state, effects.searchBuildings(payload)];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true, processing: false, buildings: []}, null];
		}

		case actions._setBuildings.type: {
			const buildings = payload;
			return [{...state, buildings, processing: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
