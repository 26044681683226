import services from 'services';
import importMaps from 'services/importMaps';
import {describeThrow} from 'utils/errors';
import {apiUrl} from 'constants/app';
import {
	maxZoom,
	buildingsMinZoom,
	styles as mapStyles,
	buildingsYearMinZoom,
	groundwaterAreasMinZoom,
	propertyLinesMinZoom,
} from 'constants/maps';
import {
	safeGetFeaturesAtPixel,
	safeHasFeatureAtPixel,
	addTooltipToLayers,
	renderBuildingTooltip,
	createMarkerLayer,
	_getMapTileSource,
} from 'io/maps';
import {deleteChildren} from 'utils/dom';
import {encodeQuery} from 'utils/url';
import {getJsonProperty, getJsonProperties, concreteBuildingStyle} from 'utils/maps';
import {mapTooltip} from 'styles/fragments';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const decorateMapMethod = method => args =>
	importMaps()
		.then(method(args))
		.catch(describeThrow(intl.formatMessage({id: 'Error loading map'})));

const _getBuildingsStyle =
	({zoom, filters, selectionId, manufacturingLimit}) =>
	imports => {
		const {openLayers: ol} = imports;

		const sty = mapStyles(ol);
		const keys = [
			'id',
			'encounterState',
			'manufacturingYear',
			'banned',
			'encounterDate',
			'clientId',
			'tagIds',
		];

		return feature => {
			const {
				id,
				encounterState,
				manufacturingYear,
				banned,
				encounterDate,
				clientId,
				tagIds,
			} = getJsonProperties(feature, keys);

			if (filters) {
				if (
					filters.encounterState &&
					filters.encounterState !== 'any' &&
					encounterState !== filters.encounterState
				)
					return null;
				if (
					filters.minYear &&
					(manufacturingYear < filters.minYear || !manufacturingYear)
				)
					return null;
				if (
					filters.maxYear &&
					(manufacturingYear > filters.maxYear || !manufacturingYear)
				)
					return null;

				if (filters.tagIds && filters.tagIds.length > 0) {
					const tagIdFilters = (filters.tagIds ?? '')
						.trim()
						.split(',')
						.filter(v => !!v)
						.map(v => Number(v));

					const attachedTags = tagIds ?? [];
					if (!tagIdFilters.some(tagId => attachedTags.includes(tagId))) return null;
				}
			}

			const selected = selectionId === id;
			const building = {encounterState, encounterDate, banned, manufacturingYear};

			return new ol.style.Style({
				...concreteBuildingStyle(ol, {
					geomType: feature.getGeometry().getType(),
					zoom,
					style: sty.buildingStyleProps({
						building,
						manufacturingLimit,
						selected,
						lightFill: !clientId,
						strongStroke: !!clientId,
					}),
				}),
				zIndex: selected ? 1 : 0,
				text:
					zoom >= buildingsYearMinZoom
						? new ol.style.Text({
								text: manufacturingYear ? `${manufacturingYear}` : '',
								overflow: true,
						  })
						: null,
			});
		};
	};
export const getBuildingsStyle = decorateMapMethod(_getBuildingsStyle);

const _getBuildingsSource =
	({apiToken, organizationId}) =>
	imports => {
		const {openLayers: ol} = imports;

		return new ol.source.VectorTile({
			format: new ol.format.MVT({dataProjection: 'EPSG:4326'}),
			// prettier-ignore
			url: `${apiUrl}/maps/buildings/mvt/{z}/{x}/{y}${encodeQuery({organizationId, token: apiToken})}`,
			maxZoom: maxZoom,
		});
	};
export const getBuildingsSource = decorateMapMethod(_getBuildingsSource);

const createBuildingsLayer =
	({
		apiToken,
		organizationId,
		initialZoom,
		initialFilters,
		selectionId,
		manufacturingLimit,
	}) =>
	imports => {
		const {openLayers: ol} = imports;

		const layer = new ol.layer.VectorTile({
			source: _getBuildingsSource({apiToken, organizationId})(imports),
			style: _getBuildingsStyle({
				zoom: initialZoom,
				filters: initialFilters,
				selectionId,
				manufacturingLimit,
			})(imports),
			visible: initialZoom >= buildingsMinZoom,
		});

		return layer;
	};

// groundwaterAreas layer

const _getGroundwaterAreasStyle = () => imports => {
	const {openLayers: ol} = imports;

	const sty = mapStyles(ol);

	return feature => {
		return new ol.style.Style({
			fill: sty.groundwaterAreasFill,
			stroke: sty.groundwaterAreasStroke,
		});
	};
};
export const getGroundwaterAreasStyle = decorateMapMethod(_getGroundwaterAreasStyle);

const _getGroundwaterAreasSource =
	({apiToken}) =>
	imports => {
		const {openLayers: ol} = imports;

		return new ol.source.VectorTile({
			format: new ol.format.MVT({dataProjection: 'EPSG:4326'}),
			url: `${apiUrl}/maps/groundwaterAreas/mvt/{z}/{x}/{y}${encodeQuery({
				token: apiToken,
			})}`,
			maxZoom: maxZoom,
		});
	};
export const getGroundwaterAreasSource = decorateMapMethod(_getGroundwaterAreasSource);

const createGroundwaterAreasLayer =
	({apiToken, initialZoom, visible}) =>
	imports => {
		const {openLayers: ol} = imports;

		const layer = new ol.layer.VectorTile({
			source: _getGroundwaterAreasSource({apiToken})(imports),
			style: _getGroundwaterAreasStyle()(imports),
			visible: visible ? initialZoom >= groundwaterAreasMinZoom : false,
		});

		return layer;
	};

// PropertyLines layer

const _getPropertyLinesStyle = () => imports => {
	const {openLayers: ol} = imports;

	const style = mapStyles(ol);

	return feature => {
		if (feature.getType() === 'LineString') {
			return new ol.style.Style({
				fill: style.propertyLinesFill,
				stroke: style.propertyLinesStroke,
			});
		}
	};
};
export const getPropertyLinesStyle = decorateMapMethod(_getPropertyLinesStyle);

const _getPropertyLinesSource =
	({apiToken}) =>
	imports => {
		const {openLayers: ol} = imports;

		return new ol.source.VectorTile({
			format: new ol.format.MVT({dataProjection: 'EPSG:4326'}),
			url: `${apiUrl}/maps/propertyLines/mvt/{z}/{x}/{y}${encodeQuery({
				token: apiToken,
			})}`,
			maxZoom: maxZoom,
		});
	};
export const getPropertyLinesSource = decorateMapMethod(_getPropertyLinesSource);

const createPropertyLinesLayer =
	({apiToken, initialZoom, visible}) =>
	imports => {
		const {openLayers: ol} = imports;

		const layer = new ol.layer.VectorTile({
			source: _getPropertyLinesSource({apiToken})(imports),
			style: _getPropertyLinesStyle()(imports),
			visible: visible ? initialZoom >= propertyLinesMinZoom : false,
		});

		return layer;
	};

const _initFreerideMap =
	({
		apiToken,
		organizationId,
		initialZoom,
		initialCenter,
		initialFilters,
		selectionId,
		onBuildingClick,
		onMapLocationChanged,
		manufacturingLimit,
		mapSourceProps,
		layersVisibility,
		getLayersVisibility,
	}) =>
	imports => {
		// keep some local state
		let currHoverBuildingId = null;

		const {openLayers: ol} = imports;

		const mapEl = document.querySelector('#freeride-map');
		if (!mapEl) {
			return Promise.reject(new Error('Map element not found'));
		}

		const view = new ol.View({
			projection: 'EPSG:3857',
			center: initialCenter,
			zoom: initialZoom,
			maxZoom,
			enableRotation: false,
			constrainResolution: true,
		});

		const buildingsLayer = createBuildingsLayer({
			apiToken,
			organizationId,
			initialZoom,
			initialFilters,
			selectionId,
			manufacturingLimit,
		})(imports);

		const groundwaterAreasLayer = createGroundwaterAreasLayer({
			apiToken,
			initialZoom,
			visible: layersVisibility.groundwaterAreasLayer,
		})(imports);

		const propertyLinesLayer = createPropertyLinesLayer({
			apiToken,
			initialZoom,
			visible: layersVisibility.propertyLinesLayer,
		})(imports);

		const mapSource = _getMapTileSource(mapSourceProps)(imports);
		const mapLayer = new ol.layer.Tile({source: mapSource});

		const markerLayer = createMarkerLayer(imports);

		// map

		const map = new ol.Map({
			target: 'freeride-map',
			view,
			layers: [
				mapLayer,
				groundwaterAreasLayer,
				buildingsLayer,
				markerLayer,
				propertyLinesLayer,
			],
		});

		map.on('moveend', e => {
			const zoom = map.getView().getZoom();
			const center = map.getView().getCenter();

			onMapLocationChanged({z: zoom, x: center[0], y: center[1]});

			// toggle buildings layer
			if (zoom >= buildingsMinZoom) {
				buildingsLayer.setVisible(true);
			} else {
				buildingsLayer.setVisible(false);
			}

			// toggle groundwater areas layer
			if (
				zoom >= groundwaterAreasMinZoom &&
				getLayersVisibility().groundwaterAreasLayer
			) {
				groundwaterAreasLayer.setVisible(true);
			} else {
				groundwaterAreasLayer.setVisible(false);
			}

			// toggle property lines layer
			if (zoom >= propertyLinesMinZoom && getLayersVisibility().propertyLinesLayer) {
				propertyLinesLayer.setVisible(true);
			} else {
				propertyLinesLayer.setVisible(false);
			}
		});

		map.on('click', e => {
			const features = safeGetFeaturesAtPixel(map, e.pixel, {
				layerFilter: l => l === buildingsLayer,
			});
			if (!features) return;
			const buildingId = getJsonProperty(features[0], 'id');
			onBuildingClick(buildingId, e.coordinate);
		});

		map.on('pointermove', e => {
			const pixel = map.getEventPixel(e.originalEvent);
			const hit = safeHasFeatureAtPixel(map, pixel, {
				layerFilter: l => l === buildingsLayer,
			});
			map.getViewport().style.cursor = hit ? 'pointer' : '';
		});

		const buildingTooltipEl = document.createElement('div');
		buildingTooltipEl.id = 'freeride-building-tooltip';
		buildingTooltipEl.style.cssText = mapTooltip;

		const buildingTooltip = new ol.Overlay({
			element: buildingTooltipEl,
			positioning: 'bottom-center',
			offset: [0, -5],
		});

		const updateBuildingTooltip = buildingFeature => {
			const {
				id,
				address,
				manufacturingYear,
				encounterState,
				encounterDate,
				clientId,
				clientFirstName,
				clientLastName,
			} = getJsonProperties(buildingFeature, [
				'id',
				'address',
				'manufacturingYear',
				'encounterState',
				'encounterDate',
				'clientId',
				'clientFirstName',
				'clientLastName',
			]);

			if (id === currHoverBuildingId) return;

			currHoverBuildingId = id;
			deleteChildren(buildingTooltipEl);
			renderBuildingTooltip({
				tooltipEl: buildingTooltipEl,
				address,
				manufacturingYear,
				encounterState,
				encounterDate,
				clientId,
				clientFirstName,
				clientLastName,
			});
		};

		addTooltipToLayers({
			mapEl,
			map,
			layers: [buildingsLayer],
			tooltipEl: buildingTooltipEl,
			tooltip: buildingTooltip,
			updateTooltip: updateBuildingTooltip,
		});

		return Promise.resolve({
			map,
			buildingsLayer,
			mapLayer,
			markerLayer,
			groundwaterAreasLayer,
			propertyLinesLayer,
		});
	};
export const initFreerideMap = decorateMapMethod(_initFreerideMap);
