import React from 'react';

const SvgIconStreetview = props => (
	<svg viewBox="0 0 42.52 42.52" {...props} width="1em" height="1em">
		<path
			d="M33.762 29.805c-1.961-.972-4.651-1.699-7.576-2.048l-.273-.033.22-2.113.185-.05a2.629 2.629 0 0 0 1.933-2.324l.598-8.557a4.207 4.207 0 0 0-2.932-4.309l-.377-.118.238-.315a4.843 4.843 0 0 0 .958-2.877 4.811 4.811 0 0 0-4.806-4.806 4.81 4.81 0 0 0-4.803 4.806c0 1.027.34 2.049.96 2.878l.236.316-.377.117a4.211 4.211 0 0 0-2.932 4.307l.266 3.756-.009.053.34 4.747c.074 1.09.87 2.046 1.933 2.328l.185.049.22 2.112-.273.033c-2.917.348-5.6 1.072-7.552 2.037-3.187 1.575-3.857 3.37-3.857 4.598 0 2.527 2.877 4.757 7.698 5.965l.249.046c.26.037.598.097.96.161 1.682.3 3.988.711 6.756.711 4.064 0 7.899-.65 10.8-1.831 3.137-1.279 4.865-3.074 4.865-5.052 0-1.224-.665-3.015-3.833-4.587zM21.93 3.943c1.721 0 3.119 1.398 3.119 3.117s-1.398 3.117-3.119 3.117c-1.719 0-3.117-1.398-3.117-3.117s1.398-3.117 3.117-3.117zm-4.638 19.175l-.266-3.793.013-.063-.347-4.699c-.048-.707.19-1.38.674-1.896a2.49 2.49 0 0 1 1.841-.801h5.445c.709 0 1.363.285 1.845.802.482.519.72 1.191.672 1.895l-.6 8.557a.91.91 0 0 1-.901.84h-.301a.844.844 0 0 0-.84.757l-.883 8.481H20.22l-.886-8.482a.845.845 0 0 0-.842-.756h-.299a.907.907 0 0 1-.901-.842zm14.797 14.764c-2.698 1.101-6.306 1.706-10.159 1.706-2.764 0-4.93-.249-7.469-.857l-.065-.005c-3.981-.999-6.44-2.657-6.44-4.332 0-2.15 4.252-4.28 9.888-4.955l.281-.034.491 4.725a.844.844 0 0 0 .84.757h4.947a.846.846 0 0 0 .842-.758l.491-4.724.281.034c5.637.675 9.888 2.805 9.888 4.955.001 1.228-1.39 2.5-3.816 3.488z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgIconStreetview;
