import services from 'services';
import {catchNonFatalDefault} from 'io/errors';
import {effect} from 'utils/redux';
import namespace from './namespace';
import {getTags as getAvailableTags} from 'modules/usersApp/tagsPage/io';
import * as confirmerActions from 'modules/confirmer/actions';
import * as actions from './actions';
import * as selectors from './selectors';
import {
	getReasonMappings as getReasonMappingsIo,
	createReasonMapping as createReasonMappingIo,
	updateReasonMapping as updateReasonMappingIo,
	deleteReasonMapping as deleteReasonMappingIo,
	restoreReasonMapping as restoreReasonMappingIo,
} from './io';
import {decorateWithNotifications} from 'io/app';
import {getQuery, pushQuery} from 'io/history';
import messages from 'dicts/messages';
import {parseUrlQuery} from './utils';
import {equals, mergeLeft} from 'ramda';
import {TYPE_BUILDING} from '../tagsPage/constants';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

export let initialize = () => (getState, dispatch) => {
	const {trashed} = parseUrlQuery(getQuery());
	dispatch(actions._setShowDeleted(trashed));
	dispatch(actions._initialize());

	getAvailableTags({
		type: TYPE_BUILDING,
		getAllTags: false,
	}).then(({data: tags}) => {
		dispatch(actions._setAvailableTags(tags));
	});
};
initialize = creator('initialize', initialize);

export let getReasonMappings = () => (getState, dispatch) => {
	const query = selectors.reasonMappingsQuery(getState());
	getReasonMappingsIo(query)
		.then(({data: reasonMappings}) => {
			dispatch(actions._getReasonMappings(reasonMappings));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
getReasonMappings = creator('getReasonMappings', getReasonMappings);

export let createReasonMapping = values => (getState, dispatch) => {
	decorateWithNotifications(
		{id: 'create-reasonMapping', loading: intl.formatMessage({id: messages.processing})},
		createReasonMappingIo(values),
	)(getState, dispatch)
		.then(reasonMapping => {
			const reasonMappings = selectors.reasonMappings(getState());
			dispatch(actions._getReasonMappings([reasonMapping, ...reasonMappings]));
			dispatch(actions.closeReasonMappingModal());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
createReasonMapping = creator('createReasonMapping', createReasonMapping);

export let updateReasonMapping =
	({id, values}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'update-reasonMapping',
				loading: intl.formatMessage({id: messages.processing}),
			},
			updateReasonMappingIo(id, values),
		)(getState, dispatch)
			.then(reasonMapping => {
				const reasonMappings = selectors
					.reasonMappings(getState())
					.map(t => (t.id === reasonMapping.id ? reasonMapping : t));
				dispatch(actions._getReasonMappings(reasonMappings));
				dispatch(actions.closeReasonMappingModal());
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
updateReasonMapping = creator('updateReasonMapping', updateReasonMapping);

export let deleteReasonMapping = id => (getState, dispatch) => {
	const onConfirm = () => {
		decorateWithNotifications(
			{
				id: 'delete-reasonMapping',
				loading: intl.formatMessage({id: messages.processing}),
			},
			deleteReasonMappingIo(id),
		)(getState, dispatch)
			.then(() => {
				const reasonMappings = selectors
					.reasonMappings(getState())
					.filter(t => t.id !== id);
				dispatch(actions._getReasonMappings(reasonMappings));
				dispatch(actions.closeReasonMappingModal());
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete reasonMapping?'}),
			cancelText: intl.formatMessage({id: messages.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
deleteReasonMapping = creator('deleteReasonMapping', deleteReasonMapping);

export let restoreReasonMapping = id => (getState, dispatch) => {
	decorateWithNotifications(
		{id: 'update-reasonMapping', loading: intl.formatMessage({id: messages.processing})},
		restoreReasonMappingIo(id),
	)(getState, dispatch)
		.then(reasonMapping => {
			const reasonMappings = selectors
				.reasonMappings(getState())
				.filter(t => t.id !== reasonMapping.id);
			dispatch(actions._getReasonMappings(reasonMappings));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
restoreReasonMapping = creator('restoreReasonMapping', restoreReasonMapping);

export let recheckQuery = () => (getState, dispatch) => {
	const {trashed} = parseUrlQuery(getQuery());
	const currentTrashed = selectors.showDeleted(getState());
	if (!equals(trashed, currentTrashed)) {
		dispatch(actions._setShowDeleted(trashed));
	}
	dispatch(actions.getReasonMappings());
};
recheckQuery = creator('recheckQuery', recheckQuery);

export let setShowDeleted = shouldShow => (getState, dispatch) => {
	pushQuery(
		mergeLeft({
			trashed: shouldShow,
		}),
	);
	dispatch(actions._setShowDeleted(shouldShow));
};
setShowDeleted = creator('setShowDeleted', setShowDeleted);
