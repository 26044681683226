import services from 'services';
import {catchNonFatalDefault} from 'io/errors';
import {effect} from 'utils/redux';
import namespace from './namespace';
import * as confirmerActions from 'modules/confirmer/actions';
import * as actions from './actions';
import * as selectors from './selectors';
import {
	getTags as getTagsIo,
	createTag as createTagIo,
	updateTag as updateTagIo,
	deleteTag as deleteTagIo,
	restoreTag as restoreTagIo,
} from './io';
import {decorateWithNotifications} from 'io/app';
import {getQuery, pushQuery} from 'io/history';
import messages from 'dicts/messages';
import {parseUrlQuery} from './utils';
import {equals, mergeLeft} from 'ramda';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

export let initialize = () => (getState, dispatch) => {
	const {trashed} = parseUrlQuery(getQuery());
	dispatch(actions._setShowDeleted(trashed));
	dispatch(actions._initialize());
};
initialize = creator('initialize', initialize);

export let getTags = () => (getState, dispatch) => {
	const query = selectors.tagsQuery(getState());
	getTagsIo(query)
		.then(({data: tags}) => {
			dispatch(actions._getTags(tags));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
getTags = creator('getTags', getTags);

export let createTag = values => (getState, dispatch) => {
	decorateWithNotifications(
		{id: 'create-tag', loading: intl.formatMessage({id: messages.processing})},
		createTagIo(values),
	)(getState, dispatch)
		.then(tag => {
			const tags = selectors.tags(getState());
			dispatch(actions._getTags([tag, ...tags]));
			dispatch(actions.closeTagModal());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
createTag = creator('createTag', createTag);

export let updateTag =
	({id, values}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{id: 'update-tag', loading: intl.formatMessage({id: messages.processing})},
			updateTagIo(id, values),
		)(getState, dispatch)
			.then(tag => {
				const tags = selectors.tags(getState()).map(t => (t.id === tag.id ? tag : t));
				dispatch(actions._getTags(tags));
				dispatch(actions.closeTagModal());
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
updateTag = creator('updateTag', updateTag);

export let deleteTag = id => (getState, dispatch) => {
	const onConfirm = () => {
		decorateWithNotifications(
			{id: 'delete-tag', loading: intl.formatMessage({id: messages.processing})},
			deleteTagIo(id),
		)(getState, dispatch)
			.then(() => {
				const tags = selectors.tags(getState()).filter(t => t.id !== id);
				dispatch(actions._getTags(tags));
				dispatch(actions.closeTagModal());
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete tag?'}),
			cancelText: intl.formatMessage({id: messages.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
deleteTag = creator('deleteTag', deleteTag);

export let restoreTag = id => (getState, dispatch) => {
	decorateWithNotifications(
		{id: 'update-tag', loading: intl.formatMessage({id: messages.processing})},
		restoreTagIo(id),
	)(getState, dispatch)
		.then(tag => {
			const tags = selectors.tags(getState()).filter(t => t.id !== tag.id);
			dispatch(actions._getTags(tags));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
restoreTag = creator('restoreTag', restoreTag);

export let recheckQuery = () => (getState, dispatch) => {
	const {trashed} = parseUrlQuery(getQuery());
	const currentTrashed = selectors.showDeleted(getState());
	if (!equals(trashed, currentTrashed)) {
		dispatch(actions._setShowDeleted(trashed));
	}
	dispatch(actions.getTags());
};
recheckQuery = creator('recheckQuery', recheckQuery);

export let setShowDeleted = shouldShow => (getState, dispatch) => {
	pushQuery(
		mergeLeft({
			trashed: shouldShow,
		}),
	);
	dispatch(actions._setShowDeleted(shouldShow));
};
setShowDeleted = creator('setShowDeleted', setShowDeleted);
