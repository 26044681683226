import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';

export default {
	...lifecycle,
	processing: false,
	requestsQuery: {_page: 1},
	requests: [],
	requestsLoading: true,
	requestsPagination: emptyPagination,
	requestCreatorOpen: false,
	clients: [],
	clientsLoading: false,
};
