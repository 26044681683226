import {pipe, prop, map, path} from 'ramda';
import services from 'services';
import {describeThrow, describeError} from 'utils/errors';
import {getResponseData, mapResponseData} from 'utils/app';
import {
	calendarResourceInclude,
	calResQueryBase,
	calendarResourceEncounterInclude,
	userTeamsInclude,
} from './constants';
import msgs from 'dicts/messages';
import * as normalize from 'utils/normalize';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getUserTeams = () =>
	httpJson('get', '/users/me', {include: userTeamsInclude})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load user'})))
		.then(getResponseData(pipe(path(['teams', 'data']), map(normalize.team))));

export const getCalendarResources = query =>
	httpJson('get', '/calendarResource', {
		include: calendarResourceInclude,
		...calResQueryBase,
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load calendar entries'})))
		.then(pipe(prop('data'), map(normalize.calendarResource)));

export const getBonusCalendarResources = query =>
	httpJson('get', '/calendarResource/bonus', {
		include: calendarResourceInclude,
		...calResQueryBase,
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load calendar entries'})))
		.then(pipe(prop('data'), map(normalize.calendarResource)));

export const postCalendarResource = resource =>
	httpJson(
		'post',
		'/calendarResource',
		{include: calendarResourceInclude},
		{body: resource},
	)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), normalize.calendarResource));

export const deleteCalendarResource = id =>
	httpJson('delete', `/calendarResource/${id}`).catch(e => {
		const reserved = !!e.response && e.response.status === 409;
		const errMsgKey = reserved
			? 'You cannot delete the appointment time because it is already booked. Try refreshing the page'
			: 'Failed to delete entry';
		throw describeError(intl.formatMessage({id: errMsgKey}), e);
	});

export const putCalendarResource = resource =>
	httpJson(
		'put',
		`/calendarResource/${resource.id}`,
		{include: calendarResourceInclude},
		{body: resource},
	)
		.catch(e => {
			const reserved = !!e.response && e.response.status === 409;
			const errMsgKey = reserved
				? 'Entry reserved for another user'
				: 'Failed to save calendar entry';
			throw describeError(intl.formatMessage({id: errMsgKey}), e);
		})
		.then(pipe(prop('data'), normalize.calendarResource));

export const getBuildings = query =>
	httpJson('get', '/buildings/search', query)
		.catch(describeThrow(intl.formatMessage({id: 'Failed to search buildings'})))
		.then(prop('data'));

export const searchUsers = query =>
	httpJson('get', '/users/search', query)
		.catch(describeThrow(intl.formatMessage({id: 'Search failed'})))
		.then(prop('data'));

export const getCalendarResourceEncounter = id =>
	httpJson('get', `/calendarResource/${id}`, {
		include: calendarResourceEncounterInclude,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load contact'})))
		.then(
			pipe(
				path(['data', 'reservation', 'data', 'encounter', 'data']),
				normalize.encounter,
			),
		);

export const getMarketingLeadSources = _ =>
	httpJson('get', `/marketingSources`, {}, {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getUsers = query =>
	httpJson('get', '/users', {_limit: 999, include: 'roles', ...query})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), map(normalize.user)));

export const checkTeamCalendarVisibility = teamId =>
	httpJson('get', '/checkTeamCalendarVisibility', {teamId})
		.then(mapResponseData(map(normalize.calendarResource)))
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})));

export const postClient = (client, buildingId) =>
	httpJson('post', '/clients', {}, {body: {buildingId, ...client}})
		.catch(e => {
			const conflict = !!e.response && e.response.status === 409;
			e.causedByMarketingPrevented = conflict;
			return conflict
				? e.response.json().then(body => describeThrow(body.message, e))
				: Promise.reject(describeError(msgs.saveFailed, e));
		})
		.then(getResponseData(normalize.client));
