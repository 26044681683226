import {dissoc} from 'ramda';
import {replace} from 'utils/objects';
import initState from './state';

export const parseUrlQuery = query => {
	const parsed = replace(query, initState.logsQuery);
	return dissoc('_page', parsed);
};

export const formatUrlQuery = query => {
	return {logModel: query.logModel};
};

export const formatFetchableQuery = q => {
	const {user, building, salesman, ...query} = q;

	if (user) {
		query.userId = user.id;
	}

	if (building) {
		query.buildingId = building.id;
	}

	if (salesman) {
		query.salesmanId = salesman.id;
	}

	if (query.logModel === 'salesmanApp') {
		query.include = 'user,organization,building';
	}

	return query;
};

export const getLogModelColumns = logModel => {
	switch (logModel) {
		case 'call':
			return [
				{title: 'Date [datetime]', path: 'createdAt', type: 'date'},
				{title: 'Phone', path: 'phone'},
				{title: 'Client', path: 'clientName'},
				{title: 'User', path: 'userName'},
				{title: 'Address', path: 'address'},
				{title: 'City', path: 'city'},
				{title: 'Organization', path: 'organization.title'},
			];

		case 'piimega':
			return [
				{title: 'Date [datetime]', path: 'createdAt', type: 'date'},
				{title: 'Status', path: 'status'},
				{title: 'Info', path: 'info'},
				{title: 'Organization', path: 'organization.title'},
				{title: 'URL-address', path: 'requestUrl'},
				{title: 'Response', path: 'response', type: 'json'},
			];

		case 'salesmanApp':
			return [
				{title: 'Date [datetime]', path: 'createdAt', type: 'date'},
				{title: 'Type', path: 'type'},
				{title: 'User', path: 'user', type: 'user'},
				{title: 'Organization', path: 'organization.title'},
				{title: 'Building', path: 'building', type: 'building'},
				{title: 'Info', path: 'data', type: 'json'},
			];

		case 'calendarResource':
			return [
				{title: 'Date [datetime]', path: 'createdAt', type: 'date'},
				{title: 'Team', path: 'logModel.team.title'},
				{title: 'Appointment time [visit]', path: 'logModel.dateFrom', type: 'date'},
				{title: 'User', path: 'user', type: 'user'},
				{title: 'Organization', path: 'organization.title'},
				{title: 'Changed data', path: 'log', type: 'json', isChangelog: true},
			];

		case 'building':
			return [
				{title: 'Date [datetime]', path: 'createdAt', type: 'date'},
				{title: 'Building', path: 'logModel', type: 'building'},
				{title: 'User', path: 'user', type: 'user'},
				{title: 'Organization', path: 'organization.title'},
				{title: 'Changed data', path: 'log', type: 'json', isChangelog: true},
			];

		case 'buildingState':
			return [
				{title: 'Date [datetime]', path: 'createdAt', type: 'date'},
				{title: 'Building', path: 'logModel', type: 'building'},
				{title: 'User', path: 'user', type: 'user'},
				{title: 'Organization', path: 'organization.title'},
				{title: 'Changed data', path: 'log', type: 'json', isChangelog: true},
			];

		case 'timeEntry':
			return [
				{title: 'Date [datetime]', path: 'createdAt', type: 'date'},
				{title: 'User', path: 'user', type: 'user'},
				{title: 'Team', path: 'logModel.team.title'},
				{title: 'Organization', path: 'organization.title'},
				{title: 'Changed data', path: 'log', type: 'json', isChangelog: true},
			];

		case 'client':
			return [
				{title: 'Date [datetime]', path: 'createdAt', type: 'date'},
				{title: 'Client', path: 'logModel', type: 'user'},
				{title: 'User', path: 'user', type: 'user'},
				{title: 'Organization', path: 'organization.title'},
				{title: 'Changed data', path: 'log', type: 'json', isChangelog: true},
			];

		case 'salesmanVisit':
			return [
				{title: 'Date [datetime]', path: 'createdAt', type: 'date'},
				{title: 'Client', path: 'logModel.client', type: 'user'},
				{title: 'Building', path: 'logModel.building', type: 'building'},
				{title: 'User', path: 'user', type: 'user'},
				{title: 'Organization', path: 'organization.title'},
				{title: 'Changed data', path: 'log', type: 'json', isChangelog: true},
			];

		case 'calendarLock':
			return [
				{title: 'Date [datetime]', path: 'createdAt', type: 'date'},
				{title: 'Team', path: 'team.title'},
				{title: 'Calendar entry', path: 'calendarResourceId'},
				{title: 'Appointment time [visit]', path: 'dateFrom', type: 'date'},
				{title: 'Salesperson', path: 'salesman', type: 'user'},
				{title: 'User', path: 'user', type: 'user'},
				{title: 'Organization', path: 'organization.title'},
			];

		default:
			return [
				{title: 'Date [datetime]', path: 'createdAt', type: 'date'},
				{title: 'User', path: 'user', type: 'user'},
				{title: 'Organization', path: 'organization.title'},
				{title: 'Changed data', path: 'log', type: 'json', isChangelog: true},
			];
	}
};

export const getLogModelInclude = logModel => {
	switch (logModel) {
		case 'piimega':
			return 'organization';
		case 'calendarResource':
			return 'user,organization,logModel.team';
		case 'timeEntry':
			return 'user,organization,logModel.team';
		case 'buildingState':
			return 'user,organization,logModel.building';
		case 'salesmanVisit':
			return 'user,organization,logModel.client,logModel.building';
		case 'calendarLock':
			return 'user,organization,team,salesman';
		default:
			return 'user,organization,logModel';
	}
};

export const getLogModelFilters = logModel => {
	const defaultFilters = {
		createdAtStart: true,
		createdAtEnd: true,
		user: true,
		organization: true,
		search: true,
	};

	switch (logModel) {
		case 'call':
			return {
				createdAtStart: true,
				createdAtEnd: true,
				client: true,
				building: true,
				user: true,
				organization: true,
			};

		case 'piimega':
			return {
				createdAtStart: true,
				createdAtEnd: true,
				organization: true,
				search: true,
			};

		case 'building':
			return {...defaultFilters, building: true};

		case 'buildingState':
			return {...defaultFilters, building: true};

		case 'client':
			return {...defaultFilters, client: true};

		case 'salesmanVisit':
			return {...defaultFilters, id: true, building: true, client: true};

		case 'calendarResource':
			return {...defaultFilters, id: true};

		case 'timeEntry':
			return {...defaultFilters, id: true};

		case 'calendarLock':
			return {
				createdAtStart: true,
				createdAtEnd: true,
				user: true,
				organization: true,
				salesman: true,
				team: true,
				id: true,
			};

		default:
			return defaultFilters;
	}
};
