import React from 'react';

const SvgGroupLogo = props => (
	<svg viewBox="0 0 280 388" {...props} width="1em" height="1em">
		<g fill="currentColor" fillRule="evenodd">
			<path d="M279.999 347.577V0L100 79.661l.43 268.288h20.019l-.433-254.629 140.005-61.954v316.211z" />
			<path fill="#73C8A0" d="M0 388h280v-20.026H0z" />
			<path d="M180.002 347.577V115.632l-180 79.661v152.656h20.017V208.952l140.005-61.956v200.581z" />
		</g>
	</svg>
);

export default SvgGroupLogo;
