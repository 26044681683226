import {FormattedMessage, injectIntl} from '@meiko/react-intl';
import Content from 'components/generic/Content';
import Header from 'components/generic/Header';
import Input from 'components/generic/Input';
import Label from 'components/generic/Label';
import _Page from 'components/generic/Page';
import Panel from 'components/generic/Panel';
import TabNavbar from 'components/generic/TabNavbar';
import {pageDefs} from 'constants/pageDefs';
import {activeOrganization, user} from 'modules/common/selectors';
import {
	initialize,
	destroy,
	setQuery,
	createDashboardTarget,
} from 'modules/usersApp/dashboardTargetsPage/actions';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {applyDispatch, applyState} from 'utils/redux';
import DashboardTargetsFilterForm from './components/DashboardTargetsFilterForm';
import {
	activeTeam,
	availableTeams,
	dashboardTargets,
	dashboardTargetsLoading,
	processing,
	dashboardTargetsQuery,
	initialized,
} from 'modules/usersApp/dashboardTargetsPage/selectors';
import LoaderOverlay from 'components/generic/LoaderOverlay';
import {screenSmMax} from 'styles/constants';
import {encodeUserIdToFormInput} from 'modules/usersApp/dashboardTargetsPage/utils';
import DashboardTargetsUsersForm from './components/DashboardTargetsUsersForm';
import Row from 'components/generic/Row';
import Col from 'components/generic/Col';
import FormBlock from 'components/generic/FormBlock';
import FieldSet from 'components/generic/FieldSet';
import styled from 'styled-components';
import {propOr} from 'ramda';
import {
	OFFERS_TARGET,
	SALES_TARGET,
	TARGETS,
	VISITS_TARGET,
} from 'modules/usersApp/dashboardTargetsPage/constants';

const Centered = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
`;

const Page = styled(_Page)`
	min-height: 60vh;
`;

const DashboardTargetsPage = ({
	initialized,
	initialize,
	destroy,
	location,
	user,
	activeOrganization,
	availableTeams,
	setQuery,
	dashboardTargets,
	dashboardTargetsLoading,
	processing,
	dashboardTargetsQuery,
	activeTeam,
	createDashboardTarget,
	showNavigation = true,
}) => {
	useEffect(() => {
		initialize();
		return destroy;
	}, []);
	// Exit early so that we don't render the page before the module is initialized (and initial query parsed)
	if (!initialized) return null;

	const subpages = pageDefs(user, activeOrganization)
		.find(app => app.id === 'users')
		?.pages?.find(page => page.name === 'Settings')?.pages;

	const targetsByType = (dashboardTargets ?? []).reduce((acc, target) => {
		acc[target.type] = [...propOr([], target.type, acc), target];
		return acc;
	}, {});

	const sum = (acc, target) => acc + target.target;

	const estimatedTeamSalesTarget = propOr([], SALES_TARGET, targetsByType).reduce(sum, 0);
	const estimatedTeamOffersTarget = propOr([], OFFERS_TARGET, targetsByType).reduce(
		sum,
		0,
	);
	const estimatedVisitsTarget = propOr([], VISITS_TARGET, targetsByType).reduce(sum, 0);

	return (
		<Page>
			{showNavigation && (
				<Header
					justifyContent="flex-end"
					leftActions={<TabNavbar pages={subpages} location={location} />}
				/>
			)}
			<Content>
				<Panel maxWidth={screenSmMax}>
					<DashboardTargetsFilterForm
						initialValues={dashboardTargetsQuery}
						teams={availableTeams}
						onSubmit={values => {
							setQuery(values);
						}}
					/>
				</Panel>

				{!dashboardTargetsLoading && activeTeam?.users && activeTeam.users.length > 0 && (
					<>
						<Panel maxWidth={screenSmMax}>
							<LoaderOverlay loading={dashboardTargetsLoading || processing}>
								<FieldSet>
									<Row>
										<Col>
											<Centered>
												<FormattedMessage id="Teams target (calculated)" />
											</Centered>
										</Col>
										<Col size={2}>
											<FormBlock>
												<Label htmlFor="team-estimation-salesTarget">
													<FormattedMessage id="Sales" /> €
												</Label>
												<Input
													id="team-estimation-salesTarget"
													block
													stretch
													disabled
													value={estimatedTeamSalesTarget}
												/>
											</FormBlock>
										</Col>
										<Col size={2}>
											<FormBlock>
												<Label htmlFor="team-estimation-offersTarget">
													<FormattedMessage id="Offers" /> <FormattedMessage id="pcs" />
												</Label>
												<Input
													id="team-estimation-offersTarget"
													block
													stretch
													disabled
													value={estimatedTeamOffersTarget}
												/>
											</FormBlock>
										</Col>
										<Col size={2}>
											<FormBlock>
												<Label htmlFor="team-estimation-visitsTarget">
													<FormattedMessage id="Visits" /> <FormattedMessage id="pcs" />
												</Label>
												<Input
													id="team-estimation-visitsTarget"
													block
													stretch
													disabled
													value={estimatedVisitsTarget}
												/>
											</FormBlock>
										</Col>
									</Row>
								</FieldSet>
							</LoaderOverlay>
						</Panel>
						<Panel maxWidth={screenSmMax}>
							<LoaderOverlay loading={dashboardTargetsLoading || processing}>
								<DashboardTargetsUsersForm
									onSubmit={createDashboardTarget}
									disabled={processing}
									initialValues={(activeTeam?.users ?? []).reduce((acc, user) => {
										const targetsForUser =
											(dashboardTargets ?? []).filter(t => t.userId === user.id) ?? [];
										const targets = TARGETS.reduce((acc, key) => {
											return {
												...acc,
												[key]: targetsForUser?.find(t => t.type === key)?.target ?? 0,
											};
										}, {});

										acc[encodeUserIdToFormInput(user.id)] = targets;
										return acc;
									}, {})}
									users={activeTeam?.users ?? []}
									targets={dashboardTargets}
								/>
							</LoaderOverlay>
						</Panel>
					</>
				)}
			</Content>
		</Page>
	);
};

export default compose(
	injectIntl,
	connect(
		applyState({
			user,
			activeOrganization,
			availableTeams,
			dashboardTargets,
			dashboardTargetsLoading,
			processing,
			activeTeam,
			dashboardTargetsQuery,
			initialized,
		}),
		applyDispatch({
			initialize,
			destroy,
			setQuery,
			createDashboardTarget,
		}),
	),
)(DashboardTargetsPage);
