import React from 'react';

const SvgArrowUp = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M11.71 5.883L6.885.4A1.32 1.32 0 0 0 6 0c-.335.017-.65.16-.884.4L.289 5.882a.707.707 0 1 0 1.061.934l3.725-4.233a.125.125 0 0 1 .218.083v8.628a.707.707 0 1 0 1.414 0V2.665a.125.125 0 0 1 .219-.083l3.724 4.233a.707.707 0 1 0 1.06-.933z"
		/>
	</svg>
);

export default SvgArrowUp;
