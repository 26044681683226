import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const postCalendarResourceReservation = id =>
	httpJson('post', `/calendarResource/${id}/reserve`, {}, {}).catch(
		describeThrow(
			intl.formatMessage({id: 'Failed to reserve appointment time, please try again'}),
		),
	);

export const postCalendarResourceRelease = id =>
	httpJson('post', `/calendarResource/${id}/release`, {}, {}).catch(
		describeThrow(intl.formatMessage({id: msgs.contentPostFailed})),
	);
