import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import {borderColorBase, fontSizeM} from 'styles/constants';

const Wrapper = styled.div`
	display: flex;
	min-height: ${({large}) => (large ? '80px;' : '53px;')};
	max-height: ${({large}) => (large ? '80px;' : '53px;')};
	width: 100%;
	background-color: #fff;
	border-top: 1px solid ${borderColorBase};
	overflow: hidden;

	> button {
		display: inline-flex;
		flex: 1;
		border-radius: 0;
		font-size: ${fontSizeM};
		text-align: center;

		> span {
			margin-top: auto;
			margin-bottom: auto;
			margin-left: auto;
			margin-right: auto;
		}
	}
`;

const TabNav = ({children, ...rest}) => <Wrapper {...rest}>{children}</Wrapper>;

TabNav.propTypes = {
	children: propTypes.node,
	size: propTypes.number,
};

export default styled(TabNav)``;
