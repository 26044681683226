import services from 'services';
import {map} from 'ramda';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';
import {capitalize} from 'utils/strings';
import {getResponseData} from 'utils/app';
import * as normalize from 'utils/normalize';
import {createFormData} from 'utils/http';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let httpForm = null;
services.waitFor('api').then(x => (httpForm = x.httpForm));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const getRoute = type => {
	if (type === 'tags') return '/tags';
	if (type === 'serviceMappings') return '/leadServiceMappings';

	return `/marketing${capitalize(type)}`;
};

const getInclude = type => {
	if (type === 'serviceMappings') return 'product,organization';
	return '';
};

const getQuery = type => {
	if (type === 'tags') return {type: 'marketingLead'};
	return {};
};

const formatItem = item => {
	if (item.type === 'tags') return {...item, type: 'marketingLead'};
	return item;
};

export const getSettings = ({type, trashed}) =>
	httpJson(
		'get',
		getRoute(type),
		{...getQuery(type), include: getInclude(type), trashed},
		{},
	)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.unwrapDatas)));

export const postSetting = item =>
	httpJson(
		'post',
		getRoute(item.type),
		{include: getInclude(item.type)},
		{body: formatItem(item)},
	)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentPostFailed})))
		.then(getResponseData(normalize.unwrapDatas));

export const putSetting = item =>
	httpJson(
		'put',
		`${getRoute(item.type)}/${item.id}`,
		{include: getInclude(item.type)},
		{body: formatItem(item)},
	)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentPostFailed})))
		.then(getResponseData(normalize.unwrapDatas));

export const deleteSetting = item =>
	httpJson('delete', `${getRoute(item.type)}/${item.id}`).catch(
		describeThrow(intl.formatMessage({id: msgs.contentPostFailed})),
	);

export const postRestoreSetting = ({type, id}) =>
	httpJson('post', `${getRoute(type)}/${id}/restore`, {}, {}).catch(
		describeThrow(intl.formatMessage({id: 'Failed to restore'})),
	);

export const getOrganizations = () =>
	httpJson('get', '/organizations', {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.organization)));

export const getProducts = () =>
	httpJson('get', '/products/all', {_limit: 999, include: 'organizations'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.product)));

export const postImportFacebookExcel = data =>
	httpForm('post', '/leads/importFacebookExcel', {}, {body: createFormData(data)})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentPostFailed})))
		.then(res => res.data);
