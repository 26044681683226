import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const fetchStats = creator('fetchStats');

export const setTimeSpan = creator('setTimeSpan');

export const getStatsByDate = creator('getStatsByDate', P.Number);

export const _setStatistics = creator('_setStatistics');

export const _setTeams = creator('_setTeams');
