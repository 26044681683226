import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const buildings = pipe(root, prop('buildings'));

export const clients = pipe(root, prop('clients'));

export const lead = pipe(root, prop('lead'));

export const leadFormInitialValues = pipe(root, prop('leadFormInitialValues'));

export const loadingLeadForm = pipe(root, prop('loadingLeadForm'));

export const loadingBuildings = pipe(root, prop('loadingBuildings'));

export const salesmanagers = pipe(root, prop('salesmanagers'));

export const processing = pipe(root, prop('processing'));

export const products = pipe(root, prop('products'));

export const organizations = pipe(root, prop('organizations'));

export const addBuildingModalOpen = pipe(root, prop('addBuildingModalOpen'));

export const addBuildingFormInitValues = pipe(root, prop('addBuildingFormInitValues'));

export const buildingToAdd = pipe(root, prop('buildingToAdd'));

export const mapModalOpen = pipe(root, prop('mapModalOpen'));
