import services from 'services';
import {prop, pipe} from 'ramda';
import {describeThrow} from 'utils/errors';
import {csvImport as normalizeCsvImport} from 'utils/normalize';
import msg from 'dicts/messages';

services.waitFor('api').then(x => (httpJson = x.httpJson));

let intl = null;
services.waitFor('intl').then(x => (intl = x));

let httpJson = null;
services.waitFor('httpJson').then(x => (httpJson = x));

const defaultIncludes = {
	include: 'user',
};

export const fetchCsvImport = id => {
	return httpJson('GET', `/imports/${id}`, defaultIncludes)
		.catch(
			describeThrow(
				intl.formatMessage({
					id: msg.saveFailed,
				}),
			),
		)
		.then(pipe(prop('data'), normalizeCsvImport));
};

export const updateCsvImport = (id, body) => {
	return httpJson('PUT', `/imports/${id}`, defaultIncludes, {body})
		.catch(
			describeThrow(
				intl.formatMessage({
					id: msg.saveFailed,
				}),
			),
		)
		.then(pipe(prop('data'), normalizeCsvImport));
};

export const removeCsvImport = id => {
	return httpJson('DELETE', `/imports/${id}`).catch(
		describeThrow(
			intl.formatMessage({
				id: msg.deleteFailed,
			}),
		),
	);
};

export const deleteImportedClients = id => {
	return httpJson('POST', `/imports/${id}/deleteClients`, defaultIncludes)
		.catch(
			describeThrow(
				intl.formatMessage({
					id: msg.deleteFailed,
				}),
			),
		)
		.then(pipe(prop('data'), normalizeCsvImport));
};
