import React from 'react';

const SvgMarker = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M6 0a4.756 4.756 0 0 0-4.75 4.75c0 3.274 3.269 6.244 4.27 7.076.278.231.681.231.959 0 1-.832 4.271-3.803 4.271-7.077A4.756 4.756 0 0 0 6 0zm0 7.25a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z"
		/>
	</svg>
);

export default SvgMarker;
