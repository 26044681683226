import React from 'react';
import propTypes from 'prop-types';
import Autosuggest from './Autosuggest';
import {autosuggestAreas} from 'io/geo';
import {describeThrow} from 'utils/errors';
import services from 'services';
import {decorateWithNotifications} from 'io/app';
import {catchNonFatalDefault} from 'io/errors';
import {debounce} from 'throttle-debounce';

let intl = null;
services.waitFor('intl').then(x => (intl = x));
let store = null;
services.waitFor('store').then(x => (store = x));

class PlacesAutosuggest extends React.Component {
	currentInput = '';

	fetchSuggestions = (input, cb) => {
		this.currentInput = input;

		// don't do anything if we have 1 - 2 input characters, to optimize API usage costs. this keeps the autosuggest unchanged, and possible subsequent inputs will take care of fetching data. you generally don't need to see anything if you just typed 2 characters or less, but we have extra handling for that too if the user stops typing at 2 characters.
		if (input.length > 0 && input.length < 3) {
			setTimeout(() => {
				// there's place names like "Ii" in finland, and more in sweden, but users usually should be searching for street names. we still add an extra timeout here to perform a search after several seconds if the input hasn't changed.
				if (this.currentInput === input) {
					autosuggestAreas({input, types: this.props.placeTypes})
						.then(suggestions => {
							cb(suggestions);
						})
						.catch(e => {
							// ignore errors, the component may have dismounted already
							console.warn(e);
						});
				}
			}, 1500);

			return;
		}

		decorateWithNotifications(
			{id: 'places-sugg-err'},
			autosuggestAreas({input, types: this.props.placeTypes}).catch(
				describeThrow(intl.formatMessage({id: 'Search failed'})),
			),
		)(store.getState, store.dispatch)
			.then(suggestions => {
				cb(suggestions);
			})
			.catch(catchNonFatalDefault(store.getState, store.dispatch));
	};
	fetchSuggestions = debounce(250, this.fetchSuggestions);

	render() {
		// alwaysRenderSuggestions isn't supported by the component
		const {alwaysRenderSuggestions, ...rest} = this.props;
		return (
			<Autosuggest
				{...rest}
				loadSuggestions={this.fetchSuggestions}
				getSuggestionValue={s => s.description}
			/>
		);
	}
}

PlacesAutosuggest.propTypes = {
	alwaysRenderSuggestions: propTypes.any,
	// see: https://developers.google.com/places/supported_types#table3
	// for supported place types, for example pass placeTypes=['geocode'] if you don't need business results
	placeTypes: propTypes.array,
};

export default PlacesAutosuggest;
