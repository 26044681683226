import createCache from 'services/createCache';

export default createCache({
	addBuildingMap: {
		map: null,
		buildingsLayer: null,
		mapLayer: null,
		markerLayer: null,
	},
});
