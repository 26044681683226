export const resolveObject = obj => {
	const keys = Object.keys(obj);
	// values should come in the same order as keys no matter the browser
	const promises = Object.values(obj);
	return Promise.all(promises).then(results => {
		const resolved = {};
		keys.forEach((k, i) => {
			resolved[k] = results[i];
		});
		return resolved;
	});
};
