import {action} from 'utils/redux';
import {P} from 'utils/types';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize', P.Number);

export const destroy = creator('destroy');

export const verifyRemoval = creator('verifyRemoval');

export const openEncounterModal = creator('openEncounterModal', P.Number);

export const closeEncounterModal = creator('closeEncounterModal');

export const saveComment = creator('saveComment');

export const removeComment = creator('removeComment', P.Number);

export const openCommentEditor = creator('openCommentEditor', P.Number);

export const removeRemovalRequest = creator('removeRemovalRequest');

export const _commentSaved = creator('_commentSaved');

export const _commentRemoved = creator('_commentRemoved');

export const _setRequest = creator('_setRequest', P.Object);

export const _updateRequest = creator('_updateRequest', P.Object);

export const _setEncounterData = creator('_setEncounterData');

export const _setClients = creator('_setClients', P.Array);

export const _startOp = creator('_startOp');

export const _opOk = creator('_opOk');

export const _opFailed = creator('_opFailed');
