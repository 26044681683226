import React from 'react';
import ReactDom from 'react-dom';
import {Provider as ReduxProvider} from 'react-redux';
import {Router} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {addLocaleData} from '@meiko/react-intl';
import App from 'views/App';
import IntlProvider from 'views/IntlProvider';
//import registerServiceWorker from 'io/registerServiceWorker';
import createStoreActionHook from 'services/createStoreActionHook';
import createStore from 'services/createStore';
import createApi from 'services/createApi';
import services from 'services';
import enLocale from '@meiko/react-intl/locale-data/en';
import fiLocale from '@meiko/react-intl/locale-data/fi';
import svLocale from '@meiko/react-intl/locale-data/sv';
import {handleUncaught} from 'io/errors';
import {initialize, logout} from 'modules/common/actions';
import {info} from 'modules/notifications/actions';
import {apiToken, activeOrganizationId} from 'modules/common/selectors';
import {medDur, longDur} from 'constants/notifications';
import {isApiTokenError} from 'utils/app';
import {formatMessageRaw} from 'io/app';
import {Chart} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import 'styles/global.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'styles/react-datepicker.css'; // datepicker custom styles

Chart.register(ChartDataLabels);
// Change default options for ALL charts
Chart.defaults.set('plugins.datalabels', {
	formatter: (value, context) => {
		if (value > 0) {
			return value;
		}
		return '';
	},
});

window.addEventListener('error', evt => handleUncaught(evt.error));
window.addEventListener('unhandledrejection', evt => handleUncaught(evt.reason));

const bugsnag = services.get('bugsnag');

// we handle bugsnag notifications manually from browser error handlers from now on
if (bugsnag) {
	window.stopBugsnagAutoNotify();
}

// TODO: first figure out how to bust its cache when updating app version
//registerServiceWorker();

addLocaleData(enLocale);
addLocaleData(fiLocale);
addLocaleData(svLocale);

const {instance: storeHook, enhancer: actionHookEnhancer} = createStoreActionHook();

const store = createStore({actionHookEnhancer});
services.set('store', store);

services.set('storeHook', storeHook);

if (bugsnag) {
	storeHook.subscribe((action, oldState, newState) => {
		bugsnag.leaveBreadcrumb('Redux action', {type: action.type});
	});
}

const onApiError = e => {
	if (isApiTokenError(e) && apiToken(store.getState())) {
		store.dispatch(logout());
		store.dispatch(
			info({
				id: 'api-401',
				message: formatMessageRaw('Session expired, new login required'),
				duration: medDur,
			}),
		);
	} else if (e.response && e.response.status === 503) {
		store.dispatch(
			info({
				id: 'api-503',
				message: formatMessageRaw(
					'The service is being updated. Please refresh the page in a few moments. The upgrade process may take a few minutes.',
				),
				duration: longDur,
			}),
		);
	}
};

const api = createApi({
	get: key => {
		const state = store.getState();
		return {
			apiToken: apiToken(state),
			organizationId: activeOrganizationId(state),
			onError: onApiError,
		}[key];
	},
});
services.set('api', api);

// delay starting redux a bit so that listeners have time to receive our newly-created services before anything else happens
setTimeout(() => store.dispatch(initialize()));

const queryClient = new QueryClient();

ReactDom.render(
	<QueryClientProvider client={queryClient}>
		<ReduxProvider store={store}>
			<IntlProvider>
				<Router history={services.get('history')}>
					<App />
				</Router>
			</IntlProvider>
		</ReduxProvider>
	</QueryClientProvider>,
	document.getElementById('root'),
);
