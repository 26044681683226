import React from 'react';

const SvgPlay = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M10.763 5.124L2.553.148A1.023 1.023 0 0 0 1 1.024v9.952a1.023 1.023 0 0 0 1.554.876l8.21-4.977a1.023 1.023 0 0 0 0-1.75z"
		/>
	</svg>
);

export default SvgPlay;
