export const formatFileFormInput = file => {
	if (!file) {
		return {};
	}

	return {
		fileCategoryId: file.fileCategoryId,
		description: file.description,
		organizationId: file.organizationId,
	};
};

export const formatCategoryFormInput = category => {
	if (!category) {
		return {};
	}

	return {
		title: category.title,
		roleIds: category.roles?.map(r => r.id) || [],
	};
};
