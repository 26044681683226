import React from 'react';

const SvgPerson = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M6.067 5.867a2.933 2.933 0 1 1 0-5.867 2.933 2.933 0 0 1 0 5.867zm0 .8a5.073 5.073 0 0 1 5.066 5.066c0 .148-.12.267-.266.267h-9.6A.267.267 0 0 1 1 11.733a5.073 5.073 0 0 1 5.067-5.066z"
		/>
	</svg>
);

export default SvgPerson;
