import services from 'services';
import {capitalize} from 'utils/strings';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const formatSearchFormInput = reportQuery => {
	return reportQuery;
};

export const formatChartLabels = (type = '', keys = []) => {
	switch (type) {
		case 'week':
			return keys.map(val => intl.formatMessage({id: 'wk {num}'}, {num: val}));
		case 'month':
			return keys.map(val => {
				const d = new Date();
				d.setMonth(Number(val) - 1);
				return capitalize(intl.formatDate(d, {month: 'long'}));
			});
		default:
			return keys;
	}
};

export const formatChartDataset = (datasetKey = '', data = {}) => {
	return Object.values(data).map(x => x[datasetKey] || 0);
};
