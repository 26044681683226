import {state as lifecycle} from 'fragments/lifecycle';
import createDatePickState from 'fragments/calendarResourcePicker/state';
import createBuildingModalState from 'fragments/buildingModalActions/state';
import createReminderState from 'fragments/callReminder/state';

const datePickState = createDatePickState({});
const buildingModalState = createBuildingModalState({});
const reminderState = createReminderState({});

export default {
	...lifecycle,
	...datePickState,
	...buildingModalState,
	...reminderState,
	processing: false,
	building: null,
	buildingLoading: true,
	productStates: null,
	encounters: [],
	encountersLoading: true,
	encounter: null,
	client: null,
	clientEditorOpen: false,
	clientFormInitValues: {},
	userTeams: [],
	userTeamsLoading: true,
	salesTeams: [],
	salesTeamsLoading: true,
	freeCalendarResourcesLoading: true,
	visitJustSaved: false,
	openedAt: null,
	salesVisitModalOpen: false,
	marketingLeadSources: [],
	products: [],
	availableTags: [],
	tags: [],
	processingTags: false,
	processingTag: false,
};
