export const buildingInclude =
	'buildingMetasKotifyEstate,buildingProductStates.product,allEncounters,allEncounters.organization,allEncounters.source.user,allBuildingProductStates.product,areas';

export const encounterInclude = [
	'building',
	'organization',
	'source.formFill.formAttributes',
	'source.calendarResource.client',
	'source.calendarResource.salesmanVisit',
	'source.calendarResource.salesman',
	'source.calendarResource.team',
	'source.user',
	'source.products',
	'source.canceler',
	'reasonMapping',
].join(',');

export const createEmptyClient = () => ({
	firstName: '',
	lastName: '',
	email: '',
	password: '',
	swedish: false,
	comments: '',
	title: '',
	address: '',
	zip: '',
	city: '',
});
