import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {formatAddAreaFormInput, formatTeamAreas} from './utils';
import * as perms from 'utils/perms';
import * as commonSelectors from 'modules/common/selectors';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const team = pipe(root, prop('team'));

export const teamAreas = createSelector([team], team => formatTeamAreas(team));

export const addAreaModalOpen = pipe(root, prop('addAreaModalOpen'));

export const selectedArea = pipe(root, prop('selectedArea'));

export const addAreaFormInitValues = createSelector([selectedArea], selectedArea =>
	formatAddAreaFormInput(selectedArea),
);

export const processing = pipe(root, prop('processing'));

export const showSalesmanAppTeamAreaTeamSelect = createSelector(
	[commonSelectors.user],
	user => (user ? perms.salesmanAppTeamAreaTeamSelect(user) : false),
);

export const teams = pipe(root, prop('teams'));

export const teamsLoading = pipe(root, prop('teamsLoading'));
