import React from 'react';

const SvgMonitorChart = props => (
	<svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M11.333 19.339C7.29 19.339 4 16.049 4 12.005s3.29-7.333 7.333-7.333c1.47 0 2.888.436 4.104 1.26.015.007.03.016.042.023a.356.356 0 0 1 .056.046 7.318 7.318 0 0 1 .536 11.593c-.014.014-.027.029-.039.038a.195.195 0 0 1-.032.023 7.32 7.32 0 0 1-4.667 1.684zM5.38 12.672a5.993 5.993 0 0 0 5.954 5.333 5.923 5.923 0 0 0 3.306-1.002l-3.618-4.331H5.38zm10.284 3.475a5.926 5.926 0 0 0 1.67-4.142 5.949 5.949 0 0 0-2.084-4.533l-3.08 4.493 3.494 4.182zm-4.68-4.808l3.168-4.622a5.963 5.963 0 0 0-2.818-.712A5.992 5.992 0 0 0 5.38 11.34h5.604zm11.017-4a.667.667 0 0 1 0-1.334h5.333a.667.667 0 0 1 0 1.334H22zm0 4a.667.667 0 0 1 0-1.334h5.333a.667.667 0 0 1 0 1.334H22zm0 4a.667.667 0 0 1 0-1.334h5.333a.667.667 0 0 1 0 1.334H22zM6.667 30.005a.667.667 0 0 1 0-1.333h8v-1.333h-12A2.67 2.67 0 0 1 0 24.672v-20a2.67 2.67 0 0 1 2.667-2.667h26.666A2.67 2.67 0 0 1 32 4.672v20a2.67 2.67 0 0 1-2.667 2.667H16v1.333h9.333a.667.667 0 0 1 0 1.333H6.667zm-5.334-5.333c0 .736.599 1.333 1.334 1.333h26.666c.736 0 1.334-.597 1.334-1.333v-2.667H1.333v2.667zm29.334-4v-16c0-.735-.598-1.333-1.334-1.333H2.667c-.735 0-1.334.598-1.334 1.333v16h29.334z"
		/>
	</svg>
);

export default SvgMonitorChart;
