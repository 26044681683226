// loaderFn: a no-arguments function that performs the loading of some static resource when called and returns a Promise that resolves when ready
// create a function that caches the loading of a resource so that loading is only performed on the initial call and all subsequent calls resolve as soon as the resource is available
export default loaderFn => {
	let loadingDone = false;
	let loadingInProgress = false;
	let loadResult = undefined;
	let loadCallbacks = [];

	const queueLoad = () =>
		new Promise((resolve, reject) => {
			loadCallbacks.push({resolve, reject});
		});

	const doLoad = () => {
		loadingInProgress = true;
		return loaderFn()
			.then(result => {
				loadingDone = true;
				loadResult = result;
				loadCallbacks.forEach(cs => cs.resolve(result));
				loadCallbacks = [];
				return result;
			})
			.catch(e => {
				loadingInProgress = false;
				loadCallbacks.forEach(cs => cs.reject(e));
				loadCallbacks = [];
				throw e;
			});
	};

	// prettier-ignore
	return () =>
		loadingDone ? Promise.resolve(loadResult)
		: loadingInProgress ? queueLoad()
		: doLoad();
};
