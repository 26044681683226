import services from 'services';
import {prop, pipe, map} from 'ramda';
import {dealsInclude, dealsQueryBase} from './constants';
import {describeThrow, describeError} from 'utils/errors';
import * as normalize from 'utils/normalize';
import {mapResponseData} from 'utils/app';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const updateTimeEntry = entry =>
	httpJson('put', `/timeEntries/${entry.id}`, {}, {body: entry})
		.catch(e => {
			const conflict = !!e.response && e.response.status === 500;
			e.causedByTimeEntryConflict = conflict;
			return conflict
				? e.response.json().then(body => describeThrow(body.message, e))
				: Promise.reject(describeError(msgs.saveFailed, e));
		})
		.then(pipe(prop('data'), normalize.timeEntry));

export const getUserTimeEntries = () =>
	httpJson('get', '/timeEntries/userEntriesToday', {})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load working hours'})))
		.then(pipe(prop('data'), normalize.timeEntry));

export const deleteTimeEntry = timeEntryId =>
	httpJson('delete', `/timeEntries/${timeEntryId}`)
		.catch(describeThrow(intl.formatMessage({id: 'Failed to delete working time'})))
		.then(prop('data'));

export const updateVisitTarget = target =>
	httpJson('put', `/targets/${target.id}`, {}, {body: target})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save goal'})))
		.then(prop('data'));

export const postVisitTarget = target =>
	httpJson('post', '/targets', {}, {body: target})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save goal'})))
		.then(prop('data'));

export const getDeals = query =>
	httpJson('get', '/calendarResource/completed', {
		...dealsQueryBase,
		include: dealsInclude,
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load sale statistics'})))
		.then(mapResponseData(map(normalize.calendarResource)));
