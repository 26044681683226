import {path, pipe, prop, pick} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {createSelector} from 'reselect';
import {formatCondosUrlQuery} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const processing = pipe(root, prop('processing'));

export const initialized = pipe(root, prop('initialized'));

export const areaCounties = pipe(root, prop('areaCounties'));

export const progressData = pipe(root, prop('progressData'));

export const condosQuery = pipe(root, prop('condosQuery'));

export const condosMeta = pipe(root, prop('condosMeta'));

export const currentLane = pipe(root, prop('currentLane'));

export const filteredList = pipe(root, prop('filteredList'));

export const loadingCondominiums = pipe(root, prop('loadingCondominiums'));

export const initFilterFormValues = createSelector([condosQuery], query => {
	return pick(
		[
			'address',
			'zip',
			'city',
			'county',
			'companyName',
			'manufacturingYearStart',
			'manufacturingYearEnd',
			'latestActivityUserId',
		],
		query,
	);
});
export const initSortFormValues = createSelector([condosQuery], query => {
	return {sort: query._sort};
});

export const urlQuery = createSelector([condosQuery], query => {
	return formatCondosUrlQuery(query);
});

export const users = pipe(root, prop('users'));
