// consider using a UA parsing library if this gets more complicated. for now this should suffice

const mobileIosRgx = /^[^(]*\((iPhone|iPad)/;

export const isMobileIos = () => {
	return navigator.userAgent.match(mobileIosRgx);
};

export const isSafari = () => {
	return navigator.userAgent.includes('Safari');
};

export const isFirefox = () => {
	return navigator.userAgent.includes('Firefox');
};

// chromium-based
export const isChromium = () => {
	return navigator.userAgent.includes('Chrome');
};

// actual mobile chromium is only available on android
export const isMobileChromium = () => {
	return navigator.userAgent.includes('Android') && isChromium();
};

export const isMobile = () => {
	return isMobileIos() || navigator.userAgent.includes('Android');
};
