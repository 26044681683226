import services from 'services';
import {map, prop} from 'ramda';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {mapResponseData, getResponseData} from 'utils/app';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getEvents = query =>
	httpJson('get', '/adminEvents', {include: 'user', ...query})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.adminEvent)));

export const putEvent = evt =>
	httpJson('put', `/adminEvents/${evt.id}`, {}, {body: evt})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.adminEvent));

export const searchUsers = query =>
	httpJson('get', '/users/search', query)
		.catch(describeThrow(intl.formatMessage({id: 'Search failed'})))
		.then(prop('data'));

export const getOptions = () =>
	httpJson('get', '/adminEventOptions', {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(prop('data'));
