import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {FormattedTime, FormattedMessage} from '@meiko/react-intl';
import Media from 'react-responsive';
import Button from 'components/generic/Button';
import {hrUnstyled} from 'styles/fragments';
import {borderColorBase, screenSmMin} from 'styles/constants';
import Icon from 'components/generic/Icon';
import TextButton from 'components/generic/TextButton';
import H2 from 'components/generic/H2';
import MutedText from 'components/generic/MutedText';

const navBorderStatic = `1px solid ${borderColorBase}`;

const secondsToHms = dur => {
	const _dur = dur ? Math.abs(dur) : 0;
	return new Date(null, null, null, null, null, _dur);
};

const StyledButton = styled(Button)`
	display: flex;
	justify-content: space-between;
	margin: ${({noMargins}) => (noMargins ? '' : '4px 0px')};
	padding: 6px 8px;
	border: 1px solid rgba(255, 255, 255, 30%);
`;

const MenuDivider = styled.hr`
	${hrUnstyled};
	margin: 0 -20px;
	border-top: ${navBorderStatic};
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const WorkTimeContainer = styled.span`
	padding: 8px 8px 8px 0px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	${({noTopPadding}) => (noTopPadding ? 'padding-top: 0;' : '')}
`;

const StyledTextButton = styled(TextButton)`
	padding: 8px 8px 8px 0px;
`;

const ButtonsWrapper = styled.span`
	margin: 4px 0;
	display: flex;
	flex-direction: column;
`;

const Text = styled.span`
	margin-right: auto;
`;

const TimerButtonText = type => {
	switch (type) {
		case 'working':
			return <FormattedMessage id="Working hours" />;
		case 'break':
			return <FormattedMessage id="Break" />;
		case 'lunch':
			return <FormattedMessage id="Lunch" />;
		default:
			return <FormattedMessage id="Total time" />;
	}
};

const TimerIconType = type => {
	switch (type) {
		case 'break':
			return 'coffee';
		case 'lunch':
			return 'lunch';
		default:
			return 'time';
	}
};

const TYPE_TOTAL = 'total';
const RunningTimerColor = type => {
	switch (type) {
		case 'working':
			return 'primary';
		case TYPE_TOTAL:
			return 'primary';
		case 'break':
			return 'warning';
		case 'lunch':
			return 'warning';
		default:
			return 'brand';
	}
};

const getWorkTime = ({type, userEntries = {}}) => {
	const lunchTime = userEntries.reasons['lunch'] ? userEntries.reasons['lunch'] : 0;
	const breakTime = userEntries.reasons['break'] ? userEntries.reasons['break'] : 0;
	const workingTime = userEntries.reasons['working'] ? userEntries.reasons['working'] : 0;

	switch (type) {
		case 'lunch':
			return lunchTime;
		case 'break':
			return breakTime;
		case 'working':
			return workingTime;
		default:
			return userEntries.totalTimeDay;
	}
};

const biggerThanMobile = `(min-width: ${screenSmMin})`;

export const TimerButton = ({
	activeTimerRunning,
	processingTimeEntries,
	userEntries,
	startTimer,
	buttonType,
	headerButton,
}) => {
	return (
		<StyledButton
			appearance={
				activeTimerRunning === buttonType || buttonType === TYPE_TOTAL
					? RunningTimerColor(buttonType)
					: 'brand'
			}
			disabled={processingTimeEntries}
			onClick={() =>
				processingTimeEntries || headerButton || activeTimerRunning === buttonType
					? null
					: startTimer(buttonType)
			}
			centerVerticallyInline
			noMargins={headerButton}
		>
			<Icon
				appearance="default"
				name={TimerIconType(buttonType)}
				width="12px"
				height="12px"
				smallRightSpacing
			/>
			{/* Hide text only from the header button when on smaller screen size */}
			{buttonType !== TYPE_TOTAL ? (
				<Text>{TimerButtonText(buttonType)}</Text>
			) : (
				<Media query={biggerThanMobile}>
					<Text>{TimerButtonText(buttonType)}</Text>
				</Media>
			)}
			&nbsp;
			<span>{getWorkTime({type: buttonType, userEntries}) < -59 ? '-' : ''}</span>
			<FormattedTime
				value={secondsToHms(getWorkTime({type: buttonType, userEntries}))}
				hour="numeric"
				minute="numeric"
			/>
		</StyledButton>
	);
};

export const TimeTrackerButtons = ({
	userEntries,
	activeTimerRunning,
	startTimer,
	stopTimer,
	processingTimeEntries,
	noTopPadding,
}) => (
	<ButtonContainer>
		<WorkTimeContainer noTopPadding={noTopPadding}>
			<MutedText>
				<FormattedMessage id="Total time" />
			</MutedText>
			<H2 noTopMargin noBottomMargin>
				{getWorkTime({type: '', userEntries}) < -59 ? '-' : ''}
				<FormattedTime
					value={secondsToHms(getWorkTime({type: '', userEntries}))}
					hour="numeric"
					minute="numeric"
				/>
			</H2>
		</WorkTimeContainer>
		<MenuDivider />
		<ButtonsWrapper>
			<TimerButton
				activeTimerRunning={activeTimerRunning}
				processingTimeEntries={processingTimeEntries}
				userEntries={userEntries}
				startTimer={startTimer}
				buttonType={'working'}
			/>
			<TimerButton
				activeTimerRunning={activeTimerRunning}
				processingTimeEntries={processingTimeEntries}
				userEntries={userEntries}
				startTimer={startTimer}
				buttonType={'lunch'}
			/>
			<TimerButton
				activeTimerRunning={activeTimerRunning}
				processingTimeEntries={processingTimeEntries}
				userEntries={userEntries}
				startTimer={startTimer}
				buttonType={'break'}
			/>
		</ButtonsWrapper>
		<MenuDivider />

		<StyledTextButton
			disabled={!activeTimerRunning || processingTimeEntries}
			onClick={stopTimer}
			centerVerticallyInline
		>
			<Icon name="close" height="12px" width="12px" smallRightSpacing />
			<FormattedMessage id="Stop work time entry" />
		</StyledTextButton>
	</ButtonContainer>
);

TimeTrackerButtons.propTypes = {
	userEntries: propTypes.object,
	activeTimerRunning: propTypes.string,
	startTimer: propTypes.func,
	stopTimer: propTypes.func,
	processingTimeEntries: propTypes.bool,
};

export default TimeTrackerButtons;
