import {state as lifecycle} from 'fragments/lifecycle';
import {localeCenterWeb, localeZoomFactor} from 'constants/maps';
import {distanceOptions} from './constants';

export default {
	...lifecycle,
	contactsQuery: {
		_page: 1,
		dismissPrompt: false,
		searchType: 'nearest',
		distance: distanceOptions[0].value,
		encounterState: '',
		hasPhone: '',
		productId: [],
		activeTab: 'list',
		z: localeZoomFactor * 5,
		x: localeCenterWeb[0],
		y: localeCenterWeb[1],
		selectionId: null,
		areaId: [],
		manufacturingYearStart: null,
		manufacturingYearEnd: null,
		organizationId: null,
		mapSource: 'here-streets',
		maxPrice: null,
		minPrice: null,
	},
	buildings: [],
	buildingsLoading: false,
	buildingsPagination: {
		currentPage: 1,
		totalPages: 1,
	},
	allBuildings: [],
	organizationProducts: [],
	allProducts: [],
	userTeams: [],
	addBonusModalOpen: false,
	bonusBuilding: null,
	processing: false,
	crossSalesCount: {
		currentWeek: 0,
		nextWeek: 0,
	},
	allAreas: [],
	allAreasLoading: true,
	showCrossSalesCount: false,
	userTeamsLoading: true,
	organizations: [],
};
