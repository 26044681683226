import {action} from 'utils/redux';
import namespace from './namespace';
import {P, Nullable, Record} from 'utils/types';
import createDatePickActions from 'fragments/calendarResourcePicker/actions';
import createBuildingModalActions from 'fragments/buildingModalActions/actions';
import createReminderActions from 'fragments/callReminder/actions';

const creator = action(namespace);

const datePickActions = createDatePickActions({namespace});

export const {
	selectCalendarResource,
	_calendarResourceSelected,
	_calendarResourceSelectionFailed,
	_calendarResourceReserved,
	_calendarResourceReleased,
} = datePickActions;

const buildingModalActions = createBuildingModalActions({namespace});

export const {
	createOtherAddress,
	setPrimaryAddress,
	removeOtherAddress,
	saveBuildingData,
	removeBuilding,
	toggleBuildingModal,
	_updateBuilding,
	_setBuildingModalProcessing,
} = buildingModalActions;

const reminderActions = createReminderActions({namespace});

export const {
	createCallReminder,
	createCalendarCallReminder,
	updateCallReminder,
	removeCallReminder,
	toggleCallReminderEditor,
	setReminderSeen,
	_setCalendarCallReminderSeen,
	_setCallReminder,
	_setCallReminderLoading,
	_setCallReminders,
	_setCallRemindersLoading,
	_setCalendarCallReminder,
} = reminderActions;

export const initialize = creator(
	'initialize',
	Record({buildingId: P.Number, allowAutoCall: P.Boolean}),
);

export const destroy = creator('destroy', P.Number);

export const _opFailed = creator('_opFailed');

export const _setBuilding = creator('_setBuilding');

export const _setEncounters = creator('_setEncounters', P.Array);

export const toggleClientEditor = creator('toggleClientEditor', Nullable(P.Object));

export const openEncounterModal = creator('openEncounterModal', P.Number);

export const _setEncounterData = creator('_setEncounterData', P.Object);

export const closeEncounterModal = creator('closeEncounterModal');

export const saveClient = creator('saveClient');

export const removeClient = creator('removeClient', P.Object);

export const _updateClients = creator('_updateClients');

export const _removeClient = creator('_removeClient', P.Number);

export const _setUserTeams = creator('_setUserTeams');

export const _setActiveCallPool = creator('_setActiveCallPool');

export const toggleNewDateCreator = creator('toggleNewDateCreator');

export const _setFreeCalRes = creator('_setFreeCalRes');

export const saveCall = creator('saveCall');

export const _setModalProcessing = creator('_setModalProcessing');

export const _reloadCallForm = creator('_reloadCallForm');

export const resetCallForm = creator('resetCallForm');

export const cancelEncounter = creator('cancelEncounter');

export const _setOpenedAt = creator('_setOpenedAt', P.Date);

export const changeBuilding = creator(
	'changeBuilding',
	Record({currentId: P.Number, nextId: P.Number}),
);

export const skip = creator('skip');

export const clearBuildingReservation = creator('clearBuildingReservation', P.String);

export const reserveBuilding = creator('reserveBuilding', P.String);

export const _reservationOk = creator('_reservationOk');

export const _setSalesTeams = creator('_setSalesTeams');

export const _encounterSaved = creator('_encounterSaved');

export const _clearCalls = creator('_clearCalls');

export const _setFetchingNextBuilding = creator('_setFetchingNextBuilding', P.Boolean);

export const telClient = creator('telClient', P.Object);

export const leaddeskCallClient = creator('leaddeskCallClient', P.Object);

export const hangupCall = creator('hangupCall');

export const _startedLeaddeskCall = creator('_startedLeaddeskCall');

export const _startedLeaddeskTalk = creator('_startedLeaddeskTalk');

export const _endedLeaddeskCall = creator('_endedLeaddeskCall');

export const setClientSwedish = creator('setClientSwedish', P.Object);

export const _setProcessing = creator('_setProcessing', P.Boolean);

export const callNotReached = creator('callNotReached');

export const _setSurvey = creator('_setSurvey', P.Object);

export const _setSurveyCallReview = creator('_setSurveyCallReview', P.Object);

export const _setLead = creator('_setLead', P.Object);

export const _setMarketingLeadSources = creator('_setMarketingLeadSources');

export const _setProducts = creator('_setProducts', P.Array);

export const refreshFreeCalendarResources = creator('refreshFreeCalendarResources');

export const _setFreeCalResLoading = creator('_setFreeCalResLoading', P.Boolean);

export const _setAvailableTags = creator('_setAvailableTags', P.Array);

export const getTags = creator('getTags', P.Number);

export const _getTags = creator('_getTags', P.Array);

export const attachTag = creator('attachTag', P.Object);

export const _attachTag = creator('_attachTag');

export const detachTag = creator('detachTag', P.Object);

export const _detachTag = creator('_detachTag');

export const setLeaveCallPoolAfterEncountedSaved = creator(
	'setLeaveCallPoolAfterEncountedSaved',
	P.Boolean,
);
