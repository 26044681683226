import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';
import {pipe, prop, map} from 'ramda';
import * as normalize from 'utils/normalize';
// import {user} from 'modules/common/selectors';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getCondosData = query =>
	httpJson('get', `/condominiums`, {
		checkUserRole: 'sales',
		groupBy: 'buildingState',
		...query,
	}).catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})));

export const getAdditionalInfos = () =>
	httpJson('get', `/activities/getAdditionalInfos`).catch(
		describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})),
	);

export const getProjectSalesmen = () =>
	httpJson('get', '/users', {
		organizationId: 5,
		type: 'projectsalesman',
		_limit: '999',
		_sort: 'lastName',
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), map(normalize.user)));
