import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {formatUrlQuery, formatFetchableQuery} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const processing = pipe(root, prop('processing'));

export const feedback = pipe(root, prop('feedback'));

export const feedbackLoading = pipe(root, prop('feedbackLoading'));

export const feedbackPagination = pipe(root, prop('feedbackPagination'));

export const feedbackQuery = pipe(root, prop('feedbackQuery'));

export const feedbackQueryFetchable = createSelector(feedbackQuery, feedbackQuery =>
	formatFetchableQuery(feedbackQuery),
);

export const urlQuery = createSelector(feedbackQuery, feedbackQuery =>
	formatUrlQuery(feedbackQuery),
);
