import {pipeHandlers} from 'utils/reduxEffects';
import common from './common/handler';
import condoPage from './condoPage/handler';
import dashboardPage from './dashboardPage/handler';
import mapPage from './mapPage/handler';
import listViewPage from './listViewPage/handler';
import trackingPage from './trackingPage/handler';
import customerAcqTrackingPage from './customerAcqTrackingPage/handler';
import activityReportPage from './activityReportPage/handler';
import activityChartPage from './activityChartPage/handler';

export default pipeHandlers(
	common,
	condoPage,
	dashboardPage,
	mapPage,
	listViewPage,
	trackingPage,
	customerAcqTrackingPage,
	activityReportPage,
	activityChartPage,
);
