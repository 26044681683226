import services from 'services';
import {pipe, prop} from 'ramda';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';
import {getResponseData} from 'utils/app';
import * as normalize from 'utils/normalize';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getOrganizations = () =>
	httpJson('get', '/organizations')
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getUsersByAccountID = id =>
	httpJson('get', '/organization')
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getUsers = () =>
	httpJson('get', '/users')
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

// NOTE: almost duplicate of searchCallLogs src/modules/common/io.js
export const searchCallLogs = query =>
	httpJson('get', `/callLogs/tracking${query}`)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getCallLogs = dateQuery =>
	httpJson('get', '/callLogs', {include: 'organization'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getTeams = q =>
	httpJson('get', '/teams', {
		_limit: 999,
		include: 'users',
		...q,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(normalize.team));

export const fetchTeamWithUsers = teamId =>
	httpJson('get', `/teams/${teamId}`, {include: 'users'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const fetchTeamsWithUsers = teamIds =>
	httpJson('get', `/teams/`, {teamIds, include: 'users'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getCityOptions = () =>
	httpJson('get', '/areas', {
		type: 'city',
		select: 'title',
		withoutTransform: true,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getCalls = (q = {}) =>
	httpJson('get', '/calls', {
		...q,
		withSummary: true,
		include: 'client,user,building,encounter,encounter.reasonMapping',
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(res => {
			const {meta} = res;
			const data = getResponseData(normalize.call)(res);
			return {
				data,
				meta,
			};
		});

const salesmanVisitIncludes = [
	'client',
	'user',
	'building',
	'encounter',
	'encounter.reasonMapping',
	'calendarResource',
	'calendarResource.salesman',
	'calendarResource.reserver',
].join(',');
export const getSalesmanVisits = (q = {}) =>
	httpJson('get', '/salesmanVisit', {
		...q,
		withSummary: true,
		include: salesmanVisitIncludes,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(res => {
			const {meta} = res;
			const data = getResponseData(normalize.visit)(res);
			return {
				data,
				meta,
			};
		});

const visitIncludes = [
	'client',
	'user',
	'building',
	'encounter',
	'encounter.reasonMapping',
	'calendarResource',
	'calendarResource.salesman',
	'calendarResource.reserver',
	'calendarResource.client',
].join(',');
export const getVisits = (q = {}) =>
	httpJson('get', '/visits', {
		...q,
		withSummary: true,
		include: visitIncludes,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(res => {
			const {meta} = res;
			const data = getResponseData(normalize.visit)(res);
			return {
				data,
				meta,
			};
		});
