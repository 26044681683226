import {defaultColors} from 'constants/app';

export const setBgColor = color => {
	return `background-color: ${color || defaultColors.bg};`;
};
export const setTextColor = color => {
	return `color: ${color || defaultColors.text};`;
};
export const setBgColorHover = color => {
	return `background-color: ${color || defaultColors.bgHover};`;
};
export const setTextColorHover = color => {
	return `color: ${color || defaultColors.textHover};`;
};
export const setBorderColor = color => {
	return `1px solid ${color || defaultColors.border};`;
};
