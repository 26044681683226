import namespace from './namespace';
import {effect} from 'utils/redux';
import {P, Record, Any} from 'utils/types';
import * as actions from './actions';

const creator = effect(namespace);

let timeouts = {};

export let removeAfter = ({id, timeout}) => (getState, dispatch) => {
	if (timeouts[id]) {
		clearInterval(timeouts[id]);
	}
	const timeoutId = setTimeout(() => {
		delete timeouts[id];
		dispatch(actions._remove(id));
	}, timeout);
	timeouts[id] = timeoutId;
};
removeAfter = creator('removeAfter', removeAfter, Record({id: Any, timeout: P.Number}));
