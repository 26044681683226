import {curry} from 'ramda';
import {sort as timsort} from 'timsort';

export const sort = curry((cmp, arr) => {
	let res = arr.slice(0);
	timsort(res, cmp);
	return res;
});

export const reverse = arr => {
	let res = arr.slice(0);
	res.reverse();
	return res;
};

export const chainComparators = (...comparators) => (a, b) => {
	let ord = 0;
	let i = 0;
	while (!ord && i < comparators.length) {
		ord = comparators[i++](a, b);
	}
	return ord;
};

export const updateWhere = curry((pred, fn, arr) => {
	let resArr = arr.slice(0);
	for (let i = 0; i < resArr.length; ++i) {
		if (pred(resArr[i])) {
			resArr[i] = fn(resArr[i]);
		}
	}
	return resArr;
});

export const setWhere = curry((pred, val, arr) => updateWhere(pred, () => val, arr));

export const toLookup = curry((getKey, getValue, arr) => {
	let obj = {};
	arr.forEach(item => {
		obj[getKey(item)] = getValue(item);
	});
	return obj;
});

export const removeNulls = arr => {
	const newArr = [];
	arr.forEach(val => {
		if (val != null) {
			newArr.push(val);
		}
	});
	return newArr;
};
