import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const processing = pipe(root, prop('processing'));

export const dashboard = pipe(root, prop('dashboard'));

export const teams = pipe(root, prop('teams'));

export const unacknowledgedVisitsCount = pipe(root, prop('unacknowledgedVisitsCount'));
