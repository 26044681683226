import {effect} from 'utils/redux';
import {catchNonFatalDefault} from 'io/errors';
import namespace from './namespace';
import * as actions from './actions';
import * as commonSelectors from 'modules/common/selectors';
import {appName} from '../constants';
import {ensureAccess, setPageTitleMessage} from 'io/app';

const creator = effect(namespace);

export let initialize = () => (getState, dispatch) => {
	setPageTitleMessage('Control Center [app]')(getState, dispatch);
	ensureAccess(appName, commonSelectors.user(getState()))(getState, dispatch)
		.then(() => {
			dispatch(actions._initialize());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);
