import React from 'react';

const SvgBuilding = props => (
	<svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M.64 28.8a.641.641 0 0 1 0-1.28h1.92v-9.68l-1.682.674a.635.635 0 0 1-.832-.356.64.64 0 0 1 .356-.832l12.398-4.96V5.388l-1.617-1.005a.637.637 0 0 1 .337-1.184c.119 0 .237.033.338.097l11.182 6.94V7.04c0-.705.573-1.28 1.28-1.28h1.28c.707 0 1.28.575 1.28 1.28v5.58l3.538 2.196a.63.63 0 0 1 .285.398.638.638 0 0 1-.961.69l-1.582-.983V27.52h1.92a.64.64 0 0 1 0 1.28H.64zm26.24-1.28V14.126l-3.446-2.139a.606.606 0 0 1-.18-.112L14.08 6.18V27.52h1.28v-3.84c0-.707.573-1.28 1.28-1.28h7.68c.707 0 1.28.573 1.28 1.28v3.84h1.28zm-2.56 0v-3.84h-2.56v3.84h2.56zm-7.68 0h3.84v-3.84h-3.84v3.84zm-3.84 0V13.745l-8.96 3.584V27.52h1.28V22.4c0-.707.575-1.28 1.28-1.28h3.84c.705 0 1.28.573 1.28 1.28v5.12h1.28zm-6.4 0h3.84V22.4H6.4v5.12zm19.2-15.694V7.04h-1.28v3.991l1.28.795zm-6.399 6.094a2.563 2.563 0 0 1-2.56-2.56 2.563 2.563 0 0 1 2.56-2.56 2.563 2.563 0 0 1 2.56 2.56 2.563 2.563 0 0 1-2.56 2.56zm0-3.84c-.706 0-1.28.575-1.28 1.28 0 .705.574 1.28 1.28 1.28.707 0 1.28-.575 1.28-1.28 0-.705-.573-1.28-1.28-1.28z"
		/>
	</svg>
);

export default SvgBuilding;
