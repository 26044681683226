import {path, pipe, prop, pick} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {createSelector} from 'reselect';
import {formatCondosUrlQuery} from './utils';
import {over} from 'utils/lenses';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const processing = pipe(root, prop('processing'));

export const initialized = pipe(root, prop('initialized'));

export const progressData = pipe(root, prop('progressData'));

export const currentLane = pipe(root, prop('currentLane'));

export const condosQuery = pipe(root, prop('condosQuery'));

export const initFilterFormValues = createSelector([condosQuery], query => {
	return pick(
		[
			'fullAddress',
			'companyName',
			'manufacturingYearStart',
			'manufacturingYearEnd',
			'userId',
		],
		query,
	);
});
export const initSortFormValues = createSelector([condosQuery], query => {
	return {sort: query._sort};
});
export const initLaneFilterFormValues = createSelector([condosQuery], query => {
	// see "utils.js"
	return over(
		['offer'],
		filters => (filters && filters.state ? {additionalInfoId: filters.state} : filters),
		query._stageFilters,
	);
});

export const urlQuery = createSelector([condosQuery], query => {
	return formatCondosUrlQuery(query);
});

export const condosMeta = pipe(root, prop('condosMeta'));

export const offerTotalSum = pipe(root, prop('offerTotalSum'));

export const conclusionTotalSum = pipe(root, prop('conclusionTotalSum'));

export const additionalInfos = pipe(root, prop('additionalInfos'));

export const salesmen = pipe(root, prop('salesmen'));
