import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const csvImport = pipe(root, prop('csvImport'));

export const loading = pipe(root, prop('loading'));

export const isEditModalOpen = pipe(root, prop('isEditModalOpen'));
