import {useEffect} from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 *  If you have elements inside this container which you don't want to trigger the callback, you can use event.stopPropagation() on the click event of those elements.
 *
 * https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 *
 *
 * Usage:
 *
 * export default function OutsideAlerter(props) {
 *	const wrapperRef = useRef(null);
 *	useOutsideAlerter(wrapperRef);
 *
 *	return <div ref={wrapperRef}>{props.children}</div>;
 * }
 *
 */
function useClickOutsideComponent(ref, callback = () => null) {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				callback(event);
			}
		}
		// Bind the event listener
		document.addEventListener('click', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('click', handleClickOutside);
		};
	}, [ref]);
}

export default useClickOutsideComponent;
