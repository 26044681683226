import {path, pipe} from 'ramda';
import namespace from './namespace';
import cache from './cache';

const root = path(namespace);

export const opts = pipe(root, state => {
	const {onCancel, onOk} = cache.read();
	return {...state, showCancel: !!onCancel, showOk: !!onOk};
});
