import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	processing: false,
	organizationTargets: {
		organizationSalesPrice: null,
	},
	organizationTargetsLoading: true,
	organizationTargetsQuery: {
		date: new Date().toISOString().split('T')[0].slice(0, -3),
	},
};
