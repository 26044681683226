import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import {getFormValues} from 'redux-form';
import {replaceQuery, pushQuery} from 'io/history';
import removeUnassignedProperties from 'utils/remove-unassigned-props';
import {mergeLeft, always, isEmpty} from 'ramda';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, effects.destroy()];
		}

		case actions.recheckQuery.type: {
			const newState = {
				...state,
				processing: true,
				clientsLoading: true,
			};

			return [newState, effects.requestingBuildingsByQuery()];
		}

		case actions.changePage.type: {
			const newState = {
				...state,
				clientsLoading: true,
				clientsQuery: {...state.clientsQuery, _page: payload},
			};

			pushQuery(
				mergeLeft(removeUnassignedProperties({...state.buildingsQuery, _page: payload})),
			);

			return [newState, null];
		}

		case actions.searchClients.type: {
			const values = getFormValues('clientsFilterForm')(fullState) || {};

			const newQuery = {
				...state.clientsQuery,
				...values,
				_page: 1,
			};

			replaceQuery(always(removeUnassignedProperties(newQuery)));

			return [
				{
					...state,
					clientsQuery: newQuery,
				},
				null,
			];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false, buildingsLoading: false}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false}, null];
		}

		case actions._updateMetaData.type: {
			const newQuery = {
				...state.clientsQuery,
				...payload,
			};

			return [
				{
					...state,
					clientsQuery: newQuery,
					clientsLoading: !isEmpty(payload) ? true : false,
					processing: !isEmpty(payload) ? true : false,
				},
				!isEmpty(payload) ? effects.requestingBuildingsByQuery() : null,
			];
		}

		case actions._setClients.type: {
			const newState = {
				...state,
				clients: payload.data,
				clientsPagination: payload.pagination,
				clientsLoading: false,
				processing: false,
			};

			return [newState, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
