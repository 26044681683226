import React from 'react';
import {connect} from 'react-redux';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {applyState, applyDispatch} from 'utils/redux';
import {opts} from 'modules/confirmer/selectors';
import {ok, cancel} from 'modules/confirmer/actions';
import Button from 'components/generic/Button';
import Modal from 'components/generic/Modal';
import ModalHeaderTitle from 'components/generic/ModalHeaderTitle';

const Messages = styled.div`
	display: flex;
	flex-direction: column;
`;

class Confirmer extends React.Component {
	render() {
		const {opts, ok, cancel} = this.props;
		const {show, showCancel, showOk, cancelText, okText, title, message, messages} = opts;

		if (!show) {
			return null;
		}

		return (
			<Modal
				verticalPadding
				maxWidth="600px"
				headerTitle={<ModalHeaderTitle>{title}</ModalHeaderTitle>}
				cancelButton={
					showCancel && (
						<Button id="modal-cancel-button" onClick={() => cancel()}>
							{cancelText}
						</Button>
					)
				}
				confirmButton={
					showOk && (
						<Button
							id="modal-confirm-button"
							data-ti="confirmer-ok-btn"
							appearance="success"
							onClick={() => ok()}
						>
							{okText}
						</Button>
					)
				}
			>
				{messages ? (
					<Messages>
						{messages.map((m, idx) => (
							<span key={idx}>{m}</span>
						))}
					</Messages>
				) : (
					message
				)}
			</Modal>
		);
	}
}

Confirmer.propTypes = {
	opts: propTypes.object.isRequired,
	ok: propTypes.func,
	cancel: propTypes.func,
};

export default connect(applyState({opts}), applyDispatch({ok, cancel}))(Confirmer);
