import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import PageLoader from 'components/generic/PageLoader';

const Wrapper = styled.div`
	padding-top: 40px;
	text-align: center;
`;

const LoadingPage = ({message, ...rest}) => (
	<Wrapper {...rest}>
		<PageLoader message={message} />
	</Wrapper>
);

LoadingPage.propTypes = {message: propTypes.node};

export default LoadingPage;
