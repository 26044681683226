export const clientsInclude = 'building,ban';

export const clientsFiltersKeys = [
	'search',
	'firstName',
	'lastName',
	'phone',
	'street',
	'streetNumber',
	'zip',
	'city',
];

export const emptyClientsFilters = {
	search: '',
	firstName: '',
	lastName: '',
	phone: '',
	street: '',
	streetNumber: null,
	zip: '',
	city: '',
};
