import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {createSelector} from 'reselect';
import {createEmptyRole} from './constants';
import {formatRoleFormInput} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const processing = pipe(root, prop('processing'));

export const rolesLoading = pipe(root, prop('rolesLoading'));

export const roleEditorOpen = pipe(root, prop('roleEditorOpen'));

export const selectedRole = pipe(root, prop('selectedRole'));

export const permissions = pipe(root, prop('permissions'));

export const appPermissions = pipe(root, prop('appPermissions'));

export const otherPermissions = pipe(root, prop('otherPermissions'));

export const roles = pipe(root, prop('roles'));

export const rolesQueryRaw = pipe(root, prop('rolesQuery'));

export const rolesQueryFetchable = createSelector([rolesQueryRaw], raw => {
	return {
		...raw,
	};
});

export const roleFormInitValues = createSelector(
	[selectedRole, permissions],
	(selectedRole, permissions) =>
		formatRoleFormInput({
			role: selectedRole || createEmptyRole(),
			permissions,
		}),
);
