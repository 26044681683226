import React from 'react';

const SvgChevronUp = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M6 2.538c.305 0 .596.131.798.36l4.528 5.147a.852.852 0 1 1-1.28 1.125l-3.96-4.5a.115.115 0 0 0-.173 0l-3.96 4.501a.852.852 0 1 1-1.28-1.125L5.202 2.9c.202-.23.493-.361.799-.362z"
		/>
	</svg>
);

export default SvgChevronUp;
