export const formatOrganizationTargetFormInput = ({organizationTargets, date}) => {
	const {organizationSalesPrice} = organizationTargets;

	return {
		date,
		organizationSalesPrice: organizationSalesPrice ? organizationSalesPrice.target : null,
	};
};

export const formatOrganizationTargetFormOutput = ({form, organizationId}) => {
	const targets = [];
	const [year, month] = form.date.split('-');

	targets.push({
		type: 'salesPrice',
		timespan: 'month',
		target: form.organizationSalesPrice || 0,
		year,
		month,
		organizationId,
	});

	return targets;
};

export const isValidDate = date => {
	const dateFormat = /([12]\d{3}-(0[1-9]|1[0-2]))/;
	return dateFormat.test(date);
};
