import {action} from 'utils/redux';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const recheckQuery = creator('recheckQuery');

export const changePage = creator('changePage');

export const searchClients = creator('searchClients');

export const _initialize = creator('_initialize');

export const _startOp = creator('_startOp');

export const _opOk = creator('_opOk');

export const _opFailed = creator('_opFailed');

export const _updateMetaData = creator('_updateMetaData');

export const _setClients = creator('_setClients');
