import {action} from 'utils/redux';
import {P, Nullable, PaginatedData} from 'utils/types';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const changePage = creator('changePage', P.Number);

export const setShowDeleted = creator('setShowDeleted', P.Boolean);

export const initUserEditor = creator('initUserEditor', Nullable(P.Object));

export const cancelUserEditor = creator('cancelUserEditor');

export const cancelExcelEditor = creator('cancelExcelEditor');

export const openExcelEditor = creator('openExcelEditor');

export const saveUser = creator('saveUser');

export const reviveUser = creator('reviveUser');

export const deleteUser = creator('deleteUser');

export const searchUsers = creator('searchUsers');

export const clearForm = creator('clearForm', P.String);

export const getExcel = creator('getExcel');

export const _userRevived = creator('_userRevived');

export const _newUserSaved = creator('_newUserSaved');

export const _updateUsersQuery = creator('_updateUsersQuery', P.Object);

export const _setUsers = creator('_setUsers', PaginatedData(P.Array));

export const _setUserTeams = creator('_setUserTeams', P.Array);

export const _setInitMeta = creator('_setInitMeta', P.Array);

export const _toggleUserCreator = creator('_toggleUserCreator');

export const _initialize = creator('_initialize');

export const _startOp = creator('_startOp');

export const _opOk = creator('_opOk');

export const _opFailed = creator('_opFailed');

export const recheckQuery = creator('recheckQuery');

export const createEniocallerDetails = creator('createEniocallerDetails', P.Object);

export const deleteEniocallerDetails = creator('deleteEniocallerDetails', P.Object);

export const _updateEnioDetails = creator('_updateEnioDetails', P.Object);
