import * as React from 'react';

const File = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			width="1em"
			height="1em"
			{...props}
		>
			<g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
				<path
					d="M28.571 28.571a2.286 2.286 0 01-2.285 2.286H5.714a2.286 2.286 0 01-2.285-2.286V3.43a2.286 2.286 0 012.285-2.286h11.429L28.57 12.57z"
					strokeWidth={2.28571}
				/>
				<path d="M17.143 1.143V12.57H28.57" strokeWidth={2.28571} />
			</g>
		</svg>
	);
};

export default File;
