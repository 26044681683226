import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {formatUrlQuery, formatFetchableQuery} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const logsQuery = pipe(root, prop('logsQuery'));

export const logsQueryFetchable = createSelector(logsQuery, q => formatFetchableQuery(q));

export const urlQuery = createSelector(logsQuery, q => formatUrlQuery(q));

export const logModel = createSelector(logsQuery, q => q.logModel);

export const logs = pipe(root, prop('logs'));

export const logsPagination = pipe(root, prop('logsPagination'));

export const logsLoading = pipe(root, prop('logsLoading'));

export const fetchingMore = pipe(root, prop('fetchingMore'));

export const initialized = pipe(root, prop('initialized'));

export const organizations = pipe(root, prop('organizations'));

export const teams = pipe(root, prop('teams'));
