import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const navigateToBuilding = creator('navigateToBuilding', P.Number);

export const searchBuildings = creator('searchBuildings');

export const startCalling = creator('startCalling');

export const _initialize = creator('_initialize');

export const _setBuildings = creator('_setBuildings');

export const _opOk = creator('_opOk');

export const _startOp = creator('_startOp');

export const _opFailed = creator('_opFailed');
