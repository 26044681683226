import {effect} from 'utils/redux';
import namespace from './namespace';
import * as actions from './actions';
import {decorateWithNotifications, setPageTitleMessage} from 'io/app';
import {getSmsLogs as getSmsLogsIO, getUsers} from './io';
import {catchNonFatalDefault} from 'io/errors';

const creator = effect(namespace);

export let initialize = () => (getState, dispatch) => {
	setPageTitleMessage('Sms logs [app]')(getState, dispatch);
	getUsers({
		_limit: '999',
	}).then(({data}) => {
		dispatch(actions._initialize());
		dispatch(actions._getUsers(data));
	});
};
initialize = creator('initialize', initialize);

export let getSmsLogs = q => (getState, dispatch) => {
	return decorateWithNotifications(
		{
			id: 'fetch-sms-logs',
			failureStyle: 'error',
		},
		getSmsLogsIO(q),
	)(getState, dispatch)
		.then(data => {
			dispatch(actions._getSmsLogs(data));
		})
		.catch(catchNonFatalDefault);
};

getSmsLogs = creator('getSmsLogs', getSmsLogs);
