import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const openTarget = creator('openTarget', P.String);

export const saveCallTarget = creator('saveCallTarget');

export const startCalling = creator('startCalling');

export const _setCallReminders = creator('_setCallReminders', P.Array);

export const _setStats = creator('_setStats', P.Object);

export const _setTargets = creator('_setTargets', P.Object);

export const _updateTargets = creator('_updateTargets');

export const _setActiveCallPool = creator('_setActiveCallPool', P.Object);

export const _startCallingFailed = creator('_startCallingFailed');

export const _setActiveCallPoolLoadFailed = creator(
	'_setActiveCallPoolLoadFailed',
	P.Boolean,
);
