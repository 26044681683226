import React, {useEffect, useMemo} from 'react';
import {FormattedMessage} from '@meiko/react-intl';
import Field from 'components/generic/Field';
import FieldError from 'components/generic/FieldError';
import FormBlock from 'components/generic/FormBlock';
import Label from 'components/generic/Label';
import ReactSelect, {components} from 'components/generic/ReactSelect';

const MultiValueContainer = props => {
	const {selectProps, data} = props;
	const {value: currentValue} = selectProps;
	const isFirst = currentValue.findIndex(option => option.value === data.value) === 0;

	return isFirst ? (
		<div>
			<components.MultiValueContainer {...props} />
		</div>
	) : null;
};

const MultiValueLabel = props => {
	const {selectProps, data} = props;
	const {value: currentValue} = selectProps;
	const isFirst = currentValue.findIndex(option => option.value === data.value) === 0;

	return isFirst ? (
		<div content={'Customise your multi-value label component!'}>
			<components.MultiValueLabel {...props}>
				+{currentValue.length}
			</components.MultiValueLabel>
		</div>
	) : null;
};

const MultiValueRemove = () => {
	return null;
};

const TagIdsFormlet = ({
	availableTags = [],
	getAvailableTags = () => null,
	// The current value as comma separated string
	currentValue = '',
	labelPosition = 'top', // top, bottom, left, right
	menuPlacement = 'bottom',
	concise = true,
}) => {
	const formatTagOptions = tags => tags.map(tag => ({value: tag.id, label: tag.name}));
	const defaultTagOptions = formatTagOptions(availableTags);
	const tagPromiseOptions = inputValue =>
		new Promise(resolve => {
			if (availableTags.length > 0) {
				resolve(
					formatTagOptions(
						availableTags.filter(tag => {
							const tagName = (tag?.name ?? '').toLowerCase();
							return tagName.includes(inputValue.toLowerCase());
						}),
					),
				);
			} else {
				// NOTE: we are a bit 'misusing' this promise functionality, ideally we should not fetch and return the results immediately.
				// This however will require us to call io-methods directly (without passing actions through redux-module). It is a viable option, but as the rest of the code uses redux-store we want to enforce the same pattern.
				// So, we'll immediately resolve the promise but also trigger action which fetches the tags. After the tags have been fetched, they will be available for the select-component via the `defaultTagOptions`.
				getAvailableTags();
				resolve([]);
			}
		});

	const selectedTagIds = useMemo(
		() =>
			(currentValue ?? '')
				.toString()
				.trim()
				.split(',')
				.filter(v => !!v)
				.map(v => Number(v)),
		[currentValue],
	);

	useEffect(() => {
		// Fetch available tags in case the tag has default selection
		if (selectedTagIds.length > 0 && availableTags.length === 0) {
			getAvailableTags();
		}
	}, [selectedTagIds.length, availableTags.length]);

	const componentProps = concise
		? {
				MultiValueContainer,
				MultiValueLabel,
				MultiValueRemove,
		  }
		: {};
	return (
		<>
			{labelPosition === 'left' && (
				<Label>
					<FormattedMessage id="Tags" />
				</Label>
			)}
			<Field
				name="tagIds"
				props={{options: tagPromiseOptions, defaultOptions: defaultTagOptions}}
				// The select field accepts (and outputs input) as comma separated value string. This enables us to directly pick the value from query string
				format={value => {
					return (value ?? '')
						.toString()
						.split(',')
						.filter(v => !!v)
						.map(id => Number(id))
						.map(id => ({
							value: id,
							label: availableTags.find(tag => tag.id === id)?.name ?? '',
						}));
				}}
				parse={value => {
					return [...new Set(value.map(v => (v.value ? v.value : v)))].join(',');
				}}
				component={({input, inputId, meta, options, defaultOptions}) => (
					<FormBlock>
						{labelPosition === 'top' && (
							<Label htmlFor={inputId}>
								<FormattedMessage id="Tags" />
							</Label>
						)}
						<ReactSelect
							{...input}
							minWidth="150px"
							id={inputId}
							async
							block
							isMulti
							defaultOptions={defaultOptions}
							loadOptions={options}
							closeMenuOnSelect={false}
							menuPlacement={menuPlacement}
							hideSelectedOptions={false}
							components={componentProps}
							onFocus={() => {
								// Fetch tags on focus
								if (availableTags.length === 0) {
									getAvailableTags();
								}
							}}
						/>
						<FieldError {...meta} />
					</FormBlock>
				)}
			/>
		</>
	);
};

export default TagIdsFormlet;
