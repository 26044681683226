import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';
import {defaultBuildingsQuery} from './constants';

export default {
	...lifecycle,
	processing: false,
	buildingsQuery: defaultBuildingsQuery,
	contactUpdater: false,
	buildingsType: 'house',
	onlyWithProjectBuildingId: false,
	buildings: [],
	buildingsPagination: emptyPagination,
	buildingsLoading: false,
	buildingsNotYetLoaded: true,
	massEditorOpen: false,
	massSelection: [],
	areasSelectorOpen: false,
	areasSelectorSearch: '',
	csvFileSelectorOpen: false,
	csvFileSelectorProcessing: false,
	availableTags: [],
};
