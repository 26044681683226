export const getJsonProperties = (feature, keys) => {
	let properties = {};
	const props = feature.getProperties();
	keys.forEach(k => {
		properties[k] = JSON.parse(props[k]);
	});
	return properties;
};

export const getAllJsonProperties = feature => {
	let properties = {};
	const props = feature.getProperties();

	// this comes as a standard MVT field from the API
	delete props.layer;
	Object.keys(props).forEach(k => {
		properties[k] = JSON.parse(props[k]);
	});
	return properties;
};

export const getJsonProperty = (feature, prop) => {
	return JSON.parse(feature.get(prop));
};

const pointRadiusForZoom = {
	13: 2,
	14: 3,
	15: 4,
	16: 6,
	17: 10,
	18: 12,
};

export const scalePointRadius = zoom => {
	return pointRadiusForZoom[zoom] || 2;
};

let cachedCircleStyles = {};

export const concreteBuildingStyle = (ol, {geomType, zoom, style}) => {
	if (geomType === 'MultiPoint' || geomType === 'Point') {
		const radius = scalePointRadius(zoom);

		// cache the previously created ol styles and reuse them
		// creating a new style each time by calling new.ol.style() is inefficient performance-wise
		const fillPart = style.fill ? style.fill.getColor() : '.';
		const strokePart = style.stroke
			? style.stroke.getColor() +
			  (style.stroke.getWidth() || '') +
			  (style.stroke.getLineDash() || '')
			: '.';
		const cacheKey = radius + fillPart + strokePart;

		const cachedStyle = cachedCircleStyles[cacheKey];

		if (cachedStyle) {
			return cachedStyle;
		}

		const circleStyle = {
			image: new ol.style.Circle({
				radius,
				fill: style.fill,
				stroke: style.stroke,
			}),
		};

		cachedCircleStyles[cacheKey] = circleStyle;
		return circleStyle;
	}
	return style;
};

export const getGoogleMapsAddress = building => {
	//https://developers.google.com/maps/documentation/urls/guide#forming-the-search-url

	const address = building.address || building.addressSwe;
	if (address) {
		//encodeURI seems to work better than encodeURIComponent with missing zip or city values
		const urlEncodedAddress = encodeURIComponent(
			address.split(' ').concat(building.zip).concat(building.city).join(' '),
		);

		return `https://www.google.com/maps/search/?api=1&query=${urlEncodedAddress}`;
	} else {
		// if building doesnt have address we can navigate with coordinates which are always known (in some rare cases they are (0,0))
		const lat = building.location.coordinates[0][1];
		const lng = building.location.coordinates[0][0];
		// need to use encoded comma as %2C between lat,lng
		return `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`;
	}
};
