import services from 'services';
import {describeThrow} from 'utils/errors';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getActivityReport = query =>
	httpJson('get', '/reports/activityReport', query).catch(
		describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})),
	);
