import {change, blur, reset} from 'redux-form';
import * as actions from './actions';
import * as selectors from './selectors';
import * as rootSelectors from 'modules/common/selectors';
import {
	attachTag as attachTagIo,
	detachTag as detachTagIo,
	getBuildingsTags,
} from 'modules/common/io';
import * as confirmerActions from 'modules/confirmer/actions';
import * as salesmanAppContactsActions from 'modules/salesmanApp/contactsPage/actions';
import {catchNonFatalDefault} from 'io/errors';
import {effect} from 'utils/redux';
import {decorateWithNotifications} from 'io/app';
import namespace from './namespace';
import services from 'services';
import msgs from 'dicts/messages';
import {bindToCalendarResourceReservationEvents} from 'fragments/calendarResourcePicker/effectHelpers';
import createBuildingModalEffects from 'fragments/buildingModalActions/effects';
import createDatePickEffects from 'fragments/calendarResourcePicker/effects';
import {resolveObject} from 'utils/promises';
import {
	getBuilding,
	getBuildingSalesTeams,
	getEncounters,
	getEncounter,
	updateClient,
	postClient,
	deleteClient,
	getUserTeams,
	getFreeCalendarResources,
	postFormFill,
	postCalendarResource,
	postVisit,
	getAllProducts,
	getMarketingLeadSources,
} from './io';
import {getTags as getAvailableTags} from 'modules/usersApp/tagsPage/io';
import {getReferrer, getReferrerUrl, createReferrerUrl, encodeQuery} from 'utils/url';
import {medDur, longDur} from 'constants/notifications';
import {initializeCallReminder as fetchCallReminder} from 'fragments/callReminder/effectHelpers';
import createReminderEffects from 'fragments/callReminder/effects';
import {TYPE_BUILDING} from 'modules/usersApp/tagsPage/constants';
import {appName} from '../constants';
import {pushQuery} from 'io/history';

const creator = effect(namespace);

const history = services.get('history');

let intl = null;
services.waitFor('intl').then(x => (intl = x));

let pusher = null;
services.waitFor('pusher').then(x => (pusher = x));

const setupChannels = (getState, dispatch) => {
	pusher = services.get('pusher');
	const user = rootSelectors.user(getState());
	const calendarResourcesChannel = pusher.subscribe('calendarResource');
	bindToCalendarResourceReservationEvents({actions, calendarResourcesChannel, user})(
		getState,
		dispatch,
	);
};

const clearChannels = () => {
	pusher.unsubscribe('calendarResource');
};

const reminderEffects = createReminderEffects({namespace, actions});

export const {saveCallReminder, removeCallReminder} = reminderEffects;

const fetchBuildingData =
	({buildingId, notifyOpts = {}}) =>
	(getState, dispatch) => {
		const isSales = rootSelectors.isSalesUser(getState());

		const canAddCalendarResourcesToAnyTeam =
			rootSelectors.canAddCalendarResourcesToAnyTeam(getState());
		// userTeams are always fetched (if necessary) before this routine is called
		const userTeams = selectors.userTeams(getState());
		const calResTeamId =
			isSales && !canAddCalendarResourcesToAnyTeam && userTeams.length
				? userTeams[0].id
				: null;

		return decorateWithNotifications(
			{
				id: 'init-calls-buildings',
				failureDuration: e => (e.causedByNoPermission ? longDur : medDur),
				loading: intl.formatMessage({id: msgs.loading}),
				...notifyOpts,
			},
			Promise.all([
				getBuilding(buildingId).then(building => {
					dispatch(actions._setBuilding(building));
				}),
				getEncounters(buildingId).then(encounters => {
					dispatch(actions._setEncounters(encounters));
				}),
				getFreeCalendarResources(buildingId, calResTeamId).then(cr => {
					dispatch(actions._setFreeCalRes(cr));
				}),
				!isSales || canAddCalendarResourcesToAnyTeam
					? getBuildingSalesTeams(buildingId).then(data => {
							dispatch(actions._setSalesTeams(data));
					  })
					: Promise.resolve(),
				fetchCallReminder({actions, buildingId})(getState, dispatch),
				getAllProducts().then(products => {
					dispatch(actions._setProducts(products));
				}),
			]),
		)(getState, dispatch);
	};

const doResetVisitForm = (getState, dispatch) => {
	dispatch(actions.selectCalendarResource(null));
	dispatch(reset('visitForm'));
};

// this is a separate helper to increase readability. note that it doesn't return a promise since it may change the page
// NOTE: may crash if used after module destroyed
const doAfterEncounterOperation = (getState, dispatch) => {
	const referrer = getReferrer(history.location.search);
	const referrerUrl = getReferrerUrl(history.location.search);

	const currentBuildingId = selectors.building(getState()).id;

	if (referrer === 'listview') {
		history.push(referrerUrl);
	} else if (referrer === 'freeride') {
		history.push(referrerUrl);
	} else if (referrer === 'salesman-app-contacts') {
		dispatch(salesmanAppContactsActions.updateBuilding(currentBuildingId));

		history.push(referrerUrl);
	} else {
		doResetVisitForm(getState, dispatch);

		fetchBuildingData({buildingId: currentBuildingId})(getState, dispatch).catch(
			catchNonFatalDefault(getState, dispatch),
		);

		const {pathname} = history.location;
		// In freeride application, close the building overlay after saving encounter and refresh the map
		if (pathname === '/d2d/freeride') {
			pushQuery(q => ({...q, selectionId: null, refreshMap: true}));
		}
	}
};

const buildingModalEffects = createBuildingModalEffects({
	namespace,
	actions,
});

export const {removeBuilding, saveBuildingData} = buildingModalEffects;

const datePickEffects = createDatePickEffects({
	namespace,
	actions,
	selectResource: dateId => (getState, dispatch) => {
		dispatch(change('visitForm', 'calendarResourceId', dateId));
		// this does maybe something important with redux-form, not sure what
		setTimeout(() => {
			dispatch(blur('visitForm', 'calendarResourceId'));
		});
	},
});

export const {selectCalendarResource} = datePickEffects;

export let initialize = buildingId => (getState, dispatch) => {
	setupChannels(getState, dispatch);

	dispatch(actions._setOpenedAt(new Date()));
	const canAddCalendarResourcesToAnyTeam = rootSelectors.canAddCalendarResourcesToAnyTeam(
		getState(),
	);

	dispatch(actions.getTags(buildingId));

	decorateWithNotifications(
		{
			id: 'init-d2d',
			failureDuration: e => (e.causedByNoPermission ? longDur : medDur),
		},
		Promise.all([
			(rootSelectors.isSalesUser(getState())
				? getUserTeams({includeTeamUsers: true}).then(teams => {
						dispatch(actions._setUserTeams(teams));
				  })
				: Promise.resolve()
			)
				.then(() =>
					Promise.all([
						fetchBuildingData({buildingId, notifyOpts: {disableEverything: true}})(
							getState,
							dispatch,
						),
						getAvailableTags({
							type: TYPE_BUILDING,
							getAllTags: false,
							view: appName,
						}).then(({data: tags}) => {
							dispatch(actions._setAvailableTags(tags));
						}),
					]),
				)
				.then(_ => {
					if (
						getReferrer(history.location.search) === 'salesman-app-contacts' ||
						canAddCalendarResourcesToAnyTeam
					) {
						dispatch(change('visitForm', 'dateCreatorOpen', true));
					}
				}),
			getMarketingLeadSources().then(sources => {
				dispatch(actions._setMarketingLeadSources(sources));
			}),
		]),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let saveVisit =
	({visit, calendarResource, formFill}) =>
	(getState, dispatch) => {
		const seq = selectors.seq(getState());

		const postIfNeeded = (item, relationName, postFunc) =>
			item ? postFunc(item).then(({id}) => ({[relationName]: id})) : Promise.resolve({});

		// set visit source to salesmanApp if that is the referrer
		const referrer = getReferrer(history.location.search);
		const source = referrer === 'salesman-app-contacts' ? 'salesmanApp' : null;

		decorateWithNotifications(
			{
				id: 'save-visit',
				failureStyle: e => (e.causedByDateTaken ? 'warning' : 'error'),
				failureDuration: longDur,
				success: intl.formatMessage({id: msgs.saved}),
				loading: intl.formatMessage({id: msgs.processing}),
			},
			Promise.all([
				resolveObject({
					rel1: postIfNeeded(
						calendarResource,
						'calendarResourceId',
						postCalendarResource,
					),
					rel2: postIfNeeded(formFill, 'formFillId', postFormFill),
				})
					.then(({rel1, rel2}) => postVisit({...visit, ...rel1, ...rel2, source}))
					.catch(e => {
						if (e.causedByDateTaken) {
							dispatch(change('visitForm', 'calendarResourceId', null));
							dispatch(actions._calendarResourceReserved(visit.calendarResourceId));
						}
						throw e;
					}),
			]),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._visitSaveFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._visitSaved());

				if (selectors.seq(getState()) === seq) {
					doAfterEncounterOperation(getState, dispatch);
				}
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
saveVisit = creator('saveVisit', saveVisit);

export let changeBuilding = buildingId => (getState, dispatch) => {
	dispatch(actions._setOpenedAt(new Date()));

	dispatch(actions.getTags(buildingId));

	fetchBuildingData({buildingId, notifyOpts: {loading: null}})(getState, dispatch).catch(
		catchNonFatalDefault(getState, dispatch),
	);
};
changeBuilding = creator('changeBuilding', changeBuilding);

// encounter modal
export let getEncounterData = encounterId => (getState, dispatch) => {
	dispatch(actions._startOp());
	decorateWithNotifications(
		{
			id: 'get-encounter-data',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		getEncounter(encounterId)
			.then(encounter => dispatch(actions._setEncounterData(encounter)))
			.then(dispatch(actions._opOk())),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
getEncounterData = creator('getEncounterData', getEncounterData);

// TODO: wtf? this creates AND updates, and buildingId decides that?
export let createClient =
	({client, buildingId}) =>
	(getState, dispatch) => {
		dispatch(actions._startOp());
		decorateWithNotifications(
			{
				id: 'save-client',
				failureStyle: 'warning',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Saved'}),
			},
			!buildingId ? updateClient(client) : postClient(client, buildingId),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(client => {
				dispatch(
					actions._updateClients({
						client: client,
						type: !buildingId ? 'update' : 'add',
					}),
				);

				// note: if we turn client management into a fragment then these need to be handled through an optional callback
				if (!buildingId) {
					dispatch(change('visitForm', 'contactClientId', null));
				}
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
createClient = creator('createClient', createClient);

export let removeClient =
	({id, reason}) =>
	(getState, dispatch) => {
		const onConfirm = () => {
			dispatch(actions._startOp());
			decorateWithNotifications(
				{
					id: 'delete-client',
					failureStyle: 'error',
					loading: intl.formatMessage({id: msgs.processing}),
					success: intl.formatMessage({id: 'Client deleted'}),
				},
				deleteClient({id, reason}),
			)(getState, dispatch)
				.catch(e => {
					dispatch(actions._opFailed());
					throw e;
				})
				.then(() => {
					dispatch(actions._updateClients({id: id, type: 'remove'}));

					// note: if we turn client management into a fragment then these need to be handled through an optional callback
					dispatch(change('visitForm', 'contactClientId', null));
				})
				.then(() => dispatch(actions._opOk()))
				.catch(catchNonFatalDefault);
		};

		dispatch(
			confirmerActions.show({
				message: intl.formatMessage({id: 'Delete client?'}),
				cancelText: intl.formatMessage({id: msgs.cancel}),
				onCancel: () => {},
				onOk: onConfirm,
			}),
		);
	};
removeClient = creator('removeClient', removeClient);

export let resetVisitForm = () => (getState, dispatch) => {
	doResetVisitForm(getState, dispatch);
};
resetVisitForm = creator('resetVisitForm', resetVisitForm);

export let destroy = () => (getState, dispatch) => {
	clearChannels();
};
destroy = creator('destroy', destroy);

export let setClientSwedish = client => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'set-client-swedish',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Saved'}),
		},
		updateClient(client),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(client => {
			dispatch(
				actions._updateClients({
					client: client,
					type: 'update',
				}),
			);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
setClientSwedish = creator('setClientSwedish', setClientSwedish);

export let saveSalesVisit =
	({calendarResource}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'save-sales-visit',
				loading: intl.formatMessage({id: 'Saving'}),
				success: intl.formatMessage({id: 'Saved'}),
				failureStyle: 'error',
			},
			postCalendarResource(calendarResource),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(cr => {
				dispatch(actions._opOk());
				const referrerUrl = createReferrerUrl(history.location);
				history.push(`/sales/${cr.id}${encodeQuery({referrerUrl})}`);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
saveSalesVisit = creator('saveSalesVisit', saveSalesVisit);

export let getTags = id => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'fetch-tags',
			failureStyle: 'warning',
		},
		getBuildingsTags(id),
	)(getState, dispatch)
		.then(tags => {
			dispatch(actions._getTags(tags));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
getTags = creator('getTags', getTags);

export let attachTag = params => (getState, dispatch) => {
	const {buildingId} = params;
	decorateWithNotifications(
		{
			id: 'attach-tag',
			failureStyle: 'error',
		},
		attachTagIo(params),
	)(getState, dispatch)
		.then(() => dispatch(actions._attachTag()))
		.then(() => dispatch(actions.getTags(buildingId)))
		.catch(catchNonFatalDefault);
};

attachTag = creator('attachTag', attachTag);

export let detachTag = params => (getState, dispatch) => {
	const {buildingId} = params;
	decorateWithNotifications(
		{
			id: 'detach-tag',
			failureStyle: 'error',
		},
		detachTagIo(params),
	)(getState, dispatch)
		.catch(catchNonFatalDefault)
		.then(() => dispatch(actions._detachTag()))
		.then(() => dispatch(actions.getTags(buildingId)));
};

detachTag = creator('detachTag', detachTag);
