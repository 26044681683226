import {state as lifecycle} from 'fragments/lifecycle';
import {leadFormInitialValues} from './constants';

export default {
	...lifecycle,
	initialized: false,
	lead: {},
	processing: false,
	loading: false,
	buildings: [],
	products: [],
	clients: [],
	organizations: [],
	leadFormInitialValues: {...leadFormInitialValues},
	loadingLeadForm: false,
	addBuildingModalOpen: false,
	mapModalOpen: false,
	addBuildingFormInitValues: {
		address: '',
		zip: '',
	},
	buildingToAdd: {
		address: '',
		zip: '',
		type: '',
		manufacturingYear: '',
		coords: [],
	},
	leadEvents: [],
	leadEventsLoading: true,
	eventModalOpen: false,
	salesmanagers: [],
	salesTeams: [],
	marketingLeadTags: [],
	handlers: [],
	duplicateLeads: [],
	duplicateLeadsLoading: true,
};
