import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import Notification from 'components/generic/Notification';
import {connect} from 'react-redux';
import {applyState, applyDispatch} from 'utils/redux';
import {notifications} from 'modules/notifications/selectors';
import {remove} from 'modules/notifications/actions';

const FixWrapper = styled.div`
	pointer-events: none;
	position: fixed;
	/* this makes the first notification be about vertically centered in relation to the navbar */
	top: 9px;
	z-index: 150;
	width: 100%;
`;

const WidthWrapper = styled.div`
	margin: 0 auto;
	/* this leaves a bit of space around the notification so that the main navbar buttons can still be pressed even on mobile */
	max-width: calc(100% - 116px);
`;

// needed because browsers don't support css "max" function yet (for max-width)
const InnerWidthWrapper = styled.div`
	margin: 0 auto;
	max-width: 700px;

	> * {
		pointer-events: auto;
	}

	> *:not(:first-child) {
		margin-top: 8px;
	}
`;

class Notifications extends React.Component {
	render() {
		const {notifications, remove} = this.props;

		return (
			<FixWrapper>
				<WidthWrapper>
					<InnerWidthWrapper>
						{notifications.map(({id, type, message}) => (
							<Notification
								key={id}
								id={id}
								type={type}
								message={message}
								remove={() => remove(id)}
							/>
						))}
					</InnerWidthWrapper>
				</WidthWrapper>
			</FixWrapper>
		);
	}
}

Notifications.propTypes = {
	notifications: propTypes.array,
	remove: propTypes.func,
};

export default connect(
	applyState({notifications}),
	applyDispatch({remove}),
)(Notifications);
