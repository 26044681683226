import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	referencesQuery: {
		_page: 1,
		city: null,
		zip: null,
		county: null,
		dismissPrompt: false,
		products: [],
	},
	references: [],
	referencesLoading: false,
	referencesPagination: {
		currentPage: 1,
		totalPages: 0,
		total: 0,
	},
	areasLoading: true,
	areas: {
		counties: [],
		cities: [],
		postcodes: [],
	},
	products: [],
	productsLoading: true,
	bookOpen: false,
};
