import {pipeHandlers} from 'utils/reduxEffects';
import common from './common/handler';
import buildings from './buildingsPage/handler';
import building from './buildingPage/handler';
import clients from './clientsPage/handler';
import client from './clientPage/handler';
import bans from './bansPage/handler';
import addBuilding from './addBuildingPage/handler';
import removalRequests from './removalRequestsPage/handler';
import removalRequest from './removalRequestPage/handler';
import csvImports from './csvImportsPage/handler';
import csvImport from './csvImportPage/handler';

export default pipeHandlers(
	common,
	buildings,
	building,
	clients,
	client,
	bans,
	addBuilding,
	removalRequests,
	removalRequest,
	csvImports,
	csvImport,
);
