// core dependencies
import React, {Component} from 'react';
import ReactDom from 'react-dom';
import styled from 'styled-components';
import propTypes from 'prop-types';
// custom helpers & utils
import {
	screenXsMax,
	borderColorBase,
	gray,
	fontSizeL,
	cardTitleBgColor,
} from 'styles/constants';
import {textButton} from 'styles/fragments';
// components
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';
import SpacedItems from './SpacedItems';

const ModalRoot = styled.div``;

const Backdrop = styled.div`
	position: fixed;
	z-index: 100;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0.4;
	background: black;

	transition: opacity 100ms ease-in;
	${/*prettier-ignore*/ ({animationPending}) => animationPending ? `
		opacity: 0;
	` : ''};
`;

const ModalContainer = styled.div`
	overflow: auto;
	position: fixed;
	z-index: 100;
	top: 0;
	/* chrome mobile hides the navbar when scrolling a fixed element with 100% screen size. this causes issues, so stop that from happening. */
	bottom: 1px;
	left: 0;
	right: 0;
	padding: 30px 30px;

	@media (max-width: ${screenXsMax}) {
		padding: 10px 10px;
	}
`;

const ModalWrapper = styled.div`
	max-width: ${({maxWidth}) => maxWidth || screenXsMax};
	margin: 0 auto;
	border-radius: 3px;
	background: white;

	transition: top 100ms ease-in;
	${
		/*prettier-ignore*/ ({animationPending}) => animationPending ? `
		margin-top: -10px;
	` : ''
	};
`;

const HeaderWrapper = styled.div`
	border-bottom: 1px solid ${borderColorBase};
	padding: 15px 20px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: ${cardTitleBgColor};
`;

const CloseButton = styled.button.attrs(() => ({type: 'button'}))`
	${textButton};

	padding: 3px 5px;
	font-size: ${fontSizeL};
	color: ${gray};
`;

const ContentWrapper = styled.div`
	padding: 0 20px;
	${({topPadding, verticalPadding}) =>
		topPadding || verticalPadding ? 'padding-top: 20px;' : ''};
	${({bottomPadding, verticalPadding}) =>
		bottomPadding || verticalPadding ? 'padding-bottom: 20px;' : ''};
`;

const FooterWrapper = styled.div`
	border-top: 1px solid ${borderColorBase};
	padding: 15px 20px;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	background: ${cardTitleBgColor};
`;

class ModalComponent extends Component {
	state = {animationPending: true};

	backgroundComponentRef = React.createRef();

	hide = () => {
		if (this.props.hide) this.props.hide();
	};

	onKeyDown = event => {
		if (event.key === 'Escape') this.hide();
	};

	hideOnBackgroundClick = event => {
		const bgEl = this.backgroundComponentRef.current;
		if (bgEl && bgEl === event.target) {
			this.hide();
		}
	};

	componentDidMount() {
		// give react time to render the initial pre-animation state first
		setTimeout(() => this.setState({animationPending: false}));
	}

	render() {
		const {
			children,
			header,
			headerTitle,
			headerActions,
			footer,
			footerLeft,
			cancelButton,
			confirmButton,
			maxWidth,
			topPadding = false,
			bottomPadding = false,
			verticalPadding = false,
			hide,
			onKeyDown,
			...rest
		} = this.props;

		const closeBtn = hide && (
			<CloseButton onClick={this.hide}>
				<i className="fa fa-times" />
			</CloseButton>
		);

		return (
			<ModalRoot tabIndex="-1" onKeyDown={this.onKeyDown} {...rest}>
				<Backdrop animationPending={this.state.animationPending} />
				<ModalContainer
					ref={this.backgroundComponentRef}
					onClick={this.hideOnBackgroundClick}
				>
					<ModalWrapper
						maxWidth={maxWidth}
						animationPending={this.state.animationPending}
					>
						{header ? (
							<HeaderWrapper>
								{header}
								{closeBtn}
							</HeaderWrapper>
						) : headerTitle || headerActions ? (
							<HeaderWrapper>
								<ModalHeader title={headerTitle} actions={headerActions} />
								{closeBtn}
							</HeaderWrapper>
						) : null}
						<ContentWrapper
							topPadding={topPadding}
							bottomPadding={bottomPadding}
							verticalPadding={verticalPadding}
						>
							{children}
						</ContentWrapper>
						{footer ? (
							<FooterWrapper>{footer}</FooterWrapper>
						) : footerLeft || cancelButton || confirmButton ? (
							<FooterWrapper>
								<ModalFooter
									left={footerLeft}
									right={
										<SpacedItems>
											{cancelButton}
											{confirmButton}
										</SpacedItems>
									}
								/>
							</FooterWrapper>
						) : null}
					</ModalWrapper>
				</ModalContainer>
			</ModalRoot>
		);
	}
}

ModalComponent.propTypes = {
	show: propTypes.bool,
	size: propTypes.string,
	maxWidth: propTypes.string,
	topPadding: propTypes.bool,
	bottomPadding: propTypes.bool,
	verticalPadding: propTypes.bool,
	hide: propTypes.func,
	onKeyDown: propTypes.func,
	children: propTypes.node,
	header: propTypes.node,
	headerTitle: propTypes.node,
	headerActions: propTypes.node,
	footer: propTypes.node,
	footerLeft: propTypes.node,
	cancelButton: propTypes.node,
	confirmButton: propTypes.node,
};

const Modal = ({parentNode, ...rest}) => {
	return ReactDom.createPortal(
		<ModalComponent {...rest} />,
		parentNode || document.getElementById('app'),
	);
};

export default Modal;
