import Field from 'components/generic/Field';
import propTypes from 'prop-types';
import FieldError from 'components/generic/FieldError';
import FormBlock from 'components/generic/FormBlock';
import Input from 'components/generic/Input';
import Label from 'components/generic/Label';
import React from 'react';

const DateFormlet = ({name, label, type = 'date', isClearable = true, ...rest}) => {
	return (
		<Field
			{...rest}
			name={name}
			type={type}
			component={({input, inputId, meta}) => (
				<FormBlock>
					<Label htmlFor={inputId}>{label}</Label>
					<Input
						{...input}
						id={inputId}
						meta={meta}
						block
						stretch
						autoComplete="off"
						isClearable={isClearable}
					/>
					<FieldError {...meta} />
				</FormBlock>
			)}
		/>
	);
};

DateFormlet.propTypes = {
	name: propTypes.string.isRequired,
	label: propTypes.node.isRequired,
};

export default DateFormlet;
