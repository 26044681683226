import {mapStateKeys as _mapStateKeys} from 'utils/redux';

export const stateVals = {
	freeCalendarResources: [],
	pendingCalendarResourceSelection: null,
};

export const stateKeys = Object.keys(stateVals);

export const mapStateKeys = _mapStateKeys(stateKeys);
