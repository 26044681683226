import {path, pipe, prop, find} from 'ramda';
import namespace from './namespace';
import {createSelector} from 'reselect';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {
	formatClientFormInput,
	formatCallFormInput,
	formatVisitFormInput,
	formatCondoCreatorInitValues,
	sortCondoActivities,
	sortAllActivities,
	formatCondoOwnerOpts,
	formatCondoCustomerAcquisitionOpts,
} from './utils';
import {createEmptyClient} from './constants';
import createDatePickSelectors from 'fragments/calendarResourcePicker/selectors';
import createBuildingModalSelectors from 'fragments/buildingModalActions/selectors';
import createCallReminderSelectors from 'fragments/callReminder/selectors';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const condo = pipe(root, prop('condo'));

export const processing = pipe(root, prop('processing'));

export const initialized = pipe(root, prop('initialized'));

export const buildings = pipe(root, prop('buildings'));

export const primaryBuilding = createSelector([condo, buildings], (condo, buildings) =>
	condo ? find(x => x.id === condo.projectBuildingId, buildings) : {},
);

export const comments = pipe(root, prop('comments'));

export const projectBuildingId = pipe(condo, prop('projectBuildingId'));

export const client = pipe(root, prop('client'));

export const clientType = pipe(root, prop('clientType'));

export const products = pipe(root, prop('products'));

export const clients = pipe(root, prop('clients'));

export const clientEditorOpen = pipe(root, prop('clientEditorOpen'));

export const calendarResources = pipe(root, prop('calendarResources'));

export const calResProcessing = pipe(root, prop('calResProcessing'));

export const activities = createSelector([condo], condo =>
	condo && condo.activities ? sortCondoActivities(condo) : [],
);

export const projectSalesmen = pipe(root, prop('projectSalesmen'));

export const projectCustomerAcquisitionUsers = pipe(
	root,
	prop('projectCustomerAcquisitionUsers'),
);

export const condoOwnerOpts = createSelector(
	[condo, projectSalesmen],
	(condo, salesmen) => formatCondoOwnerOpts(condo, salesmen),
);

export const condoCustomerAcquisitionOpts = createSelector(
	[condo, projectCustomerAcquisitionUsers],
	(condo, projectCustomerAcquisitionUsers) =>
		formatCondoCustomerAcquisitionOpts(condo, projectCustomerAcquisitionUsers),
);

export const activeTab = pipe(root, prop('activeTab'));

export const clientFormInitValues = createSelector([client], client =>
	formatClientFormInput({
		client: client || createEmptyClient(),
	}),
);

export const additionalInfos = pipe(root, prop('additionalInfos'));

export const tooltip = pipe(root, prop('tooltip'));

export const openedAt = pipe(root, prop('openedAt'));

export const userTeams = pipe(root, prop('userTeams'));

export const callFormInitValues = createSelector(
	[userTeams, openedAt],
	(teams, openedAt) => formatCallFormInput({teams, openedAt}),
);

export const visitFormInitValues = createSelector([clients], clients =>
	formatVisitFormInput({clients}),
);

export const newDateCreatorOpen = pipe(root, prop('newDateCreatorOpen'));

export const condoNotFound = pipe(root, prop('condoNotFound'));

export const clientSearchOpen = pipe(root, prop('clientSearchOpen'));

export const newClients = pipe(root, prop('newClients'));

export const building = pipe(root, prop('building'));

export const condoCreatorInitValues = createSelector([building], building =>
	formatCondoCreatorInitValues({building}),
);

export const attachingBuilding = pipe(root, prop('attachingBuilding'));

export const disableReinit = pipe(root, prop('disableReinit'));

export const newManagerClient = pipe(root, prop('newManagerClient'));

export const newHouseManagerClient = pipe(root, prop('newHouseManagerClient'));

export const condoEditorOpen = pipe(root, prop('condoEditorOpen'));

export const activeCallPool = pipe(root, prop('activeCallPool'));

export const fetchingNextBuilding = pipe(root, prop('fetchingNextBuilding'));

export const loading = pipe(root, prop('loading'));

export const activitiesPopupOpen = pipe(root, prop('activitiesPopupOpen'));

export const dateEditorOpen = pipe(root, prop('dateEditorOpen'));

export const allActivities = createSelector(
	[calendarResources, activities],
	(calRes, acts) => calRes && acts && sortAllActivities(calRes, acts),
);

const datePickSelectors = createDatePickSelectors({namespace});

export const {
	freeCalendarResources,
	pendingCalendarResourceSelection,
	lastCalendarResourceSelection,
} = datePickSelectors;

// building modal
const buildingPanelSelectors = createBuildingModalSelectors({namespace});

export const {buildingModalOpen} = buildingPanelSelectors;

const callReminderSelectors = createCallReminderSelectors({namespace});

export const {
	callReminder,
	callReminderFormInitValues,
	callReminderLoading,
	callReminderEditorOpen,
} = callReminderSelectors;
