export const userInclude = 'organizations,roles,teams,allTeams,userPermissions';

export const usersQueryBase = {
	_limit: 15,
};

export const usersQueryTrashed = {
	_limit: 15,
	trashed: true,
};

export const createEmptyUser = () => ({
	firstName: '',
	lastName: '',
	email: '',
	password: '',
	hideEncounters: false,
	additionalTeamIds: [],
	allTeams: [],
	organizations: [],
	roles: [],
	userPermissions: [],
});
