import {state as lifecycle} from 'fragments/lifecycle';
import createBuildingModalState from 'fragments/buildingModalActions/state';
import createCalendarPickerState from 'fragments/calendarResourcePicker/state';
import createReminderState from 'fragments/callReminder/state';

const buildingModalState = createBuildingModalState({});

const calendarPickerState = createCalendarPickerState({});

const reminderState = createReminderState({});

export default {
	...lifecycle,
	...buildingModalState,
	...calendarPickerState,
	...reminderState,
	reservedSuccessfully: false,
	leaveCallPoolAfterEncountedSaved: false,
	building: null,
	buildingLoading: true,
	productStates: null,
	processing: false,
	encounters: [],
	encountersLoading: true,
	encounter: null,
	clientFormInitvalues: {},
	activeCallPool: null,
	userTeams: [],
	userTeamsLoading: true,
	salesTeams: [],
	salesTeamsLoading: true,
	freeCalendarResourcesLoading: true,
	openedAt: null,
	modalProcessing: false,
	client: null,
	clientEditorOpen: false,
	buildingModalOpen: false,
	callJustSaved: false,
	fetchingNextBuilding: false,
	leaddeskCallInProgress: false,
	leaddeskTalkInProgress: false,
	survey: null,
	surveyLoading: true,
	surveyCallReview: null,
	lead: null,
	marketingLeadSources: [],
	products: [],
	availableTags: [],
	tags: [],
	processingTags: false,
	processingTag: false,
};
