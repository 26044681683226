export const bindToCalendarResourceReservationEvents = ({
	actions,
	calendarResourcesChannel,
	user,
}) => (getState, dispatch) => {
	const handler = ({userId: _userId, calendarResourceId: _resId, state}) => {
		const userId = Number(_userId);
		const calendarResourceId = Number(_resId);

		if (userId !== user.id) {
			const evt =
				state === 'reserved'
					? actions._calendarResourceReserved
					: actions._calendarResourceReleased;
			dispatch(evt(calendarResourceId));
		}
	};
	calendarResourcesChannel.bind('reservation', handler);
};
