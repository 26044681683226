import * as actions from './actions';
import namespace from './namespace';
import {effect} from 'utils/redux';
import {setPageTitleMessage, decorateWithNotifications} from 'io/app';
import {catchNonFatalDefault} from 'io/errors';
import {getNotices as ioGetNotices, getStatistics, getTargets, getUserTeams} from './io';
import {resolveObject} from 'utils/promises';
import * as commonSelectors from 'modules/common/selectors';
import * as selectors from './selectors';

const creator = effect(namespace);

const fetchStats = ({organizationId, teamId, userId, isSalesmanager, isAdminUser}) => (
	getState,
	dispatch,
) => {
	const today = new Date();
	const month = today.getMonth() + 1;
	const year = today.getFullYear();
	const timespan = 'month';

	const targetsQuery = isAdminUser
		? {organizationId, month, year, timespan}
		: isSalesmanager
		? {teamId, month, year, timespan}
		: {userId, month, year, timespan};

	const statsQuery = isAdminUser
		? {organizationId, teamId, month, year}
		: isSalesmanager
		? {teamId, month, year}
		: {userId, month, year};

	return Promise.all([
		resolveObject({
			salesPriceTarget: getTargets({
				...targetsQuery,
				type: 'salesPrice',
			}).then(t => (t.length ? t[0].target : 0)),
			offerCountTarget: getTargets({
				...targetsQuery,
				type: 'offerCount',
			}).then(t => (t.length ? t[0].target : 0)),
		}).then(targets => dispatch(actions._setTargets(targets))),
		getStatistics(statsQuery).then(stats => dispatch(actions._setStats(stats))),
	]);
};

export let initialize = () => (getState, dispatch) => {
	setPageTitleMessage('Main menu')(getState, dispatch);

	const user = commonSelectors.user(getState());
	const isSalesmanager = commonSelectors.isSalesmanagerUser(getState());
	const organizationId = commonSelectors.activeOrganizationId(getState());
	const isAdminUser = selectors.isSalesmanAppAdmin(getState());

	decorateWithNotifications(
		{id: 'init-notices', failureStyle: 'error'},
		Promise.all([
			fetchNotices()(getState, dispatch)
				.catch(_ => dispatch(actions._noticesFetchFailed()))
				.catch(catchNonFatalDefault(getState, dispatch)),
			getUserTeams().then(teams =>
				fetchStats({
					organizationId,
					teamId: teams.length ? teams[0].id : null,
					userId: user.id,
					isSalesmanager,
					isAdminUser,
				})(getState, dispatch),
			),
		]),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};

initialize = creator('initialize', initialize);

export let getNotices = _page => (getState, dispatch) => {
	fetchNotices({_page})(getState, dispatch)
		.catch(_ => dispatch(actions._noticesFetchFailed()))
		.catch(catchNonFatalDefault(getState, dispatch));
};

const fetchNotices = query => (getState, dispatch) => {
	const user = commonSelectors.user(getState());

	return decorateWithNotifications(
		{id: 'get-notices', failureStyle: 'warning'},
		ioGetNotices({...query, userId: user.id}).then(notices =>
			dispatch(actions._setUserNotices(notices)),
		),
	)(getState, dispatch);
};
