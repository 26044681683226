import React from 'react';

const SvgSalesmanApp = props => (
	<svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M6.664 16a6.669 6.669 0 016.662 6.38l.006.289v4.668c0 .327-.235.6-.547.656l-.12.01h-2.12l-.693 3.466a.667.667 0 01-.561.53l-.104.006-.12-.013a.667.667 0 01-.536-.665l.013-.12.8-4a.667.667 0 01.537-.527l.117-.01h2v-4.001A5.335 5.335 0 006.93 17.34l-.266-.006a5.335 5.335 0 00-5.329 5.068l-.006.267v4.001h2c.279 0 .523.172.621.424l.033.112.8 4.001a.667.667 0 01-1.273.377l-.034-.115-.695-3.465H.661a.667.667 0 01-.655-.547l-.011-.12v-4.668A6.669 6.669 0 016.664 16zM30.004-.005a2 2 0 012.001 2v10.67a2 2 0 01-2 2.001h-7.09l-4.48 3.841a.667.667 0 01-1.093-.396l-.008-.11v-3.335h-.667a2 2 0 01-1.992-1.808l-.009-.192V1.996a2 2 0 012-2.001zm0 1.334H16.667a.667.667 0 00-.667.667v10.67c0 .368.299.666.667.666H18c.368 0 .666.3.666.667v2.55l3.568-3.056c.09-.078.2-.13.316-.15l.118-.01h7.335a.667.667 0 00.667-.667V1.996a.667.667 0 00-.667-.667zM6.664 5.33l.217.005a5.002 5.002 0 11-.217-.005zm0 1.334l-.195.005a3.668 3.668 0 00.195 7.33l.195-.005a3.668 3.668 0 00-.195-7.33zm16.672-4.002c.327 0 .6.236.656.548l.01.12v.666h2.001a.667.667 0 01.12 1.323l-.12.011h-4.001a.667.667 0 00-.12 1.323l.12.01h2.667a2 2 0 01.193 3.993l-.193.009-.667-.001v.668a.667.667 0 01-1.323.12l-.01-.12-.001-.668-2 .001a.667.667 0 01-.12-1.323l.12-.01h4.001a.667.667 0 00.12-1.324l-.12-.01h-2.667a2 2 0 01-.193-3.993l.193-.009h.666v-.667c0-.368.3-.667.668-.667z"
		/>
	</svg>
);

export default SvgSalesmanApp;
