import React from 'react';
import HeaderTabButton from './HeaderTabButton';
import services from 'services';
import {FormattedMessage} from '@meiko/react-intl';

const history = services.get('history');

const TabNavbar = ({pages, location}) => {
	return (
		<>
			{pages.map(page => (
				<HeaderTabButton
					key={page.to}
					active={location.pathname === page.to}
					onClick={() => history.push(page.to)}
				>
					<FormattedMessage id={page.name} />
				</HeaderTabButton>
			))}
		</>
	);
};

export default TabNavbar;
