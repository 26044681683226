import {effect} from 'utils/redux';
import {mergeLeft} from 'ramda';
import namespace from './namespace';
import {decorateWithNotifications} from 'io/app';
import {catchNonFatalDefault} from 'io/errors';
import {getQuery, pushQuery} from 'io/history';
import msgs from 'dicts/messages';
import services from 'services';
import * as actions from './actions';
import * as selectors from './selectors';
import * as rootSelectors from 'modules/common/selectors';
import {isValidDate} from './utils';
import {postTarget, getTargets} from './io';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const fetchOrganizationTargets = (getState, dispatch) => {
	const organizationId = rootSelectors.activeOrganizationId(getState());
	const [year, month] = selectors.selectedDate(getState()).split('-');

	return decorateWithNotifications(
		{id: 'fetch-organization-targets', failureStyle: 'error'},
		getTargets({
			type: 'salesPrice',
			organizationId,
			month,
			year,
		}),
	)(getState, dispatch).then(targets => {
		dispatch(
			actions._setOrganizationTargets({
				organizationSalesPrice: targets.length ? targets[0] : null,
			}),
		);
	});
};

export let initialize = () => (getState, dispatch) => {
	const {date} = getQuery();
	if (date && isValidDate(date)) {
		dispatch(actions._updateOrganizationTargetsQuery({date}));
	}

	fetchOrganizationTargets(getState, dispatch).catch(
		catchNonFatalDefault(getState, dispatch),
	);
};
initialize = creator('initialize', initialize);

export let saveTargets = targets => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'save-targets',
			loading: intl.formatMessage({id: 'Saving'}),
			success: intl.formatMessage({id: msgs.saved}),
			failureStyle: 'error',
		},
		Promise.all(targets.map(t => postTarget(t))),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(() => {
			dispatch(actions._saveTargets());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
saveTargets = creator('saveTargets', saveTargets);

export let updateOrganizationTargets = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	fetchOrganizationTargets(getState, dispatch).catch(
		catchNonFatalDefault(getState, dispatch),
	);
};
updateOrganizationTargets = creator(
	'updateOrganizationTargets',
	updateOrganizationTargets,
);
