import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';
const creator = action(namespace);

export const initialize = creator('initialize');
export const _initialize = creator('_initialize');

export const destroy = creator('destroy');

export const getSmsLogs = creator('getSmsLogs', P.Object);
export const _getSmsLogs = creator('_getSmsLogs', P.Object);

export const _getUsers = creator('_getUsers', P.Array);
