import {state as encounterState} from './encounters';

export const type = {
	free: 'Free',
	bonus: 'Bonus',
	reserved: 'Reserved',
	completed: 'Completed',
};

export const salesmanVisitState = {
	...encounterState,
	contacted: 'Not offered',
};

export const ihSource = {
	installed: 'Installed building',
	installing: 'Environment of building currently being installed',
	offer: 'Offer utilization',
	call: 'Phone call',
	d2d: 'Door-to-door',
	cancelledDeal: 'Cancelled sale utilization',
	notContacted: 'Uncontacted',
	lead: 'Lead',
	customerLead: 'Customer lead',
};

export const projectState = {
	1: 'Visit 1',
	2: 'Visit 2',
	3: 'Info session',
	offer: 'Offer',
	noOffer: 'Not offered',
	cancelledOffer: 'Cancelled offer',
	deal: 'Sale',
	notReached: 'Not reached',
};
