import {useEffect, useState} from 'react';

const useWindowWidth = () => {
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		window.addEventListener('resize', _e => {
			setWidth(window.innerWidth);
		});
	}, []);
	return {
		width,
	};
};

export default useWindowWidth;
