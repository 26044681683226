import React from 'react';
import styled from 'styled-components';
import Button from 'components/generic/Button';
import Icon from 'components/generic/Icon';

const CollapseButton = styled(Button)`
	background: transparent;
	display: flex;
	align-items: center;
`;

const AnimatedIcon = styled(Icon)`
	transform: ${({isVisible}) => (isVisible ? 'rotate(180deg)' : 'rotate(0deg)')};
	transition-duration: 100ms;
	transition-property: transform;
	margin-right: 5px;
	width: 10px;
`;

const CollapseContent = styled.div`
	${({isVisible}) => (isVisible ? '' : 'display: none')};
`;

/**
 * Simple collapse component.
 *
 * NOTE: Specifically used when elements need to be mounted on the DOM but not necessarily show all the time.
 * E.g You wan't to initialize and register redux-form fields as usual but you do not wan't to show them all the time.
 */
const Collapse = ({
	isVisible = false,
	showToggle = true,
	onToggle = () => null,
	title = '',
	children,
}) => {
	return (
		<div>
			{showToggle && (
				<CollapseButton size="s" onClick={() => onToggle(!isVisible)}>
					<AnimatedIcon isVisible={isVisible} name="chevronDown" /> {title}
				</CollapseButton>
			)}
			<CollapseContent isVisible={isVisible}>{children}</CollapseContent>
		</div>
	);
};

export default Collapse;
