import {effect} from 'utils/redux';
import namespace from './namespace';
import {getUserStats, getPersonalTargets, getDashboardCallReminders} from 'io/data';
import {getNextBuilding} from '../io';
import {catchNonFatalDefault} from 'io/errors';
import * as rootSelectors from 'modules/common/selectors';
import * as rootActions from 'modules/common/actions';
import {decorateWithNotifications} from 'io/app';
import * as actions from './actions';
import {
	updateTimeEntry,
	deleteTimeEntry,
	postCallTarget,
	updateCallTarget,
	getUserTimeEntries,
} from './io';
import services from 'services';
import msgs from 'dicts/messages';
import {longDur} from 'constants/notifications';
import * as confirmerActions from 'modules/confirmer/actions';
import {pushPath} from 'io/history';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

// TODO: should offer a public api for the underscore commonActions used here

export let initialize = () => (getState, dispatch) => {
	const user = rootSelectors.user(getState());
	dispatch(actions._startOp());

	decorateWithNotifications(
		{id: 'get-dashboard-data'},
		Promise.all([
			getUserStats({userId: user.id}).then(stats => {
				dispatch(actions._setStats(stats));
			}),
			getPersonalTargets({type: 'call'}).then(targets => {
				dispatch(actions._setTargets(targets));
			}),
			getDashboardCallReminders({userId: user.id}).then(reminders => {
				dispatch(actions._setCallReminders(reminders));
			}),
			getUserTimeEntries().then(te => {
				dispatch(rootActions._setTimeEntries(te));
			}),
		]).then(() => dispatch(actions._opOk())),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let saveTimeEntry = entry => (getState, dispatch) => {
	dispatch(rootActions._startProcessingTimeEntries());
	decorateWithNotifications(
		{
			id: 'update-time-entry',
			loading: intl.formatMessage({id: msgs.processing}),
			failureStyle: 'error',
		},
		updateTimeEntry(entry),
	)(getState, dispatch)
		.then(t => {
			dispatch(rootActions._setTimeEntries(t));
			dispatch(actions.closeTimeEntryModal());
		})
		.catch(e => {
			dispatch(rootActions._stopProcessingTimeEntries());
			throw e;
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
saveTimeEntry = creator('saveTimeEntry', saveTimeEntry);

export let removeTimeEntry = timeEntryId => (getState, dispatch) => {
	const onConfirm = () => {
		dispatch(rootActions._startProcessingTimeEntries());
		decorateWithNotifications(
			{
				id: 'remove-time-entry',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Working hours removed'}),
				failureStyle: 'error',
			},
			deleteTimeEntry(timeEntryId),
		)(getState, dispatch)
			.then(t => {
				dispatch(rootActions._setTimeEntries(t));
				dispatch(actions.closeTimeEntryModal());
			})
			.catch(e => {
				dispatch(rootActions._stopProcessingTimeEntries());
				throw e;
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Confirm deleting the entry'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
removeTimeEntry = creator('removeTimeEntry', removeTimeEntry);

export let startCalling = callPoolId => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'get-next-building',
			failureStyle: e => (e.causedByNoBuildingsAvailable ? 'info' : 'warning'),
			failureDuration: longDur,
		},
		getNextBuilding(callPoolId),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(building => {
			pushPath(`/calls/buildings/${building.id}`, () => ({
				referrer: 'callPool',
				callPoolId,
				allowAutoCall: true,
			}));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
startCalling = creator('startCalling', startCalling);

export let saveCallTarget = target => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'post-call-target',
			loading: intl.formatMessage({id: msgs.processing}),
			failureStyle: 'warning',
		},
		target.id ? updateCallTarget(target) : postCallTarget(target),
	)(getState, dispatch)
		.then(t => dispatch(actions._updateTargets(t)))
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
saveCallTarget = creator('saveCallTarget', saveCallTarget);
