import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	callReminders: null,
	callRemindersLoading: true,
	processing: true,
	startingCall: false,
	stats: null,
	statsLoading: true,
	targets: null,
	targetsLoading: true,
	targetOpen: null,
	timeEntryModalOpen: false,
	timeEntry: {},
};
