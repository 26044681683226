import {FormattedMessage} from '@meiko/react-intl';
import React from 'react';
import Button from './Button';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {applyDispatch, applyState} from 'utils/redux';
import {canSendSmsMessage, canSendCustomMessage} from 'modules/sms/selectors';
import {openSendSmsModal} from 'modules/sms/actions';
import {CUSTOM} from 'modules/sms/constants';

/**
 * Button to send SMS.
 * Checks if user has permission to send SMS before displaying it.
 */
const SendSmsButton = ({
	defaultTemplateKey = '',
	availableTemplateKeys = [],
	receivers = [],
	client = null,
	data = null,
	openSendSmsModal,
	canSendSmsMessage,
	canSendCustomMessage,
	disabled = false,
	children = null,
	appearance = 'success',
	size = 'm',
}) => {
	// NOTE: we disallow opening sms modal if user does not have access to the custom template and it's included
	if (
		!canSendSmsMessage ||
		(availableTemplateKeys.includes(CUSTOM) && !canSendCustomMessage)
	) {
		return null;
	}
	const canOpen = receivers || client;
	const actualReceivers = client ? [client.phone] : receivers;

	return (
		<Button
			size={size}
			disabled={!canOpen || disabled}
			appearance={appearance}
			onClick={() =>
				openSendSmsModal({
					defaultTemplateKey,
					availableTemplateKeys,
					receivers: actualReceivers,
					client,
					data,
				})
			}
		>
			{!children ? <FormattedMessage id="Send SMS [feature]" /> : children}
		</Button>
	);
};

export default compose(
	connect(
		applyState({
			canSendSmsMessage,
			canSendCustomMessage,
		}),

		applyDispatch({
			openSendSmsModal,
		}),
	),
)(SendSmsButton);
