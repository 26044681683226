export const formatProfileFormInput = user => {
	return {
		defaultCall: user.defaultCall,
		// backend uses 'se' code for Swedish, front uses 'sv'
		language: user.language === 'se' ? 'sv' : user.language,
	};
};

export const formatProfileFormOutput = form => {
	return {
		defaultCall: form.defaultCall,
		// backend uses 'se' code for Swedish, front uses 'sv'
		language: form.language === 'sv' ? 'se' : form.language,
		password: form.password,
	};
};
