import {over, lensPath, prop, sortBy} from 'ramda';

export const sortTeamUsers = teams => {
	if (!teams.length) return [];
	return over(lensPath([0, 'users']), sortBy(prop('lastName')), teams);
};

export const formatTeamTargetFormInput = ({team, teamTargets, date}) => {
	const {teamOfferCount, teamSalesPrice, userOfferCounts, userSalesPrices} = teamTargets;

	return {
		date,
		teamOfferCount: teamOfferCount ? teamOfferCount.target : null,
		teamSalesPrice: teamSalesPrice ? teamSalesPrice.target : null,
		users: team
			? team.users.map(u => {
					const salesPrice = userSalesPrices.find(s => s.userId === u.id);
					const offerCount = userOfferCounts.find(o => o.userId === u.id);
					return {
						id: u.id,
						salesPrice: salesPrice ? salesPrice.target : null,
						offerCount: offerCount ? offerCount.target : null,
					};
			  })
			: [],
	};
};

export const formatTeamTargetFormOutput = ({form, teamId}) => {
	const targets = [];
	const [year, month] = form.date.split('-');

	// add team targets
	targets.push({
		type: 'salesPrice',
		timespan: 'month',
		target: form.teamSalesPrice || 0,
		year,
		month,
		teamId,
	});
	targets.push({
		type: 'offerCount',
		timespan: 'month',
		target: form.teamOfferCount || 0,
		year,
		month,
		teamId,
	});

	// add team users targets
	form.users.forEach(u => {
		targets.push({
			type: 'salesPrice',
			timespan: 'month',
			target: u.salesPrice || 0,
			year,
			month,
			userId: u.id,
		});
		targets.push({
			type: 'offerCount',
			timespan: 'month',
			target: u.offerCount || 0,
			year,
			month,
			userId: u.id,
		});
	});

	return targets;
};

export const isValidDate = date => {
	const dateFormat = /([12]\d{3}-(0[1-9]|1[0-2]))/;
	return dateFormat.test(date);
};
