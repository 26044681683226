import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import ClearCollapse from 'components/generic/ClearCollapse';

// the height of the inner div is taking the parent's negative vertical margins into account, since its own margins already consume them
const Wrapper = styled.div`
	flex-basis: 0;
	flex-grow: ${({size = 1}) => size};

	> div {
		margin: 10px 10px;
		height: calc(100% - 20px);
	}
`;

const Col = ({children, clearCollapse = true, ...rest}) => (
	<Wrapper {...rest}>
		<div>
			{clearCollapse && <ClearCollapse />}
			{children}
			{clearCollapse && <ClearCollapse />}
		</div>
	</Wrapper>
);

Col.propTypes = {
	children: propTypes.node,
	size: propTypes.number,
	clearCollapse: propTypes.bool,
};

export default styled(Col)``;
