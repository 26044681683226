import {path, pipe, prop, isEmpty} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import * as commonSelectors from 'modules/common/selectors';
import {getQueries, getWeeklyTargets} from './utils';
import * as perms from 'utils/perms';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const userTeam = pipe(root, prop('userTeam'));

export const statsQuery = pipe(root, prop('statsQuery'));

export const statsType = createSelector([statsQuery], statsQuery => statsQuery.statsType);

export const selectedDate = createSelector(
	[statsQuery],
	statsQuery => statsQuery.selectedDate,
);

export const urlQuery = createSelector([statsQuery], statsQuery => statsQuery);

export const initialized = pipe(root, prop('initialized'));

export const processing = pipe(root, prop('processing'));

export const monthSelectorInitValues = pipe(root, prop('monthSelectorInitValues'));

export const statistics = pipe(root, prop('statistics'));

export const salesPriceTarget = pipe(root, prop('salesPriceTarget'));

export const offerCountTarget = pipe(root, prop('offerCountTarget'));

export const statsLoading = pipe(root, prop('statsLoading'));

export const targetsLoading = pipe(root, prop('targetsLoading'));

export const weeklySalesPriceTargets = createSelector(
	[statistics, salesPriceTarget],
	(stats, target) =>
		!isEmpty(stats) && !isEmpty(target) ? getWeeklyTargets(target, stats) : [],
);

export const weeklyOfferCountTargets = createSelector(
	[statistics, offerCountTarget],
	(stats, target) =>
		!isEmpty(stats) && !isEmpty(target) ? getWeeklyTargets(target, stats) : [],
);

export const allQueries = createSelector(
	[
		commonSelectors.user,
		commonSelectors.activeOrganizationId,
		userTeam,
		statsType,
		selectedDate,
	],
	(user, orgId, userTeam, statsType, selectedDate) =>
		getQueries({user, orgId, userTeam, statsType, date: selectedDate}),
);

export const isSalesmanagerUser = createSelector([commonSelectors.user], user =>
	perms.isSalesmanagerUser(user),
);

export const isAdminUser = createSelector([commonSelectors.user], user =>
	perms.isSalesmanAppAdmin(user),
);
