import {scopedHandler} from 'utils/redux';
import {getFormValues} from 'redux-form';
import ns from './namespace';
import initState from './state';
import {assoc} from 'ramda';
import {over} from 'utils/lenses';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.changePage.type: {
			const newState = {
				...state,
				noticesLoading: true,
				noticesQuery: {...state.noticesQuery, _page: payload},
			};

			return [newState, effects.pageChanged()];
		}

		case actions.changeSort.type: {
			const newState = over(['noticesQuery'], assoc('_sort', payload), {
				...state,
				noticesLoading: true,
			});

			return [newState, effects.updateNotices()];
		}

		case actions.removeNotice.type: {
			return [state, effects.removeNotice(payload)];
		}

		case actions.toggleNoticeCreator.type: {
			const newState = {
				...state,
				noticeCreatorOpen: !state.noticeCreatorOpen,
				noticeFormInitValues: payload ? payload : initState.noticeFormInitValues,
				noticeInEdit: payload ? payload.id : initState.noticeInEdit,
			};
			return [newState, null];
		}

		case actions.saveNewNotice.type: {
			const values = getFormValues('noticeForm')(fullState) || {};
			const organizations =
				values.organizations && values.organizations.length
					? values.organizations.map(o => ({id: o}))
					: null;
			const teams =
				values.teams && values.teams.length ? values.teams.map(t => ({id: t})) : null;
			const roles =
				values.roles && values.roles.length ? values.roles.map(r => ({id: r})) : null;
			const notice = {...values, organizations, teams, roles};

			return [{...state, processing: true}, effects.saveNewNotice(notice)];
		}

		case actions.editNotice.type: {
			const values = getFormValues('noticeForm')(fullState) || {};
			const organizations =
				values.organizations && values.organizations.length
					? values.organizations.map(o => ({id: o}))
					: null;
			const teams =
				values.teams && values.teams.length ? values.teams.map(t => ({id: t})) : null;
			const roles =
				values.roles && values.roles.length ? values.roles.map(r => ({id: r})) : null;
			const notice = {...values, organizations, teams, roles};

			return [
				{...state, processing: true},
				effects.editNotice({notice, id: state.noticeInEdit}),
			];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._setInitData.type: {
			return [{...state, ...payload}, null];
		}

		case actions._setNotices.type: {
			const {data, pagination} = payload;
			const newState = {
				...state,
				notices: data,
				noticesPagination: pagination,
				noticesLoading: false,
			};

			return [newState, null];
		}

		case actions._updateNoticesQuery.type: {
			const newState = {
				...state,
				noticesLoading: true,
				noticesQuery: {...state.noticesQuery, ...payload},
			};
			return [newState, null];
		}

		case actions._noticeSaved.type: {
			return [{...state, processing: false, noticeCreatorOpen: false}, null];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false, noticeCreatorOpen: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
