import React from 'react';

const SvgPhone = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M11.567 8.604l-1.28-1.28a1.467 1.467 0 0 0-2.076 0l-.26.261A28.366 28.366 0 0 1 4.4 4.031l.261-.26a1.47 1.47 0 0 0 0-2.076L3.38.417a1.504 1.504 0 0 0-2.078 0l-.699.702a2.077 2.077 0 0 0-.26 2.606 28.266 28.266 0 0 0 7.916 7.918 2.095 2.095 0 0 0 2.609-.261l.702-.702a1.466 1.466 0 0 0 0-2.075h-.003z"
		/>
	</svg>
);

export default SvgPhone;
