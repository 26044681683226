import {pipe, prop, map} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {getResponseData} from 'utils/app';
import {removalRequestInclude, encounterInclude} from './constants';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getRemovalRequest = (id, query = {}) =>
	httpJson('get', `/removalRequests/${id}`, {
		...query,
		include: removalRequestInclude,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), normalize.removalRequest));

export const putRemovalRequest = request =>
	httpJson('put', `/removalRequests/${request.id}`, {}, {body: request})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.removalRequest));

export const deleteRemovalRequest = id =>
	httpJson('delete', `/removalRequests/${id}`).catch(
		describeThrow(intl.formatMessage({id: msgs.deleteFailed})),
	);

export const getEncounter = encounterId =>
	httpJson('get', `/encounters/${encounterId}`, {include: encounterInclude})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), normalize.encounter));

export const postComment = comment =>
	httpJson('post', '/removalRequestComments', {}, {body: comment})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentPostFailed})))
		.then(getResponseData(normalize.removalRequestComment));

export const updateComment = (comment, id) =>
	httpJson('put', `/removalRequestComments/${id}`, {}, {body: comment})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.removalRequestComment));

export const deleteComment = id =>
	httpJson('delete', `/removalRequestComments/${id}`).catch(
		describeThrow(intl.formatMessage({id: msgs.deleteFailed})),
	);

export const removalRequestClientSearch = (query = {}) =>
	httpJson('get', '/removalRequestClientSearch', {
		include: 'building.allEncounters.organization,building.allEncounters.source.user',
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load clients'})))
		.then(getResponseData(map(normalize.client)));
