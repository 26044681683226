import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const navigateToTargetPage = creator('navigateToTargetPage', P.Object);

export const setStatsType = creator('setStatsType');

export const fetchData = creator('fetchData');

export const _initialize = creator('_initialize');

export const _setTargets = creator('_setTargets', P.Array);

export const _setStatistics = creator('_setStatistics');

export const _setUserTeam = creator('_setUserTeam');

export const _updateStatsQuery = creator('_updateStatsQuery', P.Object);
