import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import initState from './state';
import {parseNullableNumber} from 'utils/fields';

const parseNullableJson = deflt => str => str !== '' ? JSON.parse(str) : deflt;

export const parseUrlQuery = query => {
	const vals = evolve(
		{
			z: Number,
			x: Number,
			y: Number,
			maxYear: parseNullableNumber,
			minYear: parseNullableNumber,
			selectionId: parseNullableNumber,
			groundwaterAreasLayer: parseNullableJson(false),
			propertyLinesLayer: parseNullableJson(false),
			buildingMarkersLayer: parseNullableJson(false),
		},
		query,
	);
	return {buildingsQuery: replace(vals, initState.buildingsQuery)};
};

export const formatUrlQuery = ({buildingsQuery}) => buildingsQuery;
