import {action} from 'utils/redux';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const saveSettings = creator('saveSettings');

export const _initialize = creator('_initialize');

export const _setTeams = creator('_setTeams');

export const _setSettings = creator('_setSettings');
