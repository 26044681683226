import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';

export default scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.openSendSmsModal.type: {
			return [
				{
					...state,
					smsTemplatesLoadingFailed: false,
					isSendSmsModalOpen: true,
				},
				effects.openSendSmsModal(payload),
			];
		}

		case actions._openSendSmsModal.type: {
			return [
				{
					...state,
					smsDraft: payload,
				},
			];
		}

		case actions.closeSendSmsModal.type: {
			return [
				{
					...state,
					isSendSmsModalOpen: false,
					smsDraft: null,
				},
				null,
			];
		}

		case actions.sendSms.type: {
			return [
				{
					...state,
					processing: true,
				},
				effects.sendSms(payload),
			];
		}

		case actions._sendSms.type: {
			return [
				{
					...state,
					processing: false,
				},
				null,
			];
		}

		case actions.smsTemplatesLoadingFailed.type: {
			return [
				{
					...state,
					smsTemplatesLoadingFailed: payload,
				},
			];
		}

		default:
			return [state, null];
	}
});
