import {mergeLeft, equals} from 'ramda';
import {decorateWithNotifications} from 'io/app';
import {catchNonFatalDefault, logWarning} from 'io/errors';
import {geocodeGooglePlaceId} from 'io/geo';
import {transform} from 'ol/proj';
import {P} from 'utils/types';
import {over} from 'utils/lenses';
import {effect} from 'utils/redux';
import {describeThrow} from 'utils/errors';
import services from 'services';
import * as rootSelectors from 'modules/common/selectors';
import * as actions from './actions';
import * as selectors from './selectors';
import * as confirmerActions from 'modules/confirmer/actions';
import cache from './cache';
import namespace from './namespace';
import {
	initTeamAreasMap as doInitTeamAreasMap,
	getAreasSource,
	getUserTeams,
	updateTeam,
	getAreasStyle,
	getTeams,
} from './io';
import {getTeamArea, parseUrlQuery} from './utils';
import {areaFocusZoom} from 'constants/maps';
import {getAllJsonProperties} from 'utils/maps';
import msgs from 'dicts/messages';
import {getQuery} from 'io/history';

const history = services.get('history');

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const selectTeamArea = area => (getState, dispatch) => {
	const team = selectors.team(getState());
	const teamArea = getTeamArea({team, areaId: area.id});
	if (!teamArea) {
		// team doesn't have the selected area, ask to add it as temp area
		dispatch(actions.openAddAreaModal(area));
	} else if (teamArea.temporaryArea) {
		// team has the area and it's temporary, ask to remove it
		dispatch(actions.removeTeamArea(teamArea));
	}
};

const initTeamAreasMap = (getState, dispatch) => {
	const apiToken = rootSelectors.apiToken(getState());
	const org = rootSelectors.activeOrganization(getState());
	const onAreaClick = feature =>
		selectTeamArea(getAllJsonProperties(feature))(getState, dispatch);

	return doInitTeamAreasMap({
		apiToken,
		organizationId: org.id,
		areasType: 'city',
		getTeam: () => selectors.team(getState()),
		onAreaClick,
	});
};

const _updateTeamAreas = ({team, notifyOpts = {}}) => (getState, dispatch) => {
	return decorateWithNotifications(
		{
			id: 'update-team-areas',
			failureStyle: 'error',
			...notifyOpts,
		},
		updateTeam(team),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(team => {
			dispatch(actions._updateTeamAreas(team));

			// update map
			const {
				teamAreasMap: {areasLayer},
			} = cache.read();
			if (areasLayer) {
				getAreasStyle({team: selectors.team(getState())})
					.then(x => areasLayer.setStyle(x))
					.catch(logWarning);
			}
		});
};

export let recheckQuery = () => (getState, dispatch) => {
	const urlQueries = parseUrlQuery(getQuery());
	const team = selectors.team(getState());

	if (!team || equals(team.id, urlQueries.teamId)) {
		window.location.reload();
	}
};

export let initialize = () => (getState, dispatch) => {
	const {teamId} = parseUrlQuery(getQuery());
	const salesmanAppTeamAreaTeamSelect = selectors.showSalesmanAppTeamAreaTeamSelect(
		getState(),
	);

	decorateWithNotifications(
		{
			id: 'init-salesman-app-team-areas',
			failureStyle: 'error',
		},
		salesmanAppTeamAreaTeamSelect
			? getTeams().then(teams => {
					if (teams.length) {
						dispatch(actions._setTeams(teams));
						if (teamId) {
							const team = teams.filter(t => t.id === teamId)[0];
							if (team) {
								dispatch(actions._setTeam(team));
							}
						} else {
							dispatch(actions._setTeam(teams[0]));
						}
					}
			  })
			: getUserTeams().then(teams => {
					if (teams.length) {
						dispatch(actions._setTeam(teams[0]));
					}
			  }),
	)(getState, dispatch)
		.then(_ => initTeamAreasMap(getState, dispatch))
		.then(res => {
			cache.update(over(['teamAreasMap'], mergeLeft(res)));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let destroy = () => (getState, dispatch) => {
	cache.reset();
};
destroy = creator('destroy', destroy);

export let setTeamAreasMapType = type => (getState, dispatch) => {
	const apiToken = rootSelectors.apiToken(getState());
	const {
		teamAreasMap: {areasLayer},
	} = cache.read();
	getAreasSource({apiToken, areasType: type})
		.then(x => areasLayer.setSource(x))
		.catch(catchNonFatalDefault(getState, dispatch));
};
setTeamAreasMapType = creator('setTeamAreasMapType', setTeamAreasMapType, P.String);

export let openPlacesSuggestion = placeId => (getState, dispatch) => {
	geocodeGooglePlaceId(placeId)
		.then(res => {
			const {lat, lng} = res.geometry.location;
			const coord = transform([lng(), lat()], 'EPSG:4326', 'EPSG:3857');
			const {
				teamAreasMap: {map},
			} = cache.read();
			const view = map.getView();
			view.animate({center: coord, zoom: areaFocusZoom});
		})
		.catch(describeThrow(intl.formatMessage({id: 'Search failed'})))
		.catch(catchNonFatalDefault(getState, dispatch));
};
openPlacesSuggestion = creator('openPlacesSuggestion', openPlacesSuggestion, P.String);

export let updateTeamAreas = team => (getState, dispatch) => {
	_updateTeamAreas({
		team,
		notifyOpts: {
			loading: intl.formatMessage({id: 'Saving'}),
			success: intl.formatMessage({id: 'Area added for the team'}),
		},
	})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
updateTeamAreas = creator('updateTeamAreas', updateTeamAreas, P.Object);

export let removeTeamArea = area => (getState, dispatch) => {
	const onConfirm = () => {
		const team = selectors.team(getState());
		const updatedTeam = {...team, areas: team.areas.filter(a => a.id !== area.id)};

		dispatch(actions._startOp());

		_updateTeamAreas({
			team: updatedTeam,
			notifyOpts: {
				loading: msgs.processing,
				success: intl.formatMessage({id: 'Area removed from team'}),
			},
		})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage(
				{id: 'Delete temporary area "{area}" from the team?'},
				{area: area.title},
			),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
removeTeamArea = creator('removeTeamArea', removeTeamArea, P.Object);

export let changeTeam = team => (getState, dispatch) => {
	history.push(`team-areas?teamId=${team}`);
};
changeTeam = creator('changeTeam', changeTeam);
