import {effect} from 'utils/redux';
import namespace from './namespace';
import * as actions from './actions';
import services from 'services';
import {decorateWithNotifications, setPageTitleMessage} from 'io/app';
import * as nActions from 'modules/notifications/actions';
import {medDur} from 'constants/notifications';
import {parseBothDates, parseDate} from './utils';
import {
	getOrganizations as getOrganizationsIO,
	getUsers,
	searchCallLogs as searchCallLogsIO,
	getTeams as getTeamsIO,
	fetchTeamWithUsers as fetchTeamWithUsersIO,
	fetchTeamsWithUsers as fetchTeamsWithUsersIO,
} from './io';
import {getQuery} from 'io/history';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const creator = effect(namespace);

export let getTeams =
	(teamType = 'all') =>
	(getState, dispatch) => {
		const teamTypeQuery = teamType !== 'all' ? `&type=${teamType}` : '';

		return decorateWithNotifications(
			{
				id: 'fetch-teams',
				failuterStyle: 'error',
			},
			getTeamsIO(teamTypeQuery),
		)(getState, dispatch).then(result => {
			dispatch(actions._setTeams(result));
		});
	};

getTeams = creator('getTeams', getTeams);

export let getAccounts = () => (getState, dispatch) => {
	return decorateWithNotifications(
		{
			id: 'fetch-accounts',
			failureStyle: 'error',
		},
		getUsers(),
	)(getState, dispatch).then(result => {
		dispatch(actions._setUsers(result));
	});
};

getAccounts = creator('getAccounts', getAccounts);

export let getOrganizations = () => (getState, dispatch) => {
	return decorateWithNotifications(
		{
			id: 'fetch-accounts',
			failureStyle: 'error',
		},
		getOrganizationsIO(),
	)(getState, dispatch).then(result => {
		dispatch(actions._setOrganizations(result));
	});
};

getOrganizations = creator('getOrganizations', getOrganizations);

export let initialize = () => (getState, dispatch) => {
	setPageTitleMessage('Overview [app]')(getState, dispatch);
	dispatch(actions._initialize());
};
initialize = creator('initialize', initialize);

export let searchCallLogs = () => (getState, dispatch) => {
	const store = services.get('store');
	const q = getQuery();
	dispatch(actions._setTrackingData([]));

	if (q.date.length <= 0 || q.teams.length <= 0) {
		let warnMsg = `${intl.formatMessage({id: 'Required field'})} - `;

		if (q.date.length <= 0) {
			warnMsg = warnMsg.concat(
				`${intl.formatMessage({id: 'Date'})} ${intl.formatMessage({
					id: 'From [date from]',
				})}`,
			);
		}

		if (q.teams.length <= 0) {
			warnMsg = warnMsg.concat(
				`${q.date.length <= 0 ? ', ' : ''}${intl.formatMessage({id: 'Teams'})}`,
			);
		}

		store.dispatch(
			nActions.warning({
				id: '',
				message: warnMsg,
				duration: medDur,
			}),
		);

		return;
	}

	let date = '';
	if (q?.date.length > 0 && q?.dateTo.length > 0) {
		date = parseBothDates(q?.date, q?.dateTo);
	} else if (q?.date.length > 0 && q?.dateTo.length < 1) {
		date = parseDate(q?.date);
	}

	// NOTE: Should be refactored to use just providing the query directly as object to the IO function
	const textQuery = `?teams=${q.teams}&${date}`;

	return decorateWithNotifications(
		{
			id: 'search-call-logs',
			failureStyle: 'error',
		},
		searchCallLogsIO(textQuery),
	)(getState, dispatch).then(result => {
		dispatch(actions._setTrackingData(result));
		dispatch(actions._initialize());
	});
};

searchCallLogs = creator('searchCallLogs', searchCallLogs);

export let fetchTeamWithUsers = data => (getState, dispatch) => {
	const store = services.get('store');
	const teamId = data.toString();

	if (!teamId) {
		const invalidTeamIdMsg =
			intl.formatMessage({id: 'Required field'}) +
			' - ' +
			intl.formatMessage({id: 'Team'});
		store.dispatch(
			nActions.warning({
				id: '',
				message: invalidTeamIdMsg,
				duration: medDur,
			}),
		);
		return;
	}
	return decorateWithNotifications(
		{
			id: 'get-team-with-users',
			failureStyle: 'error',
		},
		fetchTeamWithUsersIO(teamId),
	)(getState, dispatch).then(result => {
		dispatch(actions._setUsers(result.users.data));
	});
};

fetchTeamWithUsers = creator('fetchTeamWithUsers', fetchTeamWithUsers);

export let fetchTeamsWithUsers = teamIds => (getState, dispatch) => {
	const store = services.get('store');

	if (!Array.isArray(teamIds)) {
		const invalidTeamIdMsg =
			intl.formatMessage({id: 'Required field'}) +
			' - ' +
			intl.formatMessage({id: 'Team'});
		store.dispatch(
			nActions.warning({
				id: '',
				message: invalidTeamIdMsg,
				duration: medDur,
			}),
		);
		return;
	}

	if (teamIds.length <= 0) {
		return dispatch(actions._setUsers([]));
	}

	return decorateWithNotifications(
		{
			id: 'get-team-with-users',
			failureStyle: 'error',
		},
		fetchTeamsWithUsersIO(teamIds.toString()),
	)(getState, dispatch).then(result => {
		const users = [];
		result.forEach(team => {
			team.users?.data.forEach(user => {
				if (!users.some(u => u.id === user.id)) users.push(user);
			});
		});

		dispatch(actions._setUsers(users));
	});
};

fetchTeamsWithUsers = creator('fetchTeamsWithUsers', fetchTeamsWithUsers);

export let setTeamType = teamType => (getState, dispatch) => {
	dispatch(actions._setTeamType(teamType));
	getTeams(teamType)(getState, dispatch);
};

setTeamType = creator('setTeamType', setTeamType);
