import {action} from 'utils/redux';
import namespace from './namespace';
import {P, Nullable} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const fetchTimeEntries = creator('fetchTimeEntries');

export const setSelectedUser = creator('setSelectedUser', Nullable(P.Number));

export const createTimeEntry = creator('createTimeEntry', P.Object);

export const deleteTimeEntry = creator('deleteTimeEntry');

export const updateTimeEntry = creator('updateTimeEntry', P.Object);

export const openTimeEntriesCreator = creator('openTimeEntriesCreator');

export const closeTimeEntriesCreator = creator('closeTimeEntriesCreator');

export const openTimeEntriesEditor = creator('openTimeEntriesEditor', P.Object);

export const closeTimeEntriesEditor = creator('closeTimeEntriesEditor');

export const getDayInfo = creator('getDayInfo', P.String);

export const _setUsers = creator('setUsers');

export const _setTimeEntries = creator('_setTimeEntries');

export const _timeEntryCreated = creator('_timeEntryCreated');

export const _timeEntryUpdated = creator('_timeEntryUpdated');

export const _timeEntryDeleted = creator('_timeEntryDeleted');

export const _startOp = creator('_startOp');

export const _opFailed = creator('_opFailed');

export const _initialize = creator('_initialize');

export const _updateTimeEntriesQuery = creator('_updateTimeEntriesQuery', P.Object);

export const _setSelectedUserId = creator('_setSelectedUserId', Nullable(P.Number));
