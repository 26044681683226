import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const reInitialize = creator('reInitialize');

export const destroy = creator('destroy');

export const saveLead = creator('saveLead', P.Boolean);

export const searchBuildings = creator('searchBuildings', P.Object);

export const setBuilding = creator('setBuilding');

export const setProcessing = creator('setProcessing');

export const resetForm = creator('resetForm');

export const openAddBuildingModal = creator('openAddBuildingModal', P.Object);

export const closeAddBuildingModal = creator('closeAddBuildingModal');

export const openMapModal = creator('openMapModal', P.Object);

export const closeMapModal = creator('closeMapModal');

export const addBuilding = creator('addBuilding');

export const addClientToBuilding = creator('addClientToBuilding');

export const restoreLead = creator('restoreLead', P.Number);

export const updateClientData = creator('updateClientData');

export const copyLeadToClient = creator('copyLeadToClient');

export const resetFormField = creator('resetFormField');

export const openEventModal = creator('openEventModal');

export const closeEventModal = creator('closeEventModal');

export const saveLeadEvent = creator('saveLeadEvent');

export const _clientSaved = creator('_clientSaved');

export const _setBuildingToAddCoords = creator('_setBuildingToAddCoords', P.Array);

export const _addBuilding = creator('_addBuilding');

export const _addClient = creator('_addClient');

export const _initialize = creator('_initialize');

export const _opFailed = creator('_opFailed');

export const _setBuildings = creator('_setBuildings');

export const _setProducts = creator('_setProducts');

export const _setLead = creator('_setLead');

export const _leadEventAdded = creator('_leadEventAdded');

export const _setMarketingLeadTags = creator('_setMarketingLeadTags');

export const _setHandlers = creator('_setHandlers', P.Array);
