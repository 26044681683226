// core dependencies
import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
// custom helpers & utils
import {textColorBase, linkColor, screenMdMin, fontWeightMedium} from 'styles/constants';
import {textStyles} from 'styles/fragments';
import {FormattedDate} from '@meiko/react-intl';
import {setUtcHour} from 'utils/time';
// components
import H3 from 'components/generic/H3';
import Caption from 'components/generic/Caption';

const dateTimeFmtOpts = {
	day: 'numeric',
	month: 'numeric',
	year: 'numeric',
};

const ContentText = styled.div`
	${textStyles.base};

	strong {
		font-weight: ${fontWeightMedium};
	}
`;

const NoticeTitle = styled(H3)`
	margin-top: 6px;
	margin-bottom: 11px;
`;

const Wrapper = styled.div`
	color: ${textColorBase};
	margin: 4px 0px;
	display: block;
	border: ${({newNotice}) => (newNotice ? `1px solid ${linkColor}` : `none`)};
	border-radius: 4px;
	padding: 15px 10px;
	flex-direction: column;
	height: auto;
	> h2 {
		padding: 5px 0px;
	}
	:nth-of-type(n + 5) {
		animation: newNotice 5s ease-in-out 1;
		border-radius: 5px;

		@keyframes newNotice {
			0% {
				background-color: #e8e9eb;
			}
			100% {
				background-color: transparent;
			}
		}
	}

	@media (min-width: ${screenMdMin}) {
		margin: 5px -20px;
		padding: 15px 20px;
	}
`;

const Alert = styled.span`
	padding: 0px 10px;
	height: 25px;
	margin-top: auto;
	margin-bottom: auto;
	color: transparent;

	animation: notification 10s ease-in-out 1;

	@keyframes notification {
		0% {
			color: red;
		}
		100% {
			color: transparent;
		}
	}
`;

const Notice = ({date, header, content, userLogin, ...rest}) => (
	<Wrapper
		{...rest}
		newNotice={
			setUtcHour(new Date(userLogin), 0, 0, 0, 0) <=
			setUtcHour(new Date(date), 0, 0, 0, 0)
		}
	>
		<Caption block>
			<FormattedDate value={date ? date : new Date()} {...dateTimeFmtOpts} />
		</Caption>
		<NoticeTitle>
			{header}{' '}
			{new Date(userLogin) <= new Date(date) ? (
				<Alert>
					<i className="fa fa-exclamation-circle" />
				</Alert>
			) : null}
		</NoticeTitle>
		<ContentText dangerouslySetInnerHTML={{__html: content}} />
	</Wrapper>
);

Notice.propTypes = {
	date: propTypes.string,
	header: propTypes.any,
	content: propTypes.string,
	userLogin: propTypes.string,
};

export default styled(Notice)``;
