import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {
	getRoutesFromCalendarResources,
	sortByDateFrom,
	getNextPossibleRoutes,
} from './utils';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.toggleRouteVisibility.type: {
			const newState = {
				...state,
				routes: state.routes.map(r =>
					r.routeNum === payload.routeNum ? {...r, visible: payload.visible} : r,
				),
			};

			return [newState, effects.toggleRouteVisibility(payload)];
		}

		case actions.createRoutes.type: {
			return [{...state, processing: true}, effects.createRoutes(payload)];
		}

		case actions.setRouteSalesman.type: {
			const newState = {
				...state,
				// prettier-ignore
				routes: state.routes.map(r =>
					r.routeNum === payload.routeNum ? {...r, salesmanId: payload.salesmanId}
						// if same salesman already selected on another route, clear that selection
						: r.salesmanId === payload.salesmanId ? {...r, salesmanId: null}
						: r,
				)
			};

			return [newState, null];
		}

		case actions.updateMarker.type: {
			const {calendarResource, clearAll} = payload;

			const {location} = calendarResource.building;

			const {routes, extra} = getNextPossibleRoutes({
				routes: state.routes,
				calRes: calendarResource,
				extra: state.extra,
				clearAll,
			});

			const nextRoutes = [...routes, ...extra]
				.map(r => {
					return r.calendarResources
						? r.calendarResources.filter(cr => cr.closeBy)
						: r.closeBy
						? r
						: null;
				})
				.flat();

			return [
				{...state, routes, extra, processingCloseByRoutes: false},
				effects.updateMarker({
					location,
					calRes: payload,
					clearAll,
					markers: state.markers,
					nextRoutes,
				}),
			];
		}

		case actions._setMarkers.type: {
			const markers = payload;
			return [{...state, markers, processingCloseByRoutes: false}, null];
		}

		case actions.setItemRoute.type: {
			const {calendarResource, newRoute, prevRoute} = payload;

			const newRoutes = state.routes.map(r => {
				if (newRoute && r.routeNum === newRoute.routeNum) {
					// add CR to new route
					return {
						...r,
						calendarResources: sortByDateFrom([...r.calendarResources, calendarResource]),
					};
				} else if (prevRoute && r.routeNum === prevRoute.routeNum) {
					// remove CR from prev route
					return {
						...r,
						calendarResources: sortByDateFrom(
							r.calendarResources.filter(cr => cr.id !== calendarResource.id),
						),
					};
				}
				return r;
			});

			// prettier-ignore
			const newExtra = !prevRoute ? sortByDateFrom(state.extra.filter(e => e.id !== calendarResource.id))
				: !newRoute ? sortByDateFrom([...state.extra, {...calendarResource, distanceToNext: null, durationToNext: null}])
				: state.extra;

			const newState = {
				...state,
				routes: newRoutes,
				extra: newExtra,
				processingRoutes: true,
			};

			return [newState, effects.updateRoutes()];
		}

		case actions.focusRoute.type: {
			const newState = {
				...state,
				routes: state.routes.map(r =>
					r.routeNum === payload ? {...r, visible: true} : r,
				),
			};

			return [newState, effects.focusRoute(payload)];
		}

		case actions.saveRoutes.type: {
			return [{...state, processing: true}, effects.saveRoutes()];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._updateCalendarQuery.type: {
			return [{...state, calendarQuery: {...state.calendarQuery, ...payload}}, null];
		}

		case actions._setCalendarResources.type: {
			return [
				{
					...state,
					calendarResources: payload,
					routes: getRoutesFromCalendarResources(payload),
				},
				null,
			];
		}

		case actions._setRoutes.type: {
			const routes = payload.map(r => ({
				...r,
				calendarResources: sortByDateFrom(r.calendarResources),
			}));

			return [{...state, routes, processingRoutes: false}, null];
		}

		case actions._setExtra.type: {
			return [{...state, extra: sortByDateFrom(payload)}, null];
		}

		case actions._setTeam.type: {
			return [{...state, team: payload}, null];
		}

		case actions._createRoutes.type: {
			return [{...state, processing: false, routesCreated: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
