import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	processing: true,
	initialized: false,
	salesPriceTarget: {target: 0},
	offerCountTarget: {target: 0},
	statistics: {},
	statsLoading: true,
	targetsLoading: true,
	statsQuery: {
		statsType: 'user',
		selectedDate: new Date().toISOString().split('T')[0].slice(0, -3),
	},
	userTeam: null,
};
