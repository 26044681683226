import {increaseDay} from 'utils/time';

export const formatCallReminderFormInput = ({callReminder}) => {
	const tomorrow = increaseDay(new Date(), 1);

	return {
		callAt: callReminder.callAt ? new Date(callReminder.callAt) : tomorrow,
		clientId: callReminder.clientId,
		description: callReminder.description,
		sendReminderMail: callReminder.sendReminderMail,
	};
};
