import services from 'services';
import {map} from 'ramda';
import {describeThrow} from 'utils/errors';
import {condoSearchQueryBase, buildingInclude} from './constants';
import * as normalize from 'utils/normalize';
import {mapResponseData, getResponseData} from 'utils/app';
import msgs from 'dicts/messages';
import {encodeQuery} from 'utils/url';
import importMaps from 'services/importMaps';
import {apiUrl} from 'constants/app';
import {
	styles as mapStyles,
	maxZoom,
	localeCenterWeb,
	localeZoomFactor,
	areasMaxDetailZoom,
} from 'constants/maps';
import {deleteChildren} from 'utils/dom';
import {
	safeGetFeaturesAtPixel,
	safeHasFeatureAtPixel,
	addTooltipToLayers,
	renderAreaTooltip,
	_getMapTileSource,
} from 'io/maps';
import {getJsonProperty, getJsonProperties} from 'utils/maps';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const decorateMapMethod = method => args =>
	importMaps()
		.then(method(args))
		.catch(describeThrow(intl.formatMessage({id: 'Error loading map'})));

export const getCondos = query =>
	httpJson('get', '/condominiums', {
		...condoSearchQueryBase,
		include: buildingInclude,
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.building)));

export const getBuilding = id =>
	httpJson('get', `/buildings/${id}`, {
		include: buildingInclude,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(normalize.building));

export const createVisit = visit =>
	httpJson('post', '/visits', {}, {body: visit}).catch(
		describeThrow(intl.formatMessage({id: 'Failed to save visit'})),
	);

export const getProducts = () =>
	httpJson('get', '/products/all', {_limit: '999'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.product)));

// maps

const initZoom = 4 * localeZoomFactor;

const _getAreasStyle = ({selectedAreaIds}) => imports => {
	const {openLayers: ol} = imports;

	const sty = mapStyles(ol);

	return feature =>
		new ol.style.Style({
			fill: selectedAreaIds.has(getJsonProperty(feature, 'id'))
				? sty.areasHlFill
				: sty.areasFill,
			stroke: sty.areasStroke,
			// note: could move labels into a separate json layer at some point
			text: new ol.style.Text({
				text: getJsonProperty(feature, 'title'),
				fill: sty.areasTextFill,
				stroke: sty.areasTextStroke,
				font: sty.areasFont,
			}),
		});
};
export const getAreasStyle = decorateMapMethod(_getAreasStyle);

const createAreasLayer = ({apiToken, areasType, selectedAreaIds}) => imports => {
	const {openLayers: ol} = imports;

	const layer = new ol.layer.VectorTile({
		source: _getAreasSource({apiToken, areasType})(imports),
		style: _getAreasStyle({selectedAreaIds})(imports),
	});

	return layer;
};

const _getAreasSource = ({apiToken, areasType}) => imports => {
	const {openLayers: ol} = imports;

	return new ol.source.VectorTile({
		format: new ol.format.MVT({dataProjection: 'EPSG:4326'}),
		url: `${apiUrl}/maps/areas/mvt/{z}/{x}/{y}${encodeQuery({
			type: areasType,
			token: apiToken,
		})}`,
		maxZoom: areasMaxDetailZoom,
	});
};
export const getAreasSource = decorateMapMethod(_getAreasSource);

const _initAreasMap = ({
	apiToken,
	areasType,
	selectedAreaIds,
	onAreaClick,
}) => imports => {
	// keep some local state
	let currHoverAreaId = null;

	const {openLayers: ol} = imports;

	const mapEl = document.querySelector('#areas-selection-map');
	if (!mapEl) {
		return Promise.reject(new Error('Map element not found'));
	}

	const view = new ol.View({
		projection: 'EPSG:3857',
		center: localeCenterWeb,
		zoom: initZoom,
		maxZoom,
		enableRotation: false,
		constrainResolution: true,
	});

	const areasLayer = createAreasLayer({apiToken, areasType, selectedAreaIds})(imports);

	const map = new ol.Map({
		target: 'areas-selection-map',
		view,
		layers: [
			new ol.layer.Tile({
				source: _getMapTileSource({sourceId: 'here-light'})(imports),
			}),
			// TODO: generic toggling for google maps - MVT layers don't work with it so need to use geojson sources and strict min zooms
			//new olgm.layer.Google(),
			areasLayer,
		],
		//interactions: olgm.interaction.defaults(),
	});

	const areaTooltipEl = document.getElementById('areas-selection-map-area-tooltip');

	const areaTooltip = new ol.Overlay({
		element: areaTooltipEl,
		positioning: 'bottom-center',
		offset: [0, -5],
	});

	const updateAreaTooltip = areaFeature => {
		const {id, title, subtitle} = getJsonProperties(areaFeature, [
			'id',
			'title',
			'subtitle',
		]);
		if (id === currHoverAreaId) return;
		currHoverAreaId = id;
		deleteChildren(areaTooltipEl);
		renderAreaTooltip({tooltipEl: areaTooltipEl, title, subtitle});
	};

	addTooltipToLayers({
		mapEl,
		map,
		layers: [areasLayer],
		tooltipEl: areaTooltipEl,
		tooltip: areaTooltip,
		updateTooltip: updateAreaTooltip,
	});

	map.on('click', e => {
		const features = safeGetFeaturesAtPixel(map, e.pixel, {
			layerFilter: l => l === areasLayer,
		});
		if (!features) return;
		onAreaClick(features[0]);
	});

	map.on('pointermove', e => {
		const pixel = map.getEventPixel(e.originalEvent);
		const hit = safeHasFeatureAtPixel(map, pixel, {
			layerFilter: l => l === areasLayer,
		});
		map.getViewport().style.cursor = hit ? 'pointer' : '';
	});

	//const olgmInst = new olgm.GoogleMaps({map});
	//olgmInst.activate();
	// use when removing olgm layer
	//olgmInst.deactivate();

	return Promise.resolve({map, areasLayer});
};
export const initAreasMap = decorateMapMethod(_initAreasMap);
