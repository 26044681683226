import services from 'services';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import {getResponseData} from 'utils/app';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const updateUser = (id, user) =>
	httpJson('put', `/users/${id}`, {}, {body: user})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save user'})))
		.then(getResponseData(normalize.user));
