import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import * as perms from 'utils/perms';
import * as commonSelectors from 'modules/common/selectors';

const root = path(namespace);

export const initialized = pipe(root, prop('initialized'));

export const isSendSmsModalOpen = pipe(root, prop('isSendSmsModalOpen'));

export const smsDraft = pipe(root, prop('smsDraft'));

export const processing = pipe(root, prop('processing'));

export const canSendSmsMessage = createSelector(commonSelectors.user, user =>
	user ? perms.canSendSmsMessage(user) : false,
);

export const canEditSmsMessageField = createSelector(commonSelectors.user, user =>
	user ? perms.canEditSmsMessageField(user) : false,
);

export const canSendCustomMessage = createSelector(commonSelectors.user, user =>
	user ? perms.canSendCustomMessage(user) : false,
);
export const smsTemplatesLoadingFailed = pipe(root, prop('smsTemplatesLoadingFailed'));
