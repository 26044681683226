import {effect} from 'utils/redux';
import namespace from './namespace';
import {getUserStats, getPersonalTargets, getDashboardCallReminders} from 'io/data';
import {catchNonFatalDefault} from 'io/errors';
import * as rootSelectors from 'modules/common/selectors';
import {decorateWithNotifications} from 'io/app';
import * as actions from './actions';
import {updateVisitTarget, postVisitTarget} from './io';
import services from 'services';
import msgs from 'dicts/messages';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

export let initialize = () => (getState, dispatch) => {
	const user = rootSelectors.user(getState());

	decorateWithNotifications(
		{id: 'get-dashboard-data', failureStyle: 'warning'},
		Promise.all([
			getUserStats({userId: user.id}).then(stats => {
				dispatch(actions._setStats(stats));
			}),
			getPersonalTargets({type: 'call'}).then(targets => {
				dispatch(actions._setTargets(targets));
			}),
			getDashboardCallReminders({userId: user.id}).then(reminders => {
				dispatch(actions._setCallReminders(reminders));
			}),
		]),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let saveVisitTarget = target => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'post-visit-target',
			loading: intl.formatMessage({id: msgs.processing}),
			failureStyle: 'warning',
		},
		target.id ? updateVisitTarget(target) : postVisitTarget(target),
	)(getState, dispatch)
		.then(t => dispatch(actions._updateTargets(t)))
		.catch(catchNonFatalDefault(getState, dispatch));
};
saveVisitTarget = creator('saveVisitTarget', saveVisitTarget);
