import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
// custom helpers & utils
import {applyState, applyDispatch} from 'utils/redux';
import {initialize, destroy, getNotices} from 'modules/launcher/actions';
import {user, activeOrganization} from 'modules/common/selectors';
import {
	notices,
	noticesPagination,
	noticesLoading,
	userTargets,
	showTracker,
	showTargets,
	targets,
	targetsLoading,
	stats,
	statsLoading,
} from 'modules/launcher/selectors';
import {screenSmMax, screenMdMin, screenLgMin, screenSmMin} from 'styles/constants';
import memoize from 'memoize-one';
import {
	userAppPermissions,
	isSubcontractorUser,
	isThirdPartyUser,
	isSalesmanagerUser,
	isSalesmanAppAdmin,
} from 'utils/perms';
import {FormattedMessage} from '@meiko/react-intl';
import Media from 'react-responsive';
// components
import DefaultNavbar from 'components/views/DefaultNavbar';
import View from 'components/generic/View';
import Button from 'components/generic/Button';
import Page from 'components/generic/Page';
import Content from 'components/generic/Content';
import Link from 'components/generic/Link';
import NoticesFeed from './components/NoticesFeed';
import MonthlyTargetsGraph from 'components/views/MonthlyTargetsGraph';
import TabNav from 'components/generic/TabNav';
import Icon from 'components/generic/Icon';
import H4 from 'components/generic/H4';
import AppItems from './components/AppItems';

/* eslint-disable react/prop-types */

const getAppPerms = memoize(userAppPermissions);

const ContentContainer = styled(Content)`
	flex-grow: 2;
	padding: 10px;

	@media only screen and (min-width: ${screenMdMin}) {
		padding: 30px 30px;
	}

	@media only screen and (min-width: ${screenLgMin}) {
		padding-left: 60px;
		padding-right: 60px;
	}
`;

const Container = styled.div`
	flex-grow: 1;
	display: flex;
	overflow: hidden;
	flex-direction: row;

	@media only screen and (max-width: 991px) {
		flex-direction: column;
	}
`;

const TargetsContainer = styled.div`
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 20px;

	@media (min-width: ${screenSmMin}) {
		padding-left: 15px;
		padding-right: 15px;
	}

	@media (min-width: ${screenMdMin}) {
		padding-bottom: 30px;
	}
`;

const StyledLink = styled(Link)`
	display: inline-flex;
	align-items: center;
`;

const FeedWrapper = styled.div`
	min-width: 35%;
	max-width: 35%;
	overflow-y: scroll;
`;

const smallQuery = `(max-width: ${screenSmMax})`;

const largeQuery = `(min-width: ${screenMdMin})`;

const NavSecondaryActions = ({activeOrganization}) => (
	<>
		<Link target="_blank" rel="noopener noreferrer" href=" http://ins.li/myynti_kotisun ">
			<FormattedMessage id="Add a quality deviation" />
		</Link>
	</>
);

class Launcher extends React.Component {
	state = {
		tab: 'Launcher',
	};

	componentDidMount() {
		this.props.initialize();
	}

	changeTab = newTab => {
		this.setState({tab: newTab});
	};

	componentWillUnmount() {
		this.props.destroy();
	}

	render() {
		const {
			user,
			activeOrganization,
			notices,
			noticesPagination,
			noticesLoading,
			getNotices,
			showTargets,
			targets,
			targetsLoading,
			stats,
			statsLoading,
		} = this.props;

		const appPerms = getAppPerms(user);
		const isSubcontractor = isSubcontractorUser(user);
		const isThirdParty = isThirdPartyUser(user);
		const isSalesmanager = isSalesmanagerUser(user);
		const isAdminUser = isSalesmanAppAdmin(user);

		const targetsGraphProps = {
			salesPriceTarget: targets.salesPriceTarget,
			offerCountTarget: targets.offerCountTarget,
			salesPrice: stats.dealPrices,
			offerCount: stats.totalOffers,
			loading: targetsLoading || statsLoading,
			headerLeft: (
				<H4 noTopMargin noBottomMargin>
					<FormattedMessage
						id={
							isAdminUser
								? "Organization's monthly goals"
								: isSalesmanager
								? 'Team monthly goals'
								: 'Monthly personal goals'
						}
					/>
					{` - ${new Date().getMonth() + 1}/${new Date().getFullYear()}`}
				</H4>
			),
			headerRight: (
				<StyledLink href="/salesman-app/stats">
					<Icon name="arrowRight" height="12px" width="12px" smallRightSpacing />
					<FormattedMessage id="See more detailed statistics" />
				</StyledLink>
			),
		};

		return (
			<View>
				<DefaultNavbar
					secondaryActionsProps={this.props}
					secondaryActionsClass={activeOrganization.id === 1 ? NavSecondaryActions : null}
				/>
				<Page>
					<Media query={largeQuery}>
						<Container>
							<ContentContainer>
								{showTargets ? (
									<TargetsContainer>
										<MonthlyTargetsGraph {...targetsGraphProps} />
									</TargetsContainer>
								) : null}
								<AppItems user={user} activeOrganization={activeOrganization} />
							</ContentContainer>

							{!isSubcontractor && !isThirdParty && (
								<FeedWrapper>
									<NoticesFeed
										organization={activeOrganization.title}
										notices={notices}
										noticesPagination={noticesPagination}
										noticesLoading={noticesLoading}
										getNotices={getNotices}
										user={user}
										appPerms={appPerms}
									/>
								</FeedWrapper>
							)}
						</Container>
					</Media>
					<Media query={smallQuery}>
						{this.state.tab === 'Launcher' ? (
							<ContentContainer className="uncollapsible">
								{showTargets ? (
									<TargetsContainer>
										<MonthlyTargetsGraph {...targetsGraphProps} />
									</TargetsContainer>
								) : null}
								<AppItems user={user} />
							</ContentContainer>
						) : !isSubcontractor && !isThirdParty ? (
							<FeedWrapper>
								<NoticesFeed
									organization={activeOrganization.title}
									notices={notices}
									noticesLoading={noticesLoading}
									noticesPagination={noticesPagination}
									getNotices={getNotices}
									user={user}
									appPerms={appPerms}
								/>
							</FeedWrapper>
						) : null}
						{!isSubcontractor && !isThirdParty && (
							<TabNav>
								<Button
									appearance={this.state.tab === 'Launcher' ? 'brand' : ''}
									onClick={() => this.changeTab('Launcher')}
								>
									<FormattedMessage id="Front page" />
								</Button>
								<Button
									appearance={this.state.tab === 'Notices' ? 'brand' : ''}
									onClick={() => this.changeTab('Notices')}
								>
									{notices && this.state.tab !== 'Notices' ? (
										notices.find(n => new Date(n.startDate) > user.lastLogin) ? (
											<i className="fa fa-exclamation-circle" />
										) : null
									) : null}
									<FormattedMessage id="Internal releases" />
								</Button>
							</TabNav>
						)}
					</Media>
				</Page>
			</View>
		);
	}
}

export default connect(
	applyState({
		user,
		activeOrganization,
		notices,
		noticesPagination,
		noticesLoading,
		userTargets,
		showTracker,
		showTargets,
		targets,
		targetsLoading,
		stats,
		statsLoading,
	}),
	applyDispatch({
		initialize,
		destroy,
		getNotices,
	}),
)(Launcher);
