import React, {useMemo} from 'react';
import styled from 'styled-components';
import {buttonUnstyled, listUnstyled} from 'styles/fragments';
import {
	organizationNavBorderColors,
	navHeight,
	borderColorBase,
	textColorBase,
	linkColor,
} from 'styles/constants';
import {isMobileIos} from 'utils/userAgents';
import Icon from 'components/generic/Icon';

const MenuWrapper = styled.div`
	position: relative;
`;

const MenuButton = styled.li.attrs(() => ({role: 'button'}))`
	${buttonUnstyled};

	height: calc(${navHeight} - 1px);
	flex-shrink: 0;
	display: flex;
	align-items: center;
	border-right: 1px solid
		${({organizationId}) => organizationNavBorderColors[organizationId]};
	padding: 0px 15px;
	cursor: pointer;
	order: 99;

	&:hover,
	&:active,
	&:focus {
		background: ${({organizationId}) => organizationNavBorderColors[organizationId]};
	}
`;

const navBorderStatic = `1px solid ${borderColorBase}`;

const Menu = styled.ul`
	${listUnstyled};

	position: fixed;
	background: white;
	transform: translateX(-50%);
	z-index: 51;
	overflow: ${isMobileIos() ? 'visible' : 'auto'};
	border-left: ${navBorderStatic};
	border-right: ${navBorderStatic};
	border-bottom: ${navBorderStatic};
	min-width: 150px;
	box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.12);
	color: ${textColorBase};
	padding: 8px 0px;

	a {
		height: auto;
		padding: 8px 16px;
		border: 0;

		&:hover {
			background-color: #fff;
			color: ${linkColor};
		}

		&.active {
			color: ${linkColor};
		}
	}
`;

const NavOverflowMenu = ({
	children,
	visibilityMap,
	organizationId,
	menuOpen,
	toggleMenu = () => {},
}) => {
	const shouldShowMenu = useMemo(
		() => Object.values(visibilityMap).some(v => v === false),
		[visibilityMap],
	);

	if (!shouldShowMenu) {
		return null;
	}

	const handleToggleMenu = e => {
		e.stopPropagation();
		toggleMenu();
	};

	return (
		<MenuWrapper>
			<MenuButton
				data-targetid="more"
				onClick={handleToggleMenu}
				organizationId={organizationId}
			>
				<Icon name="chevronDown" width="15px" height="15px" />
			</MenuButton>
			{menuOpen && (
				<Menu>
					{React.Children.map(children, child =>
						!visibilityMap[child.props['data-targetid']]
							? React.cloneElement(child, {
									onClick: handleToggleMenu,
							  })
							: null,
					)}
				</Menu>
			)}
		</MenuWrapper>
	);
};

export default NavOverflowMenu;
