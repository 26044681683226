import services from 'services';
import {map} from 'ramda';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {mapResponseData, getResponseData} from 'utils/app';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getRemovalRequests = (query = {}) =>
	httpJson('get', '/removalRequests', query)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.removalRequest)));

export const postRemovalRequest = request =>
	httpJson('post', '/removalRequests', {}, {body: request})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentPostFailed})))
		.then(getResponseData(normalize.removalRequest));

export const removalRequestClientSearch = (query = {}) =>
	httpJson('get', '/removalRequestClientSearch', {
		include: 'building',
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load clients'})))
		.then(getResponseData(map(normalize.client)));

export const postRemovalRequestComment = comment =>
	httpJson('post', '/removalRequestComments', {}, {body: comment})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentPostFailed})))
		.then(getResponseData(normalize.removalRequestComment));
