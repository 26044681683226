import {map} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize'; // TODO ADD NORMALIZE LEAD TO getReport
import msgs from 'dicts/messages';
import {mapResponseData} from 'utils/app';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getLeads = query =>
	httpJson('get', `/leads`, {
		...query,
		include: 'building,client,products,salesmanager,calendarResource.team',
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.lead)));

export const deleteLead = ({id}) =>
	httpJson('delete', `/leads/${id}`, {}).catch(
		describeThrow(intl.formatMessage({id: 'Failed to delete lead'})),
	);
