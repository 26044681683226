import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {formatFetchableQuery} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const processing = pipe(root, prop('processing'));

export const events = pipe(root, prop('events'));

export const eventsLoading = pipe(root, prop('eventsLoading'));

export const eventsPagination = pipe(root, prop('eventsPagination'));

export const eventsQuery = pipe(root, prop('eventsQuery'));

export const eventsQueryFetchable = createSelector(eventsQuery, eventsQuery =>
	formatFetchableQuery(eventsQuery),
);

export const fetchingMore = pipe(root, prop('fetchingMore'));

export const options = pipe(root, prop('options'));

export const categories = createSelector(options, options => options?.categories || []);

export const types = createSelector(options, options => options?.types || []);
