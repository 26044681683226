import {pipe, prop, path, map, filter} from 'ramda';
import services from 'services';
import {describeThrow, describeError} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {condoInclude, calendarInclude, buildingInclude} from './constants';
// import {user} from 'modules/common/selectors';
import {getResponseData, mapResponseData} from 'utils/app';
import {freeCalendarResourceInclude} from 'constants/data';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

let condoNumber = 1; // hack for avoiding cached response

export const getCondo = (id, query = {}) =>
	httpJson('get', `/condominiums`, {
		include: condoInclude,
		buildingId: id,
		...query,
		_condoNumber: condoNumber++,
	})
		.catch(e => {
			const notFound = !!e.response && e.response.status === 404;
			e.causedByCondoNotFound = notFound;
			return notFound
				? e.response.json().then(body => describeThrow(body.message, e))
				: Promise.reject(describeError(msgs.contentFetchFailed, e));
		})
		.then(pipe(prop('data'), normalize.condo));

export const saveBuilding = building =>
	httpJson('put', `/buildings/${building.id}`, {}, {body: building})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(pipe(prop('data'), normalize.building));

export const putCalendarResource = calRes =>
	httpJson(
		'put',
		`/calendarResource/${calRes.id}`,
		{
			onlyWithoutSalesmanVisit: true,
			include: calendarInclude,
		},
		{body: calRes},
	)
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(pipe(prop('data'), normalize.calendarResource));

export const saveActivity = form =>
	httpJson(
		'post',
		'/activities',
		{
			include:
				'client,calendarResource,calendarResource.client,products,user,additionalInfo',
		},
		{body: form},
	)
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.activity));

export const saveCondo = condo =>
	httpJson('put', `/condominiums/${condo.id}`, {include: condoInclude}, {body: condo})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(pipe(prop('data'), normalize.condo));

export const postComment = comment =>
	httpJson('post', `/buildingComments`, {include: 'user'}, {body: comment})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.buildingComment));

export const deleteComment = id =>
	httpJson('delete', `/buildingComments/${id}`).catch(
		describeThrow(intl.formatMessage({id: msgs.deleteFailed})),
	);

export const getProducts = orgId =>
	httpJson('get', '/products/all', {_limit: 999, include: 'organizations'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(
			mapResponseData(
				pipe(
					map(normalize.product),
					filter(p => p.organizations.find(o => o.id === orgId)),
				),
			),
		);

export const updateComment = (comment, id) =>
	httpJson('put', `/buildingComments/${id}`, {include: 'user'}, {body: comment})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.buildingComment));

export const detachBuildingFromCondo = id =>
	httpJson('post', `/buildings/${id}/removeProjectBuildingId`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to detach'})),
	);

export const postClient = (client, buildingId) =>
	httpJson('post', '/clients', {}, {body: {buildingId, ...client}}).catch(e => {
		const conflict = !!e.response && e.response.status === 409;
		e.causedByMarketingPrevented = conflict;
		return conflict
			? e.response.json().then(body => describeThrow(body.message, e))
			: Promise.reject(describeError(msgs.saveFailed, e));
	});

export const updateClient = client =>
	httpJson('put', `/clients/${client.id}`, {}, {body: client}).catch(e => {
		const conflict = !!e.response && e.response.status === 409;
		e.causedByMarketingPrevented = conflict;
		return conflict
			? e.response.json().then(body => describeThrow(body.message, e))
			: Promise.reject(describeError(msgs.saveFailed, e));
	});

export const deleteClient = id =>
	httpJson('delete', `/clients/${id}`).catch(
		describeThrow(intl.formatMessage({id: msgs.deleteFailed})),
	);

export const getCalendarResources = (buildingId, query = {}) =>
	httpJson('get', `/calendarResource`, {
		buildingId,
		onlyWithoutSalesmanVisit: true,
		include: calendarInclude,
		...query,
	}).catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})));

export const postCalendarResource = body =>
	httpJson('post', '/calendarResource', {}, {body: body})
		.catch(e => {
			const conflict = !!e.response && e.response.status === 409;
			e.causedByCalendarResourceConflict = conflict;
			return conflict
				? e.response.json().then(body => describeThrow(body.message, e))
				: Promise.reject(describeError(msgs.saveFailed, e));
		})
		.then(getResponseData(normalize.calendarResource));

export const postProjectCall = body =>
	httpJson('post', '/calls/projectCall', {}, {body: body}).catch(
		describeThrow(intl.formatMessage({id: msgs.saveFailed})),
	);

export const getUserTeams = () =>
	httpJson('get', '/users/me', {include: 'teams'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(pipe(path(['teams', 'data']), map(normalize.team))));

export const getUsers = () =>
	httpJson('get', '/users', {
		organizationId: 5,
		type: 'talotekniikkauser',
		include: 'roles',
		_limit: '999',
		_sort: 'lastName',
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), map(normalize.user)));

export const postFormFill = body =>
	httpJson('post', '/formFills', {}, {body: body})
		.then(prop('data'))
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})));

export const getFreeCalendarResources = buildingId =>
	httpJson('get', '/calendarResource/free', {
		buildingId,
		include: freeCalendarResourceInclude,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), map(normalize.calendarResource)));

export const get16100Clients = buildingId =>
	httpJson('get', `/buildings/${buildingId}/searchClients`)
		.catch(describeThrow(intl.formatMessage({id: 'Could not retrieve 16100 data'})))
		.then(pipe(prop('data'), map(normalize.clients)));

export const searchClientData = clientId =>
	httpJson('get', `/buildings/searchClient/${clientId}`).catch(
		describeThrow(intl.formatMessage({id: 'Search failed'})),
	);

export const getBuilding = buildingId =>
	httpJson('get', `/buildings/${buildingId}`, {
		...buildingInclude,
	})
		.catch(e => {
			const noPermission = !!e.response && e.response.status === 403;
			e.causedByNoPermission = noPermission;
			const errMsgKey = noPermission
				? 'You do not have access to the building'
				: 'The building could not be loaded. Try refreshing the page.';
			throw describeError(intl.formatMessage({id: errMsgKey}), e);
		})
		.then(pipe(prop('data'), normalize.building));

export const deleteCondo = id =>
	httpJson('delete', `/condominiums/${id}`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to delete'})),
	);

export const attachBuildingToCondo = (buildingId, condoId) =>
	httpJson(
		'post',
		`/condominiums/${condoId}/attachBuildings`,
		{include: condoInclude},
		{body: {buildings: [{id: buildingId}]}},
	)
		.catch(describeThrow(intl.formatMessage({id: 'Failed to add building'})))
		.then(pipe(prop('data'), normalize.condo));

export const deleteManager = condoId =>
	httpJson('post', `/condominiums/${condoId}/deleteManager`, {include: condoInclude})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to delete'})))
		.then(pipe(prop('data'), normalize.condo));

export const deleteHouseManager = condoId =>
	httpJson('post', `/condominiums/${condoId}/deleteHouseManager`, {
		include: condoInclude,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to delete'})))
		.then(pipe(prop('data'), normalize.condo));

export const getNextBuilding = callPoolId =>
	httpJson('get', '/callReservations/nextBuilding', {callPoolId})
		.catch(e => {
			const noBuildings = !!e.response && e.response.status === 404;
			e.causedByNoBuildingsAvailable = noBuildings;
			const errMsgKey = noBuildings
				? 'No more callable buildings'
				: 'Failed to load next callable building';
			throw describeError(intl.formatMessage({id: errMsgKey}), e);
		})
		.then(getResponseData(normalize.building));

export const skipBuilding = bid =>
	httpJson('get', `/buildings/${bid}/skip`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to skip building, please try again'})),
	);

export const getAdditionalInfos = () =>
	httpJson('get', `/activities/getAdditionalInfos`).catch(
		describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})),
	);
