import {pick, append} from 'ramda';
import {mapStateKeys} from './_internal';
import {getFormValues, getFormInitialValues} from 'redux-form';

export default ({stateRemaps = {}, actions, effects}) => {
	const K = mapStateKeys(stateRemaps);

	return (state, fullState, {type, payload}) => {
		switch (type) {
			// TODO: remove. we're supposed to edit the form, not the building
			case actions.createOtherAddress.type: {
				const tempArr = append('', state.building[payload]);
				const newBuilding = {
					...state.building,
					[payload]: tempArr,
				};
				return [{...state, building: newBuilding}, null];
			}

			// TODO: remove. we're supposed to edit the form, not the building
			case actions.removeOtherAddress.type: {
				const tempArr = state.building[payload[1]].splice([payload[0]], 1);
				const building = {
					...state.building,
					[payload]: tempArr,
				};
				return [{...state, building}, null];
			}

			case actions.saveBuildingData.type: {
				const form = getFormValues('buildingForm')(fullState);
				const initData = getFormInitialValues('buildingForm')(fullState);

				const data = pick(
					[
						'id',
						'address',
						'addressSwe',
						'otherAddressesFin',
						'otherAddressesSwe',
						'zip',
						'city',
						'manufacturingYear',
						'type',
						'encounterState',
						'encounterType',
						'encounterDate',
						'source',
						'banned',
					],
					form,
				);

				if (form.zipArea && form.cityArea && form.countyArea) {
					data.zipAreaId = form.zipArea.id;
					data.cityAreaId = form.cityArea.id;
					data.countyAreaId = form.countyArea.id;
				}

				return [
					{...state, [K.buildingModalProcessing]: true},
					effects.saveBuildingData({data, initData, include: payload?.include}),
				];
			}

			case actions.removeBuilding.type: {
				const bid = state.building.id;
				return [state, effects.removeBuilding(bid)];
			}

			case actions.toggleBuildingModal.type: {
				return [{...state, [K.buildingModalOpen]: payload}, null];
			}

			case actions._updateBuilding.type: {
				return [
					{
						...state,
						building: {...state.building, ...payload},
						[K.buildingModalOpen]: false,
						[K.buildingModalProcessing]: false,
					},
					null,
				];
			}

			case actions._setBuildingModalProcessing.type: {
				return [{...state, [K.buildingModalProcessing]: payload}, null];
			}

			default:
				return [state, null];
		}
	};
};
