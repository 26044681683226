import {appName as buildingsApp} from 'modules/buildingsApp/constants';
import {appName as calendarApp} from 'modules/calendarApp/constants';
import {appName as callsApp} from 'modules/callsApp/constants';
import {appName as controlCenterApp} from 'modules/controlCenterApp/constants';
import {appName as d2dApp} from 'modules/d2dApp/constants';
import {appName as filesApp} from 'modules/filesApp/constants';
import {appName as leadsApp} from 'modules/leadsApp/constants';
import {appName as mapsApp} from 'modules/mapsApp/constants';
import {appName as marketingApp} from 'modules/marketingApp/constants';
import {appName as overviewApp} from 'modules/overviewApp/constants';
import {appName as projectSalesApp} from 'modules/projectSalesApp/constants';
import {appName as referencesApp} from 'modules/referencesApp/constants';
import {appName as reportsApp} from 'modules/reportsApp/constants';
import {appName as salesApp} from 'modules/salesApp/constants';
import {appName as statsApp} from 'modules/statsApp/constants';
import {appName as teamCalendarApp} from 'modules/teamCalendarApp/constants';
import {appName as usersApp} from 'modules/usersApp/constants';

export const userInclude = {
	include: 'teams,organizations,roles.permissions,socialAccount,timeEntry,permissions',
};

export const reasonMappingTypeSalesmanVisit = 'salesmanVisit';
export const reasonMappingTypeVisit = 'visit';
export const reasonMappingTypeCall = 'call';

export const appNames = {
	[buildingsApp]: 'Buildings [app]',
	[calendarApp]: 'Calendar [app]',
	[callsApp]: 'Calls [app]',
	[controlCenterApp]: 'Control Center [app]',
	[d2dApp]: 'Visits [app]',
	[filesApp]: 'Files [app]',
	[leadsApp]: 'Leads [app]',
	[mapsApp]: 'Maps [app]',
	[marketingApp]: 'Marketing [app]',
	[overviewApp]: 'Overview [app]',
	[projectSalesApp]: 'Project Sales [app]',
	[referencesApp]: 'References [app]',
	[reportsApp]: 'Reports [app]',
	[salesApp]: 'Sales [app]',
	[statsApp]: 'Statistics [app]',
	[teamCalendarApp]: 'Team Calendar [app]',
	[usersApp]: 'Users [app]',
};
