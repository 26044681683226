import * as actions from './actions';
import {catchNonFatalDefault} from 'io/errors';
import {P} from 'utils/types';
import namespace from './namespace';
import {effect} from 'utils/redux';
import services from 'services';
import msgs from 'dicts/messages';
import {decorateWithNotifications} from 'io/app';
import {
	getRemovalRequest,
	putRemovalRequest,
	getEncounter,
	postComment,
	deleteComment,
	updateComment,
	removalRequestClientSearch,
	deleteRemovalRequest,
} from './io';
import * as selectors from './selectors';
import * as confirmerActions from 'modules/confirmer/actions';
import {formatClientSearchInput} from './utils';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const history = services.get('history');

const creator = effect(namespace);

export let initialize = id => (getState, dispatch) => {
	decorateWithNotifications(
		{id: 'get-request', failureStyle: 'error'},
		getRemovalRequest(id).then(request => {
			dispatch(actions._setRequest(request));

			const searchInput = formatClientSearchInput(request);
			return removalRequestClientSearch(searchInput).then(clients =>
				dispatch(actions._setClients(clients)),
			);
		}),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize, P.Number);

export let updateRemovalRequest = request => (getState, dispatch) => {
	const onConfirm = () => {
		dispatch(actions._startOp());
		decorateWithNotifications(
			{
				id: 'update-removal-request',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: msgs.saved}),
			},
			putRemovalRequest(request),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(request => {
				dispatch(actions._opOk());
				dispatch(actions._updateRequest(request));
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete client information?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
updateRemovalRequest = creator('updateRemovalRequest', updateRemovalRequest, P.Object);

export let fetchEncounter = id => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'get-encounter-data',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		getEncounter(id),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(encounter => dispatch(actions._setEncounterData(encounter)))
		.catch(catchNonFatalDefault(getState, dispatch));
};
fetchEncounter = creator('fetchEncounter', fetchEncounter);

export let createComment =
	({data, id}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'post-comment',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
			},
			id ? updateComment(data, id) : postComment(data),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(comment => {
				dispatch(actions._commentSaved(comment));
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
createComment = creator('createComment', createComment);

export let removeComment = id => (getState, dispatch) => {
	const onConfirm = () => {
		dispatch(actions._startOp());
		decorateWithNotifications(
			{
				id: 'delete-comment',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Comment deleted'}),
			},
			deleteComment(id),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._commentRemoved(id));
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete comment?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
removeComment = creator('removeComment', removeComment);

export let removeRemovalRequest = () => (getState, dispatch) => {
	const onConfirm = () => {
		dispatch(actions._startOp());
		const request = selectors.request(getState());
		decorateWithNotifications(
			{
				id: 'delete-removal-request',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Removal request cancelled'}),
			},
			deleteRemovalRequest(request.id),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._opOk());
				history.replace('/buildings/removal-requests');
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Cancel removal request?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
removeRemovalRequest = creator('removeRemovalRequest', removeRemovalRequest);
