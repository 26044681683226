import styled from 'styled-components';
import {screenSmMax} from 'styles/constants';

// TODO: should be renamed to PageContent

export default styled.div.attrs(({className}) => ({
	className: className ? `page-container ${className}` : 'page-container',
}))`
	overflow: auto;
	display: flex;
	flex-direction: column;
	align-items: ${({noCenter}) => (noCenter ? 'stretch' : 'center')};

	${/*prettier-ignore*/ ({noPadding}) => !noPadding ? `
		padding: 30px 20px;
		@media (max-width: ${screenSmMax}) {
			padding: 20px 5px;
		}
	` : ''}

	> * {
		flex-shrink: 0;
	}
`;
