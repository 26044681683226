import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	processing: false,
	report: null,
	pdfUrl: null,
	excelUrl: null,
	sort: '',
};
