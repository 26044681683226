import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const report = pipe(root, prop('report'));

export const pdfUrl = pipe(root, prop('pdfUrl'));

export const excelUrl = pipe(root, prop('excelUrl'));

export const sort = pipe(root, prop('sort'));

export const processing = pipe(root, prop('processing'));
