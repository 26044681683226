import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
`;

const TitleWrapper = styled.div`
	flex-shrink: 1;
	display: flex;
	align-items: center;
`;

const ActionsWrapper = styled.div`
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin: -5px 0 -5px 20px;
`;

const PanelHeader = ({title, actions = null, toggle = null, ...rest}) => (
	<Wrapper {...rest}>
		{title && <TitleWrapper>{title}</TitleWrapper>}
		{actions || toggle ? (
			<ActionsWrapper>
				{actions}
				{toggle}
			</ActionsWrapper>
		) : null}
	</Wrapper>
);

PanelHeader.propTypes = {
	title: propTypes.node,
	actions: propTypes.node,
	toggle: propTypes.node,
};

export default PanelHeader;
