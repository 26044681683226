import React from 'react';

const SvgChevronDown = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M5.76 9.6c-.318 0-.62-.136-.83-.374L.22 3.873a.887.887 0 0 1 1.332-1.171l4.118 4.68a.12.12 0 0 0 .18 0l4.118-4.68a.887.887 0 1 1 1.331 1.17L6.591 9.225c-.21.239-.513.376-.831.376z"
		/>
	</svg>
);

export default SvgChevronDown;
