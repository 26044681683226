import {
	TYPE_BUILDING,
	TYPE_CLIENT,
	TYPE_MARKETING_LEAD,
	TYPE_USER,
} from 'modules/usersApp/tagsPage/constants';

export const tagTypes = {
	[TYPE_BUILDING]: 'Tag type:building',
	[TYPE_CLIENT]: 'Tag type:client',
	[TYPE_USER]: 'Tag type:user',
	[TYPE_MARKETING_LEAD]: 'Tag type:marketingLead',
};
