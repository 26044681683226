import React from 'react';

const SvgChevronRight = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M9.75 6c0 .33-.142.646-.39.865L3.784 11.77a.923.923 0 0 1-1.22-1.386l4.877-4.29a.125.125 0 0 0 0-.188l-4.877-4.29A.924.924 0 0 1 3.784.23l5.574 4.904c.249.219.392.534.392.866z"
		/>
	</svg>
);

export default SvgChevronRight;
