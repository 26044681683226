// core dependencies
import React from 'react';
import {reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'ramda';
import {organizations} from 'modules/usersApp/common/selectors';
import {tags} from 'modules/usersApp/tagsPage/selectors';
// custom helpers & utils
import {applyDispatch, applyState} from 'utils/redux';
import {injectIntl, FormattedMessage} from '@meiko/react-intl';
import msgs from 'dicts/messages';
// components
import Input from 'components/generic/Input';
import Field from 'components/generic/Field';
import ReactSelect from 'components/generic/ReactSelect';
import Label from 'components/generic/Label';
import FieldError from 'components/generic/FieldError';
import FormBlock from 'components/generic/FormBlock';
import FormField from 'components/generic/FormField';
import FieldSet from 'components/generic/FieldSet';
import Legend from 'components/generic/Legend';
import SpacedInputs from 'components/generic/SpacedInputs';
import {tagTypes} from 'dicts/tags';

import {appNames} from 'modules/common/constants';
import {appName as d2dApp} from 'modules/d2dApp/constants';
import {appName as salesApp} from 'modules/salesApp/constants';
import {appName as callsApp} from 'modules/callsApp/constants';

const viewTypeKeys = [d2dApp, salesApp, callsApp];

export const TAG_FORM = 'tagForm';

function validate(values, {tags = [], intl, isNew}) {
	const existingTags = tags;

	const requiredMsg = intl.formatMessage({id: msgs.requiredField});
	let errors = {};

	if (!values.name) errors.name = requiredMsg;
	if (!values.type) errors.type = requiredMsg;

	if (
		isNew &&
		values.name &&
		values.type &&
		existingTags.length &&
		existingTags.find(t => t.name === values.name && t.type === values.type)
	) {
		errors.name = intl.formatMessage({id: 'Tag already exists'});
		errors.type = intl.formatMessage({id: 'Tag already exists'});
	}

	return errors;
}

const TagForm = ({intl, handleSubmit, isNew, organizations}) => {
	return (
		<form id={TAG_FORM} onSubmit={handleSubmit}>
			<FieldSet noTopMargin noBottomMargin>
				<Field
					required
					name="name"
					type="text"
					component={({input, inputId, meta}) => (
						<FormBlock>
							<Label htmlFor={inputId}>
								<FormattedMessage id="Name" />
							</Label>
							<Input {...input} id={inputId} meta={meta} block stretch />
							<FieldError {...meta} />
						</FormBlock>
					)}
				/>

				<Field
					required
					name="type"
					component={({input, inputId, meta}) => (
						<FormBlock>
							<Label htmlFor={inputId}>
								<FormattedMessage id="Type" />
							</Label>
							<ReactSelect
								{...input}
								id={inputId}
								meta={meta}
								block
								options={Object.keys(tagTypes).map(key => ({
									value: key,
									label: intl.formatMessage({id: tagTypes[key]}),
								}))}
							/>
							<FieldError {...meta} />
						</FormBlock>
					)}
				/>

				<Field
					required
					name="views"
					component={({input, inputId, meta}) => (
						<FormBlock>
							<Label htmlFor={inputId}>
								<FormattedMessage id="View" /> (
								<FormattedMessage id="Restrict to chosen options" />)
							</Label>
							<ReactSelect
								{...input}
								id={inputId}
								meta={meta}
								block
								isMulti
								closeMenuOnSelect={false}
								options={viewTypeKeys.map(key => ({
									value: key,
									label: intl.formatMessage({id: appNames[key]}),
								}))}
							/>
							<FieldError {...meta} />
						</FormBlock>
					)}
				/>

				<Field
					name={`showOnMap`}
					type="checkbox"
					component={({input, inputId}) => (
						<FormField block>
							<Input {...input} id={inputId} />
							<Label htmlFor={inputId}>
								&nbsp;{intl.formatMessage({id: 'Show on map'})}
							</Label>
						</FormField>
					)}
				/>

				<FieldSet>
					<Legend bottomSpacing>
						<FormattedMessage id="Organizations" />
					</Legend>
					<SpacedInputs>
						{organizations.map(org => (
							<Field
								key={org.id}
								name={`organizations.${org.id}`}
								type="checkbox"
								component={({input, inputId}) => (
									<FormField block>
										<Input {...input} id={inputId} defaultValue={0} />
										<Label htmlFor={inputId}>&nbsp;{org.title}</Label>
									</FormField>
								)}
							/>
						))}
					</SpacedInputs>
				</FieldSet>
			</FieldSet>
		</form>
	);
};

TagForm.propTypes = {
	intl: PropTypes.object.isRequired,
	isNew: PropTypes.bool.isRequired,
	organizations: PropTypes.array.isRequired,
	tags: PropTypes.array,
};

export default compose(
	injectIntl,
	connect(
		applyState({
			organizations,
			tags,
		}),
		applyDispatch({}),
	),
	reduxForm({
		form: TAG_FORM,
		validate,
	}),
)(TagForm);
