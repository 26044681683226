import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {
	formatUrlQuery,
	formatFetchableContactsQuery,
	formatBuildingsFilterFormInput,
	getPageBuildings,
	getSalesmen,
	formatTeamAreas,
	formatAddBonusFormInput,
	formatOrganizationManufacturingYearLimits,
} from './utils';
import * as rootSelectors from 'modules/common/selectors';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(seq, x => !!x);

export const userTeams = pipe(root, prop('userTeams'));

export const allAreas = pipe(root, prop('allAreas'));

export const allAreasLoading = pipe(root, prop('allAreasLoading'));

export const team = createSelector([userTeams], userTeams =>
	userTeams.length ? userTeams[0] : null,
);

export const teamAreas = createSelector(
	[team, allAreas, rootSelectors.isAdminUser],
	(team, allAreas, isAdmin) =>
		isAdmin ? formatTeamAreas(allAreas) : team ? formatTeamAreas(team.areas) : [],
);

export const salesmen = createSelector([team], team =>
	team ? getSalesmen(team.users) : [],
);

export const contactsQuery = pipe(root, prop('contactsQuery'));

export const urlQuery = createSelector([contactsQuery], contactsQuery =>
	formatUrlQuery({contactsQuery}),
);

export const contactsQueryFetchable = createSelector(
	[contactsQuery, teamAreas],
	(contactsQuery, teamAreas) => formatFetchableContactsQuery({contactsQuery, teamAreas}),
);

export const buildings = pipe(root, prop('buildings'));

export const buildingsLoading = pipe(root, prop('buildingsLoading'));

export const buildingsPagination = pipe(root, prop('buildingsPagination'));

export const pageBuildings = createSelector(
	[buildings, buildingsPagination],
	(buildings, buildingsPagination) =>
		getPageBuildings({buildings, currentPage: buildingsPagination.currentPage}),
);

export const allBuildings = pipe(root, prop('allBuildings'));

export const dismissPrompt = createSelector(
	[contactsQuery],
	contactsQuery => contactsQuery.dismissPrompt,
);

export const activeTab = createSelector(
	[contactsQuery],
	contactsQuery => contactsQuery.activeTab,
);

export const selectionId = createSelector(
	[contactsQuery],
	contactsQuery => contactsQuery.selectionId,
);

export const searchType = createSelector(
	[contactsQuery],
	contactsQuery => contactsQuery.searchType,
);

export const organizationManufacturingYearLimits = createSelector(
	[rootSelectors.activeOrganization],
	organization =>
		organization.meta.reportingManufacturingYearLimits
			? formatOrganizationManufacturingYearLimits(
					organization.meta.reportingManufacturingYearLimits,
			  )
			: {},
);

export const buildingsFilterFormInitValues = createSelector(
	[contactsQuery],
	contactsQuery => formatBuildingsFilterFormInput({contactsQuery}),
);

export const mapSource = createSelector(
	[contactsQuery],
	contactsQuery => contactsQuery.mapSource,
);

export const organizationProducts = pipe(root, prop('organizationProducts'));

export const allProducts = pipe(root, prop('allProducts'));

export const addBonusModalOpen = pipe(root, prop('addBonusModalOpen'));

export const bonusBuilding = pipe(root, prop('bonusBuilding'));

export const processing = pipe(root, prop('processing'));

export const encounterState = createSelector(
	[contactsQuery],
	contactsQuery => contactsQuery.encounterState,
);

export const hideBuildingStreet = createSelector(
	[encounterState],
	encounterState => encounterState === 'installed', // || encounterState === 'offer'  TEMPORARY due to covid-19 need to show addresses for offers
);

export const addBonusFormInitValues = createSelector(
	[bonusBuilding, encounterState],
	(bonusBuilding, encounterState) =>
		formatAddBonusFormInput({building: bonusBuilding, encounterState}),
);

export const crossSalesCount = pipe(root, prop('crossSalesCount'));

export const showCrossSalesCount = pipe(root, prop('showCrossSalesCount'));

export const userTeamsLoading = pipe(root, prop('userTeamsLoading'));

export const organizations = pipe(root, prop('organizations'));

// PERM: showOffersForSalesman
export const showOffersForSalesman = createSelector(
	[rootSelectors.activeOrganization],
	activeOrganization => activeOrganization.meta.showOffersForSalesman,
);
