import React from 'react';

const SvgBreak = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="#FFF"
			fillRule="evenodd"
			d="M11.4705,9.982 C11.7466424,9.982 11.9705,10.2058576 11.9705,10.482 C11.9705,10.7581424 11.7466424,10.982 11.4705,10.982 L11.4705,10.982 L0.5,10.982 C0.223857625,10.982 7.99360578e-15,10.7581424 7.99360578e-15,10.482 C7.99360578e-15,10.2058576 0.223857625,9.982 0.5,9.982 L0.5,9.982 Z M8.227,2 C8.50314237,2 8.727,2.22385763 8.727,2.5 L8.727,2.5 L8.727,2.75 C8.727,2.88807119 8.83892881,3 8.977,3 L8.977,3 L9.5065,3 C10.883622,3 12,4.11637798 12,5.4935 C12,6.87062202 10.883622,7.987 9.5065,7.987 L9.5065,7.987 L7.693,7.987 C7.62626102,7.98714544 7.56235072,8.01396977 7.5155,8.0615 C6.8503773,8.74602403 5.95573255,9.16058075 5.0035,9.2255 C3.90134907,9.29927031 2.81792464,8.91288947 2.0111736,8.15834889 C1.20442256,7.40380831 0.7465,6.34861701 0.7465,5.244 L0.7465,5.244 L0.7465,2.5 C0.7465,2.22385763 0.970357625,2 1.2465,2 L1.2465,2 Z M8.9775,3.9965 C8.83942881,3.9965 8.7275,4.10842881 8.7275,4.2465 L8.7275,4.2465 L8.7275,5.1075 C8.72744456,5.63512144 8.62822216,6.15803199 8.435,6.649 C8.40519752,6.72574156 8.41508675,6.81223628 8.46144192,6.88027048 C8.50779709,6.94830469 8.58467542,6.98915632 8.667,6.9895 L8.667,6.9895 L9.5065,6.9895 C10.332718,6.9895 11.0025,6.31971799 11.0025,5.4935 C11.0025,4.66728201 10.332718,3.9975 9.5065,3.9975 L9.5065,3.9975 Z"
		/>
	</svg>
);

export default SvgBreak;
