import services from 'services';
import {map} from 'ramda';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {mapResponseData, getResponseData} from 'utils/app';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getFeedback = query =>
	httpJson('get', '/feedback', {include: 'user,organization', ...query})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.feedback)));

export const putFeedback = feedback =>
	httpJson('put', `/feedback/${feedback.id}`, {}, {body: feedback})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.feedback));
