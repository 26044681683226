export const userTeamsInclude = 'teams.users';

export const salesmanVisitsInclude = 'user,products,client.building,calendarResource';

export const calendarResourceInclude = 'client.building,salesman,building';

export const calendarResourceQueryBase = {
	_limit: '25',
	_sort: 'date_from',
	onlyWithoutSalesmanVisit: true,
	withoutFreeTimes: true,
	bonus: false,
};
