import {map, pipe, prop} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize'; // TODO ADD NORMALIZE LEAD TO getReport
import msgs from 'dicts/messages';
import {mapResponseData} from 'utils/app';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getLeads = query =>
	httpJson('get', `/leads`, {
		...query,
		include:
			'building,client,products,salesmanager,calendarResource.team,marketingSource,organization,latestEvent',
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.lead)));

export const deleteLead = ({id, deletedReason}) =>
	httpJson('delete', `/leads/${id}`, {deletedReason}).catch(
		describeThrow(intl.formatMessage({id: 'Failed to delete lead'})),
	);

export const getCategories = _ =>
	httpJson('get', `/marketingCategories`, {}, {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getChannels = _ =>
	httpJson('get', `/marketingChannels`, {}, {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getCustomerTypes = _ =>
	httpJson('get', `/marketingCustomerTypes`, {}, {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getSources = _ =>
	httpJson('get', `/marketingSources`, {}, {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getUtmOptions = _ =>
	httpJson('get', '/leads/utmOptions')
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));
