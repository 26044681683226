import {path} from 'ramda';
import {over} from 'utils/lenses';

// TODO: should move into separate files like other fragments: state, selectors, decorateHandler

// helpers for module lifecycle handling. provide the "seq" property, a number that increments each time the module is destroyed and initialized. modules may use "seq" in their effects when necessary to check if the module was destroyed while async io was being performed.

export const decorateHandler = ({namespace, initializeType, destroyType}) => handler => (
	state,
	action,
) => {
	const {type} = action;

	const scopeState = path(namespace, state);

	// handle init event
	if (scopeState === undefined) {
		return handler(state, action);
	}

	// ignore actions that are dispatched when module is inactive
	if (!scopeState.seq && type !== initializeType) {
		return [state, null];
	}

	// allow init event to be safely dispatched multiple times. only the first one has an effect, all subsequent ones are ignored until the module is destroyed again. this can be surprising but it's convenient; initialize events are usually fired from react components on mount, but sometimes we might need to interact with the module before the init event from react has had a chance to fire, so we fire another init event manually.
	// if a module needs re-initialization, a different event should be used
	if (type === initializeType && scopeState.seq) {
		return [state, null];
	}

	const [resState, effect] = handler(state, action);

	// manage seq and nextSeq for the module
	switch (type) {
		case initializeType: {
			const newState = over(
				namespace,
				// note that reading lifecycle props from the scoped state rather than the one returned by the handler makes us ignore any possible modifications the module makes to seq and nextSeq. this can be surprising, but it's more convenient; it allows the module to just return its initial state when it wants to reset itself. lifecycle management works best as be a black box for the module anyway, it shouldn't try to edit its lifecycle props.
				s => ({...s, seq: scopeState.nextSeq, nextSeq: scopeState.nextSeq + 1}),
				resState,
			);
			return [newState, effect];
		}
		case destroyType: {
			const newState = over(
				namespace,
				// see the note above
				s => ({...s, seq: null, nextSeq: scopeState.nextSeq}),
				resState,
			);
			return [newState, effect];
		}
		default: {
			// perform some ugly mutation here - shouldn't break anything (resState isn't exposed anywhere else) or hinder performance (the loop is dead simple). the reason for this is the same as described above - not letting the module mess with its lifecycle values by accident.
			let stateLens = resState;
			for (let key of namespace) {
				stateLens = stateLens[key];
			}
			stateLens.seq = scopeState.seq;
			stateLens.nextSeq = scopeState.nextSeq;
			return [resState, effect];
		}
	}
};

export const state = {
	seq: null,
	nextSeq: 1,
};

export const selectors = namespace => ({
	seq: path([...namespace, 'seq']),
});
