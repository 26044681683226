import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.changePage.type: {
			const newState = {
				...state,
				remindersLoading: true,
				remindersQuery: {...state.remindersQuery, _page: payload},
			};

			return [newState, effects.updateReminders()];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		case actions.setReminderSeen.type: {
			return [{...state, processing: true}, effects.setReminderSeen(payload)];
		}

		case actions._setReminders.type: {
			const newState = {
				...state,
				reminders: payload.data,
				remindersPagination: payload.pagination,
				remindersLoading: false,
			};

			return [newState, null];
		}

		case actions._updateRemindersQuery.type: {
			const newState = {
				...state,
				remindersLoading: true,
				remindersQuery: {...state.remindersQuery, ...payload},
			};

			return [newState, null];
		}

		case actions._setReminderSeen.type: {
			const newState = {
				...state,
				processing: false,
				reminders: state.reminders.map(r =>
					r.id === payload.id ? {...r, ...payload} : r,
				),
			};

			return [newState, null];
		}

		case actions._setProcessing.type: {
			return [{...state, processing: payload}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
