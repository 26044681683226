import React from 'react';

const SvgClose = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M7.15 6.088a.125.125 0 0 1 0-.177l4.63-4.63A.75.75 0 1 0 10.72.22l-4.63 4.628a.125.125 0 0 1-.178 0L1.281.22A.75.75 0 0 0 .22 1.28l4.63 4.631a.125.125 0 0 1 0 .177L.22 10.72a.75.75 0 1 0 1.06 1.06l4.631-4.63a.125.125 0 0 1 .177 0l4.63 4.63a.75.75 0 0 0 1.062-1.06L7.15 6.09z"
		/>
	</svg>
);

export default SvgClose;
