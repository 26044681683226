import {state as lifecycle} from 'fragments/lifecycle';
import {emptyLeadsFilters} from './constants';

export default {
	...lifecycle,
	initialized: false,
	leads: [],
	leadsLoading: true,
	products: [],
	organizations: [],
	leadsPagination: {},
	leadsQuery: {
		_page: 1,
		sortCol: 'created_at',
		sortDir: 'desc',
		...emptyLeadsFilters,
	},
	sources: [],
	utmContents: [],
	utmCampaigns: [],
	domains: [],
	deleteLeadModalOpen: false,
	leadToDelete: null,
	handlers: [],
	commentModalOpen: false,
	leadToComment: null,
	areas: [],
};
