import services from 'services';
import {prop, pipe} from 'ramda';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import {getResponseData} from 'utils/app';
import msgs from 'dicts/messages';
import {catchNonFatalDefault} from 'io/errors';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getDashboard = payload =>
	httpJson('get', `/dashboardData`, payload)
		.catch(catchNonFatalDefault)
		.then(pipe(prop('data'), normalize.dashboardData));

export const getTeams = q =>
	httpJson('get', '/teams', {
		_limit: 999,
		include: 'users',
		...q,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(normalize.team));
