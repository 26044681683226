export default initialState => {
	let cache = initialState;

	return {
		update: f => {
			cache = f(cache);
		},
		read: () => cache,
		reset: () => {
			cache = initialState;
		},
		initial: initialState,
	};
};
