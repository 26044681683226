import {path, pipe, prop, splitEvery} from 'ramda';
import namespace from './namespace';
import {createSelector} from 'reselect';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {
	formatUrlQuery,
	formatReferencesSearchInput,
	filterSelectableAreas,
	formatFetchableReferencesQuery,
	getBookTotalPagesCount,
} from './utils';
import {referencesPerPage} from './constants';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(seq, x => !!x);

export const referencesQuery = pipe(root, prop('referencesQuery'));

export const referencesQueryFetchable = createSelector(
	[referencesQuery],
	referencesQuery => formatFetchableReferencesQuery({referencesQuery}),
);

export const urlQuery = createSelector([referencesQuery], referencesQuery =>
	formatUrlQuery({referencesQuery}),
);

export const references = pipe(root, prop('references'));

export const referencesPagination = pipe(root, prop('referencesPagination'));

export const referencesLoading = pipe(root, prop('referencesLoading'));

// split references into page arrays, each containing N amount of references
export const referencePages = createSelector([references], references =>
	references.length ? splitEvery(referencesPerPage, references) : [],
);

// current page's references in listview
export const currentPageReferences = createSelector(
	[referencePages, referencesPagination],
	(referencePages, referencesPagination) =>
		// -1 = referencePages start at 0, pagination at 1
		referencePages.length && referencePages[referencesPagination.currentPage - 1]
			? referencePages[referencesPagination.currentPage - 1]
			: [],
);

export const bookTotalPages = createSelector([references], references =>
	getBookTotalPagesCount(references),
);

export const dismissPrompt = createSelector(
	[referencesQuery],
	referencesQuery => referencesQuery.dismissPrompt,
);

export const selectedCounty = createSelector(
	[referencesQuery],
	referencesQuery => referencesQuery.county,
);

export const selectedCity = createSelector(
	[referencesQuery],
	referencesQuery => referencesQuery.city,
);

export const selectedProducts = createSelector(
	[referencesQuery],
	referencesQuery => referencesQuery.products,
);

export const referencesSearchInitValues = createSelector(
	[referencesQuery],
	referencesQuery => formatReferencesSearchInput({referencesQuery}),
);

export const areas = pipe(root, prop('areas'));

export const areasLoading = pipe(root, prop('areasLoading'));

export const selectableAreas = createSelector(
	[areas, selectedCounty, selectedCity],
	(areas, selectedCounty, selectedCity) =>
		filterSelectableAreas({areas, selectedCounty, selectedCity}),
);

export const products = pipe(root, prop('products'));

export const productsLoading = pipe(root, prop('productsLoading'));

export const bookOpen = pipe(root, prop('bookOpen'));
