import services from 'services';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {getResponseData, mapResponseData} from 'utils/app';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const defaultIncludes = '';

export const getSmsTemplates = (query = {}) =>
	httpJson('GET', '/smsTemplates', {include: defaultIncludes, ...query}, {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(normalize.smsTemplate));

export const createSmsTemplate = input =>
	httpJson('POST', '/smsTemplates', {}, {body: input})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.smsTemplate));

export const updateSmsTemplate = (id, input) =>
	httpJson('PUT', `/smsTemplates/${id}`, {include: defaultIncludes}, {body: input})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.smsTemplate));

export const deleteSmsTemplate = id =>
	httpJson('DELETE', `/smsTemplates/${id}`, {}, {}).catch(
		describeThrow(intl.formatMessage({id: msgs.deleteFailed})),
	);

export const restoreSmsTemplate = id =>
	httpJson('POST', `/smsTemplates/${id}/restore`, {}, {})
		.then(getResponseData(normalize.smsTemplate))
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})));
