import {map, pipe, path} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import {getResponseData} from 'utils/app';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getTargets = query =>
	httpJson('get', '/targets', query)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.target)));

export const getStatistics = query =>
	httpJson('post', '/statistics/forSalesmanApp', {_method: 'GET'}, {body: query})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.statistics)));

export const getUserTeams = () =>
	httpJson('get', '/users/me', {include: 'teams'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(pipe(path(['teams', 'data']), map(normalize.team))));
