import {pipe, prop} from 'ramda';
import services from 'services';
import {describeThrow, describeError} from 'utils/errors';
import {reportInclude} from './constants';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getReport = (id, query) =>
	httpJson('get', `/reports/${id}`, {include: reportInclude, ...query})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), normalize.report));

export const getPdf = (id, query) =>
	httpJson('get', `/reports/${id}/pdf`, query)
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load PDF'})))
		.then(prop('data'));

export const getExcel = ({id, type}) =>
	httpJson('get', `/reports/${id}/excel`, {})
		.catch(e => {
			const conflict = type === 'leadReport' && !!e.response && e.response.status === 404;
			return conflict
				? e.response.json().then(body => describeThrow(body.message, e))
				: Promise.reject(describeError(intl.formatMessage({id: msgs.saveFailed}), e));
		})
		.then(prop('data'));

export const deleteReport = id =>
	httpJson('delete', `/reports/${id}`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to delete report'})),
	);
