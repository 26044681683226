import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {createSelector} from 'reselect';
import {
	formatClientsFiltersInput,
	formatFetchableClientsQuery,
	formatUrlQuery,
} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const processing = pipe(root, prop('processing'));

export const clientsQuery = pipe(root, prop('clientsQuery'));

export const clientsLoading = pipe(root, prop('clientsLoading'));

export const clients = pipe(root, prop('clients'));

export const clientsTotal = pipe(root, prop('clientsTotal'));

export const clientsFiltersInitValues = createSelector([clientsQuery], q =>
	formatClientsFiltersInput(q),
);

export const clientsQueryFetchable = createSelector([clientsQuery], q =>
	formatFetchableClientsQuery(q),
);

export const urlQuery = createSelector(clientsQuery, q => formatUrlQuery(q));

export const dismissPrompt = createSelector([clientsQuery], q => q.dismissPrompt);

export const organizations = pipe(root, prop('organizations'));

export const products = pipe(root, prop('products'));
