import {action} from 'utils/redux';
import namespace from './namespace';
import {P, Record, Union, Maybe} from 'utils/types';

const creator = action(namespace);

const NId = Union(P.String, P.Number);

const AddPayload = Record({
	id: NId,
	message: P.String,
	duration: Maybe(P.Number),
});

export const info = creator('info', AddPayload);
export const success = creator('success', AddPayload);
export const warning = creator('warning', AddPayload);
export const error = creator('error', AddPayload);
export const loading = creator('loading', AddPayload);

export const remove = creator('remove', NId);
export const _remove = creator('_remove', NId);
