import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {
	formatFetchableLeadsQuery,
	formatLeadsFilterFormInput,
	formatUrlQuery,
	formatSummary,
} from './utils';
import * as commonSelectors from 'modules/common/selectors';
import * as perms from 'utils/perms';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const leads = pipe(root, prop('leads'));

export const leadsLoading = pipe(root, prop('leadsLoading'));

export const leadsPagination = pipe(root, prop('leadsPagination'));

export const leadsQuery = pipe(root, prop('leadsQuery'));

export const urlQuery = createSelector(leadsQuery, q => formatUrlQuery(q));

export const utmCampaigns = pipe(root, prop('utmCampaigns'));

export const utmContents = pipe(root, prop('utmContents'));

export const utmSources = pipe(root, prop('utmSources'));

export const utmMediums = pipe(root, prop('utmMediums'));

export const marketingServices = pipe(root, prop('marketingServices'));

export const domains = pipe(root, prop('domains'));

export const products = pipe(root, prop('products'));

export const organizations = pipe(root, prop('organizations'));

export const showAllMarketingLeads = createSelector([commonSelectors.user], user =>
	perms.showAllMarketingLeads(user),
);

export const showActiveOrganizationMarketingLeads = createSelector(
	[commonSelectors.user],
	user => perms.showActiveOrganizationMarketingLeads(user),
);

export const leadsQueryFetchable = createSelector(
	[leadsQuery, commonSelectors.user, commonSelectors.activeOrganizationId],
	(leadsQuery, user, activeOrganizationId) =>
		formatFetchableLeadsQuery({
			leadsQuery,
			user,
			activeOrganizationId,
			showAllMarketingLeads,
			showActiveOrganizationMarketingLeads,
		}),
);

export const processing = pipe(root, prop('processing'));

export const leadsFilterFormInitValues = createSelector([leadsQuery], leadsQuery =>
	formatLeadsFilterFormInput({leadsQuery}),
);

export const showTrashedLeads = createSelector(
	[leadsQuery],
	leadsQuery => leadsQuery.trashed,
);

export const salesTeams = pipe(root, prop('salesTeams'));

export const summary = createSelector(leadsPagination, leadsPagination =>
	formatSummary(leadsPagination?.summary),
);

export const areas = pipe(root, prop('areas'));

export const areasLoading = pipe(root, prop('areasLoading'));

export const tags = pipe(root, prop('tags'));

export const tagsLoading = pipe(root, prop('tagsLoading'));
