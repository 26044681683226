import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import initState from './state';

export const parseUrlQuery = query => {
	const vals = evolve(
		{
			_page: Number,
			areas: str => (str ? JSON.parse(str) : []),
			productFilters: str => (str ? JSON.parse(str) : []),
			dismissPrompt: str => (str ? JSON.parse(str) : false),
		},
		query,
	);
	return {condosQuery: replace(vals, initState.condosQuery)};
};

export const formatUrlQuery = ({condosQuery}) => {
	return {
		...condosQuery,
		areas: condosQuery.areas ? JSON.stringify(condosQuery.areas) : null,
		productFilters: condosQuery.productFilters
			? JSON.stringify(condosQuery.productFilters)
			: null,
	};
};

export const formatFetchableCondosQuery = ({condosQuery}) => {
	return {
		...condosQuery,
		areas: condosQuery.areas ? condosQuery.areas.map(a => a.id).join(',') : null,
		productFilters: condosQuery.productFilters
			? JSON.stringify(condosQuery.productFilters.map(p => ({id: p.id, year: p.year})))
			: null,
	};
};

export const formatCondoSearchFormInput = ({condosQuery}) => {
	return {
		productFilters: condosQuery.productFilters,
		areas: condosQuery.areas,
		filter: condosQuery.filter,
		manufacturingYearStart: condosQuery.manufacturingYearStart,
		manufacturingYearEnd: condosQuery.manufacturingYearEnd,
	};
};

export const formatCondoSearchFormOutput = ({form}) => {
	return {
		productFilters: form.productFilters,
		areas: form.areas,
		filter: form.filter,
		manufacturingYearStart: form.manufacturingYearStart,
		manufacturingYearEnd: form.manufacturingYearEnd,
	};
};
