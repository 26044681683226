import * as actions from './actions';
import * as selectors from './selectors';
import {catchNonFatalDefault} from 'io/errors';
import {getReservations} from './io';
import {effect} from 'utils/redux';
import namespace from './namespace';
import {decorateWithNotifications} from 'io/app';
import {getQuery, pushQuery} from 'io/history';
import {mergeLeft, equals} from 'ramda';
import {parseUrlQuery} from './utils';
import services from 'services';
import {createReferrerUrl, encodeQuery} from 'utils/url';

const creator = effect(namespace);

const history = services.get('history');

const fetchReservations = (getState, dispatch) => {
	return decorateWithNotifications(
		{id: 'get-reservations', failureStyle: 'error'},
		getReservations(selectors.reservationsQueryFetchable(getState())),
	)(getState, dispatch).then(reservations => {
		dispatch(actions._setReservations(reservations));
	});
};

export let initialize = () => (getState, dispatch) => {
	const {reservationsQuery} = parseUrlQuery(getQuery());
	dispatch(actions._updateReservationsQuery(reservationsQuery));
	fetchReservations(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let updateReservations = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	fetchReservations(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
updateReservations = creator('updateReservations', updateReservations);

export let recheckQuery = () => (getState, dispatch) => {
	const urlQueries = parseUrlQuery(getQuery());
	const reservationsQuery = selectors.reservationsQuery(getState());
	if (!equals(reservationsQuery, urlQueries.reservationsQuery)) {
		dispatch(actions._updateReservationsQuery(urlQueries.reservationsQuery));
		fetchReservations(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}
};
recheckQuery = creator('recheckQuery', recheckQuery);

export let navigateToBuilding = id => (getState, dispatch) => {
	const referrerUrl = createReferrerUrl(history.location);
	history.push(`/d2d/buildings/${id}${encodeQuery({referrer: 'listview', referrerUrl})}`);
};
navigateToBuilding = creator('navigateToBuilding', navigateToBuilding);
