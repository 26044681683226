import styled from 'styled-components';
import Header from 'components/generic/Header';

export default styled.div.attrs({
	'data-ti': 'page',
})`
	// needed for flex shrinking to work on linux
	overflow: auto;
	display: flex;
	flex-direction: column;

	> ${Header} {
		flex-shrink: 0;
	}

	// aka Content - should allow targeting custom container components as well
	> .page-container {
		overflow: auto;
		flex-grow: 1;
	}
`;
