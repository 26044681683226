import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {createSelector} from 'reselect';
import * as commonSelectors from '../common/selectors';
import {
	formatUserFormInput,
	formatSearchInput,
	formatExcelInput,
	formatUrlQuery,
} from './utils';
import {createEmptyUser} from './constants';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const toggleTrashed = pipe(root, prop('toggleTrashed'));
export const users = pipe(root, prop('users'));

export const processing = pipe(root, prop('processing'));

export const showDeleted = pipe(root, prop('showDeleted'));

export const excelEditorOpen = pipe(root, prop('excelEditorOpen'));

export const usersPagination = pipe(root, prop('usersPagination'));

export const usersLoading = pipe(root, prop('usersLoading'));

export const activeUser = pipe(root, prop('activeUser'));

export const usersQueryRaw = pipe(root, prop('usersQuery'));

export const userCreatorOpen = pipe(root, prop('userCreatorOpen'));

export const usersQueryFetchable = createSelector([usersQueryRaw], raw => {
	return {
		...raw,
	};
});

export const searchFormInitValues = createSelector([usersQueryRaw], usersQuery =>
	formatSearchInput({usersQuery}),
);

export const excelFormInitValues = createSelector([commonSelectors.roles], roles =>
	formatExcelInput(roles),
);

export const userFormInitValues = createSelector(
	[
		activeUser,
		commonSelectors.organizations,
		commonSelectors.roles,
		commonSelectors.teams,
		commonSelectors.permissions,
	],
	(activeUser, organizations, roles, teams, permissions) =>
		formatUserFormInput({
			user: activeUser || createEmptyUser({organizations}),
			organizations,
			roles,
			teams,
			permissions,
		}),
);

export const urlQuery = createSelector([usersQueryRaw], usersQuery => {
	return formatUrlQuery({usersQuery});
});
