import * as React from 'react';

const Upload = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 32"
			width="1em"
			height="1em"
			{...props}
		>
			<g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
				<path
					d="M24 11.429h2.286a1.143 1.143 0 011.143 1.142v17.143a1.143 1.143 0 01-1.143 1.143H5.714a1.143 1.143 0 01-1.143-1.143V12.571a1.143 1.143 0 011.143-1.142H8M16 17.143v-16M11.429 5.714L16 1.143l4.571 4.571"
					strokeWidth={2.28571}
				/>
			</g>
		</svg>
	);
};

export default Upload;
