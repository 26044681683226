import {assocPath, path, map, curryN, curry} from 'ramda';
import {effect as _effect, isOpEffect} from './reduxEffects';
import {assertTypeAndTransformError} from './reduxLocalHelpers';

// middleware to inject a "@@stack" property to actions, displaying where they were dispatched from
// NOTE: may cause a significant performance hits with frequent actions?
export const stackInjectorMiddleware = store => next => action => {
	// optimize. field registrations are frequent and we don't need stacks on them
	if (!action.type !== '@@redux-form/REGISTER_FIELD') {
		action['@@stack'] = new Error().stack.split('\n').slice(1);
	}
	return next(action);
};

// create an action typed by its namespace and name. the intermediary action creator accepts the payload of the action to be created, and contains a "type" prop equal to the action's type
export const action = namespace => (name, payloadType = null) => {
	const type = `${namespace.join('.')}:${name}`;
	const augmentError = e => {
		e.message = `At action "${type}": ${e.message}`;
		return e;
	};

	const creator = payload => {
		if (payloadType) {
			assertTypeAndTransformError(payloadType, payload, augmentError);
		}
		return {type, ...(payload !== undefined ? {payload} : {})};
	};
	creator.type = type;
	creator.payloadType = payloadType;
	return creator;
};

// create an effect scoped by its namespace and name.
export const effect = namespace => (name, ...rest) =>
	_effect(`${namespace.join('.')}:${name}`, ...rest);

// create a handler by supplying a namespace and a handler function that acts on the sub-state denoted by the namespace. the sub-handler receives the full state in addition to its local state.
export const scopedHandler = (namespace, subHandler) => (state = {}, action) => {
	const subResult = subHandler(path(namespace, state), state, action);
	const newState = assocPath(namespace, subResult[0], state);
	return [newState, subResult[1]];
};

// when calling delegated sub-handlers in a scoped reducer, the global state needs to be manually synced with the sub-state if any changes were made. this is a simple convenience function for doing so.
export const assocSubState = namespace => (subState, fullState) =>
	assocPath(namespace, subState, fullState);

// a convenience function for making the same updates to both the local and the global state when passing them onto a delegated sub-handler.
export const updateSubState = namespace => (subState, fullState, updater) => {
	const newSub = updater(subState);
	return [newSub, assocPath(namespace, newSub, fullState)];
};

// pipe sub-handlers of a scoped reducer. note that several handlers are allowed to react to a given action and their resulting effects are aggregated into a list.
export const pipeSubHandlers = namespace => (...handlers) => (state, fullState, action) =>
	handlers
		.reduce(
			([state, effect, fullState], handler) => {
				const [newState, resultEffect] = handler(state, fullState, action);
				// figure out a nice format for the joint effect
				// prettier-ignore
				const newEffect = !isOpEffect(resultEffect) ? effect
					: !effect ? resultEffect
					: Array.isArray(effect) ? [...effect, resultEffect]
					: [effect, resultEffect];
				// make sure to update fullState as well so that the next reducer receives up-to-date data
				return [newState, newEffect, assocPath(namespace, newState, fullState)];
			},
			[state, null, fullState],
		)
		.slice(0, 2);

// produce a map of state keys to their own values or the ones provided in the remapper object, if present. useful for fragments for allowing their parents to remap some of the state keys they use.
export const mapStateKeys = curry((keys, remaps) =>
	keys.reduce((acc, k) => ({...acc, [k]: remaps[k] || k}), {}),
);

// bind an effect or a helper function into the given store methods for more convenient use later
export const bindToStore = (getState, dispatch) => effectLike =>
	curryN(effectLike.length, (...args) => effectLike(...args)(getState, dispatch));

// apply given state to all selectors, returning an object suitable as component props
export const applyState = selectors => state => map(s => s(state), selectors);

// apply given dispatcher to all actions, returning an object suitable as component props
export const applyDispatch = actionCreators => dispatch =>
	map(a => (...args) => dispatch(a(...args)), actionCreators);

// similar to applyState, but allow resulting state props to be overwritten by parent props
export const applyStateDefault = selectors => (state, props) => ({
	...applyState(selectors)(state),
	...props,
});

// similar to applyDispatch, but allow resulting action props to be overwritten by parent props
export const applyDispatchDefault = actionCreators => (dispatch, props) => ({
	...applyDispatch(actionCreators)(dispatch),
	...props,
});
