export const deleteChildren = node => {
	while (node.lastChild) {
		node.removeChild(node.lastChild);
	}
};

/**
 * Native scrollTo with callback
 * @param element - element to scroll
 * @param offset - offset to scroll to
 * @param callback - callback function
 */
export const scrollTo = (element, offset, callback) => {
	const onScroll = () => {
		if (element.scrollTop === offset) {
			element.removeEventListener('scroll', onScroll);
			callback();
		}
	};
	element.addEventListener('scroll', onScroll);
	onScroll();
	element.scrollTo({
		top: offset,
		behavior: 'smooth',
	});
};
