import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {getFormValues} from 'redux-form';
import {formatCondoSearchFormOutput} from './utils';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.changePage.type: {
			const newState = {
				...state,
				condosLoading: true,
				condosQuery: {...state.condosQuery, _page: payload},
			};

			return [newState, effects.updateCondos()];
		}

		case actions.closeAreasSelector.type: {
			const newState = {
				...state,
				areasSelectorOpen: false,
				areasSelectorSearch: '',
				areasSelectorSuggestionsOpen: false,
				areasSelectorSuggestions: [],
			};
			return [newState, null];
		}

		case actions.navigateToCondo.type: {
			const projectBuildingId = payload;
			return [state, effects.navigateToCondo(projectBuildingId)];
		}

		case actions.openAreasSelector.type: {
			return [{...state, areasSelectorOpen: true}, effects.initAreasMap()];
		}

		case actions.openAreasSelectorSuggestion.type: {
			return [state, effects.openAreasSelectorSuggestion(payload)];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		case actions.searchCondos.type: {
			const form = getFormValues('condosSearchForm')(fullState);
			const query = formatCondoSearchFormOutput({form});

			const newState = {
				...state,
				condosLoading: true,
				condosQuery: {_page: 1, ...query, dismissPrompt: true},
			};

			return [newState, effects.updateCondos()];
		}

		case actions.setAreasSelectorType.type: {
			return [state, effects.setAreasMapType(payload)];
		}

		case actions.setAreasSelectorSearch.type: {
			return [{...state, areasSelectorSearch: payload}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._setCondos.type: {
			const newState = {
				...state,
				condos: payload.data,
				condosPagination: payload.pagination,
				condosLoading: false,
			};

			return [newState, null];
		}

		case actions._setProducts.type: {
			return [{...state, products: payload, productsLoading: false}, null];
		}

		case actions._updateCondosQuery.type: {
			const newState = {
				...state,
				condosLoading: true,
				condosQuery: {...state.condosQuery, ...payload},
			};

			return [newState, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
