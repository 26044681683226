import {scopedHandler, pipeSubHandlers} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import * as rootSelectors from 'modules/common/selectors';
import {
	processProductStates,
	formatClientFormOutput,
	formatCallFormOutput,
} from './utils';
import {isSalesUser} from 'utils/perms';
import {getFormValues} from 'redux-form';
import createDatePickHandler from 'fragments/calendarResourcePicker/handler';
import {processFreeCalendarResources} from 'fragments/calendarResourcePicker/utils';
import createBuildingModalHandler from 'fragments/buildingModalActions/handler';
import createReminderHandler from 'fragments/callReminder/handler';
import {pipe, prop, pick, equals, isEmpty} from 'ramda';
import * as selectors from './selectors';
import {notReachedCallBase} from './constants';

const pipeHandlers = pipeSubHandlers(ns);

const buildingRefreshKeys = [
	'buildingLoading',
	'encountersLoading',
	'freeCalendarResourcesLoading',
];
const buildingLoadingKeys = [...buildingRefreshKeys, 'salesTeamsLoading'];
// values to mark as loading when refreshing the building - only includes stuff that can need refresh
const buildingRefreshInitValues = pick(buildingRefreshKeys, initState);
// values to mark as loading when moving to a new building - includes anything building-specific
const buildingLoadingInitValues = pick(buildingLoadingKeys, initState);

const noCallValues = {leaddeskCallInProgress: false, leaddeskTalkInProgress: false};

const datePickHandler = createDatePickHandler({
	actions,
	effects,
	calendarResourceSelectionSelector: pipe(
		getFormValues('callClientForm'),
		prop('calendarResourceId'),
	),
});

const buildingModalHandler = createBuildingModalHandler({actions, effects});

const reminderHandler = createReminderHandler({
	actions,
	effects,
	buildingSelector: selectors.building,
});

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize(payload)];
		}

		case actions.resetCallForm.type: {
			return [state, effects.resetCallForm()];
		}

		case actions.cancelEncounter.type: {
			return [state, null];
		}

		case actions.changeBuilding.type: {
			return [
				{
					...state,
					...buildingLoadingInitValues,
					reservedSuccessfully: false,
					callJustSaved: false,
					// end possible ongoing call
					...noCallValues,
				},
				effects.changeBuilding(payload),
			];
		}

		case actions.saveCall.type: {
			const form = getFormValues('callClientForm')(fullState);
			const initValues = selectors.callFormInitValues(fullState);
			// save survey only if it has been changed from initial
			const doSaveSurvey = !equals(form.survey, initValues.survey);
			const doSaveSurveyCallReview = !equals(
				form.surveyCallReview,
				initValues.surveyCallReview,
			);
			const user = rootSelectors.user(fullState);
			const isSalesmanager = rootSelectors.isSalesmanagerUser(fullState);
			const teamId = !isEmpty(state.userTeams) && state.userTeams[0].id;

			const {call, calendarResource, formFill, survey, surveyCallReview} =
				formatCallFormOutput({
					form,
					building: state.building,
					teamId,
				});
			const call2 = {
				...call,
				buildingId: state.building.id,
				callPoolId:
					payload.inCallPool && state.activeCallPool ? state.activeCallPool.id : null,
			};

			// set salesmanid null if user isn't salesUser
			// get salesmanId from calendarResource if user is salesmanager and cr is bonus time
			// else use current user's id
			const salesmanId =
				!isSalesUser(user) || !calendarResource
					? null
					: isSalesmanager
					? !calendarResource.bonus
						? user.id
						: calendarResource.salesmanId
					: user.id;

			const setRequestSource = form => {
				if (form?.requestDate !== undefined && form?.requestSource !== undefined) {
					return {
						requestSource: form?.requestSource,
						requestDate: form?.requestDate,
					};
				}
				return {};
			};

			const marketingLeadSource = form?.isRequest ? setRequestSource(form) : {};
			const calendarResource2 = calendarResource
				? {
						...calendarResource,
						...marketingLeadSource,
						reserverId: user.id,
						salesmanId,
				  }
				: null;
			// console.log(calendarResource);
			// return;
			return [
				{...state, processingEncounter: true},
				effects.saveCallData({
					call: call2,
					calendarResource: calendarResource2,
					formFill,
					survey: doSaveSurvey ? survey : null,
					surveyCallReview: doSaveSurveyCallReview ? surveyCallReview : null,
					// end possible ongoing call
					...noCallValues,
				}),
			];
		}

		case actions.closeEncounterModal.type: {
			return [{...state, encounter: null}, null];
		}

		case actions.toggleClientEditor.type: {
			const newState = {
				...state,
				client: payload,
				clientEditorOpen: !state.clientEditorOpen,
			};

			return [newState, null];
		}

		case actions.removeClient.type: {
			return [state, effects.removeClient(payload)];
		}

		case actions.saveClient.type: {
			const form = getFormValues('clientForm')(fullState);
			const client = formatClientFormOutput({form});
			const newClient = state.client ? state.client.isNew : true;
			const buildingId = newClient ? state.building.id : null;

			return [
				{...state, modalProcessing: true},
				effects.createClient({client, buildingId}),
			];
		}

		case actions.skip.type: {
			return [
				{
					...state,
					// use buildingLoading to signal page loading (even if the building doesn't have to reload if we end up staying on the same page)
					buildingLoading: true,
					// end possible ongoing call
					...noCallValues,
				},
				effects.skip(),
			];
		}

		case actions.openEncounterModal.type: {
			return [state, effects.getEncounterData(payload)];
		}

		case actions.telClient.type: {
			return [state, effects.telClient(payload)];
		}

		case actions.leaddeskCallClient.type: {
			return [state, effects.leaddeskCallClient(payload)];
		}

		case actions.hangupCall.type: {
			return [state, effects.hangupCall()];
		}

		case actions.setClientSwedish.type: {
			return [{...state, processing: true}, effects.setClientSwedish(payload)];
		}

		case actions.callNotReached.type: {
			const form = getFormValues('callClientForm')(fullState);
			const notReachedCall = {
				...notReachedCallBase,
				clientId: form.clientId,
				buildingId: state.building.id,
				callPoolId:
					payload.inCallPool && state.activeCallPool ? state.activeCallPool.id : null,
			};

			return [
				{...state, processingEncounter: true},
				effects.saveCallData({call: notReachedCall}),
			];
		}

		case actions.refreshFreeCalendarResources.type: {
			return [
				{...state, freeCalendarResourcesLoading: true},
				effects.updateFreeCalendarResources(),
			];
		}

		case actions.destroy.type: {
			return [initState, effects.destroy(payload)];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false, processingEncounter: false}, null];
		}

		case actions._reservationOk.type: {
			return [{...state, reservedSuccessfully: true}, null];
		}

		case actions._setBuilding.type: {
			const productStates = processProductStates(payload.buildingProductStates);
			const building = {...payload, buildingProductStates: productStates};

			return [{...state, building, buildingLoading: false, productStates}, null];
		}

		case actions._setEncounters.type: {
			return [{...state, encounters: payload, encountersLoading: false}, null];
		}

		case actions._setUserTeams.type: {
			return [{...state, userTeams: payload, userTeamsLoading: false}, null];
		}

		case actions._setSalesTeams.type: {
			const activeOrganizationId = rootSelectors.activeOrganizationId(fullState);
			const salesTeams = rootSelectors.canAddCalendarResourcesToAnyTeam(fullState)
				? payload.filter(t => t.organizationId === activeOrganizationId)
				: payload;

			return [{...state, salesTeams, salesTeamsLoading: false}, null];
		}

		case actions._setFreeCalRes.type: {
			return [
				{
					...state,
					freeCalendarResources: processFreeCalendarResources(payload),
					freeCalendarResourcesLoading: false,
				},
				null,
			];
		}

		case actions._setFreeCalResLoading.type: {
			return [{...state, freeCalendarResourcesLoading: payload}, null];
		}

		case actions._setActiveCallPool.type: {
			const newState = {...state, activeCallPool: payload};
			return [newState, null];
		}

		case actions._encounterSaved.type: {
			return [
				{
					...state,
					...buildingRefreshInitValues,
					processingEncounter: false,
					callJustSaved: true,
				},
				null,
			];
		}

		case actions._clearCalls.type: {
			return [{...state, ...noCallValues}, null];
		}

		case actions._setFetchingNextBuilding.type: {
			return [{...state, fetchingNextBuilding: payload}, null];
		}

		case actions._setEncounterData.type: {
			const newState = {...state, encounter: payload};
			return [newState, null];
		}

		//case actions._startedCall.type: {
		//return [state, null];
		//}

		case actions._setOpenedAt.type: {
			return [{...state, openedAt: payload}, null];
		}

		// TODO: looks like "clients" is nested under "building". unnecessary since there's only one list of clients anyway, the page's clients. move this when / if making this into a fragment. also the action is messy. why isn't this just two actions?
		case actions._updateClients.type: {
			// prettier-ignore
			const clients = payload.type === 'update' ?
				state.building.clients.map(c => {
						return c.id === payload.client.id ? payload.client : c;})
				: payload.type === 'add' ?
					[...state.building.clients, payload.client]
				: payload.type === 'remove' ?
					state.building.clients.filter(c => c.id !== payload.id)
				: [];

			const newBuilding = {...state.building, clients};
			const newState = {
				...state,
				building: newBuilding,
				clientEditorOpen: false,
				modalProcessing: false,
				processing: false,
			};

			return [newState, null];
		}

		case actions._removeClient.type: {
			// if the removed client is the last client, flag it as deleted instead of removing
			const clients =
				state.building.clients.length === 1 && state.building.clients[0].id === payload
					? state.building.clients.map(c => ({...c, deleted: true}))
					: state.building.clients.filter(c => c.id !== payload);

			const newBuilding = {...state.building, clients};
			const newState = {
				...state,
				building: newBuilding,
				processing: false,
			};

			return [newState, null];
		}

		case actions._setModalProcessing.type: {
			return [{...state, modalProcessing: true}, null];
		}

		case actions._startedLeaddeskCall.type: {
			return [{...state, leaddeskCallInProgress: true}, null];
		}

		case actions._startedLeaddeskTalk.type: {
			return [{...state, leaddeskTalkInProgress: true}, null];
		}

		case actions._setProcessing.type: {
			return [{...state, processing: payload}, null];
		}

		case actions._setSurvey.type: {
			return [{...state, survey: payload, surveyLoading: false}, null];
		}

		case actions._setSurveyCallReview.type: {
			return [{...state, surveyCallReview: payload}, null];
		}

		case actions._setLead.type: {
			return [{...state, lead: payload}, null];
		}

		case actions._setMarketingLeadSources.type: {
			return [{...state, marketingLeadSources: payload}, null];
		}

		case actions._setProducts.type: {
			return [{...state, products: payload}, null];
		}

		case actions._setAvailableTags.type: {
			return [{...state, availableTags: payload}, null];
		}

		case actions.getTags.type: {
			return [{...state, processingTags: true}, effects.getTags(payload)];
		}

		case actions._getTags.type: {
			return [{...state, processingTags: false, tags: payload}, null];
		}

		case actions.attachTag.type: {
			return [
				{
					...state,
					processingTag: true,
				},
				effects.attachTag(payload),
			];
		}

		case actions._attachTag.type: {
			return [
				{
					...state,
					processingTag: false,
				},
				null,
			];
		}

		case actions.detachTag.type: {
			return [
				{
					...state,
					processingTag: true,
				},
				effects.detachTag(payload),
			];
		}

		case actions._detachTag.type: {
			return [
				{
					...state,
					processingTag: false,
				},
				null,
			];
		}

		case actions.setLeaveCallPoolAfterEncountedSaved.type: {
			return [
				{
					...state,
					leaveCallPoolAfterEncountedSaved: payload,
				},
				null,
			];
		}

		default:
			return pipeHandlers(datePickHandler, buildingModalHandler, reminderHandler)(
				state,
				fullState,
				{
					type,
					payload,
				},
			);
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
