export const importScript = url =>
	new Promise((resolve, reject) => {
		const script = document.createElement('script');
		script.addEventListener('load', () => {
			resolve();
		});
		script.addEventListener('error', evt => {
			reject(new Error('Script failed to load'));
		});
		script.setAttribute('src', url);

		document.querySelector('body').appendChild(script);
	});

export const importStyle = url =>
	new Promise((resolve, reject) => {
		const style = document.createElement('link');
		style.setAttribute('rel', 'stylesheet');
		style.addEventListener('load', () => {
			resolve();
		});
		style.addEventListener('error', evt => {
			reject(new Error('Stylesheet failed to load'));
		});
		style.setAttribute('href', url);

		document.querySelector('head').appendChild(style);
	});
