import createScriptImporter from './createScriptImporter';
// note: lazy
import services from '.';
import {activeOrganization} from 'modules/common/selectors';

export default createScriptImporter(
	() => 'https://prs.leaddesk.com/crm-toolkit/ld_cti_v1.js',
	() => {
		const LDTalk = window.LDTalkToolKit.init('LD_talk');

		// set leaddesks's active campaign when importing the API

		const store = services.get('store');
		const organization = activeOrganization(store.getState());
		const campaignId = organization.meta.ldCampaignId;

		// try setting campaign immediately, as `onLogin` may not fire
		if (campaignId) {
			LDTalk.setCampaign({id: campaignId});
			// immediate setCampaign may not work if the iframe is still in the login process, allow a small grace period for login
			setTimeout(() => LDTalk.setCampaign({id: campaignId}), 1e3);
			setTimeout(() => LDTalk.setCampaign({id: campaignId}), 3e3);
			setTimeout(() => LDTalk.setCampaign({id: campaignId}), 5e3);
		}
		// NOTE: this doesn't currently work, leaddesk never fires this.
		LDTalk.onLogin = () => {
			if (campaignId) {
				LDTalk.setCampaign({id: campaignId});
			}
		};

		return LDTalk;
	},
);
