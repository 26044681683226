import {effect} from 'utils/redux';
import {mergeLeft} from 'ramda';
import namespace from './namespace';
import {decorateWithNotifications} from 'io/app';
import {catchNonFatalDefault} from 'io/errors';
import {getQuery, pushQuery} from 'io/history';
import {resolveObject} from 'utils/promises';
import msgs from 'dicts/messages';
import services from 'services';
import * as actions from './actions';
import * as selectors from './selectors';
import {isValidDate} from './utils';
import {getUserTeams, postTarget, getTargets} from './io';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const fetchTeamTargets = (getState, dispatch) => {
	const team = selectors.team(getState());
	const [year, month] = selectors.selectedDate(getState()).split('-');

	return decorateWithNotifications(
		{id: 'fetch-team-targets', failureStyle: 'error'},
		resolveObject({
			// teams's targets
			teamSalesPrice: getTargets({
				type: 'salesPrice',
				teamId: team.id,
				month,
				year,
			}).then(targets => (targets.length ? targets[0] : null)),
			teamOfferCount: getTargets({
				type: 'offerCount',
				teamId: team.id,
				month,
				year,
			}).then(targets => (targets.length ? targets[0] : null)),
			// team users targets
			userSalesPrices: Promise.all(
				team.users.map(u =>
					getTargets({
						type: 'salesPrice',
						userId: u.id,
						month,
						year,
					}).then(targets => (targets.length ? targets[0] : null)),
				),
			),
			userOfferCounts: Promise.all(
				team.users.map(u =>
					getTargets({
						type: 'offerCount',
						userId: u.id,
						month,
						year,
					}).then(targets => (targets.length ? targets[0] : null)),
				),
			),
		}),
	)(getState, dispatch).then(res => {
		// filter out users who didn't have target
		const teamTargets = {
			...res,
			userSalesPrices: res.userSalesPrices.filter(s => !!s),
			userOfferCounts: res.userOfferCounts.filter(o => !!o),
		};
		dispatch(actions._setTeamTargets(teamTargets));
	});
};

export let initialize = () => (getState, dispatch) => {
	const {date} = getQuery();
	if (date && isValidDate(date)) {
		dispatch(actions._updateTeamTargetsQuery({date}));
	}

	decorateWithNotifications(
		{
			id: 'init-salesmanApp-teamTargets',
			failureStyle: 'error',
		},
		getUserTeams(),
	)(getState, dispatch)
		.then(teams => {
			dispatch(actions._setUserTeams(teams));
			return fetchTeamTargets(getState, dispatch);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let saveTargets = targets => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'save-targets',
			loading: intl.formatMessage({id: 'Saving'}),
			success: intl.formatMessage({id: msgs.saved}),
			failureStyle: 'error',
		},
		Promise.all(targets.map(t => postTarget(t))),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(() => {
			dispatch(actions._saveTargets());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
saveTargets = creator('saveTargets', saveTargets);

export let updateTeamTargets = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	fetchTeamTargets(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
updateTeamTargets = creator('updateTeamTargets', updateTeamTargets);
