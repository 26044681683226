import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {scopedHandler} from 'utils/redux';
import * as actions from './actions';
import * as effects from './effects';
import ns from './namespace';
import initState from './state';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize(payload)];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.searchBuildings.type: {
			return [state, effects.searchBuildings(payload)];
		}

		case actions.navigateToBuilding.type: {
			return [{...state, buildings: []}, effects.navigateToBuilding(payload)];
		}

		case actions.openCondoCreator.type: {
			return [{...state, condoCreatorOpen: true, condoCreatorInitValues: payload}, null];
		}

		case actions.closeCondoCreator.type: {
			return [{...state, condoCreatorOpen: false, condoCreatorInitValues: {}}, null];
		}

		case actions.saveCondo.type: {
			return [{...state, processing: true}, effects.saveCondo(payload)];
		}

		case actions._setBuildings.type: {
			return [{...state, buildings: payload}, null];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._saveCondo.type: {
			return [
				{
					...state,
					processing: false,
					condoCreatorOpen: false,
					condoCreatorInitValues: {},
				},
				null,
			];
		}

		case actions._opOk.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
