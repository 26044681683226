import {path, pipe, prop} from 'ramda';
import {mapStateKeys} from './_internal';

export default ({namespace, stateRemaps = {}}) => {
	const K = mapStateKeys(stateRemaps);

	const root = path(namespace);

	const buildingModalOpen = pipe(root, prop(K.buildingModalOpen));

	const buildingModalProcessing = pipe(root, prop(K.buildingModalProcessing));

	return {
		buildingModalOpen,
		buildingModalProcessing,
	};
};
