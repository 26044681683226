import * as actions from './actions';
import * as selectors from './selectors';
import {catchNonFatalDefault} from 'io/errors';
import {getCallReminders, putCallReminder} from './io';
import {effect} from 'utils/redux';
import namespace from './namespace';
import {decorateWithNotifications} from 'io/app';
import {getQuery, pushQuery} from 'io/history';
import {mergeLeft, equals} from 'ramda';
import {parseUrlQuery} from './utils';
import services from 'services';
import msgs from 'dicts/messages';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const fetchReminders = (getState, dispatch) => {
	return decorateWithNotifications(
		{id: 'get-reminders', failureStyle: 'error'},
		getCallReminders(selectors.remindersQueryFetchable(getState())),
	)(getState, dispatch).then(reminders => {
		dispatch(actions._setReminders(reminders));
	});
};

export let initialize = () => (getState, dispatch) => {
	const {remindersQuery} = parseUrlQuery(getQuery());
	dispatch(actions._updateRemindersQuery(remindersQuery));
	fetchReminders(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let updateReminders = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	fetchReminders(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
updateReminders = creator('updateReminders', updateReminders);

export let recheckQuery = () => (getState, dispatch) => {
	const urlQueries = parseUrlQuery(getQuery());
	const remindersQuery = selectors.remindersQuery(getState());
	if (!equals(remindersQuery, urlQueries.remindersQuery)) {
		dispatch(actions._updateRemindersQuery(urlQueries.remindersQuery));
		fetchReminders(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}
};
recheckQuery = creator('recheckQuery', recheckQuery);

export let setReminderSeen = reminder => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'set-reminder-seen',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: msgs.saved}),
		},
		putCallReminder({id: reminder.id, seen: true}),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._setProcessing(false));
			throw e;
		})
		.then(reminder => {
			dispatch(actions._setReminderSeen(reminder));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
setReminderSeen = creator('setReminderSeen', setReminderSeen);
