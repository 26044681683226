import * as nActions from 'modules/notifications/actions';
import {medDur} from 'constants/notifications';
import services from 'services';

// TODO: put any pieces of code that work with modules here, some functions from io/app should really be here

let intl = null;
services.waitFor('intl').then(x => (intl = x));

// use a parameter format that's suitable as the redux-form onSubmitFail handler. it's not exactly the nicest approach, we could use a dedicated action in the common module (for more explicit event flow) or alternatively import the store here (for avoiding any store imports at the call site), but this works and is easy.
export const handleFormSubmitFail = (errors, dispatch) => {
	dispatch(
		nActions.warning({
			id: 'form-submit-fail',
			message: intl.formatMessage({id: 'Errors in the form'}),
			duration: medDur,
		}),
	);
};
