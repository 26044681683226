import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {SMS_TEMPLATE_FORM} from 'views/UsersApp/SmsTemplatesPage/components/SmsTemplateForm';
import {getFormValues} from 'redux-form';
import {omit} from 'ramda';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [
				{
					...state,
				},
				effects.initialize(),
			];
		}

		case actions._initialize.type: {
			return [
				{
					...state,
					initialized: true,
				},
				null,
			];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions._setAvailableTags.type: {
			return [{...state, availableTags: payload}, null];
		}

		case actions.getSmsTemplates.type: {
			return [
				{
					...state,
					smsTemplatesLoading: true,
				},
				effects.getSmsTemplates(),
			];
		}

		case actions._getSmsTemplates.type: {
			return [
				{
					...state,
					smsTemplatesLoading: false,
					smsTemplates: payload,
				},
				null,
			];
		}

		case actions.openSmsTemplate.type: {
			return [
				{
					...state,
					isSmsTemplateModalOpen: true,
					activeSmsTemplate: payload,
				},
				null,
			];
		}

		case actions.openSmsTemplateModal.type: {
			return [
				{
					...state,
					isSmsTemplateModalOpen: true,
				},
				null,
			];
		}

		case actions.closeSmsTemplateModal.type: {
			return [
				{
					...state,
					isSmsTemplateModalOpen: false,
					activeSmsTemplate: null,
				},
				null,
			];
		}

		case actions.createSmsTemplate.type: {
			const form = getFormValues(SMS_TEMPLATE_FORM)(fullState);
			const values = {
				...form,
				tags: (form?.tags || []).map(t => ({id: t})),
			};

			return [
				{
					...state,
					processing: true,
				},
				effects.createSmsTemplate(values),
			];
		}

		case actions.updateSmsTemplate.type: {
			const id = payload;
			const form = getFormValues(SMS_TEMPLATE_FORM)(fullState);

			const values = omit(['key', 'senderName'], {
				...form,
			});

			return [
				{
					...state,
					processing: true,
				},
				effects.updateSmsTemplate({id, values}),
			];
		}

		case actions.deleteSmsTemplate.type: {
			const id = payload;
			return [state, effects.deleteSmsTemplate(id)];
		}

		case actions.setShowDeleted.type: {
			return [state, effects.setShowDeleted(payload)];
		}

		case actions._setShowDeleted.type: {
			return [
				{
					...state,
					smsTemplatesQuery: {
						...state.smsTemplatesQuery,
						trashed: payload,
					},
				},
				null,
			];
		}

		case actions.restoreSmsTemplate.type: {
			const id = payload;
			return [state, effects.restoreSmsTemplate(id)];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
