import {mathMod as mod} from 'ramda';
import {hourMs, dayMs} from 'constants/time';

import msgs from 'dicts/messages';
import services from 'services';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

// immutable helpers

export const setHour = (date, hour, min, s, ms) => {
	const d = new Date(date.getTime());
	d.setHours(hour, min, s, ms);
	return d;
};

export const setUtcHour = (date, hour, min, s, ms) => {
	const d = new Date(date.getTime());
	d.setUTCHours(hour, min, s, ms);
	return d;
};

// increase dates by the specified amount of time

export const addDays = (date, days) => new Date(date.getTime() + days * dayMs);

export const addHours = (date, hours) => new Date(date.getTime() + hours * hourMs);

// increase dates by the amount required to change their (locale-based) properties as requested

export const increaseDay = (date, inc) => {
	const d = new Date(date.getTime());
	const day = d.getDate();
	d.setDate(day + inc);
	return d;
};

export const increaseHour = (date, inc) => {
	const d = new Date(date.getTime());
	const hours = d.getHours();
	d.setHours(hours + inc);
	return d;
};

// http://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php
/* For a given date, get the ISO week number
 *
 * Based on information at:
 *
 *    http://www.merlyn.demon.co.uk/weekcalc.htm#WNR
 *
 * Algorithm is to find nearest thursday, its year
 * is the year of the week number. Then get weeks
 * between that date and the first day of that year.
 *
 * Note that dates in one year can be weeks of previous
 * or next year, overlap is up to 3 days.
 *
 * e.g. 2014/12/29 is Monday in week  1 of 2015
 *      2012/1/1   is Sunday in week 52 of 2011
 */
export function getWeek(date) {
	// Copy date so don't modify original
	const d = new Date(date.getTime());
	d.setHours(0, 0, 0, 0);
	// Set to nearest Thursday: current date + 4 - current day number
	// Make Sunday's day number 7
	d.setDate(d.getDate() + 4 - (d.getDay() || 7));
	// Get first day of year
	const yearStart = new Date(d.getFullYear(), 0, 1);
	// Calculate full weeks to nearest Thursday
	const weekNo = Math.ceil(((d - yearStart) / dayMs + 1) / 7);
	// Return array of year and week number
	return [d.getFullYear(), weekNo];
}

// similar to their Date.prototype counterparts, but start indexing at Monday

export const getDay = date => mod(date.getDay() - 1, 7);

export const getUtcDay = date => mod(date.getUTCDay() - 1, 7);

// (locale-based) boundaries for dates

export function getWeekBoundaries(date) {
	const mondayStart = increaseDay(date, -getDay(date));
	mondayStart.setHours(0, 0, 0, 0);
	const fridayEnd = new Date(increaseDay(mondayStart, 7).getTime() - 1);
	return [mondayStart, fridayEnd];
}

export function getHourBoundaries(date) {
	const hourStart = new Date(date.getTime());
	// note that setMinutes also resets the hour to the lower absolute date value of the conflicting dates when DST happens
	hourStart.setMinutes(0, 0, 0);
	// increaseHour - 1 gets the higher date value with DST conflicts
	const hourEnd = new Date(increaseHour(hourStart, 1).getTime() - 1);
	return [hourStart, hourEnd];
}

// takes date and returns the first day of the month
export const monthFirstDay = date => {
	return new Date(date.getFullYear(), date.getMonth(), 1, 12).toISOString().split('T')[0];
};

// takes date and returns the last day of the month
export const monthLastDay = date => {
	return new Date(date.getFullYear(), date.getMonth() + 1, 0, 12)
		.toISOString()
		.split('T')[0];
};
export const getMonthBoundaries = date => {
	return [monthFirstDay(date), monthLastDay(date)];
};

// takes an ISO-datestring (e.g. 2019-05-28T12:23:42.884Z) and returns it if form: HH:mm
export const getHoursMinutesFromIsoString = dateString => {
	const fullDate = new Date(dateString);
	let hours = fullDate.getHours();
	let minutes = fullDate.getMinutes();
	if (minutes < 10) {
		minutes = '0' + minutes;
	}
	if (hours < 10) {
		hours = '0' + hours;
	}
	return `${hours}:${minutes}`;
};

// (locale-based) rounding

export const floorHour = date => {
	const d = new Date(date.getTime());
	d.setHours(date.getHours(), 0, 0, 0);
	return d;
};

export const nextHour = date => {
	const d = new Date(date.getTime());
	d.setHours(date.getHours() + 1, 0, 0, 0);
	return d;
};

const pad2 = n => (n < 10 ? `0${n}` : n);

// misc

export const toLocaleDateString = date => {
	const y = date.getFullYear();
	const m = date.getMonth() + 1;
	const d = date.getDate();
	return `${y}-${pad2(m)}-${pad2(d)}`;
};

// add timezone error into the date value, useful when the date gets interpreted somewhere as a local date without taking the current timezone into account
export const addTimezoneError = d =>
	new Date(d.getTime() - d.getTimezoneOffset() * 60000);
export const removeTimezoneError = d =>
	new Date(d.getTime() + d.getTimezoneOffset() * 60000);

// TODO: make this pure (intl as parameter) and move elsewhere (forms?)
// not really needed, inputs have min/max values now
export const validateDate = date => {
	if (!date) {
		return intl.formatMessage({id: msgs.requiredField});
	} // check if date is before  1.1.1970 or after 1.1.2038 for validation
	else if (new Date(date) <= new Date(0) || new Date(date) >= new Date('01-01-2038')) {
		return intl.formatMessage({
			id: 'Date must be between 2.1.1970 and 1.1.2038',
		});
	} else return null;
};

// checks if date is between two dates, NOTE: ignores hours
export const dateIsBetween = ({date, from, to}) => {
	const d = new Date(date).setHours(0, 0, 0, 0);
	const f = new Date(from).setHours(0, 0, 0, 0);
	const t = new Date(to).setHours(0, 0, 0, 0);

	return d >= f && d <= t;
};
export const convertSecondsToTimer = seconds => {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = seconds % 60;
	const formattedHours = String(hours).padStart(2, '0');
	const formattedMinutes = String(minutes).padStart(2, '0');
	const formattedSeconds = String(remainingSeconds).padStart(2, '0');
	return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const convertSecondsToMinutesAndSeconds = seconds => {
	const minutes = Math.floor(seconds / 60);
	const remainingSeconds = seconds % 60;
	const formattedMinuteString = `${minutes}${intl.formatMessage({
		id: 'minutesAbbreviation',
	})}`;
	const formattedSecondString = `${remainingSeconds}${intl.formatMessage({
		id: 'secondsAbbreviation',
	})}`;
	return `${formattedMinuteString} ${formattedSecondString}`;
};

export const convertSecondsToHoursAndMinutes = seconds => {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const formattedHours = String(hours).padStart(2, '0');
	const formattedMinutes = String(minutes).padStart(2, '0');
	return `${formattedHours}:${formattedMinutes}`;
};
