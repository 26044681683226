import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	initialized: false,
	processing: true,
	categories: [],
	categoriesLoading: true,
	channels: [],
	channelsLoading: true,
	customerTypes: [],
	customerTypesLoading: true,
	sources: [],
	sourcesLoading: true,
	settingsModalOpen: false,
	selectedItem: {},
	channelsShowDeleted: false,
	categoriesShowDeleted: false,
	customerTypesShowDeleted: false,
	sourcesShowDeleted: false,
	tags: [],
	tagsLoading: true,
	tagsShowDeleted: false,
	serviceMappings: [],
	serviceMappingsLoading: true,
	serviceMappingsShowDeleted: false,
	uploadModalOpen: false,
};
