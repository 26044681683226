import styled from 'styled-components';
import {textButton, buttonSizes, centerVerticallyInline} from 'styles/fragments';

export default styled.button.attrs(({type}) => ({
	type: type || 'button',
}))`
	${textButton};
	${({size}) => (size ? buttonSizes[size] : '')};
	${centerVerticallyInline};
`;
