import {state as lifecycle} from 'fragments/lifecycle';
import createDatePickState from 'fragments/calendarResourcePicker/state';
import createBuildingModalState from 'fragments/buildingModalActions/state';

const datePickState = createDatePickState({});
const buildingModalState = createBuildingModalState({});

export default {
	...lifecycle,
	...datePickState,
	...buildingModalState,
	processing: false,
	building: null,
	buildingLoading: true,
	productStates: null,
	encounters: [],
	encountersLoading: true,
	encounter: null,
	client: null,
	clientEditorOpen: false,
	clientFormInitValues: {},
	userTeams: [],
	userTeamsLoading: true,
	salesTeams: [],
	salesTeamsLoading: true,
	freeCalendarResourcesLoading: true,
	visitJustSaved: false,
	openedAt: null,
};
