import {setCallSession} from 'modules/common/actions';
import {callSession} from 'modules/common/selectors';

const ringbacktone = document.getElementById('ringbacktoneStable');
const busyhere = document.getElementById('busyhereStable');

const enioActions = (e, store) => {
	const endCallEvent = e2 => {
		e.session.hangup();
		const callStatus = callSession(store.getState());
		//console.log(callStatus);
		const retVal = {
			id: '',
			status: 'Call terminated',
			active: false,
			startTime: callStatus.startTime,
			answerTime: callStatus.answerTime,
			endTime: Math.floor(Date.now() / 1000),
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
			muted: false,
		};
		store.dispatch(setCallSession(retVal));
	};

	const endCallBtn = document.getElementById('endCall');

	if (
		endCallBtn &&
		e.description !== 'Call terminated' &&
		e.description !== 'Call terminating...' &&
		e.description !== 'Busy Here'
	) {
		if (!endCallBtn.hasAttribute('listening')) {
			endCallBtn.setAttribute('listening', true);
			endCallBtn.addEventListener('click', endCallEvent);
		}
	}
	if (
		e.description === 'Call terminated' ||
		e.description === 'Busy Here' ||
		e.description === 'Call terminating...'
	) {
		if (endCallBtn) {
			endCallBtn.removeEventListener('click', endCallEvent);
			endCallBtn.removeAttribute('listening');
		}
	}
};

export const enioCallDescriptions = {
	'Call in progress...': async (e, store, client = {}) => {},
	Trying: async (e, store, client = {}) => {
		const callStatus = callSession(store.getState());
		//console.log('Yhdistetään verkkoon...');
		const retVal = {
			id: 'test',
			status: e.description,
			active: true,
			startTime: Math.floor(Date.now() / 1000),
			answerTime: null,
			endTime: null,
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		enioActions(e, store);
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	Ringing: async (e, store, client = {}) => {
		//console.log('Soittaa...');
		await busyhere.pause();
		await ringbacktone.play();

		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: true,
			startTime: callStatus.startTime,
			answerTime: null,
			endTime: null,
			customerURI: window.location.pathname + window.location.search,
			customerDetails: client,
		};
		enioActions(e, store);
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	OK: async (e, store, client = {}) => {
		await ringbacktone.pause();
		const callStatus = callSession(store.getState());
		//console.log('Puhelu yhdistetty');
		const retVal = {
			id: 'test',
			status: e.description,
			active: true,
			startTime: callStatus?.startTime ? callStatus?.startTime : null,
			answerTime: null,
			endTime: null,
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		enioActions(e, store);
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	// Call is in
	'In Call': async (e, store, client = {}) => {
		await ringbacktone.pause();
		ringbacktone.currentTime = 0;
		const callStatus = callSession(store.getState());
		//console.log('Puhelussa');
		let retVal = {
			id: 'test',
			status: e.description,
			active: true,
			startTime: callStatus.startTime,
			answerTime: Math.floor(Date.now() / 1000),
			endTime: null,
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		enioActions(e, store);
		//console.log(callStatus);
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	// Proper connection is made
	'Media Added': async (e, store, client = {}) => {
		//console.log('Media lisätty');
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: true,
			startTime: callStatus.startTime,
			answerTime: callStatus.answerTime,
			endTime: null,
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		enioActions(e, store);
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	// Call terminated = Client has terminated the call
	'Call terminated': async (e, store, client = {}) => {
		//console.log('Puhelu päättyi');
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: false,
			startTime: callStatus.startTime,
			answerTime: callStatus.answerTime,
			endTime: Math.floor(Date.now() / 1000),
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		enioActions(e, store);
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	// Call terminating... = User has terminated the phone
	'Call terminating...': async (e, store, client = {}) => {
		await ringbacktone.pause();
		ringbacktone.currentTime = 0;
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: false,
			startTime: callStatus.startTime,
			answerTime: callStatus.answerTime,
			endTime: null,
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		enioActions(e, store);
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	// Busy Here = Client does not want to answer or line is in use
	'Busy Here': async (e, store, client = {}) => {
		//console.log('Varattu');
		await ringbacktone.pause();
		ringbacktone.currentTime = 0;
		await busyhere.play();
		setTimeout(async () => {
			await busyhere.pause();
			busyhere.currentTime = 0;
		}, 5500);
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: false,
			startTime: callStatus.startTime,
			answerTime: null,
			endTime: Math.floor(Date.now() / 1000),
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		enioActions(e, store);
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	'Request Terminated': async (e, store, client = {}) => {
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: false,
			startTime: callStatus.startTime,
			answerTime: null,
			endTime: Math.floor(Date.now() / 1000),
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		enioActions(e, store);
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
	'Not Found': async (e, store, client = {}) => {
		await ringbacktone.pause();
		ringbacktone.currentTime = 0;
		const callStatus = callSession(store.getState());
		const retVal = {
			id: 'test',
			status: e.description,
			active: false,
			startTime: callStatus.startTime,
			answerTime: null,
			endTime: Math.floor(Date.now() / 1000),
			customerURI: callStatus.customerURI,
			customerDetails: callStatus.customerDetails,
		};
		enioActions(e, store);
		store.dispatch(setCallSession(retVal));
		return retVal;
	},

	unknown: async (e, store, client = {}) => {
		await ringbacktone.pause();
		ringbacktone.currentTime = 0;
		const retVal = {
			id: 'test',
			status: e.description,
			active: false,
			startTime: null,
			answerTime: null,
			endTime: null,
			customerURI: null,
			customerDetails: null,
		};
		enioActions(e, store);
		store.dispatch(setCallSession(retVal));
		return retVal;
	},
};
