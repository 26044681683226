import {minRefreshRate} from './constants';

export const getNextFetchableCallpool = (callpools, lastCallpoolFetched) => {
	let i = lastCallpoolFetched;
	let counter = 0;
	let isPanelOpen = callpools[i] && callpools[i].panelIsOpen ? true : false;

	while (!isPanelOpen && counter <= callpools.length) {
		counter++;
		i++;

		if (i >= callpools.length) i = 0;
		isPanelOpen = callpools[i] && callpools[i].panelIsOpen ? true : false;
	}
	const cid = counter < callpools.length ? callpools[i].id : null;
	const callPools = callpools.map(c => (c.id === cid ? {...c, loading: true} : c));

	i++;
	if (i >= callpools.length) i = 0;

	return {callPools, cid, lastCallpoolFetched: i};
};

export const getRefreshRate = refreshRate => {
	const rRate = refreshRate < minRefreshRate ? minRefreshRate : Number(refreshRate);

	return rRate;
};
