import React from 'react';
import {compose} from 'redux';
import {reduxForm, formValueSelector} from 'redux-form';
import propTypes from 'prop-types';
import {connect} from 'react-redux';
//custom helpers & utils
import {injectIntl, FormattedMessage} from '@meiko/react-intl';
import msgs from 'dicts/messages';
import {applyState} from 'utils/redux';
import services from 'services';
import {handleFormSubmitFail} from 'fragments/helpers';
import * as feedbackOpts from 'dicts/feedback';
import {toPairs, isEmpty, uniq} from 'ramda';
// components
import Field from 'components/generic/Field';
import Label from 'components/generic/Label';
import FieldError from 'components/generic/FieldError';
import FormBlock from 'components/generic/FormBlock';
import Select from 'components/generic/Select';
import TextArea from 'components/generic/TextArea';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const fieldValue = path => state => formValueSelector('feedbackForm')(state, path);

const getSubPages = ({currentPage}) => {
	if (currentPage) {
		const {pages, hiddenPages} = currentPage;
		return hiddenPages ? [...pages, ...hiddenPages] : pages;
	} else return [];
};

function validate(values) {
	const errors = {};

	if (!values.type) errors.type = intl.formatMessage({id: msgs.requiredField});
	if (!values.text) errors.text = intl.formatMessage({id: msgs.requiredField});

	return errors;
}

const FeedbackForm = ({id, intl, handleSubmit, pageDefs, viewValue}) => {
	const typeOpts = toPairs(feedbackOpts.type);

	const currentPage = viewValue && pageDefs.filter(p => p.id === viewValue)[0];
	const subPages = getSubPages({currentPage});
	const _pageDefs = uniq(pageDefs);

	return (
		<form id={id} onSubmit={handleSubmit} autoComplete="off">
			<Field
				name="type"
				component={({input, inputId, meta}) => (
					<FormBlock>
						<Label>
							<FormattedMessage id="Type" />
						</Label>
						<Select {...input} id={inputId} meta={meta} block stretch>
							{typeOpts.map(([key, val]) => (
								<option key={key} value={key}>
									{intl.formatMessage({id: val})}
								</option>
							))}
						</Select>
						<FieldError {...meta} />
					</FormBlock>
				)}
			/>
			<Field
				name="view"
				component={({input, inputId, meta}) => (
					<FormBlock noTopMargin>
						<Label>
							<FormattedMessage id="Application" />
						</Label>
						<Select {...input} id={inputId} meta={meta} block stretch>
							<option value="">{intl.formatMessage({id: msgs.selectorPrompt})}</option>
							{_pageDefs.map(c => (
								<option key={c.id} value={c.id}>
									{intl.formatMessage({id: c.name})}
								</option>
							))}
						</Select>
					</FormBlock>
				)}
			/>
			{viewValue && !isEmpty(subPages) && (
				<Field
					name="subView"
					props={{subPages: subPages}}
					component={({input, inputId, meta, subPages}) => (
						<FormBlock noTopMargin>
							<Label>
								<FormattedMessage id="View" />
							</Label>
							<Select {...input} id={inputId} meta={meta} block stretch>
								<option value="">{intl.formatMessage({id: msgs.selectorPrompt})}</option>
								{subPages.map(s => (
									<option key={s.to} value={s.to}>
										{intl.formatMessage({id: s.name})}
									</option>
								))}
							</Select>
						</FormBlock>
					)}
				/>
			)}
			<Field
				name="text"
				type="text"
				component={({input, inputId, meta}) => (
					<FormBlock>
						<Label htmlFor={inputId}>
							<FormattedMessage id="Description" />
						</Label>
						<TextArea {...input} meta={meta} id={inputId} block stretch />
						<FieldError {...meta} />
					</FormBlock>
				)}
			/>
		</form>
	);
};

FeedbackForm.propTypes = {
	intl: propTypes.object.isRequired,
	id: propTypes.string,
	handleSubmit: propTypes.func,
	pageDefs: propTypes.array,
	viewValue: propTypes.string,
};

export default compose(
	injectIntl,
	connect(
		applyState({
			viewValue: fieldValue('view'),
		}),
	),
	reduxForm({
		form: 'feedbackForm',
		validate,
		onSubmitFail: handleFormSubmitFail,
	}),
)(FeedbackForm);
