import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const changePage = creator('changePage');

export const recheckQuery = creator('recheckQuery');

export const removeLead = creator('removeLead', P.Object);

export const showPersonalLeads = creator('showPersonalLeads');

export const showSalesmanagerLeads = creator('showSalesmanagerLeads');

export const _initialize = creator('_initialize');

export const _setLeads = creator('_setLeads');

export const _updateLeadsQuery = creator('_updateLeadsQuery');

export const _removedLead = creator('_removedLead');

export const _opFailed = creator('_opFailed');

export const _setProcessing = creator('_setProcessing');
