import {pipe, pick, omit, merge, evolve, prop} from 'ramda';
import {over} from 'utils/lenses';
import {
	buildingsFiltersKeys,
	csvUniqIdStorageKey,
	emptyBuildingsFilters,
	massSelectionLimit,
} from './constants';
import {getJson} from 'io/localStorage';
import {isAfter} from 'date-fns';

const TYPE_HOUSE = 'house';
const TYPE_PROJECT = 'project';
const TYPE_CONDO = 'condo';

export const buildingSpecificTypesByType = {
	[TYPE_HOUSE]: ['detached', 'semidetached', 'otherdetached', 'unknown', 'vacationhome'],
	[TYPE_PROJECT]: [
		'apartmenthouse',
		'other',
		'rowhouse',
		'balconyaccessblock',
		'terracedhouse',
		'detachedhousecompany',
	],
	[TYPE_CONDO]: [
		'apartmenthouse',
		'other',
		'rowhouse',
		'balconyaccessblock',
		'terracedhouse',
		'detachedhousecompany',
	],
};

export const getBuildingsType = (type, overridableTypes = []) => {
	let q = '';
	const types = (
		overridableTypes && overridableTypes.length
			? overridableTypes
			: buildingSpecificTypesByType[type]
	).join(',');

	if (type === TYPE_HOUSE) {
		q = {
			types,
			onlyWithProjectBuildingId: false,
		};
	} else if (type === TYPE_PROJECT) {
		q = {
			types,
			onlyWithProjectBuildingId: false,
		};
	} else if (type === TYPE_CONDO) {
		q = {
			types,
			onlyWithProjectBuildingId: true,
		};
	}

	return q;
};

// PERM: Currently not used, old buildings app had MassEditor exception for this. Probably legacy.
export const isContactUpdater = user =>
	!!user.roles.find(r => r.slug === 'kontaktinpaeivitys');

/**
 * Removes uniqId from filters if it has expired
 * @param {Object} filters
 */

export const checkCsvUniqIdExpiration = filters => {
	const expiresAt = prop('expiresAt', getJson(csvUniqIdStorageKey));

	if (expiresAt) {
		const expiresAtDate = new Date(expiresAt);
		const shouldDropValue = isAfter(new Date(), expiresAtDate);
		if (shouldDropValue) {
			return omit(['uniqId'], filters);
		}
	}
	return filters;
};

export const formatBuildingsFiltersInput = ({buildingsQuery}) => {
	const urlQueryVals = pick(buildingsFiltersKeys, buildingsQuery);

	const filters = {
		...emptyBuildingsFilters,
		...urlQueryVals,
		types: urlQueryVals.types ? urlQueryVals.types.split(',') : [],
	};
	return checkCsvUniqIdExpiration(filters);
};

export const formatMassSelectionQuery = buildingsQuery => {
	const massSelectVals = {
		_limit: massSelectionLimit,
		onlyIds: true,
		_select: 'id',
	};
	const selection = pipe(
		omit(['_limit', '_page', '_sortDirection', '_sortColumn']),
		merge(massSelectVals),
	)(buildingsQuery);

	return selection;
};

export const parseUrlQuery = query => {
	return evolve({areas: str => (str ? JSON.parse(str) : [])}, query);
};

export const formatUrlQuery = ({buildingsQuery}) => {
	const q = buildingsQuery.areas
		? over(['areas'], JSON.stringify, buildingsQuery)
		: buildingsQuery;
	return checkCsvUniqIdExpiration(q);
};

export const formatFetchableBuildingsQuery = buildingsQuery => {
	return buildingsQuery.areas
		? over(['areas'], areas => areas.map(a => a.id).join(','), buildingsQuery)
		: buildingsQuery;
};
