import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';

export default {
	...lifecycle,
	processing: false,
	clientsQuery: {},
	clientsLoading: false,
	clients: [],
	clientsPagination: emptyPagination,
};
