// core dependencies
import React from 'react';
import styled, {keyframes} from 'styled-components';
import {injectIntl} from '@meiko/react-intl';
import propTypes from 'prop-types';
// custom helpers & utils
import {currencySymbol} from 'constants/loc';
import {
	barFillColor,
	backgroundColor,
	fontSizeS,
	textColorLight,
	screenXsMax,
} from 'styles/constants';
// components
import Panel from 'components/generic/Panel';
import ContentLoader from 'components/generic/ContentLoader';

const barHeight = '32px';

const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 20px;

	@media (max-width: ${screenXsMax}) {
		flex-direction: column;

		> :first-child {
			margin-bottom: 5px;
		}
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
`;

const BarContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;

	&:last-of-type {
		margin-bottom: 0;
	}
`;

const BarRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 5px;

	@media (max-width: ${screenXsMax}) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const BarHeader = styled.div`
	font-size: ${fontSizeS};
	color: ${textColorLight};
	min-width: 100px;
	max-width: 100px;
	line-height: 1.92;
`;

const TargetBar = styled.div`
	width: 100%;
	height: ${barHeight};
	background-color: ${backgroundColor};
	border-radius: 8px;
	overflow: hidden;
	z-index: 1;
	> * {
		height: 100%;
	}
`;

const fillBar = fillPercentage => keyframes`
	from {
		width: 0px;
		opacity: 0;
	}
	to {
		width: ${({fillPercentage}) => (fillPercentage ? `${fillPercentage}%` : '0')};
		opacity: 1;
	}
`;

const ProgressBar = styled.div`
	background-color: ${barFillColor};
	height: ${barHeight};
	border-radius: 8px;
	width: ${({fillPercentage}) => (fillPercentage ? `${fillPercentage}%` : '0%')};
	animation: ${({fillPercentage}) => fillBar(fillPercentage)} 0.5s
		cubic-bezier(0.22, 0.05, 0, 1);
	overflow: hidden;
	z-index: 2;
`;

const Amount = styled.div`
	display: flex;
	justify-content: flex-end;
	white-space: nowrap;
	font-size: ${fontSizeS};
	color: ${textColorLight};
	margin-right: 5px;
`;

const numFormatter = num =>
	Math.abs(num) > 999999
		? Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + 'M'
		: Math.abs(num) > 999
		? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
		: Math.sign(num) * Math.abs(num);

const calculateFillPercentage = ({targetAmount, currAmount}) => {
	if (!targetAmount) {
		if (currAmount > 0) return 100;
		else return 0;
	}
	return (currAmount * 100) / targetAmount;
};

// eslint-disable-next-line react/prop-types
const Bar = ({targetAmount = 0, currAmount = 0, title, unit, loading}) => (
	<BarContainer>
		<BarRow>
			<BarHeader>{`${title} (${unit})`}</BarHeader>
			<TargetBar>
				{loading ? (
					<ContentLoader noPadding />
				) : (
					<ProgressBar
						fillPercentage={calculateFillPercentage({
							targetAmount,
							currAmount,
						})}
					/>
				)}
			</TargetBar>
		</BarRow>
		<Amount>
			{`${numFormatter(currAmount)} ${unit} / ${numFormatter(targetAmount)} ${unit}`}
		</Amount>
	</BarContainer>
);

const MonthlyTargetsGraph = ({
	intl,
	salesPriceTarget,
	offerCountTarget,
	salesPrice,
	offerCount,
	loading,
	headerLeft,
	headerRight,
	maxWidth,
}) => {
	return (
		<Panel verticalPadding maxWidth={maxWidth}>
			<Header>
				{headerLeft}
				{headerRight}
			</Header>
			<Content>
				<Bar
					loading={loading}
					targetAmount={salesPriceTarget}
					currAmount={salesPrice}
					title={intl.formatMessage({id: 'Sales'})}
					unit={currencySymbol}
				/>
				<Bar
					loading={loading}
					targetAmount={offerCountTarget}
					currAmount={offerCount}
					title={intl.formatMessage({id: 'Offer'})}
					unit={intl.formatMessage({id: 'pcs'})}
				/>
			</Content>
		</Panel>
	);
};

MonthlyTargetsGraph.propTypes = {
	intl: propTypes.object,
	salesPriceTarget: propTypes.number,
	offerCountTarget: propTypes.number,
	salesPrice: propTypes.number,
	offerCount: propTypes.number,
	loading: propTypes.bool,
	headerLeft: propTypes.node,
	headerRight: propTypes.node,
	maxWidth: propTypes.string,
};

export default injectIntl(MonthlyTargetsGraph);
