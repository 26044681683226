import styled from 'styled-components';
import {headingUnstyled, textStyles} from 'styles/fragments';

const PanelHeaderTitle = styled.h1`
	${headingUnstyled};
	${textStyles.title}
	${({textAlign}) => textAlign && `text-align: ${textAlign};`}
`;

export default PanelHeaderTitle;
