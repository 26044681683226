//import {mergeLeft, equals} from 'ramda'; // TEMPORARY due to covid-19
import {mergeLeft} from 'ramda';
import {destroy as reduxFormDestroy} from 'redux-form';
import {P} from 'utils/types';
import {getQuery, pushQuery, replaceQuery} from 'io/history';
import {decorateWithNotifications} from 'io/app';
import {catchNonFatalDefault} from 'io/errors';
import {getCurrentPosition} from 'utils/geolocation';
import {over} from 'utils/lenses';
import {effect} from 'utils/redux';
import {createReferrerUrl, encodeQuery} from 'utils/url';
import {describeThrow} from 'utils/errors';
import services from 'services';
import {transform} from 'ol/proj';
import {buildingFocusZoom, buildingsMinZoom} from 'constants/maps';
import {shortDur} from 'constants/notifications';
import namespace from './namespace';
import {parseUrlQuery, formatBuildingsToGeoJSON, formatBuildingsInclude} from './utils';
import * as actions from './actions';
import * as selectors from './selectors';
import * as rootSelectors from 'modules/common/selectors';
import * as nActions from 'modules/notifications/actions';
import cache from './cache';
import {
	getBuildings,
	initializeMap,
	getBuildingsStyle,
	getFeaturesFromGeoJSON,
	getOrganizationProducts,
	getAllProducts,
	getBuilding,
	getUserTeams,
	postCalendarResource,
	getCrossSalesCount,
	postSalesmanLog,
	getOrganizations,
	getAllAreas,
} from './io';
import msgs from 'dicts/messages';
// import initState from './state';
// import {salesmanDefaultQuery} from './constants';
import {getMapTileSource} from 'io/maps';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const history = services.get('history');

const fetchBuildings =
	({notifyOpts = {}}) =>
	(getState, dispatch) => {
		const activeTab = selectors.activeTab(getState());
		const {encounterState} = selectors.contactsQuery(getState());

		const include = formatBuildingsInclude({
			encounterState,
			isSalesmanager: rootSelectors.hasSalesmanAppSalesmanagerPerms(getState()),
		});

		const buildingsQuery = selectors.contactsQueryFetchable(getState());

		const allBuildingsQuery = {
			...buildingsQuery,
			_page: 1,
			_limit: 9999,
		};

		/**
		 * Fetch separate buildings for list and map
		 * For list we fetch 300 buildings with includes
		 * For map we fetch all buildings without includes
		 * Otherwise backend runs out of memory due to sheer amount of data
		 */
		return decorateWithNotifications(
			{id: 'get-buildings', failureStyle: 'error', ...notifyOpts},
			Promise.all([
				getBuildings(buildingsQuery, include).then(res =>
					dispatch(actions._setBuildings(res.data)),
				),
				getBuildings(allBuildingsQuery, 'clients').then(res => {
					dispatch(actions._setAllBuildings(res.data));
					return res.data;
				}),
			]),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._setBuildingsLoading(false));
				throw e;
			})
			.then(([_, allBuildings]) => {
				// Create GeoJSON object from buildings and update map source
				if (allBuildings && activeTab === 'map') {
					const geoJSON = formatBuildingsToGeoJSON(allBuildings);
					const {
						contactsMap: {buildingsLayer},
					} = cache.read();

					if (buildingsLayer) {
						getFeaturesFromGeoJSON(geoJSON)
							.then(features => {
								const source = buildingsLayer.getSource();
								source.clear();
								source.addFeatures(features);
							})
							.catch(catchNonFatalDefault(getState, dispatch));
					}
				}
			});
	};

const handleBuildingClick = buildingId => (getState, dispatch) => {
	// TEMPORARY due to covid-19 Remove open add bonus modal
	dispatch(actions.openBuilding({buildingId, app: 'd2d'}));
	// Open add bonus modal if searching from area
	// if (selectors.searchType(getState()) === 'area') {
	// 	const buildings = selectors.buildings(getState());
	// 	const building = buildings.find(b => b.id === buildingId);
	// 	if (building && building.encounterState === 'salesAssignment') {
	// 		dispatch(
	// 			nActions.info({
	// 				id: 'already-has-sales-assignment',
	// 				message: intl.formatMessage({
	// 					id: 'Building already has sales assignment',
	// 				}),
	// 				duration: shortDur,
	// 			}),
	// 		);
	// 	} else if (building) {
	// 		dispatch(actions.openAddBonusModal(building));
	// 	}
	// } else {
	// 	dispatch(actions.openBuilding(buildingId));
	// }
};

const doInitMap = contactsQuery => (getState, dispatch) => {
	const {z, x, y, selectionId, mapSource} = contactsQuery;
	const org = rootSelectors.activeOrganization(getState());

	return initializeMap({
		initialZoom: z,
		initialCenter: [x, y],
		onMapLocationChanged: location => dispatch(actions.updateContactsQuery(location)),
		onBuildingClick: buildingId => handleBuildingClick(buildingId)(getState, dispatch),
		selectionId,
		manufacturingLimit: org.meta.manufacturingLimit,
		getHideBuildingStreet: () => selectors.hideBuildingStreet(getState()),
		mapSourceProps: {sourceId: mapSource},
	});
};

const doInitMapStandalone = renderBuildings => (getState, dispatch) => {
	const contactsQuery = selectors.contactsQuery(getState());

	decorateWithNotifications(
		{id: 'map-init'},
		doInitMap(contactsQuery)(getState, dispatch),
	)(getState, dispatch)
		.then(resources => {
			cache.update(over(['contactsMap'], mergeLeft(resources)));
			if (renderBuildings) {
				// create GeoJSON from buildings and render on map
				const geoJSON = formatBuildingsToGeoJSON(selectors.allBuildings(getState()));
				const {
					contactsMap: {buildingsLayer},
				} = cache.read();

				if (buildingsLayer) {
					getFeaturesFromGeoJSON(geoJSON)
						.then(features => {
							const source = buildingsLayer.getSource();
							source.clear();
							source.addFeatures(features);
						})
						.catch(catchNonFatalDefault(getState, dispatch));
				}
			}
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};

const doGetNearestBuildings = (getState, dispatch) => {
	const activeTab = selectors.activeTab(getState());
	// get user's current location
	return decorateWithNotifications(
		{
			id: 'loading-geolocation',
			failureStyle: 'error',
			loading: intl.formatMessage({id: 'Locating'}),
		},
		getCurrentPosition({enableHighAccuracy: true}).catch(
			describeThrow(
				intl.formatMessage({
					id: 'Could not determine location. Try refreshing the page.',
				}),
			),
		),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._setBuildingsLoading(false));
			throw e;
		})
		.then(position => {
			if (position.coords) {
				// transform the coordinates for map's projection (EPSG:3857)
				const transformedCoords = transform(
					[position.coords.longitude, position.coords.latitude],
					'EPSG:4326',
					'EPSG:3857',
				);

				// add location to query
				dispatch(
					actions._updateContactsQuery({
						x: transformedCoords[0],
						y: transformedCoords[1],
						z: buildingFocusZoom,
					}),
				);
				pushQuery(mergeLeft(selectors.urlQuery(getState())));

				// if map tab is active, focus map on user's location and update layer style
				if (activeTab === 'map') {
					const {
						contactsMap: {map, buildingsLayer},
					} = cache.read();
					if (map) {
						const view = map.getView();
						view.animate({center: transformedCoords, zoom: buildingFocusZoom});
					}
					if (buildingsLayer) {
						const org = rootSelectors.activeOrganization(getState());
						getBuildingsStyle({
							zoom: buildingFocusZoom,
							selectionId: selectors.selectionId(getState()),
							manufacturingLimit: org.meta.manufacturingLimit,
						})
							.then(style => buildingsLayer.setStyle(style))
							.catch(catchNonFatalDefault(getState, dispatch));
					}
				}

				// fetch buildings near user's location
				const notifyOpts =
					activeTab === 'map'
						? {loading: intl.formatMessage({id: 'Loading buildings'})}
						: {};
				return fetchBuildings({notifyOpts})(getState, dispatch);
			}
		});
};

export let initialize = () => (getState, dispatch) => {
	const isSalesmanager = rootSelectors.hasSalesmanAppSalesmanagerPerms(getState());
	const isAdmin = rootSelectors.isAdminUser(getState());
	// const isSalesman = rootSelectors.isSalesmanUser(getState()); // TEMPORARY due to covid-19
	const showOffersForSalesman = selectors.showOffersForSalesman(getState());

	const {manufacturingYearStart, manufacturingYearEnd} =
		selectors.organizationManufacturingYearLimits(getState());

	const query = mergeLeft(getQuery(), {manufacturingYearStart, manufacturingYearEnd});
	const {contactsQuery} = parseUrlQuery(query, isSalesmanager, showOffersForSalesman);

	// update manufacturing years to state which come from organization.meta.reportingManufacturingYearLimits if they arent in url
	dispatch(
		actions._updateManufacturingYears({
			manufacturingYearStart: contactsQuery.manufacturingYearStart,
			manufacturingYearEnd: contactsQuery.manufacturingYearEnd,
		}),
	);

	const {dismissPrompt, activeTab} = contactsQuery;
	let _doGetNearestBuildings = false;

	// TEMPORARY removal due to covid-19
	// need to add organizations manufacturingYears to init contactsQuery
	// const initContactsQuery = {
	// 	...initState.contactsQuery,
	// 	manufacturingYearStart,
	// 	manufacturingYearEnd,
	// };

	if (dismissPrompt) {
		dispatch(actions._updateContactsQuery(contactsQuery));
		dispatch(actions._updateBuildingsPagination({currentPage: contactsQuery._page}));
	}
	// TEMPORARY removal due to covid-19
	// else if (isSalesman && equals(contactsQuery, initContactsQuery)) {
	// 	// if the user is salesman and the query is initial, get nearest buildings with default search params
	// 	dispatch(
	// 		actions._updateContactsQuery({
	// 			...salesmanDefaultQuery,
	// 		}),
	// 	);
	// 	_doGetNearestBuildings = true;
	// }

	decorateWithNotifications(
		{
			id: 'init-salesmanApp-contacts',
			failureStyle: 'error',
		},
		Promise.all([
			activeTab === 'map'
				? doInitMap(contactsQuery)(getState, dispatch)
				: Promise.resolve(null),
			getOrganizationProducts().then(products =>
				dispatch(actions._setOrganizationProducts(products)),
			),
			isSalesmanager
				? getAllProducts().then(products => dispatch(actions._setAllProducts(products)))
				: Promise.resolve(null),
			// TEMPORARY change due to covid-19 add isSalesmanager check back after restrictions
			getUserTeams().then(teams => dispatch(actions._setUserTeams(teams))),
			isAdmin
				? getAllAreas().then(areas => dispatch(actions._setAllAreas(areas)))
				: Promise.resolve(null),
			isSalesmanager
				? getOrganizations().then(o => dispatch(actions._setOrganizations(o)))
				: Promise.resolve(null),
			isSalesmanager
				? getCrossSalesCount().then(cs => dispatch(actions._setCrossSalesCount(cs)))
				: Promise.resolve(null),
		]),
	)(getState, dispatch)
		.then(res => {
			if (res[0]) {
				cache.update(over(['contactsMap'], mergeLeft(res[0])));
			}
			if (dismissPrompt) {
				const notifyOpts =
					activeTab === 'map'
						? {loading: intl.formatMessage({id: 'Loading buildings'})}
						: {};
				return fetchBuildings({notifyOpts})(getState, dispatch);
			} else if (_doGetNearestBuildings) {
				return doGetNearestBuildings(getState, dispatch);
			}
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let destroy = () => (getState, dispatch) => {
	dispatch(reduxFormDestroy('buildingsFilterForm'));
	cache.reset();
};
destroy = creator('destroy', destroy);

export let getNearestBuildings = () => (getState, dispatch) => {
	doGetNearestBuildings(getState, dispatch).catch(
		catchNonFatalDefault(getState, dispatch),
	);
};
getNearestBuildings = creator('getNearestBuildings', getNearestBuildings);

export let getAreaBuildings = () => (getState, dispatch) => {
	const activeTab = selectors.activeTab(getState());
	pushQuery(mergeLeft(selectors.urlQuery(getState())));

	// Get area's coords and focus map on them
	const {areaId} = selectors.contactsQuery(getState());
	const teamAreas = selectors.teamAreas(getState());
	const area = teamAreas.find(a => a.id === areaId);

	if (area && area.centroid && area.centroid.coordinates) {
		// transform the coordinates for map's projection (EPSG:3857)
		const transformedCoords = transform(
			area.centroid.coordinates,
			'EPSG:4326',
			'EPSG:3857',
		);

		// add location to query
		dispatch(
			actions._updateContactsQuery({
				x: transformedCoords[0],
				y: transformedCoords[1],
				z: buildingsMinZoom,
			}),
		);
		pushQuery(mergeLeft(selectors.urlQuery(getState())));

		// if map tab is active, focus map on user's location and update layer style
		if (activeTab === 'map') {
			const {
				contactsMap: {map, buildingsLayer},
			} = cache.read();
			if (map) {
				const view = map.getView();
				view.animate({center: transformedCoords, zoom: buildingsMinZoom});
			}
			if (buildingsLayer) {
				const org = rootSelectors.activeOrganization(getState());
				getBuildingsStyle({
					zoom: buildingsMinZoom,
					selectionId: selectors.selectionId(getState()),
					manufacturingLimit: org.meta.manufacturingLimit,
				})
					.then(style => buildingsLayer.setStyle(style))
					.catch(catchNonFatalDefault(getState, dispatch));
			}
		}
	}

	// fetch buildings in the area
	const notifyOpts =
		activeTab === 'map' ? {loading: intl.formatMessage({id: 'Loading buildings'})} : {};
	return fetchBuildings({notifyOpts})(getState, dispatch).catch(
		catchNonFatalDefault(getState, dispatch),
	);
};
getAreaBuildings = creator('getAreaBuildings', getAreaBuildings);

export let initMap =
	({dismissPrompt}) =>
	(getState, dispatch) => {
		pushQuery(mergeLeft(selectors.urlQuery(getState())));

		if (!dismissPrompt) {
			dispatch(
				nActions.info({
					id: 'search-prompt',
					message: intl.formatMessage({
						id: 'To search for contacts, set the criteria above and press Search',
					}),
					duration: shortDur,
				}),
			);
		}

		// if prompt dismissed, we have already done search in listview and buildings are in store
		doInitMapStandalone(dismissPrompt)(getState, dispatch);
	};
initMap = creator('initMap', initMap);

export let reinitMap = () => (getState, dispatch) => {
	const {
		contactsMap: {map},
	} = cache.read();

	// handle edge case where the map can get "unmounted" while the module is on background if the window size changes
	if (!map.isRendered()) {
		map.dispose();
		doInitMapStandalone(true)(getState, dispatch);
	}
};
reinitMap = creator('reinitMap', reinitMap);

export let setMapSource = id => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	const {
		contactsMap: {mapLayer},
	} = cache.read();

	if (mapLayer) {
		getMapTileSource({user: rootSelectors.user(getState()), sourceId: id})
			.then(s => mapLayer.setSource(s))
			.catch(catchNonFatalDefault(getState, dispatch));
	}
};
setMapSource = creator('setMapSource', setMapSource, P.String);

export let updateUrlQuery = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
};
updateUrlQuery = creator('updateUrlQuery', updateUrlQuery);

export let updateContactsQuery = prevQuery => (getState, dispatch) => {
	const currQuery = selectors.urlQuery(getState());
	replaceQuery(mergeLeft(currQuery));

	if (currQuery.z !== prevQuery.z) {
		// update buildings layer style when zoom changes
		const {
			contactsMap: {buildingsLayer},
		} = cache.read();

		if (buildingsLayer) {
			const {z, selectionId} = currQuery;
			const org = rootSelectors.activeOrganization(getState());
			getBuildingsStyle({
				zoom: z,
				selectionId,
				manufacturingLimit: org.meta.manufacturingLimit,
			})
				.then(style => buildingsLayer.setStyle(style))
				.catch(catchNonFatalDefault(getState, dispatch));
		}
	}
};
updateContactsQuery = creator('updateContactsQuery', updateContactsQuery);

export let openBuilding =
	({buildingId, app}) =>
	(getState, dispatch) => {
		// update selected buildingId to url query
		const currQuery = selectors.urlQuery(getState());
		replaceQuery(mergeLeft(currQuery));

		// highlight on map
		const {z, selectionId} = currQuery;
		const {
			contactsMap: {buildingsLayer},
		} = cache.read();

		if (buildingsLayer) {
			const org = rootSelectors.activeOrganization(getState());
			getBuildingsStyle({
				zoom: z,
				selectionId,
				manufacturingLimit: org.meta.manufacturingLimit,
			})
				.then(style => buildingsLayer.setStyle(style))
				.catch(catchNonFatalDefault(getState, dispatch));
		}
		const url =
			app === 'd2d'
				? `/salesman-app/contacts/d2d/buildings/${buildingId}`
				: app === 'calls'
				? `/salesman-app/contacts/calls/buildings/${buildingId}`
				: '';

		const referrerUrl = createReferrerUrl(history.location);

		decorateWithNotifications(
			{id: 'salesman-app-log'},
			postSalesmanLog({url, buildingId}),
		)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));

		// navigate to building
		history.push(
			`${url}${encodeQuery({
				referrer: 'salesman-app-contacts',
				referrerUrl,
				disableReturn: true,
			})}`,
		);
	};
openBuilding = creator('openBuilding', openBuilding, P.Object);

export let updateBuilding = buildingId => (getState, dispatch) => {
	const activeTab = selectors.activeTab(getState());
	const encounterState = selectors.encounterState(getState());
	const isSalesmanager = rootSelectors.hasSalesmanAppSalesmanagerPerms(getState());

	const include = formatBuildingsInclude({encounterState, isSalesmanager});
	decorateWithNotifications(
		{id: 'update-building', failureStyle: 'error'},
		getBuilding(buildingId, include),
	)(getState, dispatch)
		.then(b => {
			dispatch(actions._updateBuilding(b));
			if (activeTab === 'map') {
				// update building feature on map
				const {
					contactsMap: {buildingsLayer},
				} = cache.read();
				if (buildingsLayer) {
					const source = buildingsLayer.getSource();
					const feature = source.getFeatureById(buildingId);
					if (feature) {
						feature.setProperties({
							encounterState: b.encounterState,
							encounterDate: b.encounterDate,
							manufacturingYear: b.manufacturingYear,
							address: b.address,
						});
					}
				}
			}
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
updateBuilding = creator('updateBuilding', updateBuilding, P.Number);

export let addBonus = calendarResource => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'add-bonus',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Calendar entry saved'}),
		},
		postCalendarResource(calendarResource),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(res => {
			dispatch(actions._addBonus());
			dispatch(actions._updateBuilding(res.building));

			// update crossSales count
			if (calendarResource.crossSales) {
				return decorateWithNotifications(
					{id: 'fetch-cross-sales', failureStyle: 'warning'},
					getCrossSalesCount(),
				)(getState, dispatch).then(crossSales => {
					dispatch(actions._setCrossSalesCount(crossSales));
				});
			}

			// update building feature on map
			const activeTab = selectors.activeTab(getState());
			if (activeTab === 'map') {
				const {
					contactsMap: {buildingsLayer},
				} = cache.read();
				if (buildingsLayer) {
					const source = buildingsLayer.getSource();
					const feature = source.getFeatureById(res.building.id);
					if (feature) {
						feature.setProperties({
							encounterState: res.building.encounterState,
							encounterDate: res.building.encounterDate,
						});
					}
				}
			}
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
addBonus = creator('addBonus', addBonus);
