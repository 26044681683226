import {reset} from 'redux-form';
import {effect} from 'utils/redux';
import {catchNonFatalDefault} from 'io/errors';
import namespace from './namespace';
import * as actions from './actions';
import {ensureAccess, setPageTitleMessage, decorateWithNotifications} from 'io/app';
import * as commonSelectors from 'modules/common/selectors';
import {appName} from '../constants';
import services from 'services';
import {getBuildings, postCondo} from './io';
import {encodeQuery} from 'utils/url';
import * as condoPageActions from 'modules/projectSalesApp/condoPage/actions';
import * as mapActions from 'modules/projectSalesApp/mapPage/actions';

const history = services.get('history');
const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

export let initialize = () => (getState, dispatch) => {
	setPageTitleMessage('Project Sales [app]')(getState, dispatch);
	ensureAccess(appName, commonSelectors.user(getState()))(getState, dispatch).then(() => {
		dispatch(actions._initialize());
	});
};
initialize = creator('initialize', initialize);

export let searchBuildings = ({text, callback}) => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'search-buildings',
			failureStyle: 'warning',
		},
		getBuildings({_q: text, _limit: '20', include: 'clients'}),
	)(getState, dispatch)
		.then(buildings => {
			callback(buildings);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
searchBuildings = creator('searchBuildings', searchBuildings);

export let navigateToBuilding = ({buildingId, referrer}) => (getState, dispatch) => {
	history.push(`/project-sales/map${encodeQuery({buildingId, referrer})}`);
	dispatch(reset('searchForm'));
};
navigateToBuilding = creator('navigateToBuilding', navigateToBuilding);

export let saveCondo = ({form, initialValues}) => (getState, dispatch) => {
	if (form.encounterState && form.encounterState !== initialValues.encounterState) {
		form.encounterDate = new Date();
		form.encounterType = 'none';
	}
	decorateWithNotifications(
		{
			id: 'save-condo',
			failureStyle: 'error',
			loading: intl.formatMessage({id: 'Saving'}),
			success: intl.formatMessage({id: 'Condominium saved'}),
		},
		postCondo(form),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(condo => {
			dispatch(actions._saveCondo());
			if (form.projectBuildingId) {
				// Creating condo from existing building, reinitialize CondoPage and update map
				dispatch(condoPageActions.clearNewManagerClients());
				dispatch(condoPageActions.reinitialize(condo.projectBuildingId));
				dispatch(mapActions.updateMap());
			} else {
				history.push(`/project-sales/condo/${condo.projectBuildingId}`);
			}
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
saveCondo = creator('saveCondo', saveCondo);
