import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {formatTimeEntryFormOutput} from './utils';
import {getFormValues} from 'redux-form';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, effects.destroyReduxForm('timeEntriesSearch')];
		}

		case actions.fetchTimeEntries.type: {
			const form = getFormValues('timeEntriesSearch')(fullState);
			const timeEntriesQuery = {...state.timeEntriesQuery, ...form};

			return [
				{
					...state,
					loadingTimeEntries: true,
					timeEntriesQuery,
				},
				effects.fetchTimeEntries(),
			];
		}

		case actions.setSelectedUser.type: {
			const timeEntriesQuery = {...state.timeEntriesQuery, userId: payload};

			return [
				{
					...state,
					selectedUserId: payload,
					loadingTimeEntries: true,
					timeEntriesQuery,
				},
				effects.fetchTimeEntries(),
			];
		}

		case actions.createTimeEntry.type: {
			const entry = formatTimeEntryFormOutput({
				form: payload,
				userId: state.selectedUserId,
			});

			return [{...state, processing: true}, effects.createTimeEntry(entry)];
		}

		case actions.updateTimeEntry.type: {
			const entry = formatTimeEntryFormOutput({
				form: payload,
				userId: state.selectedUserId,
			});

			return [
				{...state, processing: true},
				effects.updateTimeEntry({id: state.entryInEdit.id, ...entry}),
			];
		}

		case actions.deleteTimeEntry.type: {
			return [state, effects.deleteTimeEntry(state.entryInEdit.id)];
		}

		case actions.openTimeEntriesCreator.type: {
			return [{...state, creatorOpen: true}, effects.openTimeEntriesCreator(payload)];
		}

		case actions.closeTimeEntriesCreator.type: {
			return [{...state, creatorOpen: false}, null];
		}

		case actions.openTimeEntriesEditor.type: {
			return [{...state, editorOpen: true, entryInEdit: payload}, null];
		}

		case actions.closeTimeEntriesEditor.type: {
			return [{...state, editorOpen: false, entryInEdit: null}, null];
		}

		case actions.getDayInfo.type: {
			return [state, effects.getDayInfo(payload)];
		}

		case actions._setTimeEntries.type: {
			return [{...state, timeEntries: payload, loadingTimeEntries: false}, null];
		}

		case actions._setUsers.type: {
			return [{...state, users: payload}, null];
		}

		case actions._timeEntryCreated.type: {
			return [{...state, creatorOpen: false, processing: false}, null];
		}

		case actions._timeEntryUpdated.type: {
			return [{...state, editorOpen: false, entryInEdit: null, processing: false}, null];
		}

		case actions._timeEntryDeleted.type: {
			return [{...state, editorOpen: false, entryInEdit: null, processing: false}, null];
		}

		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._updateTimeEntriesQuery.type: {
			return [{...state, timeEntriesQuery: payload}, null];
		}

		case actions._setSelectedUserId.type: {
			return [{...state, selectedUserId: payload}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
