import {scopedHandler} from 'utils/redux';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {formatOrganizationTargetFormOutput} from './utils';
import * as rootSelectors from 'modules/common/selectors';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.saveTargets.type: {
			const targets = formatOrganizationTargetFormOutput({
				form: payload,
				organizationId: rootSelectors.activeOrganizationId(fullState),
			});
			return [{...state, processing: true}, effects.saveTargets(targets)];
		}

		case actions.updateOrganizationTargetsQuery.type: {
			const newState = {
				...state,
				organizationTargetsLoading: true,
				organizationTargetsQuery: {...state.organizationTargetsQuery, ...payload},
			};

			return [newState, effects.updateOrganizationTargets()];
		}

		case actions._saveTargets.type: {
			return [{...state, processing: false}, null];
		}

		case actions._setOrganizationTargets.type: {
			return [
				{...state, organizationTargetsLoading: false, organizationTargets: payload},
			];
		}

		case actions._updateOrganizationTargetsQuery.type: {
			const newState = {
				...state,
				organizationTargetsQuery: {...state.organizationTargetsQuery, ...payload},
			};

			return [newState, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
