import {action} from 'utils/redux';
import namespace from './namespace';
import {P, PaginatedData} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const changePage = creator('changePage', P.Number);

export const recheckQuery = creator('recheckQuery');

export const setReminderSeen = creator('setReminderSeen', P.Object);

export const _setReminders = creator('_setReminders', PaginatedData(P.Array));

export const _updateRemindersQuery = creator('_updateRemindersQuery', P.Object);

export const _setReminderSeen = creator('_setReminderSeen', P.Object);

export const _setProcessing = creator('_setProcessing', P.Boolean);
