import {evolve, over, lensPath, sortBy, prop} from 'ramda';
import {replace} from 'utils/objects';
import initState from './state';
import {sortByTitle as sortAreas} from 'utils/areas';

const formDefaultValues = [
	'deal',
	'cancelledOffer',
	'offer',
	'cancelledDeal',
	'notReached',
	'contacted',
];

const parseArrayValues = (val, key) => {
	if (key === 'state') return !val ? formDefaultValues : val.split(',');
	if (key === 'reservationSources') return !val ? [] : val.split(',');
	if (key === 'countyId' || key === 'cityId')
		return !val ? [] : val.split(',').map(v => parseInt(v));
	if (key === 'userId')
		return !val ? [] : val.split(',').map(v => (v === 'team' ? v : parseInt(v)));
	if (key === 'cancelled') return val ? JSON.parse(val) : false;
};

export const parseUrlQuery = query => {
	const vals = evolve(
		{
			_page: Number,
			state: val => parseArrayValues(val, 'state'),
			countyId: val => parseArrayValues(val, 'countyId'),
			cityId: val => parseArrayValues(val, 'cityId'),
			userId: val => parseArrayValues(val, 'userId'),
			cancelled: val => parseArrayValues(val, 'cancelled'),
			unacknowledged: val => (val ? JSON.parse(val) : false),
			reservationSources: val => parseArrayValues(val, 'reservationSources'),
		},
		query,
	);
	return {salesmanVisitsQuery: replace(vals, initState.salesmanVisitsQuery)};
};

export const formatFetchableQuery = ({
	salesmanVisitsQuery,
	user,
	userTeams,
	isSalesman,
	canSelectTeam,
	canSelectUser,
}) => {
	// send teamId if "own team" option is selected and current user has permission to view team users
	const teamId =
		salesmanVisitsQuery.userId.includes('team') && canSelectTeam && userTeams.length
			? userTeams[0].id
			: null;

	// do not send userId if "own team" option is selected
	// send selected user's id if current user has permission to select other users
	// otherwise send current user's id
	// prettier-ignore
	const userId = teamId ? null
		: salesmanVisitsQuery.userId.length && canSelectUser ? salesmanVisitsQuery.userId
		: user.id;
	const areaId = salesmanVisitsQuery.cityId || salesmanVisitsQuery.countyId;

	// checkVisibilityForSalesman if user is salesman
	// prettier-ignore
	const salesmanQuery = isSalesman && userId === user.id ? {
    checkVisibilityForSalesman: true,
	} : {};
	// fetching calendarResources instead of salesmanVisits, query is pretty much the same
	//console.log('---formatFetchableQuery---', salesmanVisitsQuery);
	if (
		salesmanVisitsQuery.unacknowledged &&
		JSON.stringify(salesmanVisitsQuery.state) === JSON.stringify(['unvisited'])
	) {
		return {
			_page: salesmanVisitsQuery._page,
			dateFrom: salesmanVisitsQuery.dateFrom,
			dateTo: salesmanVisitsQuery.dateTo,
			areaId: areaId?.length !== 0 ? areaId : null,
			teamId: teamId?.length !== 0 ? teamId : null,
			salesmanId: userId?.length !== 0 ? userId : null,
			withoutCancelled: !salesmanVisitsQuery.cancelled,
			reservationSources: salesmanVisitsQuery.reservationSources,
			unacknowledged: salesmanVisitsQuery.unacknowledged,
			...salesmanQuery,
		};
	}
	// Force state change if state is still unvisited
	const state =
		JSON.stringify(salesmanVisitsQuery.state) === JSON.stringify(['unvisited'])
			? formDefaultValues
			: salesmanVisitsQuery.state;

	if (areaId?.length > 1) {
		return {
			_page: salesmanVisitsQuery._page,
			states: state,
			dateFrom: salesmanVisitsQuery.dateFrom,
			dateTo: salesmanVisitsQuery.dateTo,
			reservationSources: salesmanVisitsQuery.reservationSources,
			unacknowledged: salesmanVisitsQuery.unacknowledged,
			areaIds: areaId,
			teamId,
			userId,
		};
	}
	return {
		_page: salesmanVisitsQuery._page,
		states: state,
		dateFrom: salesmanVisitsQuery.dateFrom,
		dateTo: salesmanVisitsQuery.dateTo,
		reservationSources: salesmanVisitsQuery.reservationSources,
		unacknowledged: salesmanVisitsQuery.unacknowledged,
		areaId: areaId?.length !== 0 ? areaId : null,
		teamId,
		userId,
	};
};

export const formatAreas = areas => {
	// areas fetched withoutTransform, so change municipal_name to camelCase here
	const _areas = sortAreas(areas.map(a => ({...a, municipalName: a.municipal_name})));
	const counties = _areas.filter(a => a.type === 'county');
	const cities = _areas.filter(a => a.type === 'city');
	const postcodes = _areas.filter(a => a.type === 'postcode');

	return {
		counties,
		cities,
		postcodes,
	};
};

export const formatUrlQuery = ({salesmanVisitsQuery}) => {
	//console.log('---FormatUrlQuery---', salesmanVisitsQuery);
	return {
		...salesmanVisitsQuery,
		userId: salesmanVisitsQuery.userId.join(','),
	};
};

export const formatSearchFormInput = ({salesmanVisitsQuery, user}) => {
	//console.log('---formatSearchFormInput---', salesmanVisitsQuery);
	//console.log(salesmanVisitsQuery.unacknowledged);
	const state =
		JSON.stringify(salesmanVisitsQuery.state) === JSON.stringify(['unvisited']) &&
		!salesmanVisitsQuery.unacknowledged
			? formDefaultValues
			: salesmanVisitsQuery.state;
	return {
		state: state,
		dateFrom: salesmanVisitsQuery.dateFrom,
		dateTo: salesmanVisitsQuery.dateTo,
		countyId: salesmanVisitsQuery.countyId,
		cityId: salesmanVisitsQuery.cityId,
		userId: salesmanVisitsQuery.userId,
		cancelled: salesmanVisitsQuery.cancelled,
		unacknowledged: salesmanVisitsQuery.unacknowledged,
		reservationSources: salesmanVisitsQuery.reservationSources,
	};
};

export const formatSearchFormOutput = form => {
	let state;

	if (form.unacknowledged) {
		state = ['unvisited'];
	} else if (
		!form.unacknowledged &&
		JSON.stringify(form.state) === JSON.stringify(['unvisited'])
	) {
		state = formDefaultValues;
	} else {
		state = form.state;
	}
	return {
		_page: 1,
		state: state,
		dateFrom: form.dateFrom,
		dateTo: form.dateTo,
		countyId: form.countyId,
		cityId: form.cityId,
		userId: form.userId,
		cancelled: form.cancelled,
		reservationSources: form.reservationSources,
		unacknowledged: form.unacknowledged,
	};
};

export const sortTeamUsers = teams => {
	if (!teams.length) return [];
	return over(lensPath([0, 'users']), sortBy(prop('lastName')), teams);
};

// PERM: ignore-visible-for-salesman
export const isSalesmanUser = user => !!user.roles.find(r => r.type === 'salesman');
