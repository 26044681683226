import {effect} from 'utils/redux';
import {decorateWithNotifications} from 'io/app';
import {catchNonFatalDefault} from 'io/errors';
import {deleteBuilding, updateBuilding} from './io';
import * as confirmerActions from 'modules/confirmer/actions';
import msgs from 'dicts/messages';
import services from 'services';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

export default ({namespace, actions}) => {
	const creator = effect(namespace);

	const removeBuilding = bid => (getState, dispatch) => {
		const onConfirm = () => {
			dispatch(actions._setBuildingModalProcessing(true));
			decorateWithNotifications(
				{
					id: 'delete-building',
					failureStyle: 'error',
					loading: intl.formatMessage({id: msgs.processing}),
					success: intl.formatMessage({id: 'Building deleted'}),
				},
				deleteBuilding(bid),
			)(getState, dispatch)
				.catch(e => {
					dispatch(actions._setBuildingModalProcessing(false));
					throw e;
				})
				.then(() => {
					dispatch(actions._setBuildingModalProcessing(false));
					dispatch(actions.toggleBuildingModal(false));
					// TODO: Navigoidaan pois?
				})
				.catch(catchNonFatalDefault);
		};
		dispatch(
			confirmerActions.show({
				message: intl.formatMessage({id: 'Delete building?'}),
				cancelText: intl.formatMessage({id: msgs.cancel}),
				onCancel: () => {},
				onOk: onConfirm,
			}),
		);
	};

	const saveBuildingData =
		({data, initData, include}) =>
		(getState, dispatch) => {
			// front takes care of updating encounter date and type modifying a building's encounterState directly
			if (data.encounterState && data.encounterState !== initData.encounterState) {
				data.encounterDate = new Date();
				data.encounterType = 'none';
			}

			decorateWithNotifications(
				{
					id: 'save-building',
					failureStyle: 'error',
					loading: intl.formatMessage({id: 'Saving'}),
					success: intl.formatMessage({id: 'Building information saved'}),
				},
				updateBuilding(data, include),
			)(getState, dispatch)
				.catch(e => {
					dispatch(actions._setBuildingModalProcessing(false));
					throw e;
				})
				.then(building => {
					dispatch(actions._setBuildingModalProcessing(false));
					dispatch(actions._updateBuilding(building));
				})
				.catch(catchNonFatalDefault);
		};

	return {
		removeBuilding: creator('removeBuilding', removeBuilding),
		saveBuildingData: creator('saveBuildingData', saveBuildingData),
	};
};
