import {pipe, prop, map} from 'ramda';
import services from 'services';
import importMaps from 'services/importMaps';
import msgs from 'dicts/messages';
import {describeThrow, describeError} from 'utils/errors';
import {apiUrl} from 'constants/app';
import {
	maxZoom,
	buildingsMinZoom,
	styles as mapStyles,
	buildingsYearMinZoom,
	areasMaxDetailZoom,
	groundwaterAreasMinZoom,
	propertyLinesMinZoom,
} from 'constants/maps';
import {
	safeGetFeaturesAtPixel,
	safeHasFeatureAtPixel,
	addTooltipToLayers,
	renderBuildingTooltip,
	renderAreaTooltip,
	renderCallPoolsTooltip,
	createMarkerLayer,
	_getMapTileSource,
} from 'io/maps';
import {deleteChildren} from 'utils/dom';
import {encodeQuery} from 'utils/url';
import * as normalize from 'utils/normalize';
import {getResponseData, mapResponseData} from 'utils/app';
import {
	activeCallPoolInclude,
	issueInclude,
	areaOffersQueryBase,
	callPoolAreasInclude,
	drawTypes,
	projectOnlyOrganizations,
	buildingInclude,
	encounterInclude,
} from './constants';
import {transform} from 'ol/proj';
import {getJsonProperties, getJsonProperty, concreteBuildingStyle} from 'utils/maps';
import {getAggregateByType} from './utils';
import {mapTooltip as mapTooltipStyles} from 'styles/fragments';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));

let intl = null;
services.waitFor('intl').then(x => (intl = x));

// exclusive max boundary for showing areas - show when buildings are no longer shown
const areasMaxZoom = buildingsMinZoom;

const decorateMapMethod = method => args =>
	importMaps()
		.then(method(args))
		.catch(describeThrow(intl.formatMessage({id: 'Error loading map'})));

// resources

export const getCallPools = () =>
	httpJson('get', '/callPools', {})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load callpools'})))
		.then(pipe(prop('data'), map(normalize.callPool)));

export const getActiveCallPool = callPoolId =>
	httpJson('get', `/callPools/${callPoolId}`, {include: activeCallPoolInclude})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load the callpool'})))
		.then(getResponseData(normalize.callPool));

export const deleteCallPoolArea = (callPoolId, areaId) =>
	httpJson('delete', `/callPools/${callPoolId}/areas/${areaId}`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to remove area from callpool'})),
	);

export const getAreaInfo = areaId =>
	httpJson('get', `/areas/${areaId}/info`, {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(prop('data'));

export const postAddAreaToCallPool = (
	areaId,
	manufacturingYearStart,
	manufacturingYearEnd,
	excludeUsers,
	callPoolId,
) =>
	httpJson(
		'post',
		`/callPools/${callPoolId}/areas`,
		{},
		{body: {areaId, manufacturingYearStart, manufacturingYearEnd, excludeUsers}},
	)
		.catch(describeThrow(intl.formatMessage({id: 'Failed to add areas'})))
		.then(prop('data'));

export const getIssues = areaIds =>
	httpJson('get', '/issues', {areaIds, include: issueInclude})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load issues'})))
		.then(prop('data'));

export const postIssue = issue =>
	httpJson('post', '/issues', {include: issueInclude}, {body: issue})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to create issue'})))
		.then(prop('data'));

export const deleteIssue = id =>
	httpJson('delete', `/issues/${id}`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to delete issue'})),
	);

export const getTeams = () =>
	httpJson('get', '/teams', {_limit: 999, include: 'users'})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load teams'})))
		.then(getResponseData(map(normalize.team)));

export const putCallPoolArea = (callPoolId, areaId, data) =>
	httpJson('put', `/callPools/${callPoolId}/areas/${areaId}`, {}, {body: data})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save area'})))
		.then(prop('data'));

export const getSalesmanVisits = (areaId, _page) =>
	httpJson('get', '/salesmanVisit', {...areaOffersQueryBase, areaId, _page})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.salesmanVisit)));

export const boostCallPoolArea = ({areaId, callPoolId}) =>
	httpJson('post', `/callPools/${callPoolId}/areas/${areaId}/boost`, {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentPostFailed})))
		.then(pipe(prop('data'), normalize.callPool));

export const getCallPoolAreas = callPoolId =>
	httpJson('get', `/callPools/${callPoolId}`, {include: callPoolAreasInclude})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load the callpool'})))
		.then(getResponseData(normalize.callPool));

export const getCallPoolProfinderLists = callPoolId =>
	httpJson('get', '/profinder', {callPoolId})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(prop('data'));

export const postArea = area =>
	httpJson('post', '/areas', {}, {body: area})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save area'})))
		.then(prop('data'));

export const deleteArea = id =>
	httpJson('delete', `/areas/${id}`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to delete area'})),
	);

export const getProducts = () =>
	httpJson('get', '/products/all', {_limit: 999, include: 'organizations'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.product)));

export const deleteCallPool = id =>
	httpJson('delete', `/callPools/${id}`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to delete callpool'})),
	);

export const createCallPool = data =>
	httpJson('post', '/callPools', {}, {body: data})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to create callpool'})))
		.then(getResponseData(normalize.callPool));

export const updateCallPool = (id, data) =>
	httpJson('put', `/callPools/${id}`, {include: 'teams,teams.users'}, {body: data})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save callpool'})))
		.then(getResponseData(normalize.callPool));

export const getBuildings = query =>
	httpJson('get', `/buildings/search`, query)
		.catch(describeThrow(intl.formatMessage({id: 'Failed to search buildings'})))
		.then(prop('data'));

export const getBuilding = buildingId =>
	httpJson('get', `/buildings/${buildingId}`, {include: buildingInclude})
		.catch(e => {
			const noPermission = !!e.response && e.response.status === 403;
			const errMsgKey = noPermission
				? 'You do not have access to the building'
				: 'The building could not be loaded. Try refreshing the page.';
			throw describeError(intl.formatMessage({id: errMsgKey}), e);
		})
		.then(pipe(prop('data'), normalize.building));

export const getEncounters = buildingId =>
	httpJson('get', `/encounters`, {
		buildingId,
		include: 'source',
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.encounter)));

export const getEncounter = encounterId =>
	httpJson('get', `/encounters/${encounterId}`, {include: encounterInclude})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(normalize.encounter));

export const getOrganizations = () =>
	httpJson('get', '/organizations', {customerOrganizations: false})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.organization)));

export const getSmartDataOptions = () =>
	httpJson('get', '/clients/smartDataOptions')
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(prop('data'));

export const fetchUsersByTeamId = teamId =>
	httpJson('get', '/users', {_limit: 999, teamId})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), map(normalize.user)));

// maps

// buildings layer

const _getBuildingsStyle =
	({zoom, filters, selectionIds, selectionType, manufacturingLimit}) =>
	imports => {
		const {openLayers: ol} = imports;

		const sty = mapStyles(ol);
		const keys = ['id', 'encounterState', 'manufacturingYear', 'encounterDate', 'banned'];

		return feature => {
			const {id, encounterState, manufacturingYear, banned, encounterDate} =
				getJsonProperties(feature, keys);

			if (filters) {
				if (
					filters.encounterState &&
					filters.encounterState !== 'any' &&
					encounterState !== filters.encounterState
				)
					return null;
				if (
					filters.minYear &&
					(manufacturingYear < filters.minYear || !manufacturingYear)
				)
					return null;
				if (
					filters.maxYear &&
					(manufacturingYear > filters.maxYear || !manufacturingYear)
				)
					return null;
			}

			const selected = selectionType === 'building' && selectionIds.includes(id);
			const building = {encounterState, encounterDate, banned, manufacturingYear};

			return new ol.style.Style({
				...concreteBuildingStyle(ol, {
					geomType: feature.getGeometry().getType(),
					zoom,
					style: sty.buildingStyleProps({building, manufacturingLimit, selected}),
				}),
				zIndex: selected ? 1 : 0,
				text:
					zoom >= buildingsYearMinZoom
						? new ol.style.Text({
								text: manufacturingYear ? `${manufacturingYear}` : '',
								overflow: true,
						  })
						: null,
			});
		};
	};
export const getBuildingsStyle = decorateMapMethod(_getBuildingsStyle);

const _getBuildingsSource =
	({apiToken, organizationId}) =>
	imports => {
		const {openLayers: ol} = imports;
		const project = projectOnlyOrganizations.includes(organizationId);

		return new ol.source.VectorTile({
			format: new ol.format.MVT({dataProjection: 'EPSG:4326'}),
			// prettier-ignore
			url: `${apiUrl}/maps/buildings/mvt/{z}/{x}/{y}${encodeQuery({organizationId, token: apiToken, project})}`,
			maxZoom: maxZoom,
		});
	};
export const getBuildingsSource = decorateMapMethod(_getBuildingsSource);

const createBuildingsLayer =
	({
		apiToken,
		organizationId,
		initialZoom,
		initialFilters,
		selectionIds,
		selectionType,
		manufacturingLimit,
		visible,
	}) =>
	imports => {
		const {openLayers: ol} = imports;

		const layer = new ol.layer.VectorTile({
			source: _getBuildingsSource({apiToken, organizationId})(imports),
			style: _getBuildingsStyle({
				zoom: initialZoom,
				filters: initialFilters,
				selectionIds,
				selectionType,
				manufacturingLimit,
			})(imports),
			visible: visible ? initialZoom >= buildingsMinZoom : false,
		});

		return layer;
	};

// areas layer

const _getAreasStyle =
	({aggregateType, selectionIds, selectionType}) =>
	imports => {
		const {openLayers: ol} = imports;

		const sty = mapStyles(ol);
		const keys = ['id', 'title', 'subtitle', 'info'];

		return feature => {
			const {id, title, subtitle, info} = getJsonProperties(feature, keys);
			const aggregate = aggregateType ? getAggregateByType(info, aggregateType) : null;
			const isSelected = selectionType === 'area' && selectionIds.includes(id);

			return new ol.style.Style({
				fill: aggregate ? new ol.style.Fill({color: aggregate.color}) : sty.areasFill,
				stroke: isSelected ? sty.activeAreaStroke : sty.areasStroke,
				zIndex: isSelected ? 1 : 0,
				text: new ol.style.Text({
					text: subtitle ? `${subtitle} ${title}` : title,
					fill: sty.areasTextFill,
					stroke: sty.areasTextStroke,
					font: sty.areasFont,
				}),
			});
		};
	};
export const getAreasStyle = decorateMapMethod(_getAreasStyle);

const _getAreasSource =
	({apiToken, areaType, organizationId}) =>
	imports => {
		const {openLayers: ol} = imports;

		return new ol.source.VectorTile({
			format: new ol.format.MVT({dataProjection: 'EPSG:4326'}),
			url: `${apiUrl}/maps/areas/mvt/{z}/{x}/{y}${encodeQuery({
				type: areaType,
				token: apiToken,
				organizationId,
			})}`,
			maxZoom: areasMaxDetailZoom,
		});
	};
export const getAreasSource = decorateMapMethod(_getAreasSource);

const createAreasLayer =
	({
		apiToken,
		organizationId,
		initialZoom,
		areaType,
		aggregateType,
		selectionIds,
		selectionType,
		visible,
	}) =>
	imports => {
		const {openLayers: ol} = imports;

		const layer = new ol.layer.VectorTile({
			source: _getAreasSource({apiToken, areaType, organizationId})(imports),
			style: _getAreasStyle({aggregateType, selectionIds, selectionType})(imports),
			visible: visible ? initialZoom < areasMaxZoom : false,
		});

		return layer;
	};

// call pools layer

const _getCallPoolsStyle =
	({activeCallPoolId, selectionIds, selectionType}) =>
	imports => {
		const {openLayers: ol} = imports;
		const sty = mapStyles(ol);
		const keys = ['id', 'callPoolId', 'organizationId'];

		return feature => {
			const {id, callPoolId, organizationId} = getJsonProperties(feature, keys);
			const isSelected = selectionType === 'area' && selectionIds.includes(id);

			return new ol.style.Style({
				fill: sty.callPoolFills[organizationId],
				zIndex: isSelected ? 1 : 0,
				stroke: isSelected
					? sty.activeAreaStroke
					: activeCallPoolId && callPoolId === activeCallPoolId
					? sty.activeCallPoolStroke
					: sty.callPoolStroke,
			});
		};
	};
export const getCallPoolsStyle = decorateMapMethod(_getCallPoolsStyle);

const _getCallPoolsSource =
	({apiToken}) =>
	imports => {
		const {openLayers: ol} = imports;

		return new ol.source.VectorTile({
			format: new ol.format.MVT({dataProjection: 'EPSG:4326'}),
			url: `${apiUrl}/maps/callPools/mvt/{z}/{x}/{y}${encodeQuery({
				token: apiToken,
			})}`,
			maxZoom: areasMaxDetailZoom,
		});
	};
export const getCallPoolsSource = decorateMapMethod(_getCallPoolsSource);

const createCallPoolsLayer =
	({apiToken, initialZoom, activeCallPoolId, selectionIds, selectionType, visible}) =>
	imports => {
		const {openLayers: ol} = imports;

		const layer = new ol.layer.VectorTile({
			source: _getCallPoolsSource({apiToken})(imports),
			style: _getCallPoolsStyle({activeCallPoolId, selectionIds, selectionType})(imports),
			visible: visible ? initialZoom < areasMaxZoom : false,
		});

		return layer;
	};

// groundwaterAreas layer

const _getGroundwaterAreasStyle = () => imports => {
	const {openLayers: ol} = imports;

	const sty = mapStyles(ol);

	return feature => {
		return new ol.style.Style({
			fill: sty.groundwaterAreasFill,
			stroke: sty.groundwaterAreasStroke,
		});
	};
};
export const getGroundwaterAreasStyle = decorateMapMethod(_getGroundwaterAreasStyle);

const _getGroundwaterAreasSource =
	({apiToken}) =>
	imports => {
		const {openLayers: ol} = imports;

		return new ol.source.VectorTile({
			format: new ol.format.MVT({dataProjection: 'EPSG:4326'}),
			url: `${apiUrl}/maps/groundwaterAreas/mvt/{z}/{x}/{y}${encodeQuery({
				token: apiToken,
			})}`,
			maxZoom: maxZoom,
		});
	};
export const getGroundwaterAreasSource = decorateMapMethod(_getGroundwaterAreasSource);

const createGroundwaterAreasLayer =
	({apiToken, initialZoom, visible}) =>
	imports => {
		const {openLayers: ol} = imports;

		const layer = new ol.layer.VectorTile({
			source: _getGroundwaterAreasSource({apiToken})(imports),
			style: _getGroundwaterAreasStyle()(imports),
			visible: visible ? initialZoom >= groundwaterAreasMinZoom : false,
		});

		return layer;
	};

// propertyLines layer

const _getPropertyLinesStyle = () => imports => {
	const {openLayers: ol} = imports;

	const sty = mapStyles(ol);

	return feature => {
		if (feature.getType() === 'LineString') {
			return new ol.style.Style({
				fill: sty.propertyLinesFill,
				stroke: sty.propertyLinesStroke,
			});
		}
	};
};
export const getPropertyLinesStyle = decorateMapMethod(_getPropertyLinesStyle);

const _getPropertyLinesSource =
	({apiToken}) =>
	imports => {
		const {openLayers: ol} = imports;

		return new ol.source.VectorTile({
			format: new ol.format.MVT({dataProjection: 'EPSG:4326'}),
			url: `${apiUrl}/maps/propertyLines/mvt/{z}/{x}/{y}${encodeQuery({
				token: apiToken,
			})}`,
			maxZoom: maxZoom,
		});
	};
export const getPropertyLinesSource = decorateMapMethod(_getPropertyLinesSource);

const createPropertyLinesLayer =
	({apiToken, initialZoom, visible}) =>
	imports => {
		const {openLayers: ol} = imports;

		const layer = new ol.layer.VectorTile({
			source: _getPropertyLinesSource({apiToken})(imports),
			style: _getPropertyLinesStyle()(imports),
			visible: visible ? initialZoom >= propertyLinesMinZoom : false,
		});

		return layer;
	};

// draw layer

const createDrawLayer = () => imports => {
	const {openLayers: ol} = imports;
	const drawSource = new ol.source.VectorSource({wrapX: false});

	const drawLayer = new ol.layer.VectorLayer({
		source: drawSource,
	});

	return {drawLayer, drawSource};
};

const _addDrawInteraction =
	({map, drawSource, type, onDrawEnd}) =>
	imports => {
		const {openLayers: ol} = imports;

		const draw = new ol.interaction.Draw({
			source: drawSource,
			type,
		});

		map.addInteraction(draw);

		const radiusTooltip = map.getOverlayById('radius-tooltip');
		const radiusTooltipEl = radiusTooltip.getElement();

		draw.on('drawstart', evt => {
			const geometry = evt.feature.getGeometry();
			if (type === drawTypes.circle) {
				radiusTooltipEl.className += ' show';
			}

			geometry.on('change', e => {
				const geom = e.target;
				if (type === drawTypes.circle) {
					// get radius of circle for tooltip
					const center = geom.getCenter();
					const radius = geom.getRadius();
					const edgeCoordinate = [center[0] + radius, center[1]];
					const groundRadius = ol.sphere.getDistance(
						transform(center, 'EPSG:3857', 'EPSG:4326'),
						transform(edgeCoordinate, 'EPSG:3857', 'EPSG:4326'),
					);
					const groundRadiusKm = groundRadius / 1000;
					radiusTooltipEl.innerHTML = `${intl.formatMessage({
						id: 'Radius',
					})}: ${groundRadiusKm.toFixed(2)} km`;
					radiusTooltip.setPosition(center);
				}
			});
		});

		draw.on('drawend', e => {
			const geometry = e.feature.getGeometry();
			const geomType = geometry.getType();
			let polygon = geometry;
			// convert circle to polygon
			if (geomType === drawTypes.circle) {
				polygon = ol.geom.fromCircle(geometry);
			}
			// write to GeoJSON
			const writer = new ol.format.GeoJSON({dataProjection: 'EPSG:4326'});
			const area = writer.writeGeometryObject(polygon);
			onDrawEnd(area);
			draw.setActive(false);
		});

		return draw;
	};
export const addDrawInteraction = decorateMapMethod(_addDrawInteraction);

// map layer

const _initMap =
	({
		apiToken,
		organizationId,
		initialZoom,
		initialCenter,
		initialFilters,
		activeCallPoolId,
		selectionType,
		selectionIds,
		onMapLocationChanged,
		onBuildingClick,
		onAreaClick,
		getLayersVisibility,
		getDrawing,
		manufacturingLimit,
		layersVisibility,
		mapSourceProps,
		getSelectionLoading,
	}) =>
	imports => {
		// keep some local state
		const currHover = {
			id: null,
			layer: null,
		};

		const {openLayers: ol} = imports;

		const mapEl = document.querySelector('#map');
		if (!mapEl) {
			return Promise.reject(new Error('Map element not found'));
		}

		const view = new ol.View({
			projection: 'EPSG:3857',
			center: initialCenter,
			zoom: initialZoom,
			maxZoom,
			constrainResolution: true,
			enableRotation: false,
		});

		const buildingsLayer = createBuildingsLayer({
			apiToken,
			organizationId,
			initialZoom,
			initialFilters,
			selectionType,
			selectionIds,
			manufacturingLimit,
			visible: layersVisibility.buildingsLayer,
		})(imports);

		const areasLayer = createAreasLayer({
			apiToken,
			organizationId,
			initialZoom,
			areaType: initialFilters.areaType,
			aggregateType: initialFilters.aggregateType,
			selectionType,
			selectionIds,
			visible: layersVisibility.areasLayer,
		})(imports);

		const callPoolsLayer = createCallPoolsLayer({
			apiToken,
			initialZoom,
			activeCallPoolId,
			selectionType,
			selectionIds,
			visible: layersVisibility.callPoolsLayer,
		})(imports);

		const groundwaterAreasLayer = createGroundwaterAreasLayer({
			apiToken,
			initialZoom,
			visible: layersVisibility.groundwaterAreasLayer,
		})(imports);

		const propertyLinesLayer = createPropertyLinesLayer({
			apiToken,
			initialZoom,
			visible: true,
		})(imports);

		const {drawLayer, drawSource} = createDrawLayer()(imports);

		const mapSource = _getMapTileSource(mapSourceProps)(imports);
		const mapLayer = new ol.layer.Tile({source: mapSource});

		const markerLayer = createMarkerLayer(imports);

		// map

		const map = new ol.Map({
			target: 'map',
			view,
			layers: [
				mapLayer,
				areasLayer,
				callPoolsLayer,
				groundwaterAreasLayer,
				propertyLinesLayer,
				buildingsLayer,
				drawLayer,
				markerLayer,
			],
		});

		map.on('moveend', e => {
			const zoom = map.getView().getZoom();
			const center = map.getView().getCenter();

			onMapLocationChanged({z: zoom, x: center[0], y: center[1]});

			// toggle buildings layer
			if (zoom >= buildingsMinZoom && getLayersVisibility().buildingsLayer) {
				buildingsLayer.setVisible(true);
			} else {
				buildingsLayer.setVisible(false);
			}

			// toggle areas layer
			if (zoom >= areasMaxZoom) {
				areasLayer.setVisible(false);
			} else if (getLayersVisibility().areasLayer) {
				areasLayer.setVisible(true);
			}

			// toggle callPools layer
			if (zoom >= areasMaxZoom) {
				callPoolsLayer.setVisible(false);
			} else if (getLayersVisibility().callPoolsLayer) {
				callPoolsLayer.setVisible(true);
			}

			// toggle groundwater areas layer
			if (
				zoom >= groundwaterAreasMinZoom &&
				getLayersVisibility().groundwaterAreasLayer
			) {
				groundwaterAreasLayer.setVisible(true);
			} else {
				groundwaterAreasLayer.setVisible(false);
			}

			// toggle propertyLines areas layer
			if (zoom >= propertyLinesMinZoom && getLayersVisibility().propertyLinesLayer) {
				propertyLinesLayer.setVisible(true);
			} else {
				propertyLinesLayer.setVisible(false);
			}
		});

		map.on('click', e => {
			if (getDrawing()) {
				return;
			}

			// building click
			const buildingFeatures = safeGetFeaturesAtPixel(map, e.pixel, {
				layerFilter: l => l === buildingsLayer,
			});
			if (buildingFeatures && !getSelectionLoading()) {
				onBuildingClick(getJsonProperty(buildingFeatures[0], 'id'));
				return;
			}

			// area click
			const areaFeatures = safeGetFeaturesAtPixel(map, e.pixel, {
				layerFilter: l => l === areasLayer,
			});
			if (areaFeatures && !getSelectionLoading()) {
				onAreaClick(getJsonProperty(areaFeatures[0], 'id'), e.originalEvent.shiftKey);
				return;
			}
		});

		map.on('pointermove', e => {
			if (getDrawing()) {
				return;
			}

			const pixel = map.getEventPixel(e.originalEvent);
			const hit = safeHasFeatureAtPixel(map, pixel, {
				layerFilter: l =>
					l === buildingsLayer || l === areasLayer || l === callPoolsLayer,
			});
			map.getViewport().style.cursor = hit ? 'pointer' : '';
		});

		// tooltips
		const mapTooltipEl = document.createElement('div');
		mapTooltipEl.id = 'map-tooltip';
		mapTooltipEl.style.cssText = mapTooltipStyles;

		const mapTooltip = new ol.Overlay({
			element: mapTooltipEl,
			positioning: 'bottom-center',
			offset: [0, -5],
		});

		const updateMapTooltip = (feature, layer) => {
			const id = getJsonProperty(feature, 'id');

			if (id === currHover.id && layer === currHover.layer) return;

			currHover.id = id;
			currHover.layer = layer;
			deleteChildren(mapTooltipEl);

			switch (layer) {
				case buildingsLayer: {
					const {
						address,
						manufacturingYear,
						encounterState,
						encounterDate,
						clientId,
						clientFirstName,
						clientLastName,
					} = getJsonProperties(feature, [
						'address',
						'manufacturingYear',
						'encounterState',
						'encounterDate',
						'clientId',
						'clientFirstName',
						'clientLastName',
					]);

					renderBuildingTooltip({
						tooltipEl: mapTooltipEl,
						address,
						manufacturingYear,
						encounterState,
						encounterDate,
						clientId,
						clientFirstName,
						clientLastName,
					});
					break;
				}
				case callPoolsLayer: {
					const {title, subtitle, callPoolTitle, organizationTitle} = getJsonProperties(
						feature,
						['title', 'subtitle', 'callPoolTitle', 'organizationTitle'],
					);

					renderCallPoolsTooltip({
						tooltipEl: mapTooltipEl,
						title,
						subtitle,
						callPoolTitle,
						organizationTitle,
					});
					break;
				}
				case areasLayer: {
					const {title, subtitle, info} = getJsonProperties(feature, [
						'title',
						'subtitle',
						'info',
					]);
					renderAreaTooltip({tooltipEl: mapTooltipEl, title, subtitle, info});
					break;
				}
				default:
					break;
			}
		};

		addTooltipToLayers({
			mapEl,
			map,
			layers: [buildingsLayer, callPoolsLayer, areasLayer],
			tooltipEl: mapTooltipEl,
			tooltip: mapTooltip,
			updateTooltip: updateMapTooltip,
			getDrawing: () => getDrawing(),
		});

		const radiusTooltipEl = document.createElement('div');
		radiusTooltipEl.id = 'radius-tooltip';
		radiusTooltipEl.style.cssText = mapTooltipStyles;

		const radiusTooltip = new ol.Overlay({
			id: 'radius-tooltip',
			element: radiusTooltipEl,
			positioning: 'center-center',
		});
		map.addOverlay(radiusTooltip);

		return Promise.resolve({
			map,
			buildingsLayer,
			areasLayer,
			callPoolsLayer,
			drawSource,
			mapLayer,
			markerLayer,
			groundwaterAreasLayer,
			propertyLinesLayer,
		});
	};
export const initMap = decorateMapMethod(_initMap);

// note: don't add non-map stuff here at the end
