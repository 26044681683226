import {action} from 'utils/redux';

export default ({namespace}) => {
	const creator = action(namespace);

	return {
		createOtherAddress: creator('createOtherAddress'),
		removeOtherAddress: creator('removeOtherAddress'),
		saveBuildingData: creator('saveBuildingData'),
		removeBuilding: creator('removeBuilding'),
		toggleBuildingModal: creator('toggleBuildingModal'),
		_updateBuilding: creator('_updateBuilding'),
		_setBuildingModalProcessing: creator('_setBuildingModalProcessing'),
	};
};
