import {action} from 'utils/redux';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const updateUser = creator('updateUser');

export const _userUpdated = creator('userUpdated');

export const _initialize = creator('_initialize');
