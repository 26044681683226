import services from 'services';
import {describeThrow} from 'utils/errors';
import {map} from 'ramda';
import {getResponseData} from 'utils/app';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {callReminderInclude} from './constants';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getCallReminders = query =>
	httpJson('get', '/callReminders', {include: callReminderInclude, ...query})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.callReminder)));

export const postCallReminder = callReminder =>
	httpJson('post', '/callReminders', {include: callReminderInclude}, {body: callReminder})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save reminder'})))
		.then(getResponseData(normalize.callReminder));

export const putCallReminder = (callReminder, id) =>
	httpJson(
		'put',
		`/callReminders/${id}`,
		{include: callReminderInclude},
		{body: callReminder},
	)
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save reminder'})))
		.then(getResponseData(normalize.callReminder));

export const deleteCallReminder = id =>
	httpJson('delete', `/callReminders/${id}`).catch(
		describeThrow(intl.formatMessage({id: msgs.contentPostFailed})),
	);
