import {action} from 'utils/redux';
import {P, Record, Nullable} from 'utils/types';
import namespace from './namespace';
import createDatePickActions from 'fragments/calendarResourcePicker/actions';
import createCallReminderActions from 'fragments/callReminder/actions';

const creator = action(namespace);

export const initialize = creator('initialize', P.Number);

export const destroy = creator('destroy');

export const toggleBuildingPreviewModal = creator('toggleBuildingPreviewModal');

export const openEncounterPreviewModal = creator('openEncounterPreviewModal');

export const closeEncounterPreviewModal = creator('closeEncounterPreviewModal');

export const toggleCurEncounterPreviewModal = creator('toggleCurEncounterPreviewModal');

export const toggleCalendarResourceDateEdit = creator('toggleCalendarResourceDateEdit');

export const saveCalendarResourceDateEdit = creator('saveCalendarResourceDateEdit');

export const saveSalesmanVisit = creator('saveSalesmanVisit');

export const cancelVisit = creator('cancelVisit');

export const restoreVisit = creator('restoreVisit');

export const toggleClientEditor = creator('toggleClientEditor', Nullable(P.Object));

export const toggleClientChanger = creator('toggleClientChanger');

export const changeClient = creator('changeClient', P.Number);

export const saveClient = creator('saveClient');

export const setActiveTab = creator('setActiveTab', P.String);

export const reinitialize = creator('reinitialize', P.Number);

// comments, TEMP
export const saveComment = creator('saveComment');
export const removeComment = creator('removeComment', P.Number);
export const openCommentEditor = creator('openCommentEditor', P.Number);
export const _commentSaved = creator('_commentSaved');
export const _commentRemoved = creator('_commentRemoved');
// ...

export const _startOp = creator('_startOp');

export const _opOk = creator('_opOk');

export const _opFailed = creator('_opFailed');

export const _setInitData = creator(
	'_setInitData',
	Record({
		calendarResource: P.Object,
		building: P.Object,
		salesmanVisit: Nullable(P.Object),
	}),
);

export const _setFreeCalendarResources = creator('_setFreeCalendarResources', P.Array);

export const _setOrganizationSalesmen = creator('_setOrganizationSalesmen', P.Array);

export const _setEncounters = creator('_setEncounters', P.Array);

export const _openEncounterPreviewModal = creator('_openEncounterPreviewModal', P.Object);

export const _calendarResourceUpdated = creator('_calendarResourceDateUpdated', P.Object);

export const _salesmanVisitSaved = creator('_salesmanVisitSaved', P.Object);

export const _clientUpdated = creator('_clientUpdated', P.Object);

const datePickActions = createDatePickActions({namespace});

export const {
	selectCalendarResource,
	_calendarResourceSelected,
	_calendarResourceSelectionFailed,
	_calendarResourceReserved,
	_calendarResourceReleased,
} = datePickActions;

export const callLog = creator('callLog', P.Object);

const callReminderActions = createCallReminderActions({namespace});

export const {
	createCallReminder,
	createCalendarCallReminder,
	updateCallReminder,
	removeCallReminder,
	toggleCallReminderEditor,
	setReminderSeen,
	_setCalendarCallReminderSeen,
	_setCallReminder,
	_setCallReminderLoading,
	_setCallReminders,
	_setCallRemindersLoading,
	_setCalendarCallReminder,
} = callReminderActions;

export const _setSalesmanVisits = creator('_setSalesmanVisits', P.Array);

export const _setAvailableTags = creator('_setAvailableTags', P.Array);

export const getTags = creator('getTags', P.Number);

export const _getTags = creator('_getTags', P.Array);

export const attachTag = creator('attachTag', P.Object);

export const _attachTag = creator('_attachTag');

export const detachTag = creator('detachTag', P.Object);

export const _detachTag = creator('_detachTag');
