// CRA doesn't allow control over NODE_ENV, so use a custom variable instead - we really need to be able to discern between production environment and internal development hosting
export const nodeEnv = process.env.REACT_APP_NODE_ENV;
// only act as prod when env is explicitly set as "production". this is because a prod run does some external side actions (namely logging) that shouldn't be allowed to accidentally happen if a developer forgets to set env to development. just be sure to define the env correctly for production.
export const inProd = nodeEnv === 'production';
// so far we only have prod and dev. note that any development environment (even local) is considered to be dev, use the "deployment" variable to check for the development server specifically.
export const inDev = !inProd;

// CRA sets NODE_ENV depending on build type, so use it to automatically figure out deployment type. this can be useful for telling local dev server and public dev server apart, as both are considered dev.
// prettier-ignore
export const deployment = process.env.NODE_ENV === 'development' ? 'local'
	: inDev ? 'development'
	: 'production';
export const isLocalDeployment = deployment === 'local';
export const isDevDeployment = deployment === 'development';

// note: don't import assertion helpers from utils. causes trouble with circular dependencies.

const getOrFail = name => {
	const val = process.env[name];
	if (!val) {
		throw new Error(`Required environment variable "${name}" not set`);
	}
	return val;
};

const getOrWarn = inDev
	? name => {
			const val = process.env[name];
			if (!val) {
				console.warn(`Environment variable "${name}" not set`);
			}
			return val;
	  }
	: name => process.env[name];

export const appVersion = getOrWarn('REACT_APP_VERSION');

export const host = getOrFail('REACT_APP_HOST');
export const baseUrl = `https://${host}`;
export const locale = getOrFail('REACT_APP_LOCALE');
export const apiUrl = getOrFail('REACT_APP_API_URL');

export const defaultColors = {
	bg: '#FFFFFF',
	bgHover: '#E3E3E3',
	text: '#232323',
	textHover: '#232323',
	border: '#C7C7C7',
};
