import {map, pipe, prop} from 'ramda';
import services from 'services';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {getResponseData, mapResponseData} from 'utils/app';
import {marketingLeadInclude} from './constants';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getLeads = query =>
	httpJson('get', `/leads`, {
		...query,
		include: marketingLeadInclude,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.lead)));

export const getProducts = () =>
	httpJson('get', '/products/all', {
		_limit: 999,
		include: 'organizations',
	}).catch(describeThrow(intl.formatMessage({id: 'Product search failed'})));

export const getOrganizations = () =>
	httpJson('get', '/organizations', {_limit: 999, customerOrganizations: false}).catch(
		describeThrow(intl.formatMessage({id: 'Organization search failed'})),
	);

export const getSalesTeams = () =>
	httpJson('get', '/teams', {
		_limit: 999,
		type: 'Sales',
		getAllTeams: true,
		customerOrganizationTeams: false,
		include: 'users',
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.team)));

export const getAreas = () =>
	httpJson('get', '/areas', {
		type: 'county,city',
		select: 'id,type,title',
		withoutTransform: true,
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getTags = () =>
	httpJson('get', '/tags', {type: 'marketingLead'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.tag)));
