import React from 'react';
import View from 'components/generic/View';
import DefaultNavbar from 'components/views/DefaultNavbar';
import NotFoundPage from 'components/generic/NotFoundPage';

class NotFound extends React.Component {
	render() {
		return (
			<View>
				<DefaultNavbar />
				<NotFoundPage />
			</View>
		);
	}
}

export default NotFound;
