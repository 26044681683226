import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {createSelector} from 'reselect';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const requestsQuery = pipe(root, prop('requestsQuery'));

export const urlQuery = createSelector([requestsQuery], requestsQuery => requestsQuery);

export const requestsQueryFetchable = createSelector(
	[requestsQuery],
	requestsQuery => requestsQuery,
);

export const requests = pipe(root, prop('requests'));

export const requestsPagination = pipe(root, prop('requestsPagination'));

export const requestsLoading = pipe(root, prop('requestsLoading'));

export const requestCreatorOpen = pipe(root, prop('requestCreatorOpen'));

export const clients = pipe(root, prop('clients'));

export const clientsLoading = pipe(root, prop('clientsLoading'));

export const processing = pipe(root, prop('processing'));
