// core dependencies
import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

const ScrollWrapper = styled.div`
	position: relative;
`;

const TableWrapper = styled.div`
	white-space: ${({nowrap}) => (nowrap ? 'nowrap' : 'normal')};
	overflow: auto;
`;

const BlurLeft = styled.span`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 70px;
	pointer-events: none;
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 1),
		rgba(255, 255, 255, 0.55) 50%,
		rgba(255, 255, 255, 0) 90%
	);
`;

const BlurRight = styled.span`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: 70px;
	pointer-events: none;

	background: linear-gradient(
		to left,
		rgba(255, 255, 255, 1),
		rgba(255, 255, 255, 0.55) 50%,
		rgba(255, 255, 255, 0) 90%
	);
`;

class HorizontalScroll extends React.Component {
	state = {
		scrollable: false,
		leftBlur: false,
		rightBlur: false,
	};

	scrollableRef = React.createRef();
	scrollableParentRef = React.createRef();

	handleResize = () => {
		const diff =
			this.scrollableRef.current.scrollWidth -
			this.scrollableParentRef.current.scrollWidth;

		if (
			this.scrollableRef.current.scrollWidth >
			this.scrollableParentRef.current.scrollWidth
		) {
			this.setState({scrollable: true});

			if (this.scrollableRef.current.scrollLeft !== diff) {
				this.setState({rightBlur: true});
			}
		} else this.setState({scrollable: false});
	};

	handleScroll = e => {
		const diff = e.target.scrollWidth - this.scrollableParentRef.current.scrollWidth;

		// diff - 1 due android devices not hiding the rightblur correctly
		if (e.target.scrollLeft >= diff - 1) {
			this.setState({rightBlur: false});
		} else this.setState({rightBlur: true});

		if (e.target.scrollLeft === 0) {
			this.setState({leftBlur: false});
		} else this.setState({leftBlur: true});
	};

	componentDidMount() {
		if (!this.mobileIosProps) {
			if (
				this.scrollableRef.current.scrollWidth >
				this.scrollableParentRef.current.scrollWidth
			) {
				this.setState({scrollable: true, rightBlur: true});
			}
			window.addEventListener('resize', this.handleResize);
			this.scrollableRef.current.addEventListener('scroll', this.handleScroll);
		}
	}

	componentWillUnmount() {
		if (!this.mobileIosProps) {
			this.scrollableRef.current.removeEventListener('scroll', this.handleScroll);
			window.removeEventListener('resize', this.handleResize);
		}
	}

	render() {
		const {scrollable, leftBlur, rightBlur} = this.state;
		return (
			<ScrollWrapper ref={this.scrollableParentRef}>
				{scrollable && leftBlur && <BlurLeft />}
				<TableWrapper ref={this.scrollableRef} nowrap={this.props.nowrap}>
					{this.props.children}
				</TableWrapper>
				{scrollable && rightBlur && <BlurRight />}
			</ScrollWrapper>
		);
	}
}

// since we are implementing horizontal scroll, it seems reasonable to provide optional nowrap bool to the component
// to prevent table text from wrapping, resulting in ugly tables (for example date wrapping on 3 lines)
HorizontalScroll.propTypes = {
	children: propTypes.node,
	nowrap: propTypes.bool,
};

export default HorizontalScroll;
