import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	initialized: false,
	areaCounties: [],
	currentLane: 'callReminders',
	progressData: {},
	condosMeta: {},
	condosQuery: {
		address: '',
		zip: '',
		city: '',
		county: '',
		companyName: '',
		manufacturingYearStart: '',
		manufacturingYearEnd: '',
		latestActivityUserId: '',
		_sort: 'contactAt',
	},
	processing: false,
	loadingCondominiums: false,
	users: [],
};
