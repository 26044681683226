import {
	mergeWith,
	reduce,
	groupBy,
	prop,
	assocPath,
	over,
	pipe,
	lensProp,
	add,
	assoc,
	map,
} from 'ramda';
import {statsTimeSpans, zeroStats} from './constants';

const capitalize = s => {
	return s.charAt(0).toUpperCase() + s.slice(1);
};

export const assocStats = (stats, teams) => ({
	teams: reduce(
		(teamsAcc, timeSpan) => {
			const teamStats = groupBy(prop('teamId'), stats[timeSpan]);
			return map(team => {
				const userStats = groupBy(prop('userId'), teamStats[team.id] || []);
				const statsOfUsers = reduce(
					(acc, u) =>
						assoc(u.id, userStats[u.id] ? userStats[u.id][0].data : zeroStats, acc),
					{},
					team.users,
				);
				const statsOfTeam = reduce(
					(acc, u) => mergeWith(add, acc, statsOfUsers[u.id]),
					zeroStats,
					team.users,
				);

				return pipe(
					over(
						lensProp('users'),
						map(u => assocPath(['stats', timeSpan], statsOfUsers[u.id], u)),
					),
					assocPath(['stats', timeSpan], statsOfTeam),
				)(team);
			}, teamsAcc);
		},
		teams,
		statsTimeSpans,
	),
	totalStats: reduce(
		(statsAcc, timeSpan) =>
			assoc(timeSpan, stats[`organizationTotal${capitalize(timeSpan)}`], statsAcc),
		{},
		statsTimeSpans,
	),
});
