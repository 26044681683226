import {evolve} from 'ramda';
import {isSalesmanagerUser} from 'utils/perms';
import {replace} from 'utils/objects';
import {salesPriceTargets, offerCountTargets} from './constants';
import initState from './state';

export const isValidDate = date => {
	const dateFormat = /([12]\d{3}-(0[1-9]|1[0-2]))/;
	return dateFormat.test(date);
};

// PERM: salesman-app-organization-stats
const adminRoles = new Set(['admin', 'aluemyyntijohtaja', 'myyntijohtaja']);

export const getStatsType = user => {
	const showOrganizationStats = user.roles.find(r => adminRoles.has(r.slug));
	const showTeamStats = isSalesmanagerUser(user);
	return showOrganizationStats ? 'organization' : showTeamStats ? 'team' : 'user';
};

export const parseUrlQuery = (query, user, isSalesmanager, isAdmin) => {
	const vals = evolve(
		{
			selectedDate: s => (s && isValidDate(s) ? s : initState.statsQuery.selectedDate),
		},
		query,
	);

	// use defaultStatsType if no statsType in query
	// don't allow non salesmanager/admin user to set statsType to 'team' manually via url query
	// don't allow non admin user to set statsType to 'organization' manually via url query
	const defaultStatsType = getStatsType(user);
	if (!vals.statsType) {
		vals.statsType = defaultStatsType;
	} else if (vals.statsType === 'team' && (!isSalesmanager || !isAdmin)) {
		vals.statsType = defaultStatsType;
	} else if (vals.statsType === 'organization' && !isAdmin) {
		vals.statsType = defaultStatsType;
	}

	return {statsQuery: replace(vals, initState.statsQuery)};
};

export const getQueries = ({user, orgId, userTeam, statsType, date}) => {
	const teamId = userTeam ? userTeam.id : null;
	const month = new Date(date).getMonth() + 1;
	const year = new Date(date).getFullYear();

	const targetsQueries = [];
	let statsQuery = {};

	if (statsType === 'organization') {
		targetsQueries.push({
			...salesPriceTargets,
			organizationId: orgId,
			month,
			year,
		});
		targetsQueries.push({
			...offerCountTargets,
			organizationId: orgId,
			month,
			year,
		});
		statsQuery = {organizationId: orgId, month, year, teamId};
	} else if (statsType === 'team') {
		targetsQueries.push({...salesPriceTargets, teamId, month, year});
		targetsQueries.push({...offerCountTargets, teamId, month, year});
		statsQuery = {teamId, month, year};
	} else {
		targetsQueries.push({...salesPriceTargets, userId: user.id, month, year});
		targetsQueries.push({...offerCountTargets, userId: user.id, month, year});
		statsQuery = {userId: user.id, month, year};
	}

	return {targetsQueries, statsQuery};
};

export const formatStatistics = (statistics, type) => {
	const weeklyStats =
		type === 'organization' ? statistics.organizationWeek : statistics.weeks;
	const allStats =
		type === 'organization' ? statistics.organizationMonth : statistics.month;

	const weeklySales = Object.values(weeklyStats).map(s => s.dealPrices);
	const weeklyOffers = Object.values(weeklyStats).map(s => s.offers);
	const weekNumbers = Object.keys(weeklyStats).map(s => s);

	const formattedStats = {weeklyOffers, weeklySales, weekNumbers, allStats};

	return formattedStats;
};

export const getWeeklyTargets = (target = {}, stats) => {
	// get weeks from statistics

	const weeks = Object.keys(stats.weekNumbers).length;

	const startDay = new Date(target.startDate).getDay();
	const endDay = new Date(target.endDate).getDay();
	// Get weekdays for the first week, if first day of the month is sunday or saturday first week doesnt have any business days
	// It's fine if first week is only a weekend, because sales can happen on those days even if the weekly target is zero
	const firstWeekBusinessDays = startDay === 0 || startDay === 6 ? 0 : 6 - startDay;
	// Get business days for the last week, if last day of the month is sunday or saturday its a full 5 day week
	const lastWeekBusinessDays = endDay === 0 || endDay === 6 ? 5 : endDay;

	// Get all businessdays of the month
	const monthlyBusinessDays =
		(weeks - 2) * 5 + firstWeekBusinessDays + lastWeekBusinessDays;

	const dailyTarget = target.target / monthlyBusinessDays;

	const weeklyTargets = new Array(weeks);

	// Fill the array with values, if i = 0 its the first week which might be shorter
	// and if the "i = weeks - 1" its the last week of the month which might be shorter
	// other weeks are always full weeks
	for (let i = 0; i < weeks; i++) {
		if (i === 0) {
			weeklyTargets[i] = dailyTarget * firstWeekBusinessDays;
		} else if (i === weeks - 1) {
			weeklyTargets[i] = dailyTarget * lastWeekBusinessDays;
		} else weeklyTargets[i] = dailyTarget * 5;
	}

	return weeklyTargets;
};
