import {path, pipe, prop} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {formatClientFormInput} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const processing = pipe(root, prop('processing'));

export const client = pipe(root, prop('client'));

export const clientLoading = pipe(root, prop('clientLoading'));

export const calendarResources = pipe(root, prop('calendarResources'));

export const calendarResourcesLoading = pipe(root, prop('calendarResourcesLoading'));

export const encounters = pipe(root, prop('encounters'));

export const encountersLoading = pipe(root, prop('encountersLoading'));

export const previewableEncounter = pipe(root, prop('previewableEncounter'));

export const clientEditorOpen = pipe(root, prop('clientEditorOpen'));

export const clientFormInitValues = createSelector([client], client =>
	formatClientFormInput(client),
);
