import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {createSelector} from 'reselect';
import * as rootSelectors from 'modules/common/selectors';
import {appName as salesmanAppName} from 'modules/salesmanApp/constants';
import * as perms from 'utils/perms';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const notices = pipe(root, prop('notices'));

export const noticesPagination = pipe(root, prop('noticesPagination'));

export const noticesLoading = pipe(root, prop('noticesLoading'));

export const trackerStatistics = pipe(root, prop('trackerStatistics'));

export const teamStats = pipe(root, prop('teamStats'));

export const organizationStats = pipe(root, prop('organizationStats'));

export const showTracker = pipe(root, prop('showTracker'));

export const userTeam = pipe(root, prop('userTeam'));

export const userTargets = pipe(root, prop('userTargets'));

export const loading = pipe(root, prop('loading'));

// PERM: salesman-app-organization-stats
export const isSalesmanAppAdmin = createSelector([rootSelectors.user], user =>
	perms.isSalesmanAppAdmin(user),
);

export const trackerLoading = pipe(root, prop('trackerLoading'));

// targets are shown in launcher for users who have access to salesmanApp
// PERM: view.salesman-app
export const showTargets = createSelector([rootSelectors.user], user =>
	user.permissions.find(p => p.slug === `access.${salesmanAppName}`),
);

export const targets = pipe(root, prop('targets'));

export const targetsLoading = pipe(root, prop('targetsLoading'));

export const stats = pipe(root, prop('stats'));

export const statsLoading = pipe(root, prop('statsLoading'));
