import React, {useState} from 'react';
import {FormattedMessage} from '@meiko/react-intl';
import Icon from 'components/generic/Icon';
import RouterLink from 'components/generic/RouterLink';
import Link from 'components/generic/Link';
import HelpButton from 'components/generic/HelpButton';
import P from 'components/generic/P';
import styled from 'styled-components';
import {
	borderColorBase,
	linkColor,
	screenLgMin,
	screenMdMax,
	transitionDefault,
	fontWeightMedium,
	fontSizeXS,
} from 'styles/constants';
import {linkUnstyled, listUnstyled} from 'styles/fragments';
import Popover from 'components/generic/Popover';

// used only by Navbar
/* eslint-disable react/prop-types */

const Wrapper = styled.ul`
	${listUnstyled};
	/* NOTE: adjust bottom padding so that it always matches the upper padding of the main menu link*/
	@media (min-width: ${screenLgMin}) {
		padding-bottom: ${({lastItemOpen}) => (lastItemOpen ? '10px' : '5px')};
	}
`;

const Item = styled.li``;

const AppRow = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	${({condensed}) => (condensed ? `` : 'margin-left: 16px')}
`;

// use this instead of styling react-router Link, because Link doesn't work well with styled-components (style props get passed into the DOM component). https://github.com/styled-components/styled-components/issues/1814
const AppLinkWrapper = styled.span`
	flex-grow: 1;
	// NOTE: subtracted space is equal to chevron width, to keep link width constant regardless of whether the row has a chevron
	//max-width: calc(100% - 44px);

	${({condensed}) =>
		condensed
			? ` > a span {margin: 0 auto; display: flex; flex-direction: column; } `
			: ``};
	> * {
		${linkUnstyled};

		display: inline-flex;
		padding: 10px 0px;
		width: 100%;

		@media (max-width: ${screenMdMax}) {
			padding: 10px 0px;
		}

		${/*prettier-ignore*/ ({isActive}) => isActive ? `
			color: ${linkColor};
		` : ''}

		&:hover {
			text-decoration: none;
			color: ${linkColor};
		}
	}
`;

const AppItemNameWrapper = styled.span`
	display: inline-flex;
	align-items: center;
`;

const AppName = styled.span`
	font-weight: ${fontWeightMedium};
	margin-left: 10px;
`;
const AppNameCondensed = styled.span`
	margin-left: 10px;
	font-size: ${fontSizeXS};
	> span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block !important;
		width: 45px;
		text-align: center;
		padding-top: 5px;
	}
`;

const HelpContainer = styled.span`
	position: absolute;
	right: 44px;
`;

const Chevron = styled.span`
	padding: 0 16px;
	// NOTE: height is equal to app icon height + app link padding
	line-height: 44px;
	@media (max-width: ${screenMdMax}) {
		line-height: 54px;
	}

	&:focus,
	&:hover {
		color: ${linkColor};
	}
`;

const Pages = styled.ul`
	${listUnstyled};
	${transitionDefault};
	${
		/*prettier-ignore*/ ({isOpen}) => isOpen ? `
		transform: translateY(0);
		opacity: 1;
	` : `
		transform: translateY(-100%);
		opacity: 0;
	`
	}

	// NOTE: equal to the accumulated left spacing of the corresponding AppName
	padding-left: 50px;
	// NOTE: equal to chevron width
	padding-right: 44px;
`;

const PagesContainer = styled.div`
	overflow: hidden;
	${transitionDefault};
	${({pagesLength}) =>
		pagesLength ? `max-height: ${pagesLength * 30}px;` : 'max-height: 0px;'}

	@media (max-width: ${screenMdMax}) {
		${({pagesLength}) =>
			pagesLength ? `max-height: ${pagesLength * 50}px;` : 'max-height: 0px;'}
	}
`;

const PageRow = styled.li``;

const PageLinkWrapper = styled.span`
	display: inline-block;
	width: 100%;

	> * {
		${linkUnstyled};

		display: inline-block;
		padding: 5px 0px;
		width: 100%;

		@media (max-width: ${screenMdMax}) {
			padding: 15px 0px;
		}

		${/*prettier-ignore*/ ({isActive}) => isActive ? `
			color: ${linkColor};
		` : ''}

		&:hover {
			text-decoration: none;
			color: ${linkColor};
		}
	}
`;

const MainMenuItem = styled(Item)`
	// NOTE: assuming users always have at least one app they can use, so always show divider
	border-bottom: 1px solid ${borderColorBase};
`;

const MainMenuLinkWrapper = styled(AppLinkWrapper)`
	> * {
		padding: 15px 0;
	}
`;

const EmptyRow = ({condensed}) => (
	<Item>
		<AppRow>
			<AppLinkWrapper isActive={false} condensed={condensed}>
				{condensed ? <AppNameCondensed></AppNameCondensed> : <AppName></AppName>}
			</AppLinkWrapper>
		</AppRow>
	</Item>
);

const AppItemName = ({app, condensed}) => {
	if (condensed) {
		return (
			<Popover
				placement="right"
				triggers={['hover']}
				overlayContent={
					<AppName>
						<FormattedMessage id={app.name} />
					</AppName>
				}
				usePortal={true}
			>
				<Icon name={app.iconName} width="24px" height="24px" />
				<AppNameCondensed>
					<FormattedMessage id={app.name} />
				</AppNameCondensed>
			</Popover>
		);
	}

	return (
		<AppItemNameWrapper>
			<Icon name={app.iconName} width="24px" height="24px" />
			<AppName>
				<FormattedMessage id={app.name} />
			</AppName>
		</AppItemNameWrapper>
	);
};

const AppItem = ({app, isActive, isOpen, toggle, onSelect, condensed = false}) => {
	return (
		<Item>
			<AppRow condensed={condensed}>
				<AppLinkWrapper isActive={isActive} condensed={condensed}>
					{app.to ? (
						<RouterLink
							to={app.to}
							onClick={onSelect}
							data-ti={`navbar-left-menu-${app.id}`}
						>
							<AppItemName app={app} condensed={condensed} />
						</RouterLink>
					) : (
						<Link
							href={app.href}
							onClick={onSelect}
							data-ti={`navbar-left-menu-${app.id}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<AppItemName app={app} condensed={condensed} />
						</Link>
					)}
				</AppLinkWrapper>
				{app.help && (
					<HelpContainer>
						<HelpButton light>
							<P>
								<FormattedMessage id={app.help} />
							</P>
						</HelpButton>
					</HelpContainer>
				)}
				{app.pages.length > 0 && !condensed && (
					<Chevron
						onClick={toggle}
						data-ti={`navbar-left-menu-toggle-${app.id}`}
						data-open={isOpen ? 'true' : 'false'}
					>
						<Icon
							name={isOpen ? 'chevronUp' : 'chevronDown'}
							width="12px"
							height="12px"
						/>
					</Chevron>
				)}
			</AppRow>
			{app.pages.length > 0 ? (
				<PagesContainer pagesLength={isOpen ? app.pages.length : 0}>
					<Pages isOpen={isOpen}>
						{app.pages.map((page, i) => (
							<PageRow key={page.to}>
								<PageLinkWrapper isActive={isActive && i === app.iActivePage}>
									<RouterLink
										to={page.to}
										onClick={onSelect}
										data-ti={`navbar-left-menu-${page.to}`}
									>
										<FormattedMessage id={page.name} />
									</RouterLink>
								</PageLinkWrapper>
							</PageRow>
						))}
					</Pages>
				</PagesContainer>
			) : null}
		</Item>
	);
};

const NavbarLeftMenu = ({
	pageDefs,
	activeApp,
	onItemSelect,
	user,
	showMainMenu = true,
	condensed = false,
}) => {
	const [activeAppId, setActiveAppId] = useState(activeApp ? activeApp.id : null);

	const lastItemOpen =
		pageDefs.length && activeAppId === pageDefs[pageDefs.length - 1].id;

	return (
		<Wrapper
			lastItemOpen={lastItemOpen}
			data-ti="navbar-left-menu"
			style={{backgroundColor: 'white'}}
		>
			{showMainMenu && (
				<MainMenuItem>
					<AppRow>
						<MainMenuLinkWrapper>
							<RouterLink to="/" onClick={onItemSelect}>
								<AppItemNameWrapper>
									<Icon name="dashboardApp" width="24px" height="24px" />
									<AppName>
										<FormattedMessage id="Main menu" />
									</AppName>
								</AppItemNameWrapper>
							</RouterLink>
						</MainMenuLinkWrapper>
					</AppRow>
				</MainMenuItem>
			)}
			{activeApp && (
				<AppItem
					condensed={condensed}
					app={activeApp}
					isActive={true}
					isOpen={activeAppId === activeApp.id}
					toggle={() =>
						setActiveAppId(activeAppId === activeApp.id ? null : activeApp.id)
					}
					onSelect={onItemSelect}
				/>
			)}
			{pageDefs.map(app => (
				<AppItem
					condensed={condensed}
					key={app.id}
					app={app}
					isActive={false}
					isOpen={app.id === activeAppId}
					toggle={() => setActiveAppId(activeAppId === app.id ? null : app.id)}
					onSelect={onItemSelect}
				/>
			))}
			{/*
			Create space between the last item and the bottom of the
			menu. This is done to ensure that the last item is always visible when the menu is
			scrolled to the bottom (some mobile browsers hide the last item behind the bottom
			bar when the menu is scrolled to the bottom)
			 */}
			<EmptyRow condensed={condensed} />
		</Wrapper>
	);
};

export default NavbarLeftMenu;
