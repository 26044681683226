import styled from 'styled-components';
import {
	buttonUnstyled,
	link,
	buttonSizes,
	centerVerticallyInline,
} from 'styles/fragments';

export default styled.button.attrs(({type}) => ({
	type: type || 'button',
}))`
	${buttonUnstyled};
	${link};
	${({size}) => (size ? buttonSizes[size] : '')};
	${centerVerticallyInline};
	cursor: pointer;
`;
