import {decodeQuery, encodeQuery} from 'utils/url';
import services from 'services';

const history = services.get('history');

const editQuery = (mapper, location) => {
	const {pathname, search, hash} = location;
	const query = decodeQuery(search);
	const newSearch = encodeQuery(mapper(query));
	return `${pathname}${newSearch}${hash}`;
};

export const pushQuery = mapper => {
	history.push(editQuery(mapper, window.location));
};

export const replaceQuery = mapper => {
	history.replace(editQuery(mapper, window.location));
};

export const getQuery = () => decodeQuery(window.location.search);

export const pushPath = (newPath, mapper) => {
	history.push(
		editQuery(mapper, {
			...window.location,
			pathname: newPath,
		}),
	);
};
export const replacePath = (newPath, mapper) => {
	history.replace(
		editQuery(mapper, {
			...window.location,
			pathname: newPath,
		}),
	);
};
