import React from 'react';

const SvgCalendar = props => (
	<svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M2.56 30.721a1.922 1.922 0 0 1-1.92-1.92V5.761c0-1.058.861-1.92 1.92-1.92h1.92v-1.92a.64.64 0 0 1 1.28 0v1.92H9.6v-1.92a.64.64 0 0 1 1.28 0v1.92h3.84v-1.92a.64.64 0 0 1 1.28 0v1.92h3.84v-1.92a.64.64 0 0 1 1.28 0v1.92h3.84v-1.92a.64.64 0 0 1 1.28 0v1.92h1.92c1.059 0 1.92.862 1.92 1.92v23.04c0 1.059-.861 1.92-1.92 1.92H2.56zm0-25.6a.64.64 0 0 0-.64.64v23.04c0 .354.287.64.64.64h25.6a.64.64 0 0 0 .64-.64V5.761a.64.64 0 0 0-.64-.64h-1.92v.64a.64.64 0 0 1-1.28 0v-.64h-3.84v.64a.64.64 0 0 1-1.28 0v-.64H16v.64a.64.64 0 0 1-1.28 0v-.64h-3.84v.64a.64.64 0 0 1-1.28 0v-.64H5.76v.64a.64.64 0 0 1-1.28 0v-.64H2.56zm7.68 21.76l-.027-.001H6.4a1.922 1.922 0 0 1-1.92-1.92v-7.68c0-1.059.861-1.92 1.92-1.92h3.84l.027.001h4.453V12.16c0-1.059.861-1.92 1.92-1.92h7.68c1.059 0 1.92.861 1.92 1.92v12.8c0 1.059-.861 1.92-1.92 1.92h-3.813l-.027.001H10.24zm9.6-1.28v-3.84H16v3.84h3.84zm-5.12 0v-3.84h-3.84v3.84h3.84zm9.6-.001a.64.64 0 0 0 .64-.64v-3.199h-3.84V25.6h3.2zm-18.56-.64c0 .353.287.64.64.64h3.2v-3.84H5.76v3.2zm14.08-4.479v-3.84H16v3.84h3.84zm-5.12 0v-3.84h-3.84v3.84h3.84zm10.24-.001v-3.839h-3.84v3.839h3.84zm-15.36 0v-3.84H6.4a.64.64 0 0 0-.64.64v3.2H9.6zm15.36-5.12v-3.2a.64.64 0 0 0-.64-.64h-3.2v3.84h3.84zm-5.12 0v-3.84h-3.2a.64.64 0 0 0-.64.64v3.2h3.84z"
		/>
	</svg>
);

export default SvgCalendar;
