import {getHourBoundaries} from 'utils/time';
import {callFormBase} from './constants';
import {pickIfNot} from 'utils/objects';
import {getNextDayEncounterSuggestion} from 'utils/encounters';
import {formatEncounterFormFillFormOutput} from 'utils/formData';
import memoize from 'memoize-one';
import {sort} from 'utils/arrays';
import {descend, uniqBy, prop, evolve, map, any, includes} from 'ramda';
import {sort as timsort} from 'timsort';

export const formatClientFormInput = ({client}) => {
	return client;
};

export const formatClientFormOutput = ({form}) => {
	const client = {...form, emailMarketing: form.emailMarketing ? new Date() : null};
	return client;
};

// NOTE: similar to Calls etc
const _getCallStates = state => {
	const visit = state === 'visit';
	const notReached = state === 'notReached';
	const contacted = state === 'contacted';
	const cancelled = state === 'cancelled';
	const reached = !notReached;
	const lost = contacted || cancelled;

	return {visit, notReached, contacted, cancelled, reached, lost};
};
// easy optimization
export const getCallStates = memoize(_getCallStates);

export const formatCallFormInput = ({teams, openedAt}) => {
	return {
		...callFormBase,
		calendarResource: {
			...callFormBase.calendarResource,
			teamId: teams ? teams[0].id : null,
			dateFrom: openedAt ? getNextDayEncounterSuggestion(openedAt) : null,
		},
	};
};

export const formatCallFormOutput = ({form}) => {
	const {
		dateCreatorOpen,
		state,
		formFill,
		calendarResource,
		contactClientId,
		...callVals
	} = form;

	const ss = getCallStates(state);

	const projectCallData = {
		...callVals,
		contactClientId,
		// keep fields if they match criteria, otherwise clear them to base values
		...pickIfNot(
			{
				calendarResourceId: ss.visit && !dateCreatorOpen,
				contactClientId: ss.visit,
				reason: ss.lost,
				description: ss.reached,
			},
			callFormBase,
		),
		appointment: ss.visit,
		reached: ss.reached,
		preferredEncounterState: ss.lost ? state : null,
	};

	const calendarResourceData =
		ss.visit && dateCreatorOpen
			? {
					...calendarResource,
					dateFrom: getHourBoundaries(calendarResource.dateFrom)[0],
					dateTo: getHourBoundaries(calendarResource.dateFrom)[1],
					clientId: contactClientId,
			  }
			: null;

	const formFillData = ss.visit ? formatEncounterFormFillFormOutput(formFill) : null;

	return {
		projectCall: projectCallData,
		calendarResource: calendarResourceData,
		formFill: formFillData,
	};
};

export const formatVisitFormInput = ({clients}) => {
	return {
		contact: clients.length > 0 ? clients[0].id : null,
	};
};

export const formatVisitFormOutput = ({form, projectBuildingId, userId, teamId}) => {
	const times = getHourBoundaries(form.visitAt);
	const calendarResource = {
		appointmentType: 'call',
		clientId: form.client,
		teamId: teamId,
		buildingId: projectBuildingId,
		dateFrom: times[0].toISOString(),
		dateTo: times[1].toISOString(),
		salesmanId: userId,
		reserverId: userId,
	};
	return {calendarResource};
};

export const formatCondoCreatorInitValues = ({building}) => {
	if (!building) return {};
	return {
		projectBuildingId: building.id,
		encounterState: building.encounterState,
		encounterType: building.encounterType,
		encounterDate: building.encounterDate,
		companyZip: building.zip,
		companyDomicile: building.city,
		companyCity: building.city,
		propertyManufacturingYear: building.manufacturingYear,
		propertyType: building.type,
		banned: building.banned,
		users: [],
	};
};

export const formatPropertyProblemsInitValues = ({condo}) => {
	return {
		propertyJam: condo.propertyJam,
		propertyDraft: condo.propertyDraft,
		propertyLeak: condo.propertyLeak,
		propertyWaterDamage: condo.propertyWaterDamage,
		propertySmell: condo.propertySmell,
		propertyOtherProblems: condo.propertyOtherProblems,
	};
};

export const sortCondoActivities = condo => {
	const {activities} = condo;

	if (!activities.length) return [];

	const sortedActivities = sort(descend(a => a.createdAt))(activities);
	return sortedActivities;
};

const sortActivities = (a, b) => {
	let aVal = a.date ? a.date : a.dateFrom ? a.dateFrom : a.createdAt;

	let bVal = b.date ? b.date : b.dateFrom ? b.dateFrom : b.createdAt;

	return aVal < bVal ? 1 : aVal > bVal ? -1 : 0;
};

export const sortAllActivities = (calRes, activities) => {
	const allActivities = [...calRes, ...activities];

	timsort(allActivities, sortActivities);

	return allActivities;
};

export const formatCondoOwnerOpts = (condo, salesmen) => {
	if (condo && condo.user) {
		return uniqBy(prop('id'), [condo.user, ...salesmen]);
	} else return salesmen;
};

export const formatCondoCustomerAcquisitionOpts = (condo, customerAcquisitionUsers) => {
	if (condo && condo.customerAcquisitionUser) {
		return uniqBy(prop('id'), [
			condo.customerAcquisitionUser,
			...customerAcquisitionUsers,
		]);
	} else return customerAcquisitionUsers;
};

export const getActivityType = ({state}) => {
	// const _type = Object.entries(activityStatesAndTypes)
	// 	.filter(p => p[1][state])
	// 	.flat();
	// const type = _type && _type[0] ? _type[0] : 'unknown';
	return state;
};

export const formatActivityFormOutput = (form, condominiumId) => {
	const formData = evolve({products: map(id => ({id}))}, form);
	const activity = {
		...formData,
		condominiumId,
		type: getActivityType({state: form.state}),
	};

	return activity;
};

export const formatCalendarResourceEditFormOutput = ({form, selectedItemId}) => {
	const [dateFrom, dateTo] = form.date ? getHourBoundaries(form.date) : [null, null];

	return {
		id: selectedItemId,
		dateFrom,
		dateTo,
	};
};

export const formatCalendarResources = ({calendarResources, calRes}) => {
	return calendarResources.map(cr => {
		return cr.id === calRes.id
			? {
					...calRes,
					dateTo: calRes.dateTo.toISOString(),
					dateFrom: calRes.dateFrom.toISOString(),
			  }
			: cr;
	});
};

// adds condo's manager and houseManager to the beginning of clients list
export const sortCondoClients = (clients = [], condo = {}) => {
	const managers = [];
	if (condo.manager) managers.push(condo.manager);
	if (condo.houseManager) managers.push(condo.houseManager);
	const condoClients = managers.concat(
		clients.filter(c => {
			if (condo.manager && c.id === condo.manager.id) return false;
			if (condo.houseManager && c.id === condo.houseManager.id) return false;
			return true;
		}),
	);

	return condoClients;
};

export const isProjectSalesUser = user =>
	any(r => includes(r.slug, 'projektimyynti'), user.roles);

export const isProjectCustomerAcquisitionUser = user =>
	any(r => includes(r.slug, 'projektitelemyyja'), user.roles);
