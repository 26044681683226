import {effect} from 'utils/redux';
import {catchNonFatalDefault, logInfo} from 'io/errors';
import {reset, change} from 'redux-form';
import namespace from './namespace';
import * as actions from './actions';
import {
	getBuildings,
	getSalesmanagers,
	getProducts,
	postLead,
	getLead,
	putLead,
	postBuilding,
	postClient,
	initAddBuildingMap as doInitAddBuildingMap,
	getOrganizations,
	postRestoreLead,
	putClient,
} from './io';
import {decorateWithNotifications} from 'io/app';
import services from 'services';
import * as selectors from './selectors';
import * as commonSelectors from 'modules/common/selectors';
import {getReferrerUrl} from 'utils/url';
import {getLocationByAddress} from 'io/geo';
import {transform} from 'ol/proj';
import * as nActions from 'modules/notifications/actions';
import {medDur} from 'constants/notifications';
import {buildingFocusZoom, localeCenterWeb, localeZoomFactor} from 'constants/maps';
import {mergeLeft} from 'ramda';
import {over} from 'utils/lenses';
import msgs from 'dicts/messages';
import cache from './cache';

const creator = effect(namespace);
let intl = null;
services.waitFor('intl').then(x => (intl = x));
const history = services.get('history');

const fetchSalesmanagers = (query = {}, {notifyOpts = {}}) => (getState, dispatch) => {
	return decorateWithNotifications(
		{
			id: 'get-salesmanagers',
			failureStyle: 'warning',
			...notifyOpts,
		},
		getSalesmanagers(query),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._setSalesmanagers({}));
			throw e;
		})
		.then(sm => {
			dispatch(actions._setSalesmanagers(sm));
		})

		.catch(catchNonFatalDefault(getState, dispatch));
};

export let initialize = id => (getState, dispatch) => {
	const queries = Number(id)
		? [getSalesmanagers(), getProducts(), getOrganizations(), getLead(id)]
		: [getSalesmanagers(), getProducts(), getOrganizations()];
	decorateWithNotifications(
		{
			id: 'get-lead-init-data',
			failureStyle: 'warning',
		},
		Promise.all(queries)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(data => {
				dispatch(actions._initialize(data));
			}),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let saveLead = ({form}) => (getState, dispatch) => {
	const lead = selectors.lead(getState());

	decorateWithNotifications(
		{
			id: 'save-lead',
			failureStyle: 'error',
			success: intl.formatMessage({id: 'Lead saved'}),
		},
		lead && lead.id ? putLead(form, lead.id) : postLead(form),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(l => {
			dispatch(reset('leadForm'));
			dispatch(actions.setProcessing(false));
			const referrerUrl = getReferrerUrl(history.location.search);
			history.push(referrerUrl || '/leads');
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
saveLead = creator('saveLead', saveLead);

export let restoreLead = id => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'restore-lead',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Lead restored'}),
		},
		postRestoreLead(id),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(_ => {
			// reInitialize the page after restoring
			dispatch(actions.reInitialize(id));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};

export let searchBuildings = ({text, callback}) => (getState, dispatch) => {
	const query = {
		_q: text,
		_limit: 20,
		include: 'clients',
	};

	decorateWithNotifications(
		{
			id: 'search-buildings',
			failureStyle: 'warning',
		},
		getBuildings(query),
	)(getState, dispatch)
		.then(buildings => {
			callback(buildings);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
searchBuildings = creator('searchBuildings', searchBuildings);

export let setFormValues = values => (getState, dispatch) => {
	dispatch(values);
};
setFormValues = creator('setFormValues', setFormValues);

export let updateClient = ({client}) => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'search-buildings',
			failureStyle: 'warning',
		},
		putClient(client),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(_ => {
			dispatch(actions._clientSaved());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
updateClient = creator('updateClient', updateClient);

export let searchSalesmanagers = query => (getState, dispatch) => {
	fetchSalesmanagers(query, {})(getState, dispatch);
};
searchSalesmanagers = creator('searchSalesmanagers', searchSalesmanagers);

export let initAddBuildingMap = () => (getState, dispatch) => {
	const apiToken = commonSelectors.apiToken(getState());
	const org = commonSelectors.activeOrganization(getState());
	const building = selectors.buildingToAdd(getState());

	// Fetch address coords from Google, set to state (buildingToAdd) and as initial center
	decorateWithNotifications(
		{id: 'init-add-building-map', loading: intl.formatMessage({id: msgs.loading})},
		getLocationByAddress(`${building.address},${building.zip}`)
			.then(res => {
				const {lat, lng} = res.geometry.location;
				return {
					coords: transform([lng(), lat()], 'EPSG:4326', 'EPSG:3857'),
					zoom: buildingFocusZoom,
				};
			})
			.catch(e => {
				// address not found in Google, return localeCenterWeb as fallback
				logInfo(e);
				return {
					coords: localeCenterWeb,
					zoom: 4 * localeZoomFactor,
				};
			})
			.then(({coords, zoom}) => {
				dispatch(
					nActions.info({
						id: 'drag-marker-info',
						message: intl.formatMessage({
							id: 'You can adjust the position of the building by dragging the marker',
						}),
						duration: medDur,
					}),
				);
				dispatch(actions._setBuildingToAddCoords(coords));

				return doInitAddBuildingMap({
					apiToken,
					organizationId: org.id,
					initialZoom: zoom,
					initialCenter: coords,
					onDragEnd: coords => dispatch(actions._setBuildingToAddCoords(coords)),
				});
			}),
	)(getState, dispatch)
		.then(resources => {
			cache.update(over(['addBuildingMap'], mergeLeft(resources)));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initAddBuildingMap = creator('initAddBuildingMap', initAddBuildingMap);

export let addBuilding = building => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'add-building',
			failureStyle: 'error',
			loading: intl.formatMessage({id: 'Saving'}),
			success: intl.formatMessage({id: 'Building created'}),
		},
		postBuilding(building),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(b => {
			dispatch(actions._addBuilding());
			dispatch(change('leadForm', 'building', {...b, clients: {data: []}}));
			dispatch(change('leadForm', 'clientId', ''));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
addBuilding = creator('addBuilding', addBuilding);

export let createClient = client => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'add-client',
			failureStyle: 'error',
			loading: intl.formatMessage({id: 'Saving'}),
			success: intl.formatMessage({id: 'Client saved'}),
		},
		postClient(client),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(c => {
			dispatch(actions._addClient(c));
			dispatch(change('leadForm', 'clientNotFound', false));
			dispatch(change('leadForm', 'clientId', c.id));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
createClient = creator('createClient', createClient);
