export const removalRequestInclude = 'user,removalRequestComments';

export const encounterInclude =
	'building,' +
	'organization,' +
	'source.formFill.formAttributes,' +
	'source.calendarResource.client,' +
	'source.calendarResource.salesmanVisit,' +
	'source.calendarResource.salesman,' +
	'source.calendarResource.team,' +
	'source.user,' +
	'source.products';
