import React from 'react';

const SvgTeamcalendar = props => (
	<svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M2.56 30.721a1.922 1.922 0 0 1-1.92-1.92V5.761c0-1.058.861-1.92 1.92-1.92h1.92v-1.92a.64.64 0 0 1 1.28 0v1.92H9.6v-1.92a.64.64 0 0 1 1.28 0v1.92h3.84v-1.92a.64.64 0 0 1 1.28 0v1.92h3.84v-1.92a.64.64 0 0 1 1.28 0v1.92h3.84v-1.92a.64.64 0 0 1 1.28 0v1.92h1.92c1.059 0 1.92.862 1.92 1.92v23.04c0 1.059-.861 1.92-1.92 1.92H2.56zm0-25.6a.64.64 0 0 0-.64.64v23.04c0 .354.287.64.64.64h25.6a.64.64 0 0 0 .64-.64V5.761a.64.64 0 0 0-.64-.64h-1.92v.64a.64.64 0 0 1-1.28 0v-.64h-3.84v.64a.64.64 0 0 1-1.28 0v-.64H16v.64a.64.64 0 0 1-1.28 0v-.64h-3.84v.64a.64.64 0 0 1-1.28 0v-.64H5.76v.64a.64.64 0 0 1-1.28 0v-.64H2.56zm8.645 15.29A5.212 5.212 0 0 1 6 15.205 5.212 5.212 0 0 1 11.205 10a5.212 5.212 0 0 1 5.206 5.205 5.212 5.212 0 0 1-5.206 5.206zm0-9.315a4.114 4.114 0 0 0-4.11 4.11 4.114 4.114 0 0 0 4.11 4.11 4.114 4.114 0 0 0 4.11-4.11 4.114 4.114 0 0 0-4.11-4.11zm6.429 14.448a.549.549 0 0 1-.457-.25 7.11 7.11 0 0 0-5.986-3.236 7.081 7.081 0 0 0-3.847 1.134.55.55 0 0 1-.758-.163.54.54 0 0 1-.074-.412.541.541 0 0 1 .239-.344 8.175 8.175 0 0 1 4.44-1.31 8.256 8.256 0 0 1 6.465 3.12 5.99 5.99 0 0 1 4.513-2.027c.69 0 1.369.117 2.02.35a.548.548 0 0 1-.367 1.033 4.93 4.93 0 0 0-5.732 1.868.547.547 0 0 1-.452.24h-.004v-.003zm4.53-4.037a3.84 3.84 0 0 1-3.835-3.836 3.84 3.84 0 0 1 3.835-3.835A3.84 3.84 0 0 1 26 17.67a3.84 3.84 0 0 1-3.836 3.836zm0-6.575a2.743 2.743 0 0 0-2.74 2.74c0 1.51 1.23 2.739 2.74 2.739 1.51 0 2.74-1.23 2.74-2.74 0-1.511-1.23-2.74-2.74-2.74z"
		/>
	</svg>
);

export default SvgTeamcalendar;
