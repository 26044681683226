import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	metrics: {},
	metricsLoading: true,
	piimegaLogs: [],
	piimegaLogsLoading: true,
	feedback: [],
	feedbackLoading: true,
};
