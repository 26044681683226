import React from 'react';

const SvgDelete = props => (
	<svg width="12px" height="12px" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M10.243 1.76a5.992 5.992 0 0 0-8.487.002 6.021 6.021 0 0 0 .003 8.504 6.058 6.058 0 0 0 8.34.138c.502-.47.92-1.022 1.236-1.633a5.988 5.988 0 0 0 .6-1.875c.043-.293.065-.588.065-.883a5.983 5.983 0 0 0-1.757-4.253zm-7.596.88a4.804 4.804 0 0 1 6.114-.512.132.132 0 0 1 .016.2L2.335 8.786a.132.132 0 0 1-.2-.015 4.766 4.766 0 0 1 .512-6.13zm6.728 6.745a4.751 4.751 0 0 1-6.115.512.132.132 0 0 1-.016-.2l6.443-6.458a.132.132 0 0 1 .2.015 4.766 4.766 0 0 1-.512 6.13z"
		/>
	</svg>
);

export default SvgDelete;
