import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {createSelector} from 'reselect';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {
	formatUrlQuery,
	formatFetchableCondosQuery,
	formatCondoSearchFormInput,
} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const condosQuery = pipe(root, prop('condosQuery'));

export const condosQueryFetchable = createSelector([condosQuery], condosQuery => {
	return formatFetchableCondosQuery({condosQuery});
});

export const urlQuery = createSelector([condosQuery], condosQuery => {
	return formatUrlQuery({condosQuery});
});

export const searchFormInitValues = createSelector([condosQuery], condosQuery =>
	formatCondoSearchFormInput({condosQuery}),
);

export const condos = pipe(root, prop('condos'));

export const condosPagination = pipe(root, prop('condosPagination'));

export const condosLoading = pipe(root, prop('condosLoading'));

export const dismissPrompt = createSelector(
	[condosQuery],
	condosQuery => condosQuery.dismissPrompt,
);

export const processing = pipe(root, prop('processing'));

export const organizations = pipe(root, prop('organizations'));

export const areasSelectorSearch = pipe(root, prop('areasSelectorSearch'));

export const areasSelectorOpen = pipe(root, prop('areasSelectorOpen'));

export const products = pipe(root, prop('products'));
