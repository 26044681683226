import {action} from 'utils/redux';
import {P, PaginatedData} from 'utils/types';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const recheckQuery = creator('recheckQuery');

export const changePage = creator('changePage', P.Number);

export const toggleTeamCreator = creator('toggleTeamCreator');

export const openTeamAreasSelector = creator('openTeamAreasSelector');

export const closeTeamAreasSelector = creator('closeTeamAreasSelector');

export const setTeamAreasSelectorType = creator('setTeamAreasSelectorType');

export const setTeamAreasSelectorSearch = creator('setTeamAreasSelectorSearch', P.String);

export const openTeamAreasSelectorSuggestion = creator(
	'openTeamAreasSelectorSuggestion',
	P.String,
);

export const saveTeam = creator('saveTeam');

export const removeTeam = creator('removeTeam');

export const _setTeams = creator('_setTeams', PaginatedData(P.Array));

export const _toggleTeamCreator = creator('_toggleTeamCreator');

export const _updateTeamsQuery = creator('_updateTeamsQuery', P.Object);

export const _initialize = creator('_initialize');

export const _startOp = creator('_startOp');

export const _opOk = creator('_opOk');

export const _opFailed = creator('_opFailed');
