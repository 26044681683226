import React from 'react';

const SvgLock = props => {
	return (
		<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
			<g
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				transform="scale(.85714)"
			>
				<rect x={2} y={5.5} width={10} height={8} rx={1} />
				<path d="M10.5 5.5V4a3.5 3.5 0 00-7 0v1.5" />
				<circle cx={7} cy={9.5} r={0.5} />
			</g>
		</svg>
	);
};

export default SvgLock;
