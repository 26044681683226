import {effect} from 'utils/redux';
import {catchNonFatalDefault} from 'io/errors';
import namespace from './namespace';
import * as selectors from './selectors';
import * as actions from './actions';
import {decorateWithNotifications} from 'io/app';
import {getQuery, pushQuery} from 'io/history';
import {mergeLeft, equals} from 'ramda';
import {parseUrlQuery} from './utils';
import services from 'services';
import * as confirmerActions from 'modules/confirmer/actions';
import msgs from 'dicts/messages';
import {deleteLead, getLeads} from '../common/io';
import {leadsQueryBase} from './constants';

const creator = effect(namespace);
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const fetchLeads = ({notifyOpts = {}}) => (getState, dispatch) => {
	return decorateWithNotifications(
		{id: 'get-leads', failureStyle: 'warning', ...notifyOpts},
		getLeads({...selectors.leadsQueryFetchable(getState()), ...leadsQueryBase}),
	)(getState, dispatch).then(leads => {
		dispatch(actions._setLeads(leads));
	});
};

export let initialize = () => (getState, dispatch) => {
	const {leadsQuery} = parseUrlQuery(getQuery());
	dispatch(actions._updateLeadsQuery(leadsQuery));

	const {salesmanagerLeads} = getQuery();
	if (
		!salesmanagerLeads &&
		(selectors.showOwnSalesmanagerLeads(getState()) ||
			selectors.showAllSalesmanagerLeads(getState()) ||
			selectors.showOwnOrgSalesmanagerLeads(getState()))
	) {
		// if query doesn't have tab selection and user is salesmanager, change default tab to salesmanagerLeads
		dispatch(actions._updateLeadsQuery({salesmanagerLeads: true}));
		pushQuery(mergeLeft(selectors.leadsQuery(getState())));
	}

	fetchLeads({})(getState, dispatch)
		.then(_ => dispatch(actions._initialize()))
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let updateLeads = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.leadsQuery(getState())));
	fetchLeads({})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
updateLeads = creator('updateLeads', updateLeads);

export let recheckQuery = () => (getState, dispatch) => {
	const urlQueries = parseUrlQuery(getQuery());
	const leadsQuery = selectors.leadsQuery(getState());
	if (!equals(leadsQuery, urlQueries.leadsQuery)) {
		dispatch(actions._updateLeadsQuery(urlQueries.leadsQuery));
		fetchLeads({})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}
};

export let removeLead = ({lead}) => (getState, dispatch) => {
	const onConfirmed = () => {
		dispatch(actions._setProcessing(true));
		decorateWithNotifications(
			{
				id: 'delete-lead',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Lead removed'}),
			},
			deleteLead({id: lead.id}),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._removedLead());
				const {_page: page} = selectors.leadsQuery(getState());
				if (selectors.leads(getState()).length <= 1 && page > 1) {
					dispatch(actions._updateLeadsQuery({_page: page - 1}));
					pushQuery(mergeLeft(selectors.urlQuery(getState())));
				}

				return fetchLeads({
					notifyOpts: {loading: intl.formatMessage({id: msgs.loading})},
				})(getState, dispatch);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete lead?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirmed,
		}),
	);
};
