import {state as lifecycle} from 'fragments/lifecycle';
import {emptyPagination} from 'constants/pagination';

export default {
	...lifecycle,
	loading: true,
	salesmanVisits: [],
	visitsPagination: emptyPagination,
	userTeams: [],
	areas: {
		counties: [],
		cities: [],
		postcodes: [],
	},
	salesmanVisitsQuery: {
		_page: 1,
		state: [],
		dateFrom: '',
		dateTo: '',
		countyId: [],
		cityId: [],
		userId: [],
		cancelled: false,
		unacknowledged: false,
		reservationSources: [],
	},
	users: [],
	showRemovedUsers: false,
	lockFrom: false,
};
