import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {formatSearchFormOutput, sortTeamUsers, formatAreas} from './utils';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.recheckQuery.type: {
			return [state, effects.recheckQuery()];
		}

		case actions.search.type: {
			const salesmanVisitsQuery = formatSearchFormOutput(payload);

			const newState = {
				...state,
				loading: true,
				salesmanVisitsQuery,
			};

			return [newState, effects.updateSalesmanVisits()];
		}

		case actions._setShowRemovedUsers.type: {
			const newState = {
				...state,
				showRemovedUsers: state.showRemovedUsers ? false : true,
			};
			return [newState, null];
		}

		case actions.changePage.type: {
			const newState = {
				...state,
				loading: true,
				salesmanVisitsQuery: {...state.salesmanVisitsQuery, _page: payload},
			};

			return [newState, effects.updateSalesmanVisits()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions._setUserTeams.type: {
			return [{...state, userTeams: sortTeamUsers(payload)}, null];
		}

		case actions._setAreas.type: {
			const areas = formatAreas(payload);
			return [{...state, areas, areasLoading: false}, null];
		}

		case actions._setVisits.type: {
			return [
				{
					...state,
					salesmanVisits: payload.data,
					visitsPagination: payload.pagination,
					loading: false,
				},
				null,
			];
		}

		case actions._updateQuery.type: {
			const newState = {
				...state,
				loading: true,
				salesmanVisitsQuery: {...state.salesmanVisitsQuery, ...payload},
			};

			return [newState, null];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._setUsers.type: {
			return [{...state, users: payload}, null];
		}

		case actions._setLockFrom.type: {
			return [{...state, lockFrom: payload ? new Date(payload) : null}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
