import {locale} from 'constants/app';
import {symbol as currencySymbols} from 'dicts/currency';
import fiMessages from 'messages/fi';
import svMessages from 'messages/sv';
import enMessages from 'messages/en';

export const allLanguages = {
	fi: 'fi',
	se: 'sv',
};

export const language = allLanguages[locale];

export const allMessages = {
	fi: fiMessages,
	sv: svMessages,
	en: enMessages,
};

export const messages = allMessages[language];

export const supportedLanguages = Object.keys(allMessages);

export const currencies = {
	fi: 'EUR',
	se: 'SEK',
};

export const currency = currencies[locale];

export const currencySymbol = currencySymbols[currency];
