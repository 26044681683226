import {encodeQuery} from 'utils/url';
import {validateResponse} from 'utils/http';

export const request = (method, url, query, conf = {}) =>
	new Request(`${url}${encodeQuery(query)}`, {...conf, method});

export const requestJson = (method, url, query, conf = {}) =>
	request(method, url, query, {
		...conf,
		...(conf.body ? {body: JSON.stringify(conf.body)} : {}),
		headers: {'content-type': 'application/json; charset=utf-8', ...(conf.headers || {})},
	});

export const fetch_ = request => fetch(request).then(validateResponse);

export const responseJson = response =>
	response.text().then(text => (text !== '' ? JSON.parse(text) : undefined));

export const fetchJson = request => fetch_(request).then(responseJson);

export const http = (...args) => fetch_(request(...args));

export const httpJson = (...args) => fetchJson(requestJson(...args));

// for performing requests that use the "multipart/form-data" content-type (for uploading files from forms). our API always returns JSON so the response is parsed, but the request a vanilla fetch request, you just need to make sure to use a FormData object as its body.
export const httpForm = (...args) => fetchJson(request(...args));
