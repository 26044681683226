import services from 'services';
import {prop} from 'ramda';
import {describeThrow} from 'utils/errors';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getBuildings = query =>
	httpJson('get', `/buildings/search`, query)
		.catch(describeThrow(intl.formatMessage({id: 'Failed to search buildings'})))
		.then(prop('data'));
