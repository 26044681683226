import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	processing: false,
	request: null,
	previewableEncounter: null,
	comments: [],
	clients: [],
	clientsLoading: true,
};
