import {effect} from 'utils/redux';
import {P} from 'utils/types';
import {catchNonFatalDefault, logWarning} from 'io/errors';
import namespace from './namespace';
import * as actions from './actions';
import * as selectors from './selectors';
import * as rootSelectors from 'modules/common/selectors';
import {
	initTeamAreasMap as doInitTeamAreasMap,
	getAreasStyle,
	getAreasSource,
	getTeams,
	updateTeam,
	postTeam,
	deleteTeam,
} from './io';
import services from 'services';
import cache from './cache';
import msgs from 'dicts/messages';
import {decorateWithNotifications} from 'io/app';
import {describeThrow} from 'utils/errors';
import {geocodeGooglePlaceId} from 'io/geo';
import {transform} from 'ol/proj';
import {parseUrlQuery} from './utils';
import {getQuery, pushQuery} from 'io/history';
import {pick, mergeLeft, equals} from 'ramda';
import {over} from 'utils/lenses';
import * as confirmerActions from 'modules/confirmer/actions';
import {formValueSelector, change as formChange} from 'redux-form';
import {sortByTitle as sortAreas} from 'utils/areas';
import {areaFocusZoom} from 'constants/maps';
import {getAllJsonProperties} from 'utils/maps';
import * as commonActions from '../common/actions';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const teamFormVal = formValueSelector('teamForm');

const teamsQueryKeys = ['_page'];

const getActiveTeamAreaIds = (getState, dispatch) => {
	const areas = teamFormVal(getState(), 'areas').map(a => a.id);
	return new Set(areas);
};

const fetchTeamsData = (getState, dispatch) => {
	const usersQuery = {...selectors.teamsQueryRaw(getState()), _limit: '999'};

	return Promise.all([
		getTeams(usersQuery).then(teams => {
			dispatch(actions._setTeams(teams));
		}),
	]);
};

const fetchTeams = ({notifyOpts = {}}) => (getState, dispatch) => {
	return decorateWithNotifications(
		{id: 'get-teams', failureStyle: 'error', ...notifyOpts},
		getTeams(selectors.teamsQueryFetchable(getState())),
	)(getState, dispatch).then(teams => {
		dispatch(actions._setTeams(teams));
	});
};

const selectTeamArea = props => (getState, dispatch) => {
	const areas = teamFormVal(getState(), 'areas');
	const newAreas = areas.find(a => a.id === props.id)
		? areas.filter(a => a.id !== props.id)
		: sortAreas([...areas, props]);
	dispatch(formChange('teamForm', 'areas', newAreas));

	const selectedAreaIds = getActiveTeamAreaIds(getState, dispatch);
	const {
		teamAreasMap: {areasLayer},
	} = cache.read();
	getAreasStyle({selectedAreaIds, team: selectors.selectedTeam(getState())})
		.then(x => areasLayer.setStyle(x))
		.catch(logWarning);
};

export let initialize = () => (getState, dispatch) => {
	dispatch(actions._updateTeamsQuery(pick(teamsQueryKeys, getQuery())));

	decorateWithNotifications(
		{id: 'init-teams', failureStyle: 'error'},
		fetchTeamsData(getState, dispatch),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let updateTeams = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	const teamsQuery = selectors.teamsQueryRaw(getState());

	decorateWithNotifications(
		{id: 'get-teams', failureStyle: 'error'},
		getTeams(teamsQuery),
	)(getState, dispatch)
		.then(teams => {
			dispatch(actions._setTeams(teams));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
updateTeams = creator('updateTeams', updateTeams);

export let recheckQuery = () => (getState, dispatch) => {
	const urlQueries = parseUrlQuery(getQuery());
	const reportsQuery = selectors.teamsQueryRaw(getState());
	if (!equals(reportsQuery, urlQueries.teamsQuery)) {
		dispatch(actions._updateTeamsQuery(urlQueries.teamsQuery));
		fetchTeams({})(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}
};

export let initTeamAreasMap = () => (getState, dispatch) => {
	const apiToken = rootSelectors.apiToken(getState());
	const org = rootSelectors.activeOrganization(getState());
	const selectedAreaIds = getActiveTeamAreaIds(getState, dispatch);
	const onAreaClick = feature =>
		selectTeamArea(getAllJsonProperties(feature))(getState, dispatch);

	decorateWithNotifications(
		{id: 'map-init'},
		doInitTeamAreasMap({
			apiToken,
			organizationId: org.id,
			areasType: 'city',
			selectedAreaIds,
			onAreaClick,
			getTeam: () => selectors.selectedTeam(getState()),
		}),
	)(getState, dispatch)
		.then(resources => {
			cache.update(over(['teamAreasMap'], mergeLeft(resources)));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initTeamAreasMap = creator('initTeamAreasMap', initTeamAreasMap);

export let setTeamAreasMapType = type => (getState, dispatch) => {
	const apiToken = rootSelectors.apiToken(getState());
	const {
		teamAreasMap: {areasLayer},
	} = cache.read();
	getAreasSource({apiToken, areasType: type})
		.then(x => areasLayer.setSource(x))
		.catch(catchNonFatalDefault(getState, dispatch));
};
setTeamAreasMapType = creator('setTeamAreasMapType', setTeamAreasMapType, P.String);

export let openTeamAreasSelectorSuggestion = placeId => (getState, dispatch) => {
	geocodeGooglePlaceId(placeId)
		.then(res => {
			const {lat, lng} = res.geometry.location;
			const coord = transform([lng(), lat()], 'EPSG:4326', 'EPSG:3857');
			const {
				teamAreasMap: {map},
			} = cache.read();
			const view = map.getView();
			view.animate({center: coord, zoom: areaFocusZoom});
		})
		.catch(describeThrow(intl.formatMessage({id: 'Search failed'})))
		.catch(catchNonFatalDefault(getState, dispatch));
};
openTeamAreasSelectorSuggestion = creator(
	'openTeamAreasSelectorSuggestion',
	openTeamAreasSelectorSuggestion,
	P.String,
);

export let saveTeam = ({id, team}) => (getState, dispatch) => {
	dispatch(actions._startOp());
	decorateWithNotifications(
		{
			id: 'save-team',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Saved'}),
		},
		id ? updateTeam(id, team) : postTeam(team),
	)(getState, dispatch)
		.then(() => {
			updateTeams()(getState, dispatch);
			dispatch(commonActions.updateData());
			dispatch(actions._opOk());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
saveTeam = creator('saveTeam', saveTeam, P.Object);

export let removeTeam = id => (getState, dispatch) => {
	const onConfirmed = () => {
		dispatch(actions._startOp());
		decorateWithNotifications(
			{
				id: 'delete-team',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Team removed'}),
			},
			deleteTeam(id),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._opOk());
				dispatch(commonActions.updateData());
				const {_page: page} = selectors.teamsQueryRaw(getState());
				if (selectors.teams(getState()).length <= 1 && page > 1) {
					dispatch(actions._updateTeamsQuery({_page: page - 1}));
					pushQuery(mergeLeft(selectors.urlQuery(getState())));
				}

				return fetchTeamsData(getState, dispatch);
			});
	};
	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete team?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirmed,
		}),
	);
};
removeTeam = creator('removeTeam', removeTeam);
