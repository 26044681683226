export default (initialServices = {}) => {
	let services = {};
	let awaiters = {};

	Object.assign(services, initialServices);

	const all = () => ({...services});

	const get = key => services[key];

	const set = (key, service) => {
		services[key] = service;

		if (awaiters[key]) {
			awaiters[key].forEach(resolver => {
				resolver(service);
			});
			// we don't "officially" support re-setting services so delete the associated awaiters
			delete awaiters[key];
		}
	};

	const waitFor = key => {
		if (Object.prototype.hasOwnProperty.call(services, key)) {
			return Promise.resolve(services[key]);
		}
		if (!awaiters[key]) {
			awaiters[key] = [];
		}
		return new Promise(resolve => {
			awaiters[key].push(resolve);
		});
	};

	return {all, get, set, waitFor};
};
