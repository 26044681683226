import {state as lifecycle} from 'fragments/lifecycle';

export default {
	...lifecycle,
	processing: false,
	roles: [],
	rolesLoading: true,
	roleEditorOpen: false,
	selectedRole: null,
	permissions: [],
	otherPermissions: [],
	appPermissions: [],
};
