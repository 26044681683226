// create a simple hook that allows listening for actions (redux's own subscribe api is lacking). need to plug the enhancer into the store to setup the instance
export default () => {
	let listeners = {};
	let seq = 1;

	const subscribe = f => {
		const currSeq = seq;
		seq += 1;

		listeners[currSeq] = f;

		// unsubscribe
		return () => {
			delete listeners[currSeq];
		};
	};

	const instance = {
		subscribe,
		//subscribeToType,
	};

	const enhancer = next => (reducer, initialState, enhancer) => {
		const reducer_ = (state, action) => {
			const newState = reducer(state, action);
			Object.values(listeners).forEach(f => f(action, state, newState));
			return newState;
		};

		return next(reducer_, initialState, enhancer);
	};

	return {
		instance,
		enhancer,
	};
};
