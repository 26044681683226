import {path, pipe, prop} from 'ramda';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {createSelector} from 'reselect';
import {formatBuildingsFiltersInput} from './utils';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const processing = pipe(root, prop('processing'));

export const buildingsType = pipe(root, prop('buildingsType'));

export const onlyWithProjectBuildingId = pipe(root, prop('onlyWithProjectBuildingId'));

export const buildingsQuery = pipe(root, prop('buildingsQuery'));

export const buildings = pipe(root, prop('buildings'));

export const buildingsPagination = pipe(root, prop('buildingsPagination'));

export const buildingsLoading = pipe(root, prop('buildingsLoading'));

export const buildingsNotYetLoaded = pipe(root, prop('buildingsNotYetLoaded'));

export const massEditorOpen = pipe(root, prop('massEditorOpen'));

export const massSelection = pipe(root, prop('massSelection'));

export const buildingsFiltersInitValues = createSelector(
	[buildingsQuery],
	buildingsQuery => formatBuildingsFiltersInput({buildingsQuery}),
);

export const areasSelectorOpen = pipe(root, prop('areasSelectorOpen'));

export const areasSelectorSearch = pipe(root, prop('areasSelectorSearch'));

export const availableTags = pipe(root, prop('availableTags'));

export const csvFileSelectorOpen = pipe(root, prop('csvFileSelectorOpen'));

export const csvFileSelectorProcessing = pipe(root, prop('csvFileSelectorProcessing'));
