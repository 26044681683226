import React from 'react';
import Page from './Page';
import Content from './Content';
import NotFound from './NotFound';

const NotFoundPage = () => (
	<Page>
		<Content>
			<NotFound />
		</Content>
	</Page>
);

export default NotFoundPage;
