export const salesmanVisitFormBase = {
	// meta
	dateCreatorOpen: false,
	// values
	state: 'deal',
	comment: '',
	price: null,
	dealAt: null,
	secondUserId: null,
	secondUserPercentage: null,
	newCalendarResourceId: null,
	newAppointmentDate: null,
	reason: null,
	reasonMappingId: null,
	otherReason: '',
	products: [],
	// note: productStates not here since they have no reasonable empty value (other than empty list maybe)
};

export const createEmptyClient = () => ({
	firstName: '',
	lastName: '',
	email: '',
	password: '',
	swedish: false,
	comments: '',
	title: '',
	address: '',
	zip: '',
	city: '',
});

export const initCalendarResourceInclude =
	'client,' +
	'reserver,' +
	'salesman,' +
	'salesmanVisit.products,' +
	'salesmanVisit.newCalendarResource,' +
	'salesmanVisit.surveyFill,' +
	'salesmanVisit.encounter,' +
	'team,' +
	'organization.products,' +
	'reservation.encounter.building.clients,' +
	'reservation.encounter.building.comments,' +
	'reservation.encounter.building.comments.user,' +
	'reservation.encounter.building.allBuildingProductStates.product,' +
	'reservation.user,' +
	'reservation.formFill.formAttributes';

export const salesmanVisitInclude = 'products,newCalendarResource,surveyFill';

export const tinyEncounterInclude = 'source.user';

export const encounterInclude =
	'building,' +
	'source.formFill.formAttributes,' +
	'source.calendarResource.client,' +
	'source.calendarResource.salesmanVisit,' +
	'source.calendarResource.salesman,' +
	'source.calendarResource.team,' +
	'source.user,' +
	'source.products';

export const salesmanVisitsQueryBase = {
	states: ['deal', 'cancelledDeal', 'offer', 'cancelledOffer'],
	include: 'user',
};
