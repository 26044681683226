export const salesmanVisitStates = {
	deal: 'Sale',
	cancelledDeal: 'Cancelled sale',
	cancelledOffer: 'Offer declined',
	offer: 'Offer',
	notReached: 'Not actualized',
	contacted: 'Not offered',
	notHome: 'Not home',
};

export const contactedStateReason = {
	notInterested: "I didn't get the customer interested in the service",
	couldNotEnter: 'I could not enter the house',
	noTime: 'The customer had no time, I will be in touch at',
	notAbleToDecide: 'Customer was not able to decide',
	removeClient: 'Customer info needs to be deleted (contact Sales Manager)',
	removeClientFromOrg:
		'Customer info to be deleted from organisation (contact Sales Manager)',
	other: 'Other',
};
