import {effect} from 'utils/redux';
import services from 'services';
import {pick, mergeLeft, equals} from 'ramda';
import {decorateWithNotifications} from 'io/app';
import {catchNonFatalDefault} from 'io/errors';
import {getQuery, pushQuery} from 'io/history';
import namespace from './namespace';
import {resolveObject} from 'utils/promises';
import * as actions from './actions';
import msgs from 'dicts/messages';
import * as selectors from './selectors';
import {
	getFormFills,
	getTeams,
	getTrackingForm,
	postTrackingForm,
	deleteTrackingForm,
} from './io';
import * as confirmerActions from 'modules/confirmer/actions';
import {parseUrlQuery} from './utils';

const creator = effect(namespace);

// eslint-disable-next-line
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const formsQueryKeys = ['_page'];

const fetchTrackingFormsData = (getState, dispatch) => {
	const formFillsQuery = selectors.formFillsQuery(getState());

	return Promise.all([
		resolveObject({
			allTeams: getTeams({_limit: '999', include: 'users'}),
			form: getTrackingForm({include: 'formAttributes'}),
		}).then(data => {
			dispatch(actions._setInitMeta(data));
		}),
		getFormFills(formFillsQuery).then(formFills => {
			dispatch(actions._setTrackingForms(formFills));
		}),
	]);
};

const fetchTrackingForms = ({notifyOpts = {}}) => (getState, dispatch) => {
	return decorateWithNotifications(
		{id: 'get-tracking-forms', failureStyle: 'error', ...notifyOpts},
		getFormFills(selectors.formFillsQuery(getState())),
	)(getState, dispatch).then(formFills => {
		dispatch(actions._setTrackingForms(formFills));
	});
};

export let initialize = () => (getState, dispatch) => {
	dispatch(actions._updateTrackingFormsQuery(pick(formsQueryKeys, getQuery())));

	decorateWithNotifications(
		{id: 'init-tracking-forms', failureStyle: 'error'},
		fetchTrackingFormsData(getState, dispatch),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let updateForms = () => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));

	const formFillsQuery = selectors.formFillsQuery(getState());

	decorateWithNotifications(
		{id: 'get-form-fills', failureStyle: 'error'},
		getFormFills(formFillsQuery),
	)(getState, dispatch)
		.then(formFills => {
			dispatch(actions._setTrackingForms(formFills));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
updateForms = creator('updateForms', updateForms);

export let saveNewTrackingForm = form => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'post-tracking-form',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		postTrackingForm(form),
	)(getState, dispatch)
		.then(form => {
			dispatch(actions._newTrackingFormSaved(form));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
saveNewTrackingForm = creator('saveNewTrackingForm', saveNewTrackingForm);

export let recheckQuery = () => (getState, dispatch) => {
	const urlQueries = parseUrlQuery(getQuery());
	const formFillsQuery = selectors.formFillsQuery(getState());
	if (!equals(formFillsQuery, urlQueries.formFillsQuery)) {
		dispatch(actions._updateTrackingFormsQuery(urlQueries.teamsQuery));
		fetchTrackingForms({})(getState, dispatch).catch(
			catchNonFatalDefault(getState, dispatch),
		);
	}
};

export let removeTrackingForm = id => (getState, dispatch) => {
	const onConfirm = () => {
		dispatch(actions._startOp());

		decorateWithNotifications(
			{
				id: 'delete-tracking-form',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Tracking form removed'}),
			},
			deleteTrackingForm(id),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._opOk());
				const {_page: page} = selectors.formFillsQuery(getState());
				if (selectors.trackingForms(getState()).length <= 1 && page > 1) {
					dispatch(actions._updateTrackingFormsQuery({_page: page - 1}));
					pushQuery(mergeLeft(selectors.urlQuery(getState())));
				}
				return fetchTrackingForms({notifyOpts: {loading: msgs.loading}})(
					getState,
					dispatch,
				);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete tracking form?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
removeTrackingForm = creator('removeTrackingForm', removeTrackingForm);
