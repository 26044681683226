import {FormattedMessage, injectIntl} from '@meiko/react-intl';
import DateFormlet from 'components/views/DateFormlet';
import TeamsFormlet from 'components/views/TeamsFormlet';
import React from 'react';
import {compose} from 'redux';
import {formValueSelector, reduxForm} from 'redux-form';
import Media from 'react-responsive';
import {screenMdMin} from 'styles/constants';
import services from 'services';
import messages from 'dicts/messages';
import ButtonGroup from 'components/generic/ButtonGroup';
import Button from 'components/generic/Button';
import {addMonths} from 'date-fns';
import {applyState} from 'utils/redux';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {formatDate, parseDate} from 'modules/usersApp/dashboardTargetsPage/utils';

const Row = styled.div`
	display: flex;
	gap: 10px;
	&:first-child {
		margin-top: 15px;
	}
`;

const Col = styled.div`
	flex: 1;
`;

let intl = null;
services.waitFor('intl').then(x => (intl = x));

function validate(values) {
	const errors = {};

	if (!values.date) {
		errors.date = intl.formatMessage({id: messages.requiredField});
	}

	if (!values.teamId || values.teamId.length === 0) {
		errors.teamId = intl.formatMessage({id: messages.requiredField});
	}

	return errors;
}

const DashboardTargetsFilterForm = ({handleSubmit, intl, teams, change, dateValue}) => {
	const currentDateValue = dateValue ? parseDate(dateValue) : new Date();

	const setDate = date => {
		change('date', formatDate(date));
	};
	return (
		<form onSubmit={handleSubmit}>
			<Row>
				<Col>
					<ButtonGroup style={{marginLeft: 'auto'}}>
						<Button onClick={() => setDate(addMonths(currentDateValue, -1))}>
							<i className="fa fa-angle-left" />
						</Button>
						<Button onClick={() => setDate(new Date())}>
							<FormattedMessage id="This month" />
						</Button>
						<Button onClick={() => setDate(addMonths(currentDateValue, 1))}>
							<i className="fa fa-angle-right" />
						</Button>
					</ButtonGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<DateFormlet
						name="date"
						type="month"
						label={intl.formatMessage({id: 'Month'})}
					/>
				</Col>
				<Col>
					<TeamsFormlet isMulti={false} name="teamId" teams={teams} />
				</Col>
				<Media query={`(min-width: ${screenMdMin})`}>
					<Col />
				</Media>
			</Row>
		</form>
	);
};

const DASHBOARD_TARGET_FILTER_FORM = 'dashboardTargetFilterForm';
const fieldValue = path => state =>
	formValueSelector(DASHBOARD_TARGET_FILTER_FORM)(state, path);

export default compose(
	injectIntl,
	connect(
		applyState({
			dateValue: fieldValue('date'),
		}),
	),
	reduxForm({
		validate,
		form: DASHBOARD_TARGET_FILTER_FORM,
		onChange: (values, dispatch, props, _previousValues) => {
			if (Object.keys(validate(values)).length === 0) {
				// It seeems to be impossible to trigger on submit when any of the form fields change WITHOUT manually implementing form state tracking and validtion OR using setTimeout
				setTimeout(() => {
					props.submit();
				}, 0);
			}
		},
	}),
)(DashboardTargetsFilterForm);
