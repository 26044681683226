import React from 'react';
import ReactDOM from 'react-dom';
import propTypes from 'prop-types';
import ReactDatePicker, {registerLocale} from 'react-datepicker';
import fi from 'date-fns/locale/fi';
import sv from 'date-fns/locale/sv';
import enGB from 'date-fns/locale/en-GB';
import {
	formatLocalDateOutput,
	formatLocalDateInput,
	maxDate,
	minDate,
} from 'utils/fields';
import {getNavigatorLanguage} from 'utils/loc';
import {addTimezoneError, removeTimezoneError, setHour} from 'utils/time';
import {isMobile} from 'utils/userAgents';
import services from 'services';

import styled from 'styled-components';
import {input} from 'styles/fragments';

registerLocale('fi-FI', fi);
registerLocale('fi', fi);
registerLocale('sv-SE', sv);
registerLocale('sv', sv);
registerLocale('en-GB', enGB);
registerLocale('en-US', enGB);
registerLocale('en', enGB);

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const NativeInput = styled.input`
	${input};
`;

const DateWrapper = styled.div`
	.react-datepicker {
		/* prevent breakage if the calendar overflows the screen */
		min-width: max-content;
	}

	> * {
		${({stretch}) => (stretch ? 'width: 100%;' : '')};
	}

	.react-datepicker__input-container > input {
		${input};
	}
`;

const CalendarContainer = ({children}) => {
	const el = document.getElementById('app');
	return ReactDOM.createPortal(children, el);
};

const dateProps = {min: '1970-01-02T00:00', max: '2038-01-01T00:00'};

const customDateInputTypes = new Set(['datetime-local', 'date', 'month']);

// use this to create custom events that play nice with redux-form. redux-form uses the "isEvent" function to determine if a value is an event (and thus whether to read event.target.value or use event directly).
const customEvent = value => {
	const evt = new CustomEvent('change');
	// note: may need to provide "type" also (input's type) if redux-form changes or if the input type is checkbox etc, but currently this is fine
	Object.defineProperty(evt, 'target', {value: {value}, enumerable: true});
	return evt;
};
// mostly brutal copy-paste from utils/fields
const parseCustomDate = d => {
	if (!d) return '';
	const dNew = addTimezoneError(d);
	if (isNaN(dNew.getTime())) return '';
	return dNew.toISOString().split('T')[0];
};
const formatCustomDate = str => {
	if (!str) return null;
	const s = str + 'T00:00:00Z';
	const d = removeTimezoneError(new Date(s));
	if (isNaN(d.getTime()) || d >= maxDate || d <= minDate) return null;
	return d;
};
const parseCustomYearMonth = d => {
	if (!d) return '';
	return parseCustomDate(d).substring(0, 7);
};
const formatCustomYearMonth = str => {
	if (!str) return null;
	const s = str + '-01T00:00:00Z';
	const d = removeTimezoneError(new Date(s));
	if (isNaN(d.getTime()) || d >= maxDate || d <= minDate) return null;
	return d;
};

const renderCustomDateInput = (props, ref, isValid) => {
	// don't pass onBlur
	const {
		value,
		onChange,
		step,
		onBlur,
		stretch,
		placeholder,
		overrideProps = {},
		isClearable = true,
		...rest
	} = props;

	// NOTE: disable the input textfield of the picker since this is supposed to be for mobile devices only for now, and there you don't want to see the picker and the keyboard simultaneously. also, editing the time part of the text field doesn't trigger a change on react-datepicker anyway, at least on this version.
	const disableInput = dateEl => {
		// may be null for whatever reason
		if (isMobile() && dateEl && dateEl.input) {
			dateEl.input.setAttribute('readonly', '');
		}
	};

	const setDefaultDateTime = date => {
		// Set default time to 12:00. Selecting date from DatePicker sets time to 00:00 by default and it cannot be changed.
		// Drawback: 00:00 time cannot be selected at all, but there should never be need to do that
		if (date && date.getHours() === 0 && date.getMinutes() === 0) {
			date.setHours(12, 0, 0, 0);
			return date;
		}

		return date;
	};

	// note: ref prop from parent not passed on to the component
	const commonProps = {
		...rest,
		ref: disableInput,
		isClearable: rest.disabled || !isClearable ? false : true,
		locale: getNavigatorLanguage(),
		calendarStartDay: 1,
		minDate: new Date(dateProps.min + ':00'),
		maxDate: new Date(dateProps.max + ':00'),
		popperContainer: CalendarContainer,
		popperClassName: 'header-dropdown-close-immune',
		popperModifiers: [{name: 'preventOverflow'}],
		placeholderText: placeholder,
	};

	let component;
	switch (props.type) {
		case 'datetime-local': {
			component = (
				<ReactDatePicker
					{...commonProps}
					showTimeSelect
					timeIntervals={step ? step / 60 : 60}
					minTime={setHour(new Date(), 7, 0, 0, 0)}
					maxTime={setHour(new Date(), 22, 0, 0, 0)}
					dateFormat="dd.MM.yyyy HH:mm"
					timeFormat="HH:mm"
					timeCaption={intl.formatMessage({id: 'Time'})}
					onChange={date =>
						onChange(customEvent(formatLocalDateInput(setDefaultDateTime(date))))
					}
					selected={formatLocalDateOutput(value)}
					{...overrideProps}
				/>
			);
			break;
		}
		case 'date': {
			component = (
				<ReactDatePicker
					{...commonProps}
					dateFormat="dd.MM.yyyy"
					onChange={date => onChange(customEvent(parseCustomDate(date)))}
					selected={formatCustomDate(value)}
					{...overrideProps}
				/>
			);
			break;
		}
		case 'month': {
			component = (
				<ReactDatePicker
					{...commonProps}
					showMonthYearPicker
					dateFormat="MM.yyyy"
					onChange={date => onChange(customEvent(parseCustomYearMonth(date)))}
					selected={formatCustomYearMonth(value)}
					{...overrideProps}
				/>
			);
			break;
		}
		default: {
			throw new Error('invalid input type');
		}
	}

	return (
		<DateWrapper isValid={isValid} stretch={stretch}>
			{component}
		</DateWrapper>
	);
};

let Input = (_props, ref) => {
	const {meta, ...props} = _props;
	const isValid = meta ? !((meta.submitFailed || meta.touched) && meta.error) : true;

	if (customDateInputTypes.has(props.type)) {
		return renderCustomDateInput(props, ref, isValid);
	}

	const _dateProps = props.dateRange ? {...dateProps, ...props.dateRange} : dateProps;

	const defaultProps = props.type === 'datetime-local' ? _dateProps : {};

	return <NativeInput ref={ref} isValid={isValid} {...defaultProps} {...props} />;
};
Input = React.forwardRef(Input);

Input.propTypes = {
	onChange: propTypes.func,
	onBlur: propTypes.func,
	type: propTypes.string,
	value: propTypes.any,
	step: propTypes.any,
	meta: propTypes.object,
	dateRange: propTypes.object,
};

export default Input;
