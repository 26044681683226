// highly inspired by redux-loop: https://github.com/redux-loop/redux-loop

import {assertTypeAndTransformError} from './reduxLocalHelpers';
import services from 'services';

const bugsnagNotify = (severity, error) => {
	const bugsnag = services.get('bugsnag');
	if (!bugsnag) {
		return;
	}
	bugsnag.notify(error, {severity});
};

// transform a redux compliant state reducer into an action handler with no side effects
export const reducerToHandler = reducer => (state, action) =>
	[reducer(state, action), null];

// determine if an effect is non-null, ie causes any operation to be performed
export const isOpEffect = effect => effect && (!Array.isArray(effect) || effect.length);

// transform effect metadata into a serializable format
// prettier-ignore
export const formatEffect = effect => !effect ? null
	: Array.isArray(effect) ? effect.map(formatEffect).filter(x => !!x)
	: {type: effect.type, ...(effect.arg !== undefined ? {arg: effect.arg} : {})};

const runEffect = (effect, {getState, dispatch, action}) => {
	// null effects are no-ops
	if (!effect) return;
	// arrays of effects are run recursively
	if (Array.isArray(effect)) {
		effect.forEach(e => runEffect(e, {getState, dispatch, action}));
	} else {
		// normal effects are run just like that. they're supplied the additional "action" argument for internal reasons, corresponding to the action that spawned them in the reducer
		// note that effects are executed synchronously - this is important so that synchronous io and state updates from sub-dispatches are executed instantly without the need for a mechanism to wait for the execution of the effect. make sure to avoid blocking inside effects.
		// we use a try-catch around the exception. this wouldn't normally be needed, but redux-form swallows errors that get called through a form's onSubmit function, so we force logging errors here. shouldn't cause a meaningful performance issue either.
		try {
			effect(getState, dispatch, action);
		} catch (e) {
			console.warn(e);
			// hopefully this doesn't cause tons of duplicate bugsnag errors, we can leave it out if it does
			bugsnagNotify('warning', e);
			throw e;
		}
	}
};

// create an action handler enhancer for redux store. instead of a standard redux reducer, expects to receive an action handler. action handlers are similar to reducers, but instead of only the state they return a tuple of state and a side effect to be executed after the state change ([state, effect]). the side effect may be a function that receives (getState, dispatch). alternatively it may be null for no effect or a list of effects.
export const createEnhancer = () => next => (handler, initialState, enhancer) => {
	let effectsQueue = [];

	// transform an action handler into a redux compliant reducer that also implicitly executes the effects specified by the handler
	const handlerToReducer = handler => (state, action) => {
		const [resultState, effect] = handler(state, action);
		// embed info about the effect into the action after it's been handled. not the nicest way to go about it but this is merely for debugging purposes - if something more robust is needed, we should log info about the effect and the action that fired it someplace separate.
		// note that the action needs to be mutated ASAP so that redux devtools picks it up
		if (isOpEffect(effect)) {
			action['@@effect'] = formatEffect(effect);
		}
		effectsQueue.push({effect, action});
		return resultState;
	};

	const store = next(handlerToReducer(handler), initialState, enhancer);

	// perform effects on dispatch after state update
	const dispatch = action => {
		store.dispatch(action);
		const effectsToRun = effectsQueue;
		effectsQueue = [];
		effectsToRun.forEach(handleEffect);
	};

	// run the effect spawned by the action
	const handleEffect = ({effect, action}) => {
		runEffect(effect, {getState: store.getState, dispatch, action});
	};

	const replaceReducer = handler => {
		return store.replaceReducer(handlerToReducer(handler));
	};

	return {
		...store,
		dispatch,
		replaceReducer,
	};
};

// create a creator for an effect function. this enables embedding type checking and meta information into effects / effect creators.
export const effect = (type, routine, argType = null) => {
	const augmentError = e => {
		e.message = `At effect "${type}": ${e.message}`;
		return e;
	};

	const creator = arg => {
		if (argType) {
			assertTypeAndTransformError(argType, arg, augmentError);
		}

		// effect is supplied its parent action it if it's spawned as the result of a handler
		const effect = (getState, dispatch, maybeAction) => {
			// insert info about the parent action into all sub-actions dispatched from inside the effect
			// prettier-ignore
			const dispatch_ = maybeAction ?
				a => {
					a['@@parent'] = maybeAction.type;
					dispatch(a);
				}
			:
				dispatch;

			routine(arg)(getState, dispatch_);
		};
		effect.type = type;
		if (argType) {
			effect.arg = arg;
		}
		effect.argType = argType;
		effect.toString = () => type;
		return effect;
	};
	creator.type = type;
	return creator;
};

// create a handler that pipes the state through the supplied handlers in order and includes the possible effect returned by one of the handlers. note that only one of the handlers is allowed to react to the action. this is because it lets us not worry about having to prune null effects and flatten out unnecessary nesting when nesting pipeHandlers. this is ok to do since the project's state management conventions disallow handlers reacting to actions that come from an external module
export const pipeHandlers =
	(...handlers) =>
	(state, action) => {
		const [resultState, effect] = handlers.reduce(
			([state, effect], handler) => {
				const [newState, resultEffect] = handler(state, action);
				const newEffect = isOpEffect(resultEffect) ? resultEffect : effect;
				return [newState, newEffect];
			},
			[state, null],
		);
		return [resultState, effect];
	};
