import services from 'services';
import {map, prop} from 'ramda';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {mapResponseData, getResponseData} from 'utils/app';
import {getLogModelInclude} from './utils';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getLogs = (query = {}) =>
	httpJson('get', '/logs', {include: getLogModelInclude(query.logModel), ...query})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.log)));

export const getCallLogs = query =>
	httpJson('get', '/callLogs', {include: 'organization', ...query})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.log)));

export const getOrganizations = () =>
	httpJson('get', '/organizations', {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.organization)));

export const searchUsers = query =>
	httpJson('get', '/users/search', query)
		.catch(describeThrow(intl.formatMessage({id: 'Search failed'})))
		.then(prop('data'));

export const searchBuildings = query =>
	httpJson('get', '/buildings/search', query)
		.catch(describeThrow(intl.formatMessage({id: 'Failed to search buildings'})))
		.then(prop('data'));

export const getTeams = () =>
	httpJson('get', '/teams', {
		getAllTeams: true,
		customerOrganizationTeams: false,
		_limit: 999,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load teams'})))
		.then(getResponseData(map(normalize.team)));
