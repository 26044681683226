import React from 'react';
import propTypes from 'prop-types';
import styled, {css} from 'styled-components';
import PanelHeader from './PanelHeader';
import {borderColorBase, cardTitleBgColor, screenSmMax} from 'styles/constants';
import Label from 'components/generic/Label';
import Icon from 'components/generic/Icon';

const wrapperCss = css`
	${
		/*prettier-ignore*/ ({noTopSpacing}) => noTopSpacing == null ? `
		&:not(:first-child) {
			margin-top: 30px;

			@media (max-width: ${screenSmMax}) {
				margin-top: 15px;
			}
		}
	` : !noTopSpacing ? `
		margin-top: 30px;

		@media (max-width: ${screenSmMax}) {
			margin-top: 15px;
		}
	` : ''
	}
	${({bottomSpacing}) => (bottomSpacing ? 'margin-bottom: 30px;' : '')};
	${({maxWidth}) => (maxWidth ? `max-width: ${maxWidth};` : '')};
	width: 100%;
	${({square}) => (!square ? 'border-radius: 10px;' : '')};
	background: white;

	${
		/*prettier-ignore*/ ({noBorder, noTopBorder}) => !noBorder ? `
		border: 1px solid ${borderColorBase};
	` : !noTopBorder ? `
		border-top: 1px solid ${borderColorBase};
	` : ''
	};
`;

const WrapperDiv = styled.div`
	${wrapperCss};
`;

const WrapperSection = styled.section`
	${wrapperCss};
`;

const HeaderWrapper = styled.div`
	border-bottom: ${({toggleable, isOpen}) =>
		toggleable && !isOpen ? '1px solid transparent' : `1px solid ${borderColorBase}`};
	padding: 10px 20px;
	${
		/*prettier-ignore*/ ({square}) => !square ? `
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	` : ''
	};
	background: ${cardTitleBgColor};
	${({toggleable}) =>
		toggleable ? `&:hover {background: #f6f6f6; transition: all 0.1s linear;}` : ''}
	transition: all 0.1s linear;
	cursor: ${({toggleable}) => (toggleable ? 'pointer' : 'default')};
`;

const ContentWrapper = styled.div`
	/* these make the panel contain any possible margins inside it, instead of the margins collapsing with the panel. display: flow-root would also work on new browsers */
	display: inline-block;
	width: 100%;
	padding: 0 20px;
	${
		/*prettier-ignore*/ ({bottomPadding, verticalPadding}) => bottomPadding || verticalPadding ? `
		padding-bottom: 20px;
	` : ''
	};
	${
		/*prettier-ignore*/ ({topPadding, verticalPadding}) => topPadding || verticalPadding ? `
		padding-top: 20px;
	` : ''
	};
`;

const StyledLabel = styled(Label)`
	margin-right: -15px;
	padding: 10px 15px;

	display: flex;

	> input[type='checkbox'] {
		display: none;
	}
`;

const StyledIcon = styled(Icon)`
	> svg {
		vertical-align: top;
	}
`;

const ToggleButton = ({isOpen, changeHandler}) => {
	return (
		<StyledLabel>
			<StyledIcon name={isOpen ? 'subtract' : 'add'} width="12px" height="12px" />
			<input type="checkbox" checked={isOpen} onChange={changeHandler} />
		</StyledLabel>
	);
};
ToggleButton.propTypes = {
	isOpen: propTypes.bool,
	changeHandler: propTypes.func,
};

class Panel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openedPanel: props.initialToggled !== undefined ? props.initialToggled : true,
		};
	}

	componentDidUpdate() {
		this.openPanel(this.props.toggled);
	}

	toggleContent = e => {
		e.preventDefault();
		const isOpen = !this.state.openedPanel;
		if (this.props.toggleFunc) {
			this.props.toggleFunc(isOpen);
		}
		this.setState({openedPanel: isOpen});
	};

	openPanel = bool => {
		if (bool !== undefined && this.state.openedPanel !== bool) {
			this.setState({openedPanel: bool});
		}
	};

	render() {
		const {
			children,
			header,
			headerTitle,
			headerActions,
			toggleable = false,
			inline = false,
			square,
			noBorder,
			noTopSpacing,
			isSection = !!headerTitle,
			...rest
		} = this.props;

		const {bottomPadding, topPadding, verticalPadding} = rest;

		let square_ = square;
		let noBorder_ = noBorder;
		let noTopSpacing_ = noTopSpacing;
		if (inline) {
			square_ = true;
			noBorder_ = true;
			noTopSpacing_ = true;
		}

		const Wrapper = isSection ? WrapperSection : WrapperDiv;

		const toggleHandler = toggleable ? this.toggleContent : () => {};

		const isOpen =
			this.props.toggled !== undefined ? this.props.toggled : this.state.openedPanel;

		return (
			<Wrapper
				{...rest}
				square={square_}
				noBorder={noBorder_}
				noTopSpacing={noTopSpacing_}
				isOpen={isOpen}
			>
				{header ? (
					<HeaderWrapper square={square_} className="panel-header-wrapper">
						{header}
					</HeaderWrapper>
				) : headerTitle || headerActions || toggleable ? (
					<HeaderWrapper
						square={square_}
						toggleable={toggleable}
						isOpen={isOpen}
						onClick={toggleHandler}
						className="panel-header-wrapper"
					>
						<PanelHeader
							title={headerTitle}
							actions={headerActions}
							toggle={
								toggleable && (
									<ToggleButton isOpen={isOpen} changeHandler={this.toggleContent} />
								)
							}
						/>
					</HeaderWrapper>
				) : null}
				{isOpen && (
					<ContentWrapper
						bottomPadding={bottomPadding}
						topPadding={topPadding}
						verticalPadding={verticalPadding}
					>
						{children}
					</ContentWrapper>
				)}
			</Wrapper>
		);
	}
}

Panel.propTypes = {
	children: propTypes.node,
	header: propTypes.node,
	headerTitle: propTypes.node,
	headerActions: propTypes.node,
	inline: propTypes.bool,
	square: propTypes.bool,
	noBorder: propTypes.bool,
	noTopSpacing: propTypes.bool,
	isSection: propTypes.bool,
	maxWidth: propTypes.string,
	toggleable: propTypes.bool,
	toggled: propTypes.bool,
	initialToggled: propTypes.bool,
	toggleFunc: propTypes.func,
};

export default Panel;
