import {scopedHandler, pipeSubHandlers} from 'utils/redux';
import {getFormValues} from 'redux-form';
import {pipe, prop, pick, isEmpty} from 'ramda';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import * as rootSelectors from 'modules/common/selectors';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {formatClientFormOutput, formatVisitFormOutput} from './utils';
import {processFreeCalendarResources} from 'fragments/calendarResourcePicker/utils';
import createBuildingModalHandler from 'fragments/buildingModalActions/handler';
import createDatePickHandler from 'fragments/calendarResourcePicker/handler';
import {processProductStatesForForms} from 'utils/products';
import {isSalesUser} from 'utils/perms';

const pipeHandlers = pipeSubHandlers(ns);

const buildingLoadingKeys = [
	'buildingLoading',
	'encountersLoading',
	'freeCalendarResourcesLoading',
	'salesTeamsLoading',
];
// values to mark as loading when moving to a new building - includes anything building-specific
const buildingLoadingInitValues = pick(buildingLoadingKeys, initState);

const buildingModalHandler = createBuildingModalHandler({actions, effects});

const datePickHandler = createDatePickHandler({
	actions,
	effects,
	calendarResourceSelectionSelector: pipe(
		getFormValues('visitForm'),
		prop('calendarResourceId'),
	),
});

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			const buildingId = payload;
			return [state, effects.initialize(buildingId)];
		}

		case actions.destroy.type: {
			return [initState, effects.destroy()];
		}

		case actions.changeBuilding.type: {
			return [
				{...state, ...buildingLoadingInitValues, visitJustSaved: false},
				effects.changeBuilding(payload),
			];
		}

		case actions.resetVisitForm.type: {
			return [state, effects.resetVisitForm()];
		}

		case actions.saveVisit.type: {
			const form = getFormValues('visitForm')(fullState);
			const user = rootSelectors.user(fullState);
			const isSalesmanager = rootSelectors.isSalesmanagerUser(fullState);
			const teamId = !isEmpty(state.userTeams) ? state.userTeams[0].id : null;

			const {visit, calendarResource, formFill, building} = formatVisitFormOutput({
				form,
				building: state.building,
				teamId,
			});

			const visit2 = {...visit, buildingId: state.building.id};

			// set salesmanid null if user isn't salesUser
			// get salesmanId from calendarResource if user is salesmanager and cr is bonus time
			// else use current user's id
			const salesmanId =
				!isSalesUser(user) || !calendarResource
					? null
					: isSalesmanager
					? !calendarResource.bonus
						? user.id
						: calendarResource.salesmanId
					: user.id;

			const setRequestSource = form => {
				if (form?.requestDate !== undefined && form?.requestSource !== undefined) {
					return {
						requestSource: form?.requestSource,
						requestDate: form?.requestDate,
					};
				}
				return {};
			};

			const marketingLeadSource = form?.isRequest ? setRequestSource(form) : {};
			const calendarResource2 = calendarResource
				? {
						...marketingLeadSource,
						...calendarResource,
						reserverId: user.id,
						salesmanId,
				  }
				: null;

			const building2 = {
				...building,
				productEncounterType: 'visit',
			};

			return [
				{...state, processing: true},
				effects.saveVisit({
					visit: visit2,
					calendarResource: calendarResource2,
					formFill,
					building: building2,
				}),
			];
		}

		case actions._setOpenedAt.type: {
			return [{...state, openedAt: payload}, null];
		}

		case actions._setBuilding.type: {
			const productStates = processProductStatesForForms(payload.buildingProductStates);
			const building = {...payload, buildingProductStates: productStates};

			return [{...state, building, buildingLoading: false, productStates}, null];
		}

		case actions._setEncounters.type: {
			return [{...state, encountersLoading: false, encounters: payload}, null];
		}

		case actions._setSalesTeams.type: {
			const activeOrganizationId = rootSelectors.activeOrganizationId(fullState);
			const salesTeams = rootSelectors.canAddCalendarResourcesToAnyTeam(fullState)
				? payload.filter(t => t.organizationId === activeOrganizationId)
				: payload;

			return [{...state, salesTeams, salesTeamsLoading: false}, null];
		}

		case actions._startOp.type: {
			return [{...state, processing: true}, null];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false}, null];
		}

		case actions._opOk.type: {
			return [{...state, processing: false}, null];
		}

		case actions._setBuildingLoading.type: {
			return [{...state, buildingLoading: true}, null];
		}

		case actions._setEncountersLoading.type: {
			return [{...state, encountersLoading: true}, null];
		}

		case actions._visitSaved.type: {
			return [
				{
					...state,
					processing: false,
					visitJustSaved: true,
					// mark values that are relevant to the form and may have gone stale as loading
					freeCalendarResourcesLoading: true,
				},
				null,
			];
		}

		case actions._visitSaveFailed.type: {
			return [{...state, processing: false}, null];
		}

		// calendar
		case actions.toggleNewDateCreator.type: {
			const visitForm = getFormValues('visitForm')(fullState) || {};
			const newCalendarResourceId = visitForm.newCalendarResourceId;

			return [
				{...state, newDateCreatorOpen: !state.newDateCreatorOpen},
				// resets both newCalendarResourceId and newAppointmentDate, effect will make API request only if needed
				effects.selectCalendarResource({
					dateId: null,
					currentDateId: newCalendarResourceId,
					desiredDateId: null,
				}),
			];
		}

		case actions._setFreeCalRes.type: {
			const resources = processFreeCalendarResources(payload);
			return [
				{...state, freeCalendarResourcesLoading: false, freeCalendarResources: resources},
				null,
			];
		}

		case actions._setUserTeams.type: {
			return [{...state, userTeamsLoading: false, userTeams: payload}, null];
		}

		// encounter modal
		case actions.openEncounterModal.type: {
			return [state, effects.getEncounterData(payload)];
		}

		case actions.closeEncounterModal.type: {
			return [{...state, encounter: null}, null];
		}

		case actions._setEncounterData.type: {
			return [{...state, encounter: payload}, null];
		}

		// client modal
		case actions.saveClient.type: {
			const form = getFormValues('clientForm')(fullState);
			const client = formatClientFormOutput({form});
			const newClient = state.client ? state.client.isNew : true;
			const buildingId = newClient ? state.building.id : null;

			return [{...state}, effects.createClient({client, buildingId})];
		}

		case actions.removeClient.type: {
			const clientId = state.client.id;
			return [{...state}, effects.removeClient(clientId)];
		}

		case actions.toggleClientEditor.type: {
			const newState = {
				...state,
				client: payload,
				clientEditorOpen: !state.clientEditorOpen,
			};

			return [newState, null];
		}

		case actions._updateClients.type: {
			// prettier-ignore
			const clients = payload.type === 'update' ?
				state.building.clients.map(c => {
						return c.id === payload.client.id ? payload.client : c;})
				: payload.type === 'add' ?
					[...state.building.clients, payload.client]
				: payload.type === 'remove' ?
					state.building.clients.filter(c => c.id !== payload.id)
				: [];

			const newBuilding = {...state.building, clients};
			const newState = {
				...state,
				building: newBuilding,
				clientEditorOpen: false,
			};

			return [newState, null];
		}

		default:
			return pipeHandlers(datePickHandler, buildingModalHandler)(state, fullState, {
				type,
				payload,
			});
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
