import styled from 'styled-components';
import {openLayersWidgetMargin} from 'styles/constants';

export default styled.div`
	pointer-events: none;
	position: absolute;
	left: 50px;
	top: ${openLayersWidgetMargin};
	right: ${openLayersWidgetMargin};
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
	margin-left: -${openLayersWidgetMargin};
	margin-top: -${openLayersWidgetMargin};

	> * {
		pointer-events: auto;
		margin-left: ${openLayersWidgetMargin};
		margin-top: ${openLayersWidgetMargin};
	}
`;
