export const leadsQueryBase = {
	_limit: '25',
	withSummary: true,
	socialMediaLeads: true,
};

export const emptyLeadsFilters = {
	createdAtStart: '',
	createdAtEnd: '',
	dealAtStart: '',
	dealAtEnd: '',
	marketingState: '',
	organizationId: [],
	marketingCategoryId: '',
	marketingChannelId: [],
	marketingSourceId: [],
	domains: [],
	service: [],
	utmSource: [],
	utmMedium: [],
	utmContent: [],
	utmCampaign: [],
	gclid: false,
	products: [],
	buildingAddress: '',
	state: '',
	priceFrom: '',
	priceTo: '',
	salesmanId: null,
	teamId: null,
	trashed: false,
	deletedReason: '',
	county: null,
	city: null,
	paidAdd: '',
	tags: [],
};

export const marketingLeadInclude =
	'building,client,products,salesmanager,calendarResource.team,calendarResource.salesmanVisit,calendarResource.salesman,marketingSource,organization,marketingCustomerType,marketingCategory,marketingChannel';
