import React from 'react';

const SvgDoubleArrowRight = props => (
	<svg width="1em" height="1em" viewBox="0 0 12 12" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M6.792 6c0 .352-.139.689-.388.938l-4.77 4.77a.884.884 0 0 1-1.25-1.25l4.37-4.37a.125.125 0 0 0 0-.176l-4.37-4.37a.884.884 0 0 1 1.25-1.25l4.77 4.77c.25.249.389.586.388.938zm5.083 0c0 .352-.139.689-.387.938l-4.771 4.77a.884.884 0 0 1-1.25-1.25l4.37-4.37a.125.125 0 0 0 0-.176l-4.37-4.37a.884.884 0 0 1 1.25-1.25l4.77 4.77c.25.249.389.586.388.938z"
		/>
	</svg>
);

export default SvgDoubleArrowRight;
