import {action} from 'utils/redux';
import {P, Nullable} from 'utils/types';
import namespace from './namespace';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const initRoleEditor = creator('initRoleEditor', Nullable(P.Object));

export const saveRole = creator('saveRole');

export const updateRole = creator('updateRole');

export const cancelRoleEditor = creator('cancelRoleEditor');

export const _setProcessing = creator('_setProcessing');

export const _setPermissions = creator('_setPermissions');

export const _setRoles = creator('_setRoles', P.Object);

export const _updateRolesQuery = creator('_updateRolesQuery', P.Object);

export const _initialize = creator('_initialize');

export const _updateRoles = creator('_updateRoles');
