import createResolver from './createResolver';
import {createBrowserHistory as createHistory} from 'history';

export default createResolver({
	history: createHistory({
		getUserConfirmation(message, callback) {
			// use "message" as Symbol-based key
			const dialogTrigger = window[Symbol.for(message)];
			if (dialogTrigger) {
				// delegate to dialog and pass callback through
				return dialogTrigger(callback);
			}

			// Fallback to allowing navigation
			callback(true);
		},
	}),
	...(window.bugsnagInstance ? {bugsnag: window.bugsnagInstance} : {}),
});
