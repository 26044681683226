import styled from 'styled-components';
import {button} from 'styles/fragments';

/**
 * Button component
 * @param {string} type - button type
 * @param {string} apperance - button appearance (primary, success, secondary, brand, default, dark, info, warning, error, radio)
 */
export default styled.button.attrs(({type}) => ({
	type: type || 'button',
}))`
	${button};
`;
